/*	This module provides a set of functions to convert standard maps displayed in the
	yaml visual editor from/to the yaml-maps format.
*/


import type_pickup_mapsTemplate from "@/assets/templates/typePickupMapTemplate.json";
import pickup_mapsTemplate from "@/assets/templates/pickupMapTemplate.json";
import pickup_day_of_week_mapsMapTemplate from "@/assets/templates/dayOfTheWeekMapTemplate.json";
import far_out_premium_mapTemplate from "@/assets/templates/farOutPremiumMapTemplate.json";
import custom_drop_mapsTemplate from "@/assets/templates/customDropMapTemplate.json"; 
import units_pickup_maps_mapsTemplate from "@/assets/templates/units_pickup_maps.json"; 

const hash = require('object-hash');


const mapsTemplates = {
	'type_pickup_maps': type_pickup_mapsTemplate,
	'pickup_maps': pickup_mapsTemplate,
	'pickup_day_of_week_maps': pickup_day_of_week_mapsMapTemplate,
	'far_out_premium_maps' : far_out_premium_mapTemplate,
	'custom_drop_maps': custom_drop_mapsTemplate,
	'units_pickup_maps': units_pickup_maps_mapsTemplate
}


//=========================================================================================
//
//	YAML FORMAT MAPS  TO VISUAL EDITOR FORMAT MAPS
//

function hashArrayItems(items){
	var hashItems = [];
	for(var i=0; i < items.length; i++){
		hashItems.push(hash(items[i]));
	}

	return hashItems;
}

function reverseYamlToMap(map, yamlMap) {
	/*	Copies common yaml map properties into common
		visual editor map 
	*/

	if(yamlMap.hasOwnProperty('date_range')){
		map['date_range'] = {};
		map['date_range']['value'] = yamlMap['date_range'];
	}
	if(yamlMap.hasOwnProperty('key')){
		map['key'] = yamlMap['key'];
	}
 	if(yamlMap.hasOwnProperty('type')){
 		map['type'] = yamlMap['type'];
 	}
 	if(yamlMap.hasOwnProperty('map')){
 		map['value'] = yamlMap['map'];
 	}

 	return map;
}



function YamlMapsToLabelValue(yamlMaps, mapType){
	/* The simplest of converters: visual maps are made by label & value

	*/
	var convertMaps = [];
	if(mapsTemplates.hasOwnProperty(mapType)){

		let mapTemplate = mapsTemplates[mapType];
		let options = mapTemplate.options;
		let hashOptions = {};

		for(var i=0; i<options.length; i++){
			let option = options[i];
			hashOptions[hash(option.value)] = option.label;
		}

		for(var i=0; i < yamlMaps.length; i++){
			let yMap = yamlMaps[i];

			let hashMap = hash(yMap);
 			if(mapType == 'custom_drop_maps'){
 				hashMap = hash(yMap.map);
 			}

			var map = {};
			map.label = hashOptions[hashMap];
			map.value = yMap;
			
			if(mapType == 'custom_drop_maps'){
				map.type = yMap.type
	 			map.value = yMap.map;
	 		}

			if ( mapType == "far_out_premium_maps" && Object.keys(map.value).length > 0 ) {
				convertMaps.push(map);
			}
			if ( mapType == 'custom_drop_maps' ) {
				//if it is a custom drop map
				convertMaps.push(map);
			}
			
		}
	}

	return convertMaps;
}

function YamlMapsToUnitPickup(yamlMaps) {
	/*
			WARNING
			At the time of creation, this map type has only one option.
			Modify this function accordingly when adding more options;
	*/
	var convertMaps = [];
	let mapTemplate = units_pickup_maps_mapsTemplate;
	for(var i=0; i < yamlMaps.length; i++){
		let map = {};
		let yamlMap = yamlMaps[i];
		map['map'] = yamlMap['map'];
		map['key'] = yamlMap['key'];
		map['date_range'] = YamlDateRangeToMapDateRange(yamlMap['date_range'][0]);

		convertMaps.push(map);
	}

	return convertMaps;
}


export function YamlDateRangeToMapDateRange(yamlDateRange){
  /*
  	Converts a visual editor date range into yaml format
  */
    var date_range = {
                	'start_date':{'day':null, 'month': null, 'year': null},
                	'end_date': {'day': null, 'month': null, 'year': null}
            	};

    if(yamlDateRange.length == 2){
    	let start_range = yamlDateRange[0].split('-');
    	let end_range = yamlDateRange[1].split('-');

    	// assumes that frontend validation rules forbid to write to yaml incomplete date ranges, i.e. start-end date in the 
    	// yaml have always at least day-month
    	date_range.start_date.day = start_range[1];
    	date_range.start_date.month = start_range[0];

    	date_range.end_date.day = end_range[1];
    	date_range.end_date.month = end_range[0];

    	if(start_range.length == 3){
    		date_range.start_date.year = start_range[0];
	    	date_range.start_date.day = start_range[2];
	    	date_range.start_date.month = start_range[1];
    	}
    	if(end_range.length == 3){
    		date_range.end_date.year = end_range[0];
    		date_range.end_date.day = end_range[2];
    		date_range.end_date.month = end_range[1];

    	}
    }
    return date_range;
}


function YamlMapsToPickup(yamlMaps, mapType){

	/*	Convert an array of yaml maps to an array of visual editor maps if the yaml map is
		among the possible standard choices.
	*/

	var convertMaps = [];

	if(mapsTemplates.hasOwnProperty(mapType)){

		let mapTemplate = mapsTemplates[mapType];
		let dateRanges = mapTemplate.dateRanges;
		let hashDateRanges = {};		

		if(dateRanges !== undefined){
			for(var i=0; i<dateRanges.length; i++){
				let dRange = dateRanges[i];
				hashDateRanges[hash(dRange.value)] = dRange.label; //use hash for ease of comparison
			}
		}

		let options = mapTemplate.options;
		let hashOptions = {};

		for(var i=0; i<options.length; i++){
			let option = options[i];
			hashOptions[hash(option.value)] = option.label;
		}


		for(var i=0; i < yamlMaps.length; i++){
			let yMap = yamlMaps[i];
			var map = {};
			map = reverseYamlToMap(map, yMap); 

			let hashDRange = hash(map.date_range.value);
			if(hashDateRanges.hasOwnProperty(hashDRange)){
				map['date_range']['label'] = hashDateRanges[hashDRange];
			}

			let hashOption = hash(map.value);
			if(hashOptions.hasOwnProperty(hashOption)){
				map['label'] = hashOptions[hashOption]
			}

			convertMaps.push(map);
		}
	}

	return convertMaps;
}


export function yamlMapsToVisualMaps(mapsTemplate){
	/*
		This function intended use is from StandardMapsDateRange.vue. The function converts the maps of the argument map template
		into visual editor format maps. 
		Maps templates are used to populate options and selected values in the maps visual 
		editors (e.g. see pickupMapTemplate.json imported above). Map templates 'maps' field stores the maps instances 
		of a given strategy. When this function is invoked, maps field is filled with maps in yaml format, feeded by the computed 
		properties in StandardMapsYamlEditor.vue (e.g. pickupMaps()). The function converts those yaml-format maps into visual editor standard 
		format (when possible) so that the map can be displayed in the visual-editor. When yaml-format maps have values (e.g. a date range)
		which is not among the standard map template choices, the map is skipped and not added to the visual editor.
	*/
	var mapType = mapsTemplate.mapType;
	var yamlMaps = mapsTemplate.maps;
	var maps = null;
	if(mapType == 'type_pickup_maps'){
		maps = YamlMapsToPickup(yamlMaps, mapType);
	}

	if (mapType == "pickup_maps"){
		maps = YamlMapsToPickup(yamlMaps, mapType)
	}

	if(mapType == 'pickup_day_of_week_maps'){
		maps = YamlMapsToPickup(yamlMaps, mapType);
	}

	if(mapType == 'far_out_premium_maps'){
		maps = YamlMapsToLabelValue(yamlMaps, mapType);
	}

 	if(mapType == 'custom_drop_maps'){
		maps = YamlMapsToLabelValue(yamlMaps, mapType);
 	}

 	if(mapType == 'units_pickup_maps'){
		maps = YamlMapsToUnitPickup(yamlMaps);
 	}


	mapsTemplate.maps = maps; //replace yaml-maps with visual editor maps

	return mapsTemplate;

}




//=========================================================================================
//
//	VISUAL EDITOR FORMAT MAPS TO YAML FORMAT MAPS
//


function getYamlMapTemplate(mapType){
	/*	Returns a blueprint for a standard map having the appropriate format for the 
		yaml.
	*/
	const blueprints = {
		'type_pickup_maps': {'date_range': [], 'key': null, 'map': [] },
		'pickup_maps': {'date_range': [], 'key': null, 'map':[]},
		'pickup_day_of_week_maps': {'date_range':[], 'type': null, 'map': [] },
		'far_out_premium_maps' : { 'type': null},
		//'far_out_premium_maps' : { },
		'custom_drop_maps': {'type': [], 'key': null, 'map': [], },
		'units_pickup_maps': {'date_range': [], 'key': null, 'map': []}
	};

	var blueprint = null;
	if(blueprints.hasOwnProperty(mapType)){
		blueprint = blueprints[mapType];
	}

	return blueprint;

}


function dateRangeToYaml(map){
  /*
  	Converts a visual editor date range into yaml format
  */
  	var clone_date_range = [];
    let start = map.date_range.start_date;
    let end = map.date_range.end_date;     

    if(start.day !== null && start.month !== null){
       //not a mistake, date_range is an array made of arrays in the yaml
      clone_date_range.push(start.day.toString() + '-' + start.month.toString());

      if(end.day !== null && end.month !== null){
        clone_date_range.push(end.day.toString() + '-' + end.month.toString());
      }

      if(start.year && end.year){
        clone_date_range[0] = clone_date_range[0] + '-' + start.year.toString();
        clone_date_range[1] = clone_date_range[1] + '-' + end.year.toString();
      }
    }

    return clone_date_range;
}


function dateRangeToISOYaml(map){
  /*
  	Converts a visual editor date range into yaml format with iso format (i.e. days and months 0 padded)
  */
  	var clone_date_range = [];
    let start = map.date_range.start_date
    let end = map.date_range.end_date

    if(start.day !== null && start.month !== null){

    	//day and month must be 0 padded
    	let sd = start.day.toString();
    	if(sd.length == 1){
    		sd = '0' + sd;
    	}
    	let sm = start.month.toString();
    	if(sm.length == 1){
    		sm = '0' + sm;
    	}
       //not a mistake, date_range is an array made of arrays in the yaml
      clone_date_range.push(sm + '-' + sd);

      if(end.day !== null && end.month !== null){

	    	let ed = end.day.toString();
	    	if(ed.length == 1){
	    		ed = '0' + ed;
	    	}
	    	let em = end.month.toString();
	    	if(em.length == 1){
	    		em = '0' + em;
	    	}

        clone_date_range.push(em + '-' + ed );
      }

      if(start.year && end.year){
        clone_date_range[0] = start.year.toString() + '-' + clone_date_range[0];
        clone_date_range[1] = end.year.toString() + '-' + clone_date_range[1];
      }
    }

    return clone_date_range;
}






function UnitPickupMapsToYamlMaps(map){
  /*
  	Converts a unit pickup visual editor map into a yaml map (i.e. converts the start-end date into yaml format)
  */

  var yMap = Object.assign({}, map);
  delete yMap.date_range;
  yMap['date_range'] = [dateRangeToISOYaml(map)]; //date_range is an array made of arrays in the yaml

  return yMap;
}

export function customerThresholdMapsToYamlMaps(maps){
  /*
  	Converts a customer threshold visual editor map into a yaml map (i.e. converts the start-end date into yaml format)
  */
  var ymaps = [];
  for(var i=0; i<maps.length; i++){
    let map = maps[i];

		let yMap = Object.assign({}, map)
		delete yMap.date_range; //clenup in case date range is empty and thus should not be written into the yaml

		//make sure thresholds are printed as integers in the yaml
		if(yMap.lower_threshold.length == 0){
		  yMap.lower_threshold = null;
		}
		else{
		  yMap.lower_threshold = parseFloat(yMap.lower_threshold);
		}

		if(yMap.upper_threshold.length == 0){
		  yMap.upper_threshold = null;
		}
		else{
		  yMap.upper_threshold = parseFloat(yMap.upper_threshold);
		}
		yMap['date_range'] = [dateRangeToISOYaml(map)];

    ymaps.push(yMap);
  }

  return ymaps;

}


function convertMapToYaml(map, blueprint, mapType) {
 	/*	Fills a yaml-format blueprint map with only the required values from the 
 		argument map
 	*/

 	//TODO use the following line when all maps formats clarified
 	//var blueprint = {}; 
 	if(map.hasOwnProperty('date_range')){
 		blueprint['date_range'] = map['date_range']['value'];
 	}
 	if(map.hasOwnProperty('key')){
 		blueprint['key'] = map['key'];
 	}
 	if(map.hasOwnProperty('value')){
 		blueprint['map'] = map['value'];
 	}
 	if(map.hasOwnProperty('type')){
 		blueprint['type'] = map['type'];
 	}
 	if(mapType == 'far_out_premium_maps'){
 		blueprint = map['value']
 	}
 	if(mapType == 'custom_drop_maps'){
 		blueprint['map'] = map['value']
 	}

 	if(mapType == "custom_drop_maps"){
 		blueprint['type'] = map["type"];
 		blueprint["key"] = "grouped_occupancy_perc_nopr";
 	}

 	if(mapType == 'units_pickup_maps'){
 		blueprint['date_range'] = [blueprint['date_range']];
 	}


 	return blueprint
 } 


export function mapToYamlFormat(map, mapType){
	/*	Converts a standard map (pickup, day of the week etc.) to a prediction
		compatible format to be written into the yaml. The format usually has date
		ranges in form of array without label.	
	*/
	var blueprint = null;
	if(mapType == 'units_pickup_maps'){
		blueprint = UnitPickupMapsToYamlMaps(map);
	}
	else{
		blueprint = getYamlMapTemplate(mapType);
		if(blueprint !== null){
			blueprint = convertMapToYaml(map, blueprint, mapType);
	}

	}


	return blueprint;
}


export function includePropertyTypes(args){


	let mapsArray = args.mapsArray;
	let hashsArray = args.hashsArray;
	let propertyTypesArray = args.propertyTypesArray


	if( mapsArray !== undefined && mapsArray !== null  ){

		for(var j=0; j < mapsArray.length; j++){
			let mapArray = mapsArray[j];

			if(mapArray !== undefined && mapArray !== null){
				for(var i=0; i < mapArray.length; i++){
					let m = mapArray[i];

					if(propertyTypesArray.includes(m.type) == false){
						propertyTypesArray.push(m.type);
					}
				}
			}
		}
	}

	if( hashsArray !== undefined && hashsArray !== null){

		for(var j=0; j < hashsArray.length; j++){
			let hashArray = hashsArray[j];

			if(hashArray !== undefined && hashArray !== null){
				for (const [key, value] of Object.entries(hashArray)) {

		      if(propertyTypesArray.includes(key) == false){
		        propertyTypesArray.push(key);
		      }
				}
			}
		}
	}

  return propertyTypesArray;
}

export function instantiateMaps(parameters){

    if(parameters['dow'] == undefined){
      parameters['dow'] = {'pickup_day_of_week_maps': []};
    }
    if(parameters['dow']['pickup_day_of_week_maps'] == undefined){
      parameters['dow']['pickup_day_of_week_maps'] = [];
    }

    if(parameters['drop'] == undefined){
      parameters['drop'] = {'custom_drop_maps': []};
    }
    if(parameters['drop']['custom_drop_maps'] == undefined){
      parameters['drop']['custom_drop_maps'] = [];
    }

    if(parameters['drop'] == undefined){
      parameters['drop'] = {'far_out_premium_maps':[]};
    }
    if(parameters['drop']['far_out_premium_maps'] == undefined){
      parameters['drop']['far_out_premium_maps'] = [];
    }

    if(parameters['pickup'] == undefined){
      parameters['pickup'] = {'type_pickup_maps':[]};
    }
    if(parameters['pickup']['type_pickup_maps'] == undefined){
      parameters['pickup']['type_pickup_maps'] = [];
    }

	if(parameters['pickup'] == undefined){
		parameters['pickup'] = {'pickup_maps':[]};
	  }
	  if(parameters['pickup']['pickup_maps'] == undefined){
		parameters['pickup']['pickup_maps'] = [];
	  }

    if(parameters['pickup']['units_pickup_maps'] == undefined){
      parameters['pickup']['units_pickup_maps'] = [];
    }   

    if(parameters['cap'] == undefined){
      parameters['cap'] = {};
    }
    if(parameters['cap']['customer_bar_thresholds'] == undefined){
      parameters['cap']['customer_bar_thresholds'] = [];
    } 

  return parameters;
}
