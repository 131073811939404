import type_pickup_mapsTemplate from "@/assets/templates/typePickupMapTemplate.json";
import pickup_mapsTemplate from "@/assets/templates/pickupMapTemplate.json";
import pickup_day_of_week_mapsMapTemplate from "@/assets/templates/dayOfTheWeekMapTemplate.json";
import far_out_premium_mapTemplate from "@/assets/templates/farOutPremiumMapTemplate.json";
import custom_drop_mapsTemplate from "@/assets/templates/customDropMapTemplate.json";
import units_pickup_maps_mapsTemplate from "@/assets/templates/units_pickup_maps.json";

import * as yamlUtils from '@/utils/standardMapsYamlUtils.js'


const yaml = require('js-yaml')


export default {
  namespaced: true,

  state: {

    type_pickup_maps: type_pickup_mapsTemplate,
    pickup_maps: pickup_mapsTemplate,
    pickup_day_of_week_maps: pickup_day_of_week_mapsMapTemplate,
    far_out_premium_maps: far_out_premium_mapTemplate,
    custom_drop_maps: custom_drop_mapsTemplate,
    units_pickup_maps: units_pickup_maps_mapsTemplate,

    allMapTypes: ['type_pickup_maps', 'pickup_maps','pickup_day_of_week_maps', 'far_out_premium_maps', 'custom_drop_maps', 'units_pickup_maps'],
    useMapsSwitchControl: ['type_pickup_maps', 'pickup_maps','units_pickup_maps', 'far_out_premium_maps', 'custom_drop_maps'],

    //used to know when the custom config map has been edited with a visual control but not saved yet
    customConfigMapModified: 0,

    newEditorCode: null,
    newUseMapsState: [
      {
        label: 'pickup',
        yaml_parent: 'pickup',
        yaml_key: 'use_pickup',
      },
      {
        label: 'units pickup',
        yaml_parent: 'pickup',
        yaml_key: 'use_units_pickup',
      },
      {
        label: 'type pickup',
        yaml_parent: 'pickup',
        yaml_key: 'use_type_pickup',
      },
      {
        label: 'far out premium',
        yaml_parent: 'drop',
        yaml_key: 'use_far_out_premium',
      },
      {
        label: 'custom drop maps',
        yaml_parent: 'drop',
        yaml_key: 'use_custom_drop_maps',
      }
    ],

    mapsConfig: {
                  'type_pickup_maps': {'yaml_parent': 'pickup'},
                  'pickup_maps': {'yaml_parent': 'pickup'},
                  'units_pickup_maps': {'yaml_parent': 'pickup'},
                  'far_out_premium_maps': {'yaml_parent': 'drop'},
                  'custom_drop_maps': {'yaml_parent': 'drop'},
                  'pickup_day_of_week_maps': {'yaml_parent': 'dow'},
                },


  },//end of state


  mutations: {
    editStandardMaps(state, args){
      var op = args.operation;
      var index = args.index;
      var mapType = args.mapType;
      var map = null;
      var yamlMap = null;

      var parameters = yaml.load(this.state.editor.codeToRender)

      let mapParent = state.mapsConfig[mapType].yaml_parent;

      if(op == 'delete' && mapParent !== undefined){

        if(mapType == 'far_out_premium_maps'){
            delete parameters.drop.far_out_premium_map //BEWARE: this one is no array, it is a single map
            let mapParentProps = Object.keys(parameters.drop);

            if(Array.isArray(mapParentProps) == false || mapParentProps.length == 0){
              delete parameters.drop //remove map parent if it doesn/t have any other property
            }
        }

        else{
          parameters[mapParent][mapType] = parameters[mapParent][mapType].slice(0,index).concat(parameters[mapParent][mapType].slice(index+1));

          if(parameters[mapParent][mapType].length == 0){
            delete parameters[mapParent][mapType]; //remove empty array if the deleted map was the last
            let mapParentProps = Object.keys(parameters[mapParent]);

            if(Array.isArray(mapParentProps) == false || mapParentProps.length == 0){
              delete parameters[mapParent] //remove map parent if it doesn/t have any other property
            }
          }
        }
      }
      else if (op == 'create'){
        map = args.newMap;
        yamlMap = yamlUtils.mapToYamlFormat(map, mapType);

        if(parameters[mapParent] === undefined){
          parameters[mapParent] = {};
        }
        if(parameters[mapParent][mapType] === undefined && mapType !== "far_out_premium_maps"){
          parameters[mapParent][mapType] = [];
        }

        if(mapType === 'far_out_premium_maps'){
          //parameters[mapParent] = {'far_out_premium_map': yamlMap}; //BEWARE: this one is no array, it is a single map
          parameters[mapParent]["far_out_premium_map"] = yamlMap;
        }
        else{
          parameters[mapParent][mapType].push(yamlMap);
        }

      }

      else if(op == "update"){
        map = args.map
        yamlMap = yamlUtils.mapToYamlFormat(map, mapType);

        if(mapType == 'far_out_premium_maps'){ //this one is a plain object, not a list
          parameters['drop']['far_out_premium_map']  = yamlMap;
        }
        else{
          parameters[mapParent][mapType][index] = yamlMap;
        }

      }

      if(op == "delete"){
        state.customConfigMapModified -=1;
      }
      else{
        state.customConfigMapModified +=1;
      }

      this.state.editor.codeToRender = yaml.dump(parameters, {ref: false});

  },


  }, // end mutations

  actions: {
    editStandardMaps(context, args){
      context.commit('editStandardMaps', args);
    },
    setMapUse(context, args){
      let map = args.map;
      let value = args.value;
      let mapLabel = map.label;

      var parameters = context.rootGetters['editor/codeToRenderObj']

      parameters[map.yaml_parent] = parameters[map.yaml_parent] || {};
      parameters[map.yaml_parent][map.yaml_key] = parameters[map.yaml_parent][map.yaml_key] || null;

      if(value == null){
        delete parameters[map.yaml_parent][map.yaml_key]
        if(Object.keys(parameters[map.yaml_parent]).length === 0){
          delete parameters[map.yaml_parent]
        }
      }
      else{
        parameters[map.yaml_parent][map.yaml_key] = value;
      }

      context.dispatch('editor/writeCodeToRender', parameters, { root: true })
    },

  }, //end of actions
  getters: {
    getUseMapsState(state, getters, rootState, rootGetters){
      let arr = state.newUseMapsState
      const customConfigMap = rootGetters['editor/codeToRenderObj']
      const defaultPredictConfigMap = rootGetters['pipelineV1/predictConfigMap']

      if(!customConfigMap || !defaultPredictConfigMap) return arr

      return arr.map(e => {
        if (!!defaultPredictConfigMap[e.yaml_parent]?.[e.yaml_key]) {
          e.default = defaultPredictConfigMap[e.yaml_parent]?.[e.yaml_key]
        }
        e.value = customConfigMap[e.yaml_parent]?.[e.yaml_key] != null ? customConfigMap[e.yaml_parent]?.[e.yaml_key] : defaultPredictConfigMap[e.yaml_parent]?.[e.yaml_key]
        return e
      })
    }
  }

}
