<template>
  <v-container fluid class="pa-4">
    <v-row>
      <v-col class="col-12">
        <v-autocomplete
          label="Select Market"
          dense
          outlined
          hide-details
          :items="marketList"
          v-model="selectedMarket"
        ></v-autocomplete>
      </v-col>
      <v-col class="col-12" style="height: 100vh;">
        <iframe
          width="100%"
          height="100%"
          :src="reportUrl"
          frameborder="0"
          style="border:0"
          allowfullscreen
          v-if="globalSelectedPgId !== null && selectedMarket"
        ></iframe>
        <h3 class="ma-6" v-else> Select a Property Group and a Market</h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MarketIntelligence',
  data: function () {
    return {
      marketList: [],
      selectedMarket: null,
    }
  },
  computed: {
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId",
    }),
    reportUrl () {
      if (this.globalSelectedPgId == null) {
        return
      }
      const pgId = this.globalSelectedPgId
      const baseUrl = 'https://datastudio.google.com/embed/reporting/e43a657e-4f01-4d56-8235-c4e305ca476f/page/p_uswonkdr0c'
      const params = {
        'ds14.pg_id': pgId,
        'ds16.pg_id': pgId,
        'ds27.pg_id': pgId,
        'ds34.pg_id': pgId,
        'ds33.pg_id': pgId,
        'ds36.pg_id': pgId,
        'ds27.market_name': this.selectedMarket,
        'ds28.market_name': this.selectedMarket,
        'ds23.market_name': this.selectedMarket,
        'ds21.market_name': this.selectedMarket,
        'ds18.market_name': this.selectedMarket,
        'ds0.market_name': this.selectedMarket,
        'ds13.market_name': this.selectedMarket,
        'ds34.market_name': this.selectedMarket,
        'ds33.market_name': this.selectedMarket,
        'ds36.market_name' : this.selectedMarket,
        'ds0.current_year' : new Date().getFullYear(),
        'ds0.last_year' : new Date().getFullYear()-1
      };
      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString)
      return baseUrl + '?params=' + encodedParams
    }
  },
  methods: {
    async fetchMarketList(){
      const api = this.$store.state.api.api

      try {
        const responses = await Promise.all([
          api.get("graph/vpg", `/analyticalmarket`, {}, {}, {}),
          api.get("graph/vpg", `/market`, {}, {}, {})
        ]) 

        responses.forEach(res => {
          if (!!res.error) throw error
        })

        // analytical
        responses[0].items.forEach(mkt => {
          this.marketList.push({
            text: `${String(mkt.metadata.id).replaceAll('_', ' ')} - Analytical`,
            value: `A_${mkt.metadata.id}`
          })
        })

        // normal
        responses[1].items.forEach(mkt => {
          this.marketList.push({
            text: `${String(mkt.metadata.id).replaceAll('_', ' ')} - Market`,
            value: `M_${mkt.metadata.id}`
          })
        })
        
      } catch (error) {
        this.$store.dispatch("alertSystem/newError", {
          message: "Error fetching markets"
        })
      }
    },
  },
  created(){
    this.fetchMarketList()
  },
}
</script>
