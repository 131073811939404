<template>
  <v-card v-if="!chartsLoading" class="card-ui pa-4">

    <v-card-title>Single Property - Single Strategy Prediction</v-card-title>
    <v-row>
      <v-col class="col-3">
        <v-autocomplete
          outlined
          dense
          hide-details
          :items="predictStrategiesDropdown"
          label="Select Strategy"
          v-model="selectedSinglePropertyStrategy"
        ></v-autocomplete>
      </v-col>

      <v-col class="col-3">
        <v-autocomplete
          outlined
          dense
          hide-details
          :items="allowedPropertiesDropdown"
          label="Select property"
          v-model="selectedSinglePropertyProperty"
        ></v-autocomplete>
      </v-col>

      <v-col class="col-6">
        <v-btn 
          class="mr-2" 
          v-for="(featureSet, index) in featureSetListCategories" 
          :outlined="!selectedfeatureSetCat[featureSet]"
          color="primary"
          @click="selectedfeatureSetCat[featureSet] = !selectedfeatureSetCat[featureSet]"
        >
          {{ featureSet }}
        </v-btn>
      </v-col>

      <v-col class="col-12">
        <!-- single-strategy single-property multiple-features chart -->
        <Plotly :data="singlePropertyChart.data" :layout="singlePropertyChart.layout" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>
    
    <v-card-title>Price Weighted Derived Chart</v-card-title>
    <v-row >
      <v-col class="col-3">
        <v-autocomplete
          outlined
          dense
          hide-details
          multiple
          :items="predictStrategiesDropdown"
          label="Select Strategy"
          v-model="selectedPriceWeightedDerivedStrategy"
        ></v-autocomplete>
      </v-col>

      <v-col class="col-3">
        <v-autocomplete
          outlined
          dense
          hide-details
          multiple
          :items="allowedPropertiesDropdown"
          label="Select property" 
          v-model="selectedPriceWeightedDerivedProperty"
        >
          <template v-slot:prepend-item>
            <v-list-item
              ripple
              @mousedown.prevent
              @click="setAllPropertyIdsPriceWeightedDerivedChart()"
            >
              <v-list-item-action>
                <v-icon :color="selectedPriceWeightedDerivedProperty?.length > 0 ? 'white' : ''">
                  {{ selectAllIcon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete> 
      </v-col>
      
      <v-col class="col-12">
        <Plotly :data="priceWeightedDerivedChart.data" :layout="priceWeightedDerivedChart.layout" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-card-title>Price Change Logs</v-card-title>
    <v-row>
      <v-col class="col-3">
        <v-autocomplete
          outlined
          dense
          hide-details
          :items="predictStrategiesDropdown"
          label="Select Strategy"
          v-model="selectedPriceChangeLogsStrategy"
        ></v-autocomplete>
      </v-col>

      <v-col class="col-3">
        <v-autocomplete
          outlined
          dense
          hide-details
          :items="allowedPropertiesDropdown"
          label="Select property"
          v-model="selectedPriceChangeLogsProperty"
        ></v-autocomplete>
      </v-col>

      <v-col class="col-12">
        <Plotly :data="priceChangeLogsChart.data" :layout="priceChangeLogsChart.layout" />
      </v-col>
    </v-row> 

    <!-- <v-divider class="my-4"></v-divider>
    
    <v-card-title>Compset Correction</v-card-title>
    <v-row>
      <v-col class="col-3">
        <v-autocomplete
          outlined
          dense
          hide-details
          :items="predictStrategiesDropdown"
          label="Select Strategy"
          v-model="selectedCompsetCorrectionStrategy"
        ></v-autocomplete>
      </v-col>

      <v-col class="col-3">
        <v-autocomplete
          outlined
          dense
          hide-details
          :items="allowedPropertiesDropdown"
          label="Select property"
          v-model="selectedCompsetCorrectionProperty"
        ></v-autocomplete>
      </v-col>

      <v-col class="col-12">
        <Plotly :data="compsetCorrectionChart.data" :layout="compsetCorrectionChart.layout" />
      </v-col>
    </v-row>  -->
  </v-card>
  <v-card 
    v-else 
    class="card-ui pa-4 d-flex justify-center align-center flex-column" 
    height="300"
    elevation="0"
  >
    <v-progress-circular
      :size="70"
      :width="7"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <p>Loading</p>
  </v-card>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import { Plotly } from 'vue-plotly'
import Vue from "vue"

export default {
  name: 'PredictionChartInLineData',
  components: {
    Plotly,
  },
  data() {
    return {
      chartsLoading: false,

      // feature set for first chart
      selectedfeatureSetCat: {},

      // selected chart strategy and prop
      selectedSinglePropertyStrategy: null,
      selectedSinglePropertyProperty: null,

      selectedPriceWeightedDerivedStrategy: null,
      selectedPriceWeightedDerivedProperty: null,

      selectedPriceChangeLogsStrategy: null,
      selectedPriceChangeLogsProperty: null,

      selectedCompsetCorrectionStrategy: null,
      selectedCompsetCorrectionProperty: null,
    }
  },
  computed: {
    ...mapState({
      darkMode: state => state.ui.darkMode,
      allowedPropertiesDropdown: state => state.predictionCharts.propertiesId,

      compareCols: state => state.predictionCharts.compareCols,

      singlePropertyChartTraces: state => state.predictionCharts.singlePropertyChartTraces,
      priceWeightedDerivedChartTraces: state => state.predictionCharts.priceWeightedDerivedChartTraces,
      justifiersChartTraces: state => state.predictionCharts.justifiersChartTraces,
      compsetCorrectionTraces: state => state.predictionCharts.compsetCorrectionTraces,
    }),
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId",
      featureSetListCategories: 'predictionCharts/featureSetListCategories',
      allPredictPipelineBindsLoaded: "predictionCharts/allPredictPipelineBindsLoaded",

      predictStrategiesDropdown: "pipelineV1/predictStrategiesDropdown",
      allowedPropertiesDropdown: "pipelineV1/allowedPropertiesDropdown",
    }),

    singlePropertyChart() {
      if (!this.singlePropertyChartTraces || !this.selectedSinglePropertyStrategy || !this.selectedSinglePropertyProperty) return {
        layout: this.getChart2YLayout(),
        data: null,
      }

      const tracesGrouped = this.singlePropertyChartTraces?.[this.selectedSinglePropertyStrategy] || null

      const activeFeatureSets = []
      Object.entries(this.selectedfeatureSetCat).forEach(([featureSet, isActive]) => {
        if (isActive) {
          activeFeatureSets.push(featureSet)
        }
      })

      const totalTraces = []

      tracesGrouped?.priceChartTraces?.[this.selectedSinglePropertyProperty]?.forEach(trace => {
        if (!!trace) {
          totalTraces.push(trace)
        }
      })
      
      activeFeatureSets.forEach(activeFeatureSet => {
        const featureSetTraces = tracesGrouped?.featureGroupsTraces?.[this.selectedSinglePropertyProperty]?.[activeFeatureSet]

        featureSetTraces?.forEach(trace => {
          totalTraces.push(trace)
        })
      })
      
      return {
        layout: this.getChart2YLayout(),
        data: totalTraces || [],
      }
    },

    priceWeightedDerivedChart() {
      if (!this.priceWeightedDerivedChartTraces || !this.selectedPriceWeightedDerivedStrategy) return {
        layout: this.getChartLayout('', 'Price'),
        data: null,
      }

      const totalTraces = []
      
      this.selectedPriceWeightedDerivedStrategy?.forEach(strat => {

        this.compareCols?.forEach(col => {

          this.selectedPriceWeightedDerivedProperty?.forEach(prop => {
            const traces = this.priceWeightedDerivedChartTraces[strat]?.traces[col][prop]

            traces?.forEach(trace => {
              totalTraces.push(trace)
            })
          })
        })
      })

      return {
        layout: this.getChartLayout('', 'Price'),
        data: totalTraces || [],
      }
    },

    priceChangeLogsChart() {
      if (!this.justifiersChartTraces || !this.selectedPriceChangeLogsStrategy || !this.selectedPriceChangeLogsProperty) return {
        layout: this.getChart2YLayout(),
        data: null,
      }

      const traces = this.justifiersChartTraces?.[this.selectedPriceChangeLogsStrategy]?.[this.selectedPriceChangeLogsProperty]

      return {
        layout: this.getChart2YLayout(),
        data: traces || [],
      }
    },

    compsetCorrectionChart() {
      if (!this.compsetCorrectionTraces || !this.selectedCompsetCorrectionStrategy || !this.selectedCompsetCorrectionProperty) return {
        layout: this.getChart2YLayout(),
        data: null,
      }

      const traces = this.compsetCorrectionTraces?.[this.selectedCompsetCorrectionStrategy]?.[this.selectedCompsetCorrectionProperty]

      return {
        layout: this.getChart2YLayout(),
        data: traces || null,
      }
    },

    selectAllIcon () {
      let icon = ''
      if (this.allowedPropertiesDropdown?.length === this.selectedPriceWeightedDerivedProperty?.length){
        icon = 'fa-regular fa-square-minus';
      }else{
        icon = 'fa-regular fa-square';
      }
      return icon
    }

  },
  methods: {
    setAllPropertyIdsPriceWeightedDerivedChart () { // allows you to select and unselect all properties to show in the chart
      let allProperties = this.allowedPropertiesDropdown.map(val => val.value)
      if (this.allowedPropertiesDropdown.length === this.selectedPriceWeightedDerivedProperty.length ){
        allProperties = []
      }
      this.selectedPriceWeightedDerivedProperty = allProperties
    },

    getChartLayout(xlab, ylab){
      return {
        showlegend: true,
        margin: { t: 50 },
        hovermode: "closest",
        showgrid: true,
        paper_bgcolor: this.darkMode ? '#221f1c' : '#fff',
        plot_bgcolor: this.darkMode ? '#373432' : '#fff',
        font:{
          color: this.darkMode ? '#Ffffff' : '#333'
        },
        xaxis: {
          showgrid:true,
          gridcolor:'rgba(165, 161, 161, 0.27)',
          title: xlab,
        },
        yaxis: {
          showgrid: true,
          gridcolor:'rgba(165, 161, 161, 0.27)',
          title: ylab,
        },
        legend: {
          "orientation": "h",
          "x" : 0, 
          "y" : -0.5
        }
      }
    },
    getChart2YLayout(){
      return {
        yaxis: {
          title: 'Price',
          showgrid: true,
          gridcolor:'rgba(165, 161, 161, 0.27)',
        },
        margin: { t: 50 },
        yaxis2: {
          title: 'Percent',
          overlaying: 'y',
          side: 'right',
          range:[0, 1.1],
          showgrid: true,
          gridcolor:'rgba(165, 161, 161, 0.27)',
        },
        paper_bgcolor: this.darkMode ? '#221f1c' : '#fff',
        plot_bgcolor: this.darkMode ? '#373432': '#fff',
        font:{
          color: this.darkMode ? '#Ffffff' : '#333',
        },
        xaxis: {
          showgrid:true,
          gridcolor:'rgba(165, 161, 161, 0.27)',
        },
        legend: {
          "orientation": "h",
          "x" : 0, 
          "y" : -0.5
        }
      }
    },

    selectFirstValue(defaultStrat) {
      const firstValProp = this.allowedPropertiesDropdown[0].value

      this.selectedSinglePropertyStrategy = defaultStrat
      this.selectedSinglePropertyProperty = firstValProp
      this.selectedPriceWeightedDerivedStrategy = [defaultStrat]
      this.selectedPriceWeightedDerivedProperty = [firstValProp]
      this.selectedPriceChangeLogsStrategy = defaultStrat
      this.selectedPriceChangeLogsProperty = firstValProp
      this.selectedCompsetCorrectionStrategy = defaultStrat
      this.selectedCompsetCorrectionProperty = firstValProp
    }
  },
  async created(){
    this.featureSetListCategories.forEach(cat => {
      Vue.set(this.selectedfeatureSetCat, cat, false)
    })
    Vue.set(this.selectedfeatureSetCat, this.featureSetListCategories[0], true)

    this.chartsLoading = true
    if (!this.allPredictPipelineBindsLoaded) {
      await this.$store.dispatch("predictionCharts/fetchPredictData")
    }

    await Promise.all([
      this.$store.dispatch("predictionCharts/loadSinglePropertyChartData"),
      this.$store.dispatch("predictionCharts/loadPriceWeightedDerivedChart"),
      this.$store.dispatch("predictionCharts/loadJustifiersChart"),
      //this.$store.dispatch("predictionCharts/loadCompsetCorrectionChart"),
    ])

    const defaultStrat = this.$store.getters["pipelineV1/predictStrategies"].includes(this.$store.state.pipelineV1.selectedPipelineBind?.name) ? this.$store.state.pipelineV1.selectedPipelineBind?.name : this.$store.getters["pipelineV1/predictStrategies"][0]
    this.selectFirstValue(defaultStrat)
    this.chartsLoading = false
  }
}
</script>