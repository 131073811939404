<template>
    <v-container fluid class="pa-4">
        <v-row>
            <v-col class="col col-6">
                <v-autocomplete
                    label="Select user"
                    :items="this.usersNameId"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    v-model="selectedUser"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col col-6">
                <v-autocomplete
                    label="Select Propertygroup"
                    :items="this.propertyGroups"
                    item-text="text"
                    item-value="value"
                    v-model="selectedPropertygroup"
                    outlined
                    dense
                ></v-autocomplete>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="col col-6">
                <v-autocomplete
                    label="Select Partner"
                    :items="this.partners"
                    item-text="text"
                    item-value="value"
                    v-model="selectedPartner"
                    outlined
                    dense
                ></v-autocomplete>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="col col-6">
                <v-btn
                    @click="getVersoriConnectionUrl()"
                    color="primary"

                >
                    Create Versori Connection Url
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-text-field
                outlined 
                v-model="generatedConnectionUrl" 
                persistent-hint
                readonly
                class="pointer"
                :hint="copied ? `copied to clipboard` : ``"
                append-icon="far fa-copy"
                @click="openevent($event)"
                @click:append="copyevent($event)"
            ></v-text-field>
        </v-row>
    </v-container>
</template>

<script>

import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';


export default {
    name: 'Versori',
    data() {
        return {
            selectedPropertygroup : null,
            selectedUser : null,
            selectedPartner : null,

            generatedConnectionUrl: null

        };
    },
    ...mapGetters({
        currentPg: "core/currentPG",
		fetchedUsers: "users/fetchedUsers",
	}),

    methods: {
        async getVersoriConnectionUrl() {
            this.generatedConnectionUrl = await this.$store.dispatch('versori/getVersoriConnectionUrl', {
                propertyGroupId: this.selectedPropertygroup,
                userId: this.selectedUser,
                integrationSub: this.selectedPartner,

            })
        },

        async openevent(event) {
            if (this.generatedConnectionUrl) {
                window.open(this.generatedConnectionUrl, "_blank");
            }
        },
        async copyevent(event){
            await navigator.clipboard.writeText(this.generatedConnectionUrl)
            this.copied = true
            setTimeout(() => this.copied = false, 5000)
        },
    },
    computed: {
        propertyGroups() {
            return this.$store.state.core.propertyGroupsList.map((pg) => {
                return {
                    text: pg.metadata.id + " - " + pg.metadata.name,
                    value: pg.metadata.id,
                };
            });
        },
        usersNameId() {
            return this.$store.state.users.usersList.map((user) => {
                return {
                    text: user.id + " - " + user.username,
                    value: user.id,
                };
            });
        },
        partners() {
            return this.$store.state.core.partnerList.map((partner) => {
                return {
                    text: partner.spec.name,
                    value: partner.metadata.id,
                };
            });
        },
    },

    created() {
        this.$store.dispatch("users/getAllUsers");

    }
}
</script>