<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 col-md-12">
        <v-card>
          <v-card-title>
            Create Stripe Account
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field v-model="vat" :counter="11" :rules="vatRules" label="VAT"></v-text-field>

              <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>

              <v-btn :disabled="!valid" color="success" class="mr-4 mt-2" @click="validate" :loading="loadingData">
                Generate link
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-text>
            Copy the following link: {{ link }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col class="col-12 mb-16">
        <v-card>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="accounts" :search="search">
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src


import Vue from 'vue'

export default Vue.extend({
  name: 'Stripe',
  data: function () {
    return {
      loadingData: false,
      dialog: false,
      link: '',
      valid: false,
      vat: '',
      vatRules: [
        v => (!v || v.length == 11) || 'Vat must be 11 numbers',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      accounts: [],
      search: '',
      headers: [
        { text: 'Name', value: 'name', },
        { text: 'Merchant', value: 'merchant' },
        { text: 'Email', value: 'email' },
        { text: 'Enabled', value: 'enabled'},
      ]
    }
  },
  methods: {
    async generateOnboardingLink() {
      this.loadingData = true
      this.link = await this.$store.dispatch('smartpaying/generateStripeOnboardingLink', { mail: this.email, vat: `IT${this.vat}` })
      this.dialog = true
      this.loadingData = false
    },
    validate() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        this.generateOnboardingLink()
      }
      else {
        console.log("Form is not valid")
      }
    }
  },
  async mounted() {
    this.accounts = await this.$store.dispatch('smartpaying/fetchAccounts')
      
  }
})
</script>

</script>
