<template>
  <v-card outlined class="mx-auto col-10 col-md-10">
    <v-card-title>Create a new Medusa Store</v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <p class="subtitle-1">Customer basic info</p>
          <v-row>
            <v-text-field class="col-12 col-md-4" v-model="newCustomerDto.propertySetId"
              label="All property groups ID (separate with , )"></v-text-field>
            <v-text-field class="col-12 col-md-2" v-model.number="newCustomerDto.name" label="Store name"></v-text-field>
            <v-text-field class="col-12 col-md-2" v-model="newCustomerDto.link" label="Store link"></v-text-field>
            <v-select class="col-12 col-md-4" v-model="newCustomerDto.enabledServices" :items="availableServices"
              label="Select the enabled services" multiple chips item-text="name" item-value="value"></v-select>
          </v-row>
          <p class="subtitle-1">Customer contacts</p>
          <v-row>
            <v-text-field class="col-12 col-md-3" v-model="newCustomerDto.tel" label="Telephone"></v-text-field>
            <v-text-field class="col-12 col-md-3" v-model="newCustomerDto.sms" label="SMS"></v-text-field>
            <v-text-field class="col-12 col-md-3" v-model="newCustomerDto.whats" label="Whatsapp"></v-text-field>
            <v-text-field class="col-12 col-md-3" v-model="newCustomerDto.mail" label="E-Mail"></v-text-field>
          </v-row>
          <p class="subtitle-1">Customer location</p>
          <v-row>
            <v-text-field class="col-12 col-md-4" v-model="newCustomerDto.city" label="City"></v-text-field>
            <v-text-field class="col-12 col-md-4" v-model.number="newCustomerDto.latitude" type="number"
              label="Latitude"></v-text-field>
            <v-text-field class="col-12 col-md-4" v-model.number="newCustomerDto.longitude" type="number"
              label="Longitude"></v-text-field>
          </v-row>
          <p class="subtitle-1">Store customization</p>
          <v-row>
            <v-text-field class="col-12 col-md-4" v-model.number="newCustomerDto.range" type="number"
              label="Max tiqets range"></v-text-field>
            <v-text-field class="col-12 col-md-4" v-model.number="newCustomerDto.tiqetsAmount" type="number"
              label="Max tiqets services"></v-text-field>
          </v-row>
          <v-row>
            <v-color-picker dot-size="18" mode="hexa" swatches-max-height="100"
              v-model="newCustomerDto.color"></v-color-picker>
            <v-btn class="ml-auto col-4 col-md-4" color="success" :loading="loadingData" :disabled="loadingData"
              @click="createStore">Create Medusa Store</v-btn>
          </v-row>
        </v-container>
      </v-form>
      <ConfirmDialog ref="dialog" />
    </v-card-text>
  </v-card>
</template>
<script>
// @ is an alias to /src
import Vue, { ref } from "vue";
import ConfirmDialog from "@/components/utils/ConfirmDialog";
export default Vue.extend({
  name: "MedusaStoreCreator",
  components: { ConfirmDialog },
  data: function () {
    return {
      loadingData: false,
      availableServices: [
        {
          value: "internal",
          name: "Internal Services",
        },
        {
          value: "freedome",
          name: "Freedome",
        },
        {
          value: "tiqets",
          name: "Tiqets",
        },
        {
          value: "billing",
          name: "Billing",
        },
      ],
      newCustomerDto: ref({
        propertySetId: null,
        link: null,
        name: null,
        tel: null,
        sms: null,
        whats: null,
        mail: null,
        enabledServices: [],
        latitude: null,
        longitude: null,
        city: null,
        range: 50,
        tiqetsAmount: 20,
        color: "#0c1965",
      }),
    };
  },
  computed: {},
  methods: {
    async createStore() {
      const psIds = this.newCustomerDto.propertySetId.split(',')
      this.loadingData = true;
      const payload = { ...this.newCustomerDto }
      payload.propertySetId = psIds[0]
      const resp = await this.$store.dispatch("smartpaying/createMedusaStore", {
        newCustomerDto: this.newCustomerDto
      });
      this.loadingData = false;
      if (resp.response?.status == 400) {
        const options = { cardTextClass: "pa-4", color: "orange", width: 800 };
        const title = "Ouch, I cannot create a Store with this config";
        let errorExplanation = "";
        for (const error in resp.response.data) {
          errorExplanation += `${error} - ${JSON.stringify(
            resp.response.data[error]
          )}<br>`;
        }
        const message = `
        <img
        :height="100"
        src="https://media2.giphy.com/media/uIGfoVAK9iU1y/200w.gif?cid=82a1493b1v3e41ng6t0g11cr68kwf975ka2glaga9tefpkui&ep=v1_gifs_related&rid=200w.gif&ct=g"
      ></img>
        <h2>Let me show the errors</h2>
        <p>${errorExplanation}</p>`;
        await this.$refs.dialog.open(title, message, options);
      } else if (resp.response?.status == 422) {
        const options = { cardTextClass: "pa-4", color: "orange", width: 800 };
        const title = "Ouch, I cannot create a Store with this link";
        let errorExplanation = "";
        for (const error in resp.response.data) {
          errorExplanation += `${error} - ${JSON.stringify(
            resp.response.data[error]
          )}<br>`;
        }
        const message = `
        <img
        :height="100"
        src="https://media2.giphy.com/media/uIGfoVAK9iU1y/200w.gif?cid=82a1493b1v3e41ng6t0g11cr68kwf975ka2glaga9tefpkui&ep=v1_gifs_related&rid=200w.gif&ct=g"
      ></img>
        <h2>Let me show the errors</h2>
        <p>The link ${this.newCustomerDto.link} is already taken</p>`;
        await this.$refs.dialog.open(title, message, options);
      } else if (resp.response?.status == 401) {
        const options = { cardTextClass: "pa-4", color: "orange", width: 800 };
        const title = "Your auth session is expired";
        let errorExplanation = "";
        for (const error in resp.response.data) {
          errorExplanation += `${error} - ${JSON.stringify(
            resp.response.data[error]
          )}<br>`;
        }
        const message = `Please refresh the page`;
        await this.$refs.dialog.open(title, message, options);
      } else {
        this.loadingData = true
        for (const psId of psIds) {
          await this.$store.dispatch('smartpaying/setLiveState', { psId: psId, bundle: 'smartupselling' })
        }
        this.loadingData = false
        const options = {
          cardTextClass: "pa-4 accent-1--text",
          color: "success",
        };
        const title = `Store created correctly`;
        const message = `Use the token generator to access the Medusa Admin dashboard`;
        await this.$refs.dialog.open(title, message, options);
      }
    },
  },
});
</script>
