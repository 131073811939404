<template>

    <v-card class="ml-3 mb-1">
      <v-snackbar
        :timeout="messageTimeOut"
        :value= "showMessage"
        y="top"
        x="left"
        tile
        color="red accent-2"
        :multi-line="true"
      >
        {{ message }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="black"
              text
              v-bind="attrs"
              :disabled="!editable"
              @click="showMessage = false"
            >
              Close
            </v-btn>
          </template>
      </v-snackbar>
    <v-row class="mt-1 ml-2">
        <v-col class="col-8">
            <v-row class="mt-1">
                <h3 class="mr-4"> {{field.mapsTitle}}</h3>
            </v-row>
        </v-col>
        <v-col class="col-2 col-md-4 text-right">
          <v-tooltip >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="indigo" 
              class="ml-3 mr-4" 
              x-small 
              dark 
              bottom left fab @click="addMapDateRange(field.mapType)"
              :disabled="!editable"
            >
                <v-icon>fa-solid fa-plus fa-2x</v-icon>
              </v-btn>
            </template>
            <span>Add</span>
          </v-tooltip>
        </v-col>
    </v-row>
    <v-row class="ml-1 mt-3" v-if="typesWithCustomRange.includes(field.mapType)==true">
        <v-row class="ml-1 mt-3 mb-0" v-for="(map, index) in field.maps" :key="index">
            <v-col class="col-12">
                <v-row class="ma-0 pl-0">
                    <v-col class="col-5 ml-0 pl-0" v-if="map.key !== undefined">
                        <v-select
                          label="Key"
                          v-model="map.key"
                          :items="keyLabels"
                          outlined
                          dense
                          :readonly="!editable"
                          v-on:change="updateMap(index, map, field.mapType)" 
                        ></v-select>            
                    </v-col>
                    <v-col class="col-5 mr-4 pr-4"></v-col>
                    <v-col class="col-1 col-md-1 ml-2 mt-3 mr-2">
                        <v-btn
                          class="mx-2"
                          small
                          leftda
                          :disabled="!editable"
                          @click="deleteMap(index, field.mapType)"
                        >
                        <v-icon >fa-solid fa-trash-can</v-icon>
                        </v-btn>
                    </v-col> 

                </v-row>
                <v-row class="ma-0 pt-0">
                    <v-col class="col-11">
                        <v-row class="mt-0 pt-0">
                            <v-col class="col-2 ml-0 pl-1">
                                <h4>From: </h4>
                            </v-col>
                            <v-col class="col-4"></v-col>
                            <v-col class="col-3 ml-0">
                                <h4>To: </h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-2 pr-1 pl-0 mr-0 ml-0"> 
                                <v-text-field
                                  v-model= "map.date_range.start_date.year"
                                  label="Year"
                                  hint="Year"
                                  dense
                                  number
                                  outlined
                                  :readonly="!editable"
                                  v-on:change="updateMap(index, map, field.mapType)" 
                                ></v-text-field>
                            </v-col>
                            <v-col class="col-2 pr-1 pl-0 mr-0 ml-0"> 
                                <v-text-field
                                  v-model= "map.date_range.start_date.month"
                                  label="Month*"
                                  hint="Month*"
                                  dense
                                  number
                                  outlined
                                  :readonly="!editable"
                                  v-on:change="updateMap(index, map, field.mapType)" 
                                ></v-text-field>
                            </v-col>
                            <v-col class="col-2 pr-4 pl-0 mr-0 ml-0"> 
                                <v-text-field
                                  v-model= "map.date_range.start_date.day"
                                  label="Day*"
                                  hint="Day*"
                                  dense
                                  number
                                  outlined
                                  :readonly="!editable"
                                  v-on:change="updateMap(index, map, field.mapType)" 
                                ></v-text-field>
                            </v-col>

                            <v-col class="col-2 pr-1 pl-0 mr-0 ml-0"> 
                                <v-text-field
                                  v-model= "map.date_range.end_date.year"
                                  label="Year"
                                  hint="Year"
                                  dense
                                  number
                                  outlined
                                  :readonly="!editable"
                                  v-on:change="updateMap(index, map, field.mapType)" 
                                ></v-text-field>
                            </v-col>                
                            <v-col class="col-2 pr-1 pl-0 mr-0 ml-0"> 
                                <v-text-field
                                  v-model= "map.date_range.end_date.month"
                                  label="Month*"
                                  hint="Month*"
                                  dense
                                  number
                                  outlined
                                  :readonly="!editable"
                                  v-on:change="updateMap(index, map, field.mapType)" 
                                ></v-text-field>
                            </v-col>
                            <v-col class="col-2 pr-1 pl-0 mr-0 ml-0"> 
                                <v-text-field
                                  v-model= "map.date_range.end_date.day"
                                  label="Day*"
                                  hint="Day*"
                                  dense
                                  number
                                  outlined
                                  :readonly="!editable"
                                  v-on:change="updateMap(index, map, field.mapType)" 
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-row>
                <v-col class="col-11 ml-1 mr-1 mb-4">
                    <v-divider class="mb-4"></v-divider>
                </v-col>
            </v-row>
        </v-row>
    </v-row>

    <v-row class="ml-1" v-for="(map, index) in field.maps" :key="index">
        <v-row v-if="typesWithCustomRange.includes(field.mapType)==false">
            <v-col class="col-3 ml-1"> 
                <v-select 
                  label="Map"
                  v-model="map.value"
                  :items="mapOptions"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                  single-line
                  :readonly="!editable"
                  v-on:change="updateMap(index, map, field.mapType)" 
                ></v-select>
            </v-col>
            <v-col class="col-2" v-if="map.date_range !== undefined">
                <v-select
                  label="Date range"
                  hint="Date range"
                  v-model="map.date_range"
                  :items="dateRanges"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                  return-object
                  single-line
                  :readonly="!editable"
                  v-on:change="updateMap(index, map, field.mapType)" 
                ></v-select>            
            </v-col>

            <v-col class="col-2" v-if="map.key !== undefined">
                <v-select
                  label="Key"
                  v-model="map.key"
                  :items="keyLabels"
                  outlined
                  v-if="rules.mapCheck('key')"
                  dense
                  :readonly="!editable"
                  v-on:change="updateMap(index, map, field.mapType)" 
                ></v-select>            
            </v-col>
            <v-col class="col-2" v-if="map.type !== undefined">
                <v-select
                  label="Type"
                  v-model="map.type"
                  :items="getPropertyTypes"
                  outlined
                  v-if="rules.mapCheck('type')"
                  dense
                  :error-messages="missingTagMappings ? [missingTagMappings] : undefined"
                  :readonly="!editable"
                  v-on:change="updateMap(index, map, field.mapType)" 
                ></v-select>            
            </v-col>            
            <v-col class="col-1 col-md-1">
                <v-btn
                  class="mx-2"
                  small
                  leftda
                  :disabled="!editable"
                  @click="deleteMap(index, field.mapType)"
                >
                <v-icon >fa-solid fa-trash-can</v-icon>
            </v-btn>
            </v-col>
        </v-row>
    </v-row>
    <v-divider class="mt-3 mb-2"></v-divider>
 </v-card>
</template>



<script>
//https://medium.com/cbazil-dev/reusable-components-using-vue-vuetify-fbb46601a83b
import * as dateValidator from '@/utils/datesValidator.js'
const jsYaml = require('js-yaml')


  export default {
    props: ['fields', 'editable'],
    data() {
      return {
        field: this.fields,
        menu: [],
        typesWithRange: ['type_pickup_maps', 'pickup_maps', 'pickup_day_of_week_maps'],
        typesWithCustomRange: ['units_pickup_maps'],
        //keyTypes: ['type_pickup_maps', 'far_out_premium_maps', 'units_pickup_map'],
        keyTypes: ['type_pickup_maps', 'pickup_maps', 'units_pickup_map'],
        roomTypes: ['type_pickup_maps', 'far_out_premium_maps', 'pickup_day_of_week_maps', "custom_drop_maps"],
        toggleMaps: [],
        //customDatesMenus: ['type_pickup_maps', 'units_pickup_maps', 'far_out_premium_maps', 'custom_drop_maps'],
        dateRangesText: [],
        showMessage: false,
        message: "",
        messageTimeOut: -1,
        switch1: false,

        rules: {
            mapCheck: (type) => {
                if (type === "type") {
                    return this.roomTypes.includes(this.field.mapType) ? true : false
                } 
                if (type === "key") {
                    return this.keyTypes.includes(this.field.mapType) ? true : false
                }
            }
        }
      }
    },

    methods: {   
        updateMap(index, value, mapType){
            let update = true;
            var message = "";
            let valid = null;

            if(mapType == 'units_pickup_maps'){
                if(value.date_range !== undefined ){
                    let datesComplete = dateValidator.datesAreCompleted(value.date_range.start_date, value.date_range.end_date);
                    if(datesComplete == true){
                        valid = dateValidator.validateMapDateRanges(this.field.maps); //prevents updating yaml and store if date ranges are not valid

                        if(valid.result == false){
                            message = valid.message;
                            this.message =  message;
                            this.showMessage = true;
                        }
                    }
                    if(datesComplete == false || valid.result == false){
                        update = false;
                    }
                }
            }

            if(update){
                this.showMessage = false;
                this.message =  "";
                var args = {index: index, map: value, mapType: mapType, operation: 'update'};
                this.$store.dispatch('mapsYamlEditor/editStandardMaps', args);
            }
        },

        deleteMap(index, mapType){
            this.showMessage = false;
            this.message =  "";           
            //console.log('Deleted ' + mapType + ' at index ' + index.toString());
            var args = {'index': index, 'mapType': mapType, operation: 'delete'};
            this.$store.dispatch('mapsYamlEditor/editStandardMaps', args);
        },

        addMapDateRange(mapType){
            this.showMessage = false;
            this.message =  "";
            var mapsTemplate = this.field;
            var newMap = null;
            var value = mapsTemplate.options[0].value;    

            if(mapType == 'units_pickup_maps'){ //this one has special needs
                newMap = {'map': value, 'key': mapsTemplate.keys[0]}; //has no labels, make it directly in yaml format
                newMap['date_range'] = {
                                        'start_date':{'day':1, 'month': 1, 'year': null},
                                        'end_date': {'day': 1, 'month': 12, 'year': null}
                                    };
            }
            else{

                var label = mapsTemplate.options[0].label

                newMap = {"label": label, "value": value};

                if(this.keyTypes.includes(mapType)){
                    newMap["key"] = "grouped_occupancy_perc_pr";
                }

                if(this.roomTypes.includes(mapType)){
                    newMap["type"] = "room";
                }

                if(this.typesWithRange.includes(mapType)){
                    let dRange = mapsTemplate.dateRanges[0];
                    newMap["date_range"] = {"label": dRange.label, "value": Object.assign(dRange.value)}
                }

                if(mapType == 'type_pickup_maps'){
                    newMap["key"] = 'grouped_occupancy_perc_nopr';
                }

                if(mapType == 'pickup_maps'){
                    newMap["key"] = 'grouped_occupancy_perc_nopr';
                }

                if(mapType == 'units_pickup_maps'){
                    newMap["key"] = 'occupancy_perc_nopr';
                }
            }
            var args = {mapType: mapType, operation: 'create', 'newMap': newMap};
            this.$store.dispatch('mapsYamlEditor/editStandardMaps', args);

        },

    },//end methods
    computed: {

        keyLabels(){
            var keys = null;
            if(this.fields.keys !== undefined){
                keys = this.fields.keys;
            }
            return keys;
        },
        mapOptions(){
            return this.fields.options;
        },

        dateRanges(){
            var dRanges = [];
            if(this.fields.dateRanges !== undefined){
                dRanges = this.fields.dateRanges;
            }
            return dRanges;
        },
        showKeys(){
            var show = false;
            if(this.fields.keys!== undefined){
                showKeys = true;
            }
            return show;
        },
        getPropertyTypes(){
            return Object.keys(this.$store.getters["editor/codeToRenderObj"]?.global?.room_tag_map || {
                room: "all"
            })
        },
        missingTagMappings() {
            return this.getPropertyTypes.length ? "" : "Missing property tag mappings"
        }
    },
    created() {
        this.$emit("fields", this.field);
    },
    mounted(){
    },

  }
</script>

<style scoped>
</style>
