<template>
  <v-container fluid>
    <v-card class="card-ui">
      <!--<v-sheet
        class=""
        color="black"
        elevation="0"
        v-if="aggregatedData !== undefined"
      >
      <v-sparkline
        :labels="aggregatedData.map((p) => { return p.dateHour.split('T')[1] })"
        :value="aggregatedData.map((p) => { return p.percBad })"
        :gradient="$store.state.dashboard.gradient"
        line-width="2"
        padding="16"
        type="trend"
        class="pa-6"
        stroke-linecap="round"
        :smooth="16"
      >
      </v-sparkline>
      </v-sheet>-->
      <v-card-text>
          <Plotly v-if="chartRows !== null" :data="chartRows"  :display-mode-bar="false" :layout="chartLayout('Pipeline predict')" ></Plotly>
      </v-card-text>
      <v-card-text>
          <v-row class="">
           <v-col class="col-2">
             <v-menu
               v-model="menuDate"
               :close-on-content-click="false"
               :nudge-right="40"
               transition="scale-transition"
               offset-y
             >
               <template v-slot:activator="{ on, attrs }">
                 <v-text-field
                   v-model="selectedDate"
                   label="Log date"
                   readonly
                   dense
                   solo-inverted
                   v-bind="attrs"
                   v-on="on"
                 ></v-text-field>
               </template>
               <v-date-picker
                 v-model="selectedDate"
                 @input="menu2 = false"
                 dark
                 color="blue"
               ></v-date-picker>
             </v-menu>
           </v-col>
         </v-row>
        <v-data-table
          dense
          :headers="aggregatedDataHeaders"
          :items="aggregatedDataReverse"
          :items-per-page="24"
          sort-by="dateHour"
          :sort-desc="true"
          @click:row="handleClick"
          :footer-props="{
            itemsPerPageOptions: [10, 100, 1000, 5000],
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
          }"
          class="elevation-0 card-ui"
          loading-text="Loading... Please wait"
          style="cursor: pointer"
        >
          <template v-slot:item.percBad="{ item }">
            <v-chip
              outlined
              :color="item.percBad > 30 ? 'red accent-3' : 'green accent-4'"
            >
              {{ item.percBad }}
            </v-chip>
          </template>
        </v-data-table>

      </v-card-text>
    </v-card>
    <v-dialog v-model="showInspector">
      <v-card style="min-height: 80vh;" class="card-ui">
        <v-toolbar flat class="app-bar">
          <v-btn icon dark @click="showInspector = false">
            <v-icon class="white--text">mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
            {{itemToShow}}
        </v-card-text>
      </v-card>
    </v-dialog>

      <v-text-field
        v-model="selectedHour"
        label="Consider last n hour (max 48)"
        type="number"
      />
      <v-select
        v-model="selectedLabels"
        :items="labelDropdownItems"
        label="select labels"
        :chips="true"
        :deletable-chips="true"
        multiple
      >
        <template v-slot:prepend-item>
          <v-list-tile
                slot="prepend-item"
                ripple
                @click="onClickSelectAllLabels"
                class="mt-2 ml-4"
              >
          <v-list-tile-action>
            <v-icon class="mr-4" :color="someLabelsSelected ? 'white' : ''">{{ iconSelectedLabels }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title clss="ml-4">Select All</v-list-tile-title>
          </v-list-tile>
          <v-divider
            slot="prepend-item"
            class="mt-2"
          />
        </template>
      </v-select>

      <v-select
        v-model="selectedLifecycle"
        :items="lifecycleDropdownItems"
        label="select lifecycle"
        :chips="true"
        :deletable-chips="true"
        multiple
      >
      </v-select>

      <v-data-table
          dense
          :headers="failureTableHeaders"
          :items="failureTableData"
          sort-by="failureCount"
          :sort-desc="true"
          :footer-props="{
            itemsPerPageOptions: [10, 100, 1000, 5000],
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
          }"
          class="elevation-0 card-ui"
          loading-text="Loading... Please wait"
          style="cursor: pointer"
      >
        <template v-slot:item.percBad="{ item }">
          <v-chip
            outlined
            :color="item.percBad > 30 ? 'red accent-3' : 'green accent-4'"
          >
            {{ item.percBad }}
          </v-chip>
        </template>
    </v-data-table>


  </v-container>
</template>

<script>
import Vue from 'vue'
import { Plotly } from 'vue-plotly'
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'PredictLogs',
  props: [],
  components: {
    Plotly
  },
  data: function () {
    return {
      chartRows: null,
      itemToShow: null,
      showInspector: false,
      aggregatedDataHeaders: [
        {
          text: 'Metric period',
          align: 'start',
          sortable: true,
          value: 'dateHour',
        },
        { text: 'Updated At', value: 'updatedAt', sortable: true },
        { text: 'Bad predict', value: 'bad', sortable: true },
        { text: 'Good predict', value: 'good', sortable: true },
        { text: 'Bad/Total %', value: 'percBad', sortable: true }
      ],
      failureTableHeaders: [
        { text: 'name' , value: 'name' },
        { text: 'propertygroup id', value: 'propertygroupId'},
        { text: 'sp lifecycle',
          value: 'spLifecycle',
          filter: value => {
            if ( this.selectedLifecycle.includes(value) ) {
              return true
            }
            return false
          },
        },
        { text: 'type' ,
          value: 'label',
          filter: value => {
            if ( this.selectedLabels.includes(value) ) {
              return true
            }
            return false
          },
        },
        { text: '# of failures' , value: 'failureCount' },
        { text: 'last failure' , value:  'mostRecentFailure' }
      ],
      selectedDate: (new Date()).toISOString().split('T')[0],
      HOURS_TO_FETCH: 48,
      aggregatedData: [],
      aggregatedDataReverse: [],
      failureTableData: [],
      labelDropdownItems: [
        "master.preprocess.smartpricing",
        "predict",
        "expertuser.master.predict",
        "predict.smartpricing",
        "expertuser.master.preview",
        "preprocess",
        "smartpricing.strategy-generation"
      ],
      lifecycleDropdownItems: [],
      selectedLabels: [
        "predict.smartpricing"
      ],
      selectedLifecycle: [
        "live/default",
        "trial/default",
        "live/retention",
        "trial/retention"
      ],
      selectedHour: 4,
      menuDate: false
    }
  },
  computed: {
      ...mapState({
        api: state => state.api.api,
      }),
      allLabelsSelected () {
        return this.selectedLabels.length === this.labelDropdownItems.length
      },
      someLabelsSelected () {
        return this.selectedLabels.length > 0
      },
      iconSelectedLabels () {
        var icon = 'fa-regular fa-square'
        if ( this.allLabelsSelected ) {
          icon = 'fa-regular fa-square-minus';
        }
        return icon
      },

  },
  watch: {
    selectedDate (newVal, oldVal) {
      this.loadingData = true
      this.fetch()
    },

    async selectedHour (newVal, oldVal) {
      await this.makeFailureData( this.$store.state.log.predictLogs, newVal)
    },

    async '$store.state.log.predictLogs' (newVal, oldVal) {
      this.makeAggregateDataReverse(newVal)
      await this.makeFailureData(newVal, this.selectedHour)
    },

  },
  methods: {
    chartLayout (title) {
      return {
        title: title,
        showlegend: true,
        margin: { t: 50 },
        hovermode: 'closest',
        showgrid: true,
        paper_bgcolor: '#262b2f',
        plot_bgcolor: '#262b2f',
        font:{
          color: '#Ffffff'
        },
        xaxis: {
          showgrid: true,
          gridcolor:'rgba(165, 161, 161, 0.27)',
          title: 'Time'
        },
        yaxis: {
          showgrid: true,
          gridcolor:'rgba(165, 161, 161, 0.27)',
          title: 'Bad/Total %'
        }
      }
    },
    handleClick (item) {
      this.itemToShow = item
      this.showInspector = true
    },
    fetch () {
      let dateArrays = []
      let currentDay = this.selectedDate.split('T')[0] + 'T'

      // if the selected day is equal to today, we want to start downloading from the current hour
      // else, we want to download all the data in that day (we start from the hour 23)
      let remainingHoursInCurrentDay
      if ( currentDay == new Date().toISOString().split('T')[0] + 'T' ) {
        remainingHoursInCurrentDay = new Date().toISOString().split('T')[1].split(":")[0]
      } else {
        remainingHoursInCurrentDay = 23
      }

      let hoursToFetch = this.HOURS_TO_FETCH

      while ( hoursToFetch > 0 ) {
        //console.log(`hourstofetch: ${hoursToFetch} | remainingHoursInCurrentDay: ${remainingHoursInCurrentDay} | currentDay: ${currentDay}`)

        if ( remainingHoursInCurrentDay < 10 ) {
          dateArrays.push(currentDay + '0' + remainingHoursInCurrentDay.toString())
        } else {
          dateArrays.push(currentDay + remainingHoursInCurrentDay.toString())
        }

        remainingHoursInCurrentDay--
        hoursToFetch--

        if ( remainingHoursInCurrentDay == 0 ) {
          //remove T at the end of the string
          currentDay = currentDay.slice(0, currentDay.length-1)
          //find the day before the current day and update current day and remainingHoursInCurrentDay
          let dayBefore = new Date( new Date(currentDay).getTime() );
          dayBefore.setDate( new Date(currentDay).getDate() - 1);
          currentDay = dayBefore.toISOString().split('T')[0] + 'T'
          remainingHoursInCurrentDay = 23
        }
      }
      this.$store.dispatch('log/predictLogs', { dates: dateArrays })
    },
    toISOLocal(d) {
      var z = n => ('0' + n).slice(-2);
      var zz = n => ('00' + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off > 0 ? '-' : '+';
      off = Math.abs(off);

      return d.getFullYear() + '-'
        + z(d.getMonth() + 1) + '-' +
        z(d.getDate()) + 'T' +
        z(d.getHours()) + ':' +
        z(d.getMinutes()) + ':' +
        z(d.getSeconds()) + '.' +
        zz(d.getMilliseconds()) +
        sign + z(off / 60 | 0) + ':' + z(off % 60);
    },
    makeAggregateDataReverse(newVal) {
      this.aggregatedData = newVal.filter(x => x !== null)
      let headers = new Set()
      this.aggregatedData.forEach(x => {
        Object.keys(x).filter(y => y !== 'global').forEach(z => {
          headers.add(z)
        })
      })
      this.chartRows = []
      let chartArray = {}
      let dates = {}
      this.aggregatedData.map(function (d) {
        d.updatedAt = this.toISOLocal(new Date(d.global.updatedAt))
        d.dateHour = d.updatedAt.split(':')[0]
        d.percBad = Math.ceil(d.global.good + d.global.bad == 0 ? 0 : d.global.bad / (d.global.good + d.global.bad) * 100)
        d.bad = d.global.bad
        d.good = d.global.good

        headers.forEach(function (x) {
          if (d[x] !== undefined) {
            d[x+'%'] = Math.ceil(d[x].bad / (d[x].good + d[x].bad) * 100)
          } else {
            d[x+'%'] = 0
          }
          if (chartArray[x] == undefined) {
            chartArray[x] = []
          }
          if (dates[x] == undefined) {
            dates[x] = []
          }

          dates[x].push(d.global.updatedAt)
          chartArray[x].push(d[x+'%'])
        }.bind(this))
      }.bind(this))
      const roundTo = roundTo => x => Math.round(x / roundTo) * roundTo;
      const roundUpTo = roundTo => x => Math.ceil(x / roundTo) * roundTo;
      Object.keys(chartArray).forEach(function (k) {
        this.chartRows.push({
          x: dates[k].map(d => new Date(d)),
          y: chartArray[k],
          type: "bar",
          name: k
        })
      }.bind(this))
      headers.forEach(function (h) {
        this.aggregatedDataHeaders.push(
          { text: h + '%', value: h+'%', sortable: true }
        )
      }.bind(this))
      this.aggregatedDataReverse = JSON.parse(JSON.stringify(this.aggregatedData)).reverse()
    },

    async makeFailureData(data, hoursToConsider=10000) {
      let lifecycleStatusByPsId = {}
      let strategyTophysical = {}

  //       // get psps using lifecycle
  // const resLc = await apiClient.get('lifecycle/v3', '/propertysetstate/latest/smartpricing', {}, {}, {})
  // const enabledPsps = resLc.items
  //   .filter((psp) => psp.fields.predict.price.cron === true)
  //   .map((psp) => psp.propertySetId)
  // console.log(new Date(), `Found ${enabledPsps.length} property set physical with cron enabled`)

  // // get all ps of kind strategy to get parent_physical_propertyset_id
  // const resPss = await apiClient.get('propertyset/v1', '/propertyset/kind/strategy', {}, {}, {})
  // const pspToPss = resPss.items.reduce(function (map, ps) {
  //   map[ps.spec.config.Strategy.parent_physical_propertyset_id] = ps.metadata.id
  //   return map
  // }, {})
  // console.log(new Date(), `Found ${Object.keys(pspToPss).length} property set strategy`)

  // // get property set strategy ids for each property set physical that has cron enabled
  // const psss = enabledPsps.filter((psp) => pspToPss[psp]).map((psp) => pspToPss[psp])
  // console.log(new Date(), `Found ${psss.length} property set strategy with cron enabled`)

      try {
        let res = await this.api.get('lifecycle/v3', "/propertysetstate/latest/smartpricing", {}, {}, {})
        res.items.map(x => {
          lifecycleStatusByPsId[x.propertySetId] = x
        })
      } catch (e) {
        console.log(e)
      }

      try {
        let res = await this.api.get('propertyset/v1', "/propertyset/kind/strategy", {}, {}, {})
        res.items.map(x => {
          strategyTophysical[x.metadata.id] = x.spec.config.Strategy.parent_physical_propertyset_id
        })
      } catch (e) {
        console.log(e)
      }

      data  = data.filter(x => x !== null)
      let result = {}
      let lifecycleSet = new Set()

      for ( let key of Object.keys(data) ) {

        if ( parseInt(key) > hoursToConsider ) {
          continue
        }

        let dataInInterval = data[key]

        for ( let label of Object.keys(dataInInterval) ) {

          let dataInIntervalLabel = dataInInterval[label]
          let failureList = dataInIntervalLabel?.badList || []

          for ( let i = 0 ; i < failureList.length ; i++ ) {
            let pbName = failureList[i]
            if ( result[pbName] == undefined ) {
              let pgId = pbName.split(".")[pbName.split(".").length-1]
              let pgLifecycle = lifecycleStatusByPsId[strategyTophysical[pgId]]?.lifecycleStateName + '/' + lifecycleStatusByPsId[strategyTophysical[pgId]]?.lifecycleStateLabel
              result[pbName] = {
                name: pbName,
                propertygroupId: pgId,
                spLifecycle: pgLifecycle,
                failureCount: 0,
                mostRecentFailure: dataInInterval.dateHour, //convert to timestamp
                label: label
              }

              lifecycleSet.add(pgLifecycle)

            }
            result[pbName].failureCount += 1
            //result[pbName].mostRecentFailure = Math.min(result[pbName].mostRecentFailure, dataInInterval.dateHour)
          }
        }
      }

      this.failureTableData = []

      this.lifecycleDropdownItems = [...lifecycleSet]

      for ( let key of Object.keys(result) ) {
        this.failureTableData.push(result[key])
      }
    },
    onClickSelectAllLabels () {
        if (this.allLabelsSelected) {
          this.selectedLabels = []
        } else {
          this.selectedLabels = this.labelDropdownItems.slice()
        }
      },
  },
  mounted() {
    this.fetch()
  }
})
</script>
