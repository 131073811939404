<template>
  <div>
    <v-row>
      <v-col class="col-10">
        <v-autocomplete 
          style="width:100%" 
          dense 
          flat 
          hide-details
          solo-inverted 
          :value="globalSelectedPsId || null"
          @change="resetData($event)"
          :items="propertySetList"
          item-text="searchValue"
          item-value="id"
          :append-outer-icon="copied ? 'far fa-solid fa-copy' : 'far fa-regular fa-copy'"
          @click:append-outer="copyevent(globalSelectedPsId)"
          key="id"
          placeholder="Property Set"
        >
          
        </v-autocomplete>
      </v-col>
      <v-col class="col-2">
        <v-btn style="width:100%" outlined text  @click="reset">Reset</v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

// @ is an alias to /src

export default {
  name: 'PropertySetGlobalSelector',
  props: [],
  components: {},
  data: function (argument) {
    return {
      menu2: false,
      copied: false,
    }
  },
  computed: {
    ...mapGetters({
      propertySetList: "core/psList",
      globalSelectedPsId: "core/globalSelectedPsId",
    })
    
  },
  methods: {
    ...mapMutations({
      resetSelectedPsData: "core/resetSelectedPsData",
    }),
    async copyevent(psId){
      await navigator.clipboard.writeText(psId)
      this.copied = true
      setTimeout(() => this.copied = false, 500)
    },
    async reset () {
      this.resetSelectedPsData()
      this.$store.state.core.currentPS = null
      this.$store.state.core.currentPSProperties = []
      this.$store.state.core.currentPSPropertyGroups = []
    },
    async resetData(psId){
      if (!!psId) {
        await Promise.all([
          this.$store.dispatch("core/selectGlobalPs", {psId: psId})
        ])
      }
    }
  }
  
}
</script>
