<template>
  <v-container fluid class="pa-4">
    <PGInfoDisplay v-if="!!globalSelectedPgId"></PGInfoDisplay>
    <SelectPgAlert v-else></SelectPgAlert>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import PGInfoDisplay from '../components/PGInfoDisplay.vue'
import SelectPgAlert from '@/components/utils/SelectPgAlert'

export default {
  components: {PGInfoDisplay, SelectPgAlert},
  data: function (argument) {
    return {}
  },
  computed: {
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId",
    })
  }
}


</script>
