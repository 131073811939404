<template>
    <v-container fluid>
        <v-row>
            <v-col class="col-12 col-md-12">
                <v-card>
                    <v-card-title>
                        Create Custom Sender Address
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form-create" v-model="validCreate" lazy-validation>
                            <v-text-field label="Name" type="string" v-model="name" required
                                :rules="formRulesCreate"></v-text-field>
                            <v-text-field label="Email" type="string" v-model="email" required
                                :rules="formRulesCreate"></v-text-field>
                            <v-text-field label="Address" type="string" v-model="address" required
                                :rules="formRulesCreate"></v-text-field>
                            <v-text-field label="City" type="string" v-model="city" required
                                :rules="formRulesCreate"></v-text-field>
                            <v-text-field label="Country" type="string" v-model="country" required
                                :rules="formRulesCreate"></v-text-field>
                            <v-btn :disabled="!validCreate" color="success" class="mr-4 mt-2" @click="validate('create')"
                                :loading="loadingDataCreate">
                                Create a new custom sender address
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12 col-md-12">
                <v-card>
                    <v-card-title>
                        Resend Verification Mail
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form-resend" v-model="validResend" lazy-validation>
                            <v-text-field label="Sender ID" type="number" v-model="senderId" required
                                :rules="formRulesResend"></v-text-field>
                            <v-btn :disabled="!validResend" color="success" class="mr-4 mt-2" @click="validate('resend')"
                                :loading="loadingDataResend">
                                Resend verication mail custom sender
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12 col-md-12">
                <v-card>
                    <v-card-title>
                        Verify Custom Sender Address
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form-verify" v-model="validVerify" lazy-validation>
                            <v-combobox v-model="psIdsVerify" :items="psIdsVerify" label="Property Group IDs" multiple chips
                                @change="psIdsVerifyCheck"></v-combobox>
                            <v-text-field label="Email" type="string" v-model="emailVerify" required
                                :rules="formRulesVerify"></v-text-field>
                            <v-text-field label="Verification Link" type="string" v-model="linkVerify" required
                                :rules="formRulesVerify"></v-text-field>
                            <v-btn :disabled="!validVerify" color="success" class="mr-4 mt-2" @click="validate('verify')"
                                :loading="loadingDataVerify">
                                Verify custom sender address
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-dialog v-model="dialog" max-width="520">
                    <v-card>
                        <v-card-title class="text-h5">
                            Are you sure you want to {{ operationType }} the custom sender address with this data?
                        </v-card-title>
                        <v-card-text>
                            This operation will {{ operationType }} email delivery configuration on SendGrid.
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog = false">
                                Disagree
                            </v-btn>
                            <v-btn color="green darken-1" text @click="senderOperation">
                                Agree
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="alertVisible" max-width="520">
                    <v-card>
                        <v-card-title class="text-h5">
                            {{ alertTitle }}
                        </v-card-title>
                        <v-card-text>
                            {{ alertLog }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green" text @click="alertVisible = false">
                                Ok
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
// @ is an alias to /src


import Vue from 'vue'

export default Vue.extend({
    name: 'CustomSenderAddress',
    data: function () {
        return {
            loadingDataCreate: false,
            loadingDataResend: false,
            loadingDataVerify: false,
            validCreate: true,
            validResend: true,
            validVerify: true,
            formRulesCreate: [
                v => !!v || 'Field id is required'
            ],
            formRulesResend: [
                v => !!v || 'Field id is required'
            ],
            formRulesVerify: [
                v => !!v || 'Field id is required'
            ],
            dialog: false,
            alertTitle: null,
            alertLog: null,
            alertVisible: false,
            name: null,
            email: null,
            address: null,
            city: null,
            country: null,
            psIdsVerify: [],
            emailVerify: null,
            linkVerify: null,
            operationType: null,
            senderId: null
        }
    },
    methods: {
        async createCustomSender() {
            this.loadingDataCreate = true
            try {
                const res = await this.$store.dispatch('smartpaying/createCustomSender', {
                    name: this.name,
                    email: this.email,
                    address: this.address,
                    city: this.city,
                    country: this.country
                })
                if (!res.success) {
                    this.alertTitle = "Something Went Wrong"
                    this.alertLog = `Error message: ${res.message}`
                    this.senderId = res.data.id;
                } else {
                    this.alertTitle = "Operation completed successfully"
                    this.alertLog = `Created a new custom sender with the entered data! Sender ID: [${res.data}] | Status code: ${res.message} `
                }
            } catch (ex) {
                this.alertTitle = "Something Went Wrong"
                this.alertLog = `Error message: ${ex.message}`
            }
            this.alertVisible = true
            this.loadingDataCreate = false
        },
        async verifyCustomSender() {
            this.loadingDataVerify = true
            try {
                const res = await this.$store.dispatch('smartpaying/verifyCustomSender', {
                    psIdsVerify: this.psIdsVerify,
                    emailVerify: this.emailVerify,
                    linkVerify: this.linkVerify
                })
                if (!res.success) {
                    this.alertTitle = "Something Went Wrong"
                    this.alertLog = `Error message: ${res.message} `
                } else {
                    this.alertTitle = "Operation completed successfully"
                    this.alertLog = `Verified custom sender with the entered data! Status code: ${res.message} `
                }
            } catch (ex) {
                this.alertTitle = "Something Went Wrong"
                this.alertLog = `Error message: ${ex.message}`
            }
            this.alertVisible = true
            this.loadingDataVerify = false
        },
        async resendVericationMailCustomSender() {
            this.loadingDataResend = true
            try {
                const res = await this.$store.dispatch('smartpaying/resendVericationMailCustomSender', {
                    senderId: this.senderId
                })
                if (!res.success) {
                    this.alertTitle = "Something Went Wrong"
                    this.alertLog = `Error message: ${res.message} `
                } else {
                    this.alertTitle = "Operation completed successfully"
                    this.alertLog = `Verification email resent to the address associated with the entered ID! Status code: ${res.message} `
                }
            } catch (ex) {
                this.alertTitle = "Something Went Wrong"
                this.alertLog = `Error message: ${ex.message}`
            }
            this.alertVisible = true
            this.loadingDataResend = false
        },
        async validate(operationType) {
            const isValid = this.$refs[`form-${operationType}`].validate()
            if (!isValid) {
                console.log("Form is not valid")
            }
            this.operationType = operationType;
            this.dialog = true
        },
        async senderOperation() {
            this.dialog = false
            switch (this.operationType) {
                case 'create':
                    await this.createCustomSender()
                    break;
                case 'verify':
                    await this.verifyCustomSender()
                    break;
                case 'resend':
                    await this.resendVericationMailCustomSender()
                    break;

            }
        },
        async psIdsVerifyCheck(psIdsVerify) {
            if (!/^\d+$/.test(psIdsVerify[psIdsVerify.length - 1])) {
                psIdsVerify.pop()
            }
        },
    },
    mounted() {
    },
})
</script>
<style lang="scss">
.v-card__text,
.v-card__title {
    word-break: normal;
    /* maybe !important  */
}
</style>
  