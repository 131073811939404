<template>  
      <v-card>   
        <perfect-scrollbar>
            <v-card>
              <prism-editor v-if="$store.state.editor.codeToRender !== null"
              :class="{'dark-editor': darkMode, 'white-editor': !darkMode}"
              v-model="$store.state.editor.codeToRender"
              :highlight="highlighter"
              line-numbers
              :readonly="readonly">      
              </prism-editor>
            </v-card>
        </perfect-scrollbar>
    <v-snackbar
      :timeout="-1"
      :value= "showMessage"
      absolute
      centered
      right
      tile
      color="snackColor"
    >
      {{ messageText }}
    </v-snackbar>
    </v-card>
</template>

<script>
const yaml = require('js-yaml');
var hash = require('object-hash');
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-funky.css'; 
import { mapState } from 'vuex';

export default {
    props: ['code', 'lang', 'readonly'],
    components: {
        PrismEditor,
    },
    data: function () {
        return {
            isEditMode: false,
            paramsBackup: null,
            messageText: null,
            showMessage: false,
            snackColor: null,
            editToolTip: 'Edit YAML',
            overlay: true,
            //originalCode: null,
        }
    },
    methods: {
        highlighter(code) {
            return highlight(code, languages[this.lang || 'yaml']); // languages.<insert language> to return html with markup
        },
    },
    mounted () {
        this.$store.state.editor.codeToRenderLang = this.lang
        if (this.lang == 'yaml') {
            this.$store.state.editor.codeToRender = yaml.dump(this.code)
        } else {
            this.$store.state.editor.codeToRender = JSON.stringify(this.code, undefined, 4)
        }
    },
    beforeMount () {
    },
    watch: {
  
    },
    computed: {
      ...mapState({
        darkMode: state => state.ui.darkMode
      })

    }
}
</script>

<style>
  /* required class */
  .dark-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #161b1f;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  .white-editor {
    background: #fff;
    color: #333;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }

.ps {
      height: 900px;
    }

</style>