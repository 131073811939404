<template>
    <v-card class="card-ui pa-4">
        
        <v-form
            ref="form"
            v-model="validForm"
        >
            <v-row class="pa-4">
                <v-col class="col-4">
                    <v-autocomplete
                        outlined
                        dense                        
                        chips
                        small-chips
                        deletable-chips
                        v-model="marketByLocationQuery"
                        :items="itemsMarketByLocation"
                        hide-details
                        label="Search Markets by Location"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-8">
                    <v-autocomplete
                        outlined
                        dense                        
                        chips
                        small-chips
                        multiple
                        readonly="true"
                        :items="marketsByLocation"
                        v-model="marketsByLocation"
                        hide-details
                        label="Markets"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-12">
                    <v-divider class="my-2"></v-divider>
                </v-col>
            </v-row>
            <v-row class="pa-4">
                <v-col class="col-6">
                    <v-autocomplete
                        v-if="mode === 'edit'"
                        outlined
                        :items="marketsListParsed"
                        dense
                        label="Select Market ID"
                        @change="loadMarket($event)"
                    ></v-autocomplete>
                    <v-text-field
                        v-else
                        outlined
                        :rules="[rules.marketAlreadyExist]"
                        v-model="selectedId"
                        dense
                        label="Market ID"
                    ></v-text-field>
                </v-col>
                <v-col class="col-6">
                    <v-text-field
                        outlined
                        hide-details
                        v-model="selectedLabel"
                        dense
                        label="Market Label"
                        presistent-hint
                        hint="Changing label doesn't change the market id"
                    ></v-text-field>
                </v-col>
                <v-col class="col-12">
                    <v-textarea
                        outlined
                        hide-details
                        v-model="selectedNotes"
                        dense
                        label="Notes"
                        auto-grow
                    ></v-textarea>
                </v-col>
                <v-col class="col-12">
                    <v-divider class="my-2"></v-divider>
                </v-col>
                <v-col class="col-12">
                    <h4>Tag Filter</h4>
                </v-col>
                <v-col class="col-12">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        v-model="selectedGeoTagsFilters"
                        chips
                        small-chips
                        deletable-chips
                        :items="tagOptionsMap['locations']"
                        hide-details
                        label="Geo Tags"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-12">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        v-model="selectedPoiTagsFilters"
                        chips
                        small-chips
                        deletable-chips
                        :items="tagOptionsMap['poi']"
                        hide-details
                        label="Poi Tags"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-12">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        v-model="selectedCategoryTagsFilters"
                        chips
                        small-chips
                        deletable-chips
                        :items="tagOptionsMap['category']"
                        hide-details
                        label="Category Tags"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-12">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        v-model="selectedStarsTagsFilters"
                        chips
                        small-chips
                        deletable-chips
                        :items="tagOptionsMap['stars']"
                        hide-details
                        label="Stars"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-12">
                    <v-data-table
                        :headers="headers"
                        :items="pgsListFiltered"
                        item-key="id"
                        class="elevation-1"
                    ></v-data-table>
                </v-col>
            </v-row>
        </v-form>

        <v-card-actions class="pa-4">
            <v-btn
                color="primary"
                @click="upsertMarket()"
            >{{ mode === "insert" ? 'Insert' : 'Save' }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters, mapState} from "vuex"

export default {
    props: ["mode"],
    data() {
        return {
            // filters
            selectedGeoTagsFilters: [],
            selectedPoiTagsFilters: [],
            selectedCategoryTagsFilters: [],
            selectedStarsTagsFilters: [],
            
            selectedId: "",
            selectedLabel: "",
            selectedNotes: "",

            selectedMarket: null,
            
            // generic
            headers: [
                {text: "ID", value: "id"},
                {text: "Name", value: "name"},
                {text: "Location", value: "locationPretty"},
                {text: "POI", value: "poi"},
                {text: "Category", value: "category"},
                {text: "Stars", value: "stars"},
            ],

            validForm: false,
            rules: {
                marketAlreadyExist: val => !this.marketsIdsList?.includes(val) || "Market already exists change id!"
            },

            marketByLocationQuery: null,
            marketByLocationResult: []
        }
    },
    computed: {
        ...mapState({
            propertyGroupList: state => state.graph.propertyGroupList,
            analyticalMarketsList: state => state.graph.analyticalMarketsList,
            marketsByLocation: state => state.graph.marketsByLocation
        }),
        ...mapGetters({
            tagOptionsMap: "graph/tagOptionsMap",
            pgNameById: "core/pgNameById",
        }),
        marketsIdsList() {
            return this.analyticalMarketsList?.map(mkt => mkt.metadata.id)
        },
        itemsMarketByLocation() {
            // show only locations that are city (they have the four geocoding levels e.g. italy_veneto_verona_garda )
            return this.tagOptionsMap['locations'].filter( (el)=>(el?.value?.split("_") || []).length == 4 )
        },
        marketsListParsed() {
            return this.analyticalMarketsList?.map(mkt => {
                return {
                    text: mkt.metadata.id,
                    value: mkt.metadata.id,
                }
            })
        },
        pgsListParsed() {
            return this.propertyGroupList?.map(val => {
                const location = val.link?.Root?.find(opt => String(opt.kind).includes("Location"))?.id
                return {
                    id: val.metadata.id,
                    name: this.pgNameById[val.metadata.id],
                    locationPretty: location?.replaceAll("_", " - ")?.replaceAll(".", " ") || null,
                    location: location || null,
                    poi: val.link?.Root?.find(opt => String(opt.kind).includes("Poi"))?.id || null,
                    category: val.link?.Root?.find(opt => String(opt.kind).includes("Category"))?.id || null,
                    stars: val.link?.Root?.find(opt => String(opt.kind).includes("Stars"))?.id || null,
                } 
            }) || []
        },
        pgsListFiltered() {
            return this.pgsListParsed.filter(val => {
                const filter1 = this.selectedGeoTagsFilters.some(loc => String(val.location).includes(loc)) || !this.selectedGeoTagsFilters?.length
                const filter2 = this.selectedPoiTagsFilters.includes(val.poi) || !this.selectedPoiTagsFilters?.length
                const filter3 = this.selectedCategoryTagsFilters.includes(val.category) || !this.selectedCategoryTagsFilters?.length
                const filter4 = this.selectedStarsTagsFilters.includes(val.stars) || !this.selectedStarsTagsFilters?.length

                return filter1 && filter2 && filter3 && filter4
            })
        },
    },
    watch: {
        marketByLocationQuery(newVal, oldVal) {
            this.fetchMarketByLocation()
            this.marketByLocationResult = this.marketsByLocation
        },
    },
    methods: {
        async fetchMarketByLocation() {
            const payload = {
                location: this.marketByLocationQuery
            }
            this.$store.dispatch('graph/fetchMarketByLocation', payload)
        },
        async upsertMarket() {
            try {
                this.$refs.form.validate()

                if (!this.validForm) throw "Form not valid"

                let newMarket = null
                if (this.mode === "insert") { //insert
                    newMarket = {
                        kind: "Analyticalmarket",
                        metadata: {
                            
                        },
                        spec: {
                            label: null,
                            configuration: {
                                notes: null,
                                tag_advanced: null,
                            }
                        },
                        link: {
                            ANALYTICALMARKET_PROPERTY: [

                            ]
                        }
                    }
                } else { // update
                    newMarket = structuredClone(this.selectedMarket)
                }

                // populate with filter and basic data
                newMarket.spec.configuration.tag_advanced = {
                    geo: this.selectedGeoTagsFilters,
                    poi: this.selectedPoiTagsFilters,
                    category: this.selectedCategoryTagsFilters,
                    stars: this.selectedStarsTagsFilters,
                }

                newMarket.spec.label = this.selectedLabel
                newMarket.spec.configuration.notes = this.selectedNotes
                newMarket.metadata.id = this.selectedId

                // fetch the neo4j properties of every pg
               
                newMarket.link.ANALYTICALMARKET_PROPERTYGROUP = this.pgsListFiltered.map(pg => {
                    return {
                        "kind": "Propertygroup",
                        "id": pg.id,
                        "value": null
                    }
                })

                this.$store.dispatch("graph/upsertAnalyticsMarket", {
                    market: newMarket,
                })

                this.$store.dispatch("alertSystem/newSuccess", {
                    message: `Market ${this.mode === "insert" ? "created" : "updated"} successfully`
                })
            } catch (error) {
                this.$store.dispatch("alertSystem/newError", {
                    message: `Market ${this.mode === "insert" ? "creation" : "update"} failed: ${error}`
                })
            }
        },
        reset() {
            this.selectedMarket = null
            this.selectedGeoTagsFilters = []
            this.selectedPoiTagsFilters = []
            this.selectedCategoryTagsFilters = []
            this.selectedStarsTagsFilters = []
            this.selectedId = ""
            this.selectedLabel = ""
            this.selectedNotes = ""
        },
        loadMarket(marketId) {
            const selectedMarket = this.analyticalMarketsList.find(mkt => mkt.metadata.id === marketId)
            this.reset()
            this.selectedMarket = selectedMarket
            this.selectedId = selectedMarket?.metadata?.id
            this.selectedLabel = selectedMarket?.spec?.label
            this.selectedNotes = selectedMarket?.spec?.configuration?.notes

            this.selectedGeoTagsFilters = selectedMarket?.spec?.configuration?.tag_advanced.geo
            this.selectedPoiTagsFilters = selectedMarket?.spec?.configuration?.tag_advanced.poi
            this.selectedCategoryTagsFilters = selectedMarket?.spec?.configuration?.tag_advanced.category
            this.selectedStarsTagsFilters = selectedMarket?.spec?.configuration?.tag_advanced.stars
        }
    }
}
</script>