<template>
    <v-container fluid :class="!!currentPG ? 'pa-0' : 'pa-4'">
        <v-card v-if="currentPG" elevation="0">
            <v-tabs v-model="selectedTab">
                <v-tab v-for="tab in tabs" :key="tab">
                    {{ tab }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="selectedTab" class="pa-4">
                <v-tab-item :key="tabs[0]">
                    <InsertRateTab
                        v-if="tabs[selectedTab] === tabs[0]"
                    ></InsertRateTab>
                </v-tab-item>

                <v-tab-item :key="tabs[1]">
                    <EditRateTab
                        v-if="tabs[selectedTab] === tabs[1]"
                    ></EditRateTab>
                </v-tab-item>

                <v-tab-item :key="tabs[2]">
                    <RemoveRateTab
                        v-if="tabs[selectedTab] === tabs[2]"
                    ></RemoveRateTab>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
import InsertRateTab from "../components/InsertRateTab.vue";
import EditRateTab from "../components/EditRateTab.vue";
import RemoveRateTab from "../components/RemoveRateTab.vue";
import SelectPgAlert from "@/components/utils/SelectPgAlert";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    components: { InsertRateTab, EditRateTab, RemoveRateTab, SelectPgAlert },
    data() {
        return {
            tabs: ["Insert New Rate", "Edit Rate", "Remove Rate"],
            selectedTab: null,
        };
    },
    computed: {
        ...mapState({
            currentPG: (state) => state.core.currentPG,
            supportsMappingHashMap: (state) =>
                state.rates.supportsMappingHashMap,
        }),
        ...mapGetters({
            ratesAndPropRatesFetched: "rates/ratesAndPropRatesFetched",
            selectedPgCmMappingSupported: "rates/selectedPgCmMappingSupported",
        }),
    },
    watch: {
        async currentPG(newVal, oldVal) {
            await this.fetchMappings();
        },
    },
    methods: {
        ...mapActions({
            getRates: "rates/getRates",
            getPropertyRates: "rates/getPropertyRates",
            getPropertyRateModifiers: "rates/getPropertyRateModifiers",
            checkMappingSupport: "rates/checkMappingSupport",
            getMappingsFromCm: "rates/getMappingsFromCm",
            getExternalRates: "rates/getExternalRates",
        }),
        async fetchMappings() {
            if (this.selectedPgCmMappingSupported) {
                this.getMappingsFromCm({ pgId: this.currentPG.metadata.id });
            }
        },
        async fetchExternalRates() {
            this.getExternalRates({ pgId: this.currentPG.metadata.id });
        },
    },
    async created() {
        if (!Object.keys(this.supportsMappingHashMap).length) {
            await this.checkMappingSupport();
            await this.fetchMappings();
        }
        if (!this.ratesAndPropRatesFetched) {
            await this.getRates();
            await this.getPropertyRates();
            await this.getPropertyRateModifiers();
            await this.fetchExternalRates();
        }
    },
};
</script>

