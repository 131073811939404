<template>
    <v-container fluid class="pa-0">
        <v-tabs v-model="tab" background-color="transparent">
            <v-tab key="insert">
                Insert
            </v-tab>
            <v-tab key="edit">
                Edit
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item key="insert" class="pa-4">
                <v-card flat>
                    <InsertPartner></InsertPartner>
                </v-card>
            </v-tab-item>

            <v-tab-item key="edit" class="pa-4">
                <v-card flat>
                    <EditPartner ref="editPartnerComponent"></EditPartner>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <v-dialog v-model="showUnsavedChangesDialog" @click:outside="closeWarningDialog()"
            transition="dialog-bottom-transition" max-width="600">
            <v-card>
                <v-toolbar color="red" dark>
                    Unsaved partner schemas
                </v-toolbar>

                <v-card-text class="text-h4 pt-6">
                    You have unsaved partner schemas, are you sure you want to leave this page? You will lose all unsaved
                    edits.
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn color="red"
                        @click="[avoidUnsavedChangesCheck = true, showUnsavedChangesDialog = false, navigate(goToRoute)]">Confirm</v-btn>
                    <v-btn text color="primary" @click="closeWarningDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import InsertPartner from '../components/InsertPartner.vue';
import EditPartner from '../components/EditPartner.vue';

export default {
    components: { InsertPartner, EditPartner },
    data() {
        return {
            tab: null,
            goToRoute: null,
            showUnsavedChangesDialog: false,
            avoidUnsavedChangesCheck: false,
        }
    },
    methods: {
        navigate(goToRoute) {
            this.$router.push(goToRoute)
        },
        undoNavigate() {
            this.$store.commit('ui/setApp', "integrations")
            this.$store.commit('ui/setAppSubresource', "managePartners")
        },
        closeWarningDialog() {
            this.avoidUnsavedChangesCheck = false
            this.showUnsavedChangesDialog = false
            this.undoNavigate()
        }
    },
    beforeRouteLeave(to, from, next) {
        // this.tab: 0 -> insert tab, 1 -> edit tab
        if (this.tab === 1 && this.$refs.editPartnerComponent.selectedPartner !== null && !this.avoidUnsavedChangesCheck) {
            if (this.$refs.editPartnerComponent.hasUnsavedData()) {
                this.showUnsavedChangesDialog = true;
                this.goToRoute = to
            } else {
                next()
            }
        } else {
            next()
        }
    }
}
</script>
