<template>
    <v-container fluid class="pa-4">
        <v-card class="card-ui">
            <v-toolbar dense elevation="0" :class="{ 'grey darken-3': darkMode }">
                <v-card-title class="overline ml-0 pl-0">
                    <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                    <b class="grey--text darken-6">Jobs executed</b>
                </v-card-title>
            </v-toolbar>
            <v-card-text class="px-3 pt-3 pb-5">
                <DataFilters :items="executedJobs" :schema="filters" v-model="filteredJobs" :filtersMenuDefault="true"
                    class="card-ui">
                    <template v-slot:filters-top>
                        <v-row class="pt-2">
                            <v-col class="col-3">
                                <v-switch label="Use Executed Date" v-model="jobsDateFilter" class="mt-0"></v-switch>
                            </v-col>
                            <v-col class="col-3">
                                <v-menu v-model="jobsFromDatePicker">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="jobsFromDate" label="Executed from" type="text" dense
                                            outlined hide-details append-icon="fa-solid fa-calendar" v-bind="attrs"
                                            v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="jobsFromDate" @input="jobsFromDatePicker = false"
                                        @picker-date.sync="jobsFromDate" align="center" color="blue">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-3">
                                <v-menu v-model="jobsToDatePicker">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="jobsToDate" label="Executed to" type="text" dense outlined
                                            hide-details append-icon="fa-solid fa-calendar" v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="jobsToDate" @input="jobsToDatePicker = false"
                                        @picker-date.sync="jobsToDate" align="center" color="blue">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </template>
                </DataFilters>
                <v-data-table :items="executedJobsByTimestamp" :headers="jobsTableHeaders" :items-per-page="10"
                    class="card-ui" style="cursor: pointer;" @click:row="showJobResults" sort-by="created_at"
                    loading-text="Loading executed jobs" sort-desc>
                    <template v-slot:item.created_at="{ item }">
                        {{ formatDate(item.created_at) }}
                    </template>
                    <template v-slot:item.data.success="{ item }">
                        <v-chip :color="item.data.success == true ? 'green' : 'red'" dark small>
                            {{ item.data.success }}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog width="auto" ax-width="1024" v-model="dialogIsOpen">
            <template v-slot:default="dialog">
                <v-card class="card-ui">
                    <v-toolbar dense elevation="0" :class="{ 'grey darken-3': darkMode }">
                        <v-card-title class="overline ml-0 pl-0">
                            <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                            <b class="grey--text darken-6">Job results for job {{ dialogJobSelected.job_id }}</b>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text class="pt-6">
                        <v-data-table :items="executedJobResults" :headers="jobResultsTableHeaders" :items-per-page="10"
                            class="card-ui" loading-text="Loading job results">
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions class="pb-6 px-6 justify-end">
                        <v-btn color="primary" @click="dialog.value = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </v-container>
</template>

<script>

import { mapState } from 'vuex';
import { formatDate } from "@/utils/dateUtils"
import DataFilters from "@/components/utils/DataFilters.vue"

export default {
    components: { DataFilters },
    data() {
        return {
            jobsTableHeaders: [
                { text: "Check kind", value: "check_kind" },
                { text: "Check origin", value: "check_origin" },
                { text: "Candidates found", value: "data.metadata.candidates_num" },
                { text: "Alarms triggered", value: "data.metadata.alarms_num" },
                { text: "Success", value: "data.success" },
                { text: "Message", value: "data.message" },
                { text: "Executed at", value: "created_at" },
            ],
            jobResultsTableHeaders: [
                { text: "Entity key", value: "scope_key" },
                { text: "Entity value", value: "scope_value" },
                { text: "Extra result data", value: "data" },
            ],

            dialogIsOpen: false,
            dialogJobSelected: null,

            filters: [
                { text: "Check kind", value: "check_kind" },
                { text: "Check origin", value: "check_origin" },
            ],
            filteredJobs: [],

            jobsDateFilter: false,
            jobsFromDatePicker: false,
            jobsToDatePicker: false,
            jobsFromDate: null,
            jobsToDate: null,
        }
    },
    computed: {
        ...mapState({
            darkMode: state => state.ui.darkMode,
            executedJobs: state => state.alarms.executedJobs,
            executedJobResults: state => state.alarms.executedJobResults
        }),
        executedJobsByTimestamp() {
            return this.filteredJobs.filter(job => {
                if (!this.jobsDateFilter) {
                    return true
                } else {
                    let fromDateOk = false
                    let toDateOk = false

                    if (this.jobsFromDate === null) {
                        fromDateOk = true
                    } else {
                        const fromDateFilter = this.jobsFromDate.split('T')[0]
                        const jobExecutedAt = job.created_at
                        if (jobExecutedAt >= fromDateFilter) {
                            fromDateOk = true
                        }
                    }
                    if (this.jobsToDate === null) {
                        toDateOk = true
                    } else {
                        const toDateFilter = this.jobsToDate.split('T')[0]
                        const jobExecutedAt = job.created_at
                        if (jobExecutedAt <= toDateFilter) {
                            toDateOk = true
                        }
                    }

                    return fromDateOk && toDateOk
                }
            })
        }
    },
    methods: {
        async showJobResults(job) {
            await this.$store.dispatch("alarms/getExecutedJobResults", job.job_id)

            this.dialogJobSelected = job
            this.dialogIsOpen = true
        },
        formatDate
    },
    async created() {
        await this.$store.dispatch("alarms/getExecutedJobs")
    }
}
</script>

