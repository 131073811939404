<template>
    <v-card class="card-ui">
        <v-toolbar color="primary">Children Status Bulk Edit</v-toolbar>

        <v-row class="ma-4">
            <v-col class="col-12">
                <v-data-table :headers="headers" :items="childrenFormatted" item-key="pgId" disable-pagination
                    hide-default-footer fixed-header height="600">
                    <template v-slot:item.actions="{ item }">
                        <v-autocomplete :items="calculateAllowedStates(item.status)" outlined dense hide-details clearable
                            @change="addToChangeList(item.pgId, $event)"></v-autocomplete>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-card-actions class="justify-space-between">
            <v-btn color="primary" :disabled="!changeList.length" @click="updateStates()" :loading="changingStates">
                Change Status
            </v-btn>
            <v-btn text color="red" @click="$emit('closeDialog')">
                Close
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    data() {
        return {
            changeList: [],
            changingStates: false,

            headers: [
                { text: "Pg Id", value: "pgId" },
                { text: "Name", value: "name" },
                { text: "Current Status", value: "statusName" },
                { text: "Set Status", value: "actions" }
            ]
        }
    },
    computed: {
        ...mapState({
            currentChildren: state => state.core.currentChildren,
            lifecycleStatesList: state => state.core.lifecycleStatesList,
            lifecycleProgressionByBundle: state => state.lifecycle.lifecycleProgressionByBundle,
        }),
        ...mapGetters({
            propertyGroupsDataById: "core/propertyGroupsDataById",
            lifecycleStatusByPgId: "core/lifecycleStatusByPgId",
        }),
        childrenFormatted() {
            return this.currentChildren.map(val => {
                return {
                    pgId: val,
                    name: this.propertyGroupsDataById[val].metadata.name,
                    statusName: this.lifecycleStatusByPgId[val]?.lifecycleStateName + '/' + this.lifecycleStatusByPgId[val]?.lifecycleStateLabel,
                    status: this.lifecycleStatusByPgId[val],
                }
            })
        },
    },
    methods: {
        ...mapActions({
            updatePgState: "core/updatePgState",
        }),
        calculateAllowedStates(currentLifecycleStatus) {
            let allowedStates = []
            if (!currentLifecycleStatus) {
                allowedStates = this.lifecycleStatesList.map((s) => { return s.metadata.name + '/' + s.metadata.label })
            } else {
                allowedStates = this.lifecycleStatesList
                    .filter((s) => { return currentLifecycleStatus.childs.includes(s.metadata.name) })
                    .map((s) => { return s.metadata.name + '/' + s.metadata.label })
                    .filter((s) => { return s !== (currentLifecycleStatus.lifecycleStateName + '/' + currentLifecycleStatus.lifecycleStateLabel) })
            }

            if (this.lifecycleProgressionByBundle['smartpricing']) {
                allowedStates = allowedStates.sort((ls1, ls2) => {
                    const ls1name = ls1.split('/')[0]
                    const ls2name = ls2.split('/')[0]

                    const ls1index = this.lifecycleProgressionByBundle['smartpricing'].indexOf(ls1name)
                    const ls2index = this.lifecycleProgressionByBundle['smartpricing'].indexOf(ls2name)

                    if (ls1index === -1 && ls2index === -1) return 0
                    if (ls1index === -1) return 1
                    if (ls2index === -1) return -1

                    return ls1index - ls2index
                })
            }

            return allowedStates
        },
        addToChangeList(pgId, newStatus) {
            const index = this.changeList.findIndex(val => val.pgId === pgId)

            if (index !== -1) { // present
                if (newStatus === null) {
                    this.changeList.splice(index, 1)
                } else {
                    this.changeList.splice(index, 1, {
                        newStatus: newStatus,
                        pgId: pgId,
                    })
                }
            } else { // not present
                this.changeList.push({
                    newStatus: newStatus,
                    pgId: pgId,
                })
            }
        },
        async updateStates() {
            this.changingStates = true
            await Promise.all(this.changeList.map(async (pgChange) => {
                let newState = {
                    metadata: {
                        propertyGroupId: pgChange.pgId,
                        name: pgChange.newStatus.split('/')[0],
                        label: pgChange.newStatus.split('/')[1],
                        setBy: this.$store.state.auth.username,
                        lifecycleStateId: this.lifecycleStatesList.find(val => {
                            return val.metadata.label === pgChange.newStatus.split('/')[1] && val.metadata.name === pgChange.newStatus.split('/')[0]
                        }).metadata.id
                    }
                }

                await this.updatePgState({
                    newState: newState
                })
            }))

            this.changeList = []
            this.changingStates = false
        }
    },
}
</script>
