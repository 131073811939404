<template>
    <v-container fluid class="pa-4">
        <v-row v-if="!!currentPG">
            <v-col class="col-12">
                <GeocodingEditor
                    v-model="selectedPgData"
                ></GeocodingEditor>
            </v-col>
            <v-col class="col-12">
                <EventSource
                    v-model="selectedPgData"
                ></EventSource>
            </v-col>
        </v-row>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
import GeocodingEditor from '../components/GeocodingEditor.vue';
import EventSource from '../components/EventSource.vue';
import SelectPgAlert from '@/components/utils/SelectPgAlert'
import {mapState} from "vuex"

export default {
    components: {
        GeocodingEditor,
        EventSource,
        SelectPgAlert,
    },
    data() {
        return {
            selectedPgData: null,
        }
    },
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG
        })
    },
    watch: {
        currentPG: {
            deep: true,
            handler: async function (newVal, oldVal) {
                if (!!newVal) {
                    this.selectedPgData = await this.fetchPgFromGraph()
                }
            }
        }
    },
    methods: {
        async fetchPgFromGraph () {
            const api = this.$store.state.api.api

            try {
                const pgId = this.$store.state.core.currentPG.metadata.id
                const res = await api.get('graph/vpg', `/propertygroup/${encodeURIComponent(pgId)}`, {}, {}, {})
                return res.items[0]
            } catch (error) {
                return null
            }
        },
    },
    async created() {
        this.$store.dispatch("graph/prefetch", {})
        if (!!this.currentPG) {
            this.selectedPgData = await this.fetchPgFromGraph()
        }
    }
}
</script>