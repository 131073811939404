<template>
    <v-card>
        <v-card-title>
            Base Price By Date Range
        </v-card-title>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-autocomplete
                v-model="selectedProperty"
                dense
                hide-details
                label="Select property"
                :items="propertiesDropdown"
                outlined
            >
                <template v-slot:item="data">
                    <span :class="data.item.color">
                        {{ data.item.text }}
                    </span>
                </template>
            </v-autocomplete>
        </v-card-actions>

        <v-data-table
            :headers="headers"
            :items="selectedPropertyValues"
            :item-class="item => item.days < 7 && item.startDate > new Date().toISOString().split('T') && !!minSpan7Days ? 'red--text' : ''"
            item-key="id"
            loading-text="Select a property"
        ></v-data-table>
    </v-card>
</template>

<script>
import dateUtils from "@/utils/dateUtils"

export default {
    props: {
        items: {
            type: Object,
        },
        propertyMappings: {
            type: Object,
        },
        minSpan7Days: {
            type: Boolean
        },
    },
    data() {
        return {
            headers: [
                { text: 'From', value: 'startDate' },
                { text: 'To', value: 'endDate' },
                { text: 'Base Price', value: 'price' },
                { text: 'Days', value: 'days'}
            ],

            selectedProperty: null,
        }
    },
    watch: {
        propertiesWithInvalidDateRanges: {
            deep: true,
            handler: function(newVal, oldVal) {
                this.$emit("input", newVal)
            }
        }
    },
    computed: {
        propertiesDropdown() {
            if (!this?.items) return null

            return Object.keys(this?.items)?.map(propId => {
                return {
                    text: `${propId} - ${this.propertyMappings?.[propId]}` || propId, 
                    value: propId,
                    color: this.propertiesWithInvalidDateRanges.includes(propId) ? "red--text" : undefined
                }
            }) || null
        },
        dateRangeValuesByProperty() {
            if (!this.items) return null

            const dateRangeValuesByProperty = {}
            for (const [propId, propValues] of Object.entries(this.items)) {

                if (propValues.dates?.length && propValues.values?.length) {

                    const dates = propValues.dates.map(date => new Date(date))
                    const prices = propValues.values.map(value => Math.round(value))
    
                    const groupedPrices = [];
                    let startDate = dates[0];
                    let currentPrice = prices[0];
    
                    for (let i = 1; i < dates?.length; i++) {
                        if (prices[i] !== currentPrice) {
                        if (dates[i - 1].getTime() === startDate.getTime()) {
                            groupedPrices.push({
                            startDate: startDate.toISOString().split('T')[0],
                            endDate: startDate.toISOString().split('T')[0],
                            price: currentPrice,
                            days: dateUtils.daysDifference(startDate, startDate, 1)
                            });
                        } else {
                            groupedPrices.push({
                            startDate: startDate.toISOString().split('T')[0],
                            endDate: dates[i - 1].toISOString().split('T')[0],
                            price: currentPrice,
                            days: dateUtils.daysDifference(startDate, dates[i - 1], 1)
                            });
                        }
                        startDate = dates[i];
                        currentPrice = prices[i];
                        }
                    }
    
                    // Add the last group
                    if (dates[dates.length - 1].getTime() === startDate.getTime()) {
                        groupedPrices.push({
                        startDate: startDate.toISOString().split('T')[0],
                        endDate: startDate.toISOString().split('T')[0],
                        price: currentPrice,
                        days: dateUtils.daysDifference(startDate, startDate, 1)
                        });
                    } else {
                        groupedPrices.push({
                        startDate: startDate.toISOString().split('T')[0],
                        endDate: dates[dates?.length - 1].toISOString().split('T')[0],
                        price: currentPrice,
                        days: dateUtils.daysDifference(startDate, dates[dates?.length - 1], 1)
                        });
                    }
    
    
                    dateRangeValuesByProperty[propId] = groupedPrices.map(value => {
                        const newValue = value
                        if (newValue.price !== null) {
                            newValue.price = newValue.price
                        }
                        return newValue
                    })
                }
            }

            return dateRangeValuesByProperty
        },
        propertiesWithInvalidDateRanges() {
            if (!this.dateRangeValuesByProperty) return null

            const propertiesList = []
            if (this.minSpan7Days) {
                const today = new Date().toISOString().split("T")
    
                Object.entries(this.dateRangeValuesByProperty).forEach(([propId, values]) => {
                    if (values.some(val => val.days < 7 && val.startDate > today )){
                        propertiesList.push(propId)
                    }
                })
            }

            return propertiesList
        },
        selectedPropertyValues() {
            const result = this.dateRangeValuesByProperty?.[this.selectedProperty]?.map(propVal => {
                const newVal = propVal
                newVal.id = JSON.stringify(newVal)
                return newVal
            })
            return !!result?.length ? result : []
        }
    }
}
</script>