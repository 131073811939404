<template>
  <v-card class="card-ui pa-4">
    <v-card-title>{{ selectedPropertyPrettyName }}</v-card-title>

    <v-card-text class="pa-0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="col-6">
            <v-autocomplete
              v-if="selectedPgCmMappingSupported && !!cmIdsDropdownNew.length"
              outlined
              dense
              :items="cmIdsDropdownNew"
              item-text="text"
              item-value="value"
              clearable
              label="Channel Manager ID"
              v-model="selectedRoomsOnWritingSys"
              :return-object="false"
            >
              <template v-slot:selection="{ item }">
                {{ item?.text }}
                <v-chip color="success" v-if="item.inDatabase" small
                  >In CM</v-chip
                >
                <v-chip v-else color="error" small>Not in CM</v-chip>
              </template>
              <template v-slot:item="{ item }">
                {{ item?.text }}
                <v-chip color="success" v-if="item.inDatabase" small
                  >In CM</v-chip
                >
                <v-chip v-else color="error" small>Not in CM</v-chip>
              </template>
            </v-autocomplete>
            <v-text-field
              v-else
              outlined
              dense
              persistent-hint
              :rules="[rules.noTrailingSpaces]"
              :hint="hintTextRoomsOnWritingSys"
              clearable
              label="Channel Manager ID"
              v-model="selectedRoomsOnWritingSys"
            ></v-text-field>
          </v-col>
          <v-col class="col-2">
            <v-text-field
              outlined
              dense
              type="number"
              :rules="[rules.isNumber, rules.intAboveOrEqualZero]"
              :disabled="isSelectedOffsetDisabled"
              label="To Offset"
              v-model="selectedOffset"
            ></v-text-field>
          </v-col>
          <v-col class="col-4">
            <v-autocomplete
              outlined
              dense
              :items="priceStrategySource"
              item-text="text"
              item-value="value"
              label="Graph JSON"
              v-model="selectedPriceStratSource"
              @change="handlePriceStratSourceChange"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="selectedPriceStratSource?.includes('IndexedPrice')">
          <v-col class="col col-6">
            <v-autocomplete
              label="Variation Type"
              outlined
              dense
              :items="[
                { text: 'Percentage', value: 'percentage' },
                { text: 'Amount', value: 'amount' },
              ]"
              :rules="[rules.required]"
              v-model="selectedPropModVarType"
              @change="selectedPropModAmount = 0"
            ></v-autocomplete>
          </v-col>
          <v-col class="col col-6">
            <v-text-field
              label="Variation Amount"
              :suffix="selectedPropModVarType === 'percentage' ? '%' : ''"
              outlined
              dense
              :value="rawValueToNormal(selectedPropModAmount)"
              @change="selectedPropModAmount = normalValueToRaw($event)"
            ></v-text-field>
          </v-col>
          <v-col class="col col-6">
            <v-autocomplete
              label="Select a property group"
              outlined
              dense
              :items="pgsDropdown"
              v-model="selectedPropModPropGroup"
              @change="fetchPropertiesDropdownPropModifiers($event)"
            ></v-autocomplete>
          </v-col>
          <v-col class="col col-6">
            <v-autocomplete
              label="Source Property Id"
              outlined
              dense
              :items="propModifierProperties"
              :rules="[rules.required]"
              v-model="selectedPropModSourceProp"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-autocomplete
          v-if="selectedPgCmRatesSupported && !!rateMasterDropdownNew.length"
          outlined
          dense
          :items="rateMasterDropdownNew"
          item-text="text"
          item-value="value"
          clearable
          label="Channel Manager Rate ID"
          v-model="selectedRateMaster"
        >
          <template v-slot:selection="{ item }">
            {{ item?.text }}
            <v-chip color="success" v-if="item.inDatabase" small>In CM</v-chip>
            <v-chip v-else color="error" small>Not in CM</v-chip>
          </template>
          <template v-slot:item="{ item }">
            {{ item?.text }}
            <v-chip color="success" v-if="item.inDatabase" small>In CM</v-chip>
            <v-chip v-else color="error" small>Not in CM</v-chip>
          </template>
        </v-autocomplete>
        <v-text-field
          v-else
          outlined
          dense
          persistent-hint
          :rules="[rules.noTrailingSpaces]"
          :hint="hintTextRateMaster"
          clearable
          label="Channel Manager Rate ID"
          v-model="selectedRateMaster"
        ></v-text-field>
        <v-card-title>Writing on Channel Manager Rate ID</v-card-title>
        <v-row>
          <v-col class="col col-3">
            <v-autocomplete
              outlined
              dense
              clearable
              :items="boardTypeList"
              label="Board Type"
              v-model="selectedBoardType"
            ></v-autocomplete>
          </v-col>
          <v-col class="col col-3">
            <v-text-field
              outlined
              dense
              clearable
              type="number"
              :rules="[rules.isNumber, rules.intAboveOrEqualZero]"
              label="Rate Occupancy"
              v-model="selectedRateOccupancy"
            ></v-text-field>
          </v-col>
          <v-col class="col col-3">
            <v-text-field
              outlined
              dense
              clearable
              type="number"
              :rules="[rules.isNumber, rules.intAboveOrEqualZero]"
              label="Min Occupancy"
              v-model="selectedMinOccupancy"
            ></v-text-field>
          </v-col>
          <v-col class="col col-3">
            <v-text-field
              outlined
              dense
              clearable
              type="number"
              :rules="[rules.isNumber, rules.intAboveOrEqualZero]"
              label="Max Occupancy"
              v-model="selectedMaxOccupancy"
            ></v-text-field>
          </v-col>
          <v-col class="col col-6">
            <v-autocomplete
              outlined
              dense
              :items="pgsDropdown"
              label="Select a property group"
              v-model="selectedParentIdPropertyGroup"
              @change="fetchPropertiesDropdownParentId($event)"
            ></v-autocomplete>
          </v-col>
          <v-col class="col col-6">
            <v-autocomplete
              outlined
              :items="parentIdProperties"
              dense
              label="Parent ID"
              v-model="selectedParentId"
            ></v-autocomplete>
          </v-col>
          <v-col class="col col-6">
            <v-textarea
              outlined
              dense
              label="Extra params"
              :rules="[rules.isJSON]"
              v-model="selectedExtraParams"
            ></v-textarea>
          </v-col>
          <v-col class="col col-6">
            <NewDynamicInputForm
              v-model="selectedExtraParams"
              :customSchema="
                !!cmPropertyExtraParamsSchema
                  ? cmPropertyExtraParamsSchema
                  : undefined
              "
            >
            </NewDynamicInputForm>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions class="pa-0">
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="confirmDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" @click="validateForm()"> Submit </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark>
              Confirm submitting mapping to CM
            </v-toolbar>

            <v-card-text class="pt-14">
              Confirm you want to submit the following mapping for property
              {{ selectedPropertyPrettyName }}
            </v-card-text>

            <v-card-actions class="justify-end pa-4">
              <v-btn
                color="primary"
                @click="submitWrite"
                v-on:click="dialog.value = false"
                >Confirm</v-btn
              >
              <v-btn text color="red" v-on:click="dialog.value = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import NewDynamicInputForm from "../../../components/newDynamicForm/NewDynamicInputForm.vue";

export default {
  components: { NewDynamicInputForm },
  data() {
    return {
      selectedRoomsOnWritingSys: "",
      selectedPriceStratSource: null,
      originalSelectedPriceStratSource: null,
      selectedRateMaster: "",
      selectedBoardType: "",
      selectedRateOccupancy: null,
      selectedMinOccupancy: null,
      selectedMaxOccupancy: null,
      selectedOffset: null,
      isSelectedOffsetDisabled: false,
      selectedExtraParams: "",

      selectedPropModVarType: "",
      selectedPropModAmount: "",
      selectedPropModPropGroup: "",
      propModifierProperties: [],
      selectedPropModSourceProp: "",

      parentIdProperties: [],
      selectedParentIdPropertyGroup: "",
      selectedParentId: "",

      rules: {
        required: (value) => !!value || "Required!",
        isNumber: (value) =>
          !isNaN(Number(value)) || "Value has to be a number!",
        intAboveOrEqualZero: (value) =>
          Number(value) >= 0 || "Value has to be above or equal zero!",
        isJSON: (value) =>
          this.isValidJSON(value) || "Value must be valid json",
        noTrailingSpaces: (value) => {
          // characters from sourced from https://www.emptycharacter.com/
          const forbiddenTextCharacters = [
            " ",
            "ㅤ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            " ",
            "　",
          ];
          for (const char of forbiddenTextCharacters) {
            if (value && (value.endsWith(char) || value.startsWith(char))) {
              return "No trailing spaces or other blank characters allowed, remove them!";
            }
          }
          return true;
        },
      },
      valid: true,
      confirmDialog: false,
    };
  },
  watch: {
    async selectedProperty(newVal, oldVal) {
      await this.defaultState();
    },
    async selectedPropertyModifier(newVal, oldVal) {
      await this.defaultState();
    },
    selectedPriceStratSource(newVal, oldVal) {
      this.selectedPropModVarType = "";
      this.selectedPropModAmount = "";
      this.selectedPropModPropGroup = "";
      this.selectedPropModSourceProp = "";
    },
  },
  computed: {
    ...mapState({
      currentProperties: (state) => state.core.currentProperties,
      priceStrategySource: (state) => state.mappingAndRates.priceStrategySource,
      currentCmId: (state) => state.core.currentPG.spec.channel_manager_name,
      boardTypeList: (state) => state.mappingAndRates.boardTypeList,
      selectedProperty: (state) => state.mappingAndRates.selectedProperty,
      selectedPropertyModifier: (state) =>
        state.mappingAndRates.selectedPropertyModifier,
      currentPG: (state) => state.core.currentPG,
    }),
    ...mapGetters({
      cmIdsDropdown: "mappingAndRates/cmIdsDropdown",
      rateMasterDropdown: "mappingAndRates/rateMasterDropdown",
      selectedPgCmMappingSupported:
        "mappingAndRates/selectedPgCmMappingSupported",
      selectedPgCmRatesSupported: "mappingAndRates/selectedPgCmRatesSupported",
      selectedPropertyPrettyName: "mappingAndRates/selectedPropertyPrettyName",
      selectedPropertyId: "mappingAndRates/selectedPropertyId",
      cmPropertyExtraParamsSchema: "core/cmPropertyExtraParamsSchema",
      pgsDropdown: "core/pgsDropdown",
    }),
    cmIdsDropdownNew() {
      const copy = structuredClone(this.cmIdsDropdown);

      const index = copy.findIndex(
        (val) => val.value === this.selectedRoomsOnWritingSys
      );
      if (index === -1) {
        copy.push({
          text: this.selectedRoomsOnWritingSys,
          value: this.selectedRoomsOnWritingSys,
          inDatabase: false,
        });
      }

      return copy;
    },
    rateMasterDropdownNew() {
      const copy = structuredClone(this.rateMasterDropdown);

      const index = copy.findIndex(
        (val) => val.value === this.selectedRateMaster
      );
      if (index === -1) {
        copy.push({
          text: this.selectedRateMaster,
          value: this.selectedRateMaster,
          inDatabase: false,
        });
      }

      return copy;
    },
    hintTextRoomsOnWritingSys() {
      return this.selectedPgCmMappingSupported
        ? ""
        : `Automapping failed or not supported, write down the room ID on your own!`;
    },
    hintTextRateMaster() {
      return this.selectedPgCmRatesSupported
        ? ""
        : `Automapping failed or not supported, write down the rate ID on your own!`;
    },
    newPropertyMapping() {
      return {
        metadata: {
          //name: this.selectedProperty.metadata.name,
          id: this.selectedPropertyId,
        },
        spec: {
          channel_manager_id: this.selectedRoomsOnWritingSys,
          graph_json:
            this.selectedPriceStratSource !== null
              ? this.selectedPriceStratSource.split(",")
              : null,
          channel_manager_rate_id: this.selectedRateMaster,
          parent_id: this.selectedParentId,
          extra_params: JSON.parse(this.selectedExtraParams),
          configuration_params: {
            board_type: this.selectedBoardType,
            rate_occupancy:
              this.selectedRateOccupancy !== null &&
              this.selectedRateOccupancy !== ""
                ? Number(this.selectedRateOccupancy)
                : null,
            min_occupancy:
              this.selectedMinOccupancy !== null &&
              this.selectedMinOccupancy !== ""
                ? Number(this.selectedMinOccupancy)
                : null,
            max_occupancy:
              this.selectedMaxOccupancy !== null &&
              this.selectedMaxOccupancy !== ""
                ? Number(this.selectedMaxOccupancy)
                : null,
          },
          property_group_id: this.selectedProperty.spec.property_group_id,
          set_price_to_offset: this.selectedOffset,
        },
      };
    },
    newPropertyModifier() {
      const pgName =
        this.$store.getters["core/propertyGroupsDataById"][
          this.selectedProperty.spec.property_group_id
        ].metadata.name;

      return {
        metadata: {},
        spec: {
          property_id: this.selectedProperty.metadata.id,
          name: this.toKebabCase(
            `${this.selectedPropModVarType}-${pgName}-${this.selectedProperty.metadata.name}`
          ),
          modifier_id: 40,
          variation_params: {
            variations: {
              "*": {
                variation_type: this.selectedPropModVarType,
                variation_value: Number(this.selectedPropModAmount),
                source_property_id: Number(this.selectedPropModSourceProp),
              },
            },
          },
          date: null,
        },
      };
    },
  },
  methods: {
    ...mapMutations({
      resetSelectedProperty: "mappingAndRates/resetSelectedProperty",
      updateSelectedProperty: "mappingAndRates/updateSelectedProperty",
    }),
    ...mapActions({
      updateProperty: "core/updateProperty",
      updatePropertyModifier: "mappingAndRates/updatePropertyModifier",
      deletePropertyModifier: "mappingAndRates/deletePropertyModifier",
      newError: "alertSystem/newError",
      fetchProperties: "core/fetchProperties",
    }),
    async validateForm() {
      await this.$refs.form.validate();

      if (this.valid) {
        this.confirmDialog = true;
      }
    },
    rawValueToNormal(rawValue) {
      const type = this.selectedPropModVarType;
      switch (type) {
        case "percentage":
          return `${Math.round(rawValue * 100)}`;
        case "amount":
          return rawValue;
      }
    },
    normalValueToRaw(normalValue) {
      const value = Number(normalValue);
      const type = this.selectedPropModVarType;
      switch (type) {
        case "percentage":
          return Number(Number(value / 100).toFixed(2));
        case "amount":
          return Number(value);
      }
    },
    async submitWrite() {
      try {
        const newProp = await this.updateProperty({
          pgId: this.selectedProperty.spec.property_group_id,
          config: this.newPropertyMapping,
        });

        if (!!newProp) {
          this.resetSelectedProperty();
          this.updateSelectedProperty({ property: newProp });
        }

        // delete property modifier if graph_json has been edited
        let res = true;
        if ( this.originalSelectedPriceStratSource != this.selectedPriceStratSource ) {
          res = await this.deletePropertyModifier({
            propertyId: this.selectedProperty.metadata.id,
            data: this.newPropertyModifier,
          });
        }
        // Avoid the insert of a modifier when graph_json is null or if it's algorithm
        if ( res == true &&
          this.selectedPriceStratSource != null &&
          this.selectedPriceStratSource.includes("NotebookPredictedPrice") == false ) {
          let response = await this.updatePropertyModifier({
            propertyId: this.selectedProperty.metadata.id,
            data: this.newPropertyModifier,
          });
          if (response == true) {
            this.originalSelectedPriceStratSource = this.selectedPriceStratSource;
          }
        }
      } catch (error) {
        this.newError({
          message: `Error updating mapping configuration for property!`,
        });
      } finally {
        await this.defaultState();
      }
    },
    async defaultState() {
      this.selectedRoomsOnWritingSys =
        this.selectedProperty?.spec?.channel_manager_id || null;
      this.selectedPriceStratSource =
        this.selectedProperty?.spec?.graph_json !== null
          ? this.selectedProperty?.spec?.graph_json
              .join(",")
              .replaceAll(/[\r\n]/gm, "")
          : null;
      this.originalSelectedPriceStratSource = this.selectedPriceStratSource;

      this.selectedRateMaster =
        this.selectedProperty?.spec?.channel_manager_rate_id || null;
      this.selectedExtraParams = this.selectedProperty.spec.extra_params
        ? JSON.stringify(this.selectedProperty.spec.extra_params)
        : "{}";

      this.selectedProperty.spec.configuration_params === null
        ? (this.selectedProperty.spec.configuration_params = {})
        : null;

      const propDetailsParentId = await this.fetchPropDetailsByPropId(
        this.selectedProperty.spec.parent_id
      );
      this.selectedParentIdPropertyGroup =
        propDetailsParentId?.spec?.property_group_id ||
        this.currentPG?.metadata?.id;
      await this.fetchPropertiesDropdownParentId(
        this.selectedParentIdPropertyGroup
      );
      this.selectedParentId = this.selectedProperty?.spec?.parent_id
        ? this.selectedProperty?.spec?.parent_id
        : null;

      const configParams = this.selectedProperty.spec.configuration_params;
      this.selectedBoardType =
        configParams.board_type !== undefined ? configParams.board_type : "";
      this.selectedRateOccupancy =
        configParams.rate_occupancy !== undefined
          ? configParams.rate_occupancy
          : "0";
      this.selectedMinOccupancy =
        configParams.min_occupancy !== undefined
          ? configParams.min_occupancy
          : "0";
      this.selectedMaxOccupancy =
        configParams.max_occupancy !== undefined
          ? configParams.max_occupancy
          : "0";

      this.selectedOffset =
        this.selectedProperty.spec.set_price_to_offset !== undefined
          ? this.selectedProperty.spec.set_price_to_offset
          : 0;

      if (
        this.selectedPriceStratSource?.includes("IndexedPrice") &&
        this.selectedPropertyModifier.spec.variation_params
      ) {
        const variationParams =
          this.selectedPropertyModifier.spec.variation_params.variations["*"];

        const propDetails = await this.fetchPropDetailsByPropId(
          variationParams.source_property_id
        );
        this.selectedPropModPropGroup =
          propDetails.spec.property_group_id || this.currentPG.metadata.id;
        await this.fetchPropertiesDropdownPropModifiers(
          this.selectedPropModPropGroup
        );
        this.selectedPropModSourceProp = variationParams.source_property_id
          ? String(variationParams.source_property_id)
          : null;

        this.selectedPropModVarType = variationParams.variation_type
          ? variationParams.variation_type
          : "";
        this.selectedPropModAmount =
          variationParams.variation_value !== null
            ? variationParams.variation_value
            : "";
      }
    },
    isValidJSON(str) {
      try {
        const obj = JSON.parse(str);
        return true;
      } catch (error) {
        return false;
      }
    },
    toKebabCase(str) {
      return (
        str &&
        str
          .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
          )
          .map((x) => x.toLowerCase())
          .join("-")
      );
    },
    async fetchPropertiesDropdownPropModifiers(pgId) {
      const props = await this.fetchProperties({ pgId: pgId });

      this.propModifierProperties = [];
      this.propModifierProperties = props.map((prop) => {
        if (prop.metadata.id !== this.selectedPropertyId) {
          return {
            text: `${prop.metadata.id} - ${prop.metadata.name}`,
            value: prop.metadata.id,
          };
        }
      });
    },
    async fetchPropertiesDropdownParentId(pgId) {
      const props = await this.fetchProperties({ pgId: pgId });

      this.parentIdProperties = [];
      this.parentIdProperties = props.map((prop) => {
        if (prop.metadata.id !== this.selectedPropertyId) {
          return {
            text: `${prop.metadata.id} - ${prop.metadata.name}`,
            value: prop.metadata.id,
          };
        }
      });
    },
    async fetchPropDetailsByPropId(propId) {
      const api = this.$store.state.api.api;

      try {
        const resp = await api.get(
          "core/v1",
          `/propertygroup/-/property/${propId}?filterColumns=id,name,property_group_id`,
          {},
          {},
          {}
        );

        return resp.items[0];
      } catch (error) {
        this.$store.dispatch("alertSystem/newError", {
          message: `Error getting the property details for the property modifier: ${error}`,
        });
      }
    },
    handlePriceStratSourceChange() {
      if (
        this.selectedPriceStratSource === this.originalSelectedPriceStratSource
      ) {
        this.isSelectedOffsetDisabled = false;
        this.selectedOffset =
          this.selectedProperty.spec.set_price_to_offset !== undefined
            ? this.selectedProperty.spec.set_price_to_offset
            : 0;
      } else {
        this.isSelectedOffsetDisabled = true;
        if (this.selectedPriceStratSource === null) {
          this.selectedOffset = 0;
        } else {
          this.selectedOffset = 500;
        }
      }
    },
  },
  async created() {
    await this.defaultState();
  },
};
</script>

<style lang="scss" scoped></style>
