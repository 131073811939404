<template>
    <v-card class="card-ui pa-4">
        <v-card-title>Insert New Rate</v-card-title>

        <v-row class="pa-4">
            <v-col class="col-6">
                <v-autocomplete
                    v-if="selectedPgCmMappingSupported"
                    outlined
                    dense
                    :items="automappingRatesList"
                    item-text="text"
                    item-value="value"
                    label="External ID"
                    v-model="selectedExternalRateID"
                    :return-object="false"
                    :disabled="!ratesAndPropRatesFetched"
                >
                    <template v-slot:selection="{ item }">
                        {{ item?.text }}
                        <v-chip
                            color="error"
                            v-if="!item.isInChannelManager"
                            small
                            >Not In CM</v-chip
                        >
                        <v-chip
                            v-else-if="!item.isInDatabase"
                            color="error"
                            small
                            >Not in DB</v-chip
                        >
                    </template>
                    <template v-slot:item="{ item }">
                        {{ item?.text }}
                        <v-chip
                            class="ml-2"
                            color="error"
                            v-if="!item.isInChannelManager"
                            small
                            >Not In CM</v-chip
                        >
                        <v-chip
                            class="ml-2"
                            v-else-if="!item.isInDatabase"
                            color="error"
                            small
                            >Not in DB</v-chip
                        >
                    </template>
                </v-autocomplete>
                <v-text-field
                    outlined
                    dense
                    v-else
                    persistent-hint
                    hint="The selected pg CM doesn't support automapping so you must write the External ID manually"
                    label="External ID"
                    v-model="selectedExternalRateID"
                ></v-text-field>
                <v-text-field
                    outlined
                    dense
                    label="Rate Name"
                    v-model="selectedRateName"
                ></v-text-field>
            </v-col>
            <v-col class="col-6">
                <v-autocomplete
                    outlined
                    dense
                    multiple
                    :items="currentPropertiesDropdown"
                    label="Select Property"
                    chips
                    deletable-chips
                    v-model="selectedProperties"
                    @change="changeSelectedProperties()"
                ></v-autocomplete>
            </v-col>
        </v-row>

        <v-row class="pa-4">
            <v-col
                class="col-12"
                v-for="(
                    propertyRateWithModifier, index
                ) of selectedProperyRatesWithModifiers"
                :key="propertyRateWithModifier.propertyRate.metadata.id"
            >
                <PropertyRateForm
                    v-model="selectedProperyRatesWithModifiers[index]"
                    :index="index"
                    @copyVarParams="copyVarParams(index)"
                ></PropertyRateForm>
            </v-col>
        </v-row>

        <v-card-actions class="pa-4">
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="confirmDialog"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary"
                        >Submit New Rate</v-btn
                    >
                </template>
                <template v-slot:default="dialog">
                    <v-card :loading="loading">
                        <v-toolbar color="primary" dark
                            >Confirm Rate Insert</v-toolbar
                        >
                        <v-card-text>
                            <div class="text-h4 pa-12">
                                Are you sure you want to submit this rate and
                                property rates w/ property rate modifiers?
                            </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn @click="submitNewRate()" color="primary"
                                >Submit New Rate</v-btn
                            >
                            <v-btn text @click="dialog.value = false"
                                >Close</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PropertyRateForm from "./PropertyRateForm.vue";

export default {
    components: { PropertyRateForm },
    data() {
        return {
            // main data
            selectedProperyRatesWithModifiers: [],

            // local data
            selectedExternalRateID: "",
            selectedRateName: "",
            selectedProperties: [],

            // generic
            confirmDialog: false,
            loading: false,
        };
    },
    computed: {
        ...mapState({
            currentPG: (state) => state.core.currentPG,
        }),
        ...mapGetters({
            currentPropertiesDropdown: "core/currentPropertiesDropdown",
            automappingRatesList: "rates/automappingRatesList",
            selectedPgCmMappingSupported: "rates/selectedPgCmMappingSupported",
            ratesAndPropRatesFetched: "rates/ratesAndPropRatesFetched",
        }),
        finalRateObj() {
            const rate = {
                metadata: {},
                spec: {
                    external_id: this.selectedExternalRateID || "",
                    name: this.selectedRateName || "",
                    property_group_id: this.currentPG.metadata.id,
                },
            };

            return {
                rate: rate,
                propertyRates: this.selectedProperyRatesWithModifiers,
            };
        },
    },
    watch: {
        currentPG(newVal, oldVal) {
            this.reset();
        },
    },
    methods: {
        ...mapActions({
            insertRate: "rates/insertRate",
        }),
        changeSelectedProperties() {
            const propertyRatesPresent =
                this.selectedProperyRatesWithModifiers.map(
                    (val) => val.propertyRate.spec.property_id
                );
            let difference = propertyRatesPresent
                .filter((x) => !this.selectedProperties.includes(x))
                .concat(
                    this.selectedProperties.filter(
                        (x) => !propertyRatesPresent.includes(x)
                    )
                )[0];

            if (propertyRatesPresent.includes(difference)) {
                // property rate already present so remove it

                const index = this.selectedProperyRatesWithModifiers.findIndex(
                    (val) => val.propertyRate.spec.property_id === difference
                );

                if (index !== -1) {
                    this.selectedProperyRatesWithModifiers.splice(index, 1);
                }
            } else {
                // property rate not present so add it
                this.selectedProperyRatesWithModifiers.push({
                    propertyRate: {
                        metadata: {},
                        spec: {
                            property_id: difference,
                            rate_id: null,
                            is_set_prices_enabled: true,
                            property_external_id: null,
                            extra_params: {},
                            graph_json: ["IndexedPrice"],
                        },
                    },
                    propertyRateModifier: {
                        metadata: {},
                        spec: {
                            property_rate_id: null,
                            modifier_id: 40,
                            variation_params: {
                                variations: {
                                    "*": {
                                        variation_type: "amount",
                                        variation_value: 0,
                                        source_property_id: null,
                                    },
                                },
                            },
                        },
                    },
                });
            }
        },
        copyVarParams(index) {
            const extraParamsToCopy = structuredClone(
                this.selectedProperyRatesWithModifiers[index].propertyRate.spec
                    .extra_params
            );
            const paramsToCopy = structuredClone(
                this.selectedProperyRatesWithModifiers[index]
                    .propertyRateModifier.spec.variation_params.variations["*"]
            );

            for (let propRateWithMod of this
                .selectedProperyRatesWithModifiers) {
                // copy extra params
                propRateWithMod.propertyRate.spec.extra_params =
                    extraParamsToCopy;

                propRateWithMod.propertyRateModifier.spec.variation_params.variations[
                    "*"
                ].variation_type = paramsToCopy.variation_type;
                propRateWithMod.propertyRateModifier.spec.variation_params.variations[
                    "*"
                ].variation_value = paramsToCopy.variation_value;
            }
        },
        reset() {
            this.selectedProperyRatesWithModifiers = [];
            this.selectedExternalRateID = "";
            this.selectedRateName = this.currentPG.metadata.name;
            this.selectedProperties = [];
        },
        async submitNewRate() {
            this.loading = true;
            await this.insertRate(this.finalRateObj);
            //await this.reset()
            this.loading = false;
            this.confirmDialog = false;
        },
    },
    created() {
        this.selectedRateName = this.currentPG.metadata.name;
    },
    destroyed() {
        this.reset();
    },
};
</script>
