<template>
    <v-container fluid class="pa-4">
        <v-row>
            <v-col class="col-12">
                <v-card class="card-ui pa-4">
                    <v-card-title>Integrations Status</v-card-title>
                    <v-autocomplete
                        label="Jira Task"
                        outlined
                        dense
                        :items="options"
                        @change="loadData($event)"
                    ></v-autocomplete>
                    <v-row>
                        <v-col class="col-12">
                            <v-text-field
                                label="Task Owner"
                                outlined
                                dense
                                readonly
                                v-model="intOwner"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-6">
                            <v-alert
                                v-show="intStatus"
                                :type="intStatus === 'Completata' ? 'success' : 'warning'"
                            >{{intStatus}}</v-alert>
                        </v-col>
                        <v-col class="col-6">
                            <v-alert
                                v-show="intFlagged"
                                :type="intFlagged ? 'success' : 'error'"
                            >{{ intFlagged ? "ACTIVE" : "BLOCKED" }}</v-alert>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col class="col-6">
                <v-card class="card-ui pa-4">
                    <v-data-table
                        :headers="headersPhases"
                        :items="intPhases"
                    ></v-data-table>
                </v-card>
            </v-col>
            <v-col class="col-6">
                <v-card class="card-ui pa-4">
                    <v-data-table
                        :headers="headersComments"
                        :items="intComments"
                    ></v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    data(){
        return {
            headersPhases: [
                {text: "Name", value: "name"},
                {text: "Status", value: "status"},
            ],
            
            headersComments: [
                {text: "Author", value: "author"},
                {text: "Date", value: "date"},
                {text: "Text", value: "text"},
            ],

            integrationsList: [],

            intOwner: "",
            intStatus: "",
            intFlagged: false,
            intPhases: [],
            intComments: [],
        }
    },
    computed: {
        ...mapState({
            api: state => state.api.api,
        }),
        options() {
            return this.integrationsList.map(val => {
                return {
                    text: val.name,
                    value: val.key,
                }
            })
        }
    },
    methods: {
        ...mapActions({
            newError: "alertSystem/newError",
        }),
        async loadData(choice) {
            try {
                this.intPhases = []
                this.intComments = []
                this.intOwner = ""
                this.intStatus = ""
                this.intFlagged = false

                const integration = this.integrationsList.find(val => val.key === choice)
                this.intPhases = integration.phases
                this.intOwner = integration.owner
                this.intStatus = integration.status
                this.intFlagged = integration.flagged
                const res = await this.api.get("integrations/v2", `/jira/integrations/${choice}/comments`, {}, {}, {})
                this.intComments = res

            } catch (error) {
                this.newError({
                    message: `Error loading integrations status comments data: ${error}`,
                })
            }
        }
    },
    async created() {
        try {
            const res = await this.api.get("integrations/v2", `/jira/integrations`, {}, {}, {})

            this.integrationsList = res
        } catch (error) {
            this.newError({
                message: `Error loading integrations status data: ${error}`,
            })
        }
    }
}
</script>