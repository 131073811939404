<template>
    <v-container class="pa-4">
        <v-card class="card-ui pa-4">
            <v-card-title>Holidays Overview (without custom ones)</v-card-title>

            <v-row>
                <v-col class="col-12">
                    <v-autocomplete
                        outlined
                        dense
                        label="Select a location"
                        :items="countries.map(country => country.metadata.id)"
                        @change="fetchFilteredHolidays($event)"
                    ></v-autocomplete>
                </v-col>

                <v-col class="col-3">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        label="Filter by Name"
                        :items="nameOptions"
                        v-model="filterName"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-3">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        label="Filter by Location (and regions)"
                        :items="locationsOptions"
                        v-model="filterLocation"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-3">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        label="Select by POI"
                        :items="pointOfInterestOptions"
                        v-model="filterPointOfInterest"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-3">
                    <v-menu
                        v-model="filterDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="filterDateRange"
                                label="Filter By Date Range"
                                readonly
                                outlined
                                clearable
                                @click:clear="filterDateRange = null"
                                dense
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filterDateRange"
                            range
                            @change="[filterDateRange = $event, filterDateMenu = false]"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <v-data-table
                :items="filteredData"
                :headers="headers"
            >
                <template v-slot:footer.prepend>
                    <v-btn
                        @click="downloadCsv()"
                    >Download CSV</v-btn>
                </template>
                <template v-slot:item.holiday_date="props"> 
                    {{ new Date(props.item.holiday_date).toISOString().split("T")[0] }}
                </template>
            </v-data-table>

        </v-card>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { jsonToWorksheet } from '@/utils/csvUtils'

export default {
    data() {
        return {
            rawData: [],

            headers: [
                {text: "Name", value: "holiday_name"},
                {text: "Location", value: "location"},
                {text: "POI", value: "attr1"},
                {text: "Date", value: "holiday_date"},
                {text: "Weight", value: "value"},
            ],

            filterDateMenu: false,

            filterDateRange: null,
            filterName: [],
            filterLocation: [],
            filterPointOfInterest: [],
        }
    },
    computed: {
        ...mapState({
            countries: state => state.graph.country,
            api: state => state.api.api,
        }),
        nameOptions() {
            return Array.from(new Set(this.rawData.map(holiday => {
                return holiday.holiday_name
            })))
        },
        locationsOptions() {
            return Array.from(new Set(this.rawData.map(holiday => {
                return holiday.location
            })))
        },
        pointOfInterestOptions() {
            return Array.from(new Set(this.rawData.map(holiday => {
                return holiday.attr1
            })))
        },
        filteredData() {
            const dateRange = this.formatDateRange(this.filterDateRange)

            return this.rawData.filter(holiday => {
                const date = new Date(holiday.holiday_date).toISOString().split("T")[0]
                const dateRangeCheck = !dateRange || date > dateRange.start && dateRange.end > date;
                const nameCheck = !this.filterName.length || this.filterName.includes(holiday.holiday_name);
                const locationCheck = !this.filterLocation.length || this.filterLocation.includes(holiday.location);
                const poiCheck = !this.filterPointOfInterest.length || this.filterPointOfInterest.includes(holiday.attr1);

                return dateRangeCheck && nameCheck && locationCheck && poiCheck;
            });
        },
    },
    methods: {
        async fetchFilteredHolidays(location) {
            const resp = await this.api.get("holiday/v3", `/holidays/filter?location=${location}`, {}, {}, {})
            
            this.rawData = []
            this.rawData = resp.response
        },
        async downloadCsv(){
            const copy = structuredClone(this.filteredData).map(holiday => {
                return {
                    name: holiday.holiday_name,
                    location: holiday.location,
                    poi: holiday.attr1,
                    date: holiday.holiday_date,
                    weight: holiday.value,
                }
            })

            await jsonToWorksheet(copy, `holidays.csv`, "csv")
        },
        formatDateRange(dateRange) {
            if (!dateRange) return null

            if (dateRange[0] < dateRange[1]) {
                return {
                    start: dateRange[0],
                    end: dateRange[1],
                }
            } else {
                return {
                    start: dateRange[1],
                    end: dateRange[0],
                }
            }
        },

    }
}
</script>