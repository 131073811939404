<template >
    <v-expansion-panels class="mb-6">
        <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
                <v-row no-gutters>
                    <v-col cols="4">
                        {{ mexKind }}
                    </v-col>
                    <v-col cols="8" class="text--secondary">
                        <v-fade-transition leave-absolute>
                            <span v-if="open">Select channels and days on which activate the automatism</span>
                            <v-row v-else no-gutters style="width: 100%">
                                <v-col cols="6">
                                    From day (included): {{ config?.from == null ? 'Not set' : config.from }}
                                </v-col>
                                <v-col cols="6">
                                    To day (excluded): {{ config?.to == null ? 'Not set' : config.to }}
                                </v-col>
                            </v-row>
                        </v-fade-transition>
                    </v-col>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-switch v-model="isAutomationEnabled" class="col-12"
                                :label="`Automation status: ${isAutomationEnabled ? 'activated' : 'deactivated'}`"></v-switch>
                        </v-row>
                        <v-row v-if="isAutomationEnabled">
                            <v-text-field class="col-12 col-md-3" v-model.number="config.from" label="From day"
                                type="number" required></v-text-field>
                            <v-text-field class="col-12 col-md-3" v-model.number="config.to" label="Until day" type="number"
                                required></v-text-field>
                            <v-text-field class="col-12 col-md-3" v-model.number="numberOfSend" label="Number of send"
                                type="number" required></v-text-field>
                            <v-select class="col-12 col-md-3" v-model="reservationType" item-text="name" item-value="value"
                                :items="reservationTypes" label="Reservation type" dense></v-select>
                        </v-row>
                        <v-row v-if="isAutomationEnabled">
                            <v-switch v-model="allChannelsIncludedSelector" class="col-4"
                                :label="`All channels included: ${allChannelsIncluded ? 'activated' : 'deactivated'}`"></v-switch>
                            <v-select class="col-8" v-model="selectedChannels" :items="availableChannels" chips
                                label="Channels" multiple :disabled="allChannelsIncluded"></v-select>
                        </v-row>
                    </v-container>
                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'ExpansionPanelsAutomation',
    //props: ['modelValue', 'mexKind'],
    props: {
        modelValue: {
            type: Object,
            default: () => {

            }
        },
        mexKind: {
            type: String
        }
    },
    emits: ['update:modelValue'],
    computed: {
        ...mapGetters({
            availableChannels: 'smartpaying/availableChannels',
        }),
        isAutomationEnabled: {
            get() {
                return this.config != null
            },
            set(value) {
                if (value == true) {
                    this.$emit('update:modelValue', {
                        "from": 0,
                        "to": 0,
                        "interval": 0,
                        "type": 0,
                        "channels": {
                            "included": true,
                            "value": []
                        }
                    })
                }
                else {
                    this.$emit('update:modelValue', null)
                }
            }
        },
        config: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        allChannelsIncludedSelector: {
            get() {
                return this.modelValue.channels.included == false && this.modelValue.channels.value.length == 0
            },
            set(value) {
                if (value) {
                    this.modelValue.channels.included = false
                    this.modelValue.channels.value = []
                }
                this.allChannelsIncluded = value
            }
        },
        selectedChannels: {
            get() {
                if (this.modelValue.channels.included) {
                    return this.modelValue.channels.value
                }
                else {
                    return []
                }
            },
            set(value) {
                this.modelValue.channels.value = value
            }
        },
        reservationType: {
            get() {
                switch (this.modelValue.type) {
                    case 0:
                        return { name: "All", value: 0 }
                    case 1:
                        return { name: "Refundable", value: 1 }
                    case 2:
                        return { name: "Not refundable", value: 2 }
                }
            },
            set(value) {
                this.modelValue.type = value
            }
        },
        numberOfSend: {
            get() {
                return Math.round((Math.abs(parseInt(this.modelValue.from) - parseInt(this.modelValue.to)) * 24) / parseInt(this.modelValue.interval))
            },
            set(value) {
                this.modelValue.interval = Math.round((Math.abs(parseInt(this.modelValue.from) - parseInt(this.modelValue.to)) * 24) / value)
            }
        }
    },
    data: function () {
        return {
            reservationTypes: [{ name: "All", value: 0 }, { name: "Refundable", value: 1 }, { name: "Not refundable", value: 2 }],
            allChannelsIncluded: this.modelValue?.channels?.included == false && this.modelValue?.channels?.value?.length == 0
        }
    },
    methods: {

    },
    mounted() {
    }
})
</script>