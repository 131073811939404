// new store for the strategy section

export default {
    namespaced: true,

    state: {
        // pipeline status
        pipelineFound: false,
        pipelineVersion: null,

        pipelineLoading: false,
    },

    getters: {

    },

    mutations: {

        resetPipelineStatus(state, payload) {
            state.pipelineFound = false
            state.pipelineVersion = null
            if (!!payload) {
                state.pipelineFound = !!payload?.pipelineFound
                state.pipelineVersion = payload?.pipelineVersion
            }
        },

        resetPipelineLoading(state, payload) { // later to use a separate store for loading actions
            state.pipelineLoading = false
            if (!!payload) {
                state.pipelineLoading = payload.value
            }
        }

    },

    actions: {

        // check pipeline version & if it exist
        async checkVersion(context, payload) {
            const api = context.rootState.api.api

            try {
                context.commit("resetPipelineLoading", {value: true})
                const res = await api.get(`predictschema/v2`, `/master/Strategy/${payload.psId}`, {}, {}, {})

                let result = null

                if (res.status === "notfound") { // didn't found the strategy
                    result = {
                        pipelineFound: false,
                        pipelineVersion: null,
                    }
                } else { // found the strategy
                    const version = res.items[0].resource?.metadata?.pipelineVersion
                    result = {
                        pipelineFound: true,
                        pipelineVersion: !!version ? version : 1,
                    }
                }

                context.commit("resetPipelineStatus", result)
                context.commit("resetPipelineLoading", {value: false})
                return result
            } catch (error) {
                context.commit("resetPipelineLoading", {value: false})
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching strategy status, reload the page! Error: ${error}`
                }, {root: true})
                return null
            }
        },

        // creates the version 1 of the strategy with its pipeline binds etc...
        async createV1Strategy(context, payload) {
            const api = context.rootState.api.api

            try {
                // call endpoint to create strategy v1
                const res = await api.put(`predictapi/v1`, `/pipeline-v1/master/skeleton/${payload.psId}`, {}, {}, {})

                if (res instanceof Error) throw res?.response?.data?.error?.error || JSON.stringify(res?.response?.data)

                // check againg the version and load the right pipeline version
                await context.dispatch("checkVersion", {psId: payload.psId})

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error creating strategy V1! Error: ${error}`,
                    timeout: -1
                }, {root: true})
            }
        },

        // creates the version 2 of the strategy with its pipelinebind etc...
        async createV2Strategy(context, payload) {
            const api = context.rootState.api.api;

            try {
                // call endpoint to create strategy v2
                const res = await api.put(`predictapi/v1/pipeline-v2`, `/master/first/${payload.psId}`, {}, payload?.config || {}, {})

                if (res instanceof Error) throw res?.response?.data?.error?.error || res?.response?.data

                if (res.outcomes?.[`baseprice.${payload.psId}-test`]?.error === "No valid base price map") {
                    context.commit("pipelineV2/resetBasePriceMapsRequired", {value: true, nextStep: "first"}, { root: true })
                    context.commit("pipelineV2/resetBasePriceMaps", {value: res.outcomes?.[`baseprice.${payload.psId}-test`]?.base_price_map_combined.suggested}, { root: true })
                }

                // check againg the version and load the right pipeline version
                await context.dispatch("checkVersion", {psId: payload.psId})

            } catch (error) {
                if ( Array.isArray(error?.["error"]) ) {
                    error["error"][0] = "\n - " + error["error"][0]
                    error = error["error"].join("\n - ")
                }

                context.dispatch("alertSystem/newError", {
                    message: `Error creating strategy V2 (aka. Automatic Pipeline)! Error: ${error}`,
                    timeout: -1
                }, {root: true})
            }
        },

        async strategyV2toV1(context, payload){
            const api = context.rootState.api.api

            try {
                // call endpoint v2 to v1
                const res = await api.put(`predictapi/v1/pipeline-v2`, `/master/v2tov1/${payload.psId}`, {}, {}, {})

                if (res instanceof Error) throw res?.response?.data?.error?.error || JSON.stringify(res?.response?.data)

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Change to V1 pipeline failed! Error: ${error}`,
                    timeout: -1
                }, {root: true})
            } finally {
                // check againg the version and load the right pipeline version
                await context.dispatch("checkVersion", {psId: payload.psId})
            }
        },

        async strategyV1toV2(context, payload) {
            const api = context.rootState.api.api

            try {
                // call endpoint to create strategy v2
                const res = await api.put(`predictapi/v1/pipeline-v2`, `/master/first/${payload.psId}`, {}, {}, {})

                if (res instanceof Error) throw res?.response?.data?.error?.error || JSON.stringify(res?.response?.data)

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Change to V2 pipeline failed! Error: ${error}`,
                    timeout: -1
                }, {root: true})
            } finally {
                // check againg the version and load the right pipeline version
                await context.dispatch("checkVersion", {psId: payload.psId})
            }
        },

        async deleteV1(context, payload) {
            const api = context.rootState.api.api

            try {
                // call endpoint to delete strategy v1
                const res = await api.delete(`predictapi/v1/pipeline-v2`, `/master/clean/${payload.psId}`, {}, {}, {})

                if (res instanceof Error) throw res?.response?.data?.error?.error || JSON.stringify(res?.response?.data)

                context.dispatch("alertSystem/newSuccess", {
                    message: `V1 strategy was succesfuly deleted`,
                }, {root: true})

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Deletion of V1 Strategy failed! Error: ${error}`,
                    timeout: -1
                }, {root: true})
            } finally {
                // check againg the version and load the right pipeline version
                await context.dispatch("checkVersion", {psId: payload.psId})
            }
        }
    }
}
