<template>
  <div class="home" >
    <v-container fluid class="pa-0">
      <v-card>
        <v-card-text>
          <v-select
            v-model="chosenPreset"
            :items="presetItems"
            @change="presetChangeHandler"
            label="Preset"
            clearable
            return-object
            single-line
          />
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dateRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRange"
                label="Picker in menu"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              scrollable
              v-model="dateRange"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dateRange)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-slider
            v-model="threshold"
            class="align-center"
            :max="1"
            :min="0"
            step=0.05
            hide-details
            thumb-label="always"
            label="threshold"
          />
        </v-card-text>
      </v-card>

      <v-row>
        <v-col class="col-12" style="height: 100vh;">
          <iframe
            width="100%"
            height="100%"
            :src="reportUrl"
            frameborder="0"
            style="border:0"
            allowfullscreen
            v-if="this.dateRange.length === 2 && this.threshold"
          ></iframe>
          <h3 class="ma-6" v-else>Select Date Range and Threshold</h3>
        </v-col>
      </v-row>
  </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'EarlySales',
  data: function (argument) {
    return {
      menu: false,
      dateRangeLocal: [],
      thresholdLocal: 1,
      chosenPreset: null,
      presetItems: [
        {
          text: '0-30 days',
          value: {
            startDate: this.getDate(),
            endDate: this.getDate(30),
            threshold: 0.9
          }
        },
        {
          text: '30-60 days',
          value: {
            startDate: this.getDate(30),
            endDate: this.getDate(60),
            threshold: 0.7
          }
        },
        {
          text: '60-90 days',
          value: {
            startDate: this.getDate(60),
            endDate: this.getDate(90),
            threshold: 0.5
          }
        },
        {
          text: '90-120 days',
          value: {
            startDate: this.getDate(90),
            endDate: this.getDate(120),
            threshold: 0.3
          }
        },
        {
          text: '120-150 days',
          value: {
            startDate: this.getDate(120),
            endDate: this.getDate(150),
            threshold: 0.25
          }
        },
        {
          text: '150-180 days',
          value: {
            startDate: this.getDate(150),
            endDate: this.getDate(180),
            threshold: 0.2
          }
        },
      ]
    }
  },
  computed: {
    reportUrl () {
      const baseUrl = 'https://lookerstudio.google.com/embed/reporting/ed1648b8-13e2-4ac3-88ea-f807d69873fe/page/WBtHD'
      const params = {
        'ds0.start_date': this.dateRange[0],
        'ds0.end_date': this.dateRange[1],
        'ds0.threshold': this.threshold
      };
      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString)
      return baseUrl + '?params=' + encodedParams
    },
    dateRange: {
      get(){
        return this.dateRangeLocal
      },
      set(newVal){
        this.dateRangeLocal = newVal
      }
    },
    threshold: {
      get(){
        return this.thresholdLocal
      },
      set(newVal){
        this.thresholdLocal = newVal
      }
    }
  },
  methods: {
    async fetchMarketList(){
      await this.$store.dispatch('strategy/fetchMarkets');
      this.marketList = this.$store.state.strategy.marketsList
    },
    updateMarketName(obj){
      this.formModel = obj
    },
    getDate(daysToAdd = 0){
      let date = new Date()

      date.setDate(date.getDate() + daysToAdd)

      return date.toISOString().split('T')[0]
    },
    presetChangeHandler(obj){
      if(!obj){
        this.dateRange = []
        this.threshold = null
        return
      }
      this.dateRange = [ obj.value.startDate, obj.value.endDate ]
      this.threshold = obj.value.threshold
    }
  },
  created(){
    this.fetchMarketList()
  },
}
</script>
