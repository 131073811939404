import { render, staticRenderFns } from "./AlarmOccupancy.vue?vue&type=template&id=f05a774a"
import script from "./AlarmOccupancy.vue?vue&type=script&lang=js"
export * from "./AlarmOccupancy.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports