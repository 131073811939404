<template>
    <v-container fluid class="pa-4">
        <v-card class="card-ui">
            <v-toolbar
                dense
                elevation="0"
                :class="{ 'grey darken-3': darkMode }"
            >
                <v-card-title class="overline ml-0 pl-0">
                    <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                    <b class="grey--text darken-6">Alarms triggered</b>
                </v-card-title>
            </v-toolbar>
            <v-card-text class="px-3 pt-3 pb-5">
                <DataFilters
                    :items="triggeredAlarms"
                    :schema="filters"
                    v-model="filteredAlarms"
                    :filtersMenuDefault="true"
                    class="card-ui"
                >
                    <template v-slot:filters-top>
                        <v-row class="pt-2">
                            <v-col class="col-3">
                                <v-menu v-model="alarmsFromDatePicker">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="alarmsFromDate"
                                            label="Triggered from"
                                            type="text"
                                            dense
                                            outlined
                                            hide-details
                                            append-icon="fa-solid fa-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="alarmsFromDate"
                                        @input="alarmsFromDatePicker = false"
                                        @picker-date.sync="alarmsFromDate"
                                        align="center"
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-3">
                                <v-menu v-model="alarmsToDatePicker">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="alarmsToDate"
                                            label="Triggered to"
                                            type="text"
                                            dense
                                            outlined
                                            hide-details
                                            append-icon="fa-solid fa-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="alarmsToDate"
                                        @input="alarmsToDatePicker = false"
                                        @picker-date.sync="alarmsToDate"
                                        align="center"
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </template>
                </DataFilters>
                <v-data-table
                    :items="filteredAlarms"
                    :headers="alarmsTableHeaders"
                    :items-per-page="10"
                    class="card-ui"
                    style="cursor: pointer"
                    @click:row="showAlarmNotifications"
                    sort-by="created_at"
                    loading-text="Loading triggered alarms"
                    sort-desc
                >
                    <template v-slot:item.created_at="{ item }">
                        {{ formatDate(item.created_at) }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog width="auto" max-width="1024" v-model="dialogIsOpen">
            <template v-slot:default="dialog">
                <v-card class="card-ui">
                    <v-toolbar
                        dense
                        elevation="0"
                        :class="{ 'grey darken-3': darkMode }"
                    >
                        <v-card-title class="overline ml-0 pl-0">
                            <v-icon small class="mr-2"
                                >fa-solid fa-circle-info</v-icon
                            >
                            <b class="grey--text darken-6"
                                >{{ alarmById.check_kind }} alarm -
                                {{ alarmById.scope_key }}
                                {{ alarmById.scope_value }}
                            </b>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text class="pt-6">
                        <v-card>
                            <v-card-title>Associated Job Result</v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :items="[executedJobResult]"
                                    :headers="jobResultTableHeader"
                                    class="card-ui"
                                    loading-text="Loading job result"
                                >
                                </v-data-table>
                            </v-card-text>
                            <v-divider />
                            <v-card-title>Notifications Status</v-card-title>
                            <v-card-text>
                                <v-card
                                    v-for="notification in triggeredAlarmNotifications"
                                    class="card-ui pb-1"
                                    :class="{ 'grey darken-3': darkMode }"
                                >
                                    <v-card-title>{{
                                        notification.output_kind
                                    }}</v-card-title>
                                    <v-card flat class="mx-4 pa-4 mb-4 card-ui">
                                        <pre>{{
                                            notification.output_data
                                        }}</pre>
                                    </v-card>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-actions class="pb-6 px-6 justify-end">
                        <v-btn color="primary" @click="dialog.value = false"
                            >Close</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { formatDate } from "@/utils/dateUtils";
import DataFilters from "@/components/utils/DataFilters.vue";

const currentDate = new Date();
const oneWeekBeforeCurrentDate = new Date(
    currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
);

export default {
    components: { DataFilters },
    data() {
        return {
            alarmsTableHeaders: [
                { text: "Check kind", value: "check_kind" },
                { text: "Job executed (id)", value: "job_id" },
                { text: "Entity type", value: "scope_key" },
                { text: "Entity value", value: "scope_value" },
                { text: "Triggered at", value: "created_at" },
            ],
            jobResultTableHeader: [
                { text: "Entity type", value: "scope_key" },
                { text: "Entity value", value: "scope_value" },
                { text: "Extra result data", value: "data" },
            ],
            dialogSelectedAlarmId: null,
            dialogIsOpen: false,
            filters: [
                { text: "Check kind", value: "check_kind" },
                { text: "Entity type", value: "scope_key" },
                { text: "Entity value", value: "scope_value" },
            ],
            filteredAlarms: [],

            alarmsFromDatePicker: false,
            alarmsToDatePicker: false,
            alarmsFromDate: oneWeekBeforeCurrentDate
                .toISOString()
                .split("T")[0],
            alarmsToDate: currentDate.toISOString().split("T")[0],
        };
    },
    computed: {
        ...mapState({
            darkMode: (state) => state.ui.darkMode,
            triggeredAlarms: (state) => state.alarms.triggeredAlarms,
            triggeredAlarmNotifications: (state) =>
                state.alarms.triggeredAlarmNotifications,
            executedJobResult: (state) => state.alarms.executedJobResult,
        }),
        ...mapGetters({
            getAlarmById: "alarms/getAlarmById",
        }),
        alarmById() {
            return this.getAlarmById(this.dialogSelectedAlarmId);
        },
    },
    watch: {
        alarmsFromDate: function () {
            if (this.alarmsFromDate && this.alarmsToDate) {
                this.fetchTriggeredAlarms();
            }
        },
        alarmsToDate: function () {
            if (this.alarmsFromDate && this.alarmsToDate) {
                this.fetchTriggeredAlarms();
            }
        },
    },
    methods: {
        async fetchTriggeredAlarms() {
            await this.$store.dispatch("alarms/getTriggeredAlarms", {
                fromDate: this.alarmsFromDate,
                toDate: this.alarmsToDate,
            });
        },
        async showAlarmNotifications(alarm) {
            await this.$store.dispatch(
                "alarms/getTriggeredAlarmNotifications",
                alarm.id
            );
            await this.$store.dispatch("alarms/getExecutedJobResult", {
                jobId: alarm.job_id,
                scopeKey: alarm.scope_key,
                scopeValue: alarm.scope_value,
            });

            this.dialogSelectedAlarmId = alarm.id;
            this.dialogIsOpen = true;
        },
        formatDate,
    },
    async created() {
        if (this.alarmsFromDate && this.alarmsToDate) {
            await this.fetchTriggeredAlarms();
        }
    },
};
</script>
