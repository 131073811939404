const BOOKING_URL_REGEX = /^(?:https?:\/\/)?(?:www\.)?booking\.com\/hotel\/(?<country>[a-z]+)\/(?<id>[^.^\/]+)(?:\.(?<lang>[a-z|-]+))?\.html/

function applyBookingUrlRegex(value) {
    const re = BOOKING_URL_REGEX
    const isValid = re.exec(value)
    return isValid
}

export function isBookingUrlValid(value) {
    const isValid = applyBookingUrlRegex(value)
    if (isValid?.groups?.country != null && isValid?.groups?.id != null) {
        return true
    } else {
        return false
    }
}

export function fixBookingUrl(value) {
    if (value && value.includes('booking')) {
        const isValid = applyBookingUrlRegex(value)
        if (isValid?.groups?.country != null && isValid?.groups?.id != null) {
            return `https://www.booking.com/hotel/${isValid?.groups?.country}/${isValid?.groups?.id}.en-gb.html`
        }
    }
    return false
}
