<template>
    <v-card
        class="card-ui pa-4"
    >
        <v-card-title>Feature To Partners</v-card-title>

        <v-card-text>
            <v-autocomplete
                label="Select feature"
                :items="featuresList"
                outlined
                item-text="label"
                item-value="value"
                dense
                v-model="selectedFeature"
                @change="getPartnersByFeature({featureId: selectedFeature})"
            ></v-autocomplete>
            <v-data-table
                :headers="headers"
                :items="partnersListByFeature"
            >
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
    data() {
        return {
            headers: [
                {
                    text: "Partner",
                    value: "text",
                },
                {
                    text: "Value",
                    value: "value"
                }
            ],
            selectedFeature: null
        }
    },
    computed: {
        ...mapState({
            featuresList: state => state.integrationsSpec.featuresList,
            partnersListByFeature: state => state.integrationsSpec.partnersListByFeature,
        })
    },
    methods: {
        ...mapActions({
            getFeaturesList: "integrationsSpec/getFeaturesList",
            getPartnersByFeature: "integrationsSpec/getPartnersByFeature",
        }),
        ...mapMutations({
            resetPartnersListByFeature: "integrationsSpec/resetPartnersListByFeature"
        })
    },
    created() {
        this.resetPartnersListByFeature()
        this.getFeaturesList()
    },
    destroyed() {
        this.resetPartnersListByFeature()
    }
}
</script>

<style lang="scss" scoped>

</style>