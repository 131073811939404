const xlsx = require("xlsx")

export async function jsonToWorksheet(data, filename, fileType, enabledColumns = undefined) {
    const dataToExport = structuredClone(data.map(entry => JSON.parse(JSON.stringify(entry))))
    if (enabledColumns) {
        const entryColumns = Object.keys(dataToExport[0])
        const columnsToKeep = enabledColumns.map(column => column.value.split('.').at(-1))
        const columnsToRemove = entryColumns.filter(column => !columnsToKeep.includes(column))

        dataToExport.forEach(tableEntry => {
            columnsToRemove.forEach(column => {
                delete tableEntry[column]
            })
        })
    }

    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.json_to_sheet(dataToExport)

    const options = { bookType: fileType }
    if (fileType === "xlsx") options.compression = true

    xlsx.utils.book_append_sheet(workbook, worksheet, "Foglio 1");
    xlsx.writeFile(workbook, filename, options)
}
