<template>
    <v-container class="transparent positioning">
        <v-alert
            v-for="(alert, index) in alerts"
            :key="alert.id"
            :type="alert.type"
            :icon="alert.icon"
            v-model="alertsPresent"
            border="left"
            :color="alert.color"
            transition="scale-transition"
            dense
        >
            <v-row align="center" >
                <v-col class="grow">
                    <div class="text-h6">
                        {{ alert.title }}
                    </div>
                    <div>
                        <p class="with-breaks">{{ alert.message }}</p>
                    </div>
                </v-col>
                <v-col class="shrink">
                    <v-btn
                        outlined
                        @click="resetAlerts()"
                    >
                        Close all
                        <!-- <v-icon>fas fa-times-circle</v-icon> -->
                    </v-btn>
                </v-col>
                <v-col class="shrink">
                    <v-btn
                        outlined
                        @click="deleteAlert({id: alert.id})"
                    >
                        Close
                        <!-- <v-icon>fas fa-times-circle</v-icon> -->
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            alerts: state => state.alertSystem.alerts,
        }),
        ...mapGetters({
            alertsPresent: "alertSystem/alertsPresent"
        }),
    }, 
    methods: {
        ...mapMutations({
            deleteAlert: "alertSystem/deleteAlert",
            resetAlerts: "alertSystem/resetAlerts",
        }),
    }
}
</script>

<style lang="scss" scoped>
.transparent {
    background: rgba(0,0,0,0) !important;
}

.positioning {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    z-index: 10000;
}

p.with-breaks {
  white-space: pre-wrap;
}
</style>