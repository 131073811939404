var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","dark":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Show events after","prepend-icon":"mdi-calendar"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"header-color":"primary"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.eventsObj,"sort-by":"EventDate","single-expand":_vm.singleExpand,"show-expand":"","item-key":"uniqueKey","items-per-page":100,"footer-props":{
              itemsPerPageOptions: [100, 500,  -1],
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus'
      }},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-list',{attrs:{"lines":"one"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Source Kind")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.EventSourceKind))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Event Coefficient")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.EventTargetValue))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Date Coefficient")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.EventDateValue))])],1)],1)],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }