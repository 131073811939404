<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col class="col-12 col-md-12">
          <v-card outlined class="mx-auto col-10 col-md-10">
            <v-card-title>Create a new autonboarding</v-card-title>
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <p class="subtitle-1">Customer contract</p>
                  <v-row>
                    <v-text-field class="col-12 col-md-3" v-model.number="newCustomerDto.application_fees.card.card_flat
                " label="Card Fee Flat" type="number"></v-text-field>
                    <v-text-field class="col-12 col-md-3" v-model.number="newCustomerDto.application_fees.card.card_eu
                " label="Card Fee Europe" type="number"></v-text-field>
                    <v-text-field class="col-12 col-md-3" v-model.number="newCustomerDto.application_fees.card.card_world
                " label="Card Fee World" type="number"></v-text-field>
                    <v-select class="col-12 col-md-3" v-model="newCustomerDto.contract" :items="contractTypes"
                      item-text="name" item-value="value" label="Contract"></v-select>
                  </v-row>
                  <p class="subtitle-1">Customer info</p>
                  <v-row>
                    <v-text-field class="col-12 col-md-4" v-model="newCustomerDto.name" label="Name"></v-text-field>
                    <v-text-field class="col-12 col-md-4" v-model="newCustomerDto.email" label="Email"></v-text-field>
                    <v-text-field class="col-12 col-md-4" v-model="newCustomerDto.vat"
                      label="P.IVA (if present)"></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field class="col-12 col-md-8" v-model="newCustomerDto.property_sets"
                      label="Associated property set (if multiple use ',' as separator)"></v-text-field>
                    <v-autocomplete class="col-12 col-md-4" v-model="newCustomerDto.pms_name" :items="integrations"
                      label="Integration"></v-autocomplete>
                  </v-row>
                </v-container>
              </v-form>
              <v-form v-model="hubspotvalid">
                <v-container>
                  <p class="subtitle-1">Add a deal from hubspot (optional)</p>
                  <v-row>
                    <v-text-field class="col-6 col-md-6" v-model="newCustomerDto.onboarding_task_url"
                      label="Add onboarding task url"></v-text-field>
                    <v-text-field class="col-4 col-md-4" v-model="hubspotDealSearchInput"
                      label="Search by deal title"></v-text-field>
                    <v-btn class="col-2 col-md-2 mb-0" :loading="loadingData" :disabled="loadingData" color="info"
                      @click="searchHubspotDeals">Search deal</v-btn>
                  </v-row>
                  <v-row>
                    <v-radio-group v-if="hubspotDealsList.length > 0" v-model="newCustomerDto.hubspot_deal">
                      <v-radio v-for="deal in hubspotDealsList" :key="deal.title" :label="`Deal: ${deal.title}`"
                        :value="deal.value"></v-radio>
                    </v-radio-group>
                  </v-row>
                </v-container>
              </v-form>
              <v-row>
                <v-btn class="col-2 col-md-2 ml-auto mt-2" color="success" :loading="loadingData"
                  :disabled="loadingData" @click="createCustomer">Create AutOnboarding</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-md-12">
          <v-card outlined class="mx-auto col-10 col-md-10">
            <v-card-title>Check onboardings status
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table :items="customers" :headers="headers" :search="search"></v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ConfirmDialog ref="dialog" />
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import ConfirmDialog from "@/components/utils/ConfirmDialog";
import { ref } from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  components: {
    ConfirmDialog,
  },
  name: "Autonboarding",
  data: function () {
    return {
      loadingData: false,
      valid: true,
      hubspotvalid: true,
      contractTypes: [
        { name: "Freemium", value: "freemium" },
        { name: "Premium", value: "premium" },
      ],
      newCustomerDto: ref({
        application_fees: {
          card: {
            card_flat: null,
            card_world: null,
            card_eu: null,
          },
        },
        email: null,
        name: null,
        contract: null,
        property_sets: null,
        pms_name: null,
        hubspot_deal: null,
        onboarding_task_url: null
      }),
      search: '',
      headers: [
        { text: "Link", value: "link" },
        { text: "Email", value: "email" },
        { text: "Name", value: "name" },
        { text: "Created On", value: "created_on" },
        { text: "Last Login", value: "last_login" },
        { text: "Completed On", value: "completed_on" },
        { text: "Stripe Account", value: "stripe_account" },
        { text: "A5 italian Print", value: "a5_it_pdf" },
        { text: "A5 english Print", value: "a5_en_pdf" },
        { text: "Shipping address", value: "shipping", width: "250px"}
      ],
      hubspotDealSearchInput: null,
      hubspotDealsList: [],
    };
  },
  computed: {
    ...mapGetters({
      integrations: "smartpaying/integrations",
      customers: "smartpaying/autonboardingcustomers",
    }),
  },
  methods: {
    async createCustomer() {
      this.loadingData = true;
      const resp = await this.$store.dispatch(
        "smartpaying/createOnboardingLink",
        {
          newCustomerDto: this.newCustomerDto,
        }
      );
      this.loadingData = false;
      if (resp.response?.status == 400) {
        const options = { cardTextClass: "pa-4", color: "orange", width: 800 };
        const title =
          "Ouch, I cannot create an onboarding link with this parameters";
        let errorExplanation = "";
        for (const error in resp.response.data) {
          errorExplanation += `${error} - ${JSON.stringify(
            resp.response.data[error]
          )}<br>`;
        }
        const message = `
        <img
        :height="100"
        src="https://media2.giphy.com/media/uIGfoVAK9iU1y/200w.gif?cid=82a1493b1v3e41ng6t0g11cr68kwf975ka2glaga9tefpkui&ep=v1_gifs_related&rid=200w.gif&ct=g"
      ></img>
        <h2>Let me show the errors</h2>
        <p>${errorExplanation}</p>`;
        await this.$refs.dialog.open(title, message, options);
      } else if (resp.response?.status == 401) {
        const options = { cardTextClass: "pa-4", color: "orange", width: 800 };
        const title = "Your auth session is expired";
        let errorExplanation = "";
        for (const error in resp.response.data) {
          errorExplanation += `${error} - ${JSON.stringify(
            resp.response.data[error]
          )}<br>`;
        }
        const message = `Please refresh the page`;
        await this.$refs.dialog.open(title, message, options);
      } else {
        const options = {
          cardTextClass: "pa-4 accent-1--text",
          color: "success",
        };
        const title = `New customer onboarding created`;
        const message = `Submit this link to customer ${this.newCustomerDto.name}: <a href="${resp}" target="_blank">${resp}</a>`;
        await this.$refs.dialog.open(title, message, options);
      }
    },
    async searchHubspotDeals() {
      this.loadingData = true;
      const resp = await this.$store.dispatch(
        "smartpaying/searchHubspotDeals",
        {
          title: this.hubspotDealSearchInput,
        }
      );
      this.loadingData = false;
      if (resp.response?.status == 400) {
        const options = { cardTextClass: "pa-4", color: "orange", width: 800 };
        const title = `Ouch, I cannot find deal with this title: ${this.hubspotDealSearchInput}`;
        const message = `
        <img
        :height="100"
        src="https://media2.giphy.com/media/uIGfoVAK9iU1y/200w.gif?cid=82a1493b1v3e41ng6t0g11cr68kwf975ka2glaga9tefpkui&ep=v1_gifs_related&rid=200w.gif&ct=g"
      ></img>`;
        await this.$refs.dialog.open(title, message, options);
      } else if (resp.response?.status == 401) {
        const options = { cardTextClass: "pa-4", color: "orange", width: 800 };
        const title = "Your auth session is expired";
        let errorExplanation = "";
        for (const error in resp.response.data) {
          errorExplanation += `${error} - ${JSON.stringify(
            resp.response.data[error]
          )}<br>`;
        }
        const message = `Please refresh the page`;
        await this.$refs.dialog.open(title, message, options);
      } else {
        this.hubspotDealsList = resp.results.map((deal) => ({
          title: deal.properties.dealname,
          value: deal.id,
        }));
        this.hubspotDealsList.push({ title: "None", value: null });
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("smartpaying/fetchIntegrations");
    await this.$store.dispatch("smartpaying/fetchAutonboardingCustomers");
  },
});
</script>
<style lang="scss"></style>
