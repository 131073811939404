<template>
  <div>
    <v-row>
      <v-col class="col-10">
        <v-autocomplete 
          style="width:100%" 
          dense 
          flat 
          hide-details
          solo-inverted 
          :value="globalSelectedPgId || null"
          @change="resetData($event)"
          :items="pgsListWithLifecycleStatus"
          item-text="searchValue"
          :append-outer-icon="copied ? 'far fa-solid fa-copy' : 'far fa-regular fa-copy'"
          @click:append-outer="copyevent(globalSelectedPgId)"
          key="id"
          placeholder="Property Group"
        >
          <template v-slot:item="data" v-text="data.item.searchValue">
            <span>
              {{ data.item.id }} {{ data.item.name }} - <span :class="data.item.textColor">{{ data.item.status }}</span>
            </span>
          </template>
          <template v-slot:selection="data" v-text="data.item.searchValue">
            <span>
              {{ data.item.id }} {{ data.item.name }} - <span :class="data.item.textColor">{{ data.item.status }}</span>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="col-2">
        <v-btn style="width:100%" outlined text  @click="reset">Reset</v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

// @ is an alias to /src

export default {
  name: 'PropertyGroupGlobalSelector',
  props: [],
  components: {},
  data: function (argument) {
    return {
      menu2: false,
      copied: false,
    }
  },
  computed: {
    ...mapState({
      currentParent: state => state.core.currentParent,
    }),
    ...mapGetters({
      pgsListWithLifecycleStatus: "core/pgsListWithLifecycleStatus",
      globalSelectedPgId: "core/globalSelectedPgId",
    })
    /* selectedPropertyGroup: {
      get () {
        return this.$store.state.core.globalSelectedPropertyGroup
      },
      set (value) {
        this.$store.dispatch('core/updateSelectedPG', value)
      }
    } */
  },
  methods: {
    ...mapMutations({
      resetSelectedPgData: "core/resetSelectedPgData",
      resetCurrentPgLifecycles: "lifecycle/resetCurrentPgLifecycles"
    }),
    async copyevent(pgId){
      console.log(pgId)
      await navigator.clipboard.writeText(pgId)
      this.copied = true
      setTimeout(() => this.copied = false, 500)
    },
    async reset () {
      this.resetSelectedPgData()
      this.resetCurrentPgLifecycles()
      this.$store.state.core.currentPG = null
      this.$store.state.core.currentProperties = null

      await Promise.all([
      ]) 
    },
    async resetData(pgId){
      if (!!pgId) {
        await Promise.all([
          this.$store.dispatch("core/selectGlobalPg", {pgId: pgId}),
          this.$store.dispatch("lifecycle/getPgLatestLifecycles", {pgId: pgId}),
          this.$store.dispatch("lifecycle/getPgHistory", {pgId: pgId}),
        ])
        await this.$store.dispatch("strategyNew/checkVersion", {psId: this.$store.state.core.currentStrategyPS.metadata.id})
      }
    }
  }
  
}
</script>
