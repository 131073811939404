<template>
    <v-card elevation="0" class="pa-4">
        <v-card-actions>
            <v-btn class="ml-n2" color="primary" :outlined="!filtersMenu" @click="filtersMenu = !filtersMenu">
                Filters
            </v-btn>
            <v-spacer></v-spacer>
            <slot name="header"></slot>
        </v-card-actions>

        <v-sheet v-show="filtersMenu" width="100%" class="my-2 card-ui">
            <v-row>
                <v-col class="col-12">
                    <slot name="filters-top"></slot>
                </v-col>
                <v-col class="col-3" v-for="filter of filtersList">
                    <v-autocomplete :label="filter.label" outlined dense multiple chips hide-details :items="filter.items"
                        :value="appliedFiltersHashMap[filter.key]"
                        @change="updateAppliedFilters($event, filter.key)">

                        <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="selectAllFilters(filter.items, filter.key)">
                                
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Select All
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>

                        </template>
                    
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-sheet>

        <v-sheet v-show="allAppliedFilters.length" width="100%" class="my-2 card-ui">
            <div>
                Applied Filters:
            </div>
            <v-chip-group active-class="primary--text" column>
                <v-chip v-for="filter in allAppliedFilters" :key="filter.id" close small :color="filter.color"
                    @click:close="removeAppliedFilters(filter.value, filter.key)">
                    {{ filter.text }}
                </v-chip>
            </v-chip-group>
        </v-sheet>

    </v-card>
</template> 

<script>
import Vue from "vue"

export default {
    props: [
        "schema",
        "items",
        "value",
        "filtersMenuDefault",
    ],
    data() {
        return {
            filtersMenu: false,
            appliedFiltersHashMap: {},

            colorsArray: [ // yeah it's hardcoded not ideal but i don't want to spend 2 hours to make a random color generator :)
                "red darken-2", "red darken-4",
                "pink darken-2", "pink darken-4",
                "purple darken-2", "purple darken-4",
                "deep-purple darken-2", "deep-purple darken-4",
                "indigo darken-2", "indigo darken-4",
                "blue darken-2", "blue darken-4",
                "light-blue darken-2", "light-blue darken-4",
                "cyan darken-2", "cyan darken-4",
                "teal darken-2", "teal darken-4",
                "green darken-2", "green darken-4",
                "light-green darken-2", "light-green darken-4",
                "lime darken-2", "lime darken-4",
                "yellow darken-2", "yellow darken-4",
                "amber darken-2", "amber darken-4",
                "orange darken-2", "orange darken-4",
                "deep-orange darken-2", "deep-orange darken-4",
                "brown darken-2", "brown darken-4",
                "blue-grey darken-2", "blue-grey darken-4",
            ],
        }
    },
    watch: {
        filteredData(newVal, oldVal) {
            this.$emit("input", newVal)
        },
        filtersHashMapList: { // useful when the data changes and the filters remain the same to remove old filters
            handler: function (newVal, oldVal) {
                const filtersToRemove = [];

                // Iterate through the appliedFiltersHashMap
                Object.entries(this.appliedFiltersHashMap).forEach(([key, values]) => {
                    for (let val of values) {
                        // Check if the value is not in the new filter options
                        if (!newVal[key].includes(val)) {
                            filtersToRemove.push({ key, value: val });
                        }
                    }
                });

                // Remove filters after the iteration
                filtersToRemove.forEach(filter => {
                    this.removeAppliedFilters(filter.value, filter.key);
                });
            },
            deep: true
        }
    },
    computed: {
        keyTextHashMap() {
            const hashMap = {}

            this.schema.forEach(s => {

                hashMap[s.value] = s.text
            })

            return hashMap
        },
        colorsHashMap() {
            const hashMap = {}

            this.schema.forEach(s => {
                const color = this.colorsArray[Math.floor(Math.random() * this.colorsArray.length)]

                hashMap[s.value] = color
            })

            return hashMap
        },
        filtersList() {
            return this.schema.map(s => {
                let items = []
                this.items.map(item => {
                    let currentItem = item
                    for (const nestedProperty of s.value.split('.')) {
                        currentItem = currentItem[nestedProperty]
                    }
                    items.push(currentItem)
                })
                return {
                    items: [...new Set(items)],
                    label: s.text,
                    key: s.value,
                }
            })
        },
        filtersHashMapList() {
            const hashMap = {}
            this.filtersList.forEach(val => {
                hashMap[val.key] = val.items
            })
            return hashMap
        },
        allAppliedFilters() {
            const allAppliedFilters = []
            Object.entries(this.appliedFiltersHashMap).forEach(([key, values]) => {

                values.forEach(value => {
                    allAppliedFilters.push({
                        id: `${key}-${value}`,
                        text: `${this.keyTextHashMap[key]} ${value}`,
                        color: this.colorsHashMap[key],
                        key: key,
                        value: value,
                    })
                })
            })

            return allAppliedFilters
        },
        filteredData() {
            if (!this.allAppliedFilters.length) {
                return this.items
            } else {
                return this.items.filter(item => {
                    const toFilter = Object.entries(this.appliedFiltersHashMap).some(([key, values]) => {
                        if (!values.length) {
                            return false
                        } else {
                            let currentItem = item
                            for (const nestedProperty of key.split('.')) {
                                currentItem = currentItem[nestedProperty]
                            }

                            return !values.includes(currentItem)
                        }
                    })

                    return !toFilter
                })
            }
        }
    },
    methods: {
        updateAppliedFilters(newAppliedFilters, key) {
            Vue.set(this.appliedFiltersHashMap, key, newAppliedFilters)
        },
        selectAllFilters(elements, key) {
            if ( this.appliedFiltersHashMap[key]?.length == elements?.length ) {
                Vue.set(this.appliedFiltersHashMap, key, [])
            } else {
                Vue.set(this.appliedFiltersHashMap, key, elements)
            }
        },
        removeAppliedFilters(filterToRemove, key) {
            if (this.appliedFiltersHashMap[key] !== undefined) {
                const index = this.appliedFiltersHashMap[key].findIndex(val => val === filterToRemove)

                if (index !== -1) {
                    this.appliedFiltersHashMap[key].splice(index, 1)
                }
            }
        }
    },
    created() {
        this.schema.forEach(val => {
            if (val.defaultFilters !== undefined) {
                this.updateAppliedFilters(val.defaultFilters, val.value,)
            }
        })
        this.filtersMenu = !!this.filtersMenuDefault
        this.$emit("input", this.filteredData)
    }
}
</script>
