
export async  function compressGzip(text) {
    const cs = new CompressionStream("gzip")
    const bytes = new TextEncoder().encode(text)

    const writer = cs.writable.getWriter();

    writer.write(bytes)
    write.close()


    return await  new Response(cs.readable).arrayBuffer();
}

export async function decompressGzip(bytes) {
    const ds = new DecompressionStream("gzip");
    
    const writer = ds.writable.getWriter();
    
    writer.write(bytes);
    writer.close();
    
    const buffer = await new Response(ds.readable).arrayBuffer()

    return new TextDecoder().decode(buffer)
}

export async function base64DecToArr(sBase64, nBlocksSize) {
    const sB64Enc = sBase64.replace(/[^A-Za-z0-9+/]/g, ""); // Remove any non-base64 characters, such as trailing "=", whitespace, and more.
    const nInLen = sB64Enc.length;
    const nOutLen = nBlocksSize
        ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize
        : (nInLen * 3 + 1) >> 2;
    const taBytes = new Uint8Array(nOutLen);

    let nMod3;
    let nMod4;
    let nUint24 = 0;
    let nOutIdx = 0;
    for (let nInIdx = 0; nInIdx < nInLen; nInIdx++) {
        nMod4 = nInIdx & 3;
        nUint24 |= await b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (6 * (3 - nMod4));
        if (nMod4 === 3 || nInLen - nInIdx === 1) {
        nMod3 = 0;
        while (nMod3 < 3 && nOutIdx < nOutLen) {
            taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
            nMod3++;
            nOutIdx++;
        }
        nUint24 = 0;
        }
    }

    return taBytes;
}

async function b64ToUint6(nChr) {
    return nChr > 64 && nChr < 91
    ? nChr - 65
    : nChr > 96 && nChr < 123
    ? nChr - 71
    : nChr > 47 && nChr < 58
    ? nChr + 4
    : nChr === 43
    ? 62
    : nChr === 47
    ? 63
    : 0;
}