<template>
  <v-container fluid class="pa-4">
    <v-card class="card-ui pa-4">
      <v-card-title>Custom Root Events</v-card-title>

      <DataFilters
        :items="customRootEventsWithPgName"
        :schema="filtersSchema"
        v-model="filteredEvents"
        class="card-ui"
      >
        <template v-slot:header>
          <v-btn
            color="primary"
            @click="tagAsDiscarded"
            :disabled="!selectedRows?.length"
          >Mark As Not Of General Interest</v-btn>
          <v-btn
            color="primary"
            @click="untagAsDiscarded"
            :disabled="!selectedRows?.length"
          >Mark As Of General Interest</v-btn>
        </template>
      </DataFilters>

      <v-data-table
        :headers="customRootEventsHeaders"
        :items="filteredEvents"
        :items-per-page="100"
        :footer-props="{
        itemsPerPageOptions: [100, 500,  -1],
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus'
        }"
        show-select
        sort-by="label"
        v-model="selectedRows"
        >

        <template v-slot:header.data-table-select>
        </template>

        <template v-slot:item.dates="{ item }">
          <v-chip color="primary" v-for="date of item.dates" small>
            {{ date.id }} - {{ date.value }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            :disabled="!isSelected(item)"
            class="ma-2"
            text
            icon
            color="blue lighten-2"
            dark
            @click="openDialog(item.id)"
            >
            <!-- <v-icon style="--fa-animation-duration: 10s;">fa-solid fa-cog fa-spin</v-icon> -->
        <v-icon>fa-solid fa-cog</v-icon>
          </v-btn>
        </template>
      </v-data-table>


      <v-dialog
        v-model="showDialog"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Convert to smartpricing event
          </v-card-title>

        <v-card-text>
          <EventForm
            v-if="showDialog"
            type="add"
            :default-event="itemToEdit"
            :geocoding-pgid-list="selectedPgList"
            v-on:save-success="saveSuccess"
          />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import EventForm from '../components/EventForm'
import DataFilters from '@/components/utils/DataFilters.vue';

export default {
    name: 'CustomRootEvents',
    components: {EventForm, DataFilters},
    data: function () {
      return {
        tab: null,
        items: [],
        showDialog: false,
        itemToEdit: null,

        filteredEvents: [],
        customRootEventsHeaders: [
          {
            text: 'Event ID',
            value: 'id'
          },
          {
            text: 'Label',
            value: 'label'
          },
          {
            text: 'Date',
            value: 'dates'
          },
          {
            text: 'PG ID',
            value: 'pgId'
          },
          {
            text: 'PG Name',
            value: 'pgName'
          },
          {
            text: 'Is Not of General Interest',
            value: 'notOfGeneralInterest'
          },
          {
            text: 'Convert To Smartpricing Event',
            value: 'actions',
            sortable: false
          }
        ],

        filtersSchema: [
          {
            text: 'PG ID',
            value: 'pgId'
          },
          {
            text: 'Not Of General Interest',
            value: 'notOfGeneralInterest',
            defaultFilters: [false],
          },
        ],

        selectedRows: [],
      }
    },
    computed: {
      ...mapGetters({ 
        customRootEvents: 'graph/customRootEvents',
        customRootEventsMapped: 'graph/customRootEventsMapped',
      }),
      customRootEventsWithPgName(){
        
        const pgsHashMap = JSON.parse(JSON.stringify(this.$store.getters["core/pgNameById"]))            
        for ( let event of this.customRootEventsMapped ) {
            event.pgName = pgsHashMap[parseInt(event.pgId)]               
        }
        return this.customRootEventsMapped
      },
      selectedDates(){
        const obj = this.selectedRows.reduce((acc, curr) => {
          const dates = JSON.parse(curr.dates)

          dates.forEach(d => {
            acc[d.id] = acc[d.id] || []
            acc[d.id].push(d.value)
          })

          return acc
        }, {})

        return Object.entries(obj).map(([key, value]) => {
          return {
            kind: "Date",
            id: key,
            value: (value.reduce((a,b) => a + b) / value.length).toFixed(2)
          }
        })
      },
      selectedPgList(){
        return [...new Set(this.selectedRows.map(r => r.pgId))]
      }
    },
    methods: {
      openDialog(id){
        this.showDialog = true
        this.itemToEdit = structuredClone(this.customRootEvents.find(el => el.metadata.id === id))
        this.itemToEdit.link.EventDate = this.selectedDates
      },
      closeDialog(){
        this.showDialog = false
        this.itemToEdit = null
      },
      async tagAsDiscarded() {
        try {
          const res = []
  
          await Promise.all(this.selectedRows.map(async (selected) => {
            const wholeEvent = structuredClone(this.customRootEvents.find(el => el.metadata.id === selected.id))
  
            wholeEvent.spec.notOfGeneralInterest = true
  
            const r = await this.$store.dispatch('graph/editEvent', wholeEvent)
            res.push(r)
          }))
  
          if(res.every(r => r.success)){
            this.$store.dispatch('graph/fetchEvents')
            this.selectedRows = []
          }
        } catch (error) {
          console.error(e)
          console.log(`Something went wrong: `, res)
        }
      },
      async untagAsDiscarded() {
        try {
          const res = []
  
          await Promise.all(this.selectedRows.map(async (selected) => {
            const wholeEvent = structuredClone(this.customRootEvents.find(el => el.metadata.id === selected.id))
  
            wholeEvent.spec.notOfGeneralInterest = false
  
            const r = await this.$store.dispatch('graph/editEvent', wholeEvent)
            res.push(r)
          }))
  
          if(res.every(r => r.success)){
            this.$store.dispatch('graph/fetchEvents')
            this.selectedRows = []
          }
        } catch (error) {
          console.error(e)
          console.log(`Something went wrong: `, res)
        }
      },
      async saveSuccess(parentId){
        const res = []
        try {
          await Promise.all(
            [...await this.selectedRows].map(async e => {
              const eventToSave = structuredClone(this.customRootEvents.find(el => el.metadata.id === e.id))

              eventToSave.spec.isCreatedByUser = false
              eventToSave.spec.childEventType = 'user'
              eventToSave.link.RootEvent = [
                {
                  id: parentId,
                  value: 1,
                  kind: "Event"
                }
              ]

              eventToSave.preventFetch = true

              const r = await this.$store.dispatch('graph/editEvent', eventToSave)
              res.push(r)
            })
          )
          if(res.every(r => r.success)){
            this.$store.dispatch('graph/fetchEvents')
            this.selectedRows = []
            this.closeDialog()
          }
        } catch (e) {
          console.error(e)
          console.log(`Something went wrong: `, res)
        }
      },
      isSelected(item){
        return this.selectedRows.includes(item)
      }
    },
}
</script>
