<template>
    <perfect-scrollbar>
        <v-card
            class="pa-4"
            v-if="!errorMessages.length"
        >
            <v-card-actions>
                <v-btn
                    @click="addRoomRate"
                    class="primary"    
                    :disabled="!editable"
                >
                    Add Room Rate
                </v-btn>
                <v-tooltip
                    v-model="showInfo"
                    bottom
                    color="blue-grey darken-4"
                    max-width="50%"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            class="ml-auto"
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon small >fa-solid fa-circle-info</v-icon>
                        </v-btn>
                    </template>
                    <span>
                       The room rate allows you to set the min and max price for a property.
                    </span>
                </v-tooltip>
            </v-card-actions>
                
            <v-row class="py-2">
                <v-col class="col-12" v-for="(roomRate, index) in roomRatesList">
                    <v-card class="card-ui pa-4">
                        <v-row>
                            <v-col class="col-5">
                                <v-autocomplete
                                    outlined
                                    dense
                                    :items="propertiesDropdown(Number(roomRate.property_id))"
                                    :disabled="!editable"
                                    hide-details
                                    label="Property"
                                    :value="roomRate.property_id"
                                    @change="[roomRatesList[index].property_id = Number($event), updateYaml()]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-3">
                                <v-text-field
                                    outlined
                                    dense
                                    :disabled="!editable"
                                    type="number"
                                    label="Min"
                                    hide-details
                                    :value="roomRate.min"
                                    @change="[roomRatesList[index].min = Number($event), updateYaml()]"
                                ></v-text-field>
                            </v-col>
                            <v-col class="col-3">
                                <v-text-field
                                    outlined
                                    dense
                                    :disabled="!editable"
                                    type="number"
                                    label="Max"
                                    hide-details
                                    :value="roomRate.max"
                                    @change="[roomRatesList[index].max = Number($event), updateYaml()]"
                                ></v-text-field>
                            </v-col>
                            <v-col class="col-1">
                                <v-btn
                                    icon
                                    @click="[roomRatesList.splice(index), updateYaml()]"
                                >
                                    <v-icon>fas fa-trash-alt</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            
        </v-card>
        <v-alert type="warning" v-else>
            <ul>
                <li v-for="error in errorMessages">
                    {{ error }}
                </li>
            </ul>
        </v-alert>
    </perfect-scrollbar>
</template>

<script>
import {mapGetters, mapActions} from "vuex"

export default {
    name: "NewRoomsRatesEditor",
    props: ["editable"],
    components: {},
    data(){
        return {
            showInfo: false,

            roomRatesList: [],
            errorMessages: [],
        }
    },
    computed: {
        ...mapGetters({
            allowedProperties: "pipelineV1/allowedProperties",
            codeToRenderObj: "editor/codeToRenderObj",
        }),
        alreadyPresentProperties() {
            return this.roomRatesList.map(val => val.property_id)
        },
        propertiesDropdown() {
            return function(currentProp) {
                return this.allowedProperties.map(prop => {
                    return {
                        text: `${prop.metadata.id} - ${prop.metadata.name}`,
                        value: Number(prop.metadata.id),
                    }
                }).filter(prop => {
                    const props = structuredClone(this.alreadyPresentProperties)

                    const index = props.findIndex(val => val === currentProp)

                    if (index !== -1) {
                        props.splice(index, 1)
                    }

                    if (!props.includes(prop.value)) {
                        return true
                    }
                })
            }
        },
        allowedPropertiesIds() {
            return this.allowedProperties.map(prop => Number(prop.metadata.id))
        }
    },
    methods: {
        ...mapActions({
            writeCodeToRender: "editor/writeCodeToRender",
        }),
        addRoomRate() {
            this.roomRatesList.push({
                property_id: null,
                min: 0,
                max: 0,
            })
        },
        updateYaml() {
            const editorCode = structuredClone(this.codeToRenderObj)

            delete editorCode.global.price_range
            if (!!this.roomRatesList.length) {
                editorCode.global.price_range = structuredClone(this.roomRatesList).filter(roomRate => {
                    return !!roomRate.property_id && (!!roomRate.max || !!roomRate.min) 
                })
            }

            this.writeCodeToRender(editorCode)
        },
        checkErrors() {
            // check for unknown properties
        
            const roomRates = this.codeToRenderObj?.global?.price_range

            // check for wrong properties
            roomRates?.map(val => val.property_id).forEach(propId => {
                if (!this.allowedPropertiesIds.includes(propId)) {
                    this.errorMessages.push(`Property ${propId} doesn't exist inside the general settings, remove it!`)
                }
            })

            // check for indexed_min and indexed_max properties (maybe to do or not)

        }
    },
    created() {
        this.checkErrors()

        if (!this.errorMessages.length) {
            this.roomRatesList = structuredClone(this.codeToRenderObj?.global?.price_range) || []
        }
    },
    destroyed() {
        this.updateYaml()
    }
}
</script>