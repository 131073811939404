<template>
    <v-row>
        <v-col>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                    label="Insert from which day send upselling email before checkin (leave empty if no config)"
                    v-model="beforeCheckinMail" type="number"></v-text-field>
                <v-text-field
                    label="Insert from which day send upselling email after booking (leave empty if no config)"
                    v-model="afterCreationMail" type="number"></v-text-field>
                <v-text-field
                    label="Insert from which day send upselling whats before checkin (leave empty if no config)"
                    v-model="beforeCheckinWhats" type="number"></v-text-field>
                <v-text-field
                    label="Insert from which day send upselling whats after booking (leave empty if no config)"
                    v-model="afterCreationWhats" type="number"></v-text-field>
            </v-form>
            <div class="d-flex justify-end mt-2">
                <v-btn :disabled="!valid" color="primary" class="mr-4" @click="nextStep" :loading="loading">
                    Submit
                </v-btn>
                <v-btn color="secondary" @click="$emit('back')">
                    Back
                </v-btn>
            </div>
            <ConfirmDialog ref="dialog" />
        </v-col>
    </v-row>
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import ConfirmDialog from '@/components/utils/ConfirmDialog'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'ConfigUpsellingAutomationStep',
    components: { ConfirmDialog },
    data: function () {
        return {
            loading: false,
            valid: true,
            beforeCheckinMail: null,
            afterCreationMail: null,
            beforeCheckinWhats: null,
            afterCreationWhats: null
        }
    },
    computed: {
        ...mapGetters({
            upsellingPgId: 'smartpaying/upsellingPgId',
        })
    },
    methods: {
        async nextStep() {
            const isValid = this.$refs.form.validate()
            if (isValid) {
                this.loading = true
                const resp = await this.$store.dispatch('smartpaying/setUpsellingConfig', { link: null, internal: null, freedome: null, tiqets: null, afterCreationMail: this.afterCreationMail, beforeCheckinMail: this.beforeCheckinMail, beforeCheckinWhats: this.beforeCheckinWhats, afterCreationWhats: this.afterCreationWhats })
                if (resp.response?.status >= 400) {
                    const options = { cardTextClass: "pa-4 orange accent-1--text", color: 'orange' }
                    const title = "Error sending test message"
                    const message = resp.response.data
                    await this.$refs.dialog.open(title, message, options)
                }
                else {
                    const options = { cardTextClass: "pa-4 accent-1--text", color: 'success' }
                    const title = `Upselling automations activated for pg ${this.upsellingPgId}`
                    const message = ``
                    await this.$refs.dialog.open(title, message, options)
                }
                this.loading = false
                this.$emit('next')
            }

        }
    }
})
</script>