<template>
    <v-container class="pa-4" fluid>
        <v-row>
            <v-col class="col-12">
                <v-card class="card-ui pa-4">
                    <v-row>
                        <v-col class="col col-6">
                            <v-card-title>Manage Users</v-card-title>
                        </v-col>
                        <v-col class="col col-6 pa-7">
                            <v-dialog
                                transition="dialog-bottom-transition"
                                max-width="600"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                        >Get by Property Group</v-btn
                                    >
                                </template>
                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar color="primary" dark
                                            >Get User by Property
                                            Group</v-toolbar
                                        >

                                        <v-card-text class="pa-12">
                                            <v-row>
                                                <v-col class="col col-12">
                                                    <v-autocomplete
                                                        label="Select Property Group"
                                                        outlined
                                                        dense
                                                        @change="selectPGFind()"
                                                        :items="
                                                            $store.getters[
                                                                'core/pgsDropdown'
                                                            ]
                                                        "
                                                        item-text="text"
                                                        item-value="value"
                                                        v-model="pgselected"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col class="col col-12">
                                                    <v-autocomplete
                                                        label="Select user"
                                                        :items="
                                                            $store.state.users
                                                                .fetchedUsers
                                                        "
                                                        item-text="text"
                                                        item-value="value"
                                                        outlined
                                                        dense
                                                        v-model="selectedUser"
                                                        @change="changeUser()"
                                                    ></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                        <v-card-actions class="justify-end">
                                            <v-btn
                                                text
                                                @click="dialog.value = false"
                                                >Close</v-btn
                                            >
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-card-text>
                        <v-form ref="form" v-model="validForm">
                            <v-row>
                                <v-col class="col col-6">
                                    <v-autocomplete
                                        label="Select user to edit"
                                        outlined
                                        dense
                                        :items="userList"
                                        item-text="username"
                                        item-value="id"
                                        @change="changeUser"
                                        :rules="[rules.required]"
                                        clearable
                                        v-model="selectedUser"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col col-6">
                                    <v-text-field
                                        label="New email"
                                        outlined
                                        dense
                                        @change="updateUser(user)"
                                        :disabled="!selectedUser"
                                        :rules="[
                                            rules.required,
                                            rules.spacesAtTheEnd,
                                            rules.spacesAtTheStart,
                                        ]"
                                        v-model="user.username"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col col-6">
                                    <v-autocomplete
                                        label="Associated property groups"
                                        outlined
                                        dense
                                        @change="updateUser(user)"
                                        :items="
                                            $store.getters['core/pgsDropdown']
                                        "
                                        item-text="text"
                                        item-value="value"
                                        multiple
                                        small-chips
                                        chips
                                        :disabled="!selectedUser"
                                        v-model="user.relatedPGsIds"
                                        deletable-chips
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-3">
                                    <v-autocomplete
                                        label="Select Role"
                                        outlined
                                        clearable
                                        dense
                                        :items="rolesOptions"
                                        :disabled="!selectedUser"
                                        @change="updateUser(user)"
                                        v-model="user.role"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-3">
                                    <v-autocomplete
                                        label="Select version"
                                        :items="versionsList"
                                        item-text="label"
                                        item-value="id"
                                        outlined
                                        :disabled="!selectedUser"
                                        dense
                                        @change="updateUser(user)"
                                        v-model="user.versionID"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn color="primary" @click="checkForm">
                            Edit User
                        </v-btn>
                        <v-btn
                            color="secondary"
                            @click="resetChanges"
                            :disabled="!selectedUser"
                        >
                            Reset Changes
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-dialog
                            transition="dialog-bottom-transition"
                            max-width="800"
                            v-if="!!canReceiveEmail"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                >
                                    SEND QUESTIONNAIRE EMAIL
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-card>
                                    <v-toolbar color="primary" dark>
                                        Send Questionnaire Email
                                    </v-toolbar>

                                    <v-card-text class="pa-4">
                                        The PG is located in {{ userCountry }}.
                                    </v-card-text>
                                    <v-select
                                        class="pa-4"
                                        dense
                                        outlined
                                        hide-details
                                        :items="availableEmailLanguage"
                                        label="Select Language For Email"
                                        v-model="emailLanguage"
                                    >
                                    </v-select>

                                    <v-card-actions class="justify-end">
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="
                                                [
                                                    sendQuestionnaireNotification(),
                                                    (dialog.value = false),
                                                ]
                                            "
                                        >
                                            Send Email
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="error"
                                            @click="dialog.value = false"
                                        >
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>

                        <v-btn class="ml-auto" color="secondary" :disabled="!selectedUser" @click="showTokenGenerator = !showTokenGenerator">Token Generator</v-btn>
                    </v-card-actions>
                    
                    <v-divider v-if="showTokenGenerator" class="py-4"></v-divider>
                    <v-sheet elevation="0" v-if="showTokenGenerator" class="card-ui pa-2" >
                        <v-row>
                            <v-col class="col col-6">
                                <v-autocomplete
                                    label="Select the environment"
                                    outlined
                                    dense
                                    :items="[ {text: 'Production', value: 'admin'}, {text: 'Staging', value: 'staging'} ]"
                                    v-model="selectedEnvironment"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col col-6">
                                <v-text-field
                                    label="Token"
                                    outlined
                                    dense
                                    v-model="generatedToken"
                                    persistent-hint
                                    readonly
                                    class="pointer"
                                    :hint="copiedToken ? `copied to clipboard` : ``"
                                    append-icon="far fa-copy"
                                    @click="openevent($event)"
                                    @click:append="copyevent($event)"
                                ></v-text-field>
                            </v-col>
                            <v-col class="col col-12">
                                <v-btn
                                    color="primary"
                                    :disabled="!selectedEnvironment"
                                    @click="generateToken"
                                >
                                    Generate Token
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-card>
            </v-col>

            <v-col>
                <v-card class="card-ui pa-4">
                    <v-col class="col-12">
                        <v-card flat class="pl-3 card-ui">
                            <v-card-title>Payment Status</v-card-title>
                            <v-row>
                                <v-col class="col-6">
                                    <v-alert outlined :type="this.userPaymentStatusSmartpricing?.color">
                                        <b>Smartpricing: </b> {{ this.userPaymentStatusSmartpricing?.label }}
                                    </v-alert>
                                </v-col>

                                <v-col class="col-6">
                                    <v-alert outlined :type="this.userPaymentStatusSmartpaying?.color">
                                        <b>Smartpaying: </b> {{ this.userPaymentStatusSmartpaying?.label }}
                                    </v-alert>
                                </v-col>

                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-col>

            <v-col class="col-12">
                <v-card class="card-ui pa-4">
                    <v-card-title>Disable User</v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col class="col col-6">
                                <v-autocomplete
                                    label="Select user to disable"
                                    outlined
                                    dense
                                    :items="userList"
                                    item-text="username"
                                    item-value="id"
                                    v-model="userToDisable"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="error"
                            :disabled="!userToDisable"
                            @click="disableUser"
                        >
                            Disable User
                        </v-btn>
                        <div v-if="userToDisable" class="error--text mx-2">
                            Attention, It won't ask for confirmation so it will
                            disable user straight away
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-overlay :value="confirmOverlay">
            <v-card class="mx-auto pa-5 card-ui" max-width="500">
                <v-card-text>
                    <p class="text-h4 text--primary">Confirm Editing User</p>
                    <div class="text--primary">
                        Confirm that you're editing the following User:
                        {{ user.username }}
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="saveUser()">
                        Save User
                    </v-btn>

                    <v-btn
                        color="red"
                        @click="confirmOverlay = !confirmOverlay"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            selectedUser: "",
            confirmOverlay: false,
            validForm: true,
            userToDisable: null,
            canReceiveEmail: false,

            userPropertyGroups: [],
            pgselected: null,

            showTokenGenerator: false,
            generatedToken: "",
            selectedEnvironment: "",
            copiedToken: false,

            availableEmailLanguage: ["it", "en", "de"],
            userCountry: "unknown",
            emailLanguage: null,

            rules: {
                spacesAtTheEnd: (value) => {
                    if (value && value.endsWith(" ")) {
                        return "Your input ends with a whitespace, change it!";
                    }
                },
                spacesAtTheStart: (value) => {
                    if (value && value.startsWith(" ")) {
                        return "Your input starts with a whitespace, change it!";
                    }
                },
                required: (value) => !!value || "Required.",
            },
        };
    },
    computed: {
        rolesOptions() {
            return this.$store.state.users.rolesOptions;
        },
        userList() {
            return this.$store.state.users.usersList;
        },
        versionsList() {
            return this.$store.state.users.versionsList;
        },
        user: {
            get() {
                return this.$store.state.users.selectedUser;
            },
        },
        userPaymentStatusSmartpricing() {
            console.log("<userPaymentStatusSmartpricing ", this.$store.state.users.selectedUserPaymentStatus.smartpricing)
            return this.$store.state.users.selectedUserPaymentStatus.smartpricing;
        },
        userPaymentStatusSmartpaying() {
            console.log("<userPaymentStatusSmartpaying ", this.$store.state.users.selectedUserPaymentStatus.smartpaying)
            return this.$store.state.users.selectedUserPaymentStatus.smartpaying;
        }
    },
    methods: {
        async generateToken () {
            this.generatedToken = "",
            this.generatedToken = await this.$store.dispatch("core/generateToken", {
                targetUsername: this.user.username,
				issuedBy: this.$store.state.auth.username,
                environment: this.selectedEnvironment || "staging",
            })
        },
        async openevent(event) {
            if (this.generatedToken) {
                window.open(this.generatedToken, "_blank");
            }
        },
        async copyevent(event){
            await navigator.clipboard.writeText(this.generatedToken)
            this.copiedToken = true
            setTimeout(() => this.copiedToken = false, 5000)
        },
        async changeUser() {
            this.generatedToken = "";

            this.$store.commit("users/resetSelectedUser");
            await this.$store.dispatch("users/getUserByID", {
                userID: this.selectedUser,
            });
            await this.$store.dispatch("users/getPGsByUserID", {
                userID: this.selectedUser,
            });
            await this.$store.dispatch("users/getPaymentStatusByUserID", {
                userID: this.selectedUser,
            });

            let pgData;
            let pgLifecycleData;
            let isQuestionnaireToSend = true;

            if (
                this.$store.state.users.selectedUser.relatedPGsIds?.length == 0
            ) {
                // if there are no pg related
                isQuestionnaireToSend = false;
            }
            this.userCountry = "unknown";
            this.canReceiveEmail = false;
            this.userPropertyGroups =
                this.$store.state.users.selectedUser.relatedPGsIds;

            // iterate over the related pgs and check if they are in onboarding state and if they don't have the typeform answers saved
            for (let pgId of this.$store.state.users.selectedUser
                .relatedPGsIds) {
                pgData = await this.$store.dispatch("core/fetchPropertyGroup", {
                    pgId: pgId,
                });
                pgLifecycleData = await this.$store.dispatch(
                    "core/fetchLifecycleStatus",
                    { pgId: pgId }
                );
                // until we found a country for one of the related pg we try to fetch the country
                if (this.userCountry == "unknown") {
                    let country = await this.$store.dispatch("graph/getOne", {
                        kind: "propertygroup",
                        id: pgId,
                    });
                    let rootlink = country?.items?.[0]?.link?.Root || [];
                    for (let link of rootlink) {
                        if (link.kind == "Location:City") {
                            const splittedLoc = link?.id?.split("_");
                            this.userCountry = splittedLoc[0];

                            if (
                                ["germany", "austria"].includes(
                                    this.userCountry
                                )
                            ) {
                                this.emailLanguage = "de";
                            } else if (
                                ["italy", "san.marino"].includes(
                                    this.userCountry
                                )
                            ) {
                                this.emailLanguage = "it";
                            } else {
                                this.emailLanguage = "en";
                            }
                        }
                    }
                }

                const isPgInPreOnboarding =
                    pgLifecycleData?.metadata?.lifecycleStateName ==
                    "preonboarding";
                const isPgWithoutTypeform = pgData?.spec?.typeform == null;
                const isPgInPreOnboardingAndWithoutTypeform =
                    isPgInPreOnboarding && isPgWithoutTypeform;
                // we want to send the questionnaire if all the pgs of the selected user are in oreonboarding state and they doen't have the typeform
                isQuestionnaireToSend =
                    isQuestionnaireToSend &&
                    isPgInPreOnboardingAndWithoutTypeform;

                // if atleast one pg doesn't satisfy the requirements we can exit
                if (isQuestionnaireToSend == false) {
                    break;
                }
            }
            this.canReceiveEmail = isQuestionnaireToSend;
        },
        updateUser(newUser) {
            this.$store.commit("users/updateSelectedUser", { user: newUser });
        },
        saveUser() {
            this.$store.dispatch("users/saveUser");
            this.confirmOverlay = !this.confirmOverlay;
        },
        resetChanges() {
            this.$store.commit("users/resetSelectedUser");
            this.$store.dispatch("users/getUserByID", {
                userID: this.selectedUser,
            });
            this.$store.dispatch("users/getPGsByUserID", {
                userID: this.selectedUser,
            });
        },
        sendQuestionnaireNotification() {
            this.$store.dispatch("users/sendQuestionnaireNotification", {
                userID: this.selectedUser,
                userEmail: this.user.username,
                language: this.emailLanguage,
                activationToken: this.user.activationToken,
                propertyGroupIds:
                    this.$store.state.users.selectedUser.relatedPGsIds || [],
            });
        },
        checkForm() {
            this.$refs.form.validate();

            if (this.validForm) {
                this.confirmOverlay = !this.confirmOverlay;
            }
        },
        async disableUser() {
            await this.$store.dispatch("users/removeLinkedPGs", {
                userID: this.userToDisable,
            });
            if (this.userToDisable === this.selectedUser) {
                await this.changeUser();
            }
        },
        selectPGFind() {
            this.$store.dispatch("users/fetchUserByPGID", {
                pgid: this.pgselected,
            });
        },
    },
    created() {
        this.$store.commit("users/resetSelectedUser");
        this.$store.dispatch("users/getAllUsers");
        this.$store.dispatch("users/getVersions");
    },
    destroyed() {
        this.$store.commit("users/resetFetchedUsers");
        this.generatedToken = ""
    },
};
</script>
