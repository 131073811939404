<template>
    <v-container fluid class="pa-4 grey darken-4">
        <v-btn
            @click="[addSchemaField(), $emit('input', valueCopy)]"
            color="primary"
        >Add Schema Field</v-btn>
        <v-card class="card-ui pa-4 my-3" v-for="(singleSchema, index1) of valueCopy">
            <v-row>
                <v-col class="auto">
                    <v-btn
                        icon
                        @click="[valueCopy.splice(index1, 1), $emit('input', valueCopy)]"
                    >
                        <v-icon>fas fa-trash-alt</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="col-4">
                    <v-autocomplete
                        outlined
                        dense
                        label="Field type"
                        :items="typeOptions"
                        :value="singleSchema.type"
                        @change="[valueCopy[index1].type = $event, changeTypeFormatting(index1, $event), $emit('input', valueCopy)]"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-4">
                    <v-text-field
                        outlined
                        dense
                        label="Label"
                        :value="singleSchema.label"
                        @input="[valueCopy[index1].label = $event, $emit('input', valueCopy)]"
                    ></v-text-field>
                </v-col>
                <v-col class="col-3">
                    <v-text-field
                        outlined
                        dense
                        label="Field Name"
                        :error-messages="alreadyExistingFieldName(singleSchema.fieldname, index1)"
                        :value="singleSchema.fieldname"
                        @input="[valueCopy[index1].fieldname = $event, $emit('input', valueCopy)]"
                    ></v-text-field>
                </v-col>
                <v-col class="col-4">
                    <v-autocomplete
                        outlined
                        dense
                        label="Rules"
                        multiple
                        :items="rulesOptions.filter(val => rulesForEveryType[singleSchema.type].includes(val.value))"
                        :value="singleSchema.rules"
                        @change="[valueCopy[index1].rules = $event, $emit('input', valueCopy)]"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-4">
                    <v-text-field
                        outlined
                        dense
                        label="Hint"
                        :value="singleSchema.hint"
                        @input="[valueCopy[index1].hint = $event, $emit('input', valueCopy)]"
                    ></v-text-field>
                </v-col>
                <v-col class="col-2">
                    <v-text-field
                        outlined
                        dense
                        label="Class"
                        :value="singleSchema.class"
                        @input="[valueCopy[index1].class = $event, $emit('input', valueCopy)]"
                    ></v-text-field>
                </v-col>
                <v-col class="col-2">
                    <v-text-field
                        outlined
                        dense
                        label="Column Class"
                        :value="singleSchema.colClass"
                        @input="[valueCopy[index1].colClass = $event, $emit('input', valueCopy)]"
                    ></v-text-field>
                </v-col>
    
                <v-col v-if="['autocomplete', 'select'].includes(singleSchema.type)" class="col-12">
                    <v-divider></v-divider>
                </v-col>
    
                <v-col v-if="['autocomplete', 'select'].includes(singleSchema.type)" class="col-12">
                    <!-- Add options to dropdown -->
                    <v-btn 
                        color="primary"
                        @click="[addItem(index1), $emit('input', valueCopy)]"
                    >Add Item</v-btn>
                </v-col>
    
                <v-col v-if="['autocomplete', 'select'].includes(singleSchema.type) && !!singleSchema.items.length"  class="col-12">
                    <v-row v-for="(item, index2) in singleSchema.items">
                        <v-col class="col-4">
                            <v-text-field
                                outlined
                                dense
                                label="Label"
                                :value="item.text"
                                @input="[valueCopy[index1].items[index2].text = $event, $emit('input', valueCopy)]"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-3">
                            <v-select
                                outlined
                                dense
                                label="Type"
                                :items="itemTypeOptions"
                                :value="item.type"
                                @change="[valueCopy[index1].items[index2].type = $event, changeItemTypeFormatting(index1, index2, $event), $emit('input', valueCopy)]"
                            ></v-select>
                        </v-col>
                        <v-col class="col-4">
                            <v-text-field
                                v-if="item.type === 'number'"
                                type="number"
                                outlined
                                dense
                                label="Value"
                                :error-messages="alreadyExistingDropdownOption(valueCopy[index1], index2, item.value, item.type)"
                                :value="item.value"
                                @input="[valueCopy[index1].items[index2].value = Number($event),  $emit('input', valueCopy)]"
                            ></v-text-field>
                            <v-text-field
                                v-else
                                outlined
                                dense
                                label="Value"
                                :error-messages="alreadyExistingDropdownOption(valueCopy[index1], index2, item.value, item.type)"
                                :value="item.value"
                                @input="[valueCopy[index1].items[index2].value = String($event), $emit('input', valueCopy)]"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-1"> <!-- Delete -->
                            <v-btn
                                icon
                                @click="[valueCopy[index1].items.splice(index2, 1), $emit('input', valueCopy)]"
                            >
                                <v-icon>fas fa-trash-alt</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
    
                <v-col class="col-12">
                    <v-divider></v-divider>
                </v-col>
    
                <v-col class="col-12" v-if="['string', 'password'].includes(singleSchema.type)">
                    <v-text-field
                        outlined
                        dense
                        label="Defult Value"
                        clearable
                        :value="singleSchema.value"
                        @input="[valueCopy[index1].value = $event, $emit('input', valueCopy)]"
                    ></v-text-field>
                </v-col>
                <v-col class="col-12" v-else-if="['base64', 'asaAndXenus'].includes(singleSchema.type)">
                    <v-text-field
                        outlined
                        dense
                        persistent-hint
                        hint="This value needs to be already encoded in base 64"
                        label="Defult Value"
                        clearable
                        :value="singleSchema.value"
                        @input="[valueCopy[index1].value = $event, $emit('input', valueCopy)]"
                    ></v-text-field>
                </v-col>
                <v-col class="col-12" v-else-if="singleSchema.type === 'number'">
                    <v-text-field
                        outlined
                        dense
                        label="Defult Value"
                        clearable
                        :value="singleSchema.value"
                        @change="[valueCopy[index1].value = Number($event), $emit('input', valueCopy)]"
                    ></v-text-field>
                </v-col>
                <v-col class="col-12" v-else-if="singleSchema.type === 'bool'">
                    <v-switch
                        outlined
                        dense
                        label="Default Value"
                        clearable
                        :true-value="true"
                        :false-value="false"
                        :input-value="!!singleSchema.value"
                        @change="[valueCopy[index1].value = !!$event, $emit('input', valueCopy)]"
                    ></v-switch>
                </v-col>
                <v-col class="col-12" v-else> <!-- Case for the dropdowns -->
                    <v-autocomplete
                        outlined
                        dense
                        label="Default Value"
                        clearable
                        :items="singleSchema.items"
                        :value="singleSchema.value"
                        @change="[valueCopy[index1].value = $event, $emit('input', valueCopy)]"
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
export default {
    props: ["value"], // the array with the schema for the dynamic form
    data() {
        return {
            valueCopy: [],

            typeOptions: [
                {text: "String", value: "string"},
                {text: "Number", value: "number"},
                {text: "Bool", value: "bool"},
                {text: "Autocomplete", value: "autocomplete"},
                {text: "Select", value: "select"},
                {text: "Password", value: "password"},
                {text: "Base 64", value: "base64"},
                {text: "Asa & Xenus", value: "asaAndXenus"},
            ],
            rulesOptions: [
                {text: "Above Zero", value: "aboveZero"},
                {text: "Required Value", value: "required"},
                {text: "No Trailing Spaces", value: "noTrailingSpaces"},
            ],
            itemTypeOptions: [
                {text: "Number", value: "number"},
                {text: "String", value: "string"},
            ],

            fieldsForEveryType: {
                string: ["type", "label", "fieldname", "class", "hint", "rules", "disabled", "value", "colClass"],
                number: ["type", "label", "fieldname", "class", "hint", "rules", "disabled", "value", "colClass"],
                bool: ["type", "label", "fieldname", "class", "hint", "rules", "disabled", "value", "colClass"],
                autocomplete: ["type", "label", "fieldname", "class", "hint", "rules", "items", "disabled", "value", "colClass"],
                select: ["type", "label", "fieldname", "class", "hint", "rules", "items", "disabled", "value", "colClass"],
                password: ["type", "label", "fieldname", "class", "hint", "rules", "disabled", "value", "colClass"],
                base64: ["type", "label", "fieldname", "class", "hint", "rules", "disabled", "value", "colClass"],
                asaAndXenus: ["type", "label", "fieldname", "class", "hint", "rules", "disabled", "value", "colClass"],
            },

            rulesForEveryType: {
                string: ["required", "noTrailingSpaces"],
                number: ["required", "aboveZero"],
                bool: ["required"],
                autocomplete: ["required"],
                select: ["required"],
                password: ["required", "noTrailingSpaces"],
                base64: ["required", "noTrailingSpaces"],
                asaAndXenus: ["required", "noTrailingSpaces"],
            },

            defaultValuesForKeysByType: {
                string: {
                    type: "string",
                    label: "",
                    class: "",
                    fieldname: "",
                    hint: "",
                    rules: [],
                    disabled: false,
                    value: "",
                    colClass: "col-12",
                },
                number: {
                    type: "number",
                    label: "",
                    class: "",
                    hint: "",
                    fieldname: "",
                    rules: [],
                    disabled: false,
                    value: 0,
                    colClass: "col-12",
                },
                bool: {
                    type: "bool",
                    label: "",
                    class: "",
                    hint: "",
                    fieldname: "",
                    rules: [],
                    disabled: false,
                    value: false,
                    colClass: "col-12",
                },
                autocomplete: {
                    type: "autocomplete",
                    label: "",
                    class: "",
                    hint: "",
                    fieldname: "",
                    rules: [],
                    items: [],
                    disabled: false,
                    value: null,
                    colClass: "col-12",
                },
                select: {
                    type: "select",
                    label: "",
                    class: "",
                    hint: "",
                    rules: [],
                    fieldname: "",
                    items: [],
                    disabled: false,
                    value: null,
                    colClass: "col-12",
                },
                password: {
                    type: "password",
                    label: "",
                    class: "",
                    fieldname: "",
                    hint: "",
                    rules: [],
                    disabled: false,
                    value: "",
                    colClass: "col-12",
                },
                base64: {
                    type: "base64",
                    label: "",
                    class: "",
                    fieldname: "",
                    hint: "",
                    rules: [],
                    disabled: false,
                    value: "",
                    colClass: "col-12",
                },
                asaAndXenus: {
                    type: "asaAndXenus",
                    label: "",
                    class: "",
                    fieldname: "",
                    hint: "",
                    rules: [],
                    disabled: false,
                    value: "",
                    colClass: "col-12",
                },
            },

            defaultValuesForItemsByType: {
                number: 0,
                string: "",
            }
        }
    },
    watch: {
        value:{
            handler: function (newVal, oldVal) {
                this.valueCopy = structuredClone(this.value || [])
            },
            deep: true,
        }
    },
    methods: {
        addSchemaField() {
            this.valueCopy.push({
                type: "string",
                label: "",
                class: "",
                fieldname: "",
                hint: "",
                rules: [],
                disabled: false,
                value: "",
                colClass: "col-12",
            })
        },
        addItem(index) {
            this.valueCopy[index].items.push({
                text: "",
                type: "string",
                value: "",
            })
        },
        changeTypeFormatting(index, type) {
            const validKeys = this.fieldsForEveryType[type]

            // deletes the key if not for that field type
            for (const [key, value] of Object.entries(this.valueCopy[index])) {
                if (!validKeys.includes(key) ) { 
                    delete this.valueCopy[index][key]   
                }
            }

            const defaultValuesForKeys = this.defaultValuesForKeysByType[type]

            // reset some values for specific keys
            this.valueCopy[index]["rules"] = structuredClone(defaultValuesForKeys["rules"])
            this.valueCopy[index]["value"] = structuredClone(defaultValuesForKeys["value"])

            // adds the keys not present for that field type
            validKeys.forEach(key => {
                if (this.valueCopy[index][key] === undefined) {
                    this.valueCopy[index][key] = structuredClone(defaultValuesForKeys[key])
                }
            })
        },
        changeItemTypeFormatting(index1, index2, type) {
            this.valueCopy[index1].items[index2].value = this.defaultValuesForItemsByType[type]
        },
        alreadyExistingFieldName(val, index) {
            const copy = structuredClone(this.valueCopy)
            copy.splice(index, 1)
            const existingFieldNames = copy.map(val => val.fieldname)
            return existingFieldNames.includes(val) ? ["Field Name already exists change it!"] : null
        },
        alreadyExistingDropdownOption(obj, indexOptions, value, type) {
            const newArray = structuredClone(obj.items)
            newArray.splice(indexOptions, 1)

            const hashMap = {}
            newArray.forEach(val => {
                if (hashMap[val.type] === undefined) {
                    hashMap[val.type] = []
                    hashMap[val.type].push(val.value)
                } else {
                    hashMap[val.type].push(val.value)
                }
            })

            return hashMap[type]?.includes(value) ? ["Dropdown option already exists change it!"] : null
        }
    },
    created() {
        this.valueCopy = structuredClone(this.value || [])
    }
}
</script>