import Vue from 'vue'
import Vuex from 'vuex'

// Standard modules
import UIModule from './core-modules/ui'
import AuthModule from './core-modules/auth'
import ApiModule from './core-modules/api'
import EditorModule from './core-modules/editor'

// Apps modules
import AppModule from '../apps/app/store/app'
import CoreModule from '../apps/core/store/core'
import PredictionChartsModule from '../apps/strategy/store/pipelineV1PredictCharts'
import PreprocessChartsModule from '../apps/strategy/store/pipelineV1PreprocessCharts'
import AlarmsModule from '../apps/alarms/store/alarms'
import GpcsModule from '../apps/gpcs/store/gpcs'
import EventLogsModule from '../apps/logs/store/log'
import DashboardLogsModule from '../apps/logs/store/dashboard'
import SmartpayingModule from '../apps/smartpaying/store/smartpaying'
import GraphModule from '../apps/graph/store/graph'
import ScraperModule from '../apps/scraper/store/scraper'
import MapsYamlEditor from '../apps/strategy/store/mapsYamlEditor'
import CustomerThresholdMapsEditor from '../apps/strategy/store/customerThresholdMapsEditor'
import TypeForm from '../apps/strategy/store/typeForm'
import UsersStore from '../apps/core/store/users'
import BasePrice from '../apps/strategy/store/basePrice'
import Analytics from '../apps/analytics/store/analytics'
import IntegrationsSpec from '../apps/integrations/store/integrationsSpec'
import MappingAndRates from "../apps/core/store/mappingAndRates"
import AlertSystem from './core-modules/alertSystem'
import Rates from "../apps/core/store/rates"
import Reservations from "../apps/analytics/store/reservations"
import Oauth from "../apps/core/store/oauth"
import pipelineV2 from '../apps/strategy/store/pipelineV2'
import pipelineV1 from '../apps/strategy/store/pipelineV1'
import pipelineV2Charts from '../apps/strategy/store/pipelineV2Charts'
import strategyNew from '../apps/strategy/store/strategyNew'
import Lifecycle from '../apps/core/store/lifecycle'
import Subscriptions from '../apps/core/store/subscriptions'
import Token from '../apps/core/store/token'
import Versori from '../apps/integrations/store/versori'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    users: UsersStore,
    ui: UIModule,
    alertSystem: AlertSystem,
    app: AppModule,
    auth: AuthModule,
    api: ApiModule,
    pipelineV1: pipelineV1,
    pipelineV2: pipelineV2,
    strategyNew: strategyNew,
    pipelineV2Charts: pipelineV2Charts,
    lifecycle: Lifecycle,
    core: CoreModule,
    reservations: Reservations,
    oauth: Oauth,
    rates: Rates,
    mappingAndRates: MappingAndRates,
    predictionCharts: PredictionChartsModule,
    preprocessCharts: PreprocessChartsModule,
    basePrice: BasePrice,
    alarms: AlarmsModule,
    gpcs: GpcsModule,
    log: EventLogsModule,
    dashboard: DashboardLogsModule,
    smartpaying: SmartpayingModule,
    scraper: ScraperModule,
    integrationsSpec: IntegrationsSpec,
    graph: GraphModule,
    editor: EditorModule,
    mapsYamlEditor: MapsYamlEditor,
    customerThresholdMapsEditor: CustomerThresholdMapsEditor,
    typeForm: TypeForm,
    analytics: Analytics,
    token: Token,
    subscriptions: Subscriptions,
    versori: Versori
  }
})
