var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-4",attrs:{"elevation":0}},[_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.addModifier()}}},[_vm._v("Add Modifier")]),_vm._t("header-buttons")],2)],1),_vm._l((_vm.modifiersListWithMenuInfo),function(modifier,index){return _c('v-col',{key:modifier.id,staticClass:"col-12 card-ui my-2"},[_c('v-row',[_c('v-col',{staticClass:"col-9"},[_c('v-row',[_c('v-col',{staticClass:"col-6"},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Modifier Type","items":_vm.modifierKindList,"disabled":!modifier.active || _vm.disabled,"value":modifier.kind},on:{"change":function($event){[
                                        _vm.changeModifierKind(index, $event),
                                        _vm.emitNewCalculation(),
                                    ]}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-content',_vm._g(_vm._b({domProps:{"textContent":_vm._s(data.item.text)}},'v-list-item-content',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.item.description || ""))])])]}}],null,true)})],1),_c('v-col',{staticClass:"col-6"},[(modifier.kind !== 'extend')?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"To Date Range","value":_vm.prettifyDateRange([
                                                modifier.startDate,
                                                modifier.endDate,
                                            ]),"readonly":"","error":modifier.active &&
                                            !_vm.prettifyDateRange([
                                                modifier.startDate,
                                                modifier.endDate,
                                            ]),"error-messages":modifier.active &&
                                            !_vm.prettifyDateRange([
                                                modifier.startDate,
                                                modifier.endDate,
                                            ])
                                                ? 'Required'
                                                : '',"disabled":!modifier.active || _vm.disabled}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(modifier.showRangeMenu),callback:function ($$v) {_vm.$set(modifier, "showRangeMenu", $$v)},expression:"modifier.showRangeMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"range":""},on:{"change":function($event){[
                                            _vm.updateModifier(
                                                index,
                                                'startDate',
                                                _vm.sortDateRange($event).start
                                            ),
                                            _vm.updateModifier(
                                                index,
                                                'endDate',
                                                _vm.sortDateRange($event).end
                                            ),
                                            _vm.emitNewCalculation(),
                                            _vm.closeToDateRangeMenu(
                                                modifier.id
                                            ),
                                        ]}},model:{value:(
                                        _vm.datePickerDateRanges[modifier.id]
                                    ),callback:function ($$v) {_vm.$set(_vm.datePickerDateRanges, modifier.id, $$v)},expression:"\n                                        datePickerDateRanges[modifier.id]\n                                    "}},[_c('div',{staticClass:"date-buttons"},[_c('div',{staticClass:"date-buttons-col"},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.resetStartDate(index)}}},[_vm._v("Reset Start")]),_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.resetEndDate(index)}}},[_vm._v("Reset End")]),_c('v-btn',{attrs:{"block":"","color":"red"},on:{"click":function($event){[
                                                        _vm.selectWholeDateRange(
                                                            index
                                                        ),
                                                        _vm.emitNewCalculation(),
                                                    ]}}},[_vm._v("Whole Date Range")])],1)])])],1):_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"To Date","value":modifier.toDate,"readonly":"","error":!modifier.toDate,"error-messages":!modifier.toDate
                                                ? 'Required'
                                                : ''}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(modifier.showMenu),callback:function ($$v) {_vm.$set(modifier, "showMenu", $$v)},expression:"modifier.showMenu"}},[_c('v-date-picker',{attrs:{"value":modifier.toDate,"first-day-of-week":1},on:{"change":function($event){[
                                            _vm.updateModifier(
                                                index,
                                                'toDate',
                                                $event
                                            ),
                                            _vm.emitNewCalculation(),
                                            _vm.closeToDateMenu(modifier.id),
                                        ]}}})],1)],1),(modifier.kind !== 'extend')?_c('v-col',{staticClass:"col-6"},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Properties Affected","multiple":"","disabled":!modifier.active || _vm.disabled,"items":_vm.currentPropertiesFiltered(index),"value":modifier.propertyIds,"chips":"","small-chips":""},on:{"change":function($event){[
                                        _vm.updateModifier(
                                            index,
                                            'propertyIds',
                                            $event
                                        ),
                                        _vm.emitNewCalculation(),
                                    ]}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){[
                                                _vm.selectAllProperties(index),
                                                _vm.emitNewCalculation(),
                                            ]}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"dense":"","color":modifier.propertyIds
                                                        .length > 0
                                                        ? 'indigo darken-4'
                                                        : ''}},[_vm._v(" "+_vm._s(_vm.icon(index))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}],null,true)})],1):_vm._e(),(
                                !['extend', 'overwrite'].includes(
                                    modifier.kind
                                )
                            )?_c('v-col',{staticClass:"col-6"},[(modifier.kind === 'copy')?_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Property to copy","disabled":!modifier.active || _vm.disabled,"items":_vm.propertiesDropdown,"value":modifier.fromPropertyId},on:{"change":function($event){[
                                        _vm.updateModifier(
                                            index,
                                            'fromPropertyId',
                                            $event
                                        ),
                                        _vm.emitNewCalculation(),
                                    ]}}}):_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Value","disabled":!modifier.active || _vm.disabled,"suffix":_vm.modifierSuffix(
                                        modifier.kind,
                                        modifier.value
                                    ),"rules":[_vm.rules.isNumber],"value":modifier.value},on:{"input":function($event){[
                                        _vm.updateModifier(
                                            index,
                                            'value',
                                            $event
                                        ),
                                        _vm.emitNewCalculation(),
                                    ]}}})],1):_vm._e()],1)],1),_c('v-col',{staticClass:"col-3"},[_c('v-row',[_c('v-col',{staticClass:"col-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"text":"","icon":"","disabled":index === 0},on:{"click":function($event){[
                                                _vm.moveModifier(
                                                    index,
                                                    index - 1
                                                ),
                                                _vm.emitNewCalculation(),
                                            ]}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-chevron-up")])],1)]}}],null,true)},[_c('span',[_vm._v("Move Up")])])],1),_c('v-col',{staticClass:"col-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){[
                                                _vm.deleteModifier(index),
                                                _vm.emitNewCalculation(),
                                            ]}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-trash-alt")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1),_c('v-col',{staticClass:"col-4 d-flex align-center justify-center"},[_vm._v(" "+_vm._s(index)+" ")]),_c('v-col',{staticClass:"col-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"text":"","icon":"","disabled":_vm.modifiersList.length - 1 ===
                                            index},on:{"click":function($event){[
                                                _vm.moveModifier(
                                                    index,
                                                    index + 1
                                                ),
                                                _vm.emitNewCalculation(),
                                            ]}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v("Move Down")])])],1),_c('v-col',{staticClass:"col-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){[
                                                _vm.copyModifier(index),
                                                _vm.emitNewCalculation(),
                                            ]}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("far fa-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy")])])],1),_c('v-col',{staticClass:"col-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{staticClass:"mt-2",on:{"change":function($event){return _vm.emitNewCalculation()}},model:{value:(modifier.active),callback:function ($$v) {_vm.$set(modifier, "active", $$v)},expression:"modifier.active"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(modifier.active || _vm.disabled ? "Disable" : "Enable"))])])],1)],1)],1)],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }