<template>
  <div class="home">
    <v-container fluid class="pa-0">
      <v-row>
        <v-col class="col-12" style="height: 100vh;">
          <iframe
            width="100%"
            height="100%"
            :src="reportUrl"
            frameborder="0"
            style="border:0"
            allowfullscreen
          ></iframe>
        </v-col>
      </v-row>
  </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'AlarmRetention',
  data: function () {
    return {
    }
  },
  computed: {
    reportUrl () {
      const baseUrl = 'https://datastudio.google.com/embed/reporting/862d794b-d726-452e-b8ea-5baaf0f2ad46/page/wdBAD'
      return baseUrl
    },
  },
}
</script>
