/**
 * Store for scraper
 */

export default {
    namespaced: true,

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {
        async submit(context, payload) {
            const api = context.rootState.api.api

            console.log(JSON.stringify(payload.job, null, 2))

            try {
                const jobRes = await api.put('scraper/v2', '', {}, payload.job, {})

                if (payload.cb !== undefined && typeof payload.cb == 'function') {
                    payload.cb(jobRes)
                }

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Job run failed: ${error}`
                }, { root: true })
            }
        },

        async search(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get('predictapi/v1', '/ght/search', payload.query, {}, {})

                if (payload.cb !== undefined && typeof payload.cb == 'function') {
                    payload.cb(res)
                }
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Search failed: ${error}`
                }, { root: true })
            }
        },
    }
}
