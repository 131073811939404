<template>
    <div id="pg-info-display">
        <v-card class="card-ui" v-if="currentPG !== null">
            <v-toolbar dense elevation="0" :class="{ 'grey darken-3': darkMode }">
                <v-card-title class="overline ml-0 pl-0">

                    <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                    <b class="grey--text darken-6">Property group config</b>
                </v-card-title>
                <v-spacer />
                <v-btn color="cyan" fab x-small class="elevation-0 mr-2" @click="editPropertyGroupDialog = true">
                    <v-icon small>fa-solid fa-edit</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col class="col-12">
                        <v-card flat class="card-ui pl-3 pa-2 pt-0">
                            <v-alert border="right" :class="{ 'grey darken-3': darkMode }" elevation="2"
                                style="height: 120px;">
                                <h3>{{ currentPG.metadata.name }} @ ID {{ currentPG.metadata.id }} </h3>
                                <p class="mt-2"> Created: {{ new Date(currentPG.spec.created_at).toLocaleString() }} </p>
                                <p> Updated: {{ new Date(currentPG.spec.updated_at).toLocaleString() }} </p>
                            </v-alert>
                        </v-card>
                    </v-col>
                    <v-col class="col-12">
                        <v-card flat class="pl-3 card-ui">
                            <v-row>
                                <v-col class="col-3">
                                    <v-alert outlined :type="currentPhysicalPS != null ? 'success' : 'red'"
                                        class="ma-0">Physical Propertyset Id: <br> <b>{{ currentPhysicalPS.metadata.id }}</b> </v-alert>
                                </v-col>
                                <v-col class="col-3">
                                    <v-alert outlined :type="currentPMSPS != null ? 'success' : 'red'"
                                        class="ma-0">PMS Propertyset Id: <br> <b>{{ currentPMSPS.metadata.id }}</b> </v-alert>
                                </v-col>
                                <v-col class="col-3">
                                    <v-alert outlined :type="currentCHMPS != null ? 'success' : 'red'"
                                        class="ma-0">CHM Propertyset Id: <br> <b>{{ currentCHMPS.metadata.id }}</b> </v-alert>
                                </v-col>
                                <v-col class="col-3">
                                    <v-alert outlined :type="currentStrategyPS != null ? 'success' : 'red'"
                                        class="ma-0">Strategy Propertyset Id: <br> <b>{{ currentStrategyPS.metadata.id }}</b> </v-alert>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>

                    <v-col class="col-12">
                        <v-card flat class="pl-3 card-ui">
                            <v-row>
                                <v-col class="col-4">
                                    <v-alert outlined :type="currentPG.spec.pms_name != null ? 'success' : 'red'"
                                        class="ma-0">PMS <b>{{ currentPG.spec.pms_name }}</b> </v-alert>
                                </v-col>
                                <v-col class="col-4">
                                    <v-alert outlined
                                        :type="currentPG.spec.channel_manager_name != null ? 'success' : 'red'"
                                        class="ma-0">Channel <b>{{ currentPG.spec.channel_manager_name }}</b> </v-alert>
                                </v-col>
                                <v-col class="col-4">
                                    <v-alert outlined style="cursor: pointer;"
                                        :type="currentHubspotDeal?.spec?.dealStageName ? `info` : `error`"
                                        @click="goToHubspotDeal(currentHubspotDeal?.metadata?.id)">
                                        Hubspot Deal Status: {{ currentHubspotDeal?.spec?.dealStageName || "Not Found" }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col class="pb-8 px-6 col-12"
                        v-if="currentLifecycleStatus['smartpricing'] !== null && currentLifecycleStatus['smartpricing']">
                        <v-row>
                            <v-col>
                                <v-alert outlined
                                    :type="currentLifecycleStatus?.['smartpricing']?.spec.fields.download.reservation.manual == true ? `success` : `error`">
                                    Download: manual
                                </v-alert>
                            </v-col>
                            <v-col>
                                <v-alert outlined
                                    :type="currentLifecycleStatus?.['smartpricing']?.spec.fields.download.reservation.cron == true ? `success` : `error`">
                                    Download: cron
                                </v-alert>
                            </v-col>
                            <v-col>
                                <v-alert outlined
                                    :type="currentLifecycleStatus?.['smartpricing']?.spec.fields.predict.price.manual == true ? `success` : `error`">
                                    Predict: manual
                                </v-alert>
                            </v-col>
                            <v-col>
                                <v-alert outlined
                                    :type="currentLifecycleStatus?.['smartpricing']?.spec.fields.predict.price.cron == true ? `success` : `error`">
                                    Predict: cron
                                </v-alert>
                            </v-col>
                            <v-col>
                                <v-alert outlined
                                    :type="currentLifecycleStatus?.['smartpricing']?.spec.fields.compute.price.intool == true ? `success` : `error`">
                                    Compute: intool
                                </v-alert>
                            </v-col>
                            <v-col>
                                <v-alert outlined
                                    :type="currentLifecycleStatus?.['smartpricing']?.spec.fields.compute.price.product == true ? `success` : `error`">
                                    Compute: product
                                </v-alert>
                            </v-col>
                            <v-col>
                                <v-alert outlined
                                    :type="currentLifecycleStatus?.['smartpricing']?.spec.fields.compute.price.cron == true ? `success` : `error`">
                                    Compute: cron
                                </v-alert>
                            </v-col>
                            <v-col>
                                <v-alert outlined
                                    :type="currentLifecycleStatus?.['smartpricing']?.spec.fields.send.price.intool == true ? `success` : `error`">
                                    Send: intool
                                </v-alert>
                            </v-col>
                            <v-col>
                                <v-alert outlined
                                    :type="currentLifecycleStatus?.['smartpricing']?.spec.fields.send.price.product == true ? `success` : `error`">
                                    Send: product
                                </v-alert>
                            </v-col>
                            <v-col>
                                <v-alert outlined
                                    :type="currentLifecycleStatus?.['smartpricing']?.spec.fields.send.price.cron == true ? `success` : `error`">
                                    Send: cron
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-row class="px-6 pb-4">
                        <v-col class="col-4" v-for="bundle in bundlesList" :key="bundle">
                            <LifecycleOverview :bundle="bundle" />
                        </v-col>
                    </v-row>
                </v-row>
            </v-card-text>
            <v-card-actions v-if="currentLifecycleStatus?.['smartpricing']?.metadata?.lifecycleStateName == 'prelive'"
                class="pa-6">
                <!--v-col>
          <v-btn>Send Price ready email </v-btn>
        </v-col-->
                <v-dialog transition="dialog-bottom-transition" max-width="800">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on" dense>
                            SEND GOING LIVE EMAIL
                        </v-btn>
                    </template>

                    <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar color="primary" dark>
                                Send Going Live Email
                            </v-toolbar>
                            <v-card-text class="pa-4">
                                The PG is located in {{ currentPGCountry }}. <br>
                                The Email is going to be send to these users: {{ currentUsersEmail }}.
                            </v-card-text>
                            <v-card-text class="pa-4">
                                Please write the name to be used to refer to the customer:
                            </v-card-text>
                            <v-text-field label="Customer name" outlined class="pa-4" dense hide-details
                                v-model=customerName></v-text-field>
                            <v-text-field label="Free trial days" outlined class="pa-4" type="number" dense
                                :rules="[rules.noNegative]" :value="freeTrialDays"
                                @change="freeTrialDays = Number($event)"></v-text-field>
                            <v-select :items="availableEmailLanguage" outlined hide-details class="pa-4" dense
                                label="Select Language For Email" v-model=emailLanguage>
                            </v-select>
                            <v-menu v-model="endDatePicker" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="endDate" label="Date when Pg is going Live" readonly outlined
                                        class="pa-4" :rules="[rules.required]" hide-details dense v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="endDate" @input="endDatePicker = false"></v-date-picker>
                            </v-menu>
                            <v-card-actions class="justify-end pa-4">
                                <v-btn text :disabled="!(isNaN(freeTrialDays) == false && freeTrialDays >= 0 && !!endDate)"
                                    @click="[sendGoingLiveNotification(), dialog.value = false]">
                                    Send Email
                                </v-btn>
                                <v-btn text @click="dialog.value = false">
                                    Close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-card-actions>
        </v-card>

        <InsertRandomPrices
            v-if="['onboarding', 'created', 'test'].includes(currentLifecycleStatus?.['smartpricing']?.metadata.lifecycleStateName)">
        </InsertRandomPrices>
        <LifecycleTable />

        <HealthCheck class="mt-2 card-ui"></HealthCheck>

        <!-- EDITOR MODE -->
        <v-dialog v-model="editPropertyGroupDialog" fullscreen transition="dialog-bottom-transition"
            v-if="currentPG !== null">
            <v-card style="overflow-x: hidden;">

                <v-toolbar dense class="elevation-0" :class="{ 'grey darken-3': darkMode }">
                    <v-card-title class="overline ml-0 pl-0">

                        <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                        <b class="grey--text darken-6">Property group editor:
                            <b>{{ currentPG.apiVersion }}/{{ currentPG.kind }}/</b>{{ currentPG.metadata.id }}/{{
                                currentPG.metadata.name }}
                        </b>
                    </v-card-title>

                    <v-spacer />
                    <v-btn fab x-small class="elevation-0 cyan--text"
                        @click="editPropertyGroupDialog = false"><v-icon>fa-solid fa-close</v-icon></v-btn>
                </v-toolbar>

                <v-card-text>
                    <v-row class="mx-10 py-5">
                        <v-col class="col-12">
                            <EditPGStatus />
                        </v-col>
                        <v-col class="col-12">
                            <EditPGChildren />
                        </v-col>
                        <v-col class="col col-12">
                            <EditPGInfo mode="edit" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import HealthCheck from './HealthCheck.vue'
import Editor from '@/components/editor/Editor'
import InsertRandomPrices from './InsertRandomPrices.vue'
import EditPGInfo from './EditPGInfo.vue'
import EditPGStatus from './EditPGStatus.vue'
import EditPGChildren from './EditPGChildren.vue'
import LifecycleTable from './LifecycleTable.vue'
import LifecycleOverview from './LifecycleOverview.vue'
import { mapGetters, mapState } from 'vuex'

export default {
    components: { Editor, EditPGInfo, EditPGStatus, EditPGChildren, HealthCheck, InsertRandomPrices, LifecycleTable, LifecycleOverview },
    data: function () {
        return {
            pgInfoStateHistoryHeaders: [
                { text: 'State', sortable: true, value: 'metadata.name' },
                { text: 'Insert date', sortable: true, value: 'metadata.insertDate' },
                { text: 'Set by', sortable: true, value: 'metadata.setBy' },
                { text: 'Cron download reservation', sortable: true, value: 'spec.fields.download.reservation.cron' },
                { text: 'Cron predict price', sortable: true, value: 'spec.fields.predict.price.cron' },
                { text: 'Cron compute price', sortable: true, value: 'spec.fields.compute.price.cron' },
                { text: 'Cron send price', sortable: true, value: 'spec.fields.send.price.cron' },
                { text: 'Dashboard access', sortable: true, value: 'spec.fields.dashboard.access' },
                { text: 'Can be modified', sortable: true, value: 'spec.fields.update.propertyGroup' },
            ],
            editPropertyGroupDialog: false,
            availableEmailLanguage: ['it', 'en', 'de'],
            customerName: '',
            emailLanguage: "",
            freeTrialDays: null,
            endDatePicker: false,

            rules: {
                noNegative: val => val >= 0 || "No negative numbers allowed",
                required: val => !!val || "Required!",
            }
        }
    },
    computed: {
        ...mapState({
            darkMode: state => state.ui.darkMode,
            currentPG: state => state.core.currentPG,
            currentPGCountry: state => state.core.currentPgCountry,
            currentUsersEmail: state => state.core.currentUsers.map(user => user.spec.username).join(", "),
            currentHubspotDeal: state => state.core.currentHubspotDeal,
            currentPhysicalPS: state => state.core.currentPhysicalPS,
            currentPMSPS: state => state.core.currentPMSPS,
            currentCHMPS: state => state.core.currentCHMPS,
            currentStrategyPS: state => state.core.currentStrategyPS
        }),
        ...mapGetters({
            bundlesList: "lifecycle/bundlesList",
            currentLifecycleStatus: "lifecycle/currentLifecycleStateByBundle"
        }),
        propertyGroupList() {
            const possibleChildren = this.$store.state.core.propertyGroupsNamesAndId
            const selectedPG = this.$store.state.core.globalSelectedPropertyGroup

            return possibleChildren.filter(children => {
                if (children.text === selectedPG) {
                    return false
                }
                return true
            })
        },
        pgCurrentParent: {
            set(val) {
                this.$store.state.core.pgCurrentParenteState = val
            },
            get() {
                return this.$store.state.core.pgCurrentParentState
            }
        },
        pgCurrentChildren: {
            set(val) {
                this.$store.state.core.pgCurrentChildrenState = val
            },
            get() {
                return this.$store.state.core.pgCurrentChildrenState
            }
        },
        pgCurrentHasParent() {
            return this.$store.state.core.pgCurrentParentState?.length > 0
        },
        pgOriginalChildren: {
            set(val) {
                this.$store.state.core.pgOriginalChildrenState = val
            },
            get() {
                return this.$store.state.core.pgOriginalChildrenState
            }
        },
        allowedPgStates() {
            if (this.pgCurrentState == null) {
                return this.$store.state.core.pgStates.map((s) => { return s.metadata.name + '.' + s.metadata.label })
            } else {
                return this.$store.state.core.pgStates
                    .filter((s) => { return this.pgInfoState.spec.childs.includes(s.metadata.name) })
                    .map((s) => { return s.metadata.name + '.' + s.metadata.label })
            }
        },
    },
    methods: {
        goToHubspotDeal(dealId) {
            window.open(`https://app-eu1.hubspot.com/contacts/25674234/record/0-3/${dealId}`, "_blank")
        },
        sendGoingLiveNotification() {
            if (this.emailLanguage == "") {
                this.$store.dispatch("alertSystem/newError", {
                    message: `Please select a language`,
                }, { root: true })
                return
            }
            let usersEmailAddresses = this.$store.state.core.currentUsers.map(user => user.spec.username)
            this.$store.dispatch("users/sendGoingLiveNotification", {
                usersEmailAddresses: usersEmailAddresses,
                language: this.emailLanguage,
                endDate: new Date(this.endDate).toLocaleString("en-GB").split(",")[0],
                customerName: this.customerName,
                freeTrialDays: this.freeTrialDays,
                sender: this.$store.state.auth.username,
                propertyGroupId: this.currentPG.metadata.id
            })
        },
    }

}
</script>
