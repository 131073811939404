<template>

    <v-card class="ml-3 mb-1">
      <v-snackbar
        :timeout="messageTimeOut"
        :value= "showMessage"
        y="top"
        x="left"
        tile
        color="red accent-2"
        :multi-line="true"
      >
        {{ message }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="black"
              text
              v-bind="attrs"
              @click="showMessage = false"
              :disabled="!editable"
            >
              Close
            </v-btn>
          </template>
      </v-snackbar>
    <v-row class="mt-1 ml-2 mb-4">
        <v-col class="col-8">
            <h3> Customer bar thresholds</h3>
        </v-col>
        <v-col class="col-2 col-md-4 text-right">
          <v-tooltip >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="indigo" 
              class="ml-3 mr-4" 
              x-small 
              dark 
              bottom left fab @click="addMap()"
              :disabled="!editable"
            >
                <v-icon>fa-solid fa-plus fa-2x</v-icon>
              </v-btn>
            </template>
            <span>Add</span>
          </v-tooltip>
        </v-col>
    </v-row>
    <v-row class="mt-2 ml-2 mb-4">
    </v-row>
    <v-row class="ml-1 mt-4" v-for="(map, index) in field" :key="index">
        <v-row class="ml-3 mb-2">
            <v-row >

                <v-col class="col-11">
                    <v-row>
                        <v-col class="col-3">
                            <v-select
                              label="Key"
                              v-model="map.type"
                              :items="getTypesLabels"
                              outlined
                              dense
                              v-on:change="updateMap(index, map)" 
                              :readonly="!editable"
                            ></v-select>            
                        </v-col>
                    <!-- threshold start -->
                        <v-col class="col-3"> 
                          <v-text-field
                            outlined
                            v-model.number="map.lower_threshold" 
                            label="lower threshold"
                            hint="Lower threshold"
                            required
                            dense
                            type = "number"
                            min="0"
                            v-on:change="updateMap(index, map)" 
                            :readonly="!editable"
                          ></v-text-field> 
                        </v-col>
                        <v-col class="col-3"> 
                          <v-text-field
                            outlined
                            v-model.number="map.upper_threshold"
                            label="Upper threshold"
                            hint="upper_threshold"
                            required
                            dense
                            type = "number"
                            v-on:change="updateMap(index, map)" 
                            :readonly="!editable"
                          ></v-text-field> 
                        </v-col>   
                    <!-- threshold end -->
                        <v-col class="col-2 pl-4 pr-0 mr-0 ml-4 mt-4">
                            <v-btn
                              class="mx-2"
                              small
                              leftda
                              @click="deleteMap(index)"
                              :disabled="!editable"
                            >
                            <v-icon >fa-solid fa-trash-can</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                </v-row>
            <v-row>
                <v-col class="col-11 ml-2">
                    <v-row>
                        <v-col class="col-6">
                            <h4>Start date</h4>
                        </v-col>
                        <v-col class="col-6">
                            <h4>End date</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- date range start -->
                        <v-col class="col-2 pr-1 pl-0"> 
                            <v-text-field
                              v-model= "map.date_range.start_date.year"
                              label="Year"
                              dense
                              number
                              outlined
                              hint="Year"
                              v-on:change="updateMap(index, map)" 
                              :readonly="!editable"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-2 pr-1 pl-0"> 
                            <v-text-field
                                class="ml-1"
                              v-model= "map.date_range.start_date.month"
                              label="Month*"
                              hint="Month*"
                              dense
                              number
                              outlined
                              required
                              v-on:change="updateMap(index, map)" 
                              :readonly="!editable"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-2 pr-1 pl-0"> 
                            <v-text-field
                              v-model= "map.date_range.start_date.day"
                              label="Day*"
                              hint="Day*"
                              dense
                              number
                              outlined
                              required
                              v-on:change="updateMap(index, map)" 
                              :readonly="!editable"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-2 pr-1 pl-3"> 
                            <v-text-field
                              v-model= "map.date_range.end_date.year"
                              label="Year"
                              hint="Year"
                              dense
                              number
                              outlined
                              v-on:change="updateMap(index, map)" 
                              :readonly="!editable"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-2 pr-1 pl-0"> 
                            <v-text-field
                              v-model= "map.date_range.end_date.month"
                              label="Month*"
                              hint="Month*"
                              dense
                              number
                              outlined
                              required
                              v-on:change="updateMap(index, map)" 
                              :readonly="!editable"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-2  pr-1 pl-0"> 
                            <v-text-field
                              v-model= "map.date_range.end_date.day"
                              label="Day*"
                              hint="Day*"
                              dense
                              number
                              outlined
                              required
                              v-on:change="updateMap(index, map)" 
                              :readonly="!editable"
                            ></v-text-field>
                        </v-col>
                <!-- date range end -->
                    </v-row>
                </v-col>
            </v-row>
        </v-row>
        <v-row>
            <v-col class="col-11 ml-1 mr-1 mb-4">
                <v-divider class="mb-4"></v-divider>
            </v-col>
        </v-row>
    </v-row>
    <v-divider class="mt-3 mb-2"></v-divider>
 </v-card>
</template>



<script>

import * as dateValidator from '@/utils/datesValidator.js'


function validateMapThresholds(maps){
    // maps are an array of "view maps" i.e. start/end dates are objects
    var valid = {'result': false, 'message': ''};
    var errors = [];
    if(maps.length > 0){
        for(var i=0; i<maps.length; i++){
            let map = maps[i];
            if(map.lower_threshold === null || map.upper_threshold === null){
                errors.push('Lower and upper tresholds cannot be empty (row '+ (i+1) +')' );
            }

            if(map.lower_threshold >= map.upper_threshold ){
                errors.push('Lower treshold must be smaller than the upper tresholds (row '+ (i+1) +')' );
            }
            if(map.lower_threshold < 0){
                errors.push('Lower treshold must be greater than 0 (row '+ (i+1) +')' );
                map.lower_threshold = 0;
            }           
        }
    }

    if(errors.length > 0){
        valid.message = errors.toString().replace('[', '').replace(']', ' ');
    }
    if(errors.length == 0){
        valid.result = true;
    }

    return valid;
}


  export default {
    name: 'CustomerThresholdMapsEditor',
    props: ['fields', 'editable'],
    data() {
      return {
        field: this.fields,
        //typesLabels:["room", "family", "apartment", "suite", "type 4", "type 5", "type 6", "type 7"],

        showMessage: false,
        message: "",
        messageTimeOut: -1,
      }
    },

    methods: {   
        updateMap(index, value){
            let update = true;
            var message = "";
            let valid = null;
            this.showMessage = false;


            if(value.date_range !== undefined){
                let emptyDates = dateValidator.datesAreEmpty(value.date_range.start_date, value.date_range.end_date);
                if(emptyDates == false){ //allows writing of map with empty date range
                    let datesComplete = dateValidator.datesAreCompleted(value.date_range.start_date, value.date_range.end_date);
                    if(datesComplete == true){
                        valid = dateValidator.validateMapDateRanges(this.field);
                        if(valid.result == false){

                            message = valid.message;
                        }
                    }
                    else{

                        message = "Incomplete Dates"
                    }
                    if(message.length > 0){
                        update = false;
                        this.message =  message;
                        this.showMessage = true;
                    }
                }
            }

            let validThresholds = validateMapThresholds(this.field);
            if(validThresholds.result == false){
                update = false;
                this.message += validThresholds.message;
                this.showMessage = true;
            }        

            if(update){
                this.showMessage = false;
                this.message =  "";
                var args = {index: index, operation: 'update', 'maps': this.field};
                this.$store.dispatch('customerThresholdMapsEditor/editCustomerThresholdMap', args);
            }
        },

        deleteMap(index){
            this.showMessage = false;
            this.message =  "";           
            this.field = this.field.slice(0,index).concat(this.field.slice(index+1));
            var args = {'index': index, operation: 'delete'};
            this.$store.dispatch('customerThresholdMapsEditor/editCustomerThresholdMap', args);
        },

        addMap(){
            if(this.field === undefined){
                this.field = [];
            }

            this.showMessage = false; //cleanup in case one adds a new map while errors still on
            this.message =  "";

            var pType = this.$store.state.core.propertyTagsList[0];
            var newMap = {'type': pType, 'lower_threshold': 1, 'upper_threshold': 2};
            newMap['date_range'] = {
                'start_date':{'day':1, 'month': 1, 'year': null},
                'end_date': {'day': 31, 'month': 12, 'year': null}
            };
            this.field.push(newMap);
            var args = {operation: 'create', 'maps': this.field};
            this.$store.dispatch('customerThresholdMapsEditor/editCustomerThresholdMap', args);          
        },

    },//end methods
    computed: {

        getTypesLabels(){
            return Object.keys(this.$store.getters["editor/codeToRenderObj"]?.global?.room_tag_map || {
              room: "all"
            })
        },

    },
    created() {
        this.$emit("fields", this.field);
    },
    mounted(){

    },

  }
</script>

<style scoped>
</style>
