const dateFns = require("date-fns")

const daysDifference = (startDate, endDate, modifier = 0) => {
    return dateFns.differenceInCalendarDays(new Date(endDate), new Date(startDate)) + modifier
}

/* const addDaysToDate = (date, days) => {

    const dt = new Date();
    let diffTZ = dt.getTimezoneOffset();

    let newDate = new Date(date);
    newDate.setHours(diffTZ / (-60), 0, 0, 0); // Set time to midnight
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}; */

const largestDate = (dates) => {
    const onlyDates = dates.map(date => new Date(date).getTime())

    return new Date(Math.max(...onlyDates))
}

const smallestDate = (dates) => {
    const onlyDates = dates.map(date => new Date(date).getTime())
    
    return new Date(Math.min(...onlyDates))
}

const addDaysToDate = (date, days) => {
    //dateFns.addDays()
    let dateUTC = Date.UTC(date.split("-")[0], date.split("-")[1]-1, date.split("-")[2]);
    var newTs = dateUTC + 86400000 * days;
    var newDateUTC = new Date(newTs);
    return newDateUTC
};


const toIsoDateOnly = (date) => {
    return new Date(date).toISOString().split("T")[0]
}



const getWeekDates = (week, year = new Date().getUTCFullYear()) => {
    const jan1 = new Date(year, 0, 1); // January 1st of the year
    const isoWeekStart = dateFns.startOfWeek(jan1, { weekStartsOn: 1 }); // Start of the ISO week containing January 1st

    return {
        start: dateFns.addDays(dateFns.addWeeks(structuredClone(isoWeekStart), week - 1), 1),
        end: dateFns.addWeeks(structuredClone(isoWeekStart), week),
    }
}

function formatDate(dateTimeString) {
    const browserOptions = Intl.DateTimeFormat().resolvedOptions()
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: browserOptions.timezone
    };
  
    const userDate = new Date(dateTimeString);
    const formattedDateTime = userDate.toLocaleString(browserOptions.locale, options);
    return formattedDateTime;
}


module.exports = {
    daysDifference,
    addDaysToDate,
    toIsoDateOnly,
    getWeekDates,
    formatDate,
    largestDate,
    smallestDate,    
}