<template>
    <v-card class="card-ui pa-4">
        <v-card-title>Diff Reservations</v-card-title>

        <v-row>
            <v-col class="grow">
                <v-menu
                    v-model="dateMenu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="Canceled From"
                        outlined
                        readonly
                        clearable
                        v-model="date1"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date1"
                        @change="dateMenu1 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col class="grow">
                <v-menu
                    v-model="dateMenu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="Candeled To"
                        outlined
                        readonly
                        clearable
                        v-model="date2"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date2"
                        @change="dateMenu2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        


        <v-card-actions>
            <v-btn
                color="green"
                :disabled="date1 == null || date2 == null"
                @click="[getDiffedReservations({pgId: pgInfo.metadata.id, startDate: finalObj.startDate, endDate: finalObj.endDate})]"
            >Get diffed Reservations</v-btn>
        </v-card-actions>


        <v-card-actions class="pa-4">
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="confirmDialog"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        :disabled="selectedReservationsList.length == 0"
                    >Restore Seleted Reservations</v-btn>
                </template>
                <template v-slot:default="dialog">
                    <v-card :loading="loading">
                        <v-toolbar
                            color="primary"
                            dark
                        >Confirm Restore of Reservations</v-toolbar>
                        <v-card-text>
                            <div class="text-h4 pa-12">Are you sure you want to restore these reservations?</div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                @click="[restoreReservations({pgId: pgInfo.metadata.id, ids: selectedReservationsList }), dialog.value=false]"
                                color="primary"
                            >Confirm</v-btn>
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-card-actions>

        <template>
            <v-data-table v-model="selectedReservationsList" :items="diffedReservations" :headers="this.headers" show-select>
            </v-data-table>
        </template>
    </v-card>
</template>


<script>
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            confirmDialog: false,

            date1: null,
            date2: null,
            selectedReservationsList: [],
            headers: [ { text: 'Id', value: 'id' }, { text: 'External Id', value:"external_id"}, { text: "Diffed at", value: "diff_at"} ]
        }
    },
    computed: {
        ...mapState({
            reservationCommunicationList: state => state.gpcs.reservationsCommunicationList,
            pgInfo: state => state.core.currentPG,
        }),
        finalObj() {
            return {
                startDate:  this.date1 < this.date2 ? this.date1 : this.date2,
                endDate:  this.date1 > this.date2 ? this.date1 : this.date2,
            }
        },
        diffedReservations() {
            return this.$store.state.gpcs?.diffedReservations
        }
    

    },
    watch: {
        pgInfo(newVal, oldVal) {
            this.$store.state.gpcs.diffedReservations = []
            this.selectedReservationsList = []
            this.date1 = null
            this.date2 = null
        }
    },
    methods: {
        ...mapActions({
            getDiffedReservations: "gpcs/getDiffedReservations",
            restoreReservations: "gpcs/restoreReservations"
        }),
        selectAllProperties() {
            this.$nextTick(() => {
                if (this.selectedProperties.length === this.availableProperties.length) {
                    this.selectedProperties = []
                } else {
                    this.selectedProperties = this.availableProperties.map(prop => prop.id)
                }
            })
        },
    }
}
</script>