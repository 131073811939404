
export default {
    namespaced: true,

    state: {

        partnersList: [],
        featuresList: [],

        featuresListByPartner: [],
        partnersListByFeature: [],

    },

    mutations: {

        updatePartnersList(state, payload) {
            if (payload.partnersList.length) {
                payload.partnersList.forEach(partner => {
                    state.partnersList.push(partner)
                })
            }
        },

        updateFeaturesList(state, payload) {
            if (payload.featuresList.length) {
                payload.featuresList.forEach(feature => {
                    state.featuresList.push(feature)
                })
            } 
        },

        updateFeaturesListByPartner(state, payload) {
            if (payload.featuresByPartner.length) {
                payload.featuresByPartner.forEach(feature => {
                    state.featuresListByPartner.push(feature)
                })
            }
        },

        updatePartnersListByFeature(state, payload) {
            if (payload.partnersByFeature.length) {
                payload.partnersByFeature.forEach(partner => {
                    state.partnersListByFeature.push(partner)
                })
            } 
        },

        resetPartnersList(state) {
            state.partnersList = []
        },

        resetFeaturesList(state) {
            state.featuresList = []
        },

        resetFeatureListByPartner(state) {
            state.featuresListByPartner = []
        },

        resetPartnersListByFeature(state) {
            state.partnersListByFeature = []
        }

    },

    actions: {

        async getPartnersList(context) {
            const api = context.rootState.api.api

            try {
                if (!context.state.partnersList.length) {
                    const response = await api.get("integrations/v2", "/specs/partner", {}, {}, {})

                    context.commit("resetPartnersList")
                    context.commit("updatePartnersList", {
                        partnersList: response
                    })
                }

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the partners list: ${error}`
                }, {root: true})
            }

        },

        async getFeaturesList(context) {
            const api = context.rootState.api.api

            try {
                if (!context.state.featuresList.length) {
                    const response = await api.get("integrations/v2", "/specs/feature", {}, {}, {})
                
                    context.commit("resetFeaturesList")
                    context.commit("updateFeaturesList", {
                        featuresList: response
                    })
                }

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the features list: ${error}`
                }, {root: true})
            }

        },

        async getFeaturesByPartnerId(context, payload) {
            const api = context.rootState.api.api

            try {
                const response = await api.get("integrations/v2", `/specs/partner/${payload.partnerId}`, {}, {}, {})

                context.commit("resetFeatureListByPartner")
                const featuresListByPartner = []
                for (const [key, value] of Object.entries(response)){
                    
                    let newValue = null
                    typeof value === "object" ? newValue = JSON.stringify(value) : newValue = value 
                    

                    featuresListByPartner.push({
                        text: key,
                        value: newValue,
                    })
                }
                context.commit("updateFeaturesListByPartner", {featuresByPartner: featuresListByPartner})


            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the features from the selected partner: ${error}`
                }, {root: true})
            }

        },

        async getPartnersByFeature(context, payload) {
            const api = context.rootState.api.api

            try {
                const response = await api.get("integrations/v2", `/specs/feature/${payload.featureId}`, {}, {}, {})

                context.commit("resetPartnersListByFeature")
                const partnersListByFeature = []
                for (const [key, value] of Object.entries(response)){

                    let newValue = null
                    typeof value === "object" ? newValue = JSON.stringify(value) : newValue = value 

                    partnersListByFeature.push({
                        text: key,
                        value: newValue,
                    })
                }
                context.commit("updatePartnersListByFeature", {partnersByFeature: partnersListByFeature})


            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the partners for the selected feature: ${error}`
                }, {root: true})
            }
        },


        async resetState(context) {
            context.commit("resetPartnersListByFeature")
            context.commit("resetFeatureListByPartner")
            context.commit("resetPartnersList")
            context.commit("resetFeaturesList")
        }
       
    }   
}