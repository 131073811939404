import Vue from "vue"

export default {
    namespaced: true,

    state: {

        // general lists
        ratesList: [],
        propertyRatesList: [],
        propertyRateModifiersList: [],

        supportsMappingHashMap: {},
        mappingsFromCmList: [],
        externalRates: []
    },

    getters: {

        ratesAndPropRatesFetched(state) {
            return !!state.ratesList.length && !!state.propertyRatesList.length && !!state.propertyRateModifiersList && !!state.externalRates.length
        },

        ratesByPropertyGroupID(state, getters) {
            const hashMap = {}

            const pgs = state.ratesList.map(rate => rate.spec.property_group_id)
            pgs.forEach(pg => hashMap[pg] = [])

            state.ratesList.forEach(rate => {
                hashMap[rate.spec.property_group_id].push(rate)
            })
            return hashMap
        },

        propertyRatesByRateID(state, getters) {
            const hashMap = {}

            const props = state.propertyRatesList.map(propRate => propRate.spec.rate_id)
            props.forEach(prop => hashMap[prop] = [])

            state.propertyRatesList.forEach(propRate => {
                hashMap[propRate.spec.rate_id].push(propRate)
            })
            return hashMap
        },

        propertyRatesByPropertyID(state, getters) {
            const hashMap = {}

            const props = state.propertyRatesList.map(propRate => propRate.spec.property_id)
            props.forEach(prop => hashMap[prop] = [])

            state.propertyRatesList.forEach(propRate => {
                hashMap[propRate.spec.property_id].push(propRate)
            })
            return hashMap
        },

        propertyRateModifierByPropRateID(state) {
            const hashMap = {}
            state.propertyRateModifiersList.forEach(propRate => {
                hashMap[propRate.spec.property_rate_id] = propRate
            })
            return hashMap
        },

        selectedPgCmMappingSupported(state, getters, rootState) {
            if (!rootState.core.currentPG) {
                return false
            }

            const cmId = rootState.core.currentPG.spec.channel_manager_name
            const support = state.supportsMappingHashMap[cmId]

            if (support === undefined) return false
            if (support.rooms === undefined) return false

            return support.rooms
        },

        automappingRatesList(state, getters, rootState, rootGetters) {
            const cmId = rootState.core.currentPG.spec.channel_manager_name

            const dropdown = []
            let cmExternalRates = []
            let dbExternalRates = []

            state.mappingsFromCmList.forEach((mapping) => {
                mapping.rates.forEach(rate => {
                    const rateExternalId = rate.externalId
                    if (cmExternalRates.findIndex(rate => rate.value === rateExternalId) <= -1) {
                        let rateText = `${rateExternalId}`
                        if (cmId === "slope") {
                            const regex = / - \d+ PAX$/;
                            rateText += ` - ${rate.name.replace(regex, '')}`
                        } else {
                            rateText += ` - ${rate.name}`
                        }

                        cmExternalRates.push({
                            text: rateText,
                            value: rateExternalId
                        })
                    }
                })
            })

            state.externalRates.forEach(rate => {
                const rateExternalId = rate.spec.rate_external_id
                if (dbExternalRates.findIndex(rate => rate.value === rateExternalId) <= -1) {
                    let rateText = `${rateExternalId}`
                    if (cmId === "slope") {
                        const regex = / - \d+ PAX$/;
                        rateText += ` - ${rate.spec.rate_name.replace(regex, '')}`
                    } else {
                        rateText += ` - ${rate.spec.rate_name}`
                    }
                    dbExternalRates.push({
                        text: rateText,
                        value: rateExternalId,
                    })
                }
            })

            cmExternalRates.forEach(cmRate => {
                let isInDatabase = true

                if (dbExternalRates.findIndex(dbRate => dbRate.value === cmRate.value) <= -1) {
                    isInDatabase = false
                }

                dropdown.push({
                    text: cmRate.text,
                    value: cmRate.value,
                    isInChannelManager: true,
                    isInDatabase: isInDatabase
                })
            })

            dbExternalRates.forEach(dbRate => {
                let isInChannelManager = true

                if (cmExternalRates.findIndex(cmRate => cmRate.value === dbRate.value) <= -1) {
                    isInChannelManager = false
                } else {
                    return
                }

                dropdown.push({
                    text: dbRate.text,
                    value: dbRate.value,
                    isInChannelManager: isInChannelManager,
                    isInDatabase: true
                })
            })

            return dropdown
        },

        automappingPropertyRateExternalIdList(state) {
            const dropdown = []
            if (!!state.mappingsFromCmList) {
                state.mappingsFromCmList.forEach(val => {
                    dropdown.push({
                        text: `${val.externalId} - ${val.name}`,
                        value: val.externalId
                    })
                })
            }
            return !!dropdown.length ? dropdown : null
        }
    },

    mutations: {
        // rates
        resetRatesList(state, payload) { // resets the rates list, if passed a new ratesList, then it's populated with that
            state.ratesList = []
            if (!!payload.ratesList.length) {
                payload.ratesList.forEach(rate => {
                    state.ratesList.push(rate)
                })
            }
        },

        addRate(state, payload) {
            state.ratesList.push(payload.rate)
        },

        removeRate(state, payload) {
            const index = state.ratesList.findIndex(rate => rate.metadata.id === payload.id)

            if (index !== -1) {
                state.ratesList.splice(index, 1)
            }
        },

        updateRate(state, payload) {
            const index = state.ratesList.findIndex(rate => rate.metadata.id === payload.id)

            if (index !== -1) {
                state.ratesList.splice(index, 1, payload.rate)
            }
        },

        // property rates
        resetPropertyRatesList(state, payload) { // resets the property rates list, if passed a new ratesList, then it's populated with that
            state.propertyRatesList = []
            if (!!payload.propertyRatesList.length) {
                payload.propertyRatesList.forEach(rate => {
                    state.propertyRatesList.push(rate)
                })
            }
        },

        addPropertyRate(state, payload) {
            state.propertyRatesList.push(payload.rate)
        },

        removePropertyRate(state, payload) {
            const index = state.propertyRatesList.findIndex(rate => rate.metadata.id === payload.id)

            if (index !== -1) {
                state.propertyRatesList.splice(index, 1)
            }
        },

        updatePropertyRate(state, payload) {
            const index = state.propertyRatesList.findIndex(rate => rate.metadata.id === payload.id)

            if (index !== -1) {
                state.propertyRatesList.splice(index, 1, payload.rate)
            }
        },

        // property rate modifiers
        resetPropertyRateModifiersList(state, payload) {
            state.propertyRateModifiersList = []
            if (!!payload.propertyRateModifiersList.length) {
                payload.propertyRateModifiersList.forEach(rate => {
                    state.propertyRateModifiersList.push(rate)
                })
            }
        },

        addPropertyRateModifier(state, payload) {
            state.propertyRateModifiersList.push(payload.rate)
        },

        removePropertyRateModifier(state, payload) {
            const index = state.propertyRateModifiersList.findIndex(rate => rate.metadata.id === payload.id)

            if (index !== -1) {
                state.propertyRateModifiersList.splice(index, 1)
            }
        },

        updatePropertyRateModifier(state, payload) {
            const index = state.propertyRateModifiersList.findIndex(rate => rate.metadata.id === payload.id)

            if (index !== -1) {
                state.propertyRateModifiersList.splice(index, 1, payload.rate)
            }
        },

        // mapping
        resetMappingHashMap(state, payload) {
            state.supportsMappingHashMap = {}
            if (payload.list) {
                for (const [key, value] of Object.entries(payload.list)) {
                    state.supportsMappingHashMap[key] = value
                }
            }
        },

        resetMappingsFromCm(state, payload) {
            state.mappingsFromCmList = []
            if (payload.mappings) {
                payload.mappings.forEach(mapping => {
                    state.mappingsFromCmList.push(mapping)
                })
            }
        },

        resetExternalRates(state, payload) {
            state.externalRates = []
            if (payload.rates) {
                payload.rates.forEach(rate => {
                    state.externalRates.push(rate)
                })
            }
        }

    },

    actions: {

        // get rates
        async getRates(context) { // get all the rates
            const api = context.rootState.api.api

            try {
                const res = await api.get("rate/v1", `/rate`, {}, {}, {})

                context.commit("resetRatesList", { ratesList: res.items })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error retriving the rates: ${error}`,
                }, { root: true })
            }
        },

        async getPropertyRates(context) { // get all the property rates
            const api = context.rootState.api.api

            try {
                const res = await api.get("rate/v1", `/propertyrate`, {}, {}, {})

                context.commit("resetPropertyRatesList", { propertyRatesList: res.items })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error retriving the property rates: ${error}`,
                }, { root: true })
            }
        },

        async getPropertyRateModifiers(context) { // get all the property rate modifiers
            const api = context.rootState.api.api

            try {
                const res = await api.get("rate/v1", `/propertyratemodifier`, {}, {}, {})

                context.commit("resetPropertyRateModifiersList", { propertyRateModifiersList: res.items })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error retriving the property rate modifiers: ${error}`,
                }, { root: true })
            }
        },


        // insert rates
        async insertRate(context, payload) {
            const api = context.rootState.api.api

            try {
                let rateID = null
                if (payload.rate) {
                    const res = await api.put("rate/v1/rate", "", {}, payload.rate, {})

                    context.commit("addRate", {
                        rate: res.items[0],
                    })
                    rateID = res.items[0].metadata.id
                }

                if (payload.propertyRates) {

                    for (const propRate of payload.propertyRates) {
                        let propertyRate = propRate.propertyRate
                        let propertyRateModifier = propRate.propertyRateModifier
                        propertyRate.spec.rate_id = rateID

                        const propRateUpdateRes = await api.put("rate/v1", `/propertyrate`, {}, propertyRate, {})
                        context.commit("addPropertyRate", { rate: propRateUpdateRes.items[0] })

                        propertyRateModifier.spec.property_rate_id = propRateUpdateRes.items[0].metadata.id
                        const insertedPropRateMod = await api.put("rate/v1", `/propertyratemodifier`, {}, propertyRateModifier, {})
                        context.commit("addPropertyRateModifier", { rate: insertedPropRateMod.items[0] })
                    }
                }

                context.dispatch("alertSystem/newSuccess", {
                    message: `Successfully added the rate ${payload.rate.spec.name} and its property rates and property rate modifiers`,
                    timeout: 10000,
                }, { root: true })


            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error inserting the rate: ${error}`,
                    timeout: -1,
                }, { root: true })
            }
        },

        // update rates
        async updateRate(context, payload) {
            const api = context.rootState.api.api

            try {
                if (payload.rate) { // update the rate
                    const res = await api.put("rate/v1/rate", "", {}, payload.rate, {})

                    context.commit("updateRate", {
                        id: payload.rate.metadata.id,
                        rate: res.items[0],
                    })
                }


                if (payload.propertyRates) { // update the property rates and modifiers

                    for (const propRate of payload.propertyRates) {
                        let propertyRate = propRate.propertyRate
                        let propertyRateModifier = propRate.propertyRateModifier

                        const propRateUpdateRes = await api.put("rate/v1", `/propertyrate`, {}, propertyRate, {})

                        propertyRate.metadata.id !== undefined ?
                            context.commit("updatePropertyRate", { rate: propRateUpdateRes.items[0], id: propRateUpdateRes.items[0].metadata.id }) :
                            context.commit("addPropertyRate", { rate: propRateUpdateRes.items[0] })

                        if (!propertyRateModifier?.metadata?.id) { // insert new property rate modifier
                            propertyRateModifier.spec.property_rate_id = propRateUpdateRes.items[0].metadata.id

                            const updatedPropRateMod = await api.put("rate/v1", `/propertyratemodifier`, {}, propertyRateModifier, {})
                            context.commit("addPropertyRateModifier", { rate: updatedPropRateMod.items[0] })

                        } else { // update existing property rate modifier
                            const newPropRateMod = await api.put("rate/v1", `/propertyratemodifier`, {}, propertyRateModifier, {})
                            context.commit("updatePropertyRateModifier", { rate: newPropRateMod.items[0], id: newPropRateMod.items[0].metadata.id })
                        }

                    }
                }

                context.dispatch("alertSystem/newSuccess", {
                    message: `Successfully updated the rate with its property rates and property rate modifiers`,
                    timeout: 10000,
                }, { root: true })

                if (payload.propertyRatesToDelete) { // delete the property rates and modifiers
                    let propertiesNames = []
                    for (const propRate of payload.propertyRatesToDelete) {
                        if (propRate.propertyRate.metadata.id !== null && propRate.propertyRate.metadata.id !== undefined) {
                            await api.delete("rate/v1", `/propertyrate/${propRate.propertyRate.metadata.id}`, {}, {}, {})

                            context.commit("removePropertyRate", { id: propRate.propertyRate.metadata.id })

                            propertiesNames.push(context.rootGetters["core/allPropertyNamesById"][propRate.propertyRate.spec.property_id])
                        }
                    }

                    context.dispatch("alertSystem/newSuccess", {
                        message: `Successfully unlinked properties ${propertiesNames.join(', ')} from rate ${payload.rate.spec.name}`,
                        timeout: 10000,
                    }, { root: true })
                }

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating the rate: ${error}`,
                    timeout: -1,
                }, { root: true })
            }
        },

        // delete rate
        async deleteRate(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.delete("rate/v1", `/rate/${payload.rate.metadata.id}`, {}, {}, {})

                context.commit("removeRate", {
                    id: payload.rate.metadata.id
                })

                if (context.getters.propertyRatesByRateID[payload.rate.metadata.id] !== undefined) {
                    const propertyRates = []
                    context.getters.propertyRatesByRateID[payload.rate.metadata.id].forEach(propRate => {
                        propertyRates.push(propRate)
                        context.commit("removePropertyRate", { id: propRate.metadata.id })
                    })

                    propertyRates.forEach(propRate => {
                        const propRateModifier = context.getters.propertyRateModifierByPropRateID[propRate.metadata.id]
                        context.commit("removePropertyRateModifier", { id: propRateModifier.metadata.id })
                    })
                }

                context.dispatch("alertSystem/newSuccess", {
                    message: `Successfully deleted the rate ${payload.rate.metadata.id} ${payload.rate.spec.name}`
                }, { root: true })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error deleting the rate: ${error}`,
                }, { root: true })
            }
        },

        // check mapping support
        async checkMappingSupport(context, payload) {
            const api = context.rootState.api.api

            try {
                const responseMapping = await api.get("integrations/v2", "/specs/feature/automapping", {}, {}, {})

                context.commit("resetMappingHashMap", { list: responseMapping })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error cheking mapping support: ${error}`,
                }, { root: true })
            }
        },

        async getMappingsFromCm(context, payload) {
            const api = context.rootState.api.api

            try {
                const response = await api.get("integrations/v2", `/automapping/chm/${payload.pgId}`, {}, {}, {})

                context.commit("resetMappingsFromCm", { mappings: response.properties })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the mapping from the cm: ${error}`,
                }, { root: true })
            }

        },

        async getExternalRates(context, payload) {
            const api = context.rootState.api.api

            try {
                const response = await api.get("rate/v1", `/propertyrate/propertygroup/${payload.pgId}`, {}, {}, {})

                context.commit("resetExternalRates", { rates: response.items })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting external rates: ${error}`,
                }, { root: true })
            }

        }
    }
}
