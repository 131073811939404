export default {
    namespaced: true,

    state: {
        availableChecks: [],
        triggeredAlarms: [],
        triggeredAlarmNotifications: [],
        executedJobs: [],
        executedJobResults: [],
        executedJobResult: null,
    },

    getters: {
        getAlarmById: (state) => (alarmId) => {
            return state.triggeredAlarms.find(alarm => alarm.id === alarmId)
        }
    },

    actions: {
        async getAvailableChecks(context) {
            const api = context.rootState.api.api

            const response = await api.get('alarm/v1', '/checks', {}, {}, {})

            context.commit("resetStateArray", { state: "availableChecks", values: response.data })
        },
        async runChecks(context, checks) {
            const api = context.rootState.api.api

            for (const check of checks) {
                await api.post('alarm/v1', '/check', {}, { origin: "dashboard", check: check }, {})
            }
        },
        async getTriggeredAlarms(context, params) {
            const api = context.rootState.api.api
            const { fromDate, toDate } = params

            const response = await api.get('alarm/v1', `/alarms/${fromDate}/${toDate}`, {}, {}, {})

            context.commit("resetStateArray", { state: "triggeredAlarms", values: response.data })
        },
        async getTriggeredAlarmNotifications(context, alarmId) {
            const api = context.rootState.api.api

            const path = `/notifications/${alarmId}`
            const response = await api.get('alarm/v1', path, {}, {}, {})

            context.commit("resetStateArray", { state: "triggeredAlarmNotifications", values: response.data })
        },
        async getExecutedJobs(context) {
            const api = context.rootState.api.api

            const response = await api.get('alarm/v1', '/jobs', {}, {}, {})

            context.commit("resetStateArray", { state: "executedJobs", values: response.data })
        },
        async getExecutedJobResults(context, jobId) {
            const api = context.rootState.api.api

            const path = `/jobs/${jobId}/results`
            const response = await api.get('alarm/v1', path, {}, {}, {})

            context.commit("resetStateArray", { state: "executedJobResults", values: response.data })
        },
        async getExecutedJobResult(context, params) {
            const api = context.rootState.api.api

            const path = `/jobs/${params.jobId}/results/${params.scopeKey}/${params.scopeValue}`
            const response = await api.get('alarm/v1', path, {}, {}, {})

            context.commit("resetStateValue", { state: "executedJobResult", value: response.data })
        },
    },

    mutations: {
        resetStateValue(state, payload) {
            state[payload.state] = null
            if (!!payload?.value) {
                state[payload.state] = payload.value
            }
        },
        resetStateArray(state, payload) {
            state[payload.state] = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state[payload.state].push(val)
                })
            }
        },
    }
}
