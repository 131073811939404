
export default {
    namespaced: true,

    state: {
        // static data
        lifecycleStatesList: [],

        lifecycleHistoryHeadersByBundle: {
            "smartpricing": [
                { text: 'State', value: 'metadata.name' },
                { text: 'Insert date', value: 'metadata.insertDate' },
                { text: 'Set by', value: 'metadata.setBy' },
                { text: 'Cron download reservation', value: 'spec.fields.download.reservation.cron' },
                { text: 'Cron predict price', value: 'spec.fields.predict.price.cron' },
                { text: 'Cron compute price', value: 'spec.fields.compute.price.cron' },
                { text: 'Cron send price', value: 'spec.fields.send.price.cron' },
                { text: 'Dashboard access', value: 'spec.fields.dashboard.access' },
                { text: 'Can be modified', value: 'spec.fields.update.propertyGroup' },
            ],
            "market-intelligence": [
                { text: "State", value: "metadata.name" },
                { text: "Insert Date", value: "metadata.insertDate" },
                { text: "Set By", value: "metadata.setBy" },
                { text: "Booking Scraper Manual", value: "spec.fields.scraping.booking.manual" },
                { text: "Booking Scraper Cron", value: "spec.fields.scraping.booking.cron" },
                { text: "Dashboard Access", value: "spec.fields.dashboard.access" },
                { text: "Can Be Modified", value: "spec.fields.update.propertyGroup" },
            ],
            "smartpaying": [
                { text: "State", value: "metadata.name" },
                { text: "Insert Date", value: "metadata.insertDate" },
                { text: "Set By", value: "metadata.setBy" },
                { text: "Send Link Cron", value: "spec.fields.send.link.cron" },
                { text: "Send Link Manual", value: "spec.fields.send.link.manual" },
                { text: "Send Notification", value: "spec.fields.send.notification" },
                { text: "Dashboard Access", value: "spec.fields.dashboard.access" },
            ],
            "smartupselling": [
                { text: "State", value: "metadata.name" },
                { text: "Insert Date", value: "metadata.insertDate" },
                { text: "Set By", value: "metadata.setBy" },
                { text: "Store Access", value: "spec.fields.access.store" },
                { text: "Send Link", value: "spec.fields.send.link" },
                { text: "Dashboard Access", value: "spec.fields.access.dashboard" },
            ],
            "ght": [
                { text: "State", value: "metadata.name" },
                { text: "Insert Date", value: "metadata.insertDate" },
                { text: "Set By", value: "metadata.setBy" },
                { text: "Booking Scraper Manual", value: "spec.fields.scraping.booking.manual" },
                { text: "Booking Scraper Cron", value: "spec.fields.scraping.booking.cron" },
                { text: "Dashboard Access", value: "spec.fields.dashboard.access" },
            ]

        },

        lifecycleProgressionByBundle: {
            "smartpricing": ["created", "test", "preonboarding", "onboarding", "prelive", "trial", "live", "lost", "deleted"],
        },

        // current pg data
        currentPgLifecycles: [],
        currentPgLifecyclesHistory: [],


    },

    getters: {
        bundlesList(state) {
            return Array.from(new Set(state.lifecycleStatesList.map(state => state?.metadata?.bundle)))
        },

        lifecycleStatesByBundle(state) {
            const hashMap = {}
            state.lifecycleStatesList.forEach(state => {
                if (hashMap[state?.metadata?.bundle] === undefined) {
                    hashMap[state?.metadata?.bundle] = []
                }
                hashMap[state?.metadata?.bundle].push(state)
            })

            return hashMap
        },

        currentLifecycleStateByBundle(state) {
            const hashMap = {}
            state.currentPgLifecycles.forEach(state => {
                hashMap[state?.metadata?.bundle] = state
            })

            return hashMap
        },

        currentHistoryByBundle(state) {
            const statesHistory = structuredClone(state.currentPgLifecyclesHistory)
            const hashMap = {}
            statesHistory.forEach(state => {
                if (hashMap[state?.metadata?.bundle] === undefined) {
                    hashMap[state?.metadata?.bundle] = []
                }
                hashMap[state?.metadata?.bundle].push(state)
            })

            return hashMap
        }

    },

    mutations: {

        resetLifecycleStatesList(state, payload) {
            state.lifecycleStatesList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.lifecycleStatesList.push(val)
                })
            }
        },

        resetCurrentPgLifecycles(state, payload) {
            state.currentPgLifecycles = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.currentPgLifecycles.push(val)
                })
            }
        },

        resetCurrentPgLifecyclesHistory(state, payload) {
            state.currentPgLifecyclesHistory = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.currentPgLifecyclesHistory.push(val)
                })
            }
        }

    },

    actions: {
        async fetchPgFromGraph(context) {
            const api = context.rootState.api.api

            try {
                const pgId = context.rootState.core.currentPG.metadata.id
                const res = await api.get('graph/vpg', `/propertygroup/${(pgId)}`, {}, {}, {})

                const rootLinks = res?.items?.[0]?.link?.Root
                const hashMap = {}
                rootLinks?.forEach(val => {
                    hashMap[val.kind] = val
                })

                const category = hashMap["Category"]?.id
                const stars = hashMap["Stars"]?.id

                return {
                    category: category,
                    stars: stars
                }

            } catch (error) {
                return null
            }
        },

        async getLifecycleStatesList(context) {
            const api = context.rootState.api.api

            try {
                const res = await api.get(`lifecycle/v2`, `/state`, {}, {}, {})

                if (res?.httpResponseStatus !== 200) throw ""

                context.commit("resetLifecycleStatesList", { values: res.items })
                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the lifecycle states list. Error: ${error}`
                }, { root: true })
                return null
            }
        },

        async getPgLatestLifecycles(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get(`lifecycle/v2`, `/propertygroupstate/${payload.pgId}/latest`, {}, {}, {})

                if (res?.httpResponseStatus !== 200) throw ""

                context.commit("resetCurrentPgLifecycles", {
                    values: res.items
                })
                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the lifecycle states list. Error: ${error}`
                }, { root: true })
                return null
            }
        },

        async getPgHistory(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get(`lifecycle/v2`, `/propertygroupstate/${payload.pgId}`, {}, {}, {})

                if (res?.httpResponseStatus !== 200) throw ""

                context.commit("resetCurrentPgLifecyclesHistory", {
                    values: res.items
                })
                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the lifecycle states list. Error: ${error}`
                }, { root: true })
                return null
            }
        },

        async updateLifecycle(context, payload, rootState) {
            const api = context.rootState.api.api

            try {
                // fetch category and stars

                const pgId = context?.rootState?.core?.currentPG?.metadata?.id

                let user = await context.dispatch("fetchPgFromGraph")

                const bookingId = context?.rootState?.core?.currentPG?.spec?.booking_id
                const coordinates = context?.rootState?.core?.currentPG?.spec?.coordinates
                const category = user?.category
                const stars = user?.stars

                // checks to do in case the new state is prelive, live or trial
                // make it possible to transition to *.maintenance in order to set the correct configuration
                // intools-1646
                if (["live", "trial", "prelive"].includes(payload.newLifecycle.metadata.name) &&
                    !["maintenance"].includes(payload.newLifecycle.metadata.label) &&
                    payload.newLifecycle.metadata.bundle === "smartpricing") {

                    const hasCoordinates = coordinates != null
                    const hasCategory = category != null
                    const hasStars = stars != null // stars can be empty string
                    const hasbookingId = bookingId != null

                    if ( !( hasCoordinates || (hasCategory && hasStars && hasbookingId) ) ) {
                        throw ("To move the Propertygroup to this state the presence of the Coordinates or the presence of fields Booking URL, Category and Stars is required!")
                    }
                }

                const res = await api.put("lifecycle/v2", `/propertygroupstate`, {}, payload.newLifecycle, {})

                await Promise.all([
                    context.commit("core/updateLifecycleStatusByPgId", { pgId: payload.newLifecycle.metadata.propertyGroupId, value: res }, { root: true }),
                    context.dispatch("getPgLatestLifecycles", { pgId: payload.newLifecycle.metadata.propertyGroupId }),
                    context.dispatch("getPgHistory", { pgId: payload.newLifecycle.metadata.propertyGroupId })
                ])

                // automations in case the new state is lost or deleted for smartpricing bundle
                const fullLabel = `${payload.newLifecycle.metadata.name}/${payload.newLifecycle.metadata.label}`
                if (["lost/default", "deleted/default"].includes(fullLabel) && payload.newLifecycle.metadata.bundle === "smartpricing") {

                    // operations on properties
                    const pgProps = await context.dispatch("core/fetchProperties", { pgId: payload.newLifecycle.metadata.propertyGroupId }, { root: true })
                    await Promise.all(pgProps.map(async (prop) => {

                        const newProp = structuredClone(prop)

                        newProp.spec.is_import_reservation_enabled = false
                        newProp.spec.is_set_prices_enabled = false
                        newProp.spec.cm_id = null


                        await context.dispatch("core/updateProperty", {
                            pgId: payload.newLifecycle.metadata.propertyGroupId,
                            config: newProp,
                        }, { root: true })
                    }))

                    // updates the pg info
                    /*
                    const pg = await context.dispatch("core/fetchPropertyGroup", { pgId: payload.newLifecycle.metadata.propertyGroupId }, { root: true })
                    pg.spec.channel_manager_id = null
                    pg.spec.encoded_channel_manager_access_info = null

                    await context.dispatch("core/updatePG", {
                        config: pg
                    }, { root: true })
                    */
                }

                context.dispatch("alertSystem/newSuccess", {
                    message: `Lifecycle state updated successfully`
                }, { root: true })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating the lifecycle state. Error: ${error}`,
                    timeout: -1,

                }, { root: true })
            }
        }

    }
}
