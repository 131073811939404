<template>
    <v-card class="pa-4 card-ui">

        <v-row>
            <v-col class="shrink">
                <v-btn :outlined="selectedPartnerType === 'pms' ? false : true" color="primary"
                    @click="[selectedPartnerType = 'pms', selectedPartnerName = '', selectedPartner = null]"
                    class="mr-2">PMS</v-btn>
            </v-col>
            <v-col class="shrink">
                <v-btn :outlined="selectedPartnerType === 'cm' ? false : true" color="primary"
                    @click="[selectedPartnerType = 'cm', selectedPartnerName = '', selectedPartner = null]"
                    class="mr-2">CM</v-btn>
            </v-col>
            <v-col class="grow">
                <v-autocomplete outlined dense v-model="selectedPartnerName" :items="partnerOptions"
                    @change="loadPartnerData($event)"></v-autocomplete>
            </v-col>
        </v-row>

        <v-row v-if="!!selectedPartner">
            <v-col class="col-12">
                <v-row>
                    <v-col class="auto">
                        <v-text-field outlined dense readonly label="Partner ID"
                            v-model="selectedPartner.metadata.id"></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="col-12">
                <v-switch outlined dense :input-value="!!selectedPartner?.spec?.extra_params?.pmsIdRequired" class="mt-0"
                    v-if="selectedPartnerType === 'pms'" :true-value="true" :false-value="false"
                    :label="`${String(selectedPartnerType).toUpperCase()} ID Required`"
                    @change="setIdRequired($event)"></v-switch>
                <v-switch outlined dense :input-value="!!selectedPartner?.spec?.extra_params?.cmIdRequired" class="mt-0"
                    v-else :true-value="true" :false-value="false"
                    :label="`${String(selectedPartnerType).toUpperCase()} ID Required`"
                    @change="setIdRequired($event)"></v-switch>
            </v-col>
            <v-col class="col-6">
                <v-textarea outlined dense label="Requests Limits" rows="10" :rules="[rules.validJSON]"
                    :value="!!selectedPartner.spec.requests_limits ? JSON.stringify(selectedPartner.spec.requests_limits) : ''"
                    @change="selectedPartner.spec.requests_limits = !!$event ? JSON.parse($event) : null"></v-textarea>
            </v-col>
            <v-col class="col-6">
                <v-textarea outlined dense label="Partner Extra Params" rows="10" :rules="[rules.validJSON]"
                    :value="!!selectedPartner.spec.extra_params ? JSON.stringify(selectedPartner.spec.extra_params) : ''"
                    @change="selectedPartner.spec.extra_params = !!$event ? JSON.parse($event) : null"></v-textarea>
            </v-col>
            <v-col class="col-12">
                <v-dialog transition="dialog-bottom-transition" max-width="1000">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-show="selectedPartnerType === 'cm'" class="mr-2" v-bind="attrs"
                            v-on="on">Edit Property Extra Params Schema</v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar color="primary" dark>Editing Property Extra Params Schema</v-toolbar>

                            <DynamicFormSchemaEditor v-model="selectedPartner.spec.property_extra_params_schema">
                            </DynamicFormSchemaEditor>

                            <v-card-actions class="justify-end">
                                <v-btn text color="success" @click="dialog.value = false">Ok</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>

                <v-dialog transition="dialog-bottom-transition" max-width="1000">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on" class="mr-2">Edit Property Group Extra Params
                            Schema</v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar color="primary" dark>Editing Property Group Extra Params Schema</v-toolbar>

                            <DynamicFormSchemaEditor v-model="selectedPartner.spec.property_group_extra_params_schema">
                            </DynamicFormSchemaEditor>

                            <v-card-actions class="justify-end">
                                <v-btn text color="success" @click="dialog.value = false">Ok</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>

                <v-dialog transition="dialog-bottom-transition" max-width="1000">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on">Edit Partner Encoded Access Info Schema</v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar color="primary" dark>Edit Partner Encoded Access Info Schema</v-toolbar>

                            <DynamicFormSchemaEditor v-model="selectedPartner.spec.encoded_access_info_schema">
                            </DynamicFormSchemaEditor>

                            <v-card-actions class="justify-end">
                                <v-btn text color="success" @click="dialog.value = false">Ok</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-col>
        </v-row>

        <v-divider v-if="!!selectedPartner" class="my-2"></v-divider>

        <v-card-actions v-if="!!selectedPartner" class="pa-0">
            <v-btn color="primary" :loading="updatingPartner" @click="savePartner()">Save Partner</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import DynamicFormSchemaEditor from './DynamicFormSchemaEditor.vue';
import { mapActions, mapGetters } from "vuex"

export default {
    components: { DynamicFormSchemaEditor },
    data() {
        return {
            selectedPartnerType: "cm",
            selectedPartnerName: "",

            selectedPartner: null,

            updatingPartner: false,

            rules: {
                validJSON: val => {
                    try {
                        if (!val) return true
                        JSON.parse(val)
                        return true
                    } catch {
                        return "Invalid JSON"
                    }
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            cmNames: "core/cmNames",
            pmsNames: "core/pmsNames",
            dataByCmId: "core/dataByCmId",
            dataByPmsId: "core/dataByPmsId",
        }),
        partnerOptions() {
            if (this.selectedPartnerType === 'cm') {
                return this.cmNames
            } else {
                return this.pmsNames
            }
        },
        startingPartnerData() {
            return this.selectedPartnerType === "cm" ?
                structuredClone(this.dataByCmId[this.selectedPartner?.metadata.id])
                :
                structuredClone(this.dataByPmsId[this.selectedPartner?.metadata.id])
        }
    },
    methods: {
        ...mapActions({
            updatePms: "core/updatePms",
            updateCm: "core/updateCm",
        }),
        setIdRequired(requiredVal) {
            if (!this.selectedPartner?.spec?.extra_params) {
                this.selectedPartner.spec.extra_params = {
                    [this.selectedPartnerType === "cm" ? "cmIdRequired" : "pmsIdRequired"]: requiredVal
                }
            } else {
                this.selectedPartner.spec.extra_params[this.selectedPartnerType === "cm" ? "cmIdRequired" : "pmsIdRequired"] = requiredVal
            }
        },
        loadPartnerData(partner) {
            this.selectedPartner = null
            if (this.selectedPartnerType === "cm") {
                this.selectedPartner = structuredClone(this.dataByCmId[partner]) || null
            } else {
                this.selectedPartner = structuredClone(this.dataByPmsId[partner]) || null
            }
        },
        async savePartner() {
            this.updatingPartner = true
            if (this.selectedPartnerType === "cm") {
                await this.updateCm({ body: this.selectedPartner })
            } else {
                await this.updatePms({ body: this.selectedPartner })
            }
            this.loadPartnerData(this.selectedPartnerName)
            this.updatingPartner = false
        },
        schemaIsEqual(startingPartnerSchema, editedPartnerSchema) {
            if ((startingPartnerSchema === null || startingPartnerSchema.length === 0) &&
                (editedPartnerSchema !== null && editedPartnerSchema.length > 0)) {
                return false
            }
            if ((editedPartnerSchema === null || editedPartnerSchema.length === 0) &&
                (startingPartnerSchema !== null && startingPartnerSchema.length > 0)) {
                return false
            }
            if ((editedPartnerSchema === null || editedPartnerSchema?.length === 0) &&
                (startingPartnerSchema === null || editedPartnerSchema?.length === 0)) {
                return true
            }
            if (startingPartnerSchema.length !== editedPartnerSchema.length) {
                return false
            }

            for (const [idx, dataEntry] of startingPartnerSchema.entries()) {
                const jsonObj1 = JSON.stringify(dataEntry)
                const jsonObj2 = JSON.stringify(editedPartnerSchema?.[idx])

                if (!(jsonObj1 === jsonObj2)) {
                    return false
                }
            }
            return true
        },
        hasUnsavedData() {
            const startingPartnerData = this.startingPartnerData
            const editedPartnerData = this.selectedPartner

            let hasUnsavedData =
                !this.schemaIsEqual(startingPartnerData?.spec.property_group_extra_params_schema, editedPartnerData?.spec.property_group_extra_params_schema)
                || !this.schemaIsEqual(startingPartnerData?.spec.encoded_access_info_schema, editedPartnerData?.spec.encoded_access_info_schema)

            if (this.selectedPartnerType === "cm") {
                hasUnsavedData = hasUnsavedData || !this.schemaIsEqual(startingPartnerData?.spec.property_extra_params_schema, editedPartnerData?.spec.property_extra_params_schema)
            }

            return hasUnsavedData
        }
    }
}
</script>
