<template>
    <v-container class="pa-4" fluid>
        <PropertyForm v-if="globalSelectedPgId"></PropertyForm>
        <SelectPgAlert v-else></SelectPgAlert>   
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SelectPgAlert from '@/components/utils/SelectPgAlert'
import PropertyForm from '../components/PropertyForm.vue';
import NewChart from '@/components/utils/NewChart.vue'


export default {
    components: {PropertyForm, SelectPgAlert, NewChart},
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG,
        }),
        ...mapGetters({
            selectedPgPmsMappingSupported: "mappingAndRates/selectedPgPmsMappingSupported",
            selectedPgCmMappingSupported: "mappingAndRates/selectedPgCmMappingSupported",
            globalSelectedPgId: "core/globalSelectedPgId",
        })
    },
    watch: {
        currentPG(newVal, oldval) {
            this.initialize()
        }
    },
    methods: {
        ...mapActions({
            checkMappingSupport: "mappingAndRates/checkMappingSupport",
            getMappingsFromCm: "mappingAndRates/getMappingsFromCm",
            getMappingsFromPms: "mappingAndRates/getMappingsFromPms",
        }),
        async initialize() {
            await this.checkMappingSupport()
            if (this.selectedPgPmsMappingSupported) {
                await this.getMappingsFromPms({pgId: this.currentPG.metadata.id})
            }
            if (this.selectedPgCmMappingSupported) {
                await this.getMappingsFromCm({pgId: this.currentPG.metadata.id})
            }
        }
    },
    async created() {
        await this.initialize()
    },
}
</script>