<template>
    <v-card 
        class="card-ui"
    >
        <v-card-text>
            <v-row>
                <v-col class="col-7">
                    <v-row>
                        <v-col class="col-12">
                            <v-slider
                                v-model="selectedMixPgWeight"
                                step="0.1"
                                max="1"
                                min="0"
                                :error="!!sumLocalWeightsMustBeOne"
                                :error-messages="sumLocalWeightsMustBeOne"
                                thumb-label
                                ticks
                            >
                                <template v-slot:prepend>
                                    <div style="width: 100px;">
                                        PG weight
                                    </div>
                                </template>
                                <template v-slot:append>
                                    {{ selectedMixPgWeight }}
                                </template>
                            </v-slider>
                        </v-col>
                        <v-col class="col-12">
                            <v-slider
                                v-model="selectedMixInternalMarketWeight"
                                step="0.1"
                                max="1"
                                min="0"
                                thumb-label
                                :error="!!sumLocalWeightsMustBeOne"
                                :error-messages="sumLocalWeightsMustBeOne"
                                ticks
                            >
                                <template v-slot:prepend>
                                    <div style="width: 100px;">
                                        InternalMarket Weight
                                    </div>
                                </template>
                                <template v-slot:append>
                                    {{ selectedMixInternalMarketWeight }}
                                </template>
                            </v-slider>
                        </v-col>
                        <v-col class="col-12">
                            <v-slider
                                v-model="selectedMixExternalMarketWeight"
                                step="0.1"
                                max="1"
                                min="0"
                                :disabled="!currentPG.spec.booking_id"
                                :error="!!sumLocalWeightsMustBeOne"
                                :error-messages="sumLocalWeightsMustBeOne"
                                thumb-label
                                ticks
                            >
                                <template v-slot:prepend>
                                    <div style="width: 100px;">
                                        ExternalMarket Weight
                                    </div>
                                </template>
                                <template v-slot:append>
                                    {{ selectedMixExternalMarketWeight }}
                                </template>
                            </v-slider>
                        </v-col>    
                    </v-row>
                </v-col>
                <v-col class="col-5">
                    <v-row>
                        <v-col class="col-12">
                            <v-menu
                                v-model="dateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        label="Date Range"
                                        :value="`${selectedMixDateRange[0]} ÷ ${selectedMixDateRange[1]}`"
                                        readonly
                                        v-bind="attrs"
                                        :error="!!dateRangeRequired"
                                        :error-messages="dateRangeRequired"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    :value="selectedMixDateRange"
                                    v-model="dateRangeTemp"
                                    :first-day-of-week="1"
                                    @change="selectedMixDateRange = dateRangeTemp"
                                    :allowed-dates="validateDate"
                                    range
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-12">
                            <v-btn
                                class="ma-2"
                                text
                                icon
                                color="white"
                                @click="deleteTrendlineMix({index: index})"
                            >
                                <v-icon>fas fa-trash-alt</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
    props: ["trendlineMix", "index"],
    data() {
        return {
            dateRangeTemp: [],
            dateMenu: false,
        }
    },
    computed: {
        ...mapState({
            selectedTrendlineValues: state => state.basePrice.selectedTrendlineValues,
            currentPG: state => state.core.currentPG,
        }),
        selectedMixDateRange: {
            get() {
                return [this.trendlineMix.startDate, this.trendlineMix.endDate]
            },
            set(value){
                this.dateMenu = false
                this.updateTrendlineMix({
                    index: this.index,
                    key: "startDate",
                    value: value[0] < value[1] ? value[0] : value[1],
                })
                this.updateTrendlineMix({
                    index: this.index,
                    key: "endDate",
                    value: value[0] < value[1] ? value[1] : value[0],
                })
            }
        },
        selectedMixPgWeight: {
            get() {
                return this.trendlineMix.pgWeight
            },
            set(value){
                this.dateMenu = false
                this.updateTrendlineMix({
                    index: this.index,
                    key: "pgWeight",
                    value: value,
                })
            }
        },
        selectedMixInternalMarketWeight: {
            get() {
                return this.trendlineMix.internalMarketWeight
            },
            set(value){
                this.dateMenu = false
                this.updateTrendlineMix({
                    index: this.index,
                    key: "internalMarketWeight",
                    value: value,
                })
            }
        },
        selectedMixExternalMarketWeight: {
            get() {
                return this.trendlineMix.externalMarketWeight
            },
            set(value){
                this.updateTrendlineMix({
                    index: this.index,
                    key: "externalMarketWeight",
                    value: value,
                })
            }
        },
        // valid date ranges trendline mix list
        invalidDateRange() {
            return this.selectedTrendlineValues.trendlineLocalMixList.map((localMix, index) => {
                console.log(index, this.index)
                if (index !== this.index) {
                    return {
                        start: localMix.startDate,
                        end: localMix.endDate,
                    }
                }
            }).filter(value => value !== undefined)
        },
        sumLocalWeightsMustBeOne() {
            return this.selectedLocalWeightSum === 1 ? "" : `The sum of the global weights must be 1 , current sum is ${this.selectedLocalWeightSum}`
        },
        dateRangeRequired() {
            return this.selectedMixDateRange[0] && this.selectedMixDateRange[1] ? "" : "Date Required" 
        },
        selectedLocalWeightSum() {
            const sum = this.selectedMixPgWeight + 
            this.selectedMixInternalMarketWeight + 
            this.selectedMixExternalMarketWeight

            return Math.round(sum * 100) / 100
        },
    },
    methods: {
        ...mapMutations({
            updateTrendlineMix: "basePrice/updateTrendlineMix",
            deleteTrendlineMix: "basePrice/deleteTrendlineMix",
        }),
        validateDate (date) {
            return this.invalidDateRange.every(value => {
                return date < value.start || value.end < date
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>