<template>
    <div>
        <iframe
            :src="link"
            v-show="showIframe"
            style="border:none; min-height: 700px; width: 400px;"
        ></iframe>

        <v-btn
          color="blue darken-2"
          dark
          fab
          @click="showIframe = !showIframe"
        >
            <v-icon>
                fa-solid fa-brain
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    data() {
        return {
            apiKey: ``,
            showIframe: false,
        }
    },
    computed: {
        link() {
            return `https://app.galiai.com/chat/${this.apiKey}`
        }
    },
    async created() {
        await this.$store.dispatch("token/fetchGali")
        this.apiKey = this.$store.state.token.galiaitoken
    },
}
</script>