<template>
  <div>

  <v-card  class="card-ui elevation-0" v-if="executingSaveAndRun == true" style="text-align: center; height: 90vh">
    <v-progress-circular
      style="margin-top: 40vh;"
      :size="70"
      :width="7"        
      indeterminate
      :color="circularProgressColor"
    ></v-progress-circular>
    <h3>
      {{circularProgressText}}
    </h3>
  </v-card>
  <v-card class="mt-0 card-ui elevation-0" v-if="executingSaveAndRun == false" >
    <v-card-text>
      <v-row>
        <v-col v-show="showMenu" :class="leftTabClass" >
          <v-row>

            <v-col class="col-12">
              <v-select
                label="Version"
                v-model="currentPipelineBindVersion"
                :disabled="!editable"
                outlined
                hide-details
                dense
                :items="pipelineBindHistoryVersions"
              ></v-select>
            </v-col>

            <v-col class="col-9">
              <v-text-field
                label="Reference"
                outlined
                dense
                hide-details
                disabled
                v-model="pipelineBind.resource.metadata.root"
              ></v-text-field>
            </v-col>

            <v-col class="col-3">
              <v-text-field
                label="Ver"
                outlined
                dense
                hide-details
                disabled
                v-model="pipelineBind.resource.metadata.rootVersion"
              ></v-text-field>
            </v-col>

            <v-col class="col-12">
              <v-text-field 
                label="Name"
                :disabled="toEdit == false || editable == false" 
                outlined 
                dense  
                hide-details
                v-model="pipelineBind.resource.metadata.name"
              ></v-text-field>
            </v-col>

            <v-col class="col-12">
              <v-autocomplete 
                v-if="showVisualEditors == true"
                dense 
                hide-details
                label="Market"
                outlined 
                :disabled="!editable "
                v-model="marketValue" 
                :items="marketsList"
                @change="setMarket"
              ></v-autocomplete>
            </v-col>

            <v-col class="col-12">
              <v-text-field
                disabled
                label="Overwritten From"
                outlined
                dense
                hide-details
                v-model="overwriteSource"
              ></v-text-field>
            </v-col>

            <v-col class="col-12">
              <v-text-field
                label="Overwritten By"
                outlined
                dense
                hide-details
                disabled
                v-model="userWhoOverwrote"
              ></v-text-field>
            </v-col>

            <v-col class="col-12">
              <v-text-field
                outlined
                label="Date of Overwriting"
                dense
                hide-details
                disabled
                v-model="timestampOfOverwriting"
              ></v-text-field>
            </v-col>
          </v-row>
        
          <v-row>
            <v-col class="col-12">
              <v-btn style="width: 100%;" 
              :disabled="!editable || loading" 
              color="green"  
              class="elevation-0" 
              @click="onSaveButtonClick"
              :loading="loading"
              >
                <v-icon class="pr-2">fa-solid fa-floppy-disk</v-icon> SAVE
              </v-btn>
            </v-col>
            <v-col class="col-12">
              <v-btn style="width: 100%;" :disabled="!editable || pipelineBind.resource.metadata.root == null" color="orange"  class="elevation-0" @click="overwriteOverlay = !overwriteOverlay">
                <v-icon class="pr-2">fa-solid fa-link</v-icon> OVERWRITE REFERENCE
              </v-btn>
            </v-col>
            <v-col class="col-12">
              <v-btn style="width: 100%;" :disabled="!editable || pipelineBind.resource.metadata.root == null" color="blue"  class="elevation-0" @click="resetToDefault">
                <v-icon class="pr-2">fa-solid fa-arrow-rotate-left</v-icon> RESET TO DEFAULT
              </v-btn>
            </v-col>
            <v-col class="col-12">
              <v-btn style="width: 100%;" :disabled="!editable" color="red"  class="elevation-0" @click="deletePipelineBind">
                <v-icon class="pr-2">fa-solid fa-trash</v-icon> DELETE
              </v-btn>
            </v-col>

            <v-col class="col-12">
              <v-btn style="width: 100%;" :disabled="!editable" color="brown"  class="elevation-0" @click="onSaveAndRunButtonClick" :loading="loading">
                <v-icon class="pr-2">fa-solid fa-play</v-icon> SAVE AND RUN PIPELINEBIND
              </v-btn>
            </v-col>

            <v-col class="col-12">
              <v-btn style="width: 100%;" color="purple"  class="elevation-0" @click="hideLeftTab">
                <v-icon class="pr-2">fa-solid fa-ghost</v-icon> HIDE TAB
              </v-btn>
            </v-col>

          </v-row>
        </v-col>

        <v-col v-show="!showMenu" :class="leftTabHidedClass" >
          <v-row>
          <v-col class="col-12">
              <v-btn style="width: 100%;" color="purple"  class="elevation-0" @click="showLeftTab">
                <v-icon class="pr-2">fa-solid fa-ghost</v-icon> SHOW LEFT TAB
              </v-btn>
          </v-col>
          <v-col>
              <v-btn style="width: 100%;" :disabled="!editable" color="brown"  class="elevation-0" @click="onSaveAndRunButtonClick" :loading="loading">
                <v-icon class="pr-2">fa-solid fa-play</v-icon> SAVE AND RUN PIPELINEBIND
              </v-btn>
          </v-col>
          </v-row>
        </v-col>



        <v-col :class="centralTabClass" style="max-height: 87vh;">
          <v-tabs v-model="mapsTab" 
          @click.native.capture="checkTabChange($event)"
          >
            <v-tab v-for="item in tabsToDisplay" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <Editor class="mt-0" 
            :key="pipelineBindCustomConfigMapYamlKey" 
            :code="pipelineBindCustomConfigMapYaml" 
            :readonly="editable == false ? true : false" 
            lang="yaml" 
            v-if="mapsTab==0"
          ></Editor>  
          <Reconciliation
            v-if="mapsTab==1 && showVisualEditors === false" 
            :reconciliationsData="getReconciliations"
            :editable="editable"
          ></Reconciliation>
          <StandardMapsYamlEditor 
            v-if="mapsTab==1 && showVisualEditors === true" 
            :yaml="customConfigMapParams"
            :key="pipelineBindCustomConfigMapYamlKey" 
            :editable="editable"
          ></StandardMapsYamlEditor>
          <NewRoomsRatesEditor 
            v-if="mapsTab==2 && showVisualEditors === true"
            :roomsRatesData="getRoomsRates"
            :editable="editable"
          ></NewRoomsRatesEditor>
          <PredictionProperties
            v-if="mapsTab==3 && showVisualEditors === true"
            :editable="editable"
          ></PredictionProperties>
          
        </v-col> 
        <v-col :class="rightTabClass" style="max-height: 87vh;">
          <v-tabs v-model="tab" >
            <v-tab v-for="item in rightSideTabsToDisplay" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
            <EditorReadOnlyHack :key="pipelineBindConfigMapYamlKey" v-if="tab == 0  && pipelineBindConfigMapYaml !== null" class="mt-0" :code="pipelineBindConfigMapYaml" :readonly="true" lang="yaml"></EditorReadOnlyHack>

            <EditorReadOnlyHack :key="pipelineBindPipelineYamlKey" v-if="tab == 1 && pipelineBindPipelineYaml !== null" class="mt-0" :code="pipelineBindPipelineYaml" :readonly="true" lang="yaml"></EditorReadOnlyHack>
            
            <EditorReadOnlyHack :key="pipelineBindPipelineYamlKey" v-if="tab == 2 && getExampleYaml !== null" class="mt-0" :code="getExampleYaml" :readonly="true" lang="yaml"></EditorReadOnlyHack>

            <perfect-scrollbar v-if="tab == 3 && showVisualEditors === true">
              <StrategyChartsTabs component="predict" :showAddBasePriceMap='true'></StrategyChartsTabs>
            </perfect-scrollbar>

            <perfect-scrollbar v-if="tab == 3 && showVisualEditors === false">
              <StrategyChartsTabs component="preprocess"></StrategyChartsTabs>
            </perfect-scrollbar>

            <perfect-scrollbar v-if="tab == 4">
              <EventsByPG></EventsByPG>
            </perfect-scrollbar>

            <perfect-scrollbar v-if="tab == 5">
              <HolidaysByPG></HolidaysByPG>
            </perfect-scrollbar>

        </v-col>
      </v-row>


      <!-- Overwrite overlay -->
      <v-overlay
        :value="overwriteOverlay"
      >
        <v-card
          class="mx-auto pa-5"
          max-width="500"
        >
          <v-card-text>
            <p class="text-h4 text--primary">
              Confirm overwrite
            </p>
            <div class="text--primary">
              Are you sure you want to overwrite {{this.pipelineBind.resource.metadata.name}}  strategy reference ({{this.pipelineBind.resource.metadata.root}}) ?
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="red"
              @click="overwriteReference"
            >
              Confirm
            </v-btn>
            <v-btn
              color="primary"
              @click="overwriteOverlay = !overwriteOverlay"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>

    </v-card-text>
  </v-card>
  <v-dialog v-model="showActionOutput">
    <v-alert :type="showActionOutputContent.level" v-if="showActionOutputContent !== null">
      {{showActionOutputContent.kind}} => {{showActionOutputContent.res.status}}
      <b>{{showActionOutputContent.res}}</b>
    </v-alert>
  </v-dialog>
  <ConfirmDialog ref="confirm" />
  </div>
</template>
<script>


// @ is an alias to /src
import md5 from 'md5'
import Editor from '@/components/editor/Editor'
import EditorReadOnlyHack from '@/components/editor/EditorReadOnlyHack'
import StandardMapsYamlEditor from './StandardMapsYamlEditor'
import Reconciliation from './Reconciliation'
import EventsByPG from './EventsByPG'
import HolidaysByPG from './HolidaysByPG.vue'
import ConfirmDialog from '@/components/utils/ConfirmDialog'
import PredictionProperties from '../components/PredictionProperties'
import PipelineYamlExample from "@/assets/templates/pipelineYamlExample.json"; 
import ReconciliationYamlExample from "@/assets/templates/reconciliationYamlExample.json"; 
import StrategyChartsTabs from '../components/StategyChartsTabs'
import NewRoomsRatesEditor from './NewRoomsRatesEditor.vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'

const jsYaml = require('js-yaml')
const yaml = require('js-yaml');

export default {
  name: 'PipelineBindEditor',
  props: [],
  components: { Editor, jsYaml, EditorReadOnlyHack, StandardMapsYamlEditor, NewRoomsRatesEditor, Reconciliation, ConfirmDialog, PredictionProperties, StrategyChartsTabs, EventsByPG, HolidaysByPG },
  data: function (argument) {
    return {
      tab: 0,
      mapsTab: 0,
      pipelineBind: null,
      pipelineBindResource: null,
      toEdit: false,

      // yaml versions of resources
      pipelineBindCustomConfigMapYaml: null,
      pipelineBindPipelineYaml: null,

      // trick to re render yaml editors
      pipelineBindCustomConfigMapYamlKey: -1000,
      pipelineBindConfigMapYamlKey: 1,
      pipelineBindPipelineYamlKey: 1000,

      showActionOutput: false,
      showActionOutputContent: null,

      pipelineBindHistory: [],
      pipelineBindHistoryVersions: [],
      currentPipelineBindVersion: null,
      maxPipelineBindVersion: null,

      yamlIsValid: false,
      loading: false,

      showMenu: true,

      executingSaveAndRun: false,
      circularProgressText: '',
      circularProgressColor: 'primary',

      leftTabClass: 'col-md-3 col-sm-12 card-ui',
      leftTabHidedClass: 'col-md-0 col-sm-12 card-ui',
      centralTabClass: 'col-md-5 col-sm-12 card-ui',
      rightTabClass: 'col-md-4 col-sm-12 card-ui',

      overwriteOverlay: false
    }
  },
  watch: {// non usate sti cazzo di watcher per ogni singola cosa

    // To select the version to show/rollback
    currentPipelineBindVersion (newVal, oldVal) {
      if (oldVal !== null) {
        this.pipelineBindHistory.some((pb) => {
          if (pb.version == newVal) {
            const keepCurrentVersion = this.pipelineBind.resource.metadata.rootVersion
            this.pipelineBind[0] = pb
            this.pipelineBind.resource.metadata.rootVersion = keepCurrentVersion
            this.pipelineBindResource = this.pipelineBind.resource
            this.pipelineBindCustomConfigMapYaml = this.pipelineBindResource.spec.customConfigMap.parameters
            this.pipelineBindCustomConfigMapYamlKey += 1
          }
        })
      }
    },
    'pipelineBindResource.spec.pipeline' (newVal, oldVal) {
      this.pipelineBindPipelineYaml = this.$store.state.pipelineV1.pipelines.filter(function (cm) {
        return this.pipelineBind.resource.spec.pipeline == cm.resource.metadata.name
      }.bind(this)).map((cm) => { return cm.resource.spec })
      this.pipelineBindPipelineYamlKey += 1
    },
    mapsTab (newVal, oldVal) {

      if(this.$store.state.pipelineV1.yamlIsValid == true && this.mapsTab == 0) {
        this.pipelineBindCustomConfigMapYaml = jsYaml.load(this.$store.state.editor.codeToRender);
        this.pipelineBindCustomConfigMapYamlKey += 1
      }
    },    
    '$store.state.pipelineV1.yamlIsValid'(newVal, oldVal){
      if(newVal == true){
        this.$forceUpdate();
      }
    }
  },
  computed: {
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId"
    }),
    pipelineBindConfigMapYaml() {
      const onlyConfigMap = this.configMaps?.filter(configMap => {
        return String(configMap?.name).includes(this.pipelineBind?.name.split(".")[0])
      }) [0]

      console.log(this.configMaps?.filter(configMap => {
        return String(configMap?.name).includes(this.pipelineBind?.name.split(".")[0])
      }))

      return onlyConfigMap?.resource?.spec?.parameters
    },
    overwriteSource() {
      if ( this.pipelineBind.resource.metadata?.history?.length > 0 ) {
        return this.pipelineBind.resource.metadata.history[this.pipelineBind.resource.metadata.history.length-1].sourcePipelineBind
      } else {
        return null
      }
    },
    userWhoOverwrote() {
      if ( this.pipelineBind.resource.metadata?.history?.length > 0 ) {
        return this.pipelineBind.resource.metadata.history[this.pipelineBind.resource.metadata.history.length-1].user
      } else {
        return null
      }
    },
    timestampOfOverwriting () {
      if ( this.pipelineBind.resource.metadata?.history?.length > 0 ) {
        return this.pipelineBind.resource.metadata.history[this.pipelineBind.resource.metadata.history.length-1].date
      } else {
        return null
      }
    },
    tabsToDisplay(){
      var tabs = ['Custom Config Map'];
      if(this.$store.state.pipelineV1.selectedPipelineBind.resource.metadata.labels.includes('preprocess')){
        tabs.push('Reconciliation');
      } else{
        tabs.push('Standard Maps', 'Room Rates', 'Properties');
      }
      return tabs;
    },
    rightSideTabsToDisplay(){
      if (this.$store.state.pipelineV1.selectedPipelineBind.resource.spec.pipeline === "preprocess.standard"){
        return ['Config Map', 'Pipeline', 'Example', 'Chart']
      } else {
        return ['Config Map', 'Pipeline', 'Example', 'Chart', 'Events', "Holidays"]
      }
    },
    editable () {
      var editable = true;
      if(this.$store.state.editor.editModeIsOn == true){
        editable = false;
      }
      if(this.pipelineBind.resource.spec.editable == false){
        editable = false;
      }
      return editable //this.pipelineBind.resource.spec.editable
    },
    currentConfigMap () {
      return this.pipelineBind.resource.spec.configMaps
    },
    currentPipeline () {
      return this.pipelineBind.resource.spec.pipeline
    },
    currentPipelineHash () {
      return md5(this.pipelineBind.resource.spec.pipeline)
    },
    currentLabels () {
      return this.pipelineBind.resource.metadata.labels
    },
    strategy () {
      return this.$store.state.pipelineV1.currentStrategy
    },
    pipelineBinds () {
      return this.$store.state.pipelineV1.currentPipelineBinds
    },
    pipelines () {
      return this.$store.state.pipelineV1.pipelines
    },
    configMaps () {
      return this.$store.state.pipelineV1.configMaps
    },
    customConfigMapParams(){
      this.pipelineBindCustomConfigMapYamlKey;
      var parameters = jsYaml.load(this.$store.state.editor.codeToRender);
      return parameters;       
    },
    getReconciliations(){
      var params = this.$store.state.pipelineV1.selectedPipelineBind.resource.spec.customConfigMap.parameters
      if(params.reconciliations?.length === 0){
        delete this.$store.state.pipelineV1.selectedPipelineBind.resource.spec.customConfigMap.parameters?.reconciliations
      }
      return this.$store.state.pipelineV1.selectedPipelineBind.resource.spec.customConfigMap.parameters?.reconciliations;   
    },
    getRoomsRates(){
      var parameters = jsYaml.load(this.$store.state.editor.codeToRender)
      if(parameters.global.price_range == undefined){
        parameters.global['price_range'] = [];
      }
      return parameters.global.price_range;
    },
    showVisualEditors(){
      var show = true;
      if(this.$store.state.pipelineV1.selectedPipelineBind?.resource?.metadata?.labels?.includes('preprocess')){
        show = false;
      }
      if(this.$store.state.editor.editModeIsOn == true){
        show = false;
      }
      return show;
    },

    marketsList(){
      return this.$store.state.pipelineV1.markets.map(mkt => mkt.metadata.id);
    },
    marketValue(){

      var parameters = jsYaml.load(this.$store.state.editor.codeToRender);
      var market = null;
      if ( parameters && parameters.global !== undefined && parameters.global.market_subscription !== undefined ) {
        market = parameters.global.market_subscription
      }
      return market;
    
    },
   
    pipelineYamlExample(){
      var yamlObj = jsYaml.load(JSON.stringify(PipelineYamlExample));
      return yamlObj
    },
    reconciliationYamlExample(){
      var yamlObj = jsYaml.load(JSON.stringify(ReconciliationYamlExample));
      return yamlObj
    },
    getExampleYaml(){
      if(this.showVisualEditors){
        return this.pipelineYamlExample
      }
      return this.reconciliationYamlExample
    }

  },
  methods: {

    checkTabChange(event){
      var feedback = this.testYaml();
      if(feedback != 'success'){
        event.stopPropagation();
        this.$store.dispatch("alertSystem/newError", {
          message: feedback.messages[0]
        })
      }
    },

    testYaml(){
      this.$store.state.pipelineV1.yamlIsValid = false;
      var feedback = null;
      try{
        jsYaml.load(this.$store.state.editor.codeToRender);
        this.$store.state.pipelineV1.yamlIsValid = true;

        feedback = "success";

      }
      catch(error){

        let position = "Line: " + error.mark.line + ", column: " + error.mark.column + ".";
        let message = error.reason + ". " + position;
        feedback = {color: "red accent-2", timeout: -1, messages: [message], title: "Invalid YAML format!"};
      }
      finally{
        return feedback;
      }
    },

    setMarket(marketId){
      var pbind = yaml.load(this.$store.state.editor.codeToRender);

      if(pbind.global === undefined){
        pbind.global = {};
      }

      if(pbind.global.market_subscription === undefined){
        pbind.global.market_subscription = "";
      }
      pbind.global.market_subscription = marketId;

      this.$store.state.editor.codeToRender = yaml.dump(pbind, {ref: false})
    },
    getRandomInt() {
      return Math.floor(Math.random() * 10000)
    },
    composedPipelineBindName (pipelineBind) {
        return pipelineBind.resource.metadata.name
    },
    async onSaveButtonClick() {
      
      if ( this.maxPipelineBindVersion != this.currentPipelineBindVersion ) {
        this.$store.dispatch('alertSystem/newError', {
          title: "Error",
          message: `Can't save this Pipelinebind since the selected version (${this.currentPipelineBindVersion}) doesn't match the latest version (${this.maxPipelineBindVersion})`,
          timeout: -1,
        });
        return
      }
      
      this.loading = true
      let response = await this.save()
      this.loading = false;

      if ( response.level == 'error' ) {
        this.$store.dispatch('alertSystem/newError', {
          title: response.kind,
          message: response.message,
          timeout: -1,
        });
      } else if ( response.level == 'warning' ) {
        this.$store.dispatch('alertSystem/newWarning', {
          title: response.kind,
          message: response.message,
          timeout: -1,
        });
      } else if ( response.level == 'success' ) {
        this.$store.dispatch('alertSystem/newSuccess', {
          title: response.kind,
          message: response.message,
          timeout: -1,
        });
      } else if ( response.level == 'info' ) {
        this.$store.dispatch('alertSystem/newInfo', {
          title: response.kind,
          message: response.message,
          timeout: -1,
        });
      } else {
        this.$store.dispatch('alertSystem/addAlert', {
          type: undefined,
          title: response.kind,
          message: response.message,
          icon: undefined,
          color: `grey lighten-1`,
          timeout: -1,
        });
      }      

    },
    async onSaveAndRunButtonClick() {

      if ( this.maxPipelineBindVersion != this.currentPipelineBindVersion ) {
        this.$store.dispatch('alertSystem/newError', {
          title: "Error",
          message: `Can't save this Pipelinebind since the selected version (${this.currentPipelineBindVersion}) doesn't match the latest version (${this.maxPipelineBindVersion})`,
          timeout: -1,
        });
        return
      }

      this.executingSaveAndRun = true
      this.circularProgressText = 'Saving the new Pipelinebind'
      this.circularProgressColor = 'green'

      let title = '';
      let message = '';
      let color = '';

      let saveResult = await this.save()
      this.loading = false
      if ( saveResult.error == true ) {
        this.circularProgressText = 'Error while saving the Pipelinebind'
        this.circularProgressColor = 'red darken-1'

        title = 'Error'
        color = 'red darken-1'
        message =  saveResult.message

      } else {

        this.circularProgressText = 'Running the Pipelinebind'
        let runResult = await this.run()

        if ( runResult.error == true ) {
          
          this.circularProgressText = 'Error while running the Pipelinebind'
          this.circularProgressColor = 'red darken-1'

          title = 'Error'
          color = 'red darken-1'
          message =  runResult.messages
        
        } else {

          this.circularProgressText = 'Rendering the charts'
          this.circularProgressText = 'Finished with success!'

          title = 'Success'
          message = ['The new Pipelinebind was saved and executed with success']
          color = 'green'

        }
      }

      await new Promise(r => setTimeout(r, 1500));
      this.executingSaveAndRun = false

      if (title === "Error") {
        this.$store.dispatch("alertSystem/newError", {
          message: message
        })
      } else {
        this.$store.dispatch("alertSystem/newSuccess", {
          message: message
        })
      }
      
    },
    hideLeftTab () {
      this.centralTabClass='col-md-4 col-sm-12 card-ui'
      this.rightTabClass='col-md-8 col-sm-12 card-ui'
      this.showMenu=false
    },
    showLeftTab () {
      this.centralTabClass='col-md-5 col-sm-12 card-ui'
      this.rightTabClass='col-md-4 col-sm-12 card-ui'
      this.showMenu=true
    },
    async deletePipelineBind () {
      let strategyName = this.pipelineBind.resource.metadata.name;
      let options = {cardTextClass: "pa-4 red accent-3", color: "red accent-4"};
      let title = "Confirm delete";
      let message = '<p  style="text-align:center"><strong>Are you sure you want to delete ' + strategyName + ' strategy?</strong> <br><br>';
      
      if (await this.$refs.confirm.open(title, message, options)) {
          let res = await this.$store.dispatch("pipelineV1/deletePipelineBind", {
            workspace: this.pipelineBind.resource.metadata.workspace,
            kind: this.pipelineBind.resource.kind,
            name: this.pipelineBind.resource.metadata.name,
          })
          
          this.showActionOutput = true
          this.showActionOutputContent = {
            kind: 'deleted-pipeline-bind',
            res: "",
            level: 'success'
          }

        this.$emit('close')
      }
    },

    async getRoot () {
      const originalRoot = await this.$store.dispatch('pipelineV1/fetchPipelineBind', {
        kind: 'PipelineBind',
        workspace: "master",
        name: this.pipelineBind.resource.metadata.root
      })
      if (!originalRoot) {
        return {version: null, name: null}
      }
      return {version: originalRoot.version, name: originalRoot.resource.metadata.name}
    },

    async save () {
      this.loading = true;
      this.$store.dispatch('editor/unSetOriginalCode', {});

      let customConfigMap = null
      try {
        customConfigMap = jsYaml.load(this.$store.state.editor.codeToRender)
      } catch (error) {
        this.loading = false;
        return { error: true, level: 'error', timeout: -1, kind: "YAML syntax invalid", message: [error]}
      }

      if (this.pipelineBind.resource.spec.customConfigMap == undefined) {
        this.pipelineBind.resource.spec.customConfigMap = {parameters: {}}
      }
      this.pipelineBind.resource.spec.customConfigMap.parameters = customConfigMap

      var validatedMessages = await this.$store.dispatch('pipelineV1/validateCustomConfig', {
        pipelineBind: this.pipelineBind,
        customConfigMap: customConfigMap
      })

      if(validatedMessages?.length > 0){
        this.loading = false;
        return { error: true, level: 'error', timeout: -1, kind: "YAML validation failed", message: validatedMessages}
      }

      // Version reference managment
      if (this.pipelineBind.resource.metadata.root !== null) {
        const rootVN = await this.getRoot()
        if (this.pipelineBind.resource.metadata.rootVersion !== rootVN.version
          && this.pipelineBind.resource.metadata.rootVersion == null)  {
          this.pipelineBind.resource.metadata.rootVersion = rootVN.version
        }
      }

      let res = await this.$store.dispatch('pipelineV1/savePipelineBind', {
        newPipelineBind: this.pipelineBind
      })
      //////
      let toRet = null

      if (res.status == 'created' || res.status == 'updated') {    
        toRet = { error: false, level: 'success', timeout: -1, kind: "Saved Pipelinebind", message: res}
        
      } else if (res.status == 'untouched') {
        toRet = { error: false, level: 'info', timeout: -1, kind: "Pipelinebind untouched", message: ["The Pipelinebind wasn't saved as no edits were found"]}
        
      } else if (res.status == 'invalidschema') {
        toRet = { error: true, level: 'error', timeout: -1, kind: "Invalid Schema", message: [res?.items[0]?.instancePath + ' ' + res?.items[0]?.message] }
        
      } else {
        toRet = { error: true, level: 'info', timeout: -1, kind: "Unknwon", message: ['Unknown errror']}
      }

      //////

      var originalCode = yaml.dump(this.pipelineBind.resource.spec.customConfigMap.parameters, {ref: false});
      this.$store.dispatch('editor/setOriginalCode', {originalCode: originalCode});
      
      // update version in the dropdown menu
      this.currentPipelineBindVersion = this.pipelineBind.version
      this.maxPipelineBindVersion = this.currentPipelineBindVersion

      this.loading = false;
      return toRet

    },
    async run () {
      
      let r = await this.$store.dispatch('pipelineV1/runPipelinebind', {
        name: this.pipelineBind.resource.metadata.name,
        workspace: this.pipelineBind.resource.metadata.workspace,
        forceCache: true,
      })
      return r
    },
    async setupView () {
      if (this.$store.state.pipelineV1.selectedPipelineBind == null) {
      } else {

        // It's to update
        this.pipelineBind = JSON.parse(JSON.stringify(this.$store.state.pipelineV1.selectedPipelineBind))
        this.pipelineBindResource = this.pipelineBind.resource
        this.pipelineBindCustomConfigMapYaml = this.pipelineBindResource.spec.customConfigMap.parameters
        if (this.pipelineBindResource.metadata.name == null) {
          this.toEdit = true
        } else {
          this.toEdit = false
        }

        // To rollback we need every version of the pb
        const pipelineBindHistory = await this.$store.dispatch("pipelineV1/fetchHistory", {
          kind: 'PipelineBind',
          name: this.pipelineBind.resource.metadata.name,
          workspace: this.pipelineBind.resource.metadata.workspace
        })
        if (!!pipelineBindHistory?.length) {
          this.pipelineBindHistory = pipelineBindHistory
          this.pipelineBindHistoryVersions = pipelineBindHistory.map((i) => { return i.version })
        }        
        this.currentPipelineBindVersion = this.pipelineBind.version
        this.maxPipelineBindVersion = this.currentPipelineBindVersion
      }

    },
    resetToDefault(){
      const customConfigMap = jsYaml.load(this.$store.state.editor.codeToRender)
      this.$store.state.editor.codeToRender = jsYaml.dump({
        global: customConfigMap.global
      });
    },

    async overwriteReference() {

      if ( this.maxPipelineBindVersion != this.currentPipelineBindVersion ) {
        this.$store.dispatch('alertSystem/newError', {
          title: "Error",
          message: `Can't save this Pipelinebind since the selected version (${this.currentPipelineBindVersion}) doesn't match the latest version (${this.maxPipelineBindVersion})`,
          timeout: -1,
        });
        return
      }

      let response = await this.save()

      if ( response.level == 'error' ) {
        this.$store.dispatch('alertSystem/newError', {
          title: response.kind,
          message: response.message,
          timeout: -1,
        });
      }
      
      const pbSource = this.pipelineBind.resource.metadata.name
      const pbTarget = this.pipelineBind.resource.metadata.root
      
      let res = await this.$store.dispatch('pipelineV1/overwritePipelineBind', {
        sourcePb: pbSource, 
        targetPb: pbTarget  
      })
      
      if ( res.error ) {
        this.$store.dispatch("alertSystem/newError", {
          message: "Error during the overwriting of the Pipelinebind"
        })
      } else {
        this.$store.dispatch("alertSystem/newSuccess", {
          message: "Pipelinebind successfully overwritten"
        })
        this.cloneOverlay = !this.cloneOverlay
      }
      //call fetch
      this.overwriteOverlay = !this.overwriteOverlay

    }
  },
  beforeMount () {
    this.setupView()
  },
  mounted(){
    if (this.$store.state.pipelineV1.selectedPipelineBind.resource.spec.pipeline !== "preprocess.standard") {
      if (!this.$store.getters["basePrice/basePriceGenerated"]) {
        this.$store.dispatch("alertSystem/newWarning", {
          message: "The base price is not present for this PG so you won't be able to save the current predict pipeline bind!",
          timeout: -1,
        })
      }
    }
  }
}
</script>
