<template>
    <v-container class="pa-4" fluid>
        <DiffReservations v-if="currentPG"></DiffReservations>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DiffReservations from '../components/DiffReservations.vue'
import SelectPgAlert from '@/components/utils/SelectPgAlert'

export default {
    components: {DiffReservations, SelectPgAlert},
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG, 
        }),
    },
    methods: {
        ...mapActions({
        }),
    },
    async created() {
    }
}
</script>

<style lang="scss" scoped>

</style>