<template>
    <v-card class="card-ui pa-5">
        <v-card-title>Property Group State</v-card-title>
        <v-row>
            <v-col class="col-4" v-for="bundle in bundlesList" :key="bundle">
                <LifecycleEditor :lifecycle="currentLifecycleStateByBundle?.[bundle]" :bundle="bundle" />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import BulkEditChildrenStatus from './BulkEditChildrenStatus.vue'
import LifecycleEditor from '../components/LifecycleEditor.vue'

export default {
    components: { BulkEditChildrenStatus, LifecycleEditor },
    data() {
        return {
            pgCurrentChildren: null,
            newLifecycleStatus: null,
            pgStatusConfirmDialog: false,
            lifecycleStatesOrdering: ["created", "test", "preonboarding", "onboarding", "prelive", "trial", "live", "lost", "deleted"],
        }
    },
    watch: {
        pgInfo(newValue, oldValue) {
            this.resetChanges()
        },
    },
    computed: {
        ...mapState({
            pgParents: state => state.core.currentParent,
            pgInfo: state => state.core.currentPG,
        }),
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
            pgNameById: "core/pgNameById",
            bundlesList: "lifecycle/bundlesList",
            currentLifecycleStateByBundle: "lifecycle/currentLifecycleStateByBundle",
        }),
        propertyGroupList() {
            const possibleChildren = this.$store.getters["core/pgsDropdown"]
            const selectedPG = this.globalSelectedPgId

            return possibleChildren.filter(children => {
                if (children.text === selectedPG) {
                    return false
                }
                return true
            })
        },
    },
    methods: {
        ...mapActions({
            executeAutomapping: "core/executeAutomapping",
        }),
        resetChanges() {
            this.pgCurrentChildren = this.$store.state.core.currentChildren
        },
        savePGState() {
            let newState = {
                metadata: {
                    propertyGroupId: this.pgInfo.metadata.id,
                    name: this.newLifecycleStatus?.split('/')[0],
                    label: this.newLifecycleStatus?.split('/')[1],
                    setBy: this.$store.state.auth.username,
                    lifecycleStateId: this.$store.state.core.lifecycleStatesList.find(val => {
                        return val.metadata.label === this.newLifecycleStatus?.split('/')[1] && val.metadata.name === this.newLifecycleStatus?.split('/')[0]
                    }).metadata.id
                }
            }

            this.$store.dispatch('core/updatePgState', { newState: newState })
            this.newLifecycleStatus = null
        },
        updateStatusCheck() {
            if (['lost/default', 'deleted/default'].includes(this.newLifecycleStatus)) {
                this.pgStatusConfirmDialog = true
            } else {
                this.savePGState()
            }
        },
        async savePGChildren() {
            await this.$store.dispatch('core/updatePgChildren', {
                newChildren: this.pgCurrentChildren,
            })
            this.resetChanges()
        },
    },
    async created() {
        this.resetChanges()
    }
}
</script>
