<template>
    <v-card class="pa-4 card-ui">
        <v-card-title>Oauth Link Generator</v-card-title>
        <v-card-subtitle>Generate the link for Oauth for smoobu, octorate and apaleo</v-card-subtitle>

        <v-card-text>
            <v-form
                ref="form"
                v-model="validForm"
            >
                <v-row>
                    <v-col>
                        <v-autocomplete
                            label="Select partner"
                            dense
                            outlined
                            :disabled="!supportsOauth"
                            :items="partnerDropdown"
                            :rules="[rules.required]"
                            v-model="selectedPartner"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>

            <v-text-field
                dense
                v-if="generatedLink"
                outlined
                persistent-hint
                :hint="copied ? 'Link copied to clipboard' : ''"
                readonly
                :value="generatedLink"
                @click="openNewTab()"
            >
                <template v-slot:append-outer>
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <v-btn
                            outlined
                            style="top: -6px"
                            icon
                            :disabled="!generatedLink"
                            rounded
                            :class="hover ? `white--text` : `grey--text darken-4`"
                            tile
                            plain
                            @click="copyLink()"
                        >
                            <v-icon>fa-regular fa-copy</v-icon>
                        </v-btn>
                    </v-hover>
                </template>
            </v-text-field>
        </v-card-text>

        <v-card-actions>
            <v-row>
                <v-col class="shrink">
                    <v-btn
                        color="primary"
                        @click="generateLink()"
                        :loading="loading"
                        :disabled="!supportsOauth"
                    >Generate Link</v-btn>
                </v-col>
                <v-col class="grow orange--text" v-if="!supportsOauth">
                    Partners of selected pg don't support oauth
                </v-col>
            </v-row>
            
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {router} from "vue-router"

export default {
    data() {
        return {
            validForm: true,
            loading: false,

            selectedPartner: "",
            copied: false,

            rules: {
                required: val => !!val || "Required!"
            }
        }
    },
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG,
            generatedLink: state => state.oauth.link,
        }),
        ...mapGetters({
            partnerDropdown: "oauth/partnerDropdown",
            supportsOauth: "oauth/supportsOauth"
        })
    },
    methods: {
        ...mapActions({
            getLink: "oauth/getLink",
        }),
        async copyLink() {
            await navigator.clipboard.writeText(this.generatedLink)
            this.copied = true
            setTimeout(() => this.copied = false, 5000)
        },
        async openNewTab() {
            if (this.generatedLink) {
                window.open(this.generatedLink, "_blank");
            }
        },
        async generateLink() {
            this.$refs.form.validate()

            if (this.validForm) {
                this.loading = true

                this.getLink({
                    partnerType: this.selectedPartner,
                    pgId: this.currentPG.metadata.id,
                })

                this.loading = false
            }
        }
    }
}
</script>