<template>
    <v-container fluid class="pa-4">
        <v-card  class="card-ui pa-4">
            <v-card-text>
                <v-row>
                    <v-col class="col-12">
                        <h3>Instructions</h3>
                        <p>
                            This page allows you to scrape all the hotels in a certain area. You can select the operations you want to perform and the coordinates of the area.
                        </p>
                        <p>
                            Order of operations:
                            <ul>
                                <li>Scrape preview prices as required</li>
                            </ul>
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="col col-5">
                        <v-text-field label="Latitude" hint="For example 40.63060491398805" outlined v-model="latitude"></v-text-field>
                    </v-col>
                    <v-col class="col col-5">
                        <v-text-field label="Longitude" hint="For example 14.48469149714154" outlined v-model="longitude"></v-text-field>
                    </v-col>
                    <v-col class="col-2">
                        <v-btn @click="latitude = pgLatitude; longitude = pgLongitude" v-if="!!globalSelectedPgId">Copy current PG coordinates</v-btn>
                    </v-col>
                    <v-col class="col col-5">
                        <v-text-field label="Radius (KM)" hint="For example 7.5" outlined v-model="radius"></v-text-field>
                    </v-col>
                    <v-col class="col-12">
                        <h3> Job operations</h3>
                        <v-checkbox v-model="jobs.preview.enabled" label="Preview prices"></v-checkbox>
                        <v-switch v-model="hp" inset :label="`${hp ? 'HP' : 'LP'}`"></v-switch>
                    </v-col>
                    <v-col class="col-12">
                        <v-btn color="success" block @click="launchJob">Launch job</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
// @ is an alias to /src
import SelectPgAlert from '@/components/utils/SelectPgAlert'
import { mapActions, mapGetters, mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'Area',
    components: {
        SelectPgAlert,
    },
    data: function (argument) {
        return {
            latitude: "",
            longitude: "",
            radius: "5",
            hp: false,
            jobs: {
                preview: {
                    enabled: false,
                    type: "PreviewPrice",
                },
            },
        }
    },
    watch: {
    },
    computed: {
        ...mapState({
            pgLatitude: state => state.core.currentPG.spec.coordinates?.x,
            pgLongitude: state => state.core.currentPG.spec.coordinates?.y,
        }),
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
        }),
    },
    methods: {
        ...mapActions({
        }),
        async launchJob() {
            let actionsToCall = []

            if (!this.latitude || !this.longitude || !this.radius) {
                this.$store.dispatch("alertSystem/newWarning", {
                    message: "Please fill all the fields"
                })
                return
            }

            if (!Object.values(this.jobs).some(job => job.enabled)) {
                this.$store.dispatch("alertSystem/newWarning", {
                    message: "Please select at least one job"
                })
                return
            }

            const sessionId = uuidv4()

            let targets = []
            await this.$store.dispatch("scraper/search", {
                query: {
                    lat: this.latitude,
                    lon: this.longitude,
                    distance: this.radius + "km",
                    limit: 99999,
                },
                cb: function (res) {
                    targets = res.results.map(result => result.booking_id)
                }.bind(this)
            })

            for (let job in this.jobs) {
                if (this.jobs[job].enabled == true) {
                    const STEP = 1000

                    for (let i = 0; i < targets.length; i += STEP) {
                        const t = targets.slice(i, i + STEP)

                        actionsToCall.push(this.$store.dispatch('scraper/submit', {
                            job: {
                                "apiVersion": "scraper/v2",
                                "kind": "Request",
                                "metadata": {
                                    "tenant": "sp",
                                    "namespace": "Booking",
                                    "type": this.jobs[job].type,
                                    "channel": this.hp ? "HP" : "LP",
                                    "sessionId": sessionId,
                                },
                                "spec": {
                                    "targets": t,
                                }
                            },
                            cb: function (res) {
                                this.$store.dispatch("alertSystem/newInfo", {
                                    message: `${JSON.stringify(res)}`
                                })
                            }.bind(this)
                        }))
                    }
                }
            }

            Promise.all(actionsToCall)
                .then((value) => { })
                .catch((error) => {
                    this.$store.dispatch("alertSystem/newError", {
                        message: `Error while performing the scraping operations: ${error}`
                    })
                })
        }
    },
    async created() {
    }
}
</script>
