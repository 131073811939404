<template>
    <v-card class="card-ui pa-4">
        <v-card-title>Remove Rate</v-card-title>

        <v-row>
            <v-col class="col-6">
                <v-autocomplete
                    outlined
                    dense
                    label="Select Rate"
                    :items="currentRates"
                    v-model="selectedRate"
                ></v-autocomplete>

                <v-card-actions>
                    <v-btn
                        color="error"
                        @click="deleteRate({rate: selectedRate})"
                    >Delete Selected Rates</v-btn>
                </v-card-actions>
            </v-col>
            <v-col class="col-6">
                <v-card>
                    <v-card-title>
                        Properties Affected
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="currentPropertyRates"
                    ></v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    data() {
        return {
            selectedRate: null,

            headers: [
                {
                    text: "Property ID",
                    value: "property_id",
                },
                {
                    text: "Property Name",
                    value: "property_name",
                }
            ]
        }
    },
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG,
            currentProperties: state => state.core.currentProperties,
        }),
        ...mapGetters({
            ratesByPropertyGroupID: "rates/ratesByPropertyGroupID",
            propertyRatesByRateID: "rates/propertyRatesByRateID",
            allPropertyNamesById: "core/allPropertyNamesById",
        }),
        currentRates() {
            return this.ratesByPropertyGroupID[this.currentPG.metadata.id] !== undefined ? this.ratesByPropertyGroupID[this.currentPG.metadata.id].map(rate => {
                return {
                    text: `${rate.metadata.id} - ${rate.spec.property_group_id} - ${rate.spec.name}`,
                    value: rate
                }
            }) : []
        },
        currentPropertyRates() {
            return this.propertyRatesByRateID[this.selectedRate?.metadata?.id] !== undefined ? this.propertyRatesByRateID[this.selectedRate.metadata.id].map(propRate => {
                return {
                    property_id: propRate.spec.property_id,
                    property_name: this.allPropertyNamesById[propRate.spec.property_id],
                }
            }) : []
        }
    },
    methods: {
        ...mapActions({
            deleteRate: "rates/deleteRate",
        })
    },
    destroyed() {
        this.selectedRate = null
    },
}
</script>