<template>
    <v-card class="pa-4 card-ui">

        <v-form
            ref="insertForm"
            v-model="isFormValid"
        >
            <v-row>
                <v-col class="shrink">
                    <v-btn
                        :outlined="!selectedPartnerTypes.includes('pms')"
                        color="primary"
                        @click="[selectedPartnerTypes.includes('pms') ? removeFromArray(selectedPartnerTypes, 'pms') : selectedPartnerTypes.push('pms'), loadPMS()]"
                        class="mr-2"
                    >PMS</v-btn>
                </v-col>
                <v-col class="shrink">
                    <v-btn
                        :outlined="!selectedPartnerTypes.includes('cm')"
                        color="primary"
                        @click="[selectedPartnerTypes.includes('cm') ? removeFromArray(selectedPartnerTypes, 'cm') : selectedPartnerTypes.push('cm'), loadCM()]"
                        class="mr-2"
                    >CM</v-btn>
                </v-col>
                <v-col class="grow" v-show="!!selectedPartnerTypes.length">
                    <v-text-field
                        outlined
                        dense
                        :rules="[rules.required]"
                        label="Partner Name"
                        v-model="selectedPartnerName"
                    ></v-text-field>
                </v-col>
                <v-col class="col-12" v-show="!!selectedPartnerTypes.length">
                    <v-row>
                        <v-col class="auto">
                            <v-switch
                                outlined
                                dense
                                class="mt-0"
                                :true-value="true"   
                                :false-value="false"
                                label="API Smartpricing"
                                v-model="apiSmartpricing"
                                @change="pmscmIdsRequiredSet($event)"
                            ></v-switch>
                        </v-col>
                        <v-col class="auto">
                            <v-switch
                                outlined
                                dense
                                class="mt-0"
                                :true-value="true"   
                                :false-value="false"
                                label="Add Test Property Group"
                                v-model="addTestPropertyGroup"
                            ></v-switch>
                        </v-col>
                        <v-col class="auto" v-if="selectedPartnerTypes.includes('pms')">
                            <v-switch
                                outlined
                                dense
                                :input-value="!!selectedPMS?.spec?.extra_params?.pmsIdRequired"
                                class="mt-0"
                                :true-value="true"   
                                :false-value="false"
                                label="PMS ID Required"
                                @change="setPmsIdRequired($event)"
                            ></v-switch>
                        </v-col>
                        <v-col class="auto" v-if="selectedPartnerTypes.includes('cm')">
                            <v-switch
                                outlined
                                dense
                                :input-value="!!selectedCM?.spec?.extra_params?.cmIdRequired"
                                class="mt-0"
                                :true-value="true"   
                                :false-value="false"
                                label="CM ID Required"
                                @change="setCmIdRequired($event)"
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
    
            <v-divider v-show="!!selectedPartnerTypes.length" class="my-2"></v-divider>
    
            <v-card-title v-if="selectedPartnerTypes.includes('pms')">PMS Info</v-card-title>
    
            <v-row v-if="selectedPartnerTypes.includes('pms')">
                <v-col class="col-6">
                    <v-textarea
                        outlined
                        dense
                        label="Requests Limits"
                        :rules="[rules.validJSON]"
                        rows="10"
                        :value="!!selectedPMS?.spec?.requests_limits ? JSON.stringify(selectedPMS.spec.requests_limits) : ''"
                        @change="selectedPMS.spec.requests_limits = !!$event ? JSON.parse($event) : null"
                    ></v-textarea>
                </v-col>
                <v-col class="col-6">
                    <v-textarea
                        outlined
                        dense
                        label="Partner Extra Params"
                        :rules="[rules.validJSON]"
                        rows="10"
                        :value="!!selectedPMS?.spec?.extra_params ? JSON.stringify(selectedPMS.spec.extra_params) : ''"
                        @change="selectedPMS.spec.extra_params = !!$event ? JSON.parse($event) : null"
                    ></v-textarea>
                </v-col>
                <v-col class="col-12">
                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="1000"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                            >Edit Property Group Extra Params Schema</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                    color="primary"
                                    dark
                                >Editing Property Group Extra Params Schema</v-toolbar>
                                
                                <DynamicFormSchemaEditor
                                    v-model="selectedPMS.spec.property_group_extra_params_schema"
                                ></DynamicFormSchemaEditor>

                                <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        @click="dialog.value = false"
                                    >Close</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="1000"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                v-bind="attrs"
                                class="mr-2"
                                v-on="on"
                            >Edit Partner Encoded Access Info Schema</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                    color="primary"
                                    dark
                                >Edit Partner Encoded Access Info Schema</v-toolbar>
                                
                                <DynamicFormSchemaEditor
                                    v-model="selectedPMS.spec.encoded_access_info_schema"
                                ></DynamicFormSchemaEditor>

                                <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        color="success"
                                        @click="dialog.value = false"
                                    >Ok</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-col>
            </v-row>
    
            <v-divider v-show="selectedPartnerTypes.length === 2" class="my-2"></v-divider>
            
            <v-card-title v-if="selectedPartnerTypes.includes('cm')">Channel Manager Info</v-card-title>
    
            <v-row v-if="selectedPartnerTypes.includes('cm')">
                <v-col class="col-6">
                    <v-textarea
                        outlined
                        dense
                        label="Requests Limits"
                        rows="10"
                        :rules="[rules.validJSON]"
                        :value="!!selectedCM.spec.requests_limits ? JSON.stringify(selectedCM.spec.requests_limits) : ''"
                        @change="selectedCM.spec.requests_limits = !!$event ? JSON.parse($event) : null"
                    ></v-textarea>
                </v-col>
                <v-col class="col-6">
                    <v-textarea
                        outlined
                        dense
                        label="Extra Params"
                        rows="10"
                        :rules="[rules.validJSON]"
                        :value="!!selectedCM.spec.extra_params ? JSON.stringify(selectedCM.spec.extra_params) : ''"
                        @change="selectedCM.spec.extra_params = !!$event ? JSON.parse($event) : null"
                    ></v-textarea>
                </v-col>
                <v-col class="col-12">
                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="1000"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                            >Edit Property Extra Params Schema</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                    color="primary"
                                    dark
                                >Editing Property Extra Params Schema</v-toolbar>
                                
                                <DynamicFormSchemaEditor
                                    v-model="selectedCM.spec.property_extra_params_schema"
                                ></DynamicFormSchemaEditor>

                                <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        color="success"
                                        @click="dialog.value = false"
                                    >Ok</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                
                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="1000"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                            >Edit Property Group Extra Params Schema</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                    color="primary"
                                    dark
                                >Editing Property Group Extra Params Schema</v-toolbar>
                                
                                <DynamicFormSchemaEditor
                                    v-model="selectedCM.spec.property_group_extra_params_schema"
                                ></DynamicFormSchemaEditor>

                                <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        color="success"
                                        @click="dialog.value = false"
                                    >Ok</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                
                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="1000"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                            >Edit Partner Encoded Access Info Schema</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                    color="primary"
                                    dark
                                >Edit Partner Encoded Access Info Schema</v-toolbar>
                                
                                <DynamicFormSchemaEditor
                                    v-model="selectedCM.spec.encoded_access_info_schema"
                                ></DynamicFormSchemaEditor>

                                <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        color="success"
                                        @click="dialog.value = false"
                                    >Ok</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-form>

        <v-divider v-show="!!selectedPartnerTypes.length" class="my-2"></v-divider>

        <v-card-actions v-show="!!selectedPartnerTypes.length" class="pa-0">
            <v-btn
                color="primary"
                :loading="insertingPartner"
                @click="insertPartner()"
            >{{ selectedPartnerTypes.length === 1 ? `Insert Partner` : `Insert Partners` }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import DynamicFormSchemaEditor from './DynamicFormSchemaEditor.vue';

export default {
    components: {DynamicFormSchemaEditor},
    data() {
        return {
            insertingPartner: false,
            selectedPartnerTypes: [],

            apiSmartpricing: false,
            addTestPropertyGroup: false,

            selectedPartnerName: "",
            selectedPMS: null,
            selectedCM: null,

            isFormValid: true,

            rules: {
                required: val => !!val || "Required!",
                validJSON: val => {
                    try {
                        if (!val) return true
                        JSON.parse(val)
                        return true
                    } catch {
                        return "Invalid JSON"
                    }
                }
            }
        }
    },
    computed: {
        possibleStates() {
            return this.$store.state.core.lifecycleStatesList.map(value => {
                return {
                    id: value.metadata.id,
                    label: value.metadata.label,
                    name: value.metadata.name,
                }
            })
        }
    },
    methods: {
        ...mapActions({
            addPms: "core/addPms",
            addCm: "core/addCm",
            insertSmartpricingPartner: "core/insertSmartpricingPartner",
            addPG: "core/addPG",
        }),
        removeFromArray(strArr, str) {
            const index = strArr.findIndex(val => val === str)

            if (index !== -1) {
                strArr.splice(index, 1)
            }
        },
        loadPMS() {
            if (this.selectedPartnerTypes.includes("pms")) {
                this.selectedPMS = null
                this.selectedPMS = {
                    spec: {
                        access_info_schema: null,
                        requests_limits: null,
                        extra_params: null,
                        encoded_access_info_schema: null,
                        property_group_extra_params_schema: null,
                    }
                }
            } else {
                this.selectedPMS = null
            }
        },
        loadCM() {
            if (this.selectedPartnerTypes.includes("cm")) {
                this.selectedCM = null
                this.selectedCM = {
                    spec: {
                        access_info_schema: null,
                        requests_limits: null,
                        send_all_occupancies: false,
                        extra_params: null,
                        encoded_access_info_schema: null,
                        property_extra_params_schema: null,
                        property_group_extra_params_schema: null,
                    }
                }
            } else {
                this.selectedCM = null
            }
        },
        pmscmIdsRequiredSet(requiredVal) {
            if (!!this.selectedCM) {
                this.setCmIdRequired(requiredVal)
            }

            if (!!this.selectedPMS) {
                this.setPmsIdRequired(requiredVal)
            }
        },
        setCmIdRequired(requiredVal) {
            if (!this.selectedCM?.spec?.extra_params) {
                this.selectedCM.spec.extra_params = {
                    cmIdRequired: requiredVal
                }
            } else {
                this.selectedCM.spec.extra_params.cmIdRequired = requiredVal
            }
        },
        setPmsIdRequired(requiredVal) {
            if (!this.selectedPMS?.spec?.extra_params) {
                this.selectedPMS.spec.extra_params = {
                    pmsIdRequired: requiredVal
                }
            } else {
                this.selectedPMS.spec.extra_params.pmsIdRequired = requiredVal
            }
        },
        async insertPartner() {
            this.$refs.insertForm.validate()

            if (this.isFormValid) {
                this.insertingPartner = true
                if (this.apiSmartpricing) {
                    await this.insertSmartpricingPartner({
                        name: this.selectedPartnerName
                    })
                }
    
                if (this.selectedPartnerTypes.includes('pms')) {
                    this.selectedPMS.metadata = {
                        id: this.selectedPartnerName
                    }
                    await this.addPms({body: this.selectedPMS})
                }
                
                if (this.selectedPartnerTypes.includes('cm')) {
                    this.selectedCM.metadata = {
                        id: this.selectedPartnerName
                    }
                    await this.addCm({body: this.selectedCM})
                }

                this.$store.dispatch("alertSystem/newSuccess", {
					message: `New Partner added successfully`,
				})
    
                if (this.addTestPropertyGroup) {
                    const testName = `${this.selectedPartnerName}_test`
    
                    const res = await this.addPG({config: {
                        metadata: {
                            name: testName,
                        },
                        spec: { // not efficient since it has to do 4 checks but this operation is done so rarely it doesn't matter
                            pms_id: this.selectedPartnerTypes.includes("pms") ? testName : null,
                            pms_name: this.selectedPartnerTypes.includes("pms") ? this.selectedPartnerName : null,
                            channel_manager_id: this.selectedPartnerTypes.includes("cm") ? testName : null,
                            channel_manager_name: this.selectedPartnerTypes.includes("cm") ? this.selectedPartnerName : null,
                            is_set_prices_enabled: true,
                        }
                    }})

                    const defaultState = this.possibleStates.find(value => value.name === "test" && value.label === "default")

                    await this.$store.dispatch('core/updatePgState', {newState: {
                        metadata: {
                            propertyGroupId: res.metadata.id,
                            name: defaultState.name,
                            label: defaultState.label,
                            lifecycleStateId: defaultState.id,
                            setBy: this.$store.state.auth.username
                        }
                    }})
                }
    
                this.selectedPartnerTypes = []
                this.selectedPartnerName = ""
                this.selectedPMS = null
                this.selectedCM = null
                this.addTestPropertyGroup = false
                this.apiSmartpricing = false
    
                this.insertingPartner = false
            }
        },
    }
}
</script>