<template>
    <div id="pg-info-display">
        <v-card class="card-ui" v-if="currentPS !== null">
            <v-toolbar dense elevation="0" :class="{ 'grey darken-3': darkMode }">
                <v-card-title class="overline ml-0 pl-0">
                    <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                    <b class="grey--text darken-6">Property Set Config</b>
                </v-card-title>
                <v-spacer/>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col class="col-12">
                        <v-card flat class="card-ui pl-3 pa-2 pt-0">
                            <v-alert border="right" :class="{ 'grey darken-3': darkMode }" elevation="2"
                                style="height: 150px;">
                                <h3>Propertyset Name: {{ currentPS.metadata.name }}</h3>
                                <h3>Kind: {{ currentPS.metadata.kind }}</h3>
                                <p> Created at: {{ new Date(currentPS.status.created_at).toISOString() }} </p>
                                <p> Updated at: {{ new Date(currentPS.status.updated_at).toISOString() }} </p>
                            </v-alert>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-row class="px-6 pb-4">
                <v-col class="col-4" v-for="key in Object.keys(currentPSConfig)" :key="key">
                    <h3> {{ key }}: </h3>
                    <p> {{ currentPSConfig[key] }} </p>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="card-ui" v-if="currentPS !== null">
            <v-toolbar dense elevation="0" :class="{ 'grey darken-3': darkMode }">
                <v-card-title class="overline ml-0 pl-0">
                    <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                    <b class="grey--text darken-6">PropertyGroups linked to this Property Set</b>
                </v-card-title>
            </v-toolbar>
            <v-row class="pa-4">
                <v-col class="col-6">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        :items="currentPSPropertyGroups"
                        hide-details
                        chips
                        v-model="currentPSPropertyGroups"
                        disabled="true"
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </v-card>


        <v-card class="card-ui" v-if="currentPS !== null">
            <v-toolbar dense elevation="0" :class="{ 'grey darken-3': darkMode }">
                <v-card-title class="overline ml-0 pl-0">
                    <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                    <b class="grey--text darken-6">Properties linked to this Property Set</b>
                </v-card-title>
            </v-toolbar>
            <v-row class="pa-4">
                <v-col class="col-6">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        :items="currentPSProperties"
                        hide-details
                        chips
                        v-model="currentPSProperties"
                        disabled="true"
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </v-card>


    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    components: { },
    data: function () {
        return {
        }
    },
    computed: {
        ...mapState({
            darkMode: state => state.ui.darkMode,
            
            currentPS: state => state.core.currentPS,
            currentPSProperties: state => state.core.currentPSProperties.map(prop => `${prop.metadata.id} - ${prop.metadata.name}`),
            currentPSConfig:  state => state.core.currentPS.spec.config[state.core.currentPS.metadata.kind]
        }),
        ...mapGetters({
            pgNameById: "core/pgNameById",
        }),
        currentPSPropertyGroups() {
            let pgsList = []
            this.$store.state.core.currentPSPropertyGroups.forEach( pgPsMap => {
                let pgId = pgPsMap.metadata.property_group_id
                pgsList.push(`${pgId} - ${this.pgNameById[pgId]}`)
            })
            return pgsList
        },
    },
    
    methods: {

    }

}
</script>
