<template >
  <v-card class="ml-3 mb-1 mr-1">
    <v-card-title>Set maps use</v-card-title>

    <v-data-table
      :headers="headers"
      :items="newUseMaps"
      hide-default-footer
      disable-sort
    >
      <template v-slot:item.value="{ item }">
        <v-tooltip
          left
          color="info"
          open-delay=300
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              :color="getColor(item.value, item.default)"
              dark
            >
              {{ item.value }}
            </v-chip>
          </template>
          <span>Default value: {{item.default}}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          color="red"
          :disabled="!editable"
          @click="setMapUse(false, item)"
        >
          fa fa-regular fa-circle-xmark fa-lg
        </v-icon>
        <v-icon
          small
          class="mr-2"
          color="green"
          :disabled="!editable"
          @click="setMapUse(true, item)"
        >
          fa fa-regular fa-circle-check fa-lg
        </v-icon>
        <v-icon
          small
          class="mr-2"
          color="info"
          :disabled="!editable"
          @click="setMapUse(null, item)"
        >
          fa fa-regular fa-circle-dot fa-lg
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>


<script>
import { mapGetters } from 'vuex'
const jsYaml = require('js-yaml')

export default {
  name: 'MapsSelector',  
  props: ['editable'],

  components: {

  },

  data: function (argument) {
    return {
      absolute: true,
      headers: [
        {
          text: "To use",
          align: "start",
          value: "label"
        },
        {
          text: "Current value",
          value: "value"
        },
        {
          text: "Actions",
          value: "actions"
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      newUseMaps: 'mapsYamlEditor/getUseMapsState'
    }),
  },
  methods: {
    setMapUse(value, map){
      var args = {'map': map, 'value': value};
      this.$store.dispatch('mapsYamlEditor/setMapUse', args); 
    },
    getColor(value, defaultValue){
      if(value === true) return 'green'
      else if (value === false) return 'red'
      else return 'info'
    },
  },

}
</script>
<style>
.ps {
      height: 900px;
    }

</style>
