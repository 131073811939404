<template>
    <v-container fluid class="pa-4">
        <OauthLinkGenerator v-if="currentPG"></OauthLinkGenerator>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
import OauthLinkGenerator from '../components/OauthLinkGenerator.vue';
import SelectPgAlert from '@/components/utils/SelectPgAlert'
import {mapActions, mapMutations, mapState} from "vuex"

export default {
    components: {OauthLinkGenerator, SelectPgAlert},
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG,
        }),
    },
    watch: {
        currentPG(newVal, oldVal) {
            this.resetLink()
        }
    },
    methods: {
        ...mapActions({
            getOauthPartners: "oauth/getOauthPartners",
        }),
        ...mapMutations({
            resetLink: "oauth/resetLink",
        })
    },
    async created() {
        await this.getOauthPartners()
    }
}
</script>