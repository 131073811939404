/**
 * Store for strategy
 */



export default {
    namespaced: true,

    state: {

        pricesCommunicationList: {},
        reservationsCommunicationList: {},
        diffedReservations: [],
        reservationsAckPartners: []
    },

    getters: {

        stateValidOperations(state, getters, rootState, rootGetters) {
            const statesHashMap = {}
            rootState.core.lifecycleStatesList.forEach(state => {
                statesHashMap[state.metadata.id] = {
                    computePrice: state.spec.fields.compute.price.intool || false,
                    predictPrice: state.spec.fields.predict.price.manual || false,
                    downloadReservation: state.spec.fields.download.reservation.manual || false,
                    sendPrice: state.spec.fields.send.price.intool || false,
                }
            })
            return statesHashMap[rootGetters["lifecycle/currentLifecycleStateByBundle"]?.['smartpricing']?.metadata?.lifecycleStateId]
        },

        canDownloadReservations(state, getters, rootState, rootGetters) {
            const statePermitted = getters.stateValidOperations?.downloadReservation
            const currentState = rootState.currentPG !== null ? rootState.core.currentPG?.spec.pms_name : ""
            const partnerPermitted = state.reservationsCommunicationList[currentState] === "push" ? false : true

            const errorMessages = []

            if (!partnerPermitted) errorMessages.push("This pg pms doesn't support the download of reservations")
            if (!statePermitted) errorMessages.push("You can't perform the current operation with the pg in this state")

            return errorMessages
        },

        canPredictPrice(state, getters, rootState, rootGetters) {
            return getters.stateValidOperations?.predictPrice ? [] : ["You can't perform the current operation with the pg in this state"]
        },

        canComputePrice(state, getters, rootState, rootGetters) {
            return getters.stateValidOperations?.computePrice ? [] : ["You can't perform the current operation with the pg in this state"]
        },

        canSendPrice(state, getters, rootState, rootGetters) {
            const statePermitted = getters.stateValidOperations?.sendPrice
            const partnerPermitted = state.pricesCommunicationList[rootState.core.currentPG?.spec.channel_manager_name] === "pull" ? false : true

            const errorMessages = []

            if (!partnerPermitted) errorMessages.push("This pg cm doesn't support the send price operation")
            if (!statePermitted) errorMessages.push("You can't perform the current operation with the pg in this state")
            return errorMessages
        },

        getReservationPullPartners(state) {
            return Object.keys(state.reservationsCommunicationList).filter(partner => state.reservationsCommunicationList[partner] === "pull")
        }
    },

    mutations: {

        updatePricesCommunicationList(state, payload) {
            state.pricesCommunicationList = payload.values
        },

        resetPricesCommunicationList(state) {
            state.pricesCommunicationList = {}
        },

        updateReservationsCommunicationList(state, payload) {
            state.reservationsCommunicationList = payload.values
        },

        resetReservationsCommunicationList(state) {
            state.reservationsCommunicationList = {}
        },

        updateDiffedReservations(state, payload) {
            state.diffedReservations = payload.values
        },

        resetDiffedReservations(state) {
            state.diffedReservations = []
        },
        updateReservationsAckPartners(state, payload) {
            state.reservationsAckPartners = payload.values
        },

        resetReservationsAckPartners(state) {
            state.reservationsAckPartners = []
        }
    },

    actions: {
        async submit(context, payload) {
            const api = context.rootState.api.api

            try {
                const pgId = payload.job.metadata.propertyGroup

                const res = await context.rootState.api.api.get("propertyset/v1", `/propertyset/propertygroup/${pgId}/kind/CHM`, {}, {}, {})
                const psId = res.items[0].metadata.id

                if (psId === undefined) {
                    throw new Error(`No CHM property set found`)
                }

                payload.job.spec.propertySetId = psId
                console.log(JSON.stringify(payload.job, null, 2))
                let jobRes
                if (payload.version === 'v1') {
                    jobRes = await api.put('gpcs/v1', '', {}, payload.job, {})
                } else if (payload.version === 'v2') {
                    jobRes = await api.put('gpcs/v2', '', {}, payload.job, {})
                } else if (payload.version === 'v3') {
                    jobRes = await api.put('gpcs/v3', '', {}, payload.job, {})
                }

                if (payload.cb !== undefined && typeof payload.cb == 'function') {
                    payload.cb(jobRes)
                }

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Job run failed: ${error}`
                }, { root: true })
            }
        },

        async launchResetSentPrice(context, args) {
            const api = context.rootState.api.api
            const jobRes = await api.put("core/v1", "/prices/reset", {}, args.job, {})

            return jobRes
        },
        async checkSendSupport(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("integrations/v2", "/specs/feature/pricesCommunicationMethod", {}, {}, {})

                context.commit("resetPricesCommunicationList")
                context.commit("updatePricesCommunicationList", { values: res })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to fetch send price support: ${error}`
                }, { root: true })
            }
        },

        async checkReadSupport(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("integrations/v2", "/specs/feature/reservationsCommunicationMethod", {}, {}, {})

                context.commit("resetReservationsCommunicationList")
                context.commit("updateReservationsCommunicationList", { values: res })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to fetch download reservations support: ${error}`
                }, { root: true })
            }
        },


        async deleteReservations(context, payload) {
            const api = context.rootState.api.api

            try {

                const body = {
                    //property_group_id: payload.pgId,
                    from : new Date(payload.startDate === "-" ? "2000-01-01": payload.startDate).toISOString().split("T")[0],
                    to : new Date(payload.endDate === "-" ? "2100-01-01" : payload.endDate).toISOString().split("T")[0],
                    properties: payload.properties
                }

                //const jobRes = await api.post("core/v1", `/deletereservations`, {}, body, {})
                const jobRes = await api.post("reservation/v2", `/general/delete-reservations-by-properties`,{},body,{})

                context.dispatch("alertSystem/newSuccess", {
                    message: `${jobRes.count} reservations successfully deleted`,
                    timeout: 5000
                }, { root: true })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to delete reservations: ${error}`,
                    timeout: -1
                }, { root: true })
            }
        },


        async getDiffedReservations(context, payload) {
            const api = context.rootState.api.api

            try {

                let jobRes = await api.get("integrations/v2", `/reservationdiff/${payload.pgId}?from=${payload.startDate}&to=${payload.endDate}`, {}, {}, {})


                context.commit("resetDiffedReservations")
                context.commit("updateDiffedReservations", { values: jobRes })

                context.dispatch("alertSystem/newSuccess", {
                    message: `${jobRes.length} diffed reservations successfully fetched`,
                    timeout: 5000
                }, { root: true })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to fetch diffed reservations reservations: ${error}`,
                    timeout: -1
                }, { root: true })
            }
        },

        async restoreReservations(context, payload) {
            const api = context.rootState.api.api

            try {
                const ids = payload.ids.map( (el) => el.id )                
                ids = ids.join(',')
                const jobRes = await api.put("integrations/v2", `/reservationdiff/${payload.pgId}?ids=${ids}`, {}, {}, {})

                context.dispatch("alertSystem/newSuccess", {
                    message: `Reservations successfully restored`,
                    timeout: 5000
                }, { root: true })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to restore reservations: ${error}`,
                    timeout: -1
                }, { root: true })
            }
        },

        async runMasterPredictResources(context, payload) {
            const api = context.rootState.api.api

            try {

                const strategiesList = [`preprocess.standard.${payload.psId}`, `predict.standard.${payload.psId}`, `expert.predict.standard.${payload.psId}`]

                const responses = []
                for (let strategy of strategiesList) {

                    const job = await api.get("predictjob/v2", `/master/pipelinebind/${strategy}`, {}, {}, {})

                    const resJob = await api.put("predict/v1", `/job?priority=0.8`, {}, [{
                        force: true,
                        job: job.items[0].resource
                    }], {})

                    if (!!resJob.results[0].error) {

                        // sometimes the errors are in different places (don't fucking know why)
                        let errorString = ""
                        if (!!resJob.results[0]?.error?.error) {
                            errorString = resJob.results[0]?.error?.error
                        } else {
                            const runError = resJob.results[0].error

                            errorString = runError.parameter_errors.map(val => `${val.message} (${val.path})`).join(", ")
                        }

                        responses.push({
                            type: "error",
                            message: `Pipeline ${strategy} didn't run successfully for errors: ${errorString}`
                        })
                    } else {
                        responses.push({
                            type: "success",
                            message: `Pipeline ${strategy} run successfully!`
                        })
                    }
                }

                return responses

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to run predict jobs: ${error}`,
                    timeout: -1
                }, { root: true })
            }


        },
        async fetchReservationAckPartners(context) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("integrations/v2", "/specs/feature/reservationsAck", {}, {}, {})

                context.commit("resetReservationsAckPartners")
                context.commit("updateReservationsAckPartners", { values: Object.keys(res) })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to fetch reservation ack partners: ${error}`
                }, { root: true })
            }
        },
    }
}
