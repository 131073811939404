<template>
    <v-container fluid class="pa-4">
        <v-card class="card-ui pa-4">
            <v-card-title>Move All Reservations</v-card-title>
            <v-card-subtitle>Allows you to move all the reservations from one property to another, you can also provide a date range for the check-in date</v-card-subtitle>

            <v-form
                ref="form"
                v-model="validForm"
            >
                <v-row>
                    <v-col class="col-6">
                        <v-autocomplete
                            outlined
                            label="From Property Group"
                            :items="pgsDropdown"
                            @change="populateDropdown1($event)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-6">
                        <v-autocomplete
                            outlined
                            label="From Property"
                            :items="propertiesDropdown1"
                            :rules="[rules.requiredArr]"
                            multiple
                            v-model="fromPropIds"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-12">
                        <v-row>
                            <v-col class="shrink">
                                <v-switch
                                    :true-value="true"
                                    :false-value="false"
                                    v-model="enableDateRange"
                                    @change="[date1 = '', date2 = '']"
                                ></v-switch>
                            </v-col>
                            <v-col class="grow">
                                <v-menu
                                    v-model="dateMenu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        label="From Date (Check-In Date)"
                                        outlined
                                        readonly
                                        clearable
                                        v-model="date1"
                                        :error-messages="datesRequired"
                                        :disabled="!enableDateRange"
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date1"
                                        @change="dateMenu1 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="grow">
                                <v-menu
                                    v-model="dateMenu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        label="To Date (Check-In Date)"
                                        outlined
                                        readonly
                                        clearable
                                        v-model="date2"
                                        :error-messages="datesRequired"
                                        :disabled="!enableDateRange"
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date2"
                                        @change="dateMenu2 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="col-6">
                        <v-autocomplete
                            outlined
                            label="To Property Group"
                            :items="pgsDropdown"
                            @change="populateDropdown2($event)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-6">
                        <v-autocomplete
                            outlined
                            label="To Property"
                            :rules="[rules.required]"
                            :items="propertiesDropdown2"
                            v-model="toPropId"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>

            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="validateForm()"
                >Move Reservations</v-btn>
            </v-card-actions>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="confirmDialog"
            >
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                            color="primary"
                            dark
                        >Confirm Moving Reservations</v-toolbar>
                        <v-card-text>
                            
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                color="primary"
                                @click="moveReservations"
                            >Confirm</v-btn>
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-card>
    </v-container>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex"
// some things need to change when we have the new core store

export default {
    data() {
        return {
            confirmDialog: false,
            validForm: true,
            
            enableDateRange: false,

            dateMenu1: false,
            dateMenu2: false,

            date1: "",
            date2: "",

            propertiesDropdown1: [],
            propertiesDropdown2: [],

            fromPropIds: [],
            toPropId: "",

            rules: {
                required: value => !!value || "Value Required!",
                requiredArr: value => !!value.length || "Value Required!",
            }
        }
    },
    computed: {
        ...mapState({
            api: state => state.api.api,
        }),
        ...mapGetters({
            pgsDropdown: "core/pgsDropdown",
        }),
        finalReqObj() {
            const obj = {
                fromPropIds: this.fromPropIds,
                toPropId: this.toPropId,
            }

            if (this.enableDateRange) {
                obj.fromDate = !!this.date1 ? this.date1 : null
                obj.toDate = !!this.date2 ? this.date2 : null
            }
            return obj
        },
        datesRequired() {
            if (!this.enableDateRange) return ""

            if (!!this.date1 || !!this.date2) {
                return ""
            } else {
                return "At least one date is required!"
            }
        }
    },
    methods: {
        ...mapActions({
            newSuccess: "alertSystem/newSuccess",
            newError: "alertSystem/newError",
            fetchProperties: "core/fetchProperties",
            fetchChilds: "core/fetchChilds",
        }),
        async getProperties(pgId) {
            try {
                const propertiesList = await this.fetchProperties({pgId: pgId})

                const children = await this.fetchChilds({pgId: pgId})

				if (!!children) {
					await Promise.all(children?.map(async (pgId) => {
						const res = await this.fetchProperties({pgId: pgId})
	
						if (!!res) {
							res.forEach(prop => {
								propertiesList.push(prop)
							})
						}
					}))
				}
				
                return propertiesList
            } catch (error) {
                return []
            }
        },
        async populateDropdown1(pgId) {
            this.propertiesDropdown1 = []
            this.propertiesDropdown1 = (await this.getProperties(pgId)).map(prop => {
                return {
                    text: `${prop.spec.property_group_id} - ${prop.metadata.id} - ${prop.metadata.name}`,
                    value: prop.metadata.id
                }
            })
        },
        async populateDropdown2(pgId) {
            this.propertiesDropdown2 = []
            this.propertiesDropdown2 = (await this.getProperties(pgId)).map(prop => {
                return {
                    text: `${prop.spec.property_group_id} - ${prop.metadata.id} - ${prop.metadata.name}`,
                    value: prop.metadata.id
                }
            })
        },
        validateForm() {
            this.$refs.form.validate()

            if (this.validForm) {
                this.confirmDialog = true
            }
        },
        async moveReservations() {
            try {
                const res = await this.api.put("core/v1", `/reservations/moveallreservations`, {}, this.finalReqObj, {})

                if (!res.success) throw res.error 

                this.newSuccess({
                    message: "Reservations moved successfully",
                })
            } catch (error) {
                this.newError({
                    message: `Error moving the reservations: ${error}`
                })
            } finally {
                this.confirmDialog = false
            }
        }
    }
}
</script>