export default class FormSchemaManager {
// class FormSchemaManager {
  schema
  
  constructor(schema){
    this.schema = schema
  }

  getModelStructureBySchema(currentObj = this.schema){
    let outputObj = {}
    let result = null

    if(!currentObj){
      return
    }
    Object.entries(currentObj).forEach(([key, value]) => {
      if(value && typeof value === 'object'){
        result = this.getModelStructureBySchema(value)
      }
      else if (key === 'key'){
        result = this.getObjFromPath(value)
      }
      outputObj = {...outputObj, ...result}
      result = null
    });

    return outputObj
  }

  getObjFromPath(path, separator = '.'){
    const pathArray = path.split(separator)
    let outputObj = undefined

    while(pathArray.length > 0){
      outputObj = {
        [pathArray.pop()]: outputObj
      }
    }

    return outputObj
  }

}
