<template>
  <v-theme-provider root>
    <v-app id="inspire" style="position:relative" :class="{dark: darkMode}">
  
      <v-system-bar app class="system-bar" v-if="isAuth == true">
        <!--<b>{{$store.state.auth.username}}</b> #{{$route.path}}-->
        <v-btn small icon @click="drawer = !drawer" class="pl-2">
          <v-icon small>fa-solid fa-bars </v-icon>
        </v-btn>      
        <v-img
          alt="Smartpricing Logo"
          class="shrink ml-2"
          contain
          src="/favicon-96x96.png"
          transition="scale-transition"
          width="100"
        />
        <v-spacer></v-spacer>
        Powered by 🚀🚀🚀
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="[changeDisplayMode(), $vuetify.theme.dark = !$vuetify.theme.dark]"
        >
          <v-icon small v-if="darkMode">fa-duotone fa-moon</v-icon>
          <v-icon small v-else>fa-solid fa-sun</v-icon>
        </v-btn>
        <v-btn small icon @click="logout()" class="pl-2">
          <v-icon small>fa-solid fa-right-from-bracket </v-icon>
        </v-btn>
      </v-system-bar>

      <v-app-bar app clipped-right flat height="52"  v-if="showPropertyGroupSelectionAppBar" class="app-bar">
        <PropertyGroupGlobalSelector style="width: 100%;"/>
      </v-app-bar>

      <v-app-bar app clipped-right flat height="52"  v-if="showPropertySetSelectionAppBar" class="app-bar">
        <PropertySetGlobalSelector style="width: 100%;"/>
      </v-app-bar>
  
      <v-navigation-drawer v-model="drawer" app :width="sidebarWidth"  v-if="isAuth == true" class="sidebar">
        <v-navigation-drawer v-model="drawer" absolute class="mini-sidebar"  mini-variant hide-overlay>
          <div class="clickable text-center mt-2" v-for="n in Object.keys($store.state.app.apps)" :key="n" v-on:click="changeApp($store.state.app.apps[n])" v-if="isUserAllowedToApp(n)">
            <div v-if="$store.state.app.apps[n].visible !== false">
              <!--<div style="background: #262a30; margin: 5px; padding: 5px; border-radius: 5px;">-->
              <v-icon :class="$store.state.ui.activeApp == n ? 'cyan--text' : 'gray--text'">{{$store.state.app.apps[n].icon}}</v-icon>
              <p style="font-size: 10px">{{$store.state.app.apps[n].prettyName}}</p>
              <!--</div>-->
            </div>
          </div>
  
          <!--<div class="clickable text-center mt-2" v-on:click="createResource" style="position: absolute; bottom: 0px; left: 13px">
            <div>
              <v-icon class="pink--text">fa-solid fa-plus</v-icon>
              <p style="font-size: 10px">Create</p>
            </div>
          </div>-->
        </v-navigation-drawer>
  
        <v-list class="pl-14" shaped>
          <v-list-item 
            v-for="n in $store.state.app.apps[$store.state.ui.activeApp].subresources" 
            :key="n.name" 
            link 
            @click="setAppSubresource(n)" 
            v-if="$store.state.app.apps[$store.state.ui.activeApp].visible !== false && isUserAllowedToPage($store.state.app.apps[$store.state.ui.activeApp].name, n.name)"
          >
            <v-list-item-content>
              <v-list-item-title :class="$store.state.ui.activeAppSubresource == n.name ? 'cyan--text' : 'gray--text'"><b>{{ n.prettyName }}</b></v-list-item-title>
              <v-list-item-subtitle>{{ n.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
  
      <v-main class="app-background">
        <v-progress-linear
          indeterminate
          color="cyan"
          v-if="runningAPIRequest"
        ></v-progress-linear>      
        <router-view/>
      </v-main>
  
      <v-dialog v-model="createEditorDialog" fullscreen transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dense class="elevation-0">
            Template <v-select v-model="templateCreatorToUse" class="ml-2 mt-6 elevation-0" style="max-width: 200px;" dense solo-inverted :items="$store.state.editor.templateNames"></v-select>
            <v-spacer />
            <v-btn fab x-small class="elevation-0 red--text" @click="submitResource"><v-icon>fa-solid fa-save</v-icon></v-btn>
            <v-btn fab x-small class="elevation-0 cyan--text" @click="createEditorDialog = false"><v-icon>fa-solid fa-close</v-icon></v-btn>
          </v-toolbar>
          <Editor :key="JSON.stringify(codeEditorYaml)" v-if="createEditorDialog == true && codeEditorYaml !== null" :code="codeEditorYaml" :readonly="false" lang="yaml"></Editor>
        </v-card>
      </v-dialog>   
      <Notification></Notification>
      <GPTChat v-if="isLogged" style="position:fixed; right: 25px; bottom: 25px;"></GPTChat>
    </v-app>
  </v-theme-provider>
</template>
<script>

const yaml = require('js-yaml')

import { mapGetters, mapMutations, mapState } from 'vuex'
import Vue from 'vue'
import PropertyGroupGlobalSelector from '@/components/app-bars/PropertyGroupGlobalSelector'
import PropertySetGlobalSelector from '@/components/app-bars/PropertySetGlobalSelector'

import Editor from '@/components/editor/Editor'
import ConstEnv from '@/store/const'
import Notification from './components/utils/Notification.vue'
import GPTChat from './apps/core/components/GPTChat.vue'

export default {
  name: 'App',
  components: {
    PropertyGroupGlobalSelector,
    PropertySetGlobalSelector,
    Editor,
    Notification,
    GPTChat,
  },

  data: () => ({
    drawer: true,
    createEditorDialog: false,
    codeEditorYaml: null,
    templateCreatorToUse: null,
    runningAPIRequest: false,
  }),

  watch: {
    '$store.state.auth.isAuth' (newVal, oldVal) {
      this.loadPage() 
    },    
    templateCreatorToUse (newVal, oldVal) {
      this.codeEditorYaml = this.$store.state.editor.templates[newVal].template
    },
    '$store.state.api.runningAPIRequest' (newVal, oldVal) {
      this.runningAPIRequest = newVal
    },
  },

  computed: {
    ...mapState({
      darkMode: state => state.ui.darkMode,
      isLogged: state => state.auth.isAuth,
    }),
    ...mapGetters({
      hidePropertyGroupSelectionAppBar: 'ui/hidePropertyGroupSelectionAppBar',
      hidePropertySetSelectionAppBar: 'ui/hidePropertySetSelectionAppBar',
      userRoles: "auth/userRoles",
    }),
    showPropertyGroupSelectionAppBar(){
      if(this.hidePropertyGroupSelectionAppBar === true){
        return false
      }
      if (this.userRoles.includes("guest.all")) { // to change later since if someone has the guest.all and also admin.all role he won't see the appbar
        return false
      }
      return this.isAuth == true && this.$store.state.app.apps[this.$store.state.ui.activeApp].hidePropertyGroupSelectionAppBar !== false
    },
    showPropertySetSelectionAppBar(){
      if(this.hidePropertySetSelectionAppBar === true){
        return false
      }
      if (this.userRoles.includes("guest.all")) { // to change later since if someone has the guest.all and also admin.all role he won't see the appbar
        return false
      }
      return this.isAuth == true && this.$store.state.app.apps[this.$store.state.ui.activeApp].hidePropertySetSelectionAppBar !== false
    },

    constEnv () {
      return ConstEnv
    },
    isAuth () {
      return this.$store.state.auth.isAuth
    },
    /**
     *  "Hide" the big sidebar when
     *  the seltect app require only mini sidebar 
     */
    sidebarWidth () {
      const activeAppName = this.$store.state.ui.activeApp
      const activeApp = this.$store.state.app.apps[activeAppName]
      if (activeApp.ui !== undefined && activeApp.ui.hideSidebar === true) {
        return 55
      } else {
        return 300
      }
    }
  },

  methods: {
    ...mapMutations({
      changeDisplayMode: "ui/changeDisplayMode",
    }),
    isUserAllowedToApp (appName) {
      let allRolesByApp = []
      Object.values(this.constEnv.ROLES_APP[appName]).forEach(pageRoles => {
        pageRoles.forEach(role => allRolesByApp.push(role))
      })
      const listOfEnabledRolesForApp = Array.from(new Set(allRolesByApp))
      if (listOfEnabledRolesForApp == undefined) {
        console.log(new Date(), '#> Do not display app', appName, 'due not configured roles')
        return false
      }
      if (listOfEnabledRolesForApp.length > 0) {
        let isEnabled = false
        for (var i = 0; i < listOfEnabledRolesForApp.length; i += 1) {
          const role = listOfEnabledRolesForApp[i]

          if ( role == 'guest.all' ) {
            //isEnabled = true
            return true
          }

          const roleMap = this.constEnv.ROLES_MAP[role]
          if (roleMap?.includes(this.$store.state.auth.username)) {
            isEnabled = true
          }
        }
        return isEnabled
      } else {
        return false
      }
    },
    isUserAllowedToPage(appName, subresourceName) {
      const allRolesForPage = this.constEnv.ROLES_APP[appName][subresourceName]
      const usersAllowedSet = new Set()

      if (allRolesForPage.includes("guest.all")) {
        return true
      }
      allRolesForPage.forEach(pageRole => {
        this.constEnv.ROLES_MAP[pageRole].forEach(user => {
          usersAllowedSet.add(user)
        })
      })
      
      if (!allRolesForPage) {
        console.log(new Date(), '#> Do not display app', appName, subresourceName,'due not configured roles')
        return false
      }

      const usersAllowed = Array.from(usersAllowedSet)
      if (usersAllowed.includes(this.$store.state.auth.username)) {
        return true
      } else {
        return false
      }
    },
    createResource () {
      this.createEditorDialog = true
    },
    async submitResource () {
      const sbFn = this.$store.state.editor.templates[this.templateCreatorToUse].submitFunction
      const api = this.$store.state.api.api
      let objectToSave = null
      if (this.$store.state.editor.codeToRenderLang == 'json') {
        objectToSave = JSON.parse(this.$store.state.editor.codeToRender)
      } else {
        objectToSave = yaml.load(this.$store.state.editor.codeToRender)
      }
      const res = await sbFn(api, objectToSave)
    },
    logout: function () {
      let cookie = Vue.prototype.$cookie
      cookie.set('sp-internal-dashboard-auth', false)
      cookie.set('sp-internal-dashboard-token', null)          
      cookie.set('sp-internal-dashboard-username', null)
      cookie.set('sp-internal-dashboard-auth-date', null)
      this.$store.commit('auth/setAuthProperties', {
        api: null,
        isAuth: false,
        username: null,
        name: null,
        accessToken: null        
      })
    },
    loadPage: function () {
      if (this.isAuth) {
        this.$store.dispatch('core/fetchLifecycleStatesList'), // new
        this.$store.dispatch('core/fetchPropertyGroupsList'), // new
        this.$store.dispatch('core/fetchPropertySetsList'), // new
        Promise.all([
          this.$store.dispatch('core/fetchPartnerList'),
          this.$store.dispatch("core/fetchCurrenciesList"), // new
          this.$store.dispatch('core/fetchPmsList'), // new
          this.$store.dispatch('core/fetchCmList'), // new
          this.$store.dispatch('lifecycle/getLifecycleStatesList'),
          this.$store.dispatch('core/fetchPropertyGroupsLifecycleStatesList'),
        ])
        this.changeApp(this.$store.state.app.apps[this.$store.state.app.default])  
      } else {
        this.$router.push({ path: '/login', replace: true }).catch(()=>{});
      }      
    },
    changeApp: function (app) {
      this.$store.commit('ui/setApp', app.name)
      this.$store.commit('ui/setAppSubresource', app.subresources[0].name)
      this.$router.push({ path: app.subresources[0].link, replace: true }).catch(()=>{});
    },
    setAppSubresource: function (subresource) {
      this.$store.commit('ui/setAppSubresource', subresource.name)
      this.$router.push({ path: subresource.link, replace: true }).catch(()=>{});
    }
  },

  beforeMount () {
    // Reset view to default when page reload
    this.loadPage()
  }
};
</script>

<style lang="scss">
$light-red: #f24250;
$dark-blue: #273672;
$dark2-blue: #161a2d;
$light-blue: #262a3d;
$sm-cyan: #00b2be;
$sm-blue: #273672;

.dark .system-bar {
  background: #2e3137 !important;
}

.dark .app-bar {
  background: #252729 !important; //#1c1c1c !important;
}
.dark .sidebar {
  background: #36383e !important; //#212121 !important;
}

.dark .mini-sidebar {
  background: #252729 !important;
}

.dark .app-background {
  background: #1f2124 !important; // #27262D !important; //#121212 !important;
}

.dark .sidebar-item {
  color: grey;
}

.dark .selected-sidebar-item {
  color: $sm-cyan;
}

.dark .card-ui {
  background: #262b2f !important;
}

.dark .selected-sidebar-icon {
  color: white;
}

.dark .black-background {
  background: #000000 !important;
}

.dark .table-background {
  background: white !important;
}

.dark .table-background-warning {
  background: $light-red !important;
}

.dark .warning-text {
  color: $light-red;
}

.clickable {
  cursor: pointer;
}
</style>
