<template>
    <v-container fluid class="pa-4">
        <v-card class="card-ui pa-4">
            <v-card-title>Integrations Status Overview</v-card-title>

            <v-data-table
                :headers="headers"
                :items="dataFormatted"
            ></v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    data(){
        return {
            headers: [
                {text: "Name", value: "name"},
                {text: "Blocked", value: "isBlocked"},
                {text: "Progress", value: "progress"},
                {text: "Status", value: "status"},
                {text: "Due Date", value: "dueDate"},
                {text: "Owner", value: "owner"},
            ],

            data: [],
        }
    },
    computed: {
        ...mapState({
            api: state => state.api.api,
        }),
        dataFormatted() {
            return this.data.map(val => {
                return {
                    name: val.name,
                    isBlocked: val.flagged ? "NO" : "YES",
                    progress: ((val.phases.filter(st => st.status == "Completata").length / val.phases.length)*100).toFixed(0)+" %",
                    status: val.status,
                    dueDate: val.dueDate,
                    owner: !!val.owner ? val.owner : null 
                }
            })
        }
    },
    methods: {
        ...mapActions({
            newError: "alertSystem/newError",
        })
    },
    async created() {
        try {
            const res = await this.api.get("integrations/v2", `/jira/integrations`, {}, {}, {})

            this.data = res
        } catch (error) {
            this.newError({
                message: `Error loading integrations status managers data: ${error}`,
            })
        }
    }
}
</script>