<template>
    <perfect-scrollbar>
        <v-card>   
            <prism-editor v-if="codeToRender !== null" :class="{'dark-editor': darkMode, 'white-editor': !darkMode}" v-model="codeToRender" :highlight="highlighter" line-numbers :readonly="readonly">      
            </prism-editor style="max-height: 80vh;">
        </v-card>
    </perfect-scrollbar>
</template>

<script>
const yaml = require('js-yaml')
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-json';
//import 'prismjs/themes/prism-tomorrow.css'; 
import 'prismjs/themes/prism-funky.css'; 
import { mapState } from 'vuex';

export default {
  props: ['code', 'lang', 'readonly'],
  components: {
    PrismEditor,
  },
  data: function () {
    return {
    }
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages[this.lang || 'yaml']); // languages.<insert language> to return html with markup
    },
  },
  computed: {
    ...mapState({
      darkMode: state => state.ui.darkMode,
    }),
    codeToRender(){
      if (this.lang == 'yaml') {
        return yaml.dump(this.code)
      } else {
        return JSON.stringify(this.code, undefined, 4)
      }
    }
  },
}
</script>

<style>
  /* required class */
  .dark-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #161b1f;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  .white-editor {
    background: #fff;
    color: #333;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
  .ps {
      height: 900px;
    }
</style>
