/**
 * Store for analytics
 */

export default {

	namespaced: true,

	state: {
		api: null,
	},
	computed: {},
	mutations: {},
	actions: {
		async refreshBigqueryData(context, args) {

			const api = context.rootState.api.api;
            const pgIds = args.propertyGroupIds
            
			const res = await api.post(
				"bigquery/v1",
				`/job`,
				{},
				{property_group_ids: pgIds},
				{}
			)

            return res
		},
	},
};
