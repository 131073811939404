/**
 * Store for graph operations
 */ 

export default {
  namespaced: true,

  state: {
    poi: [],
    category: [],
    stars: [],
 
    // Geocoding
    country: [],
    state: [],
    county: [],
    city: [],

    propertyGroupList: null,
    analyticalMarketsList: null,

    statesFor: {},

    marketsByLocation: [],

    // EVENTS
    events: [],
    locations: []
  },
  getters: {
    events(state){
      return state.events
    },
    markets(state) {
      return state.locations.filter(loc => {
        return String(loc.kind).includes("Market")
      })
    },

    normalLocations(state) {
      return state.locations.filter(loc => {
        return !String(loc.kind).includes("Market")
      })
    },

    tagOptionsMap(state, getters) {
      const hashMap = {
        poi: [],
        category: [],
        stars: [],
        locations: [],
      }
      state.poi.forEach(val => {
        hashMap["poi"].push({
          text: val.metadata.id,
          value: val.metadata.id,
        })
      })
      state.category.forEach(val => {
        hashMap["category"].push({
          text: val.metadata.id,
          value: val.metadata.id,
        })
      })
      state.stars.forEach(val => {
        hashMap["stars"].push({
          text: val.metadata.id,
          value: val.metadata.id,
        })
      })
      getters.normalLocations.forEach(val => {
        hashMap["locations"].push({
          text: val.metadata.id?.replaceAll("_", " - ")?.replaceAll(".", " "),
          value: val.metadata.id,
        })
      })
      return hashMap
    },

    events(state){
      return state.events
    },

    eventsFirstDateById(state, getters){
      let returnObj = {}
      getters.events.forEach(e => {
        returnObj[e.metadata.id] = e.link.EventDate[0]?.id
      })
      return returnObj
    },
    eventsSorted(state, getters){
      return getters.events.sort((a, b) => a.metadata.id > b.metadata.id)
    },
    customRootEvents(state,getters){
      return structuredClone(getters.events.filter(e => e.spec.isCreatedByUser && e.link.RootEvent == undefined))
    },
    customRootEventsMapped(state, getters) {
      return getters.customRootEvents.map(e => {
        return {
          id: e.metadata.id,
          label: e.spec.label,
          dates: e.link.EventDate,
          pgId: e.link.EventTarget[0].id,
          pgName: '',
          notOfGeneralInterest: !!e.spec?.notOfGeneralInterest
        }
      })
    },
    eventsIdList(state, getters){
      return getters.eventsSorted.map(x => {
        let obj = {
          text: x.spec.label,
          value: x.metadata.id,
        }

        let targetPG = x.link?.EventTarget?.find(e => e.kind == 'Propertygroup')

        if(targetPG){
          obj.text = `${x.spec.label} (${targetPG.id})`
        }
 
        return obj
      })
    },
    eventsFiltered(state, getters){
      return getters.eventsSorted.filter(event => !event.link.RootEvent && !event.spec.isCreatedByUser)
    },
    eventsFilteredIdList(state, getters){
      return getters.eventsFiltered.map(x => {
        return {
          text: `${x.spec.label} (${x.metadata.id})`,
          value: x.metadata.id,
        }
      })
    },
    locations(state){
      return state.locations
    },
    locationsString(state, getters){
      return getters.locations
        .map(l => `${l.kind}::${l.metadata.id}`)
    },
  },
  mutations: {

    resetMarketsByLocation(state, payload) {
      state.marketsByLocation = []
      if ( payload?.values?.length > 0 ) {
        let set = new Set( payload?.values?.map( (el) => el.metadata.id ) )
        state.marketsByLocation = [...set]
      }
    },

    resetPropertygroupList(state, payload) {
      state.propertyGroupList = null
      if (!!payload?.values) {
        state.propertyGroupList = []
        payload.values.forEach(val => {
          state.propertyGroupList.push(val)
        })
      }
    },

    upsertMarket(state, payload) {
      const index = state.locations.findIndex(loc => loc.metadata.id === payload.newMarket.metadata.id && loc.kind === "Location:Market")

      if (index !== -1) { // update
        state.locations.splice(index, 1, payload.newMarket)
      } else { // insert
        state.locations.push(payload.newMarket)
      }
    },

    resetAnalyticalMarketsList(state, payload) {
      state.analyticalMarketsList = null
      if (!!payload?.values) {
        state.analyticalMarketsList = []
        payload.values.forEach(val => {
          state.analyticalMarketsList.push(val)
        })
      }
    },

    upsertAnalyticalMarket(state, payload) {
      const index = state.analyticalMarketsList.findIndex(loc => loc.metadata.id === payload.newMarket.metadata.id)

      if (index !== -1) { // update
        state.analyticalMarketsList.splice(index, 1, payload.newMarket)
      } else { // insert
        state.analyticalMarketsList.push(payload.newMarket)
      }
    },

    addLocation(state, payload) {
      const found = state.locations.findIndex(val => val.metadata.id === payload.newLoc.metadata.id ) !== -1

      if (!found) {
        state.locations.push(payload.newLoc)
      }
    }
  },
  actions: {

    async fetchMarketByLocation(context, payload) {
      const api = context.rootState.api.api
      try {
        const res = await api.get('graph/vpg', `/markets/location/${payload.location}`, {}, {}, {})  
        context.commit("resetMarketsByLocation", {values: res.items})
      } catch (error) {
        console.log(error)
        context.dispatch("alertSystem/newError", {
          message: "Failed to fetch markets by location from graph"
        }, {root: true})
      }
    },

    async fetchPropertygroups(context) {
      const api = context.rootState.api.api
      
      try {
        const res = await api.get('graph/vpg', '/propertygroup', {}, {}, {})  

        context.commit("resetPropertygroupList", {values: res.items})
      } catch (error) {
        context.dispatch("alertSystem/newError", {
          message: "Failed to fetch property groups from graph"
        }, {root: true})
      }
    },


    async upsertMarket(context, payload) {
      const api = context.rootState.api.api

      try {
        const res = await api.put('graph/vpg', '', {}, payload.newMarket, {})  
        if (!!res?.error) {
          throw res.error
        }

        context.commit("upsertMarket", {newMarket: payload.newMarket})        

      } catch (error) {
        context.dispatch("alertSystem/newError", {
          message: "Failed to save the market"
        }, {root: true})
      }
    },

    async saveResource (context, args) {
      const api = context.rootState.api.api
      const res = await api.put('graph/vpg', '', {}, args.body, {})  
      return res
    },
    async fetchEvents(context, args){
      const api = context.rootState.api.api
      const res = await api.get('graph/vpg', '/event', {}, {}, {})  
      context.state.events = res.items
    },
    async fetchLocations(context, args){
      const api = context.rootState.api.api
      const res = await api.get('graph/vpg', '/location', {}, {}, {})
      context.state.locations = res.items
    },

    async fetchAnalyticsMarkets(context, payload) {
      const api = context.rootState.api.api

      try {
        // TODO: cambiare quello che ci si fa
        const res = await api.get("graph/vpg", `/analyticalmarket`, {}, {}, {})

        if (!!res?.error) throw error

        context.commit("resetAnalyticalMarketsList", {values: res.items})
        return res.items
      } catch (error) {
        console.log(error)
        return null
      }
    },

    async upsertAnalyticsMarket(context, payload) {
      const api = context.rootState.api.api

      try {
        const res = await api.put("graph/vpg", ``, {}, payload.market, {})

        if (!!res?.error) throw error

        context.commit("upsertAnalyticalMarket", {
          newMarket: payload.market
        })
        return payload.market
      } catch (error) {
        console.log(error)
        return null
      }
    },

    async getPgProperties(context, payload) {
      const api = context.rootState.api.api

      try {
          const res = await api.get("graph/vpg", `/properties/propertygroup/${payload.pgId}`, {}, {}, {})

          return res.items
      } catch (error) {
          return null
      }
    },

    async addEvent(context, args){
      const preventFetch = !!args.preventFetch 

      if(preventFetch){
        delete args.preventFetch
      }

      let feedbackArgs = {
        success: {
          level: "success",
          kind: "Add Event",
          message: "Event correctly added"
        },
        error: {
          level: "error",
          kind: "Add Event",
          message: "Add event failed"
        },
      }
      let feedbackObj = feedbackArgs.success

      if(context.getters.events.find(e => e.metadata.id === args.metadata.id)){
        feedbackObj = feedbackArgs.error
        feedbackObj.message = `Event [${args.metadata.id}] already exists`
      } else {
        const res = await context.dispatch("saveResource", {body: args})
        if(res.error){
          feedbackObj = feedbackArgs.error
          feedbackObj.message += `: ${res.error}`
        } else {
          feedbackObj.message += `: ${args.metadata.id}`
        }
      }

      if(!preventFetch) await context.dispatch("fetchEvents")
      context.dispatch('showFeedback', feedbackObj)

      let result = {
        success: true
      }
      if(feedbackObj.level === 'error'){
        result.success = false
      }
      return result
    },
    async editEvent(context, args){
      const preventFetch = !!args.preventFetch 

      if(preventFetch){
        delete args.preventFetch
      }

      let feedbackArgs = {
        success: {
          level: "success",
          kind: "Edit Event",
          message: "Event correctly edited"
        },
        error: {
          level: "error",
          kind: "Edit Event",
          message: "Edit event failed"
        },
      }
      let feedbackObj = feedbackArgs.success

      const res = await context.dispatch("saveResource", {body: args})
      if(res.error){
        feedbackObj = feedbackArgs.error
        feedbackObj.message += `: ${res.error}`
      } else {
        feedbackObj.message += `: ${args.metadata.id}`
      }

      if(!preventFetch) await context.dispatch("fetchEvents")
      context.dispatch('showFeedback', feedbackObj)

      let result = {
        success: true
      }
      if(feedbackObj.level === 'error'){
        result.success = false
      }
      return result
    },
    async deleteEvent(context, args) {
      let feedbackArgs = {
        success: {
          level: "success",
          kind: "Delete Event",
          message: "Event correctly deleted"
        },
        error: {
          level: "error",
          kind: "Delete Event",
          message: "Delete event failed"
        },
      }
      const api = context.rootState.api.api
      const res = await api.delete('graph/vpg', `/Event/${args.idToDelete}`, {}, {}, {})  
      let feedbackObj = feedbackArgs.success
      if(res.error){
        feedbackObj = feedbackArgs.error
        feedbackObj.message += `: ${res.error}`
      } else if(res.response.summary.counters._stats.nodesDeleted === 0){
        feedbackObj = feedbackArgs.error
        feedbackObj.message += `: ${args.idToDelete} not found`
      }
      context.dispatch('showFeedback', feedbackObj)

      let result = {
        success: true
      }
      if(feedbackObj.level === 'error'){
        result.success = false
      } else {
        context.dispatch('fetchEvents', {})
      }
      return result
    },
    async prefetch (context, args) {
      // POI
      const api = context.rootState.api.api
      if (context.state.poi.length == 0) {
        const res = await api.get('graph/vpg', '/poi', {}, {}, {})  
        context.state.poi = res.items
      }
      if (context.state.category.length == 0) {
        const res = await api.get('graph/vpg', '/category', {}, {}, {})  
        context.state.category = res.items
      }      
      if (context.state.stars.length == 0) {
        const res = await api.get('graph/vpg', '/stars', {}, {}, {})  
        context.state.stars = res.items
      } 

      if (context.state.events.length == 0) {
        context.dispatch('fetchEvents', {})
      } 

      if (context.state.locations.length == 0) {
        context.dispatch('fetchLocations', {})
      } 

      if (context.state.country.length == 0) {
        const res = await api.get('geocoding/v1', '', {}, {}, {})
        if (res.status == 'found') {
          context.state.country = res.items
        }
        console.log("res ", res)
        await Promise.all(context.state.country.map(
          async (c) => {
            let states = (await context.dispatch('getGeocodingState', {country: c.metadata.id})).map((s) => { return s.spec.state })
            if ( states.length > 0 ) {
              context.state.statesFor[c.metadata.id] = states
            } else {
              context.state.statesFor[c.metadata.id] = ["*"]
            }
          })
        )        
      }        
    },
    async getGeocodingState (context, args) {
      const api = context.rootState.api.api
      const res = await api.get('geocoding/v1', '/' + args.country, {}, {}, {})
      return res.items
    },
    async getOne (context, args) {
      const api = context.rootState.api.api
      let res = await api.get('graph/vpg', '/' + args.kind + '/' + encodeURIComponent(args.id), {}, {}, {})
      if (args.cb !== undefined) {
        await args.cb(res)
      }
      return res
    },
    async apply (context, obj) {
      const api = context.rootState.api.api
      let res = await api.put('graph/vpg', '', {}, obj, {})
      return res
    }, 
    async getMany (context, args) {
      const api = context.rootState.api.api
      let res = await api.get('graph/vpg', '/' + args.kind, {}, {}, {})
      return res
    }, 
    async remove (context, args) {
      const api = context.rootState.api.api
      let res = await api.delete('graph/vpg', '/' + args.kind + '/' + encodeURIComponent(args.id), {}, {}, {})
      return res
    },       
    async getHolidaysForPs (context, args) {
      const api = context.rootState.api.api
      // TODO: ps id
      let res = await api.get('holiday/v3', '/holidays/' + args.psId + "/" + args.from + '/' + args.to, {}, {}, {})
      if (res.response.length > 0) {
        return res.response
      }
      return []
    },  

    // NEW Holiday stuff
    async getHolidays (context, args) {
      const api = context.rootState.api.api
      let res = await api.get('holiday/v3', '/holidays', {}, {}, {})
      return res.response
    },
    async describeHoliday (context, args) {
      const api = context.rootState.api.api
      let res = await api.get('holiday/v3', '/holiday/' + args.holiday_id, {}, {}, {})
      const holidayDates = res.response.holidayDates

      let psPgRes = await api.get('propertyset/v1', '/propertygroup/propertyset', {}, {}, {})
      const mapping = {}
      for (let map of psPgRes.items) {
        if (map.metadata.kind === 'Physical') {
          mapping[map.metadata.property_set_id] = map.metadata.property_group_id
        }
      }

      for (let hd of holidayDates) {
        for (let hl of hd.holidayLink) {
          hl.pgid = mapping[hl.propertysetId]
          delete hl.propertysetId
        }
      }

      return res.response
    }, 
    async deleteHoliday (context, args) {
      const api = context.rootState.api.api
      let res = await api.delete('holiday/v3', '/holiday/' + args.holiday_id, {}, {}, {})
      return res.response
    },  
    async saveHoliday (context, args) {
      args = structuredClone(args) // copy, so that if the save fails, we did not edit the original
      const api = context.rootState.api.api
      let psPgRes = await api.get('propertyset/v1', '/propertygroup/propertyset', {}, {}, {})
      const mapping = {}
      for (let map of psPgRes.items) {
        if (map.metadata.kind === 'Physical') {
          mapping[map.metadata.property_group_id] = map.metadata.property_set_id
        }
      }
      for (let holiday of args) {
        for (let hd of holiday.holidayDates) {
          for (let hl of hd.holidayLink) {
            if (hl.pgid !== undefined && hl.pgid !== null) {
              if (mapping[hl.pgid] === undefined) {
                throw new Error(`pgid ${hl.pgid} not found in mapping`)
              }
              hl.propertysetId = mapping[hl.pgid]
            }
            else {
              hl.propertysetId = null
            }
            delete hl.pgid
          }
        }
      }
      let res = await api.put('holiday/v3', '/holiday', {}, args, {})
      return res.response
    },             
    async showFeedback(context, args) {
      if ( args.level == 'error' ) {
        context.dispatch("alertSystem/newError", {
          title: args.kind,
          message: args.message,
          timeout: -1,
        }, {root: true})
      } else if ( args.level == 'warning' ) {
        context.dispatch("alertSystem/newWarning", {
          title: args.kind,
          message: args.message,
          timeout: -1,
        }, {root: true})
      } else if ( args.level == 'success' ) {
        context.dispatch("alertSystem/newSuccess", {
          title: args.kind,
          message: args.message,
          timeout: -1,
        }, {root: true})
      } else if ( args.level == 'info' ) {
        context.dispatch("alertSystem/newInfo", {
          title: args.kind,
          message: args.message,
          timeout: -1,
        }, {root: true})
      } else {
        context.dispatch("alertSystem/addAlert", {
          color: 'grey lighten-1',
          timeout: -1,
          icon: undefined,
          title: args.kind,
          message: args.message,
        }, {root: true})
      }
    },
    async getParentEventValue(context, args){
      const parentId = args.parentId
      const pgId = args.pgId
      const date = context.getters.eventsFirstDateById[parentId]

      if(!date){
        return 1
      }

      try {
        const api = context.rootState.api.api
        let res = await api.get('events/vpg', `/find/${pgId}/${date}/${date}`, {}, {}, {})
        let events = res.response?.events
        let parentEvent = events.find(e => e.EventId === parentId)

        return parentEvent.EventTargetValue
      } catch (error) {
        console.warn(`couldn't get parent event value, defaulting to 1: `, error)
        return 1
      }
    },
    async getEventsByPg(context, args){
      const api = context.rootState.api.api
      let res = await api.get('events/vpg', `/find/${args.pgId}/-/-`, {}, {}, {})

      return res.response
    }
  }
}
