<template>
    <v-container fluid class="pa-4">
        <v-card v-if="showCard" class="card-ui pa-4">
            <v-card-title>Computed Prices Logs</v-card-title>

            <v-row class="pa-4">
                <v-col class="col-4">
                    <v-menu v-model="datePickerMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :value="`From ${formattedDates.startDate} to ${formattedDates.endDate}`"
                                label="Fetch by Date Range" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateRange" range
                            @change="[fetchLogs(), datePickerMenu = false]"></v-date-picker>
                    </v-menu>
                    <v-autocomplete dense outlined clearable
                        :items="['Show all', 'Prices enabled only', 'Prices disabled only']" v-model="pricesEnabledToShow"
                        label="Filter by property_is_set_prices_enabled"></v-autocomplete>
                </v-col>
                <v-col class="col-4">
                    <v-autocomplete dense outlined multiple :items="propertiesOnlyList" v-model="propertiesToShow"
                        label="Property ID" @change="rateToShow = ''">
                        <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="selectAllProperties">
                                <v-list-item-action>
                                    <v-icon dense :color="propertiesToShow.length > 0 ? 'indigo darken-4' : ''">
                                        {{ icon }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Select All
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                                <span>{{ item.text }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                                (+{{ propertiesToShow.length - 1 }} others)
                            </span>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col class="col-4">
                    <v-autocomplete dense outlined clearable :items="ratesList" v-model="rateToShow"
                        label="Property Rate ID"></v-autocomplete>
                </v-col>
            </v-row>

            <v-data-table dense :headers="headers" :items="logsToShow" sort-by="date" class="elevation-0 card-ui pa-4"
                loading-text="Loading... Please wait" style="cursor: pointer">
                <template v-slot:header.computeStatus="{ header }">
                    <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <!-- {{ header.text }}  -->
                                Compute <br> Status
                                <v-icon dark small>
                                    fa-solid fa-circle-info
                                </v-icon>
                            </span>
                        </template>
                        <span>The compute status states whether the compute is OK or KO, if the last computed failed it will
                            be KO otherwise OK</span>
                    </v-tooltip>
                </template>

                <template v-slot:header.sendStatus="{ header }">
                    <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <!-- {{ header.text }}  -->
                                Send <br> Status
                                <v-icon dark small>
                                    fa-solid fa-circle-info
                                </v-icon>
                            </span>
                        </template>
                        <span>The send status states whether the send is OK or KO, if the last send failed it will be KO
                            otherwise OK</span>
                    </v-tooltip>
                </template>

                <template v-slot:header.price_consistency="{ header }">
                    <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                {{ header.text }}
                                <v-icon dark small>
                                    fa-solid fa-circle-info
                                </v-icon>
                            </span>
                        </template>
                        <!--span>The consistency will be OK only if both the last successfull compute value and last successfull send value are equal</span-->
                        <span>The consistency will be OK only if the last_send date is after the last_compute date</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.computeStatus="{ item }">
                    <v-chip :color="item.computeStatus == 'KO' ? 'red accent-3' : 'green accent-4'">
                        {{ item.computeStatus }}
                    </v-chip>
                </template>
                <template v-slot:item.price_consistency="{ item }">
                    <v-chip :color="item.price_consistency == 'KO' ? 'orange accent-3' : 'green accent-4'">
                        {{ item.price_consistency }}
                    </v-chip>
                </template>
                <template v-slot:item.sendStatus="{ item }">
                    <v-chip :color="item.sendStatus == 'KO' ? 'red accent-3' : 'green accent-4'">
                        {{ item.sendStatus }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex';
import SelectPgAlert from '@/components/utils/SelectPgAlert'

export default Vue.extend({
    name: 'ComputedLogs',
    props: ['pg'],
    components: { SelectPgAlert },
    data: function () {
        return {
            datePickerMenu: false,
            dateRange: [],

            propertiesToShow: [],
            rateToShow: null,
            pricesEnabledToShow: "Prices enabled only",

            showCard: false,

            headers: [
                { text: 'Date', align: 'start', sortable: true, value: 'date', width: "2%" },
                { text: 'Property ID', value: 'property_id', width: "1%" },
                { text: 'Property Name', value: 'property_name', width: "1%" },
                { text: 'Property Is Set Prices Enabled', value: 'property_is_set_prices_enabled', width: "1%" },
                { text: 'PropertyRate Is Set Prices Enabled', value: 'property_rate_is_set_prices_enabled', width: "1%" },
                { text: 'Rate ID', value: 'rate_id', width: "1%" },
                { text: 'Property Rate', value: 'rate_name', width: "1%" },
                //  { text: 'Compute status', value: 'computeStatus', sortable: true, width: "1%"},
                { text: 'Last OK computed date', value: 'last_successful_computed_date', width: "3%" },
                { text: 'Last OK computed value', value: 'last_successful_computed_value', width: "1%" },
                //  { text: 'Send status', value: 'sendStatus', sortable: true, width: "1%"},
                { text: 'Last OK sent date', value: 'last_successful_sent_date', width: "3%" },
                //  { text: 'Last OK sent value', value: 'last_successful_sent_value', width: "1%"},
                { text: 'Consistency', value: 'price_consistency', sortable: true, width: "1%" },
            ],
        }
    },
    watch: {
        async currentProperties(newVal, oldVal) {
            this.showCard = false
            await this.fetchLogs()
            await this.fetchPgRatesNames({ pgid: this.currentPG.metadata.id })
            this.showCard = true
        }
    },
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG,
            detailedLogs: state => state.log.detailedLogs,
            currentProperties: state => state.core.currentProperties
        }),
        ...mapGetters({
            allPropertyNamesById: "core/allPropertyNamesById",
            propertyRateNameById: "log/propertyRateNameById"
        }),
        formattedDates() {
            return {
                startDate: this.dateRange[0] > this.dateRange[1] ? this.dateRange[1] : this.dateRange[0],
                endDate: this.dateRange[0] > this.dateRange[1] ? this.dateRange[0] : this.dateRange[1],
            }
        },
        formattedLogs() {
            return this.calculateStatus(this.detailedLogs).filter(log => {
                return this.propertiesToShow.includes(log.property_id)
            })
        },
        logsToShow() {
            return this.formattedLogs.filter(log => {
                return ((this.rateToShow !== null ? this.rateToShow === log.rate_name : true) && this.handlePricesEnabledFilter(log))
            })
        },
        propertiesOnlyList() {
            const propertiesList = []

            this.currentProperties.forEach(prop => {
                propertiesList.push({
                    text: `${prop.metadata.id} - ${prop.metadata.name}`,
                    value: prop.metadata.id,
                })
            })

            return propertiesList
        },
        ratesList() {
            return Array.from(new Set(this.formattedLogs.map(log => {
                return { id: log.rate_id, name: log.rate_name }
            }))).map(rate => {
                return {
                    text: !rate.id ? "Without Rate" : rate.name, //this.propertyRateNameById[rate] ,
                    value: !rate.id ? "" : rate.name,
                }
            }).filter(rate => rate.text !== undefined)
        },
        icon() {
            if (this.propertiesToShow.length === this.propertiesOnlyList.length) return 'fa-light fa-times-square'
            if (this.propertiesToShow.length !== this.propertiesOnlyList.length && this.propertiesToShow.length > 0) return 'fa-regular fa-minus-square'
            return 'fa-regular fa-square-full'
        },
    },
    methods: {
        ...mapActions({
            fetchDetailedLogs: "log/fetchDetailedLogs",
            fetchPgRatesNames: "log/fetchPgRatesNames",
        }),
        calculateStatus(rawLogs) {
            return rawLogs.map(rawLog => {
                const copy = structuredClone(rawLog)

                if (copy.last_successful_computed_date !== null) {
                    copy.last_successful_computed_date = new Date(copy.last_successful_computed_date).toISOString().split("T").join(" - ")
                }

                if (copy.last_successful_sent_date !== null) {
                    copy.last_successful_sent_date = new Date(copy.last_successful_sent_date).toISOString().split("T").join(" - ")
                }

                copy.price_consistency = rawLog.price_consistency == true ? 'OK' : 'KO'

                if (copy.rate_id == null) {
                    copy.rate_id = ""
                    copy.rate_name = ""
                }

                return copy
            })
        },
        addDays(date, days) {
            let result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        },
        async fetchLogs() {
            await this.fetchDetailedLogs({
                pgId: this.currentPG.metadata.id,
                property: "-",
                from: this.formattedDates.startDate,
                to: this.formattedDates.endDate,
            })
            // add them to propertiesToShow only if length is 0 (probably it's the first time)
            if (this.propertiesToShow.length == 0) {
                this.propertiesToShow = this.propertiesOnlyList.map(prop => prop.value)
            }

            this.pricesEnabledToShow = "Prices enabled only"
        },
        selectAllProperties() {
            this.$nextTick(() => {
                if (this.propertiesToShow.length === this.propertiesOnlyList.length) {
                    this.propertiesToShow = []
                } else {
                    this.propertiesToShow = this.propertiesOnlyList.map(prop => prop.value)
                }
            })
        },
        handlePricesEnabledFilter(log) {
            if (!this.pricesEnabledToShow || this.pricesEnabledToShow === "Show all") return true
            if (this.pricesEnabledToShow === "Prices enabled only") return log.property_is_set_prices_enabled === true
            if (this.pricesEnabledToShow === "Prices disabled only") return log.property_is_set_prices_enabled === false
        },
    },
    async created() {
        // default dates
        const today = new Date()
        this.dateRange = [today.toISOString().split("T")[0], this.addDays(today, 100).toISOString().split("T")[0]]

        // get logs based on these dates
        this.showCard = false
        await this.fetchLogs()
        await this.fetchPgRatesNames({ pgid: this.currentPG.metadata.id })
        this.showCard = true
    }
})
</script>
