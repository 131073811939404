<template>
  <div class="home">
    <v-container fluid class="pa-0">
      <v-row>
          <v-col>
          <v-btn class="mb-6 mt-6" color="red" v-if="this.globalSelectedPgId !== null" @click="refreshBigqueryData()">REFRESH DATA </v-btn>                  
        </v-col>
        <v-col class="col-12" style="height: 100vh;">
          <iframe width="100%" height="100%" :src="reportUrl" frameborder="0" style="border:0" allowfullscreen v-if="this.globalSelectedPgId !== null"></iframe>          
          <h3 class="ma-6" v-else> Select a property group </h3>
        </v-col>
      </v-row>
  </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// @ is an alias to /src

export default {
  name: 'Analytics',
  components: {},
  data: function (argument) {
    return {}
  },
  methods: {
    async refreshBigqueryData() {
      const selectedPropertyGroup = this.globalSelectedPgId
      let res = await this.$store.dispatch("analytics/refreshBigqueryData", {
        propertyGroupIds: [selectedPropertyGroup]
      })
            
      if ( res.status == 500 ) {
        this.$store.dispatch('alertSystem/newError', {
          message: res.error,
        });
      } else {
        this.$store.dispatch('alertSystem/newSuccess', {
          message: "The job can require some minutes to finish." + 'Please wait at least 5 minutes to see if the Job has finished and do not submit another refresh job for the same Property Group',
        });
      }

    }

  },
  computed: {
    ...mapGetters({
      globalSelectedPropertyGroup: "core/globalSelectedPropertyGroup",
      globalSelectedPgId: "core/globalSelectedPgId",
    }),
    reportUrl () {
      if (this.globalSelectedPropertyGroup == null) {
        return
      }
      const propertyGroupId = this.globalSelectedPgId
      //const baseUrl = 'https://datastudio.google.com/embed/reporting/a9ebce56-abdd-483f-8b39-2f69602305ac/page/LiduC'
      const baseUrl = 'https://datastudio.google.com/embed/reporting/a4ead2f7-2c8c-437b-b324-df72ffdb102b/page/LiduC'
      const params = {
        "ds23.year_cy" : new Date().getFullYear(),
        "ds23.year_ly" : new Date().getFullYear() - 1,
        "ds20.year_cy" : new Date().getFullYear(),
        "ds20.year_ly" : new Date().getFullYear() - 1,
        "ds57.year_cy" : new Date().getFullYear(),
        "ds58.year_cy" : new Date().getFullYear(),
        "ds59.year_cy" : new Date().getFullYear(),
        "ds57.year_ly" : new Date().getFullYear() - 1,
        "ds58.year_ly" : new Date().getFullYear() - 1,
        "ds59.year_ly" : new Date().getFullYear() - 1,
        "ds20.pg_id" : propertyGroupId,
        "ds21.pg_id" : propertyGroupId,
        "ds23.pg_id" : propertyGroupId,
        "ds34.pg_id" : propertyGroupId,
        "ds45.pg_id" : propertyGroupId,
        "ds15.pg_id" : propertyGroupId,
        "ds22.pg_id" : propertyGroupId,
        "ds14.pg_id" : propertyGroupId,
        "ds38.pg_id" : propertyGroupId,
        "ds41.pg_id" : propertyGroupId,
        "ds46.pg_id" : propertyGroupId,
        "ds54.pg_id" : propertyGroupId,
        "ds57.pg_id" : propertyGroupId,
        "ds58.pg_id" : propertyGroupId,
        "ds65.pg_id" : propertyGroupId

      };
      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString)
      return baseUrl + '?params=' + encodedParams
    }
  }
}
</script>
