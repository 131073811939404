
import typeformTemplate from "@/assets/typeform_questions.json";


export default {
  namespaced: true,

  state: {

    typeformTemplate: typeformTemplate,
    typeFormView: {},
    typeformAnswers: [],


  },//end of state 


  mutations: {

    cleanupTypeForm(state, args){
      state.typeFormView = { 'email': '',
                             'questions': [],
                             'categories_label': []
                          };
      state.typeformAnswers = "\t";

    },

    computeViewScores(state, args){
      
      var typeform = args.typeform.typeform;

      var typeFormView = { 'email': '', 'questions': [], 'categories_label': [], 'bar_loaded': null, 'trendline': null};
      state.typeformAnswers = [];

      var categories = {    "Customer approach": 0,
                            "Data consistency/representativeness perception": 0,
                            "Self perception and positioning": 0,
                            "Willingness to push prices in a safe scenario": 0, 
                            "Obsessed with competitors?": 0,
                            "Drop behaviour in a uncertain scenario": 0,
                            "Pickup/drop behaviour in a winning scenario": 0,
                            "Far out strategy": 0, 
                            "Rooms hierarchy": 0

        };

      for ( const [key, value] of Object.entries(categories) ) {
        typeFormView.categories_label.push(key);
      }

      var totScore = 0;

      if ( typeform != null && Object.values(typeform).length > 1 ) {
        
        state.typeformAnswers = Object.values(typeform);

        let answers = Object.values(typeform)

        for ( var i=0; i < state.typeformTemplate.questions.length; i++ ) {

          let question = state.typeformTemplate.questions[i];          
          let answer = answers[i];
          
          if ( question.category == 'Email' ) {
            typeFormView['email'] = answer;

          } else if ( question.category == 'CustomerBar' ) {
            let isBoolBar = answer, hasBar = null
            if ( isBoolBar !== undefined && isBoolBar.length > 0 ) {
              hasBar = (isBoolBar.toLowerCase() === 'true');
            }
            typeFormView.bar_loaded = hasBar;

          } else if ( question.category == 'Trendline' ) {
            typeFormView['trendline'] = answer;

          } else {
            
            var questionView = {};
            questionView['category'] = question.category;
            questionView['question'] = question.question;
            questionView['answer'] = "unanswered";
            questionView['strategyAccount'] = ''
            if(question.answers[0].hasOwnProperty(answer)){
              questionView['answer'] = question.answers[0][answer];
  
              let score = question.answers[0][answer].score;
              if(question.customer_bar_correction !== undefined){
                score = score + question.customer_bar_correction;
              }
              questionView['answer']['strategyAccount'] = question.answers[0][answer]['strategy-account']
  
              categories[question.category] += score;
              totScore += score;
            }
  
            typeFormView.questions.push(questionView);
          }          
        }
      }

      typeFormView['categories'] = categories;
      typeFormView['score'] = totScore;
      state.typeFormView = typeFormView;

      return typeFormView;

    },


  }, // end mutations
  
  actions: {

    computeViewScores(context, args){
      context.commit('computeViewScores', args);

    },
    cleanupTypeForm(context, args){
      context.commit('cleanupTypeForm', args);
    }

  }, //end of actions

}
