/**
 * Store for scraper
 */

export default {
    namespaced: true,

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {
        async search(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get('predictapi/v1', '/ght/search', payload.query, {}, {})

                if (payload.cb !== undefined && typeof payload.cb == 'function') {
                    payload.cb(res)
                }
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Search failed: ${error}`
                }, { root: true })
            }
        },
    }
}
