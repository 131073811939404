<template >
    <v-row>
        <v-col>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field label="Email" v-model="email" :rules="emailRules" required></v-text-field>
            </v-form>
            <div class="d-flex justify-end">
                <v-btn :disabled="!valid" color="primary" class="mr-4 mt-2" @click="nextStep" :loading="loading">
                    Send message
                </v-btn>
                <v-btn color="secondary" class="mt-2" @click="$emit('back')">
                    Back
                </v-btn>
            </div>
            <ConfirmDialog ref="dialog" />
        </v-col>
    </v-row>
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import ConfirmDialog from '@/components/utils/ConfirmDialog'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'TestUpsellingMailStep',
    components: { ConfirmDialog },
    data: function () {
        return {
            loading: false,
            valid: true,
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ]
        }
    },
    computed: {
        ...mapGetters({
            upsellingPsIds: 'smartpaying/psIds',
        })
    },
    methods: {
        async nextStep() {
            const isValid = this.$refs.form.validate()
            if (isValid) {
                this.loading = true
                const resp = await this.$store.dispatch('smartpaying/sendTestMex', { pgId: parseInt(this.upsellingPsIds[0]), mexKind: 'upsellingProposalCheckin', email: this.email })
                this.loading = false
                if (resp.response?.status >= 400) {
                    const options = { cardTextClass: "pa-4 orange accent-1--text", color: 'orange' }
                    const title = "Error sending test message"
                    const message = resp.response.data
                    await this.$refs.dialog.open(title, message, options)
                }
                else {
                    const options = { cardTextClass: "pa-4 accent-1--text", color: 'success' }
                    const title = "Emails sent"
                    const message = `We sent to ${this.email} the mail template for Upselling in both IT and EN versions`
                    await this.$refs.dialog.open(title, message, options)
                }
                this.$emit('next')
            }
        },
    }
})
</script>