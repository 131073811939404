<template>
  <v-card>
    <v-card-text>
      <v-menu
        v-model="menu1"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        dark
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Show events after"
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          @input="menu1 = false"
          header-color="primary"
        ></v-date-picker>
      </v-menu>
      <v-data-table
        :headers="headers"
        :items="eventsObj"
        sort-by="EventDate"
        :single-expand="singleExpand"
        show-expand
        item-key="uniqueKey"
        :items-per-page="100"
        :footer-props="{
                itemsPerPageOptions: [100, 500,  -1],
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus'
        }"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-list lines="one">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Source Kind</v-list-item-title>
                  <v-list-item-subtitle>{{ item.EventSourceKind }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Event Coefficient</v-list-item-title>
                  <v-list-item-subtitle>{{ item.EventTargetValue }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Date Coefficient</v-list-item-title>
                  <v-list-item-subtitle>{{ item.EventDateValue }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list> 
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: "EventsByPG",
  props: [],
  components: {},
  data() {
    return {
      menu1: false,
      singleExpand: true,
      date: "",
      headers: [
        /* {
          text: 'Source Kind',
          align: 'start',
          value: 'EventSourceKind',
        }, */
        {
          text: 'Source Name',
          value: 'EventSourceName',
          width: "1%",
        },
        {
          text: 'Event Label',
          value: 'EventLabel',
          width: "1%",
        },
        /* {
          text: 'Event Coefficient',
          value: 'EventTargetValue',
        }, */
        {
          text: 'Event Date',
          value: 'EventDate',
          width: "10%",
        },
        /* {
          text: 'Date Coefficient',
          value: 'EventDateValue',
        }, */
        {
          text: 'Price Final Coefficient',
          value: 'PriceFinalCoefficient',
          width: "1%",
        },
        {
          text: 'Parent Event',
          value: 'RootEvent',
          width: "1%",
        }
      ],
      eventsLocal: [],
    }
  },
  methods: {
    async getEventsByPg(){
      let response = await this.$store.dispatch('graph/getEventsByPg', {pgId: this.pgId})
      
      this.eventsLocal = response.events
    }
  },
  computed: {
    ...mapState({
      pgId: state => state.core.currentPG.metadata.id
    }),
    eventsObj(){
      /**
      {
        "EventSourceName": "italy_lombardia_sondrio_livigno",
        "EventSourceKind": "Location:City",
        "EventLabel": "Coppa del mondo sci alpino maschile - TBA",
        "EventTargetValue": 0.6,
        "EventDate": "2023-12-29",
        "EventId": "scialpinobormio2023",
        "RootEvent": null,
        "EventSourceValue": 1660743187,
        "EventDateValue": 1.05,
        "PropertyGroupId": "1000757",
        "EventDateFromRoot": false,
        "UniqueId": "scialpinobormio2023",
        "EventKind": "Event"
      }
      */

      this.eventsLocal.forEach(e => {
        e.uniqueKey = e.EventId + e.EventDate;
        e.PriceFinalCoefficient = parseFloat((((e.EventDateValue-1)*e.EventTargetValue)+1).toFixed(3));
        //e.EventSourceName = e.EventSourceName.replaceAll("_", " ")
      })

      if (this.date){
        return this.eventsLocal.filter(value => {
          if (value.EventDate >= this.date){
            return true
          }
          return false
        })
      }

      return this.eventsLocal
    }
  },
  created(){
    this.getEventsByPg()

    // show today as date by default
    const today = new Date();
    this.date = today.toISOString().split("T")[0]
  }
}
</script>

<style scoped>
.v-list {
  display: flex;
}
</style>