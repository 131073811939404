<template>
    <v-container fluid class="pa-4">
        <v-card  class="card-ui pa-4">
            <v-card-text>
                <v-row>
                    <v-col class="col-10">
                        <v-text-field v-model="text" label="Text" outlined hint="Villa Maria Riva"></v-text-field>
                    </v-col>
                    <v-col class="col-2">
                        <v-btn @click="text = pgBookingId" v-if="!!globalSelectedPgId">Copy current PG Booking ID</v-btn>
                    </v-col>
                    <v-col class="col col-5">
                        <v-text-field label="Latitude" hint="For example 40.63060491398805" outlined v-model="latitude"></v-text-field>
                    </v-col>
                    <v-col class="col col-5">
                        <v-text-field label="Longitude" hint="For example 14.48469149714154" outlined v-model="longitude"></v-text-field>
                    </v-col>
                    <v-col class="col-2">
                        <v-btn @click="latitude = pgLatitude; longitude = pgLongitude" v-if="!!globalSelectedPgId">Copy current PG coordinates</v-btn>
                    </v-col>
                    <v-col class="col col-5">
                        <v-text-field label="Radius (KM)" hint="For example 7.5" outlined v-model="radius"></v-text-field>
                    </v-col>
                    <v-col class="col col-5">
                        <v-text-field label="Number of hotel" hint="For example 100" outlined v-model="limit"></v-text-field>
                    </v-col>
                    <v-col class="col-12">
                        <v-btn color="success" block @click="search">Search</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="col-12">
                        <v-data-table
                            :headers="table.headers"
                            :items="searchResults"
                            :items-per-page="20"
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
// @ is an alias to /src
import SelectPgAlert from '@/components/utils/SelectPgAlert'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'Search',
    components: {
        SelectPgAlert,
    },
    data: function (argument) {
        return {
            text: "",
            latitude: "",
            longitude: "",
            radius: "",
            limit: "",
            searchResults: [],
            table: {
                headers: [
                    { text: 'ID', value: 'booking_id' },
                    { text: 'Url', value: 'booking_url',},
                    { text: 'Name', value: 'booking_title' },
                    { text: 'Type', value: 'booking_accommodation_type' },
                    { text: 'Stars', value: 'acc_classification_rating' },
                    { text: 'Score', value: 'booking_review_score' },
                    { text: 'Addres', value: 'booking_address' },
                    { text: 'Latitude', value: 'lat' },
                    { text: 'Longitude', value: 'lon' },
                ],
            }
        }
    },
    watch: {
    },
    computed: {
        ...mapState({
            pgBookingId: state => state.core.currentPG.spec.booking_id,
            pgLatitude: state => state.core.currentPG.spec.coordinates?.x,
            pgLongitude: state => state.core.currentPG.spec.coordinates?.y,
        }),
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
        }),
    },
    methods: {
        ...mapActions({
        }),
        async search() {
            const isId = this.text != "" && !isNaN(this.text)

            await this.$store.dispatch("scraper/search", {
                query: {
                    words: this.text != "" && !isId ? this.text : undefined,
                    id: isId ? +this.text : undefined,
                    lat: this.latitude != "" ? this.latitude : undefined,
                    lon: this.longitude != "" ? this.longitude : undefined,
                    distance: this.radius != "" ? this.radius + "km" : undefined,
                    limit: this.limit != "" ? this.limit : undefined,
                },
                cb: function (res) {
                    this.searchResults = res.results
                }.bind(this)
            })
        }
    },
    async created() {
    }
}
</script>
