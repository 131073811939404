<template>
  <div class="home">
    <v-container fluid class="pa-0">
      <v-row>
        <v-col class="col-12" style="height: 100vh;">
          <iframe width="100%" height="100%" :src="reportUrl" frameborder="0" style="border:0" allowfullscreen ></iframe>          
        </v-col>
      </v-row>
  </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'alarmOccupancy',
  components: {},
  data: function (argument) {
    return {}
  },
  computed: {
    reportUrl () {
      const baseUrl = 'https://lookerstudio.google.com/embed/reporting/0105102b-6dea-4398-bc2f-1157b9f3a3d4/page/p_sm5ecafeyc'
      return baseUrl
    }
  }
}
</script>
