<template>
  <v-container fill-height fluid class="app-background" v-if="!isAuth">
    <v-row align="center" justify="center">
      <v-col style="max-width: 300px">
      <v-card class="app-background elevation-0" dark>
        <v-card-title>
          <img
            src="@/assets/smartpricing-compact-logo-dark.png"
            width="100%"
          />
        </v-card-title>

        <v-divider class="ma-4"></v-divider>
        <div class="subheading font-weight-light grey--text pl-4"> The use of this system is <b>reserved</b> <br>to <b>Smartpricing employees</b></div>
        <v-card-text>
          <div ref="googleLoginBtn"></div>
        </v-card-text>
        <v-alert v-if="authenticationError">
          Authentication Error
        </v-alert>
      </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import {mapState} from "vuex"

export default Vue.extend({
  name: 'Login',
  data: function () {
    return {
      token: null,
      authenticationError: false
    }
  },
  computed: {
    ...mapState({
      isAuth: state => state.auth.isAuth,
    })
  },
  methods: {
    changeApp: function (app) {
      this.$store.commit('ui/setApp', app.name)
      this.$store.commit('ui/setAppSubresource', app.subresources[0].name)
      this.$router.push({ path: app.subresources[0].link, replace: true })
    },    
    /* async loginNew(jwt) {
      console.log(jwt)
    }, */
    async login(googleAuthObj) {
      this.$store.dispatch('auth/loginWithGoogle', {
        gAuth: googleAuthObj,
        cb: function (res) {
          try {
            if (res.auth == true) {
              let cookie = Vue.prototype.$cookie
              cookie.set('sp-internal-dashboard-auth', true)
              cookie.set('sp-internal-dashboard-token', res.accessToken)          
              cookie.set('sp-internal-dashboard-username', res.username)
              cookie.set('sp-internal-dashboard-auth-date', (new Date()).getTime())            
              this.$store.dispatch('api/makeAPIClient', {
                accessToken: res.accessToken
              })
              this.$router.push({path: '/', replace: true})
            }
          } catch (err) {
            console.log(err)
          }
        }.bind(this)
      })
    }
  },
  mounted() {
    const gClientId = ['494674898042-i6qc8k09728pmfljtm3tf6vv5pvsiqs9']
    window.google.accounts.id.initialize({
      client_id: gClientId,
      callback: this.login,
      auto_select: true
    })

    window.google.accounts.id.renderButton(
      this.$refs.googleLoginBtn, {
        text: 'signin_with', // or 'signup_with' | 'continue_with' | 'signin'
        size: 'large', // or 'small' | 'medium'
        theme: 'filled_black', // or 'filled_black' |  'filled_blue'
        logo_alignment: 'left', // or 'center'
        type:"standard",
        shape:"rectangular",
      }
    )
  }
})
</script>
