<template>
    <v-container fluid class="pa-4">
        <ResetSysdatAck v-if="!!currentPG"></ResetSysdatAck>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ResetSysdatAck from '../components/ResetSysdatAck.vue';
import SelectPgAlert from '@/components/utils/SelectPgAlert'

export default {
    components: {ResetSysdatAck, SelectPgAlert},
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG,
        })
    }
}
</script>