<template>
    <v-card
        class="card-ui mt-4"
    >
        <v-card-title>Rates for property {{ selectedPropertyPrettyName }}</v-card-title>

        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="propertyRatesTable"
            >
                <template v-slot:item.variationValue="{ item }">
                    <span v-if="item.variationType === 'amount'">
                        {{ item.variationValue }} {{ currentCurrency?.spec?.code || "" }}
                    </span>
                    <span v-else>
                        {{ item.variationValue * 100 }} %
                    </span>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters} from 'vuex';

export default {
    data() {
        return {
            headers: [
                { text: 'Linked Rates', sortable: true, value: 'name' },
                { text: 'Write', sortable: true, value: 'write' },
                { text: 'Channel Manager Rate', sortable: true, value: 'cmRate' },
                { text: 'Extra Params', sortable: true, value: 'extraParams' },
                { text: "Variation Type", value:"variationType" },
                { text: "Variation Value", value:"variationValue" },
            ],
        }
    },
    computed: {
        ...mapGetters({
            propertyRatesTable: "mappingAndRates/propertyRatesTable",
            selectedPropertyPrettyName: "mappingAndRates/selectedPropertyPrettyName",
            currentCurrency: "core/currentCurrency",
        })
    }
    
}
</script>

<style lang="scss" scoped>

</style>