<template>
    <v-container fluid class="pa-4">
      <PSInfoDisplay v-if="!!globalSelectedPsId"></PSInfoDisplay>
      <SelectPsAlert v-else></SelectPsAlert>
    </v-container>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import PSInfoDisplay from '../components/PSInfoDisplay.vue'
  import SelectPsAlert from '@/components/utils/SelectPsAlert'
  
  export default {
    components: {PSInfoDisplay, SelectPsAlert},
    data: function (argument) {
      return {}
    },
    computed: {
      ...mapGetters({
        globalSelectedPsId: "core/globalSelectedPsId",
      })
    }
  }
  
  
  </script>