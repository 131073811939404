<template>
    <v-container fluid class="pa-0">
        <v-tabs
            v-model="tab"
            background-color="transparent"
        >
            <v-tab
                v-for="selectedTab in tabList"
                :key="selectedTab"
            >
                {{ selectedTab }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <!-- Insert Market -->
            <v-tab-item key="insert">
                <MarketEditor
                    mode="insert"
                ></MarketEditor>
            </v-tab-item>

            <!-- Edit Market -->
            <v-tab-item :key="tabList[1]">
                <MarketEditor
                    mode="edit"
                ></MarketEditor>
            </v-tab-item>
        </v-tabs-items>
        
    </v-container>
</template>

<script>
import MarketEditor from '../components/MarketEditor.vue';
import {mapState} from 'vuex'

export default {
    components: {MarketEditor},
    data() {
        return {
            tabList: ["insert", "edit"],
            tab: "insert",
        }
    },
    computed: {
        ...mapState({
            propertyGroupList: state => state.graph.propertyGroupList,
            analyticalMarketsList: state => state.graph.analyticalMarketsList,
        }),
    },
    async created() {
        await this.$store.dispatch("graph/prefetch", {})
        if (!this.propertyGroupList) {
            await this.$store.dispatch("graph/fetchPropertygroups")
        }

        if (!this.analyticalMarketsList) {
            await this.$store.dispatch("graph/fetchAnalyticsMarkets")
        }
        await this.$store.dispatch("graph/fetchLocations")
        
    }
}
</script>