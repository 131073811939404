<template>
    <v-container fluid class="pa-4">
        <v-card class="pa-4 card-ui">
            <v-card-title>Events Overview</v-card-title>

            <v-row>
                <v-col class="col-3">
                    <v-menu
                        v-model="filterDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="datePickerDate"
                                label="Filter By Date Range"
                                readonly
                                outlined
                                clearable
                                @click:clear="filterDateRange = []"
                                dense
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filterDateRange"
                            range
                            @change="[filterDateRange = $event, filterDateMenu = false]"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col class="col-3">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        label="Filter By Location"
                        :items="filterLocationOptions"
                        v-model="filterLocations"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-3">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        label="Filter By Name"
                        :items="filterNameOptions"
                        v-model="filterNames"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-3">
                    <v-autocomplete
                        outlined
                        dense
                        multiple
                        label="Filter By Type"
                        :items="filterTypeOptions"
                        v-model="filterType"
                    ></v-autocomplete>
                </v-col>
            </v-row>

            <v-data-table
                :headers="mainHeaders"
                :items="filteredEvents"
                item-key="id"
                :single-expand="true"
                :single-select="false"
                show-select
                show-expand
                v-model="selectedEvents"
            >
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-row class="pa-2">
                            <v-col class="col-6">
                                <v-data-table
                                    class="card-ui"
                                    :headers="dateHeaders"
                                    :items-per-page="5"
                                    :items="item.eventDates"
                                ></v-data-table>
                            </v-col>
                            <v-col class="col-6">
                                <v-data-table
                                    v-if="['custom root event', 'smartpricing event'].includes(item.eventType)"
                                    :headers="locationHeaders"
                                    :items="item.eventLocations"
                                    :items-per-page="5"
                                    class="card-ui"
                                ></v-data-table>
                                <v-data-table
                                    v-else
                                    class="card-ui"
                                    :headers="pgHeaders"
                                    :items-per-page="5"
                                    :items="item.eventPgs"
                                ></v-data-table>
                            </v-col>
                        </v-row>
                    </td>
                </template>

                <template v-slot:item.affectedLocations="{ item }">
                    <v-chip v-for="loc of item.affectedLocations" small color="primary">
                        {{ loc }}
                    </v-chip>
                </template>

                <template v-slot:item.affectedDates="{ item }">
                    <v-chip v-for="date of item.affectedDates" small color="success">
                        {{ date }}
                    </v-chip>
                </template>

                <template v-slot:item.affectedPgs="{ item }">
                    <v-chip v-for="pg of item.affectedPgs" small color="default">
                        {{ pg }}
                    </v-chip>
                </template>

                <template v-slot:footer.prepend>
                    <v-btn
                        :disabled="!selectedEvents?.length"
                        color="primary"
                        @click="updateDialog = true"
                    >Update Selected</v-btn>
                </template>
            </v-data-table>
        </v-card>


        <v-dialog
            transition="dialog-bottom-transition"
            max-width="900"
            v-model="updateDialog"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <v-toolbar color="primary">Update Selected Events</v-toolbar>

                    <v-card-actions v-if="!errors">
                        <v-btn
                            color="primary"
                            @click="addNewLocation"
                        >Add New Location</v-btn>
                    </v-card-actions>
                    
                    <v-card-text v-if="!errors">
                        <v-row>
                            <v-col class="col-12 pa-2 card-ui my-2" v-for="location, index of newLocations">
                                <v-row>
                                    <v-col class="col-8">
                                        <v-autocomplete
                                            outlined
                                            hide-details
                                            dense
                                            label="Location"
                                            :value="locations.find(loc => loc.metadata.id === location.id)"
                                            :items="locations"
                                            :item-text="val => val.metadata.id"
                                            :item-value="val => val"
                                            @change="[newLocations[index].id = $event.metadata.id, newLocations[index].kind = $event.kind]"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col class="col-3">
                                        <v-text-field
                                            number
                                            outlined
                                            hide-details
                                            dense
                                            :value="location.value"
                                            label="Weight"
                                            @change="[newLocations[index].value = Number($event)]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="col-1">
                                        <v-btn
                                            icon
                                            @click="[newLocations.splice(index, 1)]"
                                        ><v-icon>far fa-trash-can</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-alert type="warning" class="ma-2" v-else>
                        Not all selected events support locations
                    </v-alert>

                    <v-card-actions class="justify-end">
                        <v-btn
                            color="primary"
                            @click="updateEvents"
                            :disabled="!newLocations?.length"
                        >Update</v-btn>
                        <v-btn
                            text
                            @click="updateDialog = false"
                        >Close</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            mainHeaders: [
                {text: "Label", value: "label"},
                {text: "Event Type", value: "eventType"},
                {text: "Affected Locations", value: "affectedLocations"},
                {text: "Affected Pgs", value: "affectedPgs"},
                {text: "Affected Dates", value: "affectedDates"},
            ],

            locationHeaders: [
                {text: "Location", value: "id"},
                {text: "Weight", value: "value"},
            ],

            dateHeaders: [
                {text: "Date", value: "id"},
                {text: "Weight", value: "value"},
            ],

            pgHeaders: [
                {text: "PG", value: "id"},
                {text: "Weight", value: "value"},
            ],


            eventTypeParsingFunctions: { // to check again
                "smartpricing event": event => {
                    return !event.spec.isCreatedByUser 
                    && !event.spec.isUpdatedByUser 
                    && !event.link.EventTarget?.filter(val => String(val.kind).includes("Propertygroup")).length
                },
                "custom child rm event": event => {
                    return !event.spec.isCreatedByUser 
                    && !event.spec.isUpdatedByUser 
                    && !!event.link.RootEvent
                    && !!event.link.EventTarget?.filter(val => String(val.kind).includes("Propertygroup")).length
                    //&& !!event.childEventType === "smartpricing"
                },
                "custom child user event": event => {
                    return !event.spec.isCreatedByUser 
                    && !!event.link.RootEvent
                    && !!event.link.EventTarget?.filter(val => String(val.kind).includes("Propertygroup")).length
                    //&& !!event.childEventType === "user"
                },
                "custom root event": event => {
                    return !!event.spec.isUpdatedByUser
                    && !!event.link.EventTarget?.filter(val => String(val.kind).includes("Propertygroup")).length
                    && event.link.RootEvent === undefined
                }
            },

            filterDateMenu: false,
            updateDialog: false,

            filterDateRange: [],
            filterLocations: [],
            filterNames: [],
            filterType: [],

            selectedEvents: [],

            newLocations: [],
        }
    },
    computed: {
        ...mapState({
            events: state => state.graph.events,
            locations: state => state.graph.locations,
        }),
        datePickerDate() {
            const dates = this.datePickerPrettify(this.filterDateRange)
            return !!this.filterDateRange?.length ? `${dates?.start || ""} - ${dates?.end || ""}` : ""
        },
        parsedEvents() {
            return this.events.map(event => {

                const affectedDates = event.link.EventDate.map(eventdate => eventdate.id)

                const affectedLocations = event.link.EventTarget.filter(eventtarget => {
                    return String(eventtarget.kind).includes("Location")
                }).map(eventtarget => eventtarget.id)

                const affectedPgs = event.link.EventTarget.filter(eventtarget => {
                    return String(eventtarget.kind).includes("Propertygroup")
                }).map(eventtarget => eventtarget.id)

                const eventType = function (ev, eventTypeParsingFunctions) {
                    for (let [type, fx] of Object.entries(eventTypeParsingFunctions)) {
                        if (fx(ev)) {
                            return type
                        }
                    }
                }(event, this.eventTypeParsingFunctions)

                return {
                    original: event,
                    eventPgs: event.link.EventTarget.filter(eventtarget => {
                        return String(eventtarget.kind).includes("Propertygroup")
                    }),
                    eventLocations: event.link.EventTarget.filter(eventtarget => {
                        return String(eventtarget.kind).includes("Location")
                    }),
                    eventDates: event.link.EventDate,
                    eventType: eventType,
                    affectedDates: affectedDates,
                    affectedLocations: affectedLocations,
                    affectedPgs: affectedPgs,
                    label: event.spec.label,
                    id: event.metadata.id,
                }
            })
        },
        filteredEvents() {
            const dates = this.datePickerPrettify(this.filterDateRange)
            return this.parsedEvents.filter(event => {
                const activeDateFilter = !!this.filterDateRange.length ? event.affectedDates.some(date => dates.start < date && dates.end > date) : true
                const activeNameFilter = !!this.filterLocations.length ? event.affectedLocations.some(location => this.filterLocations.includes(location)) : true
                const activeLocationFilter = !!this.filterNames.length ? this.filterNames.includes(event.label) : true
                const activeTypeFilter = !!this.filterType.length ? this.filterType.includes(event.eventType) : true

                return activeDateFilter && activeNameFilter && activeLocationFilter && activeTypeFilter
            })
        },
        filterNameOptions() {
            return Array.from(new Set(this.parsedEvents.map(event => event.label)))
        },
        filterLocationOptions() {
            const locations = []
            this.parsedEvents.forEach(event => {
                event.affectedLocations.forEach(loc => {
                    locations.push(loc)
                })
            })

            return Array.from(new Set(locations))
        },
        filterTypeOptions() {
            return Array.from(new Set(this.parsedEvents.map(event => event.eventType)))
        },
        errors() {
            // check for events that don't support location
            return this.selectedEvents.some(event => event.eventType !== "smartpricing event")
        }
    },
    methods: {
        datePickerPrettify(dateRange) {
            const dates = {
                start: null,
                end: null,
            }

            if (dateRange[0] > dateRange[1]) {
                dates.start = dateRange[1]
                dates.end = dateRange[0]
            } else {
                dates.start = dateRange[0]
                dates.end = dateRange[1]
            }

            return dates
        },
        addNewLocation() {
            this.newLocations.push({
                kind: "",
                value: 1,
                id: "",
            })
        },
        async updateEvents(){
            const res = []
            try {
                const originalEventsArr = this.selectedEvents.map(event => event.original).map(event => {
                    const copy = structuredClone(event)

                    this.newLocations.forEach(newLoc => {
                        // if the new location is already attached to that event then it doesn't add it again
                        const index = copy.link.EventTarget.findIndex(val => val.id === newLoc.id)

                        if (index === -1) {
                            copy.link.EventTarget.push(newLoc)
                        }
                    })
                    return copy
                })

                await Promise.all(originalEventsArr.map(async (event) => {
                    const r = await this.$store.dispatch('graph/editEvent', event)
                    res.push(r)
                }))

                if(res.every(r => r.success)){
                    this.$store.dispatch('graph/fetchEvents')
                    this.updateDialog = false
                    this.selectedEvents = []
                    this.newLocations = []
                }
            } catch (e) {
                console.error(e)
                console.log(`Something went wrong: `, res)
            }
        },
    }
}
</script>