
<template>
    <v-card
        class="card-ui pa-4"
    >
        <v-card-title>Length of Stay management</v-card-title>
        <v-card-subtitle v-if="!enabled" class="red--text">The feature is not supported by the selected pg</v-card-subtitle>
        <v-card-text v-else>
            <v-row>
                <v-col class="col-2">
                    <v-row>
                        <v-checkbox
                            label="Select all properties"
                            v-model="areAllPropertiesSelected"
                        >
                        </v-checkbox>
                    </v-row>
                </v-col>
                <v-col class="col-10">
                    <v-row>
                        <v-col class="grow">
                            <v-autocomplete
                                dense
                                outlined
                                multiple
                                :chips=true
                                label="Select Property Ids"
                                :items="allProperties"
                                :item-text="value => `${value.id} - ${value.name}`"
                                :item-value="value => Number(value.id)"
                                :disabled="areAllPropertiesSelected==true"
                                v-model="selectedPropertyIds"
                            >

                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @mousedown.prevent
                                        @click="selectAllProperties"
                                    >
                                        <!--v-list-item-action>
                                            <v-icon dense :color="selectedPropertyIds.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icon }}
                                            </v-icon>
                                        </v-list-item-action-->
                                        <v-list-item-content>
                                            <v-list-item-title>
                                            Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        color="primary"
                        @click="saveLos()">Save
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
    name: 'LosManagement',

    data() {
        return {
            enabled: false,
            areAllPropertiesSelected: false,
            selectedPropertyIds: []
        }
    },
    computed: {
        ...mapState({
            currentPropertiesIncludingChildren: state => state.core.currentPropertiesIncludingChildren,
            currentProperties: state => state.core.currentProperties,
            currentPG: state => state.core.currentPG,
        }),
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
	    }),
        ...mapState({
            partnersListByFeature: state => state.integrationsSpec.partnersListByFeature
        }),
        allProperties() {
            return this.currentProperties.map( prop => {return {id: prop.metadata.id, name: prop.metadata.name} } )
        }
    },
    watch: {
	    async globalSelectedPgId(newVal, oldVal) {
		    await this.setup()
	    },
        areAllPropertiesSelected(newVal, oldVal) {
            this.selectedPropertyIds = []
        }
    },
    methods: {
        ...mapMutations({
            updateLosSettings: "core/updateLosSettings", 
        }),
        ...mapActions({
            saveLosSettings: "core/saveLosSettings",
            fetchLosSettings: "core/fetchLosSettings",
            getPartnersByFeature: "integrationsSpec/getPartnersByFeature",
        }),
        selectAllProperties() {
            this.selectedPropertyIds = this.allProperties.map( prop => Number(prop.id) )
        },
        checkSupport() {
            let selectedPgPMS = this.$store.state.core.currentPG.spec.pms_name
            let enabled = false
            for ( let partner of this.partnersListByFeature ) {
                if ( partner.text === selectedPgPMS ) {
                    if ( partner.value === true ) {
                        enabled = true
                    }
                    break
                }		
            }
            this.enabled = enabled
	    },
        async setup() {
            await this.fetchLosSettings({pgId: this.globalSelectedPgId })    
            await this.getPartnersByFeature({ featureId: 'lengthOfStayManagement' })                
            this.checkSupport()
            if ( this.$store.state.core.selectedLosProperties == "all" ) {
                this.areAllPropertiesSelected = true 
            } else {
                this.selectedPropertyIds = this.$store.state.core.selectedLosProperties  
                this.areAllPropertiesSelected = false
            }
        },
        async saveLos() {
            let propertyIds = []
            if ( this.areAllPropertiesSelected == true ) {
                propertyIds = "all"
            } else {
                propertyIds = this.selectedPropertyIds
            }
            await this.saveLosSettings({pgId: this.globalSelectedPgId, propertyIds: propertyIds})    
        }
    },
    async created () {
        await this.setup()
    }
}
</script>