/**
 * Store for editor documents
 */ 


const yaml = require('js-yaml')
const hash = require('object-hash');


export default {
  namespaced: true,

  state: {
    codeToRender: null,
    codeToRenderLang: 'yaml',
    codeToRenderByKey: {}, 
    editModeIsOn: false,
    codeHasChanged: false,
    originalCode: null,
  },



  mutations: {

    setOriginalCode(state, args){
      var originalCode = args.originalCode;
      this.state.originalCode = hash(originalCode); //make an hash of the yaml before being loaded into the editor
      this.state.codeHasChanged = false;
    },

    unSetOriginalCode(state, args){
      this.state.originalCode = null;
      this.state.codeHasChanged = false;

    },

    changeCodeToRender(state, newCode){
      console.log("mutation started")
      this.state.codeToRender = newCode
    },

    cleanCodeToRender(state, args) {
      state.codeToRender = null;
    },

    checkOriginalCode(state, args){
      var frontEndCode = hash(args.frontEndCode);
      state.codeHasChanged = false;
      if(this.state.originalCode !== null && frontEndCode != this.state.originalCode){ //originalCode is set to null on save
        state.codeHasChanged = true;
      }
    }, 
    writeCodeToRender(state, args){
      state.codeToRender = yaml.dump(args, {ref: false});
    },
  }, // end mutations
  
  actions: {
    setOriginalCode(context, args){
      context.commit('setOriginalCode', args);
    },

    unSetOriginalCode(context, args){
      context.commit('unSetOriginalCode', args);
    },

    cleanCodeToRender(context, args){
      context.commit('cleanCodeToRender', args);
    },

    checkOriginalCode(context, args){
      context.commit('checkOriginalCode', args);
      return context.state.codeHasChanged;
    },    

    writeCodeToRender(context, args){
      context.commit('writeCodeToRender', args)
    },

    setCustomerBarSmooth(context, args){
      let codeToRenderObj = context.getters.codeToRenderObj
      if(args){
        codeToRenderObj.customer_bar_mix = codeToRenderObj.customer_bar_mix || {}
        codeToRenderObj.customer_bar_mix.customer_bar_smoothing = args
      } else {
        delete codeToRenderObj.customer_bar_mix?.customer_bar_smoothing
        if(Object.keys(codeToRenderObj.customer_bar_mix).length === 0){
          delete codeToRenderObj.customer_bar_mix
        }
      }
      context.commit('writeCodeToRender', codeToRenderObj)
    },
    setBasePriceMap(context, args){
      let codeToRenderObj = context.getters.codeToRenderObj
      if(args){
        codeToRenderObj.trendline = codeToRenderObj.trendline || {}
        codeToRenderObj.trendline.base_price_map = args
          .filter(o => o.custom != null && o.custom != "")
          .map(el => {
            return {
              property_ids: parseInt( el.property_id_named.split("_")[0] ),
              value: Number(el.custom)
            }
          })
      }
      if(!args || codeToRenderObj.trendline?.base_price_map.length === 0) {
        delete codeToRenderObj.trendline?.base_price_map
        if(Object.keys(codeToRenderObj.trendline).length === 0){
          delete codeToRenderObj.trendline
        }
      }
      
      context.commit('writeCodeToRender', codeToRenderObj)
    }
  }, //end of actions

  getters: {
    codeToRenderObj(state){
      return yaml.load(state.codeToRender)
    },
    customerBarMixObj(state, getters){
      return getters.codeToRenderObj?.customer_bar_mix
    },
    customerBarSmooth(state, getters){
      return getters.customerBarMixObj?.customer_bar_smoothing
    },
    trendlineObj(state, getters){
      return getters.codeToRenderObj?.trendline
    },
    basePriceMapObj(state, getters){
      return getters.trendlineObj?.base_price_map
    },
    globalObj(state, getters){
      return getters.codeToRenderObj?.global
    },
  },







}
