<template>
    <v-container fluid class="pa-4">
		<PropertiesTableEditor v-if="!!globalSelectedPgId"/>
		<SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
import PropertiesTableEditor from '../components/PropertiesTableEditor'
import SelectPgAlert from '@/components/utils/SelectPgAlert'
import {mapGetters} from "vuex"

export default {
  name: 'Property',
  components: {PropertiesTableEditor, SelectPgAlert},
  computed: {
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId",
    })
  }
}
</script>
