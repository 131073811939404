<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 col-md-12">
        <v-card>
          <v-card-title>
            Test Smartpaying emails
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field v-model="pgId" :rules="pgIdRules" label="Property set id" required></v-text-field>
              <v-select :items="mexKinds" v-model="selectedMex" :rules="selectedMexRules"
                label="Pick email template"></v-select>
              <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn :disabled="!valid" color="success" class="mr-4 mt-2" @click="validate" :loading="loading">
              Send message
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDialog ref="dialog" />
  </v-container>
</template>

<script>
// @ is an alias to /src


import Vue from 'vue'
import ConfirmDialog from '@/components/utils/ConfirmDialog'
export default Vue.extend({
  name: 'Messaging',
  components: { ConfirmDialog },
  data: function () {
    return {
      loading: false,
      mexKinds: [
        { text: 'Before checkin template', value: 'beforeCheckin' },
        { text: 'Before checkout template', value: 'beforeCheckout' },
        { text: 'After creation template', value: 'afterCreation' },
        { text: 'Deposit template', value: 'deposit' },
        { text: 'Upselling template', value: 'upsellingProposalCheckin' }
      ],
      valid: false,
      pgId: null,
      pgIdRules: [
        v => !!v || 'Please specify a pg id'
      ],
      selectedMex: null,
      selectedMexRules: [
        v => !!v || 'Please select a mex template'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ]
    }
  },
  methods: {
    async sendMessage() {
      this.loading = true
      const resp = await this.$store.dispatch('smartpaying/sendTestMex', { propertySetId: this.pgId, mexKind: this.selectedMex, email: this.email })
      if (resp.response?.status >= 400) {
        const options = { cardTextClass: "pa-4 orange accent-1--text", color: 'orange' }
        const title = "Error sending test message"
        const message = resp.response.data
        await this.$refs.dialog.open(title, message, options)
      }
      else {
        const options = { cardTextClass: "pa-4 accent-1--text", color: 'success' }
        const title = "Emails sent"
        const message = `We sent to ${this.email} the mail template for ${this.selectedMex} in both IT and EN versions`
        await this.$refs.dialog.open(title, message, options)
      }
      this.loading = false
    },
    validate() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        this.sendMessage()
      }
      else {
        console.log("Form is not valid")
      }
    }
  },
  mounted() {
  }
})
</script>

</script>
