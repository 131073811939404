<template>

  <v-item-group v-if="schema.type == 'coordinates'">
    <v-row>
      <v-col>
        <v-item>
          <v-text-field
            outlined
            dense
            v-model="coordinates.x"
            label="lat"
            @change="coordinatesChangeHandler"
          />
        </v-item>
      </v-col>
      <v-col>
        <v-item>
          <v-text-field
            outlined
            dense
            v-model="coordinates.y"
            label="long"
            @change="coordinatesChangeHandler"
          />
        </v-item>
      </v-col>
    </v-row>
  </v-item-group>

  <v-switch
    v-else-if="schema.type === 'boolean'"
    outlined
    dense
    v-model="computedValue[schema.key]"
    @change="boolChangeHandler"
    :label="schema.label"
    :disabled="schema.disabled"
  />

  <div v-else-if="schema.type === 'boolean_radio'">
    {{schema.label}}
    <v-radio-group
      v-model="computedValue[schema.key]"
      row
    >
      <v-radio
        label="true"
        :value="true"
      ></v-radio>
      <v-radio
        label="false"
        :value="false"
      ></v-radio>
      <v-btn
        class="pa-0 mr-1"
        icon
        small
        color="red"
        @click="cleanField"
        :disabled="computedValue[schema.key] === undefined"
      >
        <v-icon>
          fa fa-regular fa-circle-xmark fa-lg
        </v-icon>
      </v-btn>
    </v-radio-group>
  </div>

  <v-text-field
    v-else-if="schema.type == 'string'"
    outlined
    dense
    :rules="schema.customRules || [rules.required]"
    :value="computedValue[schema.key]"
    @change="changeHandler"
    :label="schema.label"
    :disabled="!!schema.disabled"
  />

  <v-text-field
    v-else-if="schema.type == 'number'"
    outlined
    dense
    :value="computedValue[schema.key]"
    @change="numberChangeHandler"
    :label="schema.label"
    :rules="schema.customRules || [rules.numeric]"
  />

  <v-textarea
    v-else-if="schema.type == 'textarea'"
    outlined
    dense
    :value="computedValue[schema.key]"
    @change="changeHandler"
    :label="schema.label"
  />

  <v-autocomplete
    v-else-if="schema.type == 'select_autocomplete'"
    :label="schema.label"
    :value="computedValue[schema.key]"
    outlined
    dense
    @change="changeHandler"
    :rules="schema.customRules || [rules.required]"
    :items="schema?.value"
    chips
    clearable
    :disabled="!!schema.disabled"
  />

  <v-select
    v-else-if="schema.type == 'select'"
    :label="schema.label"
    :value="computedValue[schema.key]"
    outlined
    dense
    @change="changeHandler"
    :rules="[rules.required]"
    :items="schema?.value"
    chips
  />

  <div v-else-if="schema.type === 'multiselect'">
    <v-select
      :label="schema.label"
      v-model.lazy="selectedItems"
      outlined
      dense
      @change="multiselectChangeHandler"
      :rules="[rules.atLeastOne]"
      :items="schema?.value"
      chips
      multiple
    >
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index < 2">
          <span>{{ item.text }}</span>
        </v-chip>
        <span
          v-if="index === 2"
          class="grey--text text-caption"
        >
          (+{{ selectedItems.length - 2 }} others)
        </span>
      </template>

      <template v-slot:prepend-item>
        <v-list-item
          v-bind:class="{'primary--text': selectedItems.length > 0}"
          ripple
          @mousedown.prevent
          @click="toggleItemsSelectAll()"
        >
          <v-list-item-action>
            <v-icon v-bind:class="{'primary--text': selectedItems.length > 0}">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
  </div>

  <div v-else-if="schema.type === 'typed_one_of'">
    <v-select
      :label=schema?.text
      :value="computedValue[schema.key]"
      :items="schemaDictKeys"
      outlined
      dense
      @change="onTypeChange"
    />
    <DynamicComponentCatalog
      v-if="computedValue[schema.key]"
      v-for="(obj, index) in schemaValue"
      v-bind:value="computedValue"
      @input="inputOneOfComponentHandler"
      :schema="schemaValue[index]"
      @clean-model="$emit('clean-model', $event)"
    />
  </div>

  <div v-else-if="schema.type === 'one_of'">
    <v-select
      :label=schema?.text
      v-model="getTypeByModelAndSchema"
      :items="schemaDictKeys"
      outlined
      dense
      @change="onTypeChange"
    />
    <DynamicComponentCatalog
      v-if="getTypeByModelAndSchema"
      v-for="(obj, index) in schemaValue"
      v-bind:value="computedValue"
      @input="inputOneOfComponentHandler"
      :schema="schemaValue[index]"
      @clean-model="$emit('clean-model', $event)"
    />
  </div>

  <div v-else-if="schema.type === 'all'">
    <template v-for="(obj, index) in schema.value">
      <DynamicComponentCatalog 
        v-bind:value="computedValue"
        @input="inputOneOfComponentHandler"
        :schema="schema.value[index]"
      />
    </template>
  </div>

  <div v-else-if="schema.type === 'map'">
    <v-card>
      <v-card-subtitle>
        {{schema.label}}
      </v-card-subtitle>
      <v-card-text>
        <v-row v-for="(obj, index) in mapArray">
          <v-col>
            <v-text-field
              outlined
              dense
              :value="mapArray[index].key"
              @change="addRateMap(index, 'key', $event)"
              label="key"
              :rules="[rules.integer]"
              :disabled="schema.enableIf && !computedValue[schema.enableIf]"
            />
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              :value="mapArray[index].value"
              @change="addRateMap(index, 'value', $event)"
              label="value"
              :rules="[rules.numeric]"
              :disabled="schema.enableIf && !computedValue[schema.enableIf]"
            />
          </v-col>
          <v-col
            cols="2"
          >
            <v-btn
              v-if="index !== mapArray.length - 1"
              block
              depressed
              plain
              @click="deleteLineFromMap(index)"
            >
              <v-icon >fa-solid fa-trash-can</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
import FormSchemaManager from '@/components/form/FormSchemaManager'
// import { numeric } from '@vuelidate/validators'

export default {
  name: "DynamicComponentCatalog",
  props: ['value', 'schema', 'basetype'],
  components: {},
  data() {
    return {
      selectedType: null,
      oldSelectedType: null,
      selectedItemsArrBaseObj: null,
      output: null,
      rules: {
        required: value => !!value || 'Required.',
        atLeastOne: value => Array.isArray(value) && value.length > 0 || 'Select at least one',
        numeric: value => !isNaN(value) || 'Must be a number.',
        integer: value => Number.isInteger(value) || 'Must be an integer'
      },
      mapArraySupport: [],
      coordinates: {
        x: null,
        y: null
      }
    }
  },
  computed: {
    computedValue: {
      get: function(){
        return this.value
      },
      set: function(val){
        this.$emit('input', val)
      }
    },
    selectedItems: {
      get: function(){
        return this.selectedItemsArr
      },
      set: function(newValue){
        this.selectedItemsArr = newValue
        if(!this.computedValue[this.schema.key]){
          this.$set(this.computedValue, this.schema.key, [])
        }

        if(this.schema.customSetSelectedItems){
          return this.schema.customSetSelectedItems(this)
        }

        if(this.allItemsSelected){
          this.$delete(this.computedValue, this.schema.key)
        } else if (this.selectedItems.length === 1){
          this.computedValue[this.schema.key] = this.selectedItems[0]
        } else {
          this.computedValue[this.schema.key] = this.selectedItems
        }
      }
    },
    allItemsSelected(){
      // return this.value[this.schema.key]?.length === this.schema.value.length
      return this.selectedItems.length === this.schema.value.length
    },
    someItemsSelected(){
      // return this.value[this.schema.key]?.length === this.schema.value.length
      return this.selectedItems.length > 0 && !this.allItemsSelected
    },
    icon () {
      if (this.allItemsSelected) return 'fas fa-check-square'
      if (this.someItemsSelected) return 'fas fa-minus-square'
      return 'far fa-square'
    },
    schemaDictKeys(){
      return Object.keys(this.schema.dict).map(k => {
        let itemObj = {
          text: k,
          value: k
        }

        if(this.schema.dict[k].disabled){
          itemObj.disabled = this.schema.dict[k].disabled && this.computedValue[this.schema.key] !== k
        }

        return itemObj
      })
    },
    selectedSchemaDict(){
      if(this.getTypeByModelAndSchema){
        return this.schema.dict[this.getTypeByModelAndSchema]
      }
    },
    schemaValue(){
      return this.selectedSchemaDict?.value
    },
    getTypeByModelAndSchema: {
      get: function(){
        if(this.schema.type === 'typed_one_of'){
          this.selectedType = this.computedValue[this.schema.key]
          this.oldSelectedType = this.selectedType
        }
        if(this.basetype){
          this.selectedType = this.basetype
        } else if(!this.selectedType){
          this.selectedType = this.getType()
          if(this.selectedType){
            this.emitSelectedType()
          }
        }
        return this.selectedType
      },
      set: function(newValue){
        this.oldSelectedType = this.selectedType
        this.selectedType = newValue
        this.emitSelectedType()
      }
    },
    selectedItemsArr: {
      get: function(){
        if(this.schema.type !== 'multiselect'){
          return
        }
        if(this.selectedItemsArrBaseObj){
          return this.selectedItemsArrBaseObj
        }
        let yamlItems = this.computedValue[this.schema.key]
        if(this.schema.explicitAllSelected && yamlItems === 'all'){
          const valuesToAdd = this.schema.value.map(i => i.value)
          return valuesToAdd
        } else if(!this.schema.explicitAllSelected && !yamlItems){
          const valuesToAdd = this.schema.value.map(i => i.value)
          return valuesToAdd
        }
        if(!yamlItems){
          return []
        }
        if(!Array.isArray(yamlItems)){
          return [yamlItems]
        }
        return yamlItems
      },
      set: function(newValue){
        this.selectedItemsArrBaseObj = newValue
      }
    },
    mapArray: {
      get: function(){
        let obj = this.computedValue[this.schema.key]
        let outputObj = []
        if(obj){
          Object.keys(obj).forEach(k => {
            outputObj.push({
              key: Number(k),
              value: obj[k]
            })
          })
        }

        if(outputObj.every(o => o.key != null && o.value != null)){
          outputObj.push({
            key: null,
            value: null
          })
        }
        this.mapArraySupport = outputObj
        return outputObj
      },
      set: function(val){
        let obj = {}
        val.filter(o => o.key && o.value).forEach(o => {
          obj[o.key] = o.value
        })
        if(Object.keys(obj).length === 0){
          return
        }
        this.changeHandler(obj)
      }
    }
  },
  methods: {
    multiselectChangeHandler(){
      this.$emit('input', this.computedValue)
    },
    inputOneOfComponentHandler(obj){
      this.$emit('input', obj)
    },
    numberChangeHandler(obj){
      let objToSend = {...this.computedValue}
      if(this.schema.key){
        if(obj === ""){
          delete objToSend[this.schema.key]
        } else {
          objToSend[this.schema.key] = Number(obj)
        }
      }
      this.$emit('input', objToSend)
    },
    boolChangeHandler(obj){
      if(this.schema.fieldsToClean && !obj){
        this.schema.fieldsToClean.forEach(k => {
          this.$delete(this.computedValue, k)
        })
      }
      let objToSend = {...this.computedValue}
      if(this.schema.key){
        if(!obj || (typeof obj === 'object' && Object.keys(obj).length === 0)){
          delete objToSend[this.schema.key]
        } else {
          objToSend[this.schema.key] = obj
        }
      }
      this.$emit('input', objToSend)
    },
    changeHandler(obj){
      let objToSend = {...this.computedValue}
      if(this.schema.key){
        if(!obj || (typeof obj === 'object' && Object.keys(obj).length === 0)){
          delete objToSend[this.schema.key]
        } else {
          objToSend[this.schema.key] = obj
        }
      }
      this.$emit('input', objToSend)
    },
    coordinatesChangeHandler(){
      this.changeHandler(this.coordinates)
    },
    cleanModel(obj = {}){
      Object.keys(obj).forEach(k => {
        if(typeof obj[k] === 'object'){
          this.cleanModel(obj[k])
        } else {
          this.$delete(this.computedValue, k)
        }
      })
    },
    toggleItemsSelectAll(){
      if(this.schema.toggleItemsSelectAll){
        return this.schema.toggleItemsSelectAll(this, this.multiselectChangeHandler)
      }
      if(this.allItemsSelected){
        this.selectedItems = []
      } else {
        const valuesToAdd = this.schema.value.map(i => i.value)
        this.selectedItems = valuesToAdd
      }
      this.multiselectChangeHandler()
    },
    onTypeChange(newVal){
      this.selectedType = newVal
      if(this.schema.key){
        this.computedValue[this.schema.key] = newVal
      }
      if(this.oldSelectedType){
        const oldSchema = new FormSchemaManager(this.schema?.dict[this.oldSelectedType])
        const keysToRemove = oldSchema?.getModelStructureBySchema()
        this.cleanModel(keysToRemove)
      }
      this.$emit('input', this.computedValue)
      this.oldSelectedType = this.selectedType
    },
    checkArraysEquality(arr1, arr2){
      return arr1.every(i => arr2.includes(i)) && arr2.every(i => arr1.includes(i))
    },
    getType(){
      let schemaManager
      let referenceKeys
      let actualKeys = Object.keys(this.computedValue)

      if(this.schema?.type !== 'one_of' || actualKeys.length === 0){
        return
      }

      for(const [key, value] of Object.entries(this.schema.dict)){
        schemaManager = new FormSchemaManager(value)
        referenceKeys = Object.keys(schemaManager.getModelStructureBySchema())

        /*
TODO:
check that this condition really is consistent with the results needed
*/
        if(actualKeys.every(k => referenceKeys.includes(k)) || referenceKeys.every(k => actualKeys.includes(k))){
          return key
        }
      }
      return null
    },
    emitSelectedType(){
      console.log("emit selectedType")
      this.$emit('selected-type', this.selectedType)
    },
    deleteLineFromMap(index){
      delete this.mapArray[index]
      let newArray = [...this.mapArray]
      newArray = newArray.filter(e => !!e).filter(o => o.key && o.value)
      this.mapArray = newArray
    },
    cleanField(){
      this.$delete(this.computedValue, this.schema.key)
    },
    addRateMap(index, objectKey, value){
      this.mapArraySupport[index][objectKey] = Number(value)
      const objToCheck = this.mapArraySupport[index]

      if(objToCheck.key == null || objToCheck.value == null){
        return
      }

      this.mapArray = [...this.mapArraySupport]
    }
  },
}
</script>
