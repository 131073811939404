<template>
    <v-container fluid class="pa-4">
        <!-- Check if a pg is selected -->
        <div v-if="!!globalSelectedPgId && !currentParent?.length">
            <!-- text field (only integer) + button to set the pgId to copy from. Then trigger the getPredictedPropsFromPg -->
             <!-- they should be side by side -->
            <v-row>
                <v-col class="pa-0">
                    <v-autocomplete
                        outlined
                        dense
                        label="PG ID to copy FROM"
                        v-model="otherPgId"
                        :items="pgsListWithLifecycleStatus"
                        item-text="searchValue"
                        @change="getPredictedPropsFromPg($event)"
                    >
                        <template v-slot:item="data" v-text="data.item.searchValue">
                            <span>
                            {{ data.item.id }} {{ data.item.name }} - <span :class="data.item.textColor">{{ data.item.status }}</span>
                            </span>
                        </template>
                        <template v-slot:selection="data" v-text="data.item.searchValue">
                            <span>
                            {{ data.item.id }} {{ data.item.name }} - <span :class="data.item.textColor">{{ data.item.status }}</span>
                            </span>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>

            <!-- for each predicted prop, show id + name and a dropdown with all other props -->
            <v-row v-for="p in this.predictedProps" :key="p.metadata.id">
                <v-col cols="4" class="pa-0 text-right">
                    <div class="text-right">{{ p.metadata.id_name }}</div>
                </v-col>
                <v-col cols="8" class="pa-0">
                    <v-select
                        :items="otherProps"
                        item-text="metadata.id_name"
                        item-value="metadata.id"
                        label="Select Property"
                        v-model="p.metadata.matching_id"
                        dense
                        outlined
                        clearable
                    ></v-select>                   
                </v-col>
            </v-row>

            <v-btn @click="copyBaseprice()">Copy Baseprice</v-btn>
        </div>

        <v-alert type="warning" v-else-if="!!globalSelectedPgId && !!currentParent?.length">
            Strategy must be managed inside the parent PG: {{ currentParent.join(",") }}
        </v-alert>

        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
// modules import
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

// components import

// views import
export default {
    components: {},
    computed: {
        ...mapState({
            currentParent: state => state.core.currentParent,
            currentStrategyPS: state => state.core.currentStrategyPS,
        }),
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
            pgsListWithLifecycleStatus: "core/pgsListWithLifecycleStatus"
        }),
    },
    data() {
        return {
            predictedProps: [],
            otherProps: [],
            otherPsId: null,
            otherPgId: null,
        }
    },
    async mounted() {
        this.predictedProps = await this.getPredictedProps(this.currentStrategyPS.metadata.id)
    },
    watch: {
        async globalSelectedPgId(newVal, oldVal) {
            this.predictedProps = await this.getPredictedProps(this.currentStrategyPS.metadata.id)
        },
    },
    methods: {
        async getPredictedProps (psId) {
            const res = await this.$store.state.api.api.get("propertyset/v1", `/propertyset/${psId}/property`, {}, {}, {})
            const props = res.items.filter(p => p.spec.graph_json && p.spec.graph_json.includes('NotebookPredictedPrice'))
            props.forEach(p => p.metadata.id_name = `${p.metadata.id} - ${p.metadata.name}`)
            props.forEach(p => p.metadata.matching_id = null)
            return props
        },
        async getPredictedPropsFromPg () {
            // reset the matching id when changing pg
            for ( let p of this.predictedProps ) {
                p.metadata.matching_id = null
            }
            let res = await this.$store.state.api.api.get("propertyset/v1", `/propertyset/propertygroup/${this.otherPgId}/kind/Strategy`, {}, {}, {})
            const psId = res.items[0].metadata.id
            this.otherPsId = psId
            res = await this.$store.state.api.api.get("propertyset/v1", `/propertyset/${psId}/property`, {}, {}, {})
            const props = res.items.filter(p => p.spec.graph_json && p.spec.graph_json.includes('NotebookPredictedPrice'))
            props.forEach(p => p.metadata.id_name = `${p.metadata.id} - ${p.metadata.name}`)
            this.otherProps = props
            return props
        },
        async copyBaseprice () {
            
            try {
                // fetch the current base price for the ps that we are copying from
                let res = await this.$store.state.api.api.get("pricelist/v2", `/Baseprice/propertyset.${this.otherPsId}-master`, {}, {}, {})
                let currentBpSource = res.items[0]

                // fetch the current base price for the ps that we are copying to
                res = await this.$store.state.api.api.get("pricelist/v2", `/Baseprice/propertyset.${this.currentStrategyPS.metadata.id}-master`, {}, {}, {})
                let currentBpTarget = res.items[0]

                let bpToApply = structuredClone(currentBpSource)
                bpToApply.metadata.key = `propertyset.${this.currentStrategyPS.metadata.id}-master`
                delete bpToApply.metadata.version
                delete bpToApply.updatedAt
                // spec: copy the edits from the target base price
                bpToApply.spec.edits = currentBpTarget.spec.edits

                const sourceValue = currentBpSource.value
                const targetValue = currentBpTarget.value

                const newValue = {
                    date: [],
                    price: [],
                    propertyId: [],
                }

                const propertiesOverwritten = []
                let editMessage = [`Copying base prices from strategy ps ${this.otherPsId} / pg ${this.otherPgId} to the following properties:`]

                for (let prop of this.predictedProps) {

                    const matching_id = Number(prop.metadata.matching_id)
                    let found = false
                    // search for the prices only if the property has a matching id
                    if ( matching_id != null ) {
                        for (let i=0; i<sourceValue.date.length; i++) {
                            if (sourceValue.propertyId[i] === matching_id) {
                                newValue.date.push(sourceValue.date[i])
                                newValue.price.push(sourceValue.price[i])
                                newValue.propertyId.push(Number(prop.metadata.id))
                                found = true
                            }
                        }
                    }
                    // if the property was not found or it doesn't have a matching id, copy the prices from the original base price
                    if ( found == false ) {
                        for ( let i = 0 ; i < targetValue.date.length ; i++ ) {
                            if ( targetValue.propertyId[i] === Number(prop.metadata.id) ) {
                                newValue.date.push(targetValue.date[i])
                                newValue.price.push(targetValue.price[i])
                                newValue.propertyId.push(Number(prop.metadata.id))
                            }
                        }
                    } else {
                        propertiesOverwritten.push(prop.metadata.id)
                        editMessage.push(`from p ${prop.metadata.matching_id} to ${prop.metadata.id}`)
                    }
                }

                bpToApply.value = newValue
                bpToApply.spec.edits.push({
                    createdAt: new Date().toISOString(),
                    kind: "copy-from-ps",
                    value: editMessage,
                    fromPropertySetId: this.otherPsId,
                    fromPropertyGroupId: this.otherPgId,
                    user: this.$store.state.auth.username,
                    propertyIds: propertiesOverwritten
                })

                res = await this.$store.state.api.api.put("pricelist/v2", ``, {}, bpToApply, {})
                
                if ( res?.response?.status === 500 ) {
                    this.$store.dispatch("alertSystem/newError", {
                        message: `Error while copying Base Prices`,
                        timeout: -1
                    })
                } else {
                    this.$store.dispatch("alertSystem/newSuccess", {
                        message: `Base Prices copied successfully`
                    })
                }
            } catch (e) {
                
                this.$store.dispatch("alertSystem/newError", {
                    message: `Error while copying Base Prices: ${e}`,
                    timeout: -1
                })
            }
        }
    }
}
</script>