<template>
  <div>
  <v-card tile class="mt-2 card-ui elevation-2">
    <v-toolbar dense elevation="0" class="card-ui" style="border-bottom: 1px solid #3a3a3a;">

      <v-card-title class="ml-0 pl-0 overline">
        {{composedPipelineBindName(pipelineBind)}}
      </v-card-title>

    </v-toolbar>

    <v-card-text>
      <v-row style="min-height: 80px;">
        <v-col class="col-11">
          <v-chip small :class="colorForLabel(l) + ' mt-1 ml-2'" v-for="l in pipelineBind.resource.metadata.labels" v-if="l !== 'standard'">
            <v-icon x-small class="pr-2">fa-solid fa-tag</v-icon><b>{{l}}</b>
          </v-chip>
          <v-chip outlined small class="yellow  mt-1 ml-2" v-if="pipelineBind.resource.metadata.root !== null && pipelineBind.resource.metadata.root !== undefined"><v-icon x-small class="pr-2">fa-solid fa-link</v-icon>{{pipelineBind.resource.metadata.root}}</v-chip>
        </v-col>
        <v-col class="col-1">

        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions :class="{'grey darken-3': darkMode}">
      <!-- EDIT -->
      <v-tooltip bottom v-if="!pipelineBind.resource.metadata.labels.includes('expertuser')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-bind="attrs"
          v-on="on"
          :color="!pipelineBind.resource.spec.editable ? 'grey' : 'pink'" fab x-small

          @click="[editPipelineBind(pipelineBind), $emit('openPipelineBindEditor')]"
          class="elevation-0">
            <v-icon small>fa-solid fa-edit</v-icon>
          </v-btn>
        </template>
        <span>Edit PipelineBind</span>
      </v-tooltip>
      <v-spacer/>
      <!-- Exper user info -->
      <div v-if="currentPG !== null && pipelineBind.resource.metadata.labels.includes('expertuser')">
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="800"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              text
              v-bind="attrs"
              v-on="on"
              dense
            >
              Expert User Params
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar
                color="primary"
                dark
              >
                Expert User Params
              </v-toolbar>

              <v-card-text>
                <ExpertuserInfo :customerStratData="currentPG.spec.customer_strategy_params" />
              </v-card-text>

              <v-card-actions class="justify-end">
                <v-btn
                  text
                  @click="dialog.value = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

      </div>
      <v-spacer v-if="currentPG !== null"/>
      <div>
        <!-- CLONE -->
        <v-tooltip bottom v-if="!pipelineBind.resource.metadata.labels.includes('expertuser')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="blue" fab x-small @click="cloneOverlay = !cloneOverlay" class="elevation-0 ml-2">
              <v-icon small>fa-solid fa-copy</v-icon>
            </v-btn>
          </template>
          <span>Clone PipelineBind</span>
        </v-tooltip>
        <!-- RUN -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="green" fab x-small class="mt-0 ml-2 elevation-0" @click="run">
              <v-icon small>fa-solid fa-play</v-icon>
            </v-btn>
          </template>
          <span>Run PipelineBind</span>
        </v-tooltip>
      </div>

      <!-- Clone overlay -->
      <v-overlay :value="cloneOverlay">
        <v-card class="mx-auto pa-5" max-width="500" >
          <v-card-text>
            <p class="text-h4 text--primary">
              Clone Pipelinebind
            </p>
            <div class="text--primary">
              Insert the name of the new Pipelinebind (min 5 characters):
            </div>

            <v-text-field label="New PipelineBind name" v-model="newPbName">

            </v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :disabled="newPbName.length < 5"
              color="red"
              @click="clone"
            >
              Clone
            </v-btn>
            <v-btn
              color="primary"
              @click="cloneOverlay = !cloneOverlay"
            >
              Cancel
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-overlay>

    </v-card-actions>
  </v-card>
  <ConfirmDialog ref="confirm" />
  </div>


</template>



<script>


import ConfirmDialog from '@/components/utils/ConfirmDialog'
import ExpertuserInfo from './ExpertuserInfo.vue';

// @ is an alias to /src
import md5 from 'md5'
import { mapGetters, mapState } from 'vuex';
const yaml = require('js-yaml');

export default {
  name: 'PipelineBind',
  props: [
    'pipelineBind',
    'notMigrated'
  ],
  components: {
    ConfirmDialog,
    ExpertuserInfo,
  },
  data: function (argument) {
    return {
      originalMD5Hash: null,
      currentMD5Hash: null,
      cloneOverlay: false,
      newPbName: ""
    }
  },
  watch: {
    'currentConfigMap' (newVal, oldVal) {
      this.currentMD5Hash = md5(this.pipelineBind.resource)
    },
    'currentPipeline' (newVal, oldVal) {
      this.currentMD5Hash = md5(this.pipelineBind.resource)
    },
    'currentLabels' (newVal, oldVal) {
      this.currentMD5Hash = md5(this.pipelineBind.resource)
    },
  },
  computed: {
    ...mapState({
      darkMode: state => state.ui.darkMode,
      currentPG: state => state.core.currentPG,
      currentStrategyPSid: state => state.core.currentStrategyPS.metadata.id,
      strategy: state => state.pipelineV1.currentStrategy,
      pipelineBinds: state => state.pipelineV1.currentPipelineBinds,
      pipelines: state => state.pipelineV1.pipelines,
      configMaps: state => state.pipelineV1.configMaps,
    }),
    currentConfigMap () {
      return this.pipelineBind.resource.spec.configMaps
    },
    currentPipeline () {
      return this.pipelineBind.resource.spec.pipeline
    },
    currentLabels () {
      return this.pipelineBind.resource.metadata.labels
    },
    getClonePrefix() {
      return `${this.currentPipeline}.${this.currentStrategyPSid}-`
    }
  },
  methods: {
    editPipelineBind(pipelineBind){
      this.$store.commit('pipelineV1/resetSelectedPipelineBind', {value: pipelineBind});
      var originalCode = yaml.dump(pipelineBind.resource.spec.customConfigMap.parameters, {ref: false});
      this.$store.dispatch('editor/setOriginalCode', {originalCode: originalCode});
    },
    colorForLabel (l) {
      const hashMap = {
        "master": "red",
        "standard": "grey",
        "main": "orange",
        "smartpricing": "orange",
        "postprocess": "blue",
        "predict": "blue",
        "preprocess": "cyan",
        "expert": "purple",
        "expertuser": "purple",
      }

      return hashMap[l.toLowerCase()] || "cyan"
    },
    composedPipelineBindName (pipelineBind) {
      let name = pipelineBind.resource.metadata.name.split(this.currentStrategyPSid)[1]
      if (name.startsWith("-") || name.startsWith(".")) {
        name = name.substring(1)
      }
      else if (name === "") {
        name = "standard"
      }
      return name
    },
    async run () {
      let runResult = await this.$store.dispatch('pipelineV1/runPipelinebind', {
        workspace: this.pipelineBind.resource.metadata.workspace,
        name: this.pipelineBind.resource.metadata.name,
        forceCache: true,
      })

    },
    async clone() {

      const pbToCopy = this.pipelineBind.resource.metadata.name
      //prefix is predict.standard.XXX or preprocess.standard.XXX
      const pbNewName = this.getClonePrefix + this.newPbName
      const psId = this.currentStrategyPSid

      let res = await this.$store.dispatch('pipelineV1/clonePipelineBind', {
        psId: psId,
        sourcePb: pbToCopy,
        targetPb: pbNewName
      })

      if ( res.error == true ) {
        this.$store.dispatch("alertSystem/newError", {
          message: res.messages,
          title: `Error during the clonation of the Pipelinebind`,
        })

      } else {
        this.$store.dispatch("alertSystem/newSuccess", {
          message: `Pipelinebind succesfully cloned`,
          title: `Clone`,
        })
        this.cloneOverlay = !this.cloneOverlay
      }
    }
  },
  beforeMount () {
    this.originalMD5Hash = md5(JSON.stringify(this.pipelineBind.resource))
    this.currentMD5Hash = md5(JSON.stringify(this.pipelineBind.resource))
  }
}
</script>
