/**
 * Store the apps configurations
 */

export default {
    namespaced: true,

    state: {

        /**
         *  These items will appear in the
         *  mini navigation drawer on the left
         */
        default: 'core',
        apps: {

            // !! The key and the Object.name must match

            /** APP Core **/
            core: {
                visible: true,
                name: 'core',
                prettyName: 'Core',
                icon: 'fa-solid fa-database',
                subresources: [
                    {
                        name: 'propertyGroup',
                        component: 'PropertyGroup',
                        prettyName: 'Property Group',
                        link: '/core/propertyGroup/config',
                        description: 'View Property Group',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "addPropertyGroup",
                        component: "AddPropertyGroup",
                        prettyName: "Add PG",
                        link: "/core/propertyGroup/add",
                        description: "Add a new Property Group",
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'property',
                        component: 'Property',
                        prettyName: 'Property',
                        link: '/core/property/config',
                        description: 'Configure Property',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "addProperty",
                        component: "AddProperty",
                        prettyName: "Add Property",
                        link: "/core/propertyGroup/property/add",
                        description: "Add a new Property",
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'manageUsers',
                        component: 'ManageUsers',
                        prettyName: 'Manage Users',
                        link: '/core/users/config',
                        description: 'Manage Users',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'addUser',
                        component: 'AddUser',
                        prettyName: 'Add User',
                        link: '/core/users/add',
                        description: 'Add new Users',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "manageRates",
                        component: "ManageRates",
                        prettyName: "Manage Rates",
                        link: "/core/rates",
                        description: "Manage Rates",
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "manageSubscriptions",
                        component: "ManageSubscriptions",
                        prettyName: "Manage Webhooks Subscriptions",
                        link: "/core/subscriptions",
                        description: "Manage Webhooks Subscriptions",
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'customersMap',
                        component: 'CustomersMap',
                        prettyName: 'Customers Map',
                        link: '/propertyGroupsMap/customersMap/config',
                        description: 'Customers location',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "tokenGeneration",
                        component: "TokenGeneration",
                        prettyName: "Token Generator",
                        link: "/core/tokengeneration",
                        description: "Generate token to access user dashboard",
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "oauthLink",
                        component: "OauthPage",
                        prettyName: "Oauth Link",
                        link: "/core/oauthlink",
                        description: "Generate Oauth Link",
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "losManagement",
                        component: "LosManagement",
                        prettyName: "LoS management",
                        link: "/core/losmanagement",
                        description: "Manage the settings of the Lenght of Stay",
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "propertySet",
                        component: "PropertySet",
                        prettyName: "PropertySet",
                        link: "/core/propertySet/config",
                        description: "View PropertySets",
                        hidePropertyGroupSelectionAppBar: true,
                    },
                ]
            },


            /** APP Graph **/

            graph: {
                visible: true,
                name: 'graph',
                prettyName: 'Graph',
                icon: 'fa-solid fa-circle-nodes',
                subresources: [
                    {
                        name: 'propertyGroup',
                        component: 'NewPropertyGroup',
                        prettyName: 'PropertyGroup',
                        link: '/graph/propertygroup/configv2',
                        description: 'Configure Property Group',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'holiday',
                        component: 'Holiday',
                        prettyName: 'Holidays',
                        link: '/graph/holiday/config',
                        description: 'Create and configure Holidays',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "holidayOverview",
                        component: 'HolidaysOverview',
                        prettyName: 'Holidays Overview',
                        link: '/graph/holiday/filter',
                        description: 'See all holidays and filter them!',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'events',
                        component: 'Events',
                        prettyName: 'Events',
                        link: '/graph/events/config',
                        description: 'Create and configure Events',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'customRootEvents',
                        component: 'CustomRootEvents',
                        prettyName: 'CustomRootEvents',
                        link: '/graph/CustomRootEvents/config',
                        description: 'See events created by the customers',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "eventsOverview",
                        component: "EventsOverview",
                        prettyName: "Events Overview",
                        link: "/graph/eventsoverview/config",
                        description: "See all events and filter them!",
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    }
                ]
            },

            integrations: {
                visible: true,
                name: "integrations",
                prettyName: "Integrations",
                icon: "fa-solid fa-vector-square",
                subresources: [
                    {
                        name: "integrationsSpec",
                        component: "IntegrationsSpec",
                        prettyName: "Integrations Spec",
                        link: "/integrations/spec",
                        description: "View Integrations Specifications",
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "managePartners",
                        component: "ManagePartners",
                        prettyName: "Manage Partners",
                        link: "/integrations/managepartner",
                        description: "Insert/Edit Partners",
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "versori",
                        component: "Versori",
                        prettyName: "Versori",
                        link: "/integrations/versori",
                        description: "Versori",
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    }
                ]
            },

            integrationsStatus: {
                visible: true,
                name: "integrationsStatus",
                prettyName: "Integrations Status",
                icon: "fa-solid fa-list-check",
                subresources: [
                    {
                        name: "integrationsStatus",
                        component: "IntegrationsStatus",
                        prettyName: "Integrations Status ",
                        link: "/integrationsStatus/status",
                        description: "View Integrations Status",
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "integrationsStatusOverview",
                        component: "IntegrationsStatusOverview",
                        prettyName: "Integrations Status Overview",
                        link: "/integrationsStatus/statusoverview",
                        description: "View Integrations Status Overview",
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    }
                ]
            },

            /** APP GPCS **/

            gpcs: {
                visible: true,
                appbar: true,
                name: 'gpcs',
                prettyName: 'Gpcs',
                icon: 'fa-solid fa-weight-hanging',
                subresources: [
                    {
                        name: 'runJob',
                        component: 'RunJob',
                        prettyName: 'Run',
                        link: '/gpcs/run',
                        description: 'Run GPCS jobs',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'resetSentPrice',
                        component: 'ResetSentPrice',
                        prettyName: 'Reset sent price',
                        link: '/gpcs/resetsentprice',
                        description: `Reset the last send with success`,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: "moveReservations",
                        component: "MoveReservations",
                        prettyName: "Move Reservations",
                        link: "/gpcs/movereservations",
                        description: "Move reservations",
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    //{
                    //    name: "deleteReservations",
                    //    component: "DeleteReservations",
                    //    prettyName: "Delete Reservations",
                    //    link: "/gpcs/deletereservations",
                    //    description: "Delete pg reservations",
                    //},
                    {
                        name: "rawReservations",
                        component: "RawReservations",
                        prettyName: "Raw Reservations",
                        link: "/gpcs/rawreservations",
                        description: "Raw reservations",
                        hidePropertySetSelectionAppBar: true,
                    },
                    /*{
                        name: "diffReservations",
                        component: "DiffReservations",
                        prettyName: "Diff Reservations",
                        link: "/gpcs/diffReservations",
                        description: "Diff reservations",
                    },*/
                    /* {
                      name: "resetAckSysdat",
                      component: "ResetAckSysdat",
                      prettyName: "Reset Sysdat Ack",
                      link: "/gpcs/resetsysdat",
                      description: "Allows the reset of sysdat ack"
                    } */
                ]
            },

            /** APP Logs **/

            logs: {
                visible: true,
                appbar: true,
                name: 'logs',
                prettyName: 'Logs',
                icon: 'fa-solid fa-toilet-paper',
                subresources: [
                    {
                        name: 'systemLogs',
                        component: 'System',
                        prettyName: 'System logs',
                        link: '/logs/system',
                        description: 'System logs',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'dashboard',
                        component: 'Dashboard',
                        prettyName: 'Dashboard',
                        link: '/logs/dashboard',
                        description: 'Events Dashboard',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'computedPricesLogs',
                        component: 'Computed',
                        prettyName: 'Computed prices logs',
                        link: '/logs/computedprices',
                        description: 'Computed prices logs',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'predictLogs',
                        component: 'PredictLogs',
                        prettyName: 'Predict status',
                        link: '/logs/predict/status',
                        description: 'Predict pipeline logs',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                ]
            },

            /** APP Alarms **/

            alarms: {
                visible: true,
                appbar: false,
                name: 'alarms',
                prettyName: 'Alarms',
                icon: 'fa-solid fa-bell',
                subresources: [
                    {
                        name: 'alarmReservation',
                        component: 'AlarmReservation',
                        prettyName: 'Alarm Reservation',
                        link: '/alarms/AlarmReservation',
                        description: 'Alarm Reservation dashboard',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'alarmRetention',
                        component: 'AlarmRetention',
                        prettyName: 'Alarm Retention',
                        link: '/alarms/AlarmRetention',
                        description: 'Alarm Retention Report',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'alarmOccupancy',
                        component: 'AlarmOccupancy',
                        prettyName: 'Alarm Occupancy',
                        link: '/alarms/AlarmOccupancy',
                        description: 'Alarm Occupancy dashboard',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'launcher',
                        component: 'ChecksLauncher',
                        prettyName: 'Checks Launcher',
                        link: '/alarms/launcher',
                        description: 'Launch checks manually',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'triggered',
                        component: 'AlarmsTriggered',
                        prettyName: 'Alarms Triggered',
                        link: '/alarms/triggered',
                        description: 'Alarms triggered from checks',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'jobs',
                        component: 'JobsExecuted',
                        prettyName: 'Jobs Executed',
                        link: '/alarms/jobs',
                        description: 'Jobs executed from checks',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                ]
            },

            /** APP Strategy Manager **/
            strategy: {
                visible: true,
                name: 'strategy',
                prettyName: 'Strategy',
                icon: 'fa-solid fa-chess',
                subresources: [
                    {
                        name: "manageStrategy",
                        component: 'ManageStrategy',
                        prettyName: 'Manage Strategy',
                        link: '/strategy/manage',
                        description: 'Manage PG Strategy',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'typeform',
                        component: 'Typeform',
                        prettyName: 'Typeform',
                        link: '/strategy/typeform',
                        description: 'Import row from Typeform sheet',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'copyBaseprice',
                        component: 'CopyBaseprice',
                        prettyName: 'Copy Baseprice',
                        link: '/strategy/copyBaseprice',
                        description: 'Copy Baseprice',
                        hidePropertySetSelectionAppBar: true,
                    }
                ]
            },

            /** APP Analytics **/
            analytics: {
                visible: true,
                name: 'analytics',
                prettyName: 'Analytics',
                icon: 'fa-solid fa-chart-line',
                //ui: {
                //  hideSidebar: true
                //},
                subresources: [
                    {
                        name: 'analyticsDs',
                        component: 'Analytics',
                        prettyName: 'Analytics',
                        link: '/analytics/datastudio',
                        description: 'Data Studio analytics',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'analyticsReservations',
                        component: 'Reservations',
                        prettyName: 'Reservations',
                        link: '/analytics/reservations',
                        description: 'Reservations dashboard',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'analyticsPerformance',
                        component: 'Performance',
                        prettyName: 'Performance',
                        link: '/analytics/performance',
                        description: 'Performance monitor',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'manageMarkets',
                        component: 'ManageMarkets',
                        prettyName: 'Market Analytics',
                        link: '/analytics/marketsconfig',
                        description: 'Manage Analytical Markets',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'marketIntelligence',
                        component: 'MarketIntelligence',
                        prettyName: 'Market Intelligence',
                        link: '/analytics/MarketIntelligence',
                        description: 'Market Intelligence monitor',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'earlySales',
                        component: 'EarlySales',
                        prettyName: 'Early Sales',
                        link: '/analytics/EarlySales',
                        description: 'Early Sales dashboard',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'pmsCmOverview',
                        component: 'PmsCmOverview',
                        prettyName: 'PMS/CM Overview',
                        link: '/analytics/pmscmoverview',
                        description: 'PG PMS & CM Overview',
                        hidePropertyGroupSelectionAppBar: true,
                        hidePropertySetSelectionAppBar: true,
                    },
                ]
            },

            /** APP Smartpaying **/
            smartpaying: {
                visible: true,
                appbar: false,
                name: 'smartpaying',
                prettyName: 'Smartpaying',
                icon: 'fa-solid fa-dollar-sign',
                subresources: [
                    {
                        name: 'onboarding',
                        component: 'onboarding',
                        prettyName: 'Onboarding',
                        link: '/smartpaying/onboarding',
                        description: 'SmartPaying Onboarding',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'messaging',
                        component: 'messaging',
                        prettyName: 'Messaging',
                        link: '/smartpaying/messaging',
                        description: 'SmartPaying Messaging',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'stripe',
                        component: 'stripe',
                        prettyName: 'Stripe',
                        link: '/smartpaying/stripe',
                        description: 'SmartPaying Stripe management',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'upselling',
                        component: 'upselling',
                        prettyName: 'Upselling',
                        link: '/smartpaying/upselling',
                        description: 'SmartPaying Upselling',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'reporting',
                        component: 'reporting',
                        prettyName: 'Reporting',
                        link: '/smartpaying/reporting',
                        description: 'SmartPaying Reporting',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'customSenderAddress',
                        component: 'customSenderAddress',
                        prettyName: 'Custom Sender Address',
                        link: '/smartpaying/custom-sender-address',
                        description: 'SmartPaying SendGrid Custom Sender Address',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'support',
                        component: 'support',
                        prettyName: 'Support',
                        link: '/smartpaying/support',
                        description: 'SmartPaying Support',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'autonboarding',
                        component: 'autonboarding',
                        prettyName: 'Autonboarding',
                        link: '/smartpaying/autonboarding',
                        description: 'SmartPaying AutoOnboarding',
                        hidePropertySetSelectionAppBar: true,
                    }
                ]
            },

            /** APP Scraper **/
            scraper: {
                visible: true,
                appbar: true,
                name: 'scraper',
                prettyName: 'Scraper',
                icon: 'fa-solid fa-download',
                subresources: [
                    {
                        name: 'hotel',
                        component: 'Hotel',
                        prettyName: 'Hotel',
                        link: '/scraper/hotel',
                        description: 'Scrape an hotel',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'area',
                        component: 'Area',
                        prettyName: 'Area',
                        link: '/scraper/area',
                        description: 'Scrape an area',
                        hidePropertySetSelectionAppBar: true,
                    },
                    {
                        name: 'search',
                        component: 'Search',
                        prettyName: 'Search',
                        link: '/scraper/search',
                        description: 'Search hotels',
                        hidePropertySetSelectionAppBar: true,
                    }
                ],
            },
        }
    }
}
