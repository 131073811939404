<template>
	<v-container fluid class="pa-4">
		<MapNew style="height: 90dvh;" :data="pgsLocations" :mapOptions="{
			startingView: [45.8820, 11.0365],
			startingZoomLevel: 5,
			clustering: true,
		}"></MapNew>
	</v-container>
</template>

<script>
import MapNew from '../../../components/utils/Map.vue';
import {mapState} from "vuex"

export default {
	name: 'CustomersMap',
	components: {Map, MapNew},
	data() {
		return {
			pgsLocations: [],
		}
	},
	computed: {
		...mapState({
			api: state => state.api.api,
		})
	},
	methods: {
		async getPgLocations() {
			const locations = []

			try {
				const jobRes = await this.api.get('core/v1', '/propertygroup', {filterColumns: 'id,name,coordinates'}, {}, {})

				if(jobRes.status === "found") {
					jobRes.items.forEach(pg => {
						if (pg.spec.coordinates) {
							locations.push({
								type: 'marker',
								popupHtml: `
									<b>${pg.metadata.id} - ${pg.metadata.name}</b><br>
								`,
								coordinates: [pg.spec.coordinates.x, pg.spec.coordinates.y],
								color: '#2596be',
							})

						}
					})
				}

				return locations
			} catch (error) {
				this.$store.dispatch("alertSystem/newError", {
					message: "Error loading the customers map!"
				})
				return null
			}
		}
	},
	async created() {
		this.pgsLocations = await this.getPgLocations()
	}
}
</script>