<template>
    <v-container class="pa-4" fluid>
        <v-card v-if="!pipelineLoadingPreview">
            <v-row class="card-ui">
                <v-col class="col-12">
                    <v-row>
                        <v-col class="col-8">
                            <v-card flat class="card-ui pl-3 pa-2 pt-0">
                                <v-alert
                                    border="right"
                                    :class="{ 'grey darken-3': darkMode }"
                                    elevation="2"
                                    style="height: 120px"
                                >
                                    <h3>
                                        {{ currentPG.metadata.name }} @ ID
                                        {{ currentPG.metadata.id }}
                                    </h3>
                                    <p class="mt-2">
                                        Created:
                                        {{
                                            new Date(
                                                currentPG.spec.created_at
                                            ).toLocaleString()
                                        }}
                                    </p>
                                    <p>
                                        Updated:
                                        {{
                                            new Date(
                                                currentPG.spec.updated_at
                                            ).toLocaleString()
                                        }}
                                    </p>
                                </v-alert>
                            </v-card>
                        </v-col>
                        <v-col class="col-4" style="text-align: right">
                            <v-card
                                flat
                                class="card-ui"
                                v-if="!!currentLifecycleStatus"
                            >
                                <v-alert
                                    border="left"
                                    :class="
                                        (currentLifecycleStatus.metadata
                                            .lifecycleStateName == 'live' ||
                                            currentLifecycleStatus.metadata
                                                .lifecycleStateName ==
                                                'trial') &&
                                        currentLifecycleStatus.metadata
                                            .lifecycleStateLabel !==
                                            'maintenance'
                                            ? 'green pa-12'
                                            : 'red pa-12'
                                    "
                                    style="text-align: center; height: 120px"
                                >
                                    <h2>
                                        {{
                                            currentLifecycleStatus.metadata.name
                                        }}
                                    </h2>
                                    <p>
                                        Since:
                                        {{
                                            currentLifecycleStatus.metadata.insertDate.split(
                                                "T"
                                            )[0]
                                        }}
                                    </p>
                                </v-alert>
                            </v-card>
                            <v-card flat class="card-ui" v-else>
                                <v-alert
                                    outlined
                                    type="red"
                                    class="pa-10"
                                    style="text-align: center; height: 120px"
                                >
                                    Property Group State NOT SET
                                </v-alert>
                            </v-card>
                        </v-col>

                        <v-col class="col-12 pt-8">
                            <v-card flat class="pl-3 card-ui">
                                <v-row>
                                    <v-col class="col-4" v-show="false">
                                        <v-alert
                                            outlined
                                            :type="
                                                currentPG.spec
                                                    .has_purchased_smartpricing
                                                    ? 'success'
                                                    : 'error'
                                            "
                                            >{{
                                                currentPG.spec
                                                    .has_purchased_smartpricing
                                                    ? "Smartpricing purchased"
                                                    : "Smartpricing not purchased"
                                            }}</v-alert
                                        >
                                    </v-col>
                                    <v-col class="col-4" v-show="false">
                                        <v-alert
                                            outlined
                                            :type="
                                                currentPG.spec
                                                    .has_purchased_smartpaying
                                                    ? 'success'
                                                    : 'error'
                                            "
                                            >{{
                                                currentPG.spec
                                                    .has_purchased_smartpaying
                                                    ? "Smartpaying purchased"
                                                    : "Smartpaying not purchased"
                                            }}</v-alert
                                        >
                                    </v-col>
                                    <v-col class="col-4" v-show="false">
                                        <v-alert
                                            outlined
                                            :type="
                                                currentPG.spec
                                                    .has_purchased_sales_tool
                                                    ? 'success'
                                                    : 'error'
                                            "
                                            >{{
                                                currentPG.spec
                                                    .has_purchased_sales_tool
                                                    ? "Market Intelligence purchased"
                                                    : "Market Intelligence not purchased"
                                            }}</v-alert
                                        >
                                    </v-col>
                                    <v-col class="col-4">
                                        <v-alert
                                            outlined
                                            :type="
                                                currentPG.spec.pms_name != null
                                                    ? 'success'
                                                    : 'red'
                                            "
                                            >PMS
                                            <b>{{ currentPG.spec.pms_name }}</b>
                                        </v-alert>
                                    </v-col>
                                    <v-col class="col-4">
                                        <v-alert
                                            outlined
                                            :type="
                                                currentPG.spec
                                                    .channel_manager_name !=
                                                null
                                                    ? 'success'
                                                    : 'red'
                                            "
                                            >Channel
                                            <b>{{
                                                currentPG.spec
                                                    .channel_manager_name
                                            }}</b>
                                        </v-alert>
                                    </v-col>
                                    <v-col class="col-4">
                                        <v-alert
                                            outlined
                                            :type="
                                                currentHubspotDeal?.spec
                                                    ?.dealStageName
                                                    ? `info`
                                                    : `error`
                                            "
                                        >
                                            Hubspot Deal Status:
                                            {{
                                                currentHubspotDeal?.spec
                                                    ?.dealStageName ||
                                                "Not Found"
                                            }}
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="col-12 pa-4">
                    <v-row>
                        <v-col class="col-4">
                            <v-text-field
                                hide-details
                                readonly
                                dense
                                :value="
                                    userGraphInfoParsed.eventSources?.join(', ')
                                "
                                outlined
                                label="Event Source"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-4">
                            <v-text-field
                                hide-details
                                readonly
                                dense
                                :value="userGraphInfoParsed.geocoding"
                                outlined
                                label="Geocoding"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-4">
                            <v-text-field
                                hide-details
                                readonly
                                dense
                                :value="userGraphInfoParsed.poi"
                                outlined
                                label="POI"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-4">
                            <v-text-field
                                hide-details
                                readonly
                                dense
                                :value="userGraphInfoParsed.category"
                                outlined
                                label="Category"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-4">
                            <v-text-field
                                hide-details
                                readonly
                                dense
                                :value="userGraphInfoParsed.stars"
                                outlined
                                label="Stars"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col-4">
                            <v-text-field
                                hide-details
                                readonly
                                dense
                                :value="userGraphInfoParsed.markets"
                                outlined
                                label="Market"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="col-12">
                    <v-data-table
                        :headers="propertiesTableHeaders"
                        :items="propertiesTable"
                        dense
                    ></v-data-table>
                </v-col>
                <v-col class="col-12 pa-4">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-dialog
                            transition="dialog-bottom-transition"
                            max-width="600"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="error" v-bind="attrs" v-on="on">
                                    <v-icon left>fa-solid fa-radiation</v-icon>
                                    Revert To V1
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-card>
                                    <v-toolbar color="error" dark>
                                        Rollback to V1 Pipeline Confirmation
                                    </v-toolbar>
                                    <v-card-text class="py-4 px-2">
                                        <p class="red--text">
                                            Attention this change is going to
                                            affect the current saved pipeline!
                                        </p>
                                        <p class="red--text">
                                            BE AWARE everything except the base
                                            prices and the compset will be lost!
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end">
                                        <v-btn
                                            color="error"
                                            @click="
                                                strategyV2toV1({
                                                    psId: currentStrategyPS.metadata.id,
                                                })
                                            "
                                        >
                                            Confirm
                                        </v-btn>
                                        <v-btn
                                            text
                                            @click="dialog.value = false"
                                            >Close</v-btn
                                        >
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-card-actions>
                </v-col>
                <div class="d-flex pa-0 sticky-header-top purple">
                    <v-tabs v-model="selectedTab" background-color="purple">
                        <v-tab :key="tabs[0]">
                            {{ tabs[0] }}
                        </v-tab>
                        <v-tab :key="tabs[1]">
                            {{ tabs[1] }}
                        </v-tab>
                    </v-tabs>
                    <v-chip
                        v-if="inCache !== null && !!inCache"
                        class="pr-8 my-2 mr-2"
                        color="error"
                        outlined
                    >
                        <v-icon left small>
                            fa-regular fa-circle-dot fa-xs
                        </v-icon>
                        Changes Not Saved
                    </v-chip>
                </div>
                <v-col class="col-12 pa-3">
                    <PipelineV2Editor
                        v-if="tabs[0] === tabs[selectedTab]"
                        pipelineType="master"
                    ></PipelineV2Editor>

                    <PipelineV2Editor
                        v-if="tabs[1] === tabs[selectedTab]"
                        pipelineType="test"
                    ></PipelineV2Editor>
                </v-col>
            </v-row>
        </v-card>
        <v-card
            v-else
            class="card-ui d-flex justify-center align-center flex-column"
            height="300"
            elevation="0"
        >
            <v-progress-circular
                :size="70"
                :width="7"
                indeterminate
                color="primary"
            ></v-progress-circular>
            <p>Loading</p>
        </v-card>
    </v-container>
</template>

<script>
// libraries
import { mapState, mapActions, mapGetters } from "vuex";

// components
import PipelineV2Editor from "../components/PipelineV2Editor.vue";
import SelectPgAlert from "@/components/utils/SelectPgAlert";

export default {
    components: { PipelineV2Editor, SelectPgAlert },
    data() {
        return {
            tabs: ["master", "modify and test"],
            selectedTab: null,

            loading: false,

            userGraphInfo: null,
            pgMarket: null,

            propertiesTableHeaders: [
                { text: "ID", value: "id" },
                { text: "Pg Id", value: "pg_id"},
                { text: "Name", value: "name" },
                { text: "Predicted", value: "predicted" },
                { text: "Stats", value: "stats" },
            ],
        };
    },
    computed: {
        ...mapState({
            darkMode: (state) => state.ui.darkMode,
            currentPG: (state) => state.core.currentPG,
            currentStrategyPS: (state) => state.core.currentStrategyPS,
            pipelineLoadingPreview: (state) =>
                state.pipelineV2.pipelineLoadingPreview,
            currentProperties: (state) => state.core.currentProperties,
            currentPropertiesIncludingChildren: (state) => state.core.currentPropertiesIncludingChildren,
            pipelinesData: (state) => state.pipelineV2.pipelineData,
            globalPipelineData: (state) =>
                state.pipelineV2.pipelineData?.global,
            currentHubspotDeal: (state) => state.core.currentHubspotDeal,
        }),
        ...mapGetters({
            currentLifecycleStateByBundle:
                "lifecycle/currentLifecycleStateByBundle",
        }),
        currentLifecycleStatus() {
            return this.currentLifecycleStateByBundle["smartpricing"];
        },
        propertiesTable() {
            return this.currentPropertiesIncludingChildren.map((prop) => {
                return {
                    id: prop.metadata.id,
                    name: prop.metadata.name,
                    pg_id: prop.spec.property_group_id,
                    predicted:
                        this.globalPipelineData?.predicted_property_ids?.includes(
                            Number(prop?.metadata?.id)
                        ),
                    stats: this.globalPipelineData?.stats_property_ids?.includes(
                        Number(prop?.metadata?.id)
                    ),
                };
            });
        },
        userGraphInfoParsed() {
            return {
                geocoding: this.userGraphInfo?.link?.Root?.find(
                    (val) => val.kind === "Location:City"
                )?.id,
                poi: this.userGraphInfo?.link?.Root?.find(
                    (val) => val.kind === "Poi"
                )?.id,
                category: this.userGraphInfo?.link?.Root?.find(
                    (val) => val.kind === "Category"
                )?.id,
                stars: this.userGraphInfo?.link?.Root?.find(
                    (val) => val.kind === "Stars"
                )?.id,
                eventSources: this.userGraphInfo?.link?.EventSource?.map(
                    (val) => val.id
                ),
                markets: this.pgMarket?.map((mkt) => mkt.metadata.id),
            };
        },
        inCache() {
            if (this.tabs[0] === this.tabs[this.selectedTab]) return null;

            return this.pipelinesData["test"].tmp;
        },
    },
    methods: {
        ...mapActions({
            strategyV2toV1: "strategyNew/strategyV2toV1",
        }),
        async getPgInformation(pgId) {
            const api = this.$store.state.api.api;

            try {
                const res = await api.get(
                    "graph/vpg",
                    `/propertygroup/${pgId}`,
                    {},
                    {},
                    {}
                );
                return res.items[0];
            } catch (error) {
                return null;
            }
        },
        async getPgMarket(pgId) {
            const api = this.$store.state.api.api;

            try {
                const res = await api.get(
                    "graph/vpg",
                    `/markets/propertygroup/${pgId}`,
                    {},
                    {},
                    {}
                );

                return res.items;
            } catch (error) {
                return null;
            }
        },
    },
    async created() {
        await this.$store.dispatch("pipelineV2/load", {
            psId: this.currentStrategyPS.metadata.id,
        });

        this.userGraphInfo = null;
        this.pgMarket = null;
        this.userGraphInfo = await this.getPgInformation(
            this.currentPG.metadata.id
        );
        this.pgMarket = await this.getPgMarket(this.currentPG.metadata.id);
    },
};
</script>

<style lang="scss">
.sticky-header-top {
    width: 100%;
    z-index: 100;
    position: sticky;
    top: 76px;
}
</style>
