<template>
    <v-container fluid class="pa-4" v-if="!basePriceMapsRequired">
        <!-- Check if a pg is selected -->
        <div v-if="!!globalSelectedPgId && !currentParent?.length">

            <div v-if="!pipelineLoading">
                <!-- If a pipeline v1 or v2 exists then show it -->
                <div v-if="pipelineFound && !!pipelineVersion">
        
                    <!-- Version 1 of the pipeline -->
                    <div v-if="pipelineVersion === 1">
                        <Builder></Builder>
                    </div>
        
                    <!-- Version 2 of the pipeline -->
                    <div v-else-if="pipelineVersion === 2">
                        <PipelineV2></PipelineV2>
                    </div>
        
                    <!-- Error fetching the pipeline -->
                    <v-alert type="error" v-else>
                        Error fetching the pipeline reload the page! If issue persists contact tech support.
                    </v-alert>
                </div>
        
                <!-- If a pipeline doesn't exist then show the selection for creating one -->
                <div v-else-if="!pipelineFound && !pipelineVersion">
                    <StrategyVersionChoice></StrategyVersionChoice>
                </div>
            </div>
        
            <!-- Loading pipeline -->
            <v-card 
                v-else 
                class="card-ui d-flex justify-center align-center flex-column" 
                height="300"
                elevation="0"
            >
                <v-progress-circular
                    :size="70"
                    :width="7"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                <p>Loading</p>
            </v-card>
        </div>

        <v-alert type="warning" v-else-if="!!globalSelectedPgId && !!currentParent?.length">
            Strategy must be managed inside the parent PG: {{ currentParent.join(",") }}
        </v-alert>

        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
    <v-container fluid class="pa-4" v-else>
        <BasePriceMapEditor
            :value="basePriceMaps"
            @input="resetBasePriceMaps({value: $event})"
            :propertiesMappings="allPropertyNamesById"
        >
            <template #subtitle>
                The average base price could not be automatically determined for some properties, please fill in the base price map to continue.
            </template>
            <template #actions>
                <v-btn
                    color="primary"
                    :disabled="!basePriceMapCompiled"
                    @click="continueGeneration()"
                >Continue Computation</v-btn>
            </template>
        </BasePriceMapEditor>
    </v-container>
</template>

<script>
// modules import
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

// components import
import BasePriceMapEditor from "../components/BasePriceMapEditor.vue"
import StrategyVersionChoice from "../components/StrategyVersionChoice.vue"
import SelectPgAlert from '@/components/utils/SelectPgAlert'

// views import
import Builder from './Builder.vue';
import PipelineV2 from './PipelineV2.vue'

export default {
    components: {StrategyVersionChoice, BasePriceMapEditor, SelectPgAlert, Builder, PipelineV2},
    computed: {
        ...mapState({
            pipelineFound: state => state.strategyNew.pipelineFound,
            pipelineVersion: state => state.strategyNew.pipelineVersion,
            currentParent: state => state.core.currentParent,
            basePriceMapsRequired: state => state.pipelineV2.basePriceMapsRequired,
            basePriceMaps: state => state.pipelineV2.basePriceMaps,
            basePriceMapStep: state => state.pipelineV2.basePriceMapStep,
            pipelineLoading: state => state.strategyNew.pipelineLoading,
            currentStrategyPS: state => state.core.currentStrategyPS,
        }),
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
            allPropertyNamesById: "core/allPropertyNamesById",
        }),
        basePriceMapCompiled() {
            return !Object.values(this.basePriceMaps).includes(null)
        },
    },
    methods: {
        ...mapMutations({
            resetBasePriceMaps: "pipelineV2/resetBasePriceMaps",
            resetBasePriceMapsRequired: "pipelineV2/resetBasePriceMapsRequired",
        }),
        ...mapActions({
            generateNew: "pipelineV2/generateNew",
            createV2Strategy: "strategyNew/createV2Strategy",
        }),
        async continueGeneration() {
            if (this.basePriceMapStep === "new") {
                await this.generateNew({
                    config: {
                        base_price_map: this.basePriceMaps,
                    },
                    psId: this.currentStrategyPS.metadata.id,
                })
                
            } else {
                await this.createV2Strategy({
                    config: {
                        base_price_map: this.basePriceMaps,
                    },
                    psId: this.currentStrategyPS.metadata.id,
                })
            }
            this.resetBasePriceMaps()
            this.resetBasePriceMapsRequired()
        },
    }
}
</script>