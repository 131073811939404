<template>
  <div class="home">
    <v-container>
      <v-row>
        <v-col class="col-6">
          <h1> Runner </h1>
        </v-col>
      </v-row>
  </v-container>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Runner',
  components: {},
  data: function (argument) {
    return {}
  }
}
</script>
