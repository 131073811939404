<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 col-md-6 pa-2">
        <v-card class="mt-0 mx-auto">
          <v-sheet
            class=""
            :color="darkMode ? 'black' : ''"
            elevation="0"

          >
            <v-sparkline
              :labels="['get', 'compute','send']"
              :value="[warningsStats.getReservation, warningsStats.compute, warningsStats.sent] || []"
              :color="darkMode ? 'grey' : ''"
              :fill="true"
              smooth="10"
              :gradient="$store.state.dashboard.gradient"
              :auto-line-width="true"
              padding="16"
              type="bar"
            ></v-sparkline>
          </v-sheet>
          <v-card-text class="pt-0 black-background">
            <div class="text-h6 font-weight-light mb-2 pt-4">
              Compute pipeline errors ({{ warnings?.length }})
            </div>
            <div class="subheading font-weight-light grey--text">
              {{ new Date().toISOString().split("T")[0] }} Today's warning events summary
            </div>
            <v-divider class="my-3"></v-divider>

            <span class="text-caption grey--text font-weight-light">
              <v-chip :color="warningsStats.getReservation == 0 ? 'gray' : 'red' ">
                Reservation {{warningsStats.getReservation > 0 ? warningsStats.getReservation : ''}}
              </v-chip>
              <v-chip class="ml-2" :color="warningsStats.compute == 0 ? 'gray' : 'red' ">
                Compute {{warningsStats.compute > 0 ? warningsStats.compute : ''}}
              </v-chip>
              <v-chip class="ml-2"  :color="warningsStats.sent == 0 ? 'gray' : 'red' ">
                Send {{warningsStats.sent > 0 ? warningsStats.sent : ''}}
              </v-chip>
            </span>
          </v-card-text>
        </v-card>

        <v-card class="mt-4 mx-auto" :class="{'black': darkMode}">
          <v-sheet
            class="v-sheet--offset mx-auto"
            :color="darkMode ? 'black' : ''"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-sparkline
              :value="warningsStats.topChmAry?.map((p) => { return p.count }) || []"
              :gradient="$store.state.dashboard.gradient"
              line-width="2"
              padding="16"
              type="bar"
              class="pa-6"
            ></v-sparkline>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              Errors by Channel Managers
            </div>
            <div class="subheading font-weight-light grey--text">
              Registered errors during send-prices phase
            </div>
            <v-divider class="my-2"></v-divider>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Expand data
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table
                    dense
                    v-if="warningsStats.topChmAry?.length > 0"
                    class="pa-4"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Channel Manager Name</th>
                          <th class="text-left">Warning events (Today)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in warningsStats.topChmAry"
                          :key="item.pg"
                          dense
                          @click="showWarningPgForChm(item.channel_manager_name)"
                        >
                          <td>{{ item.channel_manager_name }}</td>
                          <td>{{ item.count }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>

        <v-card class="mt-4 mx-auto" :class="{'black': darkMode}">
          <v-sheet
            class="v-sheet--offset mx-auto"
            :color="darkMode ? 'black' : ''"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-sparkline
              :value="warningsStats.topPmsAry?.map((p) => { return p.count }) || []"
              :gradient="$store.state.dashboard.gradient"
              line-width="2"
              padding="16"
              type="bar"
              class="pa-6"
            ></v-sparkline>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              Errors by PMS
            </div>
            <div class="subheading font-weight-light grey--text">
              Registered errors during get reservation phase
            </div>
            <v-divider class="my-2"></v-divider>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Expand data
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table
                    dense
                    v-if="warningsStats.topPmsAry?.length > 0"
                    class="pa-4"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">PMS Name</th>
                          <th class="text-left">Warning events (Today)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in warningsStats.topPmsAry"
                          :key="item.pg"
                          dense
                          @click="showWarningPgForPms(item.pms_name)"
                        >
                          <td>{{ item.pms_name }}</td>
                          <td>{{ item.count }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>

      </v-col>
      <v-col class="col-12 col-md-6 pa-2">
        <v-card class="mt-0 mx-auto" :class="{'black': darkMode}">
          <v-sheet
            class="v-sheet--offset mx-auto"
            :color="darkMode ? 'black' : ''"
            max-width="calc(100% - 32px)"
          >
            <v-sparkline
              :value="warningsByPSs?.map((p) => { return p.count }) || []"
              :gradient="$store.state.dashboard.gradient"
              color="grey"
              :type="bars"
              line-width="2"
              padding="16"
              class="pa-6"
            ></v-sparkline>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              Errors by Property Set
            </div>
            <div class="subheading font-weight-light grey--text">
              Top {{topErrorsPgSlice}} warnings per Property Set
            </div>
            <v-divider class="my-2"></v-divider>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Expand data
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-autocomplete
                    label="Show by kind"
                    class="pa-4"
                    outlined
                    dense
                    :items="possibleKinds"
                    v-model="selectedKindsToShow"
                    multiple
                    chips
                    deletable-chips
                    small-chips
                  ></v-autocomplete>
                  <v-simple-table
                    dense
                    v-if="warningsByPSs?.length > 0"
                    class="pa-4"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Property Set</th>
                          <th class="text-left">Warning events (Today)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in warningsByPSs"
                          :key="item.ps"
                          dense
                        >
                          <td>{{ item.ps }}</td>
                          <td>{{ item.count }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>

        <v-card class="mt-4 mx-auto" :class="{'black': darkMode}">
          <v-sheet
            class="v-sheet--offset mx-auto"
            :color="darkMode ? 'black' : ''"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-sparkline
              :value="Object.values(notSentPropertiesByPgCount) || []"
              :gradient="$store.state.dashboard.gradient"
              line-width="2"
              :type="bars"
              padding="16"
              class="pa-6"
            >
            </v-sparkline>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              Dead Property Groups?
            </div>
            <div class="subheading font-weight-light grey--text">
              Pgs (in push and state live.default/live.trial) sent more than 2 days ago. Considering only predicted or indexed properties
            </div>
            <v-divider class="my-2"></v-divider>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Expand data
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table
                    dense
                    v-if="notSentPropertyGroupsSinceAtLeast2Days?.length > 0"
                    class="pa-4"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Property Group</th>
                          <th class="text-left">CHM</th>
                          <th class="text-left">Last Sent date</th>
                          <th class="text-left">Days Since Last Send</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in notSentPropertyGroupsSinceAtLeast2Days"
                          :key="item.pgId"
                          dense
                        >
                          <td>{{ item.pgId }}</td>
                          <td>{{ item.chm }}</td>
                          <td>{{ item.date }}</td>
                          <td>{{ item.daysPassed }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>


        <v-card class="mt-4 mx-auto" :class="{'black': darkMode}">
          <v-sheet
            class="v-sheet--offset mx-auto"
            :color="darkMode ? 'black' : ''"
            elevation="12"
            max-width="calc(100% - 32px)"
          >
            <v-sparkline
              :value="warningsStats.topPmsAry?.map((p) => { return p.count }) || []"
              :gradient="$store.state.dashboard.gradient"
              line-width="2"
              padding="16"
              type="bar"
              class="pa-6"
            ></v-sparkline>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              Property Groups State
            </div>
            <div class="subheading font-weight-light grey--text">
              All property groups state with duration
            </div>
            <v-divider class="my-2"></v-divider>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Expand data
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card>
                    <v-card-title>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Filter status"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headers"
                      :items="allPropertyGroupStateHistory"
                      :search="search"
                    ></v-data-table>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    <v-dialog v-model="warningChmPropertySetDialog" width="500">
      <v-card>
        <v-card-title> Related Property Sets </v-card-title>
      
      <template>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">
                Ps
              </th>
              <th class="text-left">
                Count
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in this.getChmPsWarningsByChm(warningPropertySetChmName)"
              :key="item.ps"
            >
              <td>{{ item.ps }}</td>
              <td>{{ item.count }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warningPmsPropertySetDialog" width="500">
      <v-card>
        <v-card-title> Related Property Sets </v-card-title>
        <template>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">
                Ps
              </th>
              <th class="text-left">
                Count
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in this.getPmsPsWarningsByPms(warningPmsPropertySetPmsName)"
              :key="item.ps"
            >
              <td>{{ item.ps }}</td>
              <td>{{ item.count }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { subDays, format } from 'date-fns'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'Dashboard',
  data() {
    return {
      bars: 'bar',
      warningChmPropertySetDialog: false,
      warningPmsPropertySetDialog: false,
      warningPropertySetChmName: null,

      warningPropertySetPmsName: null,

      notSentPropertyGroupsSinceAtLeast2Days: [],
      warningsStats: [],
      warningsByPSs: [],

      topErrorsPgSlice: 50,
      search: '',
      pushPartners: [],
      headers: [
        {
          text: 'Property Group',
          align: 'start',
          filterable: false,
          value: 'pgString',
        },
        {
          text: 'Current Status',
          value: 'lcString'
        },
        {
          text: 'Since (days)',
          filterable: false,
          value: 'days'
        }
      ],

      selectedKindsToShow: [],
    }
  },
  watch: {
    async 'selectedKindsToShow' (newVal, oldVal) {      
      this.warningsByPSs = await this.calculatewarningsByPSs()
    }
  },
  methods: {
    ...mapActions({
      newError: "alertSystem/newError"
    }),
    goToSystemLogs (item) {
      let pgId = /\(([^)]+)\)/.exec(item.pg)[1]
      this.$router.push('/logs/' + pgId)
    },
    showWarningPgForChm(chmName) {
      this.warningChmPropertySetDialog = true
      this.warningPropertySetChmName = chmName
    },
    showWarningPgForPms(pmsName) {
      this.warningPmsPropertySetDialog = true
      this.warningPmsPropertySetPmsName = pmsName
    },
    getChmPsWarningsByChm(chm){
      return Object.entries(this.warningsStats?.byChmPs?.[chm] || {} ).map(([key, value]) => {
        return {ps: key, count: value}
      })
    },
    getPmsPsWarningsByPms(pms){
      return Object.entries(this.warningsStats?.byPmsPs?.[pms] || {} ).map(([key, value]) => {
        return {ps: key, count: value}
      })
    },
    async fetchPushPartners() {
      try {
          const resCommMethod = await this.api.get("integrations/v2", `/specs/feature/pricesCommunicationMethod`, {}, {}, {})
          const pushPartners = []
          Object.entries(resCommMethod).forEach(([par, value]) => {
              if (["push", "hybrid"].includes(value)) {
                pushPartners.push(par)
              }
          })
          this.pushPartners = Array.from(new Set(pushPartners))
      } catch (error) {
          this.newError({
              message: `Error getting the reservations communication method: ${error}`
          })
      }
    },
    async calculateNotSentPropertyGroupsSinceAtLeast2Days() {
      let mostRecentReservationByPg = {}
      const start = performance.now()
      for (let prop of this.notSentProperties) {
        let pgId = prop.property_group;
        let pgPartners = this.pgPmsAndCmById[pgId];

        if (!this.pushPartners.includes(pgPartners.cm)) {
          continue
        }

        if ( ( mostRecentReservationByPg[pgId] === undefined || mostRecentReservationByPg[pgId] === null || prop.date > mostRecentReservationByPg[pgId] ) ) {
          mostRecentReservationByPg[pgId] = prop.date;
        }
      }
      const end = performance.now()
      console.log(`notSentPropertyGroupsSinceAtLeast2Days took ${end-start} ms`)
      
      let res = []
      for ( let key of Object.keys(mostRecentReservationByPg) ) {
        let pgPartners = this.pgPmsAndCmById[key]
        if ( mostRecentReservationByPg[key] ) {
          const diffTime = Math.abs(new Date() - new Date(mostRecentReservationByPg[key]) );
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          if ( diffDays > 2 ) {
            res.push( {pgId:key, chm: pgPartners.cm, date: mostRecentReservationByPg[key], daysPassed: diffDays })
          }
        } else {
          res.push( {pgId:key, chm: pgPartners.cm, date: null, daysPassed: 'never sent' })
        }
        
      }
      return res
    },
    async calculateWarningsStats(){
      let tmpWarningsStats = {
        getReservation: 0,
        sent: 0,
        compute: 0,
        byPg: {},
        byChm: {},
        byChmPs: {},
        byPms: {},
        byPmsPs: {},
        byComputePs: {},
        topPgAry: [],
        topChmAry: [],
        topPmsAry: [],
      }
      const self = this
      if(!this.warnings){
        return
      }

      this.warnings.forEach(function (w) {
        let pgNameId = self.pgNameById[w.property_group] + ' (' + w.property_group + ')'
        if ( ['prices-v2','prices','prices-push'].includes(w.kind)) {
          tmpWarningsStats.sent += 1
          if (w.channel_manager_name !== undefined && w.channel_manager_name !== null) {
            if (tmpWarningsStats.byChm[w.channel_manager_name] == undefined) {
              tmpWarningsStats.byChm[w.channel_manager_name] = 0
            }
            if (tmpWarningsStats.byChmPs[w.channel_manager_name] == undefined) {
              tmpWarningsStats.byChmPs[w.channel_manager_name] = {}
            }
            tmpWarningsStats.byChm[w.channel_manager_name] += 1
            if ( tmpWarningsStats.byChmPs[w.channel_manager_name][w.property_set_id] == undefined ) {
              tmpWarningsStats.byChmPs[w.channel_manager_name][w.property_set_id] = 0
            }
            tmpWarningsStats.byChmPs[w.channel_manager_name][w.property_set_id]++
          }
        } else if ( ['computed-price'].includes(w.kind)) {
          tmpWarningsStats.compute += 1
          if ( tmpWarningsStats.byComputePs[w.property_set_id] == undefined ) {
            tmpWarningsStats.byComputePs[w.property_set_id] = 0
          }
          tmpWarningsStats.byComputePs[w.property_set_id]++

        } else if ( ['reservations-v2', 'reservations' ].includes(w.kind) ) {
          tmpWarningsStats.getReservation += 1
          if (w.pms_name !== undefined && w.pms_name !== null) {
            if (tmpWarningsStats.byPms[w.pms_name] == undefined) {
              tmpWarningsStats.byPms[w.pms_name] = 0
            }
            if (tmpWarningsStats.byPmsPs[w.pms_name] == undefined) {
              tmpWarningsStats.byPmsPs[w.pms_name] = {}
            }
            tmpWarningsStats.byPms[w.pms_name] += 1
            if ( tmpWarningsStats.byPmsPs[w.pms_name][w.property_set_id] == undefined ) {
              tmpWarningsStats.byPmsPs[w.pms_name][w.property_set_id] = 0
            }
            tmpWarningsStats.byPmsPs[w.pms_name][w.property_set_id]++
          }
        }

        if (tmpWarningsStats.byPg[pgNameId] == undefined) {
          tmpWarningsStats.byPg[pgNameId] = 0
        }
        tmpWarningsStats.byPg[pgNameId] += 1
      })

      Object.keys(tmpWarningsStats.byChm).forEach(function (channel_manager_name) {
        tmpWarningsStats.topChmAry.push({ channel_manager_name: channel_manager_name, count: tmpWarningsStats.byChm[channel_manager_name] })
      })
      Object.keys(tmpWarningsStats.byPms).forEach(function (pms_name) {
        tmpWarningsStats.topPmsAry.push({ pms_name: pms_name, count: tmpWarningsStats.byPms[pms_name] })
      })

      return tmpWarningsStats
    },
    async calculatewarningsByPSs(){
      const filtered = this.warnings.filter(warning => {
        return this.selectedKindsToShow.includes(warning.kind)
      })

      const psWarningHashMap = {} 
      filtered.forEach(value => {
        const psId = value.property_set_id
        if (psWarningHashMap[psId] === undefined) {
          psWarningHashMap[psId] =  1
        } else {
          psWarningHashMap[psId]++
        }
      })
      

      return Object.entries(psWarningHashMap).map(([key,value]) => {
        return {
          ps: key,
          count: value,
        }
      })
      .sort( (a,b) => b.count - a.count)
      .slice(0, this.topErrorsPgSlice)
      
    },
  },
  async mounted() {
    await this.fetchPushPartners()
    await this.$store.dispatch('dashboard/warnings', null)
    await this.$store.dispatch('dashboard/notSentProperties', { date: format(subDays(new Date(), 2), 'yyyy-MM-dd') })
    await this.$store.dispatch('dashboard/allPropertyGroupStateHistory', null)
    this.selectedKindsToShow = this.possibleKinds

    this.notSentPropertyGroupsSinceAtLeast2Days = await this.calculateNotSentPropertyGroupsSinceAtLeast2Days()
    this.warningsStats = await this.calculateWarningsStats()
    this.warningsByPSs = await this.calculatewarningsByPSs()
  },
  computed: {
    ...mapState({
      darkMode: state => state.ui.darkMode,
      propertyGroupsList: state => state.core.propertyGroupsList,
      api: state => state.api.api,
    }),
    ...mapGetters({
      getWarnings: 'dashboard/warnings',
      getNotSentProperties: 'dashboard/notSentProperties',
      getAllPropertyGroupStateHistory: 'dashboard/allPropertyGroupStateHistory',
      pgNameById: 'core/pgNameById',
      pgPmsAndCmById: 'core/pgPmsAndCmById'
    }),
    warnings(){
      return this.getWarnings
    },
    
    possibleKinds() {
      return Array.from(new Set(this.warnings.map(value => {
        return value.kind
      })))
    },
    notSentProperties(){
      return this.getNotSentProperties.filter(value => {
        const pgStatus =  this.$store.state.dashboard.currentStateByPropertyID[value.property_group]
        return pgStatus === "live.default" || pgStatus === "live.trial"
      })
    },
    notSentPropertiesByPgCount(){
      let pgCountMap = {}

      this.notSentProperties.forEach((p) => {
        pgCountMap[p.property_group] = pgCountMap[p.property_group] == undefined ? 1 : pgCountMap[p.property_group] + 1
      })

      return pgCountMap
    },
    allPropertyGroupStateHistory(){
      return this.getAllPropertyGroupStateHistory
    }
  }
}
</script>
