import { ChartUtils } from "../../../utils/chartUtils"

// single property - single strategy prediction plot | 1° plot
function newGetTraces(featuresData, featuresGroupsCols, priceChartCol, dateCol, propertiesLabelCol, strategyName, pgId){

  const strategyLabel = strategyName.replace('.' + pgId.toString(), '').replace('.', '-');
  const valuesByProp = ChartUtils.valuesByKey(featuresData, propertiesLabelCol)

  const featureGroupsTraces = {}
  const priceTraces = {}

  for (const [propLabel, valuesByKey] of Object.entries(valuesByProp)) {
    featureGroupsTraces[propLabel] = {}

    for (const section of Object.keys(featuresGroupsCols)) {
      featureGroupsTraces[propLabel][section] = []
    }

    for (const [section, subSections] of Object.entries(featuresGroupsCols)) {

      for (let subSection of subSections) {
        // add the different key traces
        featureGroupsTraces[propLabel][section].push({
          hovertemplate: `<b>Strategy: ${strategyLabel} </b><br> <b>Property id: </b> ${propLabel} ${subSection.charAt(0).toUpperCase()} ${subSection.slice(1).replaceAll('_', ' ')} <br>Price: %{y:.2f}<br>Date: %{x}<extra></extra>`,
          name: subSection,
          type: `scatter`,
          x: valuesByKey[dateCol],
          y: valuesByKey[subSection],
          yaxis: String(subSection).includes("perc") ? 'y2' : 'y1',
        })
      }
    }

    // add the price weighted derived trace
    priceTraces[propLabel] = []
    priceTraces[propLabel].push({
      hovertemplate: `<b>Strategy: ${strategyLabel} </b><br> <b>Property id: </b> ${propLabel} <br>Price: %{y:.2f}<br>Date: %{x}<extra></extra>`,
      name: `${strategyLabel}-${propLabel}`,
      type: `scatter`,
      x: valuesByKey[dateCol],
      y: valuesByKey[priceChartCol],
    })
  }

  // calcualte the properties strings
  const propertiesIdStr = Object.keys(valuesByProp)

  return {
    featureGroupsTraces: featureGroupsTraces,
    propertiesId: propertiesIdStr,
    priceChartTraces: priceTraces,
  }
}

// price weighted derived plot | 2° plot | multi strategy and multi plot select
function newGetCompareData(compare, compareDateCol, compareColumns, propertiesLabelCol, strategy) {
  const valuesByProp = ChartUtils.valuesByKey(compare, propertiesLabelCol)

  const traces = {}
  compareColumns.forEach(compCol => {
    traces[compCol] = {}

    for (let [propLabel, propValues] of Object.entries(valuesByProp)) {

      traces[compCol][propLabel] = []

      traces[compCol][propLabel].push({
        y: propValues[`${compCol}`],
        x: propValues[compareDateCol],
        type:"scatter",
        name: `${strategy} - ${propLabel}`,
        hovertemplate: compCol + '<br>Value: %{y:.2f}<br>Date: %{x}<extra></extra>',
      })

    }
  })

  return {
    traces: traces,
    stats: {}
  }
}

// price change logs plot | 3° plot
function newGetJustifierData(justifiersData, justifiersColumns, justifiersPercColumns, propertiesLabelCol, strategyName) {
  const valuesByProp = ChartUtils.valuesByKey(justifiersData, propertiesLabelCol)

  const tracesByProperty = {}
  for (let [propLabel, propValues] of Object.entries(valuesByProp)) {
    tracesByProperty[propLabel] = []

    justifiersColumns.forEach(colName => {
      const colLabel = colName.charAt(0).toUpperCase() + colName.slice(1).replaceAll('_', ' ')

      tracesByProperty[propLabel].push({
        x: propValues["d_stay"],
        y: propValues[colName],
        type:"scatter",
        name: colLabel,
        yaxis: "y1",
        hovertemplate: '<b>' + colLabel + '</b><br><b>Strategy: ' + strategyName + '</b><br>' + '<b>Property id: </b>' + propLabel + '<br>Value: %{y:.2f}<br>Date: %{x}<extra></extra>',
      })
    })

    justifiersPercColumns.forEach(colName => {
      const colLabel = colName.charAt(0).toUpperCase() + colName.slice(1).replaceAll('_', ' ')

      tracesByProperty[propLabel].push({
        x: propValues["d_stay"],
        y: propValues[colName],
        type:"scatter",
        name: colLabel,
        yaxis: "y2",
        hovertemplate: '<b>' + colLabel + '</b><br><b>Strategy: ' + strategyName + '</b><br>' + '<b>Property id: </b>' + propLabel + '<br>Value: %{y:.2f}<br>Date: %{x}<extra></extra>',
      })
    })

  }

  //console.log(tracesByProperty)
  return tracesByProperty
}

// compset correction plot | 4° plot
function newGetCompCorrectionTraces(compCorrectionData, compCorectionColumns, dateCol, propertiesLabelCol) {
  const valuesByProp = ChartUtils.valuesByKey(compCorrectionData, propertiesLabelCol)

  const tracesByProperty = {}
  for (let [propLabel, propValues] of Object.entries(valuesByProp)) {
    tracesByProperty[propLabel] = []

    compCorectionColumns.forEach(colName => {
      const colLabel = colName.charAt(0).toUpperCase() + colName.slice(1).replaceAll('_', ' ')

      tracesByProperty[propLabel].push({
        x: propValues[dateCol],
        y: propValues[colName],
        type:"scatter",
        name: colLabel,
        yaxis: String(colName).includes("perc") ? 'y2' : 'y1',
      })
    })

  }

  return tracesByProperty
}

export default {
  namespaced: true,

  state: {

    // general chart cols
    dateCol: 'd_stay',
    compareDateCol: 'd_stay',
    propertiesIdCol: 'property_id',
    priceChartCol: 'price_weighted_derived',

    // single property chart
    featureSetList: ['defaults', 'ADR', 'Occupancy', 'Rolling Occupancy', 'Trendline'],
    featuresGroupsCols: {
      'defaults': ['price_weighted_derived', 'adr_pr', 'occupancy_perc_nopr'],
      'ADR': ['adr_pr_ly1', 'adr_pr_ly2', 'grouped_adr_pr', 'grouped_adr_pr_ly1', 'grouped_adr_pr_ly2', 'adr_pr_ly3', 'grouped_adr_pr_ly3'],
      'Occupancy':  ['grouped_adr_pr_market', 'grouped_adr_pr_market_ly1', 'grouped_adr_pr_market_ly2', 'grouped_occupancy_perc_nopr_market', 'grouped_occupancy_perc_nopr_market_ly1', 'grouped_occupancy_perc_nopr_market_ly2', 'grouped_occupancy_perc_nopr_market_ly3', 'grouped_adr_pr_market_ly3', 'grouped_occupancy_perc_nopr', 'grouped_occupancy_perc_nopr_ly1', 'grouped_occupancy_perc_nopr_ly2', 'grouped_occupancy_perc_nopr_ly3'],
      'Rolling Occupancy':  ['grouped_rolling_21_occupancy_perc_nopr_market', 'grouped_rolling_21_occupancy_perc_nopr_market_ly1', 'grouped_rolling_21_occupancy_perc_nopr_market_ly2', 'grouped_rolling_21_occupancy_perc_nopr_market_ly3', 'grouped_rolling_21_occupancy_perc_nopr', 'grouped_rolling_21_occupancy_perc_nopr_ly1', 'grouped_rolling_21_occupancy_perc_nopr_ly2', 'grouped_rolling_21_occupancy_perc_nopr_ly3'],
    },

    // justifiers chart cols
    justifiersCols: ['base_price', 'events_endprice', 'day_map_endprice', 'property_group_pickup_endprice', 'drop_lift_endprice', 'cap_endprice', 'market_correction_endprice', 'price_weighted_derived'],

    // comparison chart cols
    compareCols: ['price_weighted_derived'], //old prediction col vs new prediction col

    // comp correction chart cols
    compCorrectionColumns: ["lower_uncertainty_market_for_prop", "middle_market_for_prop", "price", "price_corrected_1", "price_corrected_w", "upper_uncertainty_market_for_prop", "grouped_occupancy_perc_nopr"],


    /* Traces calculated */
    singlePropertyChartTraces: null,

    priceWeightedDerivedChartTraces: null,

    justifiersChartTraces: null,

    compsetCorrectionTraces: null,

  },

  getters: {

    featureSetListCategories(state) {
      return Object.keys(state.featuresGroupsCols)
    },

    allPredictPipelineBindsLoaded(state, getters, rootState, rootGetters) {
      const alreadyCalculated = Object.keys(rootState.pipelineV1.pipelineBindsCalculationResults)

      const notCalculated = rootGetters["pipelineV1/predictStrategies"].filter(pb => {
        return !alreadyCalculated.includes(pb)
      })

      return !notCalculated.length
    },

  },

  mutations: {

    resetAllChartTraces(state) {
      state.singlePropertyChartTraces = null
      state.priceWeightedDerivedChartTraces = null
      state.justifiersChartTraces = null
      state.compsetCorrectionTraces = null
    },

    resetSinglePropertyChartTraces(state, payload) {
      state.singlePropertyChartTraces = null
      if (!!payload) {
        state.singlePropertyChartTraces = payload.value
      }
    },

    resetPriceWeightedDerivedChartTraces(state, payload) {
      state.priceWeightedDerivedChartTraces = null
      if (!!payload) {
        state.priceWeightedDerivedChartTraces = payload.value
      }
    },

    resetJustifiersChartTraces(state, payload) {
      state.justifiersChartTraces = null
      if (!!payload) {
        state.justifiersChartTraces = payload.value
      }
    },

    resetCompsetCorrectionTraces(state, payload) {
      state.compsetCorrectionTraces = null
      if (!!payload) {
        state.compsetCorrectionTraces = payload.value
      }
    },

  },

  actions: {

    async fetchPredictData (context){ // fetches only not loaded pipelinebinds
      try {

        // gets the info from the predict pipelinebinds
        const parsedPipelineBinds = context.rootGetters["pipelineV1/predictStrategies"].filter(pbName => {
          return !context.rootGetters["pipelineV1/alreadyLoadedPipelineBinds"].includes(pbName)
        }).map(pbName => {

          const pb = context.rootGetters["pipelineV1/pipelineBindByLabel"]?.[pbName]

          return {
            name: pb.resource.metadata.name,
            workspace: pb.resource.metadata.workspace,
            forceCache: false,
          }
        })

        await Promise.all(parsedPipelineBinds.map(async (parsedPb) => {

          await context.dispatch("pipelineV1/runPipelinebind", {
            workspace: parsedPb.workspace,
            name: parsedPb.name,
            forceCache: parsedPb.forceCache,
          }, {root: true})

        }))

      } catch (error) {
        context.dispatch("alertSystem/newError", {
          message: `Failed to fetch the predict data! Error: ${error}`,
        }, {root: true})
      }
    },

    /* Chart Data Loading */

    async loadSinglePropertyChartData(context) {
      const hashMap = {}

      Object.entries(context.rootGetters["pipelineV1/pipelineBindsPredictCalculationResults"]).forEach(([pbName, result]) => {

        const traces = newGetTraces(
          result.result.df,
          context.state.featuresGroupsCols,
          context.state.priceChartCol,
          context.state.dateCol,
          context.state.propertiesIdCol,
          pbName,
          context.rootState.core.currentPG.metadata.id
        )

        hashMap[pbName] = traces
      })

      context.commit("resetSinglePropertyChartTraces", {value: hashMap})
    },

    async loadPriceWeightedDerivedChart (context) {
      const hashMap = {}

      Object.entries(context.rootGetters["pipelineV1/pipelineBindsPredictCalculationResults"]).forEach(([pbName, result]) => {

        const traces = newGetCompareData(
          result.result.df_justifier,
          context.state.compareDateCol,
          context.state.compareCols,
          context.state.propertiesIdCol,
          pbName
        )

        hashMap[pbName] = traces
      })

      context.commit("resetPriceWeightedDerivedChartTraces", {value: hashMap})

    },

    async loadJustifiersChart (context) {
      const hashMap = {}

      Object.entries(context.rootGetters["pipelineV1/pipelineBindsPredictCalculationResults"]).forEach(([pbName, result]) => {

        const traces = newGetJustifierData(
          result.result.df_justifier,
          context.state.justifiersCols,
          [],
          context.state.propertiesIdCol,
          pbName
        )

        hashMap[pbName] = traces
      })

      context.commit("resetJustifiersChartTraces", {value: hashMap})
    },

    async loadCompsetCorrectionChart (context) {
      const hashMap = {}

      Object.entries(context.rootGetters["pipelineV1/pipelineBindsPredictCalculationResults"]).forEach(([pbName, result]) => {

        const traces = newGetCompCorrectionTraces(
          result.result.df_comp_correction,
          context.state.compCorrectionColumns,
          context.state.dateCol,
          context.state.propertiesIdCol,
        )

        hashMap[pbName] = traces
      })

      context.commit("resetCompsetCorrectionTraces", {value: hashMap})
    },

  }
}
