export default {
    namespaced: true,

    state: {

        // reservations
        reservationsList: [],
        reservationsCount: 0,

        // board types 
        boardTypesList: [],

        // rate descriptions
        rateDescriptionsList: [],

        // origin channel types
        originChannelTypesList: [],
    },

    getters: {

    },

    mutations: {

        // reservations
        resetReservationsList(state, payload) {
            state.reservationsList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.reservationsList.push(val)
                })
            }
        },

        resetReservationsCount(state, payload) {
            state.reservationsCount = 0
            if (!!payload?.value) {
                state.reservationsCount = payload.value
            }
        },

        // board types
        resetBoardTypesList(state, payload) {
            state.boardTypesList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.boardTypesList.push(val)
                })
            }
        },

        // rate descriptions
        resetRateDescriptionsList(state, payload) {
            state.rateDescriptionsList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.rateDescriptionsList.push(val)
                })
            }
        },

        resetOriginChannelTypesList(state, payload) {
            state.originChannelTypesList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.originChannelTypesList.push(val)
                })
            }
        }

    },

    actions: {

        // reservations list actions
        async fetchReservationsList(context, payload) {
            const api = context.rootState.api.api

            try {
                //const res = await api.get("core/v1", 
                //`/reservations/${payload.pgId}/${payload.stayStart}/${payload.stayEnd}/${payload.bookingStart}/${payload.bookingEnd}`, 
                //{}, {}, {})

                let query = {
                    stay_start: payload.stayStart,
                    stay_end: payload.stayEnd,
                    booking_start: `${payload.bookingStart}T00:00:00.000+00:00`,
                    booking_end: `${payload.bookingEnd}T23:59:59.999+00:00`,
                    page_size: 1000000000,
                }
                const res = await api.get("reservation/v2", 
                    `/general/reservations-by-property-group/${payload.pgId}`,
                    query, {}, {})

                context.commit("resetReservationsList", {
                    values: res.items
                })
                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the reservations list: ${error}`,
                }, {root: true})
                return null
            }
        },

        async fetchReservationsCount(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("core/v1", 
                `/reservationscount/${payload.pgId}`, 
                {}, {}, {})

                context.commit("resetReservationsCount", {
                    value: res.value,
                })

                return res.value
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the reservations count: ${error}`,
                }, {root: true})
                return 0
            }
        },
        // board types
        async fetchPgBoardTypesList(context, payload) {
			const api = context.rootState.api.api

            try {
                const query = {
                    select_option: 9
                }
                const res = await api.get("reservation/v2", `/general/reservation-origin-channel-type-and-rate-description-by-property-group/${payload.pgId}`, query, {}, {})
                
                let boardTypes = res.items.map(
                    item => 
                    {
                        return {
                            "metadata": {
                                "id": item.spec.property_group_id,
                                "name": item.spec.name
                            }
                        }
                    }
                )
                boardTypes = [...new Set(boardTypes)]

                context.commit("resetBoardTypesList", {
                    values: boardTypes,
                })
                return boardTypes
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the board types for the selected pg: ${error}`,
                }, {root: true})
                return null
            }
		},

        // rate descriptions
		async fetchPgRateDescriptionsList(context, payload) {
			const api = context.rootState.api.api;

            try {
                const query = {
                    select_option: 11
                }
                const res = await api.get("reservation/v2", `/general/reservation-origin-channel-type-and-rate-description-by-property-group/${payload.pgId}`, query, {}, {})

                let rateDescriptions = res.items.map(
                    item => 
                    {
                        return {
                            "metadata": {
                                "id": item.spec.property_group_id,
                                "name": item.spec.name
                            }
                        }
                    }
                )
                rateDescriptions = [...new Set(rateDescriptions)]

                context.commit("resetRateDescriptionsList", {
                    values: rateDescriptions,
                })
                return rateDescriptions
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the rate descriptions for the selected pg: ${error}`,
                }, {root: true})
                return null
            }
		},

        // origin channel types
		async fetchPgOriginChannelTypesList(context, payload) {
			const api = context.rootState.api.api;

            try {
                const query = {
                    select_option: 10
                }
                const res = await api.get("reservation/v2", `/general/reservation-origin-channel-type-and-rate-description-by-property-group/${payload.pgId}`, query, {}, {})

                let originChannelTypes = res.items.map(
                    item => 
                    {
                        return {
                            "metadata": {
                                "id": item.spec.property_group_id,
                                "name": item.spec.name
                            }
                        }
                    }
                )
                originChannelTypes = [...new Set(originChannelTypes)]


                context.commit("resetOriginChannelTypesList", {
                    values: originChannelTypes,
                })
                return originChannelTypes
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the origin channel types for the selected pg: ${error}`,
                }, {root: true})
                return null
            }
		},
    }
}