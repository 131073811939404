var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","dark":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Show holidays after","prepend-icon":"mdi-calendar"},on:{"change":function($event){return _vm.fetchHolidays()}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"header-color":"primary"},on:{"change":function($event){return _vm.fetchHolidays()}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.holidaysToShow,"sort-by":"holiday_date","item-key":"uniqueKey","items-per-page":100,"footer-props":{
                    itemsPerPageOptions: [100, 500,  -1],
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus'
            }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }