<template>
  <div>
    <v-container fluid>
      <v-row class="align-center">
        <v-autocomplete
          class="col-10 col-md-10"
          :items="propertySets"
          v-model="selectedPg"
          label="Property Group"
        ></v-autocomplete>
        <v-btn
          class="col-1 col-md-1 mx-auto"
          color="success"
          :loading="loadingData"
          :disabled="loadingData"
          @click="submitChanges"
          >Submit</v-btn
        >
      </v-row>
    </v-container>
    <v-container fluid v-if="config != null">
      <v-row>
        <v-col class="col-12 col-md-12">
          <v-card outlined class="mx-auto col-10 col-md-10">
            <v-card-title>
              Don't lost yourself in the configuration! Check the documentation:
              <a
                href="https://docs.google.com/document/d/12rc4D_1NIx4kdzS5UkuEZiR_29xPM04U8etlwXc7N28/edit?usp=sharing"
                target="_blank"
                >click here</a
              >
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-md-12">
          <v-card outlined class="mx-auto col-10 col-md-10">
            <v-card-title>Basic info</v-card-title>
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <p class="subtitle-1">Customer details</p>
                  <v-row>
                    <v-text-field
                      class="col-12 col-md-12"
                      v-model.number="name"
                      label="Accommodation Name"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      class="col-12 col-md-2"
                      v-model.number="config.application_fees.card.card_flat"
                      label="Card Fee Flat"
                      type="number"
                    ></v-text-field>
                    <v-text-field
                      class="col-12 col-md-2"
                      v-model.number="config.application_fees.card.card_eu"
                      label="Card Fee Europe"
                      type="number"
                    ></v-text-field>
                    <v-text-field
                      class="col-12 col-md-2"
                      v-model.number="config.application_fees.card.card_world"
                      label="Card Fee World"
                      type="number"
                    ></v-text-field>
                    <v-text-field
                      class="col-12 col-md-4"
                      v-model="config.merchantNotification.mail"
                      label="Customer Email (notification)"
                    ></v-text-field>
                    <v-switch
                      class="col-12 col-md-2"
                      v-model="config.cityTaxEnabled"
                      :label="`City tax: ${
                        config.cityTaxEnabled ? 'enabled' : 'disabled'
                      }`"
                    ></v-switch>
                  </v-row>
                  <p class="subtitle-1">
                    Accommodation contact info (used for Payment page and Email
                    footer)
                  </p>
                  <v-row>
                    <v-text-field
                      class="col-12 col-md-6"
                      v-model="config.contacts.address"
                      label="Accommodation Address"
                    ></v-text-field>
                    <v-text-field
                      class="col-12 col-md-6"
                      v-model="config.contacts.website"
                      label="Accommodation Website"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      class="col-12 col-md-4"
                      v-model="config.contacts.mail"
                      label="Accommodation Email"
                    ></v-text-field>
                    <v-text-field
                      class="col-12 col-md-4"
                      v-model="config.contacts.tel"
                      label="Accommodation Telephone"
                    ></v-text-field>
                    <v-text-field
                      class="col-12 col-md-4"
                      v-model="config.contacts.whats"
                      label="Accommodation Whatsapp"
                    ></v-text-field>
                  </v-row>
                  <p class="subtitle-1">Manual enabled channels</p>
                  <v-row>
                    <v-text-field
                      class="col-12 col-md-4"
                      v-model="config.messageOptions.senderAddress"
                      label="Custom sender address"
                    ></v-text-field>
                    <v-select
                      class="col-12 col-md-8"
                      v-model="config.messageOptions.manualChannels.value"
                      :items="availableChannels"
                      chips
                      label="Manual Channels"
                      multiple
                      :disabled="!config.messageOptions.manualChannels.included"
                    ></v-select>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-md-12">
          <v-card outlined class="mx-auto col-10 col-md-10">
            <v-card-title>Deposit</v-card-title>
            <v-card-text v-if="isDepositEnabled">
              <v-form v-model="valid">
                <v-container>
                  <v-row class="align-center">
                    <v-text-field
                      class="col-12 col-md-3"
                      v-model.number="config.deposit.value"
                      label="Percentage (i.e. 0.x)"
                      type="number"
                    ></v-text-field>
                    <v-text-field
                      class="col-12 col-md-3"
                      v-model.number="config.deposit.checkinLimit"
                      label="Checkin limit to pay deposit"
                      type="number"
                    ></v-text-field>
                    <v-select
                      class="col-12 col-md-3"
                      v-model="reservationType"
                      item-text="name"
                      item-value="value"
                      :items="reservationTypes"
                      label="Reservation type"
                      dense
                    ></v-select>
                    <v-select
                      class="col-12 col-md-3"
                      v-model="config.deposit.channels.value"
                      :items="availableChannels"
                      chips
                      label="Channels"
                      multiple
                    ></v-select>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-switch
                v-model="isDepositEnabled"
                class="col-12"
                :label="`Deposit: ${isDepositEnabled ? 'enabled' : 'disalbed'}`"
              ></v-switch>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-md-12">
          <v-card outlined class="mx-auto col-10 col-md-10">
            <v-card-title>Upselling</v-card-title>
            <v-card-subtitle
              >Use this section to specify the upselling page customer link and
              which services to enable</v-card-subtitle
            >
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row class="align-center">
                    <v-text-field
                      class="col-12 col-md-3"
                      v-model="config.upselling.link"
                      label="Customer link (e.g. 1dest, campo24...)"
                    ></v-text-field>
                    <v-switch
                      v-model="config.upselling.internal"
                      class="col-12 col-md-3"
                      :label="`Internal: ${
                        config.upselling.internal ? 'enabled' : 'disalbed'
                      }`"
                    ></v-switch>
                    <v-switch
                      v-model="config.upselling.freedome"
                      class="col-12 col-md-3"
                      :label="`Freedome: ${
                        config.upselling.freedome ? 'enabled' : 'disalbed'
                      }`"
                    ></v-switch>
                    <v-switch
                      v-model="config.upselling.tiqets"
                      class="col-12 col-md-3"
                      :label="`Tiqets: ${
                        config.upselling.tiqets ? 'enabled' : 'disalbed'
                      }`"
                    ></v-switch>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-md-12">
          <v-card outlined class="mx-auto col-10 col-md-10">
            <v-card-title>QR code options</v-card-title>
            <v-card-subtitle
              >Use this section to edit when the Payment QR Codes should be
              enabled for paying the reservation</v-card-subtitle
            >
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row class="align-center">
                    <v-text-field
                      class="col-12 col-md-6"
                      v-model.number="config.qrOptions.afterCheckinDays"
                      label="When to enable QR after checkin in days"
                      type="number"
                    ></v-text-field>
                    <v-text-field
                      class="col-12 col-md-6"
                      v-model.number="config.qrOptions.beforeCheckoutDays"
                      label="When to enable QR before checkout in days"
                      type="number"
                    ></v-text-field>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-md-12">
          <v-card outlined class="mx-auto col-10 col-md-10">
            <v-card-title>Automation Mail</v-card-title>
            <v-card-subtitle
              >Use this section to edit when the automations should be enabled
              or disabled on mail channel</v-card-subtitle
            >
            <v-card-text>
              <ExpansionPanelsAutomation
                v-for="(item, name) in config.automations.mail"
                :key="name"
                :modelValue="config.automations.mail[name]"
                @update:modelValue="
                  handleAutomationConfigUpdate(name, 'mail', $event)
                "
                :mexKind="name"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-md-12">
          <v-card outlined class="mx-auto col-10 col-md-10">
            <v-card-title>Automation Whatsapp</v-card-title>
            <v-card-subtitle
              >Use this section to edit when the automations should be enabled
              or disabled on Whatsapp channel</v-card-subtitle
            >
            <v-card-text>
              <ExpansionPanelsAutomation
                v-for="(item, name) in config.automations.whats"
                :key="name"
                :modelValue="config.automations.whats[name]"
                @update:modelValue="
                  handleAutomationConfigUpdate(name, 'whats', $event)
                "
                :mexKind="name"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ConfirmDialog ref="dialog" />
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import ExpansionPanelsAutomation from "../components/ExpansionPanelsAutomation.vue";
import ConfirmDialog from "@/components/utils/ConfirmDialog";
import { ref } from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  components: {
    ExpansionPanelsAutomation,
    ConfirmDialog,
  },
  name: "Support",
  data: function () {
    return {
      loadingData: false,
      propertySets: [],
      selectedPg: null,
      config: ref(null),
      name: ref(null),
      valid: true,
      reservationTypes: [
        { name: "All", value: 0 },
        { name: "Refundable", value: 1 },
        { name: "Not refundable", value: 2 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      availableChannels: "smartpaying/availableChannels",
    }),
    reservationType: {
      get() {
        switch (this.config.deposit.reservationType) {
          case 0:
            return { name: "All", value: 0 };
          case 1:
            return { name: "Refundable", value: 1 };
          case 2:
            return { name: "Not refundable", value: 2 };
        }
      },
      set(value) {
        this.config.deposit.reservationType = value;
      },
    },
    isDepositEnabled: {
      get() {
        return this.config.deposit != null;
      },
      set(value) {
        if (value == true) {
          this.config.deposit = {
            type: "percent",
            value: 0,
            checkinLimit: 0,
            reservationType: 0,
            channels: {
              included: true,
              value: [],
            },
          };
        } else {
          this.config.deposit = null;
        }
      },
    },
  },
  /*
  computed: {
    manualChannels: {
      get() {
        return this.config.messageOptions?.manualChannels?.included == true ? this.config.messageOptions?.manualChannels?.value : []
      },
      set(value) {
        this.config.messageOptions.manualChannels.included = true
        this.config.messageOptions.manualChannels.value = value
      }
    },
    manualChannelsExcluded: this.config.messageOptions?.manualChannels?.included == false
  },
  */
  watch: {
    selectedPg: {
      async handler(newPg) {
        if (newPg != null) {
          const resp = await this.$store.dispatch(
            "smartpaying/fetchpropertySetById",
            { pgId: newPg }
          );
          await this.$store.dispatch("smartpaying/fetchpropertySetChannels", {
            pgId: newPg,
          });
          this.updateSelectedPg(resp);
        }
      },
    },
  },
  methods: {
    handleAutomationConfigUpdate(name, channel, $event) {
      console.log("Model value updated to: ", name, $event);
      console.log(this.config, this.config.value);
      this.config.automations[channel][name] = $event;
    },
    updateSelectedPg(selectedPg) {
      this.name = ref(selectedPg.name)
      const newConfig = selectedPg.config
      this.config = ref({
        application_fees: {
          card: {
            card_flat: newConfig.application_fees.card.card_flat || null,
            card_eu: newConfig.application_fees.card.card_eu || null,
            card_world: newConfig.application_fees.card.card_world || null,
          },
        },
        cityTaxEnabled: newConfig.cityTaxEnabled,
        cityTaxFromPMS: newConfig.cityTaxFromPMS,
        deposit: newConfig.deposit,
        qrOptions: {
          afterCheckinDays: newConfig.qrOptions?.afterCheckinDays || null,
          afterCheckoutDays: newConfig.qrOptions?.afterCheckoutDays || null,
          beforeCheckinDays: newConfig.qrOptions?.beforeCheckinDays || null,
          beforeCheckoutDays: newConfig.qrOptions?.beforeCheckoutDays || null,
        },
        messageOptions: {
          senderAddress: newConfig.messageOptions.senderAddress || null,
          manualChannels: {
            included: newConfig.messageOptions.manualChannels.included,
            value: newConfig.messageOptions.manualChannels.value,
          },
        },
        automations: {
          mail: {
            afterCheckinDays:
              newConfig.automations.mail?.afterCheckinDays || null,
            afterCreationDays:
              newConfig.automations.mail?.afterCreationDays || null,
            beforeCheckinDays:
              newConfig.automations.mail?.beforeCheckinDays || null,
            beforeCheckoutDays:
              newConfig.automations.mail?.beforeCheckoutDays || null,
            depositDays: newConfig.automations.mail?.depositDays || null,
            upsellingProposalCheckin:
              newConfig.automations.mail?.upsellingProposalCheckin || null,
            upsellingProposalCreation:
              newConfig.automations.mail?.upsellingProposalCreation || null,
          },
          whats: {
            upsellingProposalCheckin:
              newConfig.automations.whats?.upsellingProposalCheckin || null,
            upsellingProposalCreation:
              newConfig.automations.whats?.upsellingProposalCreation || null,
          },
        },
        paymentMethods: ["card"],
        clientNotification: {
          sms: newConfig.clientNotification.sms,
          mail: newConfig.clientNotification.mail,
        },
        merchantNotification: {
          phone: newConfig.merchantNotification.phone,
          lang: "it",
          mail: newConfig.merchantNotification.mail,
        },
        contacts: {
          address: newConfig.contacts.address || null,
          country: "IT",
          mail: newConfig.contacts.mail || null,
          tel: newConfig.contacts.tel || null,
          website: newConfig.contacts.website || null,
          whats: newConfig.contacts.whats || null,
        },
        upselling: {
          link: newConfig.upselling?.link || "",
          internal: newConfig.upselling?.internal || false,
          freedome: newConfig.upselling?.freedome || false,
          tiqets: newConfig.upselling?.tiqets || false,
        },
      });
    },
    async submitChanges() {
      const resp = await this.$store.dispatch("smartpaying/updatePropertySet", {
        pgId: this.selectedPg,
        name: this.name,
        config: this.config,
      });
      if (resp.response?.status == 400) {
        const options = { cardTextClass: "pa-4", color: "orange", width: 800 };
        const title = "Ouch, I cannot update the PG with this config";
        let errorExplanation = "";
        for (const error in resp.response.data) {
          errorExplanation += `${error} - ${JSON.stringify(
            resp.response.data[error]
          )}<br>`;
        }
        const message = `
        <img
        :height="100"
        src="https://media2.giphy.com/media/uIGfoVAK9iU1y/200w.gif?cid=82a1493b1v3e41ng6t0g11cr68kwf975ka2glaga9tefpkui&ep=v1_gifs_related&rid=200w.gif&ct=g"
      ></img>
        <h2>Let me show the errors</h2>
        <p>${errorExplanation}</p>`;
        await this.$refs.dialog.open(title, message, options);
      } else if (resp.response?.status == 401) {
        const options = { cardTextClass: "pa-4", color: "orange", width: 800 };
        const title = "Your auth session is expired";
        let errorExplanation = "";
        for (const error in resp.response.data) {
          errorExplanation += `${error} - ${JSON.stringify(
            resp.response.data[error]
          )}<br>`;
        }
        const message = `Please refresh the page`;
        await this.$refs.dialog.open(title, message, options);
      } else {
        const options = {
          cardTextClass: "pa-4 accent-1--text",
          color: "success",
        };
        const title = `Property group ${this.selectedPg} updated correctly`;
        const message = ``;
        await this.$refs.dialog.open(title, message, options);
      }
    },
  },
  async mounted() {
    console.log(this.config);
    const propertySets = await this.$store.dispatch(
      "smartpaying/fetchpropertySets"
    );
    this.propertySets = propertySets;
  },
});
</script>
<style lang="scss"></style>
