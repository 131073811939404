<template>
    <v-card class="card-ui pa-5">
        <v-card-title>Property Group Parent</v-card-title>
        <v-card-text>
            <div v-if="!!pgParents?.length">
                <p class="py-4" v-for="parent of pgParents">{{ `${parent} - ${pgNameById[parent]}` }}</p>
            </div>
            <p v-else>Parent Not Set</p>
        </v-card-text>
        <v-card-title>Update Children</v-card-title>
        <v-card-text>
            <v-autocomplete multiple chips dense deletable-chips outlined :items="propertyGroupList"
                v-model="selectedPgCurrentChildren" :disabled="editDisabled || !!pgParents?.length" :clearable='true'>
            </v-autocomplete>
        </v-card-text>
        <v-card-actions class="pb-4">
            <v-btn class="ml-2 mr-4" color="secondary" :disabled="editDisabled || !!pgParents?.length" :loading="loadingSavingChildren"
                @click="savePGChildren">
                Update Children
            </v-btn>
            <v-btn class="mx-3" :disabled="editDisabled || !!pgParents?.length" @click="resetChanges">
                Reset Children Changes
            </v-btn>
            <v-dialog transition="dialog-bottom-transition" max-width="800">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" v-bind="attrs" v-on="on" class="mx-3" v-show="!!selectedPgCurrentChildren.length">Children
                        Bulk Status Edit</v-btn>
                </template>
                <template v-slot:default="bulkstatuseditdialog">
                    <BulkEditChildrenStatus @closeDialog="bulkstatuseditdialog.value = false"></BulkEditChildrenStatus>
                </template>
            </v-dialog>

            <!-- Automapping dialog -->
            <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" v-bind="attrs" v-on="on" class="mx-3">Execute Automapping</v-btn>
                </template>
                <template v-slot:default="automappingdialog">
                    <v-card>
                        <v-toolbar color="primary" dark>Execute auto mapping confirmation</v-toolbar>

                        <v-card-text class="pa-8">
                            Do you really want to execute the automapping for pg {{ pgInfo.metadata.name }}
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn color="primary" @click="executeAutomapping({ pgId: pgInfo.metadata.id })"
                                v-on:click="automappingdialog.value = false">
                                Execute Automapping
                            </v-btn>
                            <v-btn text color="red" @click="automappingdialog.value = false">
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-card-actions>
    </v-card>
</template>

<script>

import { mapGetters, mapState, mapActions } from 'vuex';
import BulkEditChildrenStatus from './BulkEditChildrenStatus.vue';

export default {
    components: { BulkEditChildrenStatus },
    data() {
        return {
            pgStatusConfirmDialog: false,
            selectedPgCurrentChildren: [],
            loadingSavingChildren: false
        };
    },
    watch: {
        currentChildren(newVal, oldVal) {
            this.selectedPgCurrentChildren = structuredClone(newVal)
        } 
    },
    computed: {
        ...mapState({
            pgParents: state => state.core.currentParent,
            pgInfo: state => state.core.currentPG,
            currentChildren: state => state.core.currentChildren
        }),
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
            pgNameById: "core/pgNameById",
            bundlesList: "lifecycle/bundlesList",
            currentLifecycleStatus: "lifecycle/currentLifecycleStateByBundle",
        }),
        propertyGroupList() {
            const possibleChildren = this.$store.getters["core/pgsDropdown"];
            const selectedPG = this.globalSelectedPgId;
            return possibleChildren.filter(children => {
                if (children.value === selectedPG) {
                    return false;
                }
                return true;
            });
        },
        editDisabled() {
            return !this.currentLifecycleStatus?.['smartpricing']?.spec?.fields?.update?.propertyGroup;
        },
    },
    methods: {
        ...mapActions({
            executeAutomapping: "core/executeAutomapping",
        }),
        resetChanges() {
            this.selectedPgCurrentChildren = this.$store.state.core.currentChildren;
        },
        async savePGChildren() {
            this.loadingSavingChildren = true;
            await this.$store.dispatch('core/updatePgChildren', {
                newChildren: this.selectedPgCurrentChildren,
            });
            this.resetChanges();
            this.loadingSavingChildren = false;
        },
    },
    async created() {
        this.resetChanges();
    },
}

</script>
