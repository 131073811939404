  <template>
    <v-card>
      <v-card-text>
        <v-stepper non-linear>
          <v-stepper-header>
            <v-stepper-step
              editable
              step="1"
            >
              Event Settings
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              editable
              step="2"
            >
              Dates
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="3"
              editable
              v-if="eventObj.parentId"
            >
              Property Groups
            </v-stepper-step>

            <v-stepper-step
              step="3"
              editable
              v-else-if="!eventObj.parentId"
            >
              Locations
            </v-stepper-step>
          </v-stepper-header>

          <b v-if="isCreatedByUser || isChildEventTypeUser" class="red--text pl-4">This Event was created by the User. You can't modify it</b>

          <v-stepper-items>
            <v-stepper-content step="1">
              <DynamicForm
                v-if="type !== 'add'"
                @input="selectedEventToEdit"
                v-bind:value="eventObj"
                :schema="getEditDict"
                :editable="true"
              />


              <DynamicForm
                @input="updateEventObj"
                v-bind:value="eventObj"
                :schema="getEventDict"
                :editable="true"
              />
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-date-picker
                        v-model="dates"
                        multiple
                        :events="parentEventDatesFormatted"
                        event-color="green lighten-1"
                        :picker-date.sync="pickerDate"
                        :readonly="isDatePickerReadOnly"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="showToday"
                        >
                          Today
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="resetDates"
                        >
                          Reset
                        </v-btn>
                      </v-date-picker>
                    </v-col>

                    <!-- ------------------------------------- -->

                    <v-col>
                      <v-card>
                        <v-card-title>
                          Impacted Dates
                        </v-card-title>
                        <v-data-table
                          :headers="datesHeaders"
                          :items="impactedDates"
                          :item-class="datesRowClass"
                          @click:row="clickRow"
                        >
                          <template v-slot:item.weight="{ item }">
                            <v-edit-dialog
                              v-if="!item.isUpdatedByUser"
                              :return-value.sync="item.weight"
                              large
                              persistent
                            >
                              {{ item.weight }}
                              <template v-slot:input>
                                <div class="mt-4 text-h6">
                                  Update weight
                                </div>
                                <v-text-field
                                  v-model="item.weight"
                                  :rules="[ rules.numeric ]"
                                  label="Edit"
                                  single-line
                                  counter
                                  autofocus
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>

                            <template v-else>
                              {{ item.weight }}
                            </template>
                          </template>
                        </v-data-table>
                      </v-card>

                      <v-card
                        v-if="parentEventImpactedDates?.length > 0"
                      >
                        <v-card-title>
                          Parent Impacted Dates
                        </v-card-title>
                        <v-data-table
                          :headers="datesHeaders"
                          :items="parentEventImpactedDates"
                          @click:row="clickRow"
                        />
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                v-if="eventObj.parentId"
              >
                <v-autocomplete
                  label="Property Group List"
                  v-model="targetPgList"
                  :items="propertyGroupList"
                  :disabled="type === 'edit'"
                  multiple
                  outlined
                  dense
                  chips
                />
              </v-card>

              <v-card
                v-else="eventObj.parentId"
              >
                <DynamicForm
                  @input="selectedEventToClone"
                  v-bind:value="eventToClone"
                  :schema="getLocationDict"
                  :editable="true"
                />

                <v-data-table
                  :headers="locationsHeaders"
                  :items="impactedLocations"
                  disable-pagination
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <div v-if="isEmbedded">
                        <v-row>
                          <v-col class="font-weight-bold">
                            Copy from parent event
                          </v-col>
                          <v-col
                            v-for="kind in locationsKindList"
                          >
                            <v-switch
                              v-model="locationsToCopyFromParent"
                              color="primary"
                              :label="kind.label"
                              :value="kind.value"
                              :disabled="kind.disabled"
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        @click="addLocation"
                      >
                        Add Line
                      </v-btn>
                    </v-toolbar>
                  </template>

                  <template v-slot:item.location="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.location"
                      large
                      persistent
                    >
                      {{ props.item.location }}
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update location
                        </div>
                        <DynamicForm
                          @input="selectedLocation($event, props.index)"
                          v-bind:value="{id: props.item.location}"
                          :schema="locationsSchema"
                          :editable="true"
                        />
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.weight="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.weight"
                      large
                      persistent
                    >
                      {{ props.item.weight }}
                      <template v-slot:input>
                        <v-card>
                          <v-card-title>
                            Update weight
                          </v-card-title>
                          <v-card-subtitle
                            v-if="isEmbedded"
                            class="red--text"
                            >
                            We recommend to set weight 1 for all the locations/markets in common with the original event, in order to prevent errors in the visualization of the correct weight from users
                          </v-card-subtitle>
                          <v-card-text>
                            <v-text-field
                              v-model="props.item.weight"
                              :rules="[ rules.numeric ]"
                              label="Edit"
                              single-line
                              counter
                              autofocus
                              ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <v-icon
                      small
                      @click="deleteLocationsItem(item)"
                    >
                      fa-solid fa-trash
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <!-- Delete confirm overlay -->
        <v-overlay
          :value="confirmOverlay"
        >
          <v-card
            class="mx-auto pa-5"
            :color="hasAtleastOneDateEditedByUser ? 'red darken-3' : ''"
            max-width="500"
          >
            <v-card-text>
              <p :class="hasAtleastOneDateEditedByUser ? 'black--text text-h4' : 'black--text text-h4'">
                Confirm Deletion of Event
              </p>
              <div class="black--text" v-if="hasAtleastOneDateEditedByUser">
                The following event has a date edited by the client!!!!
              </div>
              <div :class="hasAtleastOneDateEditedByUser ? 'black--text' : 'text--primary'">
                Confirm that you're deleting the following event:
                {{ eventObj.eventId }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="type === 'edit'"
                :color="hasAtleastOneDateEditedByUser ? 'red' : 'red'"
                @click="deleteEvent"
              >
                Delete
              </v-btn>
              <v-btn
                :color="hasAtleastOneDateEditedByUser ? 'grey darken-3' : 'primary'"
                @click="confirmOverlay = !confirmOverlay"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-overlay>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              :disabled="!isValid"
              @click="save"
            >
              Save
            </v-btn>
            <v-btn
              text
              :disabled="!isEditable"
              @click="reset"
            >
              Reset
            </v-btn>
          </v-col>
          <v-col
            cols="2"
          >
            <v-btn
              v-if='type === "edit"'
              color="red"
              :disabled="!isEditable"
              @click="confirmOverlay = !confirmOverlay"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

  </template>

  <script>
  import DynamicForm from '@/components/form/DynamicForm'
  import { mergeDeep } from '@/utils/deepMerge'
  import { mapGetters } from 'vuex'

  export default {
    name: 'EventForm',
    props: ["type", "defaultEvent", "geocodingPgidList"],
    components: {DynamicForm},
    data() {
      return {
        confirmOverlay: false,
        eventObjLocal: {},
        eventToClone: {},
        eventList: [],
        eventId: [],
        selectedEvent: null,
        e1: 1,
        rules: {
          numeric: value => !isNaN(value) || 'Must be a number.',
        },
        datesHeaders: [
          {
            text: 'Date',
            align: 'start',
            value: 'date',
          },
          {
            text: 'Weight',
            value: 'weight',
          },
        ],
        locationsHeaders: [
          {
            text: 'Location',
            align: 'start',
            value: 'location',
          },
          {
            text: 'Weight',
            value: 'weight',
          },
          { 
            text: 'Delete',
            value: 'delete',
            sortable: false
          }
        ],
        impactedDatesLocal: [],
        impactedLocationsLocal: [],
        locationsToCopyLocal: [],
        targetPgListLocal: [],
        eventIdDictLocal: {},
        pickerDate: null,
        geocodingLocations: {},
        locationsKindListBase: [
          {
            label: "city",
            value: "Location:City"
          },
          {
            label: "market",
            value: "Location:Market"
          }
        ],
      }
    },
    methods: {
      clickRow(event){
        this.pickerDate = event.date
      },
      showToday(){
        const today = new Date()
        this.pickerDate = today.toISOString().split('T')[0]
      },
      resetDates(){
        this.impactedDates = []
      },
      nextStep (n) {
        this.e1 = n + 1
      },
      updateEventObj(obj){
        this.eventObj = obj
      },
      selectedEventToClone(obj){
        const event = this.getEventById(obj.id)
        this.setImpactedLocations(event)
      },
      setEventToEdit(event){
        if(!event){
          this.eventObj = {}
          return
        }
        this.eventObj = {
          eventId: event.metadata.id,
          eventLabel: event.spec.label,
          notes: event.spec.notes,
          recurrencyType: event.spec.recurrencyType,
        }
        if(event.link.RootEvent?.[0]?.id){
          this.eventObj.parentId = event.link.RootEvent[0].id
        }
      },
      setImpactedDates(event){
        this.impactedDates = event?.link.EventDate.map(d => {
          return {
            date: d.id,
            weight: d.value,
            isUpdatedByUser: d.isUpdatedByUser
          }
        }) || []
      },
      setImpactedLocations(event){
        this.impactedLocations = event?.link.EventTarget
          .map(t => {
            return {
              location: `${t.kind}::${t.id}`,
              weight: t.value
            }
          }) || []
      },
      setTargetPgList(event){
        this.targetPgList = event?.link.EventTarget?.map(t => t.id) || []
      },
      selectedEventToEdit(obj){
        this.selectedEvent = this.getEventById(obj.id)
        this.eventId = [obj.id]
        this.setEventToEdit(this.selectedEvent)
        this.setImpactedDates(this.selectedEvent)
        if(this.eventObj.parentId){
          this.setTargetPgList(this.selectedEvent)
        } else {
          this.setImpactedLocations(this.selectedEvent)
        }
      },
      getEventById(id){
        return this.events.find(e => e.metadata.id === id)
      },
      deleteLocationsItem(item){
        const indexToDelete = this.impactedLocations.indexOf(item)
        this.impactedLocations.splice(indexToDelete, 1)
      },
      reset(){
        this.resetDates()
        this.impactedLocations = []
        this.targetPgList = []
        this.selectedEvent = null

        if(this.type === 'edit' && this.eventId.length > 0){
          this.selectedEventToEdit({id: this.eventId})
        } else {
          this.eventObj = {}
        }

        if(this.isEmbedded){
          const defaultEvent = structuredClone(this.defaultEvent)
          defaultEvent.metadata.id = null
          this.fetchGeocodingLocations()
          this.setEventToEdit(defaultEvent)
          this.setImpactedDates(defaultEvent)
        }
      },
      async save(){
        const res = []
        try {
          let targetString
          if(this.type === 'edit'){
            targetString = 'graph/editEvent'
          } else {
            targetString = 'graph/addEvent'
          }
          await Promise.all(
            [...await this.eventToSaveList].map(async eventToSave => {
              if(this.isEmbedded){
                eventToSave.preventFetch = true
              }
              const r = await this.$store.dispatch(targetString, eventToSave)
              res.push(r)
            })
          )
          if(res.every(r => r.success)){
            if(this.isEmbedded){
              this.$emit('save-success', this.eventObj.eventId)
            } else {
              this.reset()
            }
          }
        } catch (e) {
          console.error(e)
          console.log(`Something went wrong: `, res)
        }
      },
      async deleteEvent(){
        const res = await this.$store.dispatch('graph/deleteEvent', { idToDelete: this.eventObj.eventId })
        if(res.success){
          this.eventId = []
          this.eventObj = {}
          // this.reset()
        }
        this.confirmOverlay = !this.confirmOverlay
      },
      addLocation(){
        this.impactedLocations.push({})
      },
      locationsCompareFn(a, b){
        return a.location < b.location
      },
      selectedLocation(newVal, index){
        const newLocation = {
          location: newVal.id,
          weight: this.impactedLocations[index].weight
        }
        this.impactedLocations.splice(index, 1, newLocation)
      },
      datesRowClass(item){
        if(item.isUpdatedByUser){
          return ["grey darken-3", "grey--text text--lighten-1"]
        }
      },
      createEventIdDict(targetPgList){
        const event = {...this.eventObj}
        const eventIdDict = targetPgList.reduce((acc, curr) => {
          acc[curr] = `${event.parentId}_${curr}`
          return acc
        }, {})
        event.eventId = Object.values(eventIdDict).toString()
        this.eventIdDict = eventIdDict
        this.eventObj = event
      },
      async fetchGeocodingLocations(){
        const pgidList = this.geocodingPgidList
        const locationsObj = {}
        const res = []
        try {
          await Promise.all(
            pgidList.map(async pgid => {
              const pg = await this.$store.dispatch('graph/getOne', {
                kind: 'propertygroup',
                id: pgid,
              })
              pg.items[0].link.EventSource.
                forEach(ev => {
                  locationsObj[ev.kind] = locationsObj[ev.kind] || {}
                  locationsObj[ev.kind][ev.id] = locationsObj[ev.kind][ev.id] || ev
                })
              res.push(pg)
            })
          )
          Object.entries(locationsObj).forEach(([key, value]) => {
            locationsObj[key] = Object.values(value)
          })
          this.geocodingLocations = locationsObj
        } catch (e) {
          console.error(e)
          console.log(`Something went wrong: `, res)
        }
      }
    },
    computed: {
      ...mapGetters({
        events: 'graph/eventsSorted',
        eventsIdList: 'graph/eventsIdList',
        eventsFiltered: 'graph/eventsFilteredIdList',
        locations: 'graph/locationsString',
        propertyGroupList: 'core/pgsDropdown',
      }),
      eventObj: {
        get(){
          return this.eventObjLocal
        },
        set(newValue){
          this.eventObjLocal = newValue
        }
      },
      eventLabelDisabled() {
        const parentSet = this.eventObj.parentId

        if (parentSet) {
          const parentEvent = this.getEventById(parentSet)

          if(this.type === 'add'){
            this.eventObj.eventLabel = parentEvent.spec.label
          }

          return true
        } else {
          return false
        }
      }, 
      getEventDict(){
        const arr = [
          {
            type: "string",
            label: "Event Id",
            key: "eventId",
            disabled: this.type === 'edit' || this.eventObj.parentId
          },
          {
            type: "string",
            label: "Event Label",
            key: "eventLabel",
            disabled: this.eventLabelDisabled,
          },
          {
            type:"select_autocomplete",
            label: "Recurrency Type",
            customRules: [ true ],
            key: "recurrencyType",
            value: ['none', 'custom' ]
          },
          {
            type: "textarea",
            label: "Notes",
            key: "notes",
          },
        ]

        if(!this.isEmbedded){
          arr.push(
            {
              type: "select_autocomplete",
              label: "Parent Event",
              key: "parentId",
              customRules: [ true ],
              disabled: this.type === 'edit',
              value: this.eventsFiltered
            }
          )
        }

        return arr
      },
      getEditDict(){
        return [
          {
            type: "select_autocomplete",
            label: "Event to edit",
            key: "id",
            value: this.eventsIdList
          },
        ]
      },
      getLocationDict(){
        return [
          {
            type: "select_autocomplete",
            label: "Copy Locations From Event",
            key: "id",
            customRules: [ true ],
            value: this.eventsFiltered
          },
        ]
      },
      impactedDates: {
        get(){
          return this.impactedDatesLocal
        },
        set(newValue){
          this.impactedDatesLocal = newValue
        }
      },
      dates: {
        get(){
          return this.impactedDates.map(d => d.date)
        },
        set(newValue){
          newValue.forEach(d => {
            if(!this.dates.includes(d)){
              this.impactedDates.push({
                date: d,
                weight: 1
              })
            }
          })

          this.impactedDates.forEach((d, i) => {
            if(!newValue.includes(d.date)){
              this.impactedDates.splice(i, 1)
            }
          })
        }
      },
      impactedLocations: {
        get(){
          return this.impactedLocationsLocal
        },
        set(newValue){
          this.impactedLocationsLocal = newValue
        }
      },
      targetPgList: {
        get(){
          return this.targetPgListLocal
        },
        set(newValue){
          this.targetPgListLocal = newValue
          this.createEventIdDict(newValue)
        }
      },
      eventIdDict: {
        get(){
          return this.eventIdDictLocal
        },
        set(newValue){
          this.eventIdDictLocal = newValue
        }
      },
      isValidImpactedLocations(){
        return this.impactedLocations.every(l => !!l.weight && !isNaN(l.weight))
      },
      isValidImpactedDates(){
        return this.impactedDates.every(d => !!d.weight && !isNaN(d.weight))
      },
      isDatePickerReadOnly(){
        return this.impactedDates.some(d => !!d.isUpdatedByUser)
      },
      isFormValid(){
        return ( this.isValidImpactedLocations && this.isValidImpactedDates ) && ( this.eventObj.parentId ? this.targetPgList.length > 0 : true ) && this.eventObj.eventId?.length > 0 && this.eventObj.eventLabel && this.impactedDatesLocal.length  && ( this.impactedLocationsLocal.length ||  this.targetPgListLocal.length )
      },
      isChildEventTypeUser(){
        return this.selectedEvent?.spec?.childEventType === 'user'
      },
      isCreatedByUser(){
        return this.selectedEvent?.spec?.isCreatedByUser
      },
      isEditable(){
        if(this.type === 'edit' && this.selectedEvent?.spec){
          return !this.isCreatedByUser && !this.isChildEventTypeUser
        }
        return true
      },
      isValid(){
        if(this.type === 'edit'){
          return this.isEditable && this.isFormValid
        }
        return this.isFormValid
      },
      hasAtleastOneDateEditedByUser() {
        for ( let date of this.impactedDates ) {
          if ( date.isUpdatedByUser == true ) {
            return true
          }
        }
        return false      
      },
      async eventToSaveList(){
        const returnList = []
        const objToSave = {
          "kind": "Event",
          "spec": {
            "position": "",
            "label": this.eventObj.eventLabel,
            "notes": this.eventObj.notes,
            "recurrencyType": this.eventObj.recurrencyType
          },
          "link": {
            "EventTarget": this.impactedLocations.map(l => {
              const splitString = l.location.split("::")
              return {
                kind: splitString[0],
                id: splitString[1],
                value: l.weight
              }
            }),
            "EventDate": this.impactedDates.map(d => {
              return {
                kind: "Date",
                id: d.date,
                value: d.weight
              }
            })
          },
          "metadata": {
            "id": this.eventObj.eventId
          }
        }

        if(this.eventObj.parentId){
          if(this.type === 'add'){
            objToSave.spec.childEventType = 'smartpricing'
          }

          await Promise.all(
            this.targetPgList.map(async l => {
              const newObjToSave = structuredClone(objToSave)
              let value = await this.$store.dispatch('graph/getParentEventValue', {
                parentId: this.eventObj.parentId,
                pgId: l
              })

              newObjToSave.link.EventTarget = [{
                kind: "Propertygroup",
                id: l,
                value: value
              }]

              newObjToSave.link.RootEvent = [
                {
                  id: this.eventObj.parentId,
                  value: 1,
                  kind: "Event"
                }
              ]

              newObjToSave.metadata.id = this.eventIdDict[l]

              returnList.push(newObjToSave)
            })
          )
        } else {
          returnList.push(objToSave)
        }

        if(this.type === 'edit' && this.selectedEvent){
          return returnList.map(o => mergeDeep(this.selectedEvent, o))
        }

        return returnList
      },
    locationsSchema(){
      return [
        {
          type: "select_autocomplete",
          label: "Locations",
          key: "id",
          value: this.locations
        },
      ]
    },
    parentEvent(){
      if(this.eventObj.parentId){
        const event = this.getEventById(this.eventObj.parentId)
        return event
      }
    },
    parentEventDates(){
      return this.parentEvent?.link.EventDate
    },
    parentEventDatesFormatted(){
      return this.parentEventDates?.map(d => d.id)
    },
    parentEventImpactedDates(){
      return this.parentEventDates?.map(d => {
        return {
          date: d.id,
          weight: d.value
        }
      })
    },
    isEmbedded(){
      return !!this.defaultEvent
    },
      locationsToCopyFromParent: {
        get(){
          return this.locationsToCopyLocal
        },
        set(newValue){
          const locations = []
          newValue.forEach(l => {
            const arr = this.geocodingLocations[l]
              .map(t => {
                return {
                  location: `${t.kind}::${t.id}`,
                  weight: 1
                }
              }) || []
            locations.push(...arr)
          })
          this.impactedLocations = locations
          this.locationsToCopyLocal = newValue
        }
      },
      locationsKindList(){
        const keysList = Object.keys(this.geocodingLocations)
        return this.locationsKindListBase.map(obj => {
          obj.disabled = !keysList.includes(obj.value)
          return obj
        })
      }
  },
  async mounted(){
    if(!this.isEmbedded) return

    const defaultEvent = structuredClone(this.defaultEvent)
    defaultEvent.metadata.id = null
    this.fetchGeocodingLocations()
    this.setEventToEdit(defaultEvent)
    this.setImpactedDates(defaultEvent)
  },
}
</script>
