// store for the pipeline v2 (aka Automatic Pipeline)
import Vue from "vue"

export default {
    namespaced: true,

    state: {
        // global request priority
        globalRequestPriority: "0.8",
        
        // pipeline pipelinebinds data
        pipelineData: null,

        // loading preview
        pipelineLoadingPreview: false,

        pipelineCurrentEdits: {},
        
        basePriceMaps: null,
        basePriceMapsRequired: false,
        basePriceMapStep: "",
    },

    getters: {

        pipelineMasterInError(state) {
            return !!state?.pipelineData?.master ? "error" in state?.pipelineData?.master : false
        },

        pipelineTestInError(state) {
            return !!state?.pipelineData?.test ? "error" in state?.pipelineData?.test : false
        },
       
        pipelineLoaded(state, getters) {
            return !!state.pipelineData
        },

        // gestione di merda lo so ma è da sistemare e pensare al meglio dato che metà delle interazioni e altro son venute fuori dopo il design iniziale
        errorTypeMaster(state, getters) {
            if (getters.pipelineMasterInError) {
                if (String(state?.pipelineData?.master?.error?.error).includes("not present in the base price df")) {
                    return "baseprice"
                } else {
                    return "general"
                }
            }
        },

        errorTypeTest(state, getters) {
            if (getters.pipelineTestInError) {
                if (String(state?.pipelineData?.test?.error?.error).includes("not present in the base price df")) {
                    return "baseprice"
                } else {
                    return "general"
                }
            }
        },


        pipelineCurrentEditsFormatted(state) {
            const editsConfig = {}

            // add demand maps edits
            if (!!state.pipelineCurrentEdits?.demandMapsEdits) {
                if (!editsConfig?.demand) editsConfig.demand = {}

                editsConfig.demand.edits = state.pipelineCurrentEdits?.demandMapsEdits
            }

            // add demand map weight
            if (!!state.pipelineCurrentEdits?.demandMapsWeight) {
                if (!editsConfig?.demand) editsConfig.demand = {}

                editsConfig.demand.weight = state.pipelineCurrentEdits.demandMapsWeight
            }

            // add dow weight
            if (!!state.pipelineCurrentEdits?.dowWeight) {
                if (!editsConfig?.dow) editsConfig.dow = {}
                editsConfig.dow.weight = state.pipelineCurrentEdits.dowWeight
            }

            // add dow edits
            if (!!state.pipelineCurrentEdits?.dowMaps) {
                if (!editsConfig?.dow) editsConfig.dow = {}
                editsConfig.dow.map = state.pipelineCurrentEdits?.dowMaps
            }

            // add events weight
            if (!!state.pipelineCurrentEdits?.eventsWeight) {
                if (!editsConfig?.events) editsConfig.events = {}
                editsConfig.events.weight = state.pipelineCurrentEdits.eventsWeight
            }

            if (state.pipelineCurrentEdits?.basePrice) {
                if (!editsConfig?.baseprice) editsConfig.baseprice = structuredClone(state.pipelineData?.["test"]?.baseprice || {spec: {}, value: {}})

                editsConfig.baseprice.spec.edits = state.pipelineCurrentEdits.basePrice.modifiers
                editsConfig.baseprice.value = state.pipelineCurrentEdits.basePrice.dataFrames
            }

            if ("basePrice7DaysSpan" in state.pipelineCurrentEdits) {
                if (!editsConfig?.baseprice) editsConfig.baseprice = structuredClone(state.pipelineData?.["test"]?.baseprice || {spec: {}, value: {}})

                editsConfig.baseprice.spec.min_span_7_days = state.pipelineCurrentEdits.basePrice7DaysSpan
            }

            if ("externalMarketCorrectionWeight" in state.pipelineCurrentEdits) {
                if (!editsConfig?.market_correction) editsConfig.market_correction = {}

                editsConfig.market_correction.weight = state.pipelineCurrentEdits.externalMarketCorrectionWeight
            }
            
            return editsConfig
        }

    },

    mutations: {

        resetPipelineV2(state) {
            state.pipelineData = null
            state.pipelineLoadingPreview = false
            state.pipelineCurrentEdits = {}
            state.basePriceMaps = null
            state.basePriceMapsRequired = false
            state.basePriceMapStep = ""
        },

        resetBasePriceMapsRequired(state, payload) {
            state.basePriceMapsRequired = payload?.value !== undefined ? payload.value : false
            state.basePriceMapStep = payload?.nextStep || ""
        },

        resetBasePriceMaps(state, payload) {
            state.basePriceMaps = {}
            if (!!payload?.value) {
                state.basePriceMaps = payload.value
            }
        },
        
        resetPipelineData(state, payload) {
            state.pipelineData = null
            if (!!payload?.value) {
                state.pipelineData = payload.value
            }
        },

        resetPipelineLoadingPreview(state, payload) {
            state.pipelineLoadingPreview = payload.value
        },

        resetPipelineCurrentEdits(state) {
            state.pipelineCurrentEdits = {}
            Object.keys(state.pipelineCurrentEdits).forEach(key => {
                Vue.delete(state.pipelineCurrentEdits, key)
            })
        },

        updatePipelineCurrentEdits(state, payload) {
            Vue.set(state.pipelineCurrentEdits, payload.key, payload.value)
        },

    },


    actions: {


        // preview the prices to execute every time the pipeline is loading
        async preview(context, payload) {
            const api = context.rootState.api.api
            
            try {
                const res = await api.put(`predictapi/v1/pipeline-v2`, `/master/preview/${payload.psId}`, {priority: context.state.globalRequestPriority, force: payload?.force || false}, payload?.config || {}, {})

                if ( res?.preprocess?.reconciliations_error != null ) {
                    context.dispatch("alertSystem/newWarning", {
                        message: `The autoreconciliation was disabled because: ${res?.preprocess?.reconciliations_error} \n
                        Please fix the rate_occupancy and regenerate the strategy due to the base-price differences!`,
                        timeout: -1
                    }, {root: true})
                }

                context.commit("resetPipelineData", {value: res})

                context.dispatch("checkForErrors", {data: res})
                
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to generate the new resources: ${error}`,
                }, {root: true})
            }
        },

        async checkForErrors(context, payload) {
            Object.entries(payload.data).forEach(([pipelineName, pipelineData]) => {
                if ("error" in pipelineData) {
                    context.dispatch("alertSystem/newError", {
                        message: `Error inside ${pipelineName}: ${JSON.stringify(pipelineData.error)}`,
                        timeout: -1,
                    }, {root: true})
                }
            })
        },

        // ----------------
        // pipeline interactions

        async load(context, payload) {
            try {
                context.commit("resetPipelineV2")
                context.commit("resetPipelineLoadingPreview", {value: true})
                await context.dispatch("preview", {psId: payload.psId, force: true})

                context.dispatch("loadChartsBasedOnErrors", {toRun: ["test", "master"]})

                context.commit("resetPipelineLoadingPreview", {value: false})
            } catch (error) {
                context.commit("resetPipelineLoadingPreview", {value: false})
                context.dispatch("alertSystem/newError", {
                    message: `Error loading the pipeline v2: ${error}`,
                }, {root: true})
            }
        },

        // generate new with complete inference and puts it inside the cache
        async generateNew(context, payload) {
            const api = context.rootState.api.api

            try {
                context.commit("resetPipelineLoadingPreview", {value: true})

                const res = await api.put(`predictapi/v1/pipeline-v2`, `/master/new/${payload.psId}?priority=0.8`, {}, payload?.config || {}, {})

                if (res instanceof Error) throw res?.response?.data?.error?.error || res?.response?.data

                if (res.outcomes?.[`baseprice.${payload.psId}-test`]?.error === "No valid base price map") {
                    context.commit("resetBasePriceMapsRequired", {value: true, nextStep: "new"})
                    context.commit("resetBasePriceMaps", {value: res.outcomes?.[`baseprice.${payload.psId}-test`]?.base_price_map_combined.suggested})
                }
                
                await context.dispatch("preview", {psId: payload.psId})
                
                context.dispatch("loadChartsBasedOnErrors", {toRun: ["test"]})
                context.commit("resetPipelineLoadingPreview", {value: false})
            } catch (error) {

                if ( Array.isArray(error?.["error"]) ) {
                    error["error"][0] = "\n - " + error["error"][0]
                    error = error["error"].join("\n - ")
                }

                context.commit("resetPipelineLoadingPreview", {value: false})
                context.dispatch("alertSystem/newError", {
                    message: `Failed to generate the new resources: ${error}`,
                }, {root: true})
            }
        },

        // adds edits
        async edit(context, payload) {
            const api = context.rootState.api.api

            try {
                context.commit("resetPipelineLoadingPreview", {value: true})
                const res = await api.put(`predictapi/v1/pipeline-v2`, `/master/edit/${payload.psId}`, {}, payload.config, {})

                context.commit("resetPipelineCurrentEdits")
                if (res instanceof Error) throw res?.response?.data?.error?.error || JSON.stringify(res?.response?.data)

                await context.dispatch("preview", {psId: payload.psId})
                
                context.dispatch("loadChartsBasedOnErrors", {toRun: ["test"]})
                
                context.commit("resetPipelineLoadingPreview", {value: false})  
            } catch (error) {
                context.commit("resetPipelineLoadingPreview", {value: false})
                context.commit("resetPipelineCurrentEdits")
                context.dispatch("alertSystem/newError", {
                    message: `Failed to edit the pipeline resources: ${error}`,
                }, {root: true})
            }
        },

        // reset cache
        async resetCache(context, payload) {
            const api = context.rootState.api.api

            try {
                context.commit("resetPipelineLoadingPreview", {value: true})
                const res = await api.put(`predictapi/v1/pipeline-v2`, `/master/reset/${payload.psId}/tmp`, {}, {}, {})
                
                if (res instanceof Error) throw res?.response?.data?.error?.error || JSON.stringify(res?.response?.data)

                await context.dispatch("preview", {psId: payload.psId})
                
                context.dispatch("loadChartsBasedOnErrors", {toRun: ["test"]})
                context.commit("resetPipelineLoadingPreview", {value: false})
            } catch (error) {
                context.commit("resetPipelineLoadingPreview", {value: false})
                context.dispatch("alertSystem/newError", {
                    message: `Failed to reset the cache: ${error}`,
                }, {root: true})
            }
        },

        // overwrite master into test
        async resetTest(context, payload) {
            const api = context.rootState.api.api

            try {
                context.commit("resetPipelineLoadingPreview", {value: true})
                const res = await api.put(`predictapi/v1/pipeline-v2`, `/master/reset/${payload.psId}/test`, {}, {}, {})

                if (res instanceof Error) throw res?.response?.data?.error?.error || JSON.stringify(res?.response?.data)

                await context.dispatch("preview", {psId: payload.psId})
                
                context.dispatch("loadChartsBasedOnErrors", {toRun: ["test"]})
                context.commit("resetPipelineLoadingPreview", {value: false})
            } catch (error) {
                context.commit("resetPipelineLoadingPreview", {value: false})
                context.dispatch("alertSystem/newError", {
                    message: `Failed to reset test pipeline: ${error}`,
                }, {root: true})
            }
        },

        // saves the cache to test
        async saveTest(context, payload) {
            const api = context.rootState.api.api

            try {
                context.commit("resetPipelineLoadingPreview", {value: true})
                const res = await api.put(`predictapi/v1/pipeline-v2`, `/master/save/${payload.psId}/test`, {}, {}, {})
                if (res instanceof Error) throw res?.response?.data?.error?.error || JSON.stringify(res?.response?.data)

                await context.dispatch("preview", {psId: payload.psId, force: true})
                
                context.dispatch("loadChartsBasedOnErrors", {toRun: ["test"]})
                context.commit("resetPipelineLoadingPreview", {value: false})
            } catch (error) {
                context.commit("resetPipelineLoadingPreview", {value: false})
                context.dispatch("alertSystem/newError", {
                    message: `Failed to reset test pipeline: ${error}`,
                }, {root: true})
            }
        },

        // saves the test to master
        async saveMaster(context, payload) {
            const api = context.rootState.api.api

            try {
                context.commit("resetPipelineLoadingPreview", {value: true})
                const res = await api.put(`predictapi/v1/pipeline-v2`, `/master/save/${payload.psId}/master`, {}, {}, {})

                if (res instanceof Error) throw res?.response?.data?.error?.error || JSON.stringify(res?.response?.data)

                await context.dispatch("preview", {psId: payload.psId, force: true})
                
                context.dispatch("loadChartsBasedOnErrors", {toRun: ["master"]})
                context.commit("resetPipelineLoadingPreview", {value: false})
            } catch (error) {
                context.commit("resetPipelineLoadingPreview", {value: false})
                context.dispatch("alertSystem/newError", {
                    message: `Failed to reset test pipeline: ${error}`,
                }, {root: true})
            }
        },

        async loadChartsBasedOnErrors(context, payload) {
        
            if (payload.toRun.includes("master") && !context.getters.pipelineMasterInError) {
                if (!context?.state?.pipelineData?.master?.error) {
                    await context.dispatch("pipelineV2Charts/loadAllCharts", {pipelineType: "master"}, {root: true})
                } else {
                    throw context?.state?.pipelineData?.test?.error?.error
                }
            }

            if (payload.toRun.includes("test") && !context.getters.pipelineTestInError) {
                if (!context?.state?.pipelineData?.test?.error) {
                    await context.dispatch("pipelineV2Charts/loadAllCharts", {pipelineType: "test"}, {root: true})
                } else {
                    throw context?.state?.pipelineData?.test?.error?.error
                }
            }
        },
        
    }
}