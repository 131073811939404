/**
 * Store for smartpaying
 */
const Config = require('../../../store/const')
export default {
  namespaced: true,
  state: {
    psIds: [],
    propertySet: {
      id: '',
      config: {}
    },
    availableChannels: [],
    generatedToken: '',
    integrations: [],
    autonboardingcustomers: []
  },
  getters: {
    propertySet(state) {
      return state.propertySet
    },
    psIds(state) {
      return state.psIds
    },
    availableChannels(state) {
      return state.availableChannels
    },
    generatedToken(state) {
      if (state.generatedToken) {
        return `${Config.MEDUSA_ENDPOINT()}/?access_token=${state.generatedToken}`
      }
      else {
        return ''
      }
    },
    integrations(state) {
      return state.integrations
    },
    autonboardingcustomers(state) {
      return state.autonboardingcustomers
    }
  },
  mutations: {
    propertySet(state, data) {
      state.propertySet = data
    },
    psIds(state, data) {
      state.psIds = data
    },
    availableChannels(state, data) {
      state.availableChannels = data
    },
    generatedToken(state, data) {
      state.generatedToken = data
    },
    integrations(state, data) {
      state.integrations = data
    },
    autonboardingcustomers(state, data) {
      state.autonboardingcustomers = data
    }
  },
  actions: {
    async uploadPgLogo(context, args) {
      const api = context.rootState.api.api
      const path = '/property-set-image'
      const resp = await api.post('sp-onboarding/v1', path, {}, args.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      return resp
    },
    async uploadUpsellingLogo(context, args) {
      const api = context.rootState.api.api
      const path = '/upselling-image'
      const resp = await api.post('sp-onboarding/v1', path, {}, args.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      return resp
    },
    async generateStripeOnboardingLink(context, args) {
      const api = context.rootState.api.api
      const path = '/stripe-account'
      const linkRes = await api.post('sp-onboarding/v1', path, {}, {
        mail: args.mail,
        vat: args.vat
      }, {})
      return linkRes
    },
    async createpropertySet(context, args) {
      const api = context.rootState.api.api
      const path = '/property-set'
      const pg = await api.post('sp-onboarding/v1', path, {}, {
        propertySet: args.propertySet
      }, {})
      return pg
    },
    async createCustomSender(context, args) {
      const api = context.rootState.api.api
      const path = '/create-custom-sender'
      const res = await api.post('sp-onboarding/v1', path, {}, {
        pgId: args.pgId,
        name: args.name,
        email: args.email,
        address: args.address,
        city: args.city,
        country: args.country
      }, {})
      return res
    },
    async verifyCustomSender(context, args) {
      const api = context.rootState.api.api
      const path = '/verify-custom-sender'
      const res = await api.post('sp-onboarding/v1', path, {}, {
        psIds: args.psIdsVerify,
        email: args.emailVerify,
        verificationLink: args.linkVerify
      }, {})
      return res
    },
    async resendVericationMailCustomSender(context, args) {
      const api = context.rootState.api.api
      const path = '/resend-verification-mail'
      const res = await api.post('sp-onboarding/v1', path, {}, {
        senderId: args.senderId
      }, {})
      return res
    },
    async createpropertySetConfig(context, args) {
      const api = context.rootState.api.api
      const path = '/property-set-config'
      const { propertySet, propertySetIds } = await api.post('sp-onboarding/v1', path, {}, {
        googleSheetId: args.googleSheetId
      }, {})
      context.commit('propertySet', propertySet)
      context.commit('psIds', propertySetIds)
      return propertySet
    },
    reset(context, args) {
      context.commit('propertySet', {
        id: 0,
        config: {}
      })
      context.commit('psIds', [])
    },
    async sendTestMex(context, args) {
      const api = context.rootState.api.api
      const path = '/mail'
      const resp = await api.post('sp-onboarding/v1', path, {}, {
        propertySetId: args.propertySetId,
        mexKind: args.mexKind,
        email: args.email
      }, {})
      return resp
    },
    async fetchReportAccounts(context, args) {
      const api = context.rootState.api.api
      let path = '/accounts'
      if (args.withTransactionFrom && args.withTransactionTo) {
        path += `?withTransactionFrom=${args.withTransactionFrom}&withTransactionTo=${args.withTransactionTo}`
      }
      const accounts = await api.get('sp-report/v1', path, {}, {}, {})
      return accounts.map(acc => ({ name: acc.name, merchant: acc.merchant, fee_net: (parseFloat(acc.fee) / 1.22).toFixed(2) }))
    },
    async fetchAccounts(context, args) {
      const api = context.rootState.api.api
      let path = '/stripe-account'
      const accounts = await api.get('sp-onboarding/v1', path, {}, {}, {})
      return accounts
    },
    async fetchReport(context, args) {
      const api = context.rootState.api.api
      const path = '/report'
      const resp = await api.post('sp-report/v1', path, {}, {
        from: args.from,
        to: args.to,
        merchantId: args.merchantId
      }, {})
      return resp
    },
    async setUpsellingConfig(context, args) {
      const api = context.rootState.api.api
      const path = '/property-set-upselling'
      for (const pgId of context.state.psIds) {
        await api.post('sp-onboarding/v1', path, {}, {
          pgId: parseInt(pgId),
          sender: args.sender,
          link: args.link,
          internal: args.internal,
          freedome: args.freedome,
          tiqets: args.tiqets,
          afterCreationMail: args.afterCreationMail != null ? parseInt(args.afterCreationMail) : null,
          beforeCheckinMail: args.beforeCheckinMail != null ? parseInt(args.beforeCheckinMail) : null,
          beforeCheckinWhats: args.beforeCheckinWhats != null ? parseInt(args.beforeCheckinWhats) : null,
          afterCreationWhats: args.afterCreationWhats != null ? parseInt(args.afterCreationWhats) : null,
        }, {})
      }
      return true
    },
    async setUpsellingPsIds(context, args) {
      context.commit('psIds', args.psIds)
    },
    async fetchpropertySets(context, args) {
      const api = context.rootState.api.api
      const path = '/property-set'
      const propertySets = await api.get('sp-onboarding/v1', path, {}, {}, {})
      return propertySets.map(pg => { return { text: `${pg.id} - ${pg.name}`, value: pg.id } })
    },
    async fetchpropertySetById(context, args) {
      const api = context.rootState.api.api
      const path = `/property-set/${args.pgId}`
      const propertySet = await api.get('sp-onboarding/v1', path, {}, {}, {})
      return propertySet
    },
    async updatePropertySet(context, args) {
      const api = context.rootState.api.api
      const path = `/property-set/${args.pgId}`
      const propertySetConfig = await api.put('sp-onboarding/v1', path, {}, {name: args.name, config: args.config}, {})
      return propertySetConfig
    },
    async fetchpropertySetChannels(context, args) {
      const api = context.rootState.api.api
      const path = `/property-set-channels/${args.pgId}`
      const channels = await api.get('sp-onboarding/v1', path, {}, {}, {})
      context.commit('availableChannels', channels)
    },
    async fetchUpsellingUsers(context, args) {
      const api = context.rootState.api.api
      const path = '/users'
      const users = await api.get('sp-upselling/v1', path, {}, {}, {})
      return users.map(user => { return { text: user.first_name, value: user.id } })
    },
    async generateUpsellingToken(context, args) {
      const api = context.rootState.api.api
      const path = `/token/${args.userId}`
      const token = await api.get('sp-upselling/v1', path, {}, {}, {})
      context.commit('generatedToken', token)
    },
    async fetchIntegrations(context, args) {
      const api = context.rootState.api.api
      const path = `/integrations`
      const integrations = await api.get('sp-onboarding/v1', path, {}, {}, {})
      context.commit('integrations', integrations)
    },
    async createOnboardingLink(context, args) {
      const api = context.rootState.api.api
      const path = `/autonboarding`
      const customer = await api.post('sp-onboarding/v1', path, {}, args.newCustomerDto, {})
      return customer
    },
    async fetchAutonboardingCustomers(context, args) {
      const api = context.rootState.api.api
      const path = `/autonboarding`
      let autonboardingcustomers = await api.get('sp-onboarding/v1', path, {}, {}, {})
      autonboardingcustomers = autonboardingcustomers.map(c => {return {...c, a5_it_pdf: c.upselling_config?.a5_it_pdf, a5_en_pdf: c.upselling_config?.a5_en_pdf, shipping: `${c.upselling_config?.shipping_info?.address}, ${c.upselling_config?.shipping_info?.city}, ${c.upselling_config?.shipping_info?.province}`}})
      context.commit('autonboardingcustomers', autonboardingcustomers)
    },
    async searchHubspotDeals(context, args) {
      const api = context.rootState.api.api
      const path = `/deal?title=${args.title}`
      const deals = await api.get('sp-onboarding/v1', path, {}, {}, {})
      return deals
    },
    async createMedusaStore(context, args) {
      const api = context.rootState.api.api
      const path = `/medusastore`
      const customer = await api.post('sp-onboarding/v1', path, {}, args.newCustomerDto, {})
      return customer
    },
    async setLiveState(context, args) {
      const api = context.rootState.api.api
      const path = `/${args.psId}/${args.bundle}/live`
      await api.put('sp-onboarding/v1', path, {}, {}, {})
    }
  }
}
