export default {
    namespaced: true,

    state: {

        oauthPartnersList: [],
        
        //token: "",
        link: "",
    },

    getters: {

        supportsOauth(state, getters, rootState, rootGetters) {
            const selectedCm = rootState.core.currentPG.spec.channel_manager_name
            const selectedPms = rootState.core.currentPG.spec.pms_name

            return state.oauthPartnersList.includes(selectedCm) || state.oauthPartnersList.includes(selectedPms)
        },

        partnerDropdown(state, getters, rootState, rootGetters) {
            const selectedCm = rootState.core.currentPG.spec.channel_manager_name
            const selectedPms = rootState.core.currentPG.spec.pms_name
            
            const dropdown = []

            if (selectedCm === selectedPms) {
                if (state.oauthPartnersList.includes(selectedCm)) {
                    dropdown.push({
                        text: `${selectedCm}`,
                        value: "pms"
                    })
                }
            } else {
                if (state.oauthPartnersList.includes(selectedCm)) {
                    dropdown.push({
                        text: `${selectedCm} - chm`,
                        value: "chm"
                    })
                }
    
                if (state.oauthPartnersList.includes(selectedPms)) {
                    dropdown.push({
                        text: `${selectedPms} - pms`,
                        value: "pms"
                    })
                }
            }

            return dropdown
        },

    },

    mutations: {

        // partners list
        resetOauthPartnersList(state, payload) {
            state.oauthPartnersList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.oauthPartnersList.push(val)
                })
            }
        },

        addOauthPartner(state, payload) {
            state.oauthPartnersList.push(payload.value)
        },

        /* // token
        resetToken(state, payload) {
            state.token = ""
            if (!!payload?.value) {
                state.token = payload.value
            }
        }, */

        // link
        resetLink(state, payload) {
            state.link = ""
            if (!!payload?.value) {
                state.link = payload.value
            }
        }


    },

    actions: {
        async getOauthPartners(context) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("integrations/v2", `/specs/feature/oauth`, {}, {}, {})

                context.commit("resetOauthPartnersList")
                for (const [partner, value] of Object.entries(res)) {
                    if (value) {
                        context.commit("addOauthPartner", {value: partner})
                    }
                }

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting list of Oauth partners: ${error}`
                }, {root: true})
            }
        },

        /* async generateToken(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.post("integrations/v1", '/api/token/generate', {}, payload.data, {})


                
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error generating the token: ${error}`
                }, {root: true})
            }
        }, */

        async getLink(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("integrations/v2", `/oauth/link/${payload.partnerType}/${payload.pgId}`, {}, {}, {})

                context.commit("resetLink", {value: res.link})

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the link: ${error}`
                }, {root: true})
            }
        }

    }
}