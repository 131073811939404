<template>
    <v-row>
        <v-col 
            v-for="(field, index) in fieldsCopy" 
            :key="field.fieldname"
            :class="field.colClass ? field.colClass : 'col-12'"    
        >
            <v-text-field
                v-if="field.type === 'string'"
                outlined
                clearable
                dense
                :persistent-hint="true"
                :label="field.label"
                :class="field.class"
                :prepend-icon="field.isDeletable ? `fa-solid fa-trash fa-sm` : undefined"
                @click:prepend="[fieldsCopy.splice(index, 1), $emit('input', finalValue)]"
                :hint="field.hint"
                :rules="choseRule(field.rules)"
                :disabled="field.disabled || !!disable"
                :value="field.value"
                @input="[fieldsCopy[index].value = $event, $emit('input', finalValue)]"
            ></v-text-field>
    
            <v-text-field
                v-else-if="field.type === 'number'"
                type="number"
                outlined
                clearable
                dense
                :persistent-hint="true"
                :label="field.label"
                :prepend-icon="field.isDeletable ? `fa-solid fa-trash fa-sm` : undefined"
                @click:prepend="[fieldsCopy.splice(index, 1), $emit('input', finalValue)]"
                :class="field.class"
                :hint="field.hint"
                :rules="choseRule(field.rules)"
                :disabled="field.disabled || !!disable"
                :value="field.value"
                @input="[fieldsCopy[index].value = !!$event ? Number($event) : null, $emit('input', finalValue)]"
            ></v-text-field>
    
            <v-switch
                v-else-if="field.type === 'bool'"
                outlined
                dense
                :persistent-hint="true"
                :label="field.label"
                :class="field.class"
                :prepend-icon="field.isDeletable ? `fa-solid fa-trash fa-sm` : undefined"
                @click:prepend="[fieldsCopy.splice(index, 1), $emit('input', finalValue)]"
                :hint="field.hint"
                :true-value="true"
                :false-value="false"
                :rules="choseRule(field.rules)"
                :disabled="field.disabled || !!disable"
                :input-value="!!field.value"
                @change="[fieldsCopy[index].value = $event, $emit('input', finalValue)]"
            ></v-switch>
    
            <v-autocomplete
                v-else-if="field.type === 'autocomplete'"
                outlined
                dense
                :persistent-hint="true"
                :label="field.label"
                :prepend-icon="field.isDeletable ? `fa-solid fa-trash fa-sm` : undefined"
                @click:prepend="[fieldsCopy.splice(index, 1), $emit('input', finalValue)]"
                :class="field.class"
                :hint="field.hint"
                :rules="choseRule(field.rules)"
                :items="field.items"
                :disabled="field.disabled || !!disable"
                :value="field.value"
                @input="[fieldsCopy[index].value = $event, $emit('input', finalValue)]"
            ></v-autocomplete>
    
            <!-- <v-combobox
                v-else-if="field.type === 'combobox'"
                outlined
                dense
                :persistent-hint="true"
                :label="field.label"
                :class="field.class"
                :hint="field.hint"
                :prepend-icon="field.isDeletable ? `fa-solid fa-trash fa-sm` : undefined"
                @click:prepend="[fieldsCopy.splice(index, 1), $emit('input', finalValue)]"
                :rules="choseRule(field.rules)"
                :items="field.items"
                :disabled="field.disabled || !!disable"
                :value="field.value"
                @input="[fieldsCopy[index].value = $event.value, $emit('input', finalValue)]"
            ></v-combobox> -->
    
            <v-select
                v-else-if="field.type === 'select'"
                outlined
                dense
                :persistent-hint="true"
                :label="field.label"
                :class="field.class"
                :prepend-icon="field.isDeletable ? `fa-solid fa-trash fa-sm` : undefined"
                @click:prepend="[fieldsCopy.splice(index, 1), $emit('input', finalValue)]"
                :hint="field.hint"
                :rules="choseRule(field.rules)"
                :items="field.items"
                :disabled="field.disabled || !!disable"
                :value="field.value"
                @input="[fieldsCopy[index].value = $event, $emit('input', finalValue)]"
            ></v-select>

            <v-text-field
                v-else-if="field.type === 'password'"
                outlined
                clearable
                dense
                :persistent-hint="true"
                :label="field.label"
                :prepend-icon="field.isDeletable ? `fa-solid fa-trash fa-sm` : undefined"
                append-icon="fa-solid fa-key fa-sm"
                @click:append="[fieldsCopy[index].value = generateRandomPassword(20), $emit('input', finalValue)]"
                @click:prepend="[fieldsCopy.splice(index, 1), $emit('input', finalValue)]"
                :class="field.class"
                :hint="field.hint"
                :rules="choseRule(field.rules)"
                :disabled="field.disabled || !!disable"
                :value="field.value"
                @input="[fieldsCopy[index].value = $event, $emit('input', finalValue)]"
            ></v-text-field>

            <v-text-field
                v-else-if="field.type === 'base64'"
                outlined
                clearable
                dense
                :persistent-hint="true"
                :label="field.label"
                :prepend-icon="field.isDeletable ? `fa-solid fa-trash fa-sm` : undefined"
                @click:prepend="[fieldsCopy.splice(index, 1), $emit('input', finalValue)]"
                :class="field.class"
                :hint="field.hint"
                :rules="choseRule(field.rules)"
                :disabled="field.disabled || !!disable"
                :value="convertBase64ToPlaintext(field.value)"
                @input="[fieldsCopy[index].value = convertPlaintextToBase64($event), $emit('input', finalValue)]"
            ></v-text-field>


            <v-row v-else-if="field.type === 'asaAndXenus'">
                <v-col class="col-6">
                    <v-text-field
                        outlined
                        dense
                        :persistent-hint="true"
                        :label="`${field.label} Username`"
                        :class="field.class"
                        :hint="field.hint"
                        :rules="choseRule(field.rules)"
                        :disabled="field.disabled || !!disable"
                        :value="convertBase64ToPlaintext(field.value)?.split(':')[0] || null"
                        @input="[fieldsCopy[index].value = asaAndXenusValuesSet($event, 'user', convertBase64ToPlaintext(field.value)), $emit('input', finalValue)]"
                    ></v-text-field>
                </v-col>
                <v-col class="col-6">
                    <v-text-field
                        outlined
                        dense
                        :persistent-hint="true"
                        :label="`${field.label} Password`"
                        append-icon="fa-solid fa-key fa-sm"
                        @click:append="[fieldsCopy[index].value = asaAndXenusValuesSet(generateRandomPassword(20), 'pass', convertBase64ToPlaintext(field.value)), $emit('input', finalValue)]"
                        :class="field.class"
                        :hint="field.hint"
                        :rules="choseRule(field.rules)"
                        :disabled="field.disabled || !!disable"
                        :value="convertBase64ToPlaintext(field.value)?.split(':')[1] || null"
                        @input="[fieldsCopy[index].value = asaAndXenusValuesSet($event, 'pass', convertBase64ToPlaintext(field.value)), $emit('input', finalValue)]"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
//import ExtraParamsSchemas from "@/assets/property_extra_params_schema"

export default {
    props: ["value", "customSchema", "disable"],
    data() {
        return {
            fieldsCopy: [],

            rules: {
                aboveZero: val => val > 0 || "Value must be above zero!",
                noTrailingSpaces: value => {
                    if (value && (value.endsWith(" ") || value.startsWith(" "))) {
                        return "No trailing spaces allowed, remove them!"
                    }
                    return true
                },
                required: val => !!val || "Value required!",
            }
        }
    },
    computed: {
        finalValue() {
            return JSON.stringify(this.createObj(this.fieldsCopy))
        }
    },
    watch: {
        value(newVal, oldVal) {
            this.initialize()
        },
        customSchema(newVal, oldVal) {
            this.initialize()
        }
    },
    methods: {
        createDefaultSchema(key, value) {
            let schemasArr = []

            switch (true) {
                case this.isString(value) || this.isNull(value) || this.isUndefined(value): { // text
                    schemasArr.push({
                        type: "string",
                        label: key,
                        class: "",
                        hint: "",
                        rules: [],
                        disabled: false,
                        value: value,
                        fieldname: key,
                    })
                    break
                }
                case this.isNumber(value): { // number
                    schemasArr.push({
                        type: "number",
                        label: key,
                        class: "",
                        hint: "",
                        rules: [],
                        disabled: false,
                        value: value,
                        fieldname: key,
                    })
                    break
                }
                case this.isBool(value): { // bool
                    schemasArr.push({
                        type: "bool",
                        label: key,
                        class: "",
                        hint: "",
                        rules: [],
                        disabled: false,
                        value: value,
                        fieldname: key,
                    })
                    break
                }
                case this.isObject(value): { // object (form inside form)
                    // recursive
                    for (let [newKey, newVal] of Object.entries(value)){
                        let innerObjSchema = this.createDefaultSchema(newKey, newVal)
                        
                        innerObjSchema.forEach(element => {
                            element.fieldname = key + "." + element.fieldname
                            element.label = key + " > " + element.label
                        })
    
                        schemasArr = schemasArr.concat(innerObjSchema) 
                    }
                    break
                }
                case this.isArray(value): { // array
                    for ( let [idx, val] of value.entries()) {
                        let innerObjSchema = this.createDefaultSchema(idx, val)
                        innerObjSchema.forEach(element => {
                            element.fieldname = `${key}#${idx}`
                            element.label = `${key}[${idx}]`
                        })
                        schemasArr = schemasArr.concat(innerObjSchema)
                    }
                    break
                }
            }

            return schemasArr
        },
        createSchema(obj, customSchema){
            let schemasArr = []

            const customSchemaObj = customSchema !== undefined && customSchema !== null ? this.createObj(customSchema) : {}
            const newObj = structuredClone({...customSchemaObj, ...obj})

            for (let [key, value] of Object.entries(newObj)) {
                schemasArr = schemasArr.concat(this.createDefaultSchema(key, value))
            }

            schemasArr.forEach((singleSchema, index) => {                
                const relativeSchema = customSchema !== undefined && customSchema !== null ? structuredClone(customSchema.find(val => val.fieldname === singleSchema.fieldname)) : undefined

                if (relativeSchema !== undefined) {
                    delete relativeSchema.value
                    relativeSchema.value = singleSchema.value
                    
                    schemasArr.splice(index, 1, relativeSchema)
                }
            })

            return schemasArr
        },
        createObj(schema) {
            const result = {}

            if (!schema) {
                return {}
            }

            for(let i= 0; i < schema.length; i++){
                const {fieldname, value} = schema[i];


                let separators = []
                for ( let i = 0 ; i < fieldname.length ; i++ ) {
                    if ( fieldname[i] == "." || fieldname[i] == "#" ) {
                        separators.push(fieldname[i])
                    }
                }

                const fields = fieldname.split(/[#.]+/)

                let currentObj = result;
                for ( let j = 0; j < fields.length; j++ ) {
                    const field = fields[j];

                    if ( j === fields.length - 1 ) {
                        currentObj[field] = value;
                    } else {
                        if ( separators[j] == "." ) {
                            if (!currentObj.hasOwnProperty(field)){
                                currentObj[field] = {}
                            }
                            currentObj = currentObj[field]
                        } else if ( separators[j] == "#") {
                            if (!currentObj.hasOwnProperty(field)){
                                currentObj[field] = []
                            }
                            currentObj = currentObj[field]
                        }
                    }               
                }
            }
            return result
        },

        // value type check methods
        isNull(val) {
            return val === null 
        },
        isUndefined(val) {
            return val === undefined
        },
        isString(val) {
            return typeof val === "string"
        },
        isNumber(val) {
            return !isNaN(Number(val)) && typeof val === "number"
        },
        isBool(val) {
            return typeof val === "boolean"
        },
        isArray(val) {
            return typeof val === "object" && Array.isArray(val)
        },
        isObject(val) {
            return typeof val === "object" && !Array.isArray(val)
        },
        isDate() {
            // to do
        },
        isDateRange() {
            // to do
        },
        isValidJSON(str) {
            try {
                JSON.parse(str);
                return true;
            } catch {
                return false;
            }
        },

        // utils
        generateRandomPassword(length) {
            const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+";
            const randomValues = new Uint32Array(length);
            const password = [];

            crypto.getRandomValues(randomValues);

            for (let i = 0; i < length; i++) {
                const randomIndex = randomValues[i] % charset.length;
                password.push(charset[randomIndex]);
            }

            return password.join('');
        },

        convertBase64ToPlaintext(base64String) {
            if (!base64String) return ""
            const binaryData = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
            const textDecoder = new TextDecoder('utf-8');
            return textDecoder.decode(binaryData);
        },
        convertPlaintextToBase64(plaintextString) {
            if (!plaintextString) return ""
            const textEncoder = new TextEncoder();
            const encodedData = textEncoder.encode(plaintextString);
            const encoded = btoa(String.fromCharCode.apply(null, encodedData));
            return this.removeEqualsFromString(encoded)
        },

        removeEqualsFromString(str) {
            if (str.endsWith("o=")){
                return this.removeEqualsFromString(str.slice(0, -2))
            } else if (str.endsWith("=")) {
                return this.removeEqualsFromString(str.slice(0, -1))
            } else {
                return str;
            }
        },

        asaAndXenusValuesSet(newValue, newValueField, realValuePlaintext) {
            // hoping to remove this one day
            if (newValueField === "user") {
                const user = newValue
                const pass = realValuePlaintext.split(":")[1] || ""
                return !user && !pass ? null : this.convertPlaintextToBase64(`${user}:${pass}`)
            } else {
                const user = realValuePlaintext.split(":")[0] || ""
                const pass = newValue
                return !user && !pass ? null : this.convertPlaintextToBase64(`${user}:${pass}`)
            }
        },

        // rules
        choseRule(ruleNames) {
            if (ruleNames !== undefined) {
                        
                let newRules = []
                for (let ruleName of ruleNames) {
                    if (this.rules[ruleName] !== undefined) {
                        newRules.push(this.rules[ruleName])
                    }
                }

                return newRules
            }
            return []
        },
        // initialization
        initialize() {
            if (this.isValidJSON(this.value)) {
                this.fieldsCopy = structuredClone(this.createSchema(JSON.parse(this.value), this.customSchema))
                this.$emit('input', this.finalValue)
            }
        }
    },
    created() {
        this.initialize()
    }
}
</script>