<template>
    <v-container class="pa-4" fluid>
        <TokenGeneration v-if="!!globalSelectedPgId"/>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
import TokenGeneration from '../components/TokenGeneration.vue';
import SelectPgAlert from '@/components/utils/SelectPgAlert'
import { mapGetters } from 'vuex';

export default {
    components: {TokenGeneration, SelectPgAlert},
    computed: {
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
        })
    }
}
</script>