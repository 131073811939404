<template>
    <v-card class="card-ui pa-4">
        <v-card-text class="pa-0">
            <v-data-table
                :headers="headers"
                :items="selectedPropertyPmsMappings"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ selectedPropertyPrettyName }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-dialog
                            transition="dialog-bottom-transition"
                            max-width="600"
                            v-model="dialog"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Insert New
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-card>
                                    <v-toolbar
                                        color="primary"
                                        dark
                                    >
                                        Insert new PMS mapping
                                    </v-toolbar>

                                    <v-form
                                        ref="form"
                                        v-model="validForm"
                                    >
                                        <v-card-text class="pt-14">
                                            <v-row>
                                                <v-col class="col col-12">
                                                    <v-text-field
                                                        label="Property ID"
                                                        disabled
                                                        v-model="selectedPropertyId"
                                                        outlined
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col class="col col-12">
                                                    <v-autocomplete
                                                        label="PMS ID"
                                                        :items="pmsIdsDropdown"
                                                        item-text="text"
                                                        item-value="value"
                                                        outlined
                                                        v-model="selectedPmsId"
                                                        v-if="selectedPgPmsMappingSupported"
                                                    ></v-autocomplete>
                                                    <v-text-field
                                                        label="PMS ID"
                                                        outlined
                                                        persistent-hint
                                                        :rules="[rules.noTrailingSpaces]"
                                                        :hint="hintTextInsertPmsMapping"
                                                        v-model="selectedPmsId"
                                                        v-else
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-form>

                                    <v-card-actions class="justify-end pa-4">
                                        <v-btn
                                            color="primary"
                                            @click="insertMapping()"
                                        >Submit</v-btn>
                                        <v-btn
                                            text
                                            color="red"
                                            @click="selectedPmsId = ''"
                                            v-on:click="dialog.value = false"
                                        >Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Delete
                        </v-btn>
                        </template>
                        <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar
                            color="red"
                            dark
                            >Confirm Mapping Deletion</v-toolbar>

                            <v-card-text>
                                <div class="text-h6 pt-12">
                                    Confirm deleting PMS mapping {{ item.pmsId }} - {{ item.name }}
                                </div>
                            </v-card-text>

                            <v-card-actions class="justify-end">
                                <v-btn
                                    color="red"
                                    @click="deletePmsPropertyMapping({pmsId: item.pmsId, propertyId: selectedPropertyId})"
                                    v-on:click="dialog.value = false"
                                >
                                    Delete
                                </v-btn>
                                <v-btn
                                    text
                                    v-on:click="dialog.value = false"
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        </template>
                    </v-dialog>
                </template>


                <template v-slot:item.name="{ item }">
                    <v-chip v-show="!!item.name" :color="item.inPms ? 'success' : 'error'">
                        {{ item.inPms ? item.name : `${item.name} - Not in PMS` }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            headers: [
                { text: 'ID', sortable: true, value: 'pmsId' },
                { text: 'Name', sortable: true, value: 'name' },
                { text: 'Actions', sortable: false, value: 'actions' },
            ],
            selectedPmsId: "",

            validForm: true,
            dialog: false,

            rules: {
                noTrailingSpaces: value => {
                    if (value && (value.endsWith(" ") || value.startsWith(" "))) {
                        return "No trailing spaces allowed, remove them!"
                    }
                    return true
                },
            }
        }
    },
    computed: {
        ...mapState({
            selectedPropertyPmsMappings: state => state.mappingAndRates.selectedPropertyPmsMappings,
            currentPmsId: state => state.core.currentPG.spec.pms_name
        }),
        ...mapGetters({
            selectedPgPmsMappingSupported: "mappingAndRates/selectedPgPmsMappingSupported",
            pmsIdsDropdown: "mappingAndRates/pmsIdsDropdown",
            selectedPropertyPrettyName: "mappingAndRates/selectedPropertyPrettyName",
            selectedPropertyId: "mappingAndRates/selectedPropertyId",
        }),
        hintTextInsertPmsMapping(){
            return this.selectedPgPmsMappingSupported ? "" : `${this.currentPmsId} doesn't support the following feature, write down the id on your own!`
        }
    },
    methods: {
        ...mapActions({
            deletePmsPropertyMapping: "mappingAndRates/deletePmsPropertyMapping",
            insertPmsPropertyMapping: "mappingAndRates/insertPmsPropertyMapping",
        }),
        insertMapping() {
            this.$refs.form.validate()

            if (this.validForm) {
                this.insertPmsPropertyMapping({propertyId: this.selectedPropertyId, pmsId: this.selectedPmsId})
                this.dialog = false
            }
        }
    }
    
}
</script>