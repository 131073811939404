<template>
  <div class="home">
    <v-container>
      <v-row class="ml-4 mb-1">
        <v-col class="col-5">
          <h1> Standard maps </h1>
        </v-col>
        <v-col class="col-1 col-md-1 mt-2">
          <v-tooltip >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="indigo" 
              class="ml-3" 
              x-small 
              dark 
              bottom left fab @click="saveAll()">
                <v-icon class="fa-2x">fa-solid fa-floppy-disk fa-2x</v-icon>
              </v-btn>
            </template>
            <span>Save all</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-6">
            <StandardMapsYamlEditor></StandardMapsYamlEditor>
        </v-col>
      </v-row>
  </v-container>
  </div>
</template>

<script>
import StandardMapsYamlEditor from '../components/StandardMapsYamlEditor'



export default {
  name: 'StandardMaps',
  components: {
    'StandardMapsYamlEditor': StandardMapsYamlEditor,

  },
  data: function (argument) {
    return {}
  },
  methods:{
    async saveAll(){
      var args = {mapsTypes: 'all'}; //in case we want to implement saving only one or more but not all map types
      await this.$store.dispatch('mapsYamlEditor/saveMaps', args);
    }
  }
}
</script>
