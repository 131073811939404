/**
 * Store for event logs
 */ 

import * as compressionUtils from '@/utils/compressionUtils'
import { AxiosError } from 'axios'

export default {
  namespaced: true,

  state: {
    logs: [],
    detailedLogs: [],
    aggregatedLogs: [],
    predictLogs: [],
    rateNames: [],
  },

  mutations: {

    resetDetailedLogs(state) {
      state.detailedLogs = []
    },

    updateDetailedLogs(state, payload) {
      payload.newLogs.forEach(log => {
        state.detailedLogs.push(log)
      })
    },

    resetRateNames(state) {
      state.rateNames = []
    },

    updateRateNames(state, payload) {
      payload.newRates.forEach(rate => {
        state.rateNames.push(rate)
      })
      
    },
  },

  getters: {

    propertyRateNameById(state) {
      const hashMap = {}
      state.rateNames.forEach(rate => {
        hashMap[rate.metadata.id] = `${rate.metadata.id} - ${rate.spec.property_id} - ${rate.spec.rate_name}`
      })
      return hashMap
    }

  },

  actions: {
    async systemlogs (context, args) {
      const api = context.rootState.api.api
      // const chmId = context.rootState.core.currentPropertySets.find(ps => ps.metadata.kind === 'CHM').metadata.id
      let logsArray = []
      for (const ps of context.rootState.core.currentPropertySets) {
        const psId = ps.metadata.id
        const path = '/pg/logs/' + args.date + '/' + psId + '/' + args.hour + '?forceCache=' + args.forceCache
        let logRes = await api.get('log/v1', path, {}, {}, {})
        for (let l of logRes) {
          l.psKind = ps.metadata.kind
          l.psId = ps.metadata.id
        }
        logsArray.push(logRes)
      } 
      logsArray = logsArray.flat().sort((a,b) => {
        return new Date(b.insdate) - new Date(a.insdate)
      })
      context.state.logs = logsArray 
      if (args.cb !== undefined && typeof args.cb == 'function') {
          args.cb(logsArray)
      }         
    },
    // /log/v1/pg/logs-raw/:date/:pg/:id
    async getRawLog (context, args) {
      const api = context.rootState.api.api
      const path = '/pg/logs-raw/' + args.date + '/' + args.propertySet + '/' + args.id
      const logRes = await api.get('log/v1', path, {}, {}, {})
      if (args.cb !== undefined && typeof args.cb == 'function') {
          args.cb(logRes)
      }      
    },
    // /log/v1/pg/inspect/:pg
    async getPgLog6Days (context, args) {
      const api = context.rootState.api.api
      const path = '/pg/inspect/' + args.selectedPropertyGroup
      const logRes = await api.get('log/v1', path, {}, {}, {})
      if (args.cb !== undefined && typeof args.cb == 'function') {
          args.cb(logRes)
      }      
    },    
    // /log/v1/pg/job-tracker-logs/:pg/:run_id/:job_id
    async jobTrackerLogs (context, args) {

      try {
        const api = context.rootState.api.api
        const path = '/pg/job-tracker-logs/' + args.propertySet + '/' + args.runId + '/' + args.jobId
        if (args.jobId === args.propertySet) throw "Log has not tracker" 

        const logRes = await api.get('log/v1', path, {}, {}, {})
  
        const newLogRes = await Promise.all(logRes?.map(async (log) => {
          if (log.raw.httpLogs.type === "Buffer"){
            const decompressedString = await compressionUtils.decompressGzip(new Uint8Array(log.raw.httpLogs.data))
            log.raw.httpLogs = JSON.parse(decompressedString)
          }
          return log
        }))
  
        if (args.cb !== undefined && typeof args.cb == 'function') {
            args.cb(newLogRes)
        }   

      } catch (error) {
        if (args.cb !== undefined && typeof args.cb == 'function') {
          args.cb([])
        }   
      }
      
    },
    // /log/v1/computed/:pg/:pgid/:date
    async fetchDetailedLogs (context, args) {
      const api = context.rootState.api.api

      try {
        //const logRes = await api.get('log/v1', `/computed/${args.pgId}/${args.property}/${args.from}/${args.to}`, {}, {}, {})
        const logRes = await api.get('log/v1', `/computedreduced/${args.pgId}/${args.from}/${args.to}`, {}, {}, {})

        context.commit("resetDetailedLogs")
        context.commit("updateDetailedLogs", {newLogs: logRes})

      } catch (error) {
        context.dispatch("alertSystem/newError", {
          message: `Error retrieving compute logs: ${error}`
        }, {root: true})
      }
    },

    async fetchPgRatesNames(context, args) {
      const api = context.rootState.api.api
      try {
        const logRes = await api.get("rate/v1", `/propertyrate/propertygroup/${args.pgid}`, {}, {}, {})

        context.commit("resetRateNames")
        context.commit("updateRateNames", {newRates: logRes.items})
        

      } catch (error) {
        context.dispatch("alertSystem/newError", {
          message: `Error retrieving rate names: ${error}`
        }, {root: true})
      }
    },

    // /log/v1/pg/aggregate/logs/' + args.selectedPropertyGroup
    async aggregatedLogs (context, args) {
      const api = context.rootState.api.api
      const path = '/pg/aggregate/logs/' + args.selectedPropertyGroup
      const logRes = await api.get('log/v1', path, {}, {}, {})
      context.state.aggregatedLogs = logRes
    },
    async predictLogs (context, args) {
      const api = context.rootState.api.api
      const path = '/predict-logs-v2/' + args.dates.join(',')
      const logRes = await api.get('stats/v1general', path, {}, {}, {})
      context.state.predictLogs = logRes
    },
    async pipelineLogsT5m (context, args) {
      const api = context.rootState.api.api
      const path = '/stats-pipeline-t5m/' + args.dates.join(',')
      const logRes = await api.get('stats/v1general', path, {}, {}, {})
      return logRes      
    }, 
    async pipelineLogsS1h5m (context, args) {
      const api = context.rootState.api.api
      const path = '/stats-pipeline-s1h5m/' + args.dates.join(',')
      const logRes = await api.get('stats/v1general', path, {}, {}, {})
      return logRes      
    },
  }

}
