<template>
  <div>
    <v-card class="card-ui" v-if="pgProperties !== null">
      <v-toolbar dense elevation="0" :class="{ 'grey darken-3': darkMode }">
        <v-card-title class="overline ml-0 pl-0">
          <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
          <b class="grey--text darken-6">Property group properties</b>
        </v-card-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="validForm">
          <v-data-table
            v-model="selectedRowList"
            :items="filteredResults"
            item-key="metadata.id"
            :sort-by="`spec.property_group_id`"
            :headers="currentlyEnabledTableColumns"
            fixed-header
            height="500"
            :sort-desc="false"
            :item-class="backgroundColor"
            :items-per-page="-1"
            class="card-ui properties-data-table"
            loading-text="Properties are loading ..... Please wait"
            :show-select="currentlyEnabledTableColumns.length"
          >
            <template v-slot:top>
              <v-row justify="flex-start" class="mb-6" no-gutters>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :outlined="!showPredicted"
                      color="primary"
                      class="mr-4"
                      @click="showPredicted = !showPredicted"
                      v-bind="attrs"
                      v-on="on"
                      >Has Graph JSON</v-btn
                    >
                  </template>
                  <span>Properties with Graph_JSON != null</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :outlined="!showWrite"
                      color="primary"
                      class="mr-4"
                      @click="showWrite = !showWrite"
                      v-bind="attrs"
                      v-on="on"
                      >To Write</v-btn
                    >
                  </template>
                  <span>Properties with Set price Enabled = true</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :outlined="!showRead"
                      color="primary"
                      class="mr-4"
                      @click="showRead = !showRead"
                      v-bind="attrs"
                      v-on="on"
                      >To Read</v-btn
                    >
                  </template>
                  <span
                    >Properties with Import Reservations Enabled = true</span
                  >
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :outlined="!showVisible"
                      color="primary"
                      class="mr-4"
                      @click="showVisible = !showVisible"
                      v-bind="attrs"
                      v-on="on"
                      >Is Visible</v-btn
                    >
                  </template>
                  <span>Properties with Visible = true</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="showChildren = !showChildren"
                      :outlined="!showChildren"
                      color="primary"
                      class="mr-4"
                      v-bind="attrs"
                      v-on="on"
                      >Show Children</v-btn
                    >
                  </template>
                  <span>Shows the properties of child pgs</span>
                </v-tooltip>
                <v-btn @click="downloadXlsx">Download XLSX</v-btn>

                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="green"
                      class="ml-auto"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="selectedRowList.length === 0"
                    >
                      Edit selected rows
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title> Bulk Edit </v-card-title>

                    <v-card-text>
                      <DynamicForm
                        v-model="bulkUpdateObj"
                        :schema="bulkUpdateSchema"
                        :editable="true"
                      />
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDialog">
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="bulkUpdateAction"
                      >
                        Apply
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <DataFilters
                :items="properties"
                :schema="filters"
                v-model="filteredResults"
                :filtersMenuDefault="true"
                class="card-ui"
              >
              </DataFilters>
              <ColumnsFilter
                :tableColumns="headers"
                tableColumnsFilterDefaultEnabled="true"
                v-model="currentlyEnabledTableColumns"
                class="card-ui"
              >
              </ColumnsFilter>
            </template>

            <!-- <template v-slot:header="{ props, on, isMobile }">
              <v-data-table-header
                style="position: sticky; top: 0; z-index: 100;"
                class="card-ui"
                v-on="on"
                :mobile="isMobile"
                :headers="props.headers"
              >
              </v-data-table-header>
            </template> -->

            <template v-slot:item.metadata.id="{ item }">
              <v-btn color="primary" dark @click="openPropertyMappings(item)">
                {{ item.metadata.id }}
              </v-btn>
            </template>

            <template v-slot:item.metadata.name="props">
              <v-edit-dialog
                :return-value.sync="props.item.metadata.name"
                @close="close(props.item.metadata.id)"
              >
                {{ props.item.metadata.name }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.metadata.name"
                    label="Edit"
                    :rules="[rules.noTrailingSpaces]"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.spec.num_units="props">
              <v-edit-dialog
                :return-value.sync="props.item.spec.num_units"
                @close="close(props.item.metadata.id)"
              >
                {{ props.item.spec.num_units }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.spec.num_units"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.spec.base_price="props">
              <v-edit-dialog
                :return-value.sync="props.item.spec.base_price"
                @close="close(props.item.metadata.id)"
              >
                {{ props.item.spec.base_price }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.spec.base_price"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.spec.graph_json="props">
              {{ graphJsonPrettify(props.item.spec.graph_json) }}
            </template>

            <template v-slot:item.spec.base_price_map="props">
              <v-edit-dialog
                :return-value.sync="props.item.spec.base_price_map"
                @close="close(props.item.metadata.id)"
              >
                <div v-if="props.item.spec.base_price_map != null">Edit</div>
                <div v-else>Set</div>

                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.spec.base_price_map"
                    label="Edit"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.spec.default_min_price="props">
              <v-edit-dialog
                :return-value.sync="props.item.spec.default_min_price"
                @close="close(props.item.metadata.id)"
              >
                {{ props.item.spec.default_min_price }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.spec.default_min_price"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.spec.is_virtual="{ item }">
              <v-checkbox
                v-model="item.spec.is_virtual"
                color="cyan"
                @change="close(item.metadata.id)"
              ></v-checkbox>
            </template>

            <template v-slot:item.spec.is_visible="{ item }">
              <v-checkbox
                v-model="item.spec.is_visible"
                color="cyan"
                @change="close(item.metadata.id)"
              ></v-checkbox>
            </template>

            <template v-slot:item.spec.parent_id="props">
              <v-edit-dialog
                :return-value.sync="props.item.spec.parent_id"
                @close="close(props.item.metadata.id)"
                @save="close(props.item.metadata.id)"
              >
                {{ props.item.spec.parent_id }}
                <template v-slot:input>
                  <v-text-field
                    :value="props.item.spec.parent_id"
                    label="Edit"
                    single-line
                    clearable
                    @change="
                      [
                        (props.item.spec.parent_id = saveOrderValue($event)),
                        close(props.item.metadata.id),
                      ]
                    "
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.spec.is_allotment="{ item }">
              <v-checkbox
                v-model="item.spec.is_allotment"
                color="cyan"
                @change="close(item.metadata.id)"
              ></v-checkbox>
            </template>

            <template v-slot:item.spec.order="props">
              <v-edit-dialog
                :return-value.sync="props.item.spec.order"
                @close="close(props.item.metadata.id)"
                @save="close(props.item.metadata.id)"
              >
                {{ props.item.spec.order }}
                <template v-slot:input>
                  <v-text-field
                    :value="props.item.spec.order"
                    label="Edit"
                    single-line
                    clearable
                    @change="
                      [
                        (props.item.spec.order = saveOrderValue($event)),
                        close(props.item.metadata.id),
                      ]
                    "
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.spec.set_price_from_offset="props">
              <v-edit-dialog
                :return-value.sync="props.item.spec.set_price_from_offset"
                @close="close(props.item.metadata.id)"
              >
                {{ props.item.spec.set_price_from_offset }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.spec.set_price_from_offset"
                    label="Edit"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.spec.set_price_to_offset="props">
              <v-edit-dialog
                :return-value.sync="props.item.spec.set_price_to_offset"
                @close="close(props.item.metadata.id)"
              >
                {{ props.item.spec.set_price_to_offset }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.spec.set_price_to_offset"
                    label="Edit"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.spec.is_set_prices_enabled="{ item }">
              <v-checkbox
                v-model="item.spec.is_set_prices_enabled"
                color="cyan"
                @change="close(item.metadata.id)"
              ></v-checkbox>
            </template>

            <template v-slot:item.spec.force_store_computations="props">
              <v-edit-dialog
                :return-value.sync="props.item.spec.force_store_computations"
                @close="close(props.item.metadata.id)"
              >
                {{ props.item.spec.force_store_computations }}
                <template v-slot:input>
                  <v-select
                    v-model="props.item.spec.force_store_computations"
                    dense
                    :item-text="props.item.spec.force_store_computations"
                    :item-value="props.item.spec.force_store_computations"
                    :items="storeCompOptions"
                  >
                  </v-select>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.spec.is_import_reservation_enabled="{ item }">
              <v-checkbox
                v-model="item.spec.is_import_reservation_enabled"
                color="cyan"
                @change="close(item.metadata.id)"
              ></v-checkbox>
            </template>

            <template v-slot:item.spec.cm_id="{ item }">
              <v-chip
                :color="!!item.spec.cm_id.inCm ? 'success' : 'error'"
                v-show="!!item.spec.cm_id.label"
              >
                {{ item.spec.cm_id.label }}
              </v-chip>
            </template>

            <template v-slot:item.spec.cm_rate_id="{ item }">
              <v-chip
                :color="!!item.spec.cm_rate_id.inCm ? 'success' : 'error'"
                v-show="!!item.spec.cm_rate_id.label"
              >
                {{ item.spec.cm_rate_id.label }}
              </v-chip>
            </template>

            <template v-slot:item.spec.created_at="{ item }">
              <span>{{
                new Date(item.spec.created_at).toLocaleString("it-IT")
              }}</span>
            </template>

            <template v-slot:item.spec.updated_at="{ item }">
              <span>{{
                new Date(item.spec.updated_at).toLocaleString("it-IT")
              }}</span>
            </template>

            <template v-slot:item.spec.is_ml_enabled="{ item }">
              <v-checkbox
                v-model="item.spec.is_ml_enabled"
                color="cyan"
                @change="close(item.metadata.id)"
              ></v-checkbox>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="error" v-bind="attrs" v-on="on">Delete</v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card :loading="runningAPIRequest ? 'error' : false">
                    <v-toolbar color="error" dark
                      >Confirm property deletion</v-toolbar
                    >
                    <v-card-text>
                      <div class="text-h4 pa-12">
                        Are you sure you want to delete the property
                        {{ item.metadata.name }}? <br />
                        All the modifiers, computed_prices, reservations and
                        mappings related to this property are going to be
                        deleted.
                      </div>
                      <div class="text-h3 pa-12">
                        This operation cannot be undone
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        color="error"
                        @click="
                          deleteProperty({
                            pgId: item.spec.property_group_id,
                            propertyId: item.metadata.id,
                          })
                        "
                      >
                        Delete
                      </v-btn>
                      <v-btn text @click="dialog.value = false">Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>
          </v-data-table>
        </v-form>

        <v-chip class="ma-2">
          Total number of units (including children): {{ unitsCount }}
        </v-chip>
      </v-card-text>
      <v-row>
        <v-col class="col-1">
          <v-btn
            class="pl-4 mt-6 ml-3"
            dense
            outlined
            color="cyan"
            style="width: 100%"
            @click="saveEdits"
            >Save</v-btn
          >
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="mappingsDialog"
      v-if="mappingsDialog"
      hide-overlay
      fullscreen
      persistent
      transition="dialog-bottom-transition"
    >
      <MappingAndRates
        :property="mappingsSelectedProperty"
        @closeDialog="
          [
            (mappingsDialog = false),
            fetch($store.state.core.currentPG.metadata.id),
          ]
        "
      />
    </v-dialog>
  </div>
</template>

<script>
// import MD5 from "crypto-js/md5";
import DynamicForm from "@/components/form/DynamicForm";
import ColumnsFilter from "@/components/utils/ColumnsFilter";
import DataFilters from "@/components/utils/DataFilters";
import { jsonToWorksheet } from "@/utils/csvUtils";
import MappingAndRates from "../views/MappingAndRates.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "PropertiesTableEditor",
  components: { DynamicForm, MappingAndRates, ColumnsFilter, DataFilters },
  data() {
    return {
      pgProperties: null,
      snack: false,
      snackColor: "",
      snackText: "",
      editedProperties: [],
      pgPropertiesMap: null,
      selectedRowList: [],
      //unitsCount: 0,
      bulkUpdateObj: {},

      mappingsDialog: false,
      mappingsSelectedProperty: 0,

      validForm: true,

      pagination: {},
      headers: [
        {
          text: "Property id",
          align: "start",
          sortable: true,
          value: "metadata.id",
        },
        {
          text: "PG Id",
          sortable: true,
          value: "spec.property_group_id",
          align: "start",
        },
        {
          text: "Property name",
          sortable: true,
          value: "metadata.name",
          align: "start",
        },
        { text: "Number of units", value: "spec.num_units", align: "center" },
        //{ text: 'Base price', value: 'spec.base_price', align: 'center' },
        // { text: 'Base price map', value: 'spec.base_price_map', align: 'center' },
        { text: "Graph Json", value: "spec.graph_json", align: "center" },
        //{ text: 'Default min price', value: 'spec.default_min_price', align: 'center' },
        { text: "Virtual", value: "spec.is_virtual" },
        { text: "Visible", value: "spec.is_visible" },
        { text: "Parent ID", value: "spec.parent_id" },
        { text: "Allotment", value: "spec.is_allotment", align: "center" },
        { text: "Order", value: "spec.order" },
        {
          text: "From offset",
          value: "spec.set_price_from_offset",
          align: "center",
        },
        {
          text: "To offset",
          value: "spec.set_price_to_offset",
          align: "center",
        },
        { text: "Board Type", value: "spec.configuration_params.board_type" },
        {
          text: "Max Occupancy",
          value: "spec.configuration_params.max_occupancy",
        },
        {
          text: "Min Occupancy",
          value: "spec.configuration_params.min_occupancy",
        },
        {
          text: "Rate Occupancy",
          value: "spec.configuration_params.rate_occupancy",
        },
        { text: "Pr enabled", value: "spec.is_set_prices_enabled" },
        { text: "Force store comp.", value: "spec.force_store_computations" },
        { text: "Channel manager id", value: "spec.cm_id" },
        { text: "Channel manager rate id", value: "spec.cm_rate_id" },
        {
          text: "Import reservations enables",
          value: "spec.is_import_reservation_enabled",
        },
        //{ text: 'Creation date', value: 'spec.created_at'},
        //{ text: 'Last update', value: 'spec.updated_at'},
        //{ text: 'ML enabled', value: 'spec.is_ml_enabled' },
        { text: "Actions", value: "actions", sortable: false },
      ],
      currentlyEnabledTableColumns: [],

      filters: [{ text: "Property Name", value: "metadata.name" }],
      filteredResults: [],

      dialog: false,

      showWrite: false,
      showRead: false,
      showPredicted: false,
      showVisible: false,
      showChildren: true,

      rules: {
        noTrailingSpaces: (value) => {
          if (value && (value.endsWith(" ") || value.startsWith(" "))) {
            return "No trailing spaces allowed, remove them!";
          }
          return true;
        },
      },
    };
  },

  computed: {
    ...mapState({
      darkMode: (state) => state.ui.darkMode,
      runningAPIRequest: (state) => state.api.runningAPIRequest,
      currentPropertiesIncludingChildren: (state) =>
        state.core.currentPropertiesIncludingChildren,
    }),
    ...mapGetters({
      selectedPgCmMappingSupported:
        "mappingAndRates/selectedPgCmMappingSupported",
      selectedPgCmRatesSupported: "mappingAndRates/selectedPgCmRatesSupported",
      cmIdToPropertyName: "mappingAndRates/cmIdToPropertyName",
      rateIdToName: "mappingAndRates/rateIdToName",
      globalSelectedPgId: "core/globalSelectedPgId",
      cmIdsDropdown: "mappingAndRates/cmIdsDropdown",
      rateMasterDropdown: "mappingAndRates/rateMasterDropdown",
    }),
    storeCompOptions() {
      return ["never", "always", "user_action"]; //TODO check if more than never and always
    },
    selectedRowIdList() {
      return this.selectedRowList.map((r) => {
        return r.metadata.id;
      });
    },
    unitsCount() {
      let result = 0;
      for (var p of this.currentPropertiesIncludingChildren) {
        if (p.spec.num_units != "") {
          result += parseInt(p.spec.num_units);
        }
      }
      return result;
    },
    properties() {
      return this.pgProperties
        .filter((value) => {
          if (
            this.showPredicted ||
            this.showRead ||
            this.showVisible ||
            this.showWrite
          ) {
            return (
              (value.spec.is_set_prices_enabled && this.showWrite) ||
              (value.spec.is_import_reservation_enabled && this.showRead) ||
              (value.spec.graph_json && this.showPredicted) ||
              (value.spec.is_visible && this.showVisible)
            );
          } else {
            return true;
          }
        })
        .filter((value) => {
          const isChildren =
            value.spec.property_group_id !==
            this.$store.state.core.currentPG.metadata.id;
          if (isChildren) {
            return this.showChildren;
          } else {
            return true;
          }
        })
        .map((value) => {
          const copy = value;
          copy.spec.cm_id = {
            label: copy.spec.channel_manager_id,
            inCm: true,
          };
          if (!!Object.keys(this.cmIdToPropertyName).length) {
            const name =
              this.cmIdToPropertyName[String(copy.spec.channel_manager_id)];
            copy.spec.cm_id = {
              label: !!name
                ? `${copy.spec.channel_manager_id} - ${name}`
                : copy.spec.channel_manager_id,
              inCm: !!name,
            };
          }
          return copy;
        })
        .map((value) => {
          const copy = value;
          copy.spec.cm_rate_id = {
            label: copy.spec.channel_manager_rate_id,
            inCm: false,
          };
          if (!!Object.keys(this.rateIdToName).length) {
            const name =
              this.rateIdToName[String(copy.spec.channel_manager_rate_id)];
            copy.spec.cm_rate_id = {
              label: !!name ? `${name}` : copy.spec.channel_manager_rate_id,
              inCm: !!name,
            };
          }
          return copy;
        });
    },
    bulkUpdateSchema() {
      const schema = [
        {
          type: "number",
          label: "Num Units",
          key: "num_units",
          customRules: [
            (value) => !value || !isNaN(value) || "Must be a number.",
          ],
        },
        {
          type: "boolean_radio",
          label: "Is Visible",
          key: "is_visible",
        },
        {
          type: "number",
          label: "Order",
          key: "order",
          customRules: [
            (value) => !value || !isNaN(value) || "Must be a number.",
          ],
        },
        {
          type: "boolean_radio",
          label: "Is Allotment",
          key: "is_allotment",
        },
        {
          type: "number",
          label: "Set Price From Offset",
          key: "set_price_from_offset",
          customRules: [
            (value) => !value || !isNaN(value) || "Must be a number.",
          ],
        },
        {
          type: "number",
          label: "Set Price To Offset",
          key: "set_price_to_offset",
          customRules: [
            (value) => !value || !isNaN(value) || "Must be a number.",
          ],
        },
        {
          type: "boolean_radio",
          label: "Is Set Prices Enabled",
          key: "is_set_prices_enabled",
        },
        {
          type: "boolean_radio",
          label: "Force Store Computations",
          key: "force_store_computations",
        },
        {
          type: "boolean_radio",
          label: "Is Import Reservation Enabled",
          key: "is_import_reservation_enabled",
        },
      ];

      if (this.selectedPgCmMappingSupported) {
        schema.push({
          type: "select_autocomplete",
          label: "Channel Manager ID",
          key: "channel_manager_id",
          value: this.cmIdsDropdown,
        });
      } else {
        schema.push({
          type: "string",
          label: "Channel Manager ID",
          key: "channel_manager_id",
        });
      }

      if (this.selectedPgCmRatesSupported) {
        schema.push({
          type: "select_autocomplete",
          label: "Channel Manager Rate ID",
          key: "channel_manager_rate_id",
          value: this.rateMasterDropdown,
        });
      } else {
        schema.push({
          type: "string",
          label: "Channel Manager Rate ID",
          key: "channel_manager_rate_id",
        });
      }

      return schema;
    },
  },

  watch: {
    async globalSelectedPgId(newVal, oldVal) {
      this.pgProperties = null;
      await this.fetch(newVal);
      await this.checkMappingSupport();
      if (this.selectedPgCmMappingSupported) {
        await this.getMappingsFromCm({ pgId: this.globalSelectedPgId });
      }
    },
  },

  methods: {
    ...mapActions({
      deleteProperty: "core/deleteProperty",
      getMappingsFromCm: "mappingAndRates/getMappingsFromCm",
      checkMappingSupport: "mappingAndRates/checkMappingSupport",
    }),
    addGlobalStyle(className, styleRules) {
      // Create a new style element
      var styleElement = document.createElement("style");
      document.head.appendChild(styleElement);

      // Get the reference to the newly created stylesheet
      var styleSheet = styleElement.sheet;

      // Construct the CSS rule
      var cssRule = className + " { " + styleRules + " }";

      // Add the CSS rule to the stylesheet
      styleSheet.insertRule(cssRule, 0);
    },
    backgroundColor(item) {
      if (
        this.$store.state.core.currentPG.metadata.id !==
        item.spec.property_group_id
      ) {
        return this.darkMode
          ? `children-table-row-dark`
          : `children-table-row-light`;
      }
    },
    saveOrderValue(event) {
      return event || null;
    },
    async downloadXlsx() {
      const propertiesToExport = this.filteredResults.map((result) => {
        return {
          ...result.metadata,
          ...result.spec,
        };
      });
      await jsonToWorksheet(
        propertiesToExport,
        "properties.xlsx",
        "xlsx",
        this.currentlyEnabledTableColumns
      );
    },
    graphJsonPrettify(objGraph) {
      const graphStr =
        objGraph !== null
          ? objGraph.join(",").replaceAll(/[\r\n]/gm, "")
          : null;
      const index =
        this.$store.state.mappingAndRates.priceStrategySource.findIndex(
          (value) => value.value === graphStr
        );
      if (index !== -1) {
        return this.$store.state.mappingAndRates.priceStrategySource[index]
          .text;
      } else {
        return String(objGraph);
      }
    },
    openPropertyMappings(propId) {
      this.mappingsSelectedProperty = propId;
      this.mappingsDialog = true;
    },
    closeDialog() {
      this.bulkUpdateObj = {};
      this.dialog = false;
    },
    bulkUpdateAction() {
      this.pgProperties.forEach((p) => {
        if (this.selectedRowIdList.includes(p.metadata.id)) {
          p.spec = { ...p.spec, ...this.bulkUpdateObj };
          if (!this.editedProperties.includes(p.metadata.id)) {
            this.editedProperties.push(p.metadata.id);
          }
        }
      });
      this.closeDialog();
    },
    async saveEdits() {
      this.$refs.form.validate();

      if (this.validForm) {
        var message = null;

        for (var i = 0; i < this.editedProperties.length; i++) {
          let pid = this.editedProperties[i];
          let property = this.pgPropertiesMap[pid];
          let propertyGroupId = property.spec.property_group_id;
          property.spec.updated_at = new Date().toISOString();
          await this.$store.dispatch("core/updateProperty", {
            pgId: propertyGroupId,
            config: property,
          });
        }
        this.fetch();
      }
    },
    close(pid) {
      if (this.editedProperties.includes(pid) == false) {
        this.editedProperties.push(pid);
      }
    },
    fetch() {
      this.pgProperties =
        this.$store.state.core.currentPropertiesIncludingChildren;
      this.pgProperties = this.pgProperties.sort(
        (a, b) => parseFloat(a.metadata.id) - parseFloat(b.metadata.id)
      );

      //this.unitsCount = 0;
      //for(var p of this.pgProperties){
      //  this.unitsCount += parseInt(p.spec.num_units);
      //}

      this.pgPropertiesMap = {};
      this.editedProperties = [];
      for (var i = 0; i < this.pgProperties.length; i++) {
        let p = this.pgProperties[i];
        this.pgPropertiesMap[p.metadata.id] = p; //MD5(p).toString();
        if (p.spec.base_price_map != null) {
          p.spec.base_price_map = JSON.stringify(p.spec.base_price_map);
        }
      }
    },
    selectRowWithoutGraphJson() {
      this.selectedRowList = this.pgProperties.filter(
        (p) => p.spec.graph_json?.length > 0
      );
    },
  },
  async created() {
    await this.fetch(this.$store.state.core.currentPG.metadata.id);
    this.editedProperties = [];
    await this.addGlobalStyle(
      `.children-table-row-light`,
      `background-color: #81D4FA;`
    );
    await this.addGlobalStyle(
      `.children-table-row-dark`,
      `background-color: #546E7A;`
    );

    await this.checkMappingSupport();
    if (this.selectedPgCmMappingSupported) {
      await this.getMappingsFromCm({
        pgId: this.$store.state.core.currentPG.metadata.id,
      });
    }
  },
};
</script>

<style>
.properties-data-table .v-data-table-header {
  background: #262b2f !important;
}

.properties-data-table th[role="columnheader"] {
  background: #262b2f !important;
}
</style>
