<template>
    <v-container class="pa-4" fluid>
        <v-card class="pa-4 card-ui">
            <v-card-title>Partner Statistics</v-card-title>
            <DataFilters
                :items="parsedData"
                :schema="filtersSchema"
                v-model="filteredData"
                class="card-ui"
            ></DataFilters>

            <v-data-table
                :items="filteredData"
                :headers="headers"
            ></v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DataFilters from '@/components/utils/DataFilters.vue';

export default {
    components: {DataFilters},
    data() {
        return {
            headers: [
                {text: "Id", value: "id"},
                {text: "Name", value: "name"},
                {text: "Lifecycle Status", value: "lifecycleStatus"},
                {text: "Pms", value: "pms"},
                {text: "Pms Id", value: "pmsId"},
                {text: "Channel Manager", value: "channelManager"},
                {text: "Channel Manager Id", value: "channelManagerId"},
            ],

            filtersSchema: [
                { text: "Lifecycle Status" , value: "lifecycleStatus" , defaultFilters: ["live/default", "trial/default"]},
                { text: "Pms" , value: "pms" },
                { text: "Channel Manager" , value: "channelManager" },
            ],

            parsedData: [],
            
            filteredData: [],
        }
    },
    computed: {
        ...mapState({
            propertyGroupsList: state => state.core.propertyGroupsList,
        }),
        ...mapGetters({
            lifecycleStatusByPgId: "core/lifecycleStatusByPgId",
        }),
    },
    methods: {
        parseData() {

            this.parsedData = this.propertyGroupsList.map(pg => {
                let lifecycleName = this.lifecycleStatusByPgId[pg.metadata.id]?.lifecycleStateName
                let lifecycleLabel = this.lifecycleStatusByPgId[pg.metadata.id]?.lifecycleStateLabel
                return {
                    id: pg.metadata.id,
                    name: pg.metadata.name,
                    lifecycleStatus: ( lifecycleName + "/" + lifecycleLabel ) || null,
                    pms: pg.spec.pms_name,
                    pmsId: pg.spec.pms_id,
                    channelManager: pg.spec.channel_manager_name,
                    channelManagerId: pg.spec.channel_manager_id,
                }
            })
        },
    },
    created() {
        if (!this.parsedData.length) {
            this.parseData()
        }
    }
}
</script>