<template>
  <v-container fluid class="pa-4">
    <EditPGInfo mode="add" />
  </v-container>
</template>

<script>
import EditPGInfo from '../components/EditPGInfo.vue';

export default {
    components: {
        EditPGInfo
    }
}
</script>