<template>
    <v-card class="mt-2 card-ui">
        <v-toolbar dense elevation="0" :class="{ 'grey darken-3': darkMode }">
            <v-card-title class="overline ml-0 pl-0">
                <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                <b class="grey--text darken-6">Property group state history</b>
            </v-card-title>
            <v-spacer />
        </v-toolbar>
        <v-autocomplete class="pt-6 px-4"
            :class="currentBundle === null || !currentHistoryByBundle[currentBundle] ? 'pb-6' : ''" outlined
            label="Select Bundle History To Show" dense hide-details :items="bundlesList"
            v-model="currentBundle"></v-autocomplete>
        <v-card-text v-if="!!currentHistoryByBundle?.[currentBundle]">
            <v-data-table :items="currentHistoryByBundle?.[currentBundle]"
                :headers="lifecycleHistoryHeadersByBundle?.[currentBundle]" :items-per-page="5"
                sort-by="metadata.insertDate" class="card-ui" loading-text="Loading state" sort-desc>
                <template v-slot:item.metadata.insertDate="{ item }">
                    {{ formatDate(item.metadata.insertDate) }}
                </template>
                <template v-if="currentBundle === 'smartpricing'" v-slot:item.spec.fields.update.propertyGroup="{ item }">
                    <v-chip :color="item.spec.fields.update.propertyGroup == false ? 'green' : 'red'" dark small>
                        {{ item?.spec.fields.update.propertyGroup }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import { formatDate } from "@/utils/dateUtils"

export default {
    data() {
        return {
            currentBundle: "smartpricing"
        }
    },
    computed: {
        ...mapState({
            darkMode: state => state.ui.darkMode,
            lifecycleHistoryHeadersByBundle: state => state.lifecycle.lifecycleHistoryHeadersByBundle,
        }),
        ...mapGetters({
            currentHistoryByBundle: "lifecycle/currentHistoryByBundle",
            bundlesList: "lifecycle/bundlesList"
        }),
    },
    methods: {
        formatDate,
    }
}

</script>
