<template>
  <v-container fluid>

    <v-dialog v-model="rawLogsDialog">
      <v-card style="min-height: 80vh;" class="card-ui">
        <v-toolbar flat class="app-bar">
          <v-btn icon dark @click="rawLogsDialog = false">
            <v-icon class="white--text">mdi-close-circle</v-icon>
          </v-btn>
          <v-toolbar-title class="pb-0 mb-0 subheading font-weight-light"> Property Group <b class="ml-2">{{rawLogsDialogContent.propertyGroup}}</b><br> {{rawLogsDialogContent.date}}</v-toolbar-title>
          <v-spacer/>
            Level: <b :class="rawLogsDialogContent.level == 'info' ? 'pl-2 pr-2' : 'pl-2 pr-2 red--text'"> {{rawLogsDialogContent.level}}</b> (<b >{{ parseInt((new Date() - new Date(rawLogsDialogContent.date)) / (1000*60)) }} minutes ago</b>)<br>
          <template v-slot:extension>

            <v-tabs  background-color="card-ui" v-model="messaggeItemTab" align-with-title>
              <v-tabs-slider :color="rawLogsDialogContent.level == 'info' ? 'green--text' : 'red--text'"></v-tabs-slider>
              <v-tab dark class="white--text" v-for="item in ['message', 'tracking', 'error']" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-card-text v-if="messaggeItemTab == 0">
          <v-divider class="mb-4"/>
          <EditorReadOnlyHack :code="codeFor(rawLogsDialogContent)" lang="yaml" :readonly="true"/>
          <!--
          <div style="background: rgb(30,30,30); border-radius: 5px; overflow-y: scroll;" class="pa-2">
            <pre :class="rawLogsDialogContent.level == 'info' ? 'green--text' : 'red--text'" style="font-size: 16px">{{JSON.stringify(rawLogsDialogContent, undefined, 4)}}</pre>
          </div>-->
        </v-card-text>
        <v-card-text v-if="messaggeItemTab == 1">
          <v-divider class="mb-4"/>
          <v-btn
            class="ma-4"
            :disabled="!rawLogsDialogContentTracking.length"
            @click="copytracking(rawLogsDialogContentTracking)"
          >{{ copied ? "Copied" : "Copy All Logs" }}</v-btn>
          <v-divider  class="mb-4"/>
          <v-virtual-scroll
            height="1000"
            item-height="1000"
            :bench="2"
            :items="rawLogsDialogContentTracking"
          >
            <template v-slot:default="{ item }">
              <h4> {{(new Date(item.raw.timestamp)).toISOString().split('T')[1]}} / {{item.raw.trackId}} / {{item.raw.kind}} / {{item.raw.level}} </h4>
              <EditorReadOnlyHack :code="codeFor(item.raw.httpLogs.httpLogs)" lang="json" :readonly="true"/>
              <v-spacer></v-spacer>
            </template>
          </v-virtual-scroll>

        </v-card-text>
        <v-card-text v-if="rawLogsDialogContent.data !== undefined && messaggeItemTab == 2">
          <v-divider  class="mb-4"/>
          <div style="background: rgb(20,20,20); border-radius: 5px; overflow-y: scroll;" class="pa-2">
            <pre class="red--text" style="font-size: 16px">{{JSON.stringify(rawLogsDialogContent.data, undefined, 4)}}</pre>
          </div>
        </v-card-text>
        <v-card-text v-if="rawLogsDialogContent.data == undefined && messaggeItemTab == 2">
          <v-divider  class="mb-4"/>
          <div style="background: rgb(20,20,20); border-radius: 5px;" class="pa-2">
            No Errors
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
const yaml = require('js-yaml')
import EditorReadOnlyHack from '@/components/editor/EditorReadOnlyHack'

export default Vue.extend({
  components: {EditorReadOnlyHack},
  name: 'LogsDialog',
  data: function () {
    return {
      copied: false,
      messaggeItemTab: 0,
      rawLogsDialog: false,
      rawLogsDialogContent: {
        runId: null,
        jobId: null,
      },
      rawLogsDialogContentTracking: []
    }
  },
  methods: {
    async copytracking(allLogs){

      const parsedLogs = allLogs.map(log => {
        return {
          metadata: {
            timestamp: log?.raw?.timestamp || null,
            trackId: log?.raw?.trackId || null,
            kind: log?.raw?.kind || null,
            level: log?.raw?.level || null,
          },
          body: log?.raw?.httpLogs?.httpLogs || null
        }
      })

      await navigator.clipboard.writeText(JSON.stringify(parsedLogs, null, "\t"))
      this.copied = true
      setTimeout(() => this.copied = false, 2000)
    },
    codeFor (object, lang) {
      return object
    },
    show(row) {
      let self = this
      self.rawLogsDialogContentTracking = []
      this.$store.dispatch('log/getRawLog', {
        id: row.id,
        propertySet: row.property_set_id,
        date: row.date,
        cb: function (res) {
          self.rawLogsDialogContent = res
          self.rawLogsDialog = true
          if (self.rawLogsDialogContent !== undefined) {
            self.$store.dispatch('log/jobTrackerLogs', {
              propertySet: row.property_set_id,
              runId: self.rawLogsDialogContent.runId,
              jobId: self.rawLogsDialogContent.jobId,
              cb: function (res) {
                self.rawLogsDialogContentTracking = res
              }
            })
          }
        }
      })
    }
  }
})
</script>
