<template>
  <v-card v-if="!chartsLoading" class="card-ui pa-4">
    <v-row :elevation="5">
      <v-col class="col-3">
        <v-select
          outlined
          dense
          hide-details
          @change="selectBatchStrategy($event)"
          :items="preprocessStrategiesDropdown"
          label="Batch select preprocess strategy"
        ></v-select>
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-row>
      <v-col class="col-3">
        <v-select
          outlined
          dense
          hide-details
          v-model="selectedChartsStrategies.contour"
          :items="preprocessStrategiesDropdown"
          label="Select preprocess strategy" >
        </v-select>
      </v-col>
      <v-col class="col-12">
        <Plotly :data="contourChart.data" :layout="contourChart.layout" :display-mode-bar="false" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-card-title>Yearly Price</v-card-title>
    <v-row>
      <v-col class="col-3">
        <v-select
          outlined
          dense
          hide-details
          v-model="selectedChartsStrategies.yearlyPrice"
          :items="preprocessStrategiesDropdown"
          label="Select preprocess strategy"
        ></v-select>
      </v-col>  
      <v-col class="col-12">
        <Plotly :data="yearlyPriceChart.data" :layout="yearlyPriceChart.layout" :display-mode-bar="false" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-card-title>Price by Channel Type</v-card-title>
    <v-row>
      <v-col class="col-3">
        <v-select
          outlined
          dense
          hide-details
          v-model="selectedChartsStrategies.priceByChannelType"
          :items="preprocessStrategiesDropdown"
          label="Select preprocess strategy" >
        </v-select>
      </v-col>
      <v-col class="col-12">
        <Plotly :data="priceByChannelTypeChart.data" :layout="priceByChannelTypeChart.layout" :display-mode-bar="false" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-card-title>Monthly ADR by Year</v-card-title>
    <v-row>
      <v-col class="col-3">
        <v-select
          outlined
          dense
          hide-details
          v-model="selectedChartsStrategies.monthlyAdr"
          :items="preprocessStrategiesDropdown"
          label="Select preprocess strategy"
        ></v-select>
      </v-col>  
      <v-col class="col-12">
        <Plotly :data="monthlyAdrChart.data" :layout="monthlyAdrChart.layout" :display-mode-bar="false" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-card-title>Properties Price by Year</v-card-title>
    <v-row>
      <v-col class="col-3">
        <v-select
          outlined
          dense
          hide-details
          v-model="selectedChartsStrategies.propertiesPrice"
          :items="preprocessStrategiesDropdown"
          label="Select preprocess strategy"
        ></v-select>
      </v-col> 
      <v-col class="col-12">
        <Plotly :data="propertiesPriceChart.data" :layout="propertiesPriceChart.layout" :display-mode-bar="false" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-row>
      <v-col class="col-6">
        <v-card-title>ADR by origin channel</v-card-title>
        <v-row>
          <v-col class="col-12">
            <v-select
              outlined
              dense
              hide-details
              v-model="selectedChartsStrategies.adrOriginChannel"
              :items="preprocessStrategiesDropdown"
              label="Select preprocess strategy"
            ></v-select>
          </v-col>

          <v-col class="col-12">
            <Plotly :data="adrOriginChannelChart.data" :layout="adrOriginChannelChart.layout" :display-mode-bar="false" />
          </v-col>
        </v-row>
      </v-col>  

      <v-divider vertical></v-divider>

      <v-col class="col-6">
        <v-card-title>ADR Price Range</v-card-title>
        <v-row>
          <v-col class="col-12">
            <v-select
              outlined
              dense
              hide-details
              v-model="selectedChartsStrategies.adrPriceRange"
              :items="preprocessStrategiesDropdown"
              label="Select preprocess strategy"
            ></v-select>
          </v-col>
  
          <v-col class="col-12">
            <Plotly :data="adrPriceRangeChart.data" :layout="adrPriceRangeChart.layout" :display-mode-bar="false" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-card-title>Rate Description</v-card-title>
    <v-row>
      <v-col class="col-3">
        <v-select
          outlined
          dense
          hide-details
          v-model="selectedChartsStrategies.rateDescription"
          :items="preprocessStrategiesDropdown"
          label="Select preprocess strategy"
        ></v-select>
      </v-col>
      <v-col class="col-12">
        <Plotly :data="rateDescriptionChart.data" :layout="rateDescriptionChart.layout" :display-mode-bar="false" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>
    
    <v-card-title>Rate Description & Board Type</v-card-title>
    <v-row>
      <v-col class="col-3">
        <v-select
          outlined
          dense
          hide-details
          v-model="selectedChartsStrategies.rateDescriptionAndBoardType"
          :items="preprocessStrategiesDropdown"
          label="Select preprocess strategy"
        ></v-select>
      </v-col>
      <v-col class="col-12">
        <v-row>
          <v-col class="col-6">
            <Plotly :data="rateDescriptionPieChartChart.data" :layout="rateDescriptionPieChartChart.layout" :display-mode-bar="false" />
          </v-col>
          <v-col class="col-6">
            <Plotly :data="boardTypePieChartChart.data" :layout="boardTypePieChartChart.layout" :display-mode-bar="false" />  
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>
    
    <v-card-title>Guest count price by year</v-card-title>
    <v-row>
      <v-col class="col-12">
        <v-row>
          <v-col class="col-3">
            <v-select
              outlined
              dense
              hide-details
              v-model="selectedChartsStrategies.guestCountPrice"
              :items="preprocessStrategiesDropdown"
              label="Select preprocess strategy"
            ></v-select>
          </v-col>  
        </v-row>
      </v-col>
      
      <v-col class="col-6" v-for="year in guestCountPriceChart.data[1]">
        <Plotly :data="guestCountPriceChart.data[0][year]" :layout="guestCountPriceChart.layout" :display-mode-bar="false" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-card-title>Yearly board type price</v-card-title>
    <v-row>
      <v-col class="col-12">
        <v-row>
          <v-col class="col-3">
            <v-select
              outlined
              dense
              hide-details
              v-model="selectedChartsStrategies.yearlyBoardType"
              :items="preprocessStrategiesDropdown"
              label="Select preprocess strategy" >
            </v-select>
          </v-col>
        </v-row>
      </v-col>  
      <v-col class="col-6">
        <Plotly :data="yearlyBoardTypeChart.data" :layout="yearlyBoardTypeChart.layout" :display-mode-bar="false" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-card-title>Stay length price by year</v-card-title>
    <v-row>
      <v-col class="col-12">
        <v-row>
          <v-col class="col-3">
            <v-select
              outlined
              dense
              hide-details
              v-model="selectedChartsStrategies.stayLengthPrice"
              :items="preprocessStrategiesDropdown"
              label="Select preprocess strategy" >
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-6" v-for="year in stayLengthPriceChart.data[1]">
        <Plotly :data="stayLengthPriceChart.data[0][year]" :layout="stayLengthPriceChart.layout" :display-mode-bar="false" />
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-row>
      <v-col class="col-6">
        <v-card-title>Yearly nights count</v-card-title>
        <v-col class="col-12">
          <v-select
            outlined
            dense
            hide-details
            v-model="selectedChartsStrategies.yearlyNightCount"
            :items="preprocessStrategiesDropdown"
            label="Select preprocess strategy" >
          </v-select>
        </v-col>
        <v-col class="col-12">
          <Plotly :data="yearlyNightCountChart.data" :layout="yearlyNightCountChart.layout" :display-mode-bar="false" />
        </v-col>
      </v-col>
      
      <v-divider vertical></v-divider>

      <v-col class="col-6">
        <v-card-title>Yearly price sum</v-card-title>
        <v-col class="col-12">
          <v-select
            outlined
            dense
            hide-details
            v-model="selectedChartsStrategies.yearlyPriceSum"
            :items="preprocessStrategiesDropdown"
            label="Select preprocess strategy" >
          </v-select>
        </v-col>
        <v-col class="col-12">
          <Plotly :data="yearlyPriceSumChart.data" :layout="yearlyPriceSumChart.layout" :display-mode-bar="false" />
        </v-col>
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-card-title>Guest count by property</v-card-title>
    <v-row>
      <v-col class="col-3">
        <v-select
          outlined
          dense
          hide-details
          v-model="selectedChartsStrategies.guestCountByProperty"
          :items="preprocessStrategiesDropdown"
          label="Select preprocess strategy" >
        </v-select>
      </v-col> 
      <v-col class="col-3">
        <v-select
          outlined
          dense
          hide-details
          v-model="selectGuestCountProperty"
          :items="allowedPropertiesDropdown"
          label="Select property" >
        </v-select>
      </v-col>

      <v-col class="col-12">
        <Plotly :data="guestCountByPropertyChart.data.properties_charts[selectGuestCountProperty] || null" :layout="guestCountByPropertyChart.layout" :display-mode-bar="false" />
      </v-col>
    </v-row>

  </v-card>
  <v-card 
    v-else 
    class="card-ui pa-4 d-flex justify-center align-center flex-column" 
    height="300"
    elevation="0"
  >
    <v-progress-circular
      :size="70"
      :width="7"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <p>Loading</p>
  </v-card>
</template>


<script>
import { Plotly } from 'vue-plotly'
import { mapGetters, mapState } from 'vuex'

export default {
  name:"preprocessCharts",
  components: {
    Plotly,
  },
  data() {
    return {
      chartsLoading: false,

      selectedChartsStrategies: {
        contour: null,
        yearlyPrice: null,
        priceByChannelType: null,
        monthlyAdr: null,
        propertiesPrice: null,
        adrOriginChannel: null,
        adrPriceRange: null,
        rateDescription: null,
        rateDescriptionPieChart: null,
        boardTypePieChart: null,
        guestCountPrice: null,
        yearlyBoardType: null,
        stayLengthPrice: null,
        yearlyNightCount: null,
        yearlyPriceSum: null,
        guestCountByProperty: null,
      },

      selectGuestCountProperty: null,
    }
  },
  computed: {
    ...mapState({
      darkMode: state => state.ui.darkMode,
      tracesLists: state => state.preprocessCharts.tracesLists,
    }),
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId",
      preprocessStrategiesDropdown: "pipelineV1/preprocessStrategiesDropdown",
      allowedPropertiesDropdown: "pipelineV1/allowedPropertiesDropdown"
    }),

    contourChart() {
      return {
        layout: this.get2DContourChartLayout(),
        data: !!this.tracesLists?.contour ? this.tracesLists?.contour[this.selectedChartsStrategies?.contour] : null
      }
    },
    yearlyPriceChart() {
      return {
        layout: this.BPlotChartLayout('', 'Year', 'Price','overlay', true, 'h', 50, 50, 50),
        data: this.tracesLists?.yearlyPrice[this.selectedChartsStrategies.yearlyPrice] || null
      }
    },
    priceByChannelTypeChart() {
      return {
        layout: this.BPlotChartLayout('', 'Origin channel', 'Price', 'group', true, 'h'),
        data: this.tracesLists?.priceByChannelType[this.selectedChartsStrategies.priceByChannelType] || null
      }
    },
    monthlyAdrChart() {
      return {
        layout: this.getMonthTotalsBarChartLayout(),
        data: this.tracesLists?.monthlyAdr[this.selectedChartsStrategies.monthlyAdr] || null
      }
    },
    propertiesPriceChart() {
      return {
        layout: this.BPlotChartLayout('', 'Property', 'Price', 'group', 'h'),
        data: this.tracesLists?.propertiesPrice[this.selectedChartsStrategies.propertiesPrice] || null
      }
    },
    adrOriginChannelChart() {
      return {
        layout: this.getPieChartLayout('', 'Origin channel', 'ADR'),
        data: this.tracesLists?.adrOriginChannel[this.selectedChartsStrategies.adrOriginChannel] || null
      }
    },
    adrPriceRangeChart() {
      return {
        layout: this.getPieChartLayout('', 'Origin channel', 'Price<br>range', 'h'),
        data: this.tracesLists?.adrPriceRange[this.selectedChartsStrategies.adrPriceRange] || null
      }
    },
    rateDescriptionChart() {
      return {
        layout: this.BPlotChartLayout('', 'Price', '',  'group', true, 'h', 200),
        data: this.tracesLists?.rateDescription[this.selectedChartsStrategies.rateDescription] || null
      }
    },
    rateDescriptionPieChartChart() {
      return {
        layout: this.getPieChartLayout('', 'Rate description', 'Rate<br>description'),
        data: this.tracesLists?.rateDescriptionPieChart[this.selectedChartsStrategies.rateDescriptionPieChart] || null
      }
    },
    boardTypePieChartChart() {
      return {
        layout: this.getPieChartLayout('', 'Board Type', 'Board<br>Type'),
        data: this.tracesLists?.boardTypePieChart[this.selectedChartsStrategies.boardTypePieChart] || null
      }
    },
    guestCountPriceChart() {
      return {
        layout: this.BPlotChartLayout('', '', '', 'group', true, 'h', 170, 20, 10),
        data: this.tracesLists?.guestCountPrice[this.selectedChartsStrategies.guestCountPrice] || null
      }
    },
    yearlyBoardTypeChart() {
      return {
        layout: this.BPlotChartLayout('', '', 'Price', 'group', true, 'h', 50, 70, 50),
        data: this.tracesLists?.yearlyBoardType[this.selectedChartsStrategies.yearlyBoardType] || null
      }
    },
    stayLengthPriceChart() {
      return {
        layout: this.BPlotChartLayout('', '', '', 'group', true, 'h', 170, 20, 10),
        data: this.tracesLists?.stayLengthPrice[this.selectedChartsStrategies.stayLengthPrice] || null
      }
    },
    yearlyNightCountChart() {
      return {
        layout: this.getStackedBarChartLayout('', '', 'Count', true, 'h'),
        data: this.tracesLists?.yearlyNightCount[this.selectedChartsStrategies.yearlyNightCount] || null
      }
    },
    yearlyPriceSumChart() {
      return {
        layout: this.getStackedBarChartLayout('', '', 'Sum', true, 'h'),
        data: this.tracesLists?.yearlyPriceSum[this.selectedChartsStrategies.yearlyPriceSum] || null
      }
    },
    guestCountByPropertyChart() {
      return {
        layout: this.getPieChartLayout('', 'Property', 'Guest<br>Count<br>%'),
        data: this.tracesLists?.guestCountByProperty[this.selectedChartsStrategies.guestCountByProperty] || null
      }
    },
  },

  methods: {
    getguestCountBPlotsLayout(nyears){
      return {
        title: 'Price by guest count',
        showgrid: true,
        paper_bgcolor: '#221f1c',
        plot_bgcolor: '#373432',
        font:{
          color: '#Ffffff'
        },
        grid: {
          rows: nyears,
          columns: 1,
          pattern: 'independent',
          roworder: 'bottom to top'
        }
      }
    },
    getStackedBarChartLayout(title, xtitle, ytitle, showlegend = true, legendOrient = 'v', marginLeft = 50, marginTop = 50, marginRight = 50, xgridcolor = 'rgba(165, 161, 161, 0.27)', ygridcolor = 'rgba(165, 161, 161, 0.27)'){
      return {
        title: title,
        legend: {
          "orientation": legendOrient,
          "x" : 0, 
          "y" : -0.5
        },
        barmode: 'stack',
        hovermode: 'closest',
        showgrid: true,
        paper_bgcolor: this.darkMode ? '#221f1c' : '#fff',
        plot_bgcolor: this.darkMode ? '#2a2521' : '#fff',
        font:{
          color: this.darkMode ? '#Ffffff' : `#333`
        },
        xaxis: {
          showgrid:true,
          gridcolor: xgridcolor,
          title: xtitle,
          dtick: 1,
        },
        yaxis: {
          showgrid: true,
          gridcolor: ygridcolor,
          title: ytitle,
        }
      }
    },
    BPlotChartLayout(title, xtitle, ytitle, boxmode = 'overlay', showlegend = true, legendOrient = 'h', marginLeft = 50, marginTop = 50, marginRight = 50, xgridcolor = 'rgba(165, 161, 161, 0.27)', ygridcolor = 'rgba(165, 161, 161, 0.27)'){
      return {
        title: title,
        boxmode: boxmode,
        showlegend: showlegend,
        legend: {
          "orientation": legendOrient,
          "x" : 0, 
          "y" : -0.5
        },
        autosize: true,
        margin: {
          t: marginTop,
          l: marginLeft,
          r: marginRight,
          b: 50
        },
        hovermode: 'closest',
        showgrid: true,
        paper_bgcolor: this.darkMode ? '#221f1c' : '#fff',
        plot_bgcolor: this.darkMode ? '#2a2521' : '#fff',
        font:{
          color: this.darkMode ? '#Ffffff' : `#333`
        },
        xaxis: {
          showgrid:true,
          gridcolor: xgridcolor,
          title: xtitle,
        },
        yaxis: {
          showgrid: true,
          gridcolor: ygridcolor,
          title: ytitle,
        }
      }
    },
    getPieChartLayout(title, text, annotationText, legenOrient='h', showlegend = true, marginLeft = 50, marginTop = 50, marginRight = 50,){
      return {
        "histogram2dcontour": [{"type": "histogram2dcontour", "colorbar": {"ticks": "", "outlinewidth": 0}, "autocolorscale": true}], 
        title: title,
        showlegend: true,
        legend: {
          "orientation": legenOrient,
          "x" : 0, 
          "y" : -0.5
        },
        autosize: true,
        text: text,
        margin: {t: 50},
        hovermode: 'closest',
        showgrid: true,
        paper_bgcolor: this.darkMode ? '#221f1c' : '#fff',
        plot_bgcolor: this.darkMode ? '#2a2521' : '#fff',
        font:{
          color: this.darkMode ? '#Ffffff' : `#333`,
        },
        annotations:[
          {
            font: {
              size: 20
            },
            showarrow: false,
            text: annotationText,
            x: 0.5,
            y: 0.5
          }
        ],
      }
    },
    getMonthTotalsBarChartLayout(){
      return {
        title: '',
        barmode: 'group',
        showlegend: true,
        legend: {
          "orientation": "h",
          "x" : 0, 
          "y" : -0.5
        },
        autosize: true,
        margin: {t: 50},
        hovermode: 'closest',
        showgrid: true,
        paper_bgcolor: this.darkMode ? '#221f1c' : '#fff',
        plot_bgcolor: this.darkMode ? '#2a2521' : '#fff',
        font:{
          color: this.darkMode ? '#Ffffff' : `#333`
        },
        xaxis: {
          showgrid:true,
          gridcolor:'rgba(165, 161, 161, 0.27)',
          title: 'Month',
        },
        yaxis: {
          showgrid: true,
          gridcolor:'rgba(165, 161, 161, 0.27)',
          title: 'Adr',
        }
      }
    },
    get2DContourChartLayout(){
      const gridColor = 'rgba(165, 161, 161, 0.27)';

      return {
        showlegend: true,
        autosize: true,
        margin: {t: 50},
        hovermode: 'closest',
        bargap: 0.05, 
        bargroupgap: 0.2, 
        barmode: "overlay", 
        paper_bgcolor: this.darkMode ? '#221f1c' : '#fff',
        plot_bgcolor: this.darkMode ? '#2a2521' : '#fff',
        font:{
          color: this.darkMode ? '#Ffffff' : `#333`
        },
        colorbar:{
          bgcolor: '#2a2521',
        },
        xaxis: {
          domain: [0, 0.75],
          showgrid: true,
          zeroline: false,
          title: 'Book days',
          gridcolor: gridColor,
        },
        yaxis: {
          domain: [0, 0.7],
          showgrid: true,
          zeroline: false,
          type: "linear", 
          title: 'Price',
          gridcolor: gridColor,
        },
        xaxis2: {
          domain: [0.75, 1],
          showgrid: true,
          zeroline: false,
          title: 'book days',
          gridcolor: gridColor,
        },
        yaxis2: {
          domain: [0.7, 1],
          showgrid: true,
          zeroline: false,
          title: 'Price',
          gridcolor: gridColor,
        }
      }
    },

    // select the strategy for all the dropdowns
    selectBatchStrategy(strat) {
      const charts = Object.keys(this.selectedChartsStrategies)

      charts.forEach(chart => {
        this.selectedChartsStrategies[chart] = strat
      })
    },
    
  },
  async created() {
    this.chartsLoading = true
    if (!this.$store.getters["preprocessCharts/allPreprocessPipelineBindsLoaded"]) {
      await this.$store.dispatch("preprocessCharts/fetchPreprocessData")
    }
    
    this.selectBatchStrategy(this.$store.getters["pipelineV1/preprocessStrategies"].includes(this.$store.state.pipelineV1?.selectedPipelineBind?.name) ? this.$store.state.pipelineV1?.selectedPipelineBind?.name : this.$store.getters["pipelineV1/preprocessStrategies"][0])
    this.selectGuestCountProperty = this.allowedPropertiesDropdown[0].value
    
    await Promise.all([
      this.$store.dispatch("preprocessCharts/loadContourChart"),
      this.$store.dispatch("preprocessCharts/loadYearlyPriceChart"),
      this.$store.dispatch("preprocessCharts/loadPriceByChannelTypeChart"),
      this.$store.dispatch("preprocessCharts/loadMonthlyAdrChart"),
      this.$store.dispatch("preprocessCharts/loadPropertiesPriceChart"),
      this.$store.dispatch("preprocessCharts/loadAdrOriginChannelChart"),
      this.$store.dispatch("preprocessCharts/loadAdrPriceRangeChart"),
      this.$store.dispatch("preprocessCharts/loadRateDescriptionChart"),
      this.$store.dispatch("preprocessCharts/loadRateDescriptionPieChart"),
      this.$store.dispatch("preprocessCharts/loadBoardTypePieChart"),
      this.$store.dispatch("preprocessCharts/loadGuestCountPriceChart"),
      this.$store.dispatch("preprocessCharts/loadYearlyBoardTypeChart"),
      this.$store.dispatch("preprocessCharts/loadStayLengthPriceChart"),
      this.$store.dispatch("preprocessCharts/loadYearlyNightCountChart"),
      this.$store.dispatch("preprocessCharts/loadYearlyPriceSumChart"),
      this.$store.dispatch("preprocessCharts/loadGuestCountByPropertyChart"),
    ])
    this.chartsLoading = false
  }
}
</script>

















