<template >
    <v-row>
        <v-col>
            <v-img :width="400" :aspect-ratio="16 / 9" cover
                :src="`https://ik.imagekit.io/smartpaying/propertyset/${propertySet?.id}.jpg`"></v-img>
            <v-textarea :rows="20" class="overflow-auto" :value="JSON.stringify(propertySet?.config, null, 2)"
                @input="onChangeConfig"></v-textarea>
            <div class="d-flex justify-end">
                <v-btn color="warning" class="mx-2" @click="uploadToDb(true)" :loading="loadingData">
                    Create property group PROD
                </v-btn>
                <v-btn color="primary" class="mr-2"
                    href="https://docs.google.com/document/d/1UZi173F5PWMxqa9eg19ADQrAcTiK2AHDolZCEFAYT9c/edit?usp=sharing"
                    target="_blank">
                    Check documentation
                </v-btn>
                <v-btn color="secondary" @click="$emit('back')">Back</v-btn>
            </div>
            <ConfirmDialog ref="dialog" />
        </v-col>
    </v-row>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import ConfirmDialog from '@/components/utils/ConfirmDialog'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name: 'PropertyGroupCreationStep',
    components: { ConfirmDialog },
    data: function () {
        return {
            loadingData: false,
            actualConfig: null
        }
    },
    computed: {
        ...mapGetters({
            propertySet: 'smartpaying/propertySet',
            psIds: 'smartpaying/psIds'
        })
    },
    methods: {
        onChangeConfig(value) {
            this.actualConfig = value
        },
        async uploadToDb() {
            const propertySetToCreate = { ...this.propertySet }
            if (this.actualConfig != null) {
                propertySetToCreate.config = JSON.parse(this.actualConfig)
            }
            for (const psId of this.psIds) {
                propertySetToCreate.id = psId
                this.loadingData = true
                const propertySet = await this.$store.dispatch('smartpaying/createpropertySet', { propertySet: propertySetToCreate })
                this.loadingData = false
                if (propertySet.response?.status == 400) {
                    const options = { cardTextClass: "pa-4 accent-1--text", color: 'orange' }
                    const title = "Error generating property group"
                    const message = propertySet.response.data
                    await this.$refs.dialog.open(title, message, options)
                }
                else {
                    this.$store.dispatch('smartpaying/setLiveState', { psId: psId, bundle: 'smartpaying' })
                    const options = { cardTextClass: "pa-4 accent-1--text", color: 'success' }
                    const title = "Customer onboarded successfully"
                    const message = `Congrats, ${propertySet.id} - ${propertySet.name} onboarded successfully, check via token generator the dashboard. If customer is integrated you should see incoming reservation in 1 hour.`
                    await this.$refs.dialog.open(title, message, options)
                }
            }
            this.$emit('finish')
        },
    }
})
</script>