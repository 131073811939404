<template>
    <v-card
        class="card-ui pa-4"
    >
        <v-card-title>General Settings</v-card-title>
        <v-card-text>
            <v-form
                ref="form"
                v-model="validForm"
            >
                <v-row>
                    <v-col class="col-12">
                        <v-row>
                            <v-col class="grow">
                                <v-autocomplete
                                    dense
                                    outlined
                                    multiple
                                    :error="!!validationCheck"
                                    :error-messages="validationCheck"
                                    label="Select Property Ids"
                                    :items="allPropertiesWithDeleted"
                                    :item-text="value => `${value.metadata.id} - ${value.metadata.name}`"
                                    :item-value="value => Number(value.metadata.id)"
                                    v-model="selectedPropertyIds"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="shrink">
                                <v-btn
                                    @click="autosetup()"
                                >Auto Setup</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="col-12">
                        <v-select
                            dense
                            outlined
                            label="Select start year"
                            :items="availableYears"
                            v-model="selectedStartYear"
                        ></v-select>
                    </v-col>
                    <v-col class="col-12">
                        <v-text-field
                            dense
                            outlined
                            label="Set the default price"
                            v-model="selectedDefaultPrice"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="primary"
                @click="saveGeneralSettings({psId: currentStrategyPS.metadata.id})"
                :disabled="!!validationCheck"
            >Save settings</v-btn>
            <v-btn
                @click="fetchGeneralSettings({psId: currentStrategyPS.metadata.id})"
            >Reset settings</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
    data() {
        return {
            availableYears: [],
            validForm: true,
        }
    },
    computed: {
        ...mapState({
            currentPropertiesIncludingChildren: state => state.core.currentPropertiesIncludingChildren,
            currentStrategyPS: (state) => state.core.currentStrategyPS,
        }),
        selectedPropertyIds: {
            get() {
                return this.$store.state.pipelineV1.currentGeneralSettings?.propertyIds
            },
            set(value) {
                this.updateGeneralSettings({key: "propertyIds", value: value})
            }
        },
        selectedStartYear: {
            get() {
                return this.$store.state.pipelineV1.currentGeneralSettings.startYear
            },
            set(value) {
                this.updateGeneralSettings({key: "startYear", value: value})
            }
        },
        selectedDefaultPrice: {
            get() {
                return this.$store.state.pipelineV1.currentGeneralSettings.defaultPrice
            },
            set(value) {
                this.updateGeneralSettings({key: "defaultPrice", value: Number(value)})
            }
        },
        allPropertiesWithDeleted() {
            const existingPropsIds = this.currentPropertiesIncludingChildren.map(val => Number(val.metadata.id))
            const deletedProps = this.selectedPropertyIds?.filter(val => !existingPropsIds.includes(val))

            const allPropsArrayWithDeleted = []
            this.currentPropertiesIncludingChildren.forEach(val => allPropsArrayWithDeleted.push(val))

            deletedProps?.forEach(val => {
                allPropsArrayWithDeleted.push({
                    metadata: {
                        name: `DELETED REMOVE IT`,
                        id: val,
                    },
                    spec: {}
                })
            })
            return allPropsArrayWithDeleted
        },
        deletedProperties() {
            return this.allPropertiesWithDeleted.filter(val => val.metadata.name === "DELETED REMOVE IT")
        },
        validationCheck() {
            return !!this.deletedProperties.length ? "Remove the deleted properties" : ""
        }
    },
    methods: {
        ...mapMutations({
            updateGeneralSettings: "pipelineV1/updateGeneralSettings", 
        }),
        ...mapActions({
            saveGeneralSettings: "pipelineV1/saveGeneralSettings",
            fetchGeneralSettings: "pipelineV1/fetchGeneralSettings",
        }),
        autosetup() {
            const filtered = this.currentPropertiesIncludingChildren.filter(prop => {
                return prop.spec.graph_json !== null || !!Number(prop.spec.num_units)
            })
            this.selectedPropertyIds = filtered.map(prop => Number(prop.metadata.id))         
            this.selectedStartYear = this.selectedStartYear ?? 2020
            this.selectedDefaultPrice = this.selectedDefaultPrice ?? 500
        }
    },
    created() {
        // populate the years available
        const endYear = new Date().getFullYear()
        const startYear = 2019
        for (let i = startYear; i<= endYear; i++) {
            this.availableYears.push(i)
        }
    },
    destroyed() {
        this.fetchGeneralSettings({psId: this.currentStrategyPS.metadata.id})
    }
}
</script>