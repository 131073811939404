<template>
  <v-card class="card-ui pa-4">
    <v-card-title>Base Price Versions</v-card-title>

    <v-data-table
      :headers="headers"
      :items="versions"
      fixed-header
      height="300"
      sort-by="updatedAt"
      sort-desc
    >
      <template v-slot:item.metadata.version="{ item }">
        <span>{{
          new Date(extractDateFromUUIDv1(item.metadata.version)).toLocaleString(
            "it-it"
          )
        }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="showConfig(item)" class="mx-2"> Show Config </v-btn>
        <v-btn class="mx-2" @click="roolbackToOldBasePrice(item)">
          Restore
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="1000"
      v-model="configDialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark
            >Base Price Config for Version
          </v-toolbar>
          <v-card-text class="pa-4">
            <v-textarea
              :value="JSON.stringify(configToShow?.spec, null, '\t')"
              readonly
              auto-grow
              outlined
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      configToShow: null,
      configDialog: false,

      headers: [
        { text: "Base Price Date", value: "metadata.version" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapState({
      basePriceVersionHistory: (state) =>
        state.basePrice.basePriceVersionHistory,
      basePriceVersionHistorySpec: (state) =>
        state.basePrice.basePriceVersionHistorySpec,
      currentPG: (state) => state.core.currentPG,
    }),
    versions() {
      return this.basePriceVersionHistory;
    },
  },
  methods: {
    ...mapMutations({
      resetSelectedGenerationValues: "basePrice/resetSelectedGenerationValues",
      resetDataFrames: "basePrice/resetDataFrames",
      resetBasePriceValues: "basePrice/resetBasePriceValues",
      resetProdBasePriceValues: "basePrice/resetProdBasePriceValues",
      resetBasePriceInfo: "basePrice/resetBasePriceInfo",
      resetGenerationSourceToSend: "basePrice/resetGenerationSourceToSend",
    }),
    ...mapActions({
      loadBasePrice: "basePrice/loadBasePrice",
      generateBasePrice: "basePrice/generateBasePrice",
      getBasePriceHistorySpec: "basePrice/getBasePriceHistorySpec",
    }),
    async showConfig(item) {
      this.configToShow = null;
      this.configDialog = true;

      await this.getBasePriceHistorySpec({ versionId: item.metadata.version });

      this.configToShow =
        this.basePriceVersionHistorySpec[item.metadata.version];
    },
    extractDateFromUUIDv1(uuid) {
      const uuidArr = uuid.split("-");
      const timeStr = [uuidArr[2].substring(1), uuidArr[1], uuidArr[0]].join(
        ""
      );
      const msecs = (parseInt(timeStr, 16) - 122192928000000000) / 10000;
      const date = new Date(msecs);
      return date;
    },
    async roolbackToOldBasePrice(item) {
      this.resetSelectedGenerationValues();
      this.resetBasePriceValues();
      this.resetGenerationSourceToSend();

      await this.getBasePriceHistorySpec({ versionId: item.metadata.version });

      this.resetBasePriceInfo();
      await this.loadBasePrice({
        basePrice: this.basePriceVersionHistorySpec[item.metadata.version],
        changeProdValues: false,
      });
    },
  },
};
</script>

