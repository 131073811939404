import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../apps/login/views/Login'

// !! We will load routes from store.app.apps
import Store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

/**
 *  !! Super pork hack.
 *  I don't know how to use dynamic components
 *  with Babel
 */
Object.values(Store.state.app.apps).forEach((app) => {
  app.subresources.forEach((appview) => {
    routes.push({
      path: appview.link,
      name: appview.prettyName,
      component: require('../apps/' + app.name + '/views/' + appview.component).default
    })
  })
})

const router = new VueRouter({
  routes
})

export default router
