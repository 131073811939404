<template>
    <v-card class="card-ui pa-5">
        <v-card-title>Property Group Info</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="validFormFields">
                <!-- Basic Info About New Property Group -->
                <v-row>
                    <v-col class="col-12">
                        <v-text-field label="Property Group Name" hint="For example Villa Maria"
                            :rules="[rules.required, rules.noTrailingSpaces]" :disabled="editDisabled" outlined dense
                            v-model="pgName"></v-text-field>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row justify="space-between" align="center">
                    <v-card-title>PMS Information</v-card-title>
                    <v-dialog transition="dialog-bottom-transition" max-width="600">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" :disabled="editDisabled" v-on="on">Clone Data from another
                                Pg</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar color="primary" dark>Clone Data from another Pg
                                </v-toolbar>
                                <v-card-text class="pa-4">
                                    <v-autocomplete :items="pgsDropdown" v-model="selectedPgToClone" dense
                                        label="Select Pg to clone from" outlined :clearable="true">
                                        Select PG
                                    </v-autocomplete>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                    <v-btn elevation="2" class="h-100" color="primary" v-on:click="dialog.value = false"
                                        @click="cloneInfoFromPg">
                                        Clone PMS/CHM Info From PG
                                    </v-btn>
                                    <v-btn text @click="dialog.value = false">Close</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-row>
                <v-row>
                    <v-col class="col-6">
                        <v-autocomplete v-model="selectedPMS" :items="pmsDropdown" :disabled="editDisabled" dense outlined
                            clearable @change="[selectedPmsEncodedAccessInfo = null, pmsID = null]"
                            label="Select a PMS"></v-autocomplete>
                    </v-col>
                    <v-col class="col-6" v-show="selectedPMS">
                        <v-text-field label="PMS ID" outlined :rules="[rules.required, rules.noTrailingSpaces]"
                            :disabled="editDisabled" v-if="requiredPmsId" dense :required="!pmsID"
                            v-model="pmsID"></v-text-field>
                    </v-col>
                    <v-col class="col-12">
                        <NewDynamicInputForm v-model="selectedPmsEncodedAccessInfo" :customSchema="encodedPmsParamsSchema"
                            :disable="editDisabled"></NewDynamicInputForm>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row justify="space-between" align="center">
                    <v-card-title>Channel Manager Information</v-card-title>
                    <v-btn v-if="this.selectedPMS === this.selectedCM && this.selectedPMS && this.selectedCM" class="ma-4"
                        :disabled="editDisabled" @click="copyFromPMS">Copy From PMS</v-btn>
                </v-row>
                <v-row>
                    <v-col class="col-6">
                        <v-autocomplete v-model="selectedCM" :items="cmDropdown" outlined clearable dense
                            @change="[selectedCmEncodedAccessInfo = null, cmID = null]" :disabled="editDisabled"
                            label="Select a Channel Manager"></v-autocomplete>
                    </v-col>
                    <v-col class="col-6" v-show="selectedCM">
                        <v-text-field label="Channel Manager ID" outlined v-if="requiredCmId"
                            :rules="[rules.required, rules.noTrailingSpaces]" :disabled="editDisabled" dense
                            :required="!cmID" v-model="cmID"></v-text-field>
                    </v-col>
                    <v-col class="col-12">
                        <NewDynamicInputForm v-model="selectedCmEncodedAccessInfo" :customSchema="encodedCmParamsSchema"
                            :disable="editDisabled"></NewDynamicInputForm>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-card-title>Additional Information</v-card-title>
                <v-row>
                    <v-col class="col-6">
                        <v-row>
                            <v-col class="col-6">
                                <v-text-field label="Homepage URL" hint="For example http://www.royalprisco.it/" outlined
                                    dense :rules="[rules.noTrailingSpaces]" v-model="homepageURL"
                                    :disabled="editDisabled"></v-text-field>
                            </v-col>
                            <v-col class="col-6">
                                <v-text-field label="Booking URL"
                                    hint="For example https://www.booking.com/hotel/it/royal-prisco.it.html" outlined dense
                                    :rules="[rules.bookingUrlValid, rules.noTrailingSpaces]" v-model="bookingURL"
                                    :disabled="editDisabled"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-autocomplete :items="currenciesDropdown" :disabled="editDisabled" outlined dense clearable
                            label="Select a currency" v-model="selectedCurrency"></v-autocomplete>
                    </v-col>
                    <v-col class="col-6">
                        <v-row>
                            <v-col class="col col-4">
                                <v-text-field label="Latitude" hint="For example 40.63060491398805" outlined dense
                                    v-model="latitude" :disabled="editDisabled"
                                    @paste="onPasteCoord($event, true)"></v-text-field>
                            </v-col>
                            <v-col class="col col-4">
                                <v-text-field label="Longitude" hint="For example 14.48469149714154" dense outlined
                                    v-model="longitude" :disabled="editDisabled"
                                    @paste="onPasteCoord($event, false)"></v-text-field>
                            </v-col>
                            <v-col class="col col-4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :disabled="editDisabled" v-bind="attrs" v-on="on"
                                            @click="calculateTimezone()">Calculate Timezone</v-btn>
                                    </template>
                                    <span>The Timezone isn't always accurate</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-text-field label="Timezone" hint="For example Europe/Rome" value="Europe/Rome" dense
                            :rules="[rules.noTrailingSpaces]" :disabled="editDisabled" v-model="timezone"
                            outlined></v-text-field>
                    </v-col>
                    <v-col class="col-6">
                        <v-textarea outlined dense name="Extra Params" :disabled="editDisabled" label="Extra Params"
                            :rules="[rules.json, rules.noTrailingSpaces]" v-model="extraParams"></v-textarea>
                    </v-col>
                    <v-col class="col-6">
                        <NewDynamicInputForm v-model="extraParams" :customSchema="propertyGroupExtraParamsSchema"
                            :disable="editDisabled"></NewDynamicInputForm>
                    </v-col>
                </v-row>
            </v-form>


            <!-- Add confirm overlay -->
            <v-overlay :value="confirmOverlay">
                <v-card :loading="loading" class="mx-auto pa-5 card-ui" max-width="900">
                    <v-card-text>
                        <p v-if="mode === 'edit'" class="text-h4 text--primary">
                            Confirm Editing Property Group
                        </p>
                        <p v-else class="text-h4 text--primary">
                            Confirm Adding Property Group
                        </p>
                        <div v-if="mode === 'edit'" class="text--primary">
                            Confirm that you're editing the following Property Group:
                            {{ pgInfo.metadata.name }}
                            <br>
                            <br>
                            - If you click on "Save whole Propertyset" the changes to PMS_NAME, PMS_ID and PMS_ACCESS_INFO (and the equivalent fields for the Channel Manager) will be propagated to all the Propertygroups that shares the PMS and CHM info with this Propertygroup.
                            <br>
                            <br>
                            - If you click on "Save single Propertygroup" the changes to PMS_NAME, PMS_ID and PMS_ACCESS_INFO (and the equivalent fields for the Channel Manager) will be applied only to this Propertygroup.
                            <br>
                            <br>
                            The PMS Propertyset includes these pgs:
                            {{ currentPMSPSPropertygroups }}
                            <br>
                            <br>
                            The CHM Propertyset includes these pgs:
                            {{ currentCHMPSPropertygroups }}
                        </div>
                        <div v-else class="text--primary">
                            Confirm that you're adding the following Property Group:
                            {{ finalResult.metadata.name }}
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="green" @click="savePropertyGroupToPs()" v-if="mode === 'edit'">
                            Save whole Propertyset
                        </v-btn>
                        <v-btn color="primary" v-else @click="addPropertyGroup()">
                            Add PG
                        </v-btn>
                        <v-btn color="red" @click="confirmOverlay = !confirmOverlay">
                            Cancel
                        </v-btn>
                        <v-btn color="primary" @click="savePropertyGroup()" v-if="mode === 'edit'">
                            Save single Propertygroup
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-overlay>

        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" elevation="1" @click="validateForms" :disabled="editDisabled">
                <div v-if="mode === 'edit'">Save</div>
                <div v-else>Add New PG</div>

            </v-btn>

            <v-btn elevation="1" @click="resetForm()" v-if="mode !== 'edit'">
                Reset
            </v-btn>
            <v-btn elevation="1" @click="resetForm()" :disabled="editDisabled" v-else>
                Reset Changes
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import NewDynamicInputForm from '@/components/newDynamicForm/NewDynamicInputForm.vue'
import { isBookingUrlValid, fixBookingUrl } from '@/utils/booking.js'
import axios from 'axios'

export default {
    components: {
        NewDynamicInputForm
    },
    props: [
        "mode"
    ],
    data() {
        return {
            validFormFields: true,
            confirmOverlay: false,
            loading: false,

            pgName: "",
            extraParams: "{}",

            selectedPMS: "",
            pmsID: null,
            selectedPmsEncodedAccessInfo: null,

            selectedCM: "",
            cmID: null,
            selectedCmEncodedAccessInfo: null,

            latitude: "",
            longitude: "",
            timezone: "Europe/Rome",

            homepageURL: "",

            bookingURL: "",
            bookingID: null,

            selectedCurrency: null,

            typeform: null,
            customer_strategy_params: null,

            selectedPgToClone: null,

            rules: {
                noTrailingSpaces: value => {
                    if (value && (value.endsWith(" ") || value.startsWith(" "))) {
                        return "No trailing spaces allowed, remove them!"
                    }
                    return true
                },
                bookingUrlValid: value => {
                    if (!value) return true
                    const isValid = isBookingUrlValid(value)
                    if (isValid == true) {
                        return true
                    } else {
                        return "The provided booking.com url is not valid, please check it"
                    }
                },
                required: value => !!value || 'Required.',
                json: value => {
                    try {
                        JSON.parse(value);
                        return true;
                    } catch (e) {
                        return "JSON must be valid!";
                    }
                },
            }
        }
    },
    watch: {
        pgInfo(newValue, oldValue) {
            if (this.mode === "edit") {
                this.getUser()
            }
        },
    },
    computed: {
        ...mapState({
            pgInfo: state => state.core.currentPG,
            api: state => state.api.api,
            pmsList: state => state.core.pmsList,
            cmList: state => state.core.cmList,
            currentPMSPS: state => state.core.currentPMSPS,
            currentCHMPS: state => state.core.currentCHMPS,
            currentPhysicalPS: state => state.core.currentPhysicalPS,
        }),
        ...mapGetters({
            currenciesDropdown: "core/currenciesDropdown",
            pgsDropdown: "core/pgsDropdown",
            pmsDropdown: "core/pmsNames",
            cmDropdown: "core/cmNames",
            pgNameById: "core/pgNameById",

            currentLifecycleStatus: "lifecycle/currentLifecycleStateByBundle",
            currentPropertySets: "core/currentPropertySets",

        }),
        currentPMSPSPropertygroups() {
            let pgsList = []
            this.$store.state.core.currentPMSPSPropertygroups.split(',').forEach(pgId => {
                pgsList.push(`${pgId} - ${this.pgNameById[pgId]}`)
            })
            return pgsList
        },
        currentCHMPSPropertygroups() {
            let pgsList = []
            this.$store.state.core.currentCHMPSPropertygroups.split(',').forEach(pgId => {
                pgsList.push(`${pgId} - ${this.pgNameById[pgId]}`)
            })
            return pgsList
        },
        editDisabled() {
            return this.mode === "edit" && (this.currentLifecycleStatus?.['smartpricing'] && !this.currentLifecycleStatus?.["smartpricing"]?.spec?.fields?.update?.propertyGroup)
        },
        selectedPmsFull() {
            const index = this.pmsList.findIndex(val => val.metadata.id === this.selectedPMS)

            if (index !== -1) {
                return this.pmsList[index]
            }
        },
        selectedCmFull() {
            const index = this.cmList.findIndex(val => val.metadata.id === this.selectedCM)

            if (index !== -1) {
                return this.cmList[index]
            }
        },
        requiredPmsId() {
            return this.selectedPmsFull?.spec?.extra_params?.pmsIdRequired || false
        },
        requiredCmId() {
            return this.selectedCmFull?.spec?.extra_params?.cmIdRequired || false
        },
        encodedPmsParamsSchema() {
            return !!this.selectedPmsFull?.spec?.encoded_access_info_schema ? this.selectedPmsFull?.spec?.encoded_access_info_schema : null
        },
        encodedCmParamsSchema() {
            return !!this.selectedCmFull?.spec?.encoded_access_info_schema ? this.selectedCmFull?.spec?.encoded_access_info_schema : null
        },
        propertyGroupExtraParamsSchema() {
            const finalSchemaArr = []
            if (Array.isArray(this.selectedPmsFull?.spec?.property_group_extra_params_schema)) {
                this.selectedPmsFull?.spec?.property_group_extra_params_schema.forEach(sch => finalSchemaArr.push(sch))
            }

            if (Array.isArray(this.selectedCmFull?.spec?.property_group_extra_params_schema)) {
                this.selectedCmFull?.spec?.property_group_extra_params_schema.forEach(sch => finalSchemaArr.push(sch))
            }
            return finalSchemaArr || null
        },
        finalResult() {
            const finalObj = {}

            finalObj.metadata = {
                name: this.pgName
            }

            if (this.mode === "edit") {
                finalObj.metadata.id = this.pgInfo.metadata.id
            }

            if ( this.requiredPmsId == false ) {
                this.pmsID = "pms_id_not_required"
            }
            if ( this.requiredCmId == false ) {
                this.cmID = "chm_id_not_required"
            }

            finalObj.spec = {
                timezone: this.timezone,
                pms_name: this.selectedPMS ? this.selectedPMS : null,
                pms_id: this.pmsID ? this.pmsID : null,
                encoded_pms_access_info: JSON.parse(this.selectedPmsEncodedAccessInfo) || null,

                channel_manager_name: this.selectedCM ? this.selectedCM : null,
                channel_manager_id: this.cmID ? this.cmID : null,
                encoded_channel_manager_access_info: JSON.parse(this.selectedCmEncodedAccessInfo) || null,

                homepage_url: this.homepageURL ? this.homepageURL : null,
                booking_id: this.bookingID,
                currency_id: this.selectedCurrency ? this.selectedCurrency : null,
                coordinates: this.latitude && this.longitude ? {
                    x: this.latitude,
                    y: this.longitude,
                } : null,

                extra_params: this.extraParams ? JSON.parse(this.extraParams) : null
            }

            return finalObj
        },
        physicalPropertyset() {
            let physicalPS = {
                metadata: {
                    id: this.currentPhysicalPS.metadata.id,
                    name: this.currentPhysicalPS.metadata.name,
                    kind: "Physical"
                },
                spec: {
                    config: {
                        Physical: {
                            booking_id: this.bookingID,
                            coordinates: this.latitude && this.longitude ? {
                                x: this.latitude,
                                y: this.longitude,
                            } : null,
                            name: this.pgName,
                            typeform: this.typeform ? this.typeform : null,
                            customer_strategy_params: this.customerStrategyParams ? this.customerStrategyParams : null,
                            currency_id: this.selectedCurrency ? this.selectedCurrency : null,
                            extra_params: this.extraParams ? JSON.parse(this.extraParams) : null,
                            homepage_url: this.homepageURL ? this.homepageURL : null,
                            timezone: this.timezone ? this.timezone : null,
                        }
                    }
                }
            }
            return physicalPS
        },
        pmsPropertyset() {
            let pmsPS = {
                metadata: {
                    id: this.currentPMSPS.metadata.id,
                    name: this.currentPMSPS.metadata.name,
                    kind: "PMS"
                },
                spec: {
                    config: {
                        PMS: {
                            pms_id: this.pmsID ? this.pmsID : null,
                            pms_name: this.selectedPMS ? this.selectedPMS : null,
                            encoded_pms_access_info: JSON.parse(this.selectedPmsEncodedAccessInfo) || null
                        }
                    }
                }
            }
            return pmsPS
        },
        chmPropertyset() {
            let chmPS = {
                metadata: {
                    id: this.currentCHMPS.metadata.id,
                    name: this.currentCHMPS.metadata.name,
                    kind: "CHM"
                },
                spec: {
                    config: {
                        CHM: {
                            channel_manager_id: this.cmID ? this.cmID : null,
                            channel_manager_name: this.selectedCM ? this.selectedCM : null,
                            encoded_channel_manager_access_info: JSON.parse(this.selectedCmEncodedAccessInfo) || null
                        }
                    }
                }
            }
            return chmPS
        },
        possibleStates() {
            return this.$store.state.core.lifecycleStatesList.map(value => {
                return {
                    id: value.metadata.id,
                    label: value.metadata.label,
                    name: value.metadata.name,
                }
            })
        }
    },
    methods: {
        ...mapActions({
            newWarning: "alertSystem/newWarning",
            newError: "alertSystem/newError"
        }),
        async cloneInfoFromPg() {
            if (this.selectedPgToClone == null) {
                return
            }

            let data = await this.$store.dispatch('core/fetchPropertyGroup', { pgId: this.selectedPgToClone.split(" ")[0] })

            this.selectedPMS = ""
            this.pmsID = ""
            this.selectedPmsEncodedAccessInfo = null
            this.selectedPMS = data.spec.pms_name
            this.pmsID = data.spec.pms_id
            this.selectedPmsEncodedAccessInfo = JSON.stringify(data.spec.encoded_pms_access_info)

            this.selectedCM = ""
            this.cmID = ""
            this.selectedCmEncodedAccessInfo = null
            this.selectedCM = data.spec.channel_manager_name
            this.cmID = data.spec.channel_manager_id
            this.selectedCmEncodedAccessInfo = JSON.stringify(data.spec.encoded_channel_manager_access_info)

            this.selectedPgToClone = null
        },

        async bookingIdFromUrl() {
            await this.$store.dispatch("scraper/search", {
                query: {
                    words: this.bookingURL,
                    limit: 1,
                },
                cb: function (res) {
                    this.bookingID = res?.results?.[0]?.booking_id
                }.bind(this)
            })

            if (this.bookingID) {
                return
            }

            this.newWarning({ message: "Booking ID not found on opensearch, trying to scrape it" })

            // make a request to this.bookingURL and extract the booking_id
            const res = await axios.get(this.bookingURL)

            // check that there are no redirects
            if (res.request.res.responseUrl != this.bookingURL) {
                this.newError({ message: "The provided Booking URL caused a redirect, please check it" })
                return
            }

            const parser = new DOMParser()
            const doc = parser.parseFromString(res.data, 'text/html')

            // <link rel="alternate" href="android-app://com.booking/booking/hotel/85378?affiliate_id=375119" />
            // the booking id is 85378
            let bookingId = doc.querySelector('link[rel="alternate"]')
            // use a regex
            bookingId = bookingId?.getAttribute('href').match(/hotel\/(\d+)\?/)
            if (bookingId) {
                this.bookingID = bookingId[1]
            } else {
                this.newError({ message: "The provided Booking URL does not contain a Booking ID, please check it" })
            }
        },

        async addPropertyGroup() {
            let newUrl = fixBookingUrl(this.bookingURL)
            if (newUrl != false) {
                this.bookingURL = newUrl
                await this.bookingIdFromUrl()
            } else {
                this.bookingURL = null
                this.bookingID = null
            }

            this.loading = true

            const res = await this.$store.dispatch('core/addPG', { config: this.finalResult })

            await this.$store.dispatch('core/executeAutomapping', { pgId: res.metadata.id })

            this.loading = false
            this.confirmOverlay = false
            this.resetForm()
        },
        async savePropertyGroupToPs() {
            this.loading = true

            let newUrl = fixBookingUrl(this.bookingURL)
            if (newUrl != false) {
                this.bookingURL = newUrl
                await this.bookingIdFromUrl()
            } else {
                this.bookingURL = null
                this.bookingID = null
            }

            // make copies of the propertysets to update (with the subsequent refresh the edits of the user will be deleted)
            let physicalPsCopy = this.physicalPropertyset
            let pmsPsCopy = this.pmsPropertyset
            let chmPsCopy = this.chmPropertyset
            // refresh the global pg to make sure that we are updating the propertyset
            // currently linked to the pg we are editing (it may have changed in the meantime)
            await this.refreshSelectedGlobalPg()


            physicalPsCopy.metadata.id = this.currentPhysicalPS.metadata.id,
            physicalPsCopy.metadata.name = this.currentPhysicalPS.metadata.name,

            pmsPsCopy.metadata.id = this.currentPMSPS.metadata.id,
            pmsPsCopy.metadata.name = this.currentPMSPS.metadata.name,

            chmPsCopy.metadata.id = this.currentCHMPS.metadata.id,
            chmPsCopy.metadata.name = this.currentCHMPS.metadata.name,

            await this.$store.dispatch('core/updatePS', { config: physicalPsCopy })
            await this.$store.dispatch('core/updatePS', { config: pmsPsCopy })
            await this.$store.dispatch('core/updatePS', { config: chmPsCopy })


            await this.refreshSelectedGlobalPg()

            this.resetForm()

            this.loading = false
            this.confirmOverlay = !this.confirmOverlay
        },
        async savePropertyGroup() {
            let newUrl = fixBookingUrl(this.bookingURL)
            if (newUrl != false) {
                this.bookingURL = newUrl
                await this.bookingIdFromUrl()
            } else {
                this.bookingURL = null
                this.bookingID = null
            }

            this.loading = true

            await this.$store.dispatch('core/updatePG', { config: this.finalResult })
            // refresh data to updates the propertyset
            await this.refreshSelectedGlobalPg()

            this.resetForm()

            this.loading = false
            this.confirmOverlay = !this.confirmOverlay
        },
        resetForm() { // resets the form when the button is pressed

            if (this.mode !== "edit") {
                this.pgName = ""
                this.extraParams = "{}"

                this.selectedPMS = ""
                this.pmsID = null,
                    this.selectedPmsEncodedAccessInfo = null

                this.selectedCM = ""
                this.cmID = null,
                    this.selectedCmEncodedAccessInfo = null

                this.latitude = "",
                this.longitude = "",

                this.homepageURL = "",

                this.bookingURL = "",
                this.bookingID = null,

                this.selectedCurrency = null
            } else {
                this.getUser()
            }
        },
        copyFromPMS() {
            const pmsFields = this.encodedPmsParamsSchema?.map(val => val.fieldname) || []
            const cmFields = this.encodedCmParamsSchema?.map(val => val.fieldname) || []

            const difference = pmsFields.filter(item => !cmFields.includes(item));

            if (!difference.length) {
                this.selectedCmEncodedAccessInfo = this.selectedPmsEncodedAccessInfo
            } else {
                this.newWarning({ message: "Error the pms and cm are not equal in structure, you can't copy them!" })
            }

            if (this.requiredPmsId && this.requiredCmId) {
                this.cmID = this.pmsID
            } else {
                this.newWarning({ message: "Error the pms and cm might not both require ids, you can't copy that!" })
            }
        },
        onPasteCoord(evt, copiedInsideLat) {

            let splittedCoords = evt.clipboardData.getData('Text').split(', ')
            setTimeout(() => {
                if (splittedCoords.length == 2) {
                    this.latitude = splittedCoords[0]
                    this.longitude = splittedCoords[1]
                } else if (splittedCoords.length == 1 && copiedInsideLat) {
                    this.latitude = splittedCoords[0]
                } else if (splittedCoords.length == 1 && !copiedInsideLat) {
                    this.longitude = splittedCoords[0]
                }

            }, 100)

        },
        async getUser() {
            this.pgName = this.pgInfo.metadata.name
            this.timezone = this.pgInfo.spec.timezone
            this.extraParams = JSON.stringify(this.pgInfo.spec.extra_params)

            this.selectedPMS = this.pgInfo.spec.pms_name
            this.pmsID = this.pgInfo.spec.pms_id
            this.selectedPmsEncodedAccessInfo = JSON.stringify(this.pgInfo.spec.encoded_pms_access_info)
            this.pmsAccessInfo = this.pgInfo.spec.pms_access_info

            this.selectedCM = this.pgInfo.spec.channel_manager_name
            this.cmID = this.pgInfo.spec.channel_manager_id
            this.selectedCmEncodedAccessInfo = JSON.stringify(this.pgInfo.spec.encoded_channel_manager_access_info)
            this.cmAccessInfo = this.pgInfo.spec.channel_manager_access_info

            this.latitude = this.pgInfo.spec.coordinates?.x || null
            this.longitude = this.pgInfo.spec.coordinates?.y || null

            this.homepageURL = this.pgInfo.spec.homepage_url

            this.bookingID = this.pgInfo.spec.booking_id
            this.bookingURL = ""
            if (this.bookingID) {
                await this.$store.dispatch("scraper/search", {
                    query: {
                        id: this.bookingID,
                        limit: 1,
                    },
                    cb: function (res) {
                        this.bookingURL = res?.results?.[0]?.booking_url
                    }.bind(this)
                })

                if (!this.bookingURL) {
                    this.newError({ message: "Error getting the Booking URL from the Booking ID, refresh the page or you will lose the Booking URl" })
                }
            }

            this.selectedCurrency = this.pgInfo.spec.currency_id

            this.typeform = this.pgInfo.spec.typeform
            this.customerStrategyParams = this.pgInfo.spec.customer_strategy_params
        },
        validateForms() {
            this.$refs.form.validate()

            if (this.validFormFields) {
                this.confirmOverlay = !this.confirmOverlay
            }
        },
        async calculateTimezone() {
            try {
                const res = await this.api.get("core/v1", `/timezonefromcoordinates/${this.latitude}/${this.longitude}`, {}, {}, {})

                this.timezone = res.result[0]
            } catch (error) {
                this.newError({
                    message: "Error getting the timezone from coordinates"
                })
            }
        },
        async refreshSelectedGlobalPg() {
            await this.$store.dispatch("core/selectGlobalPg", {pgId: this.pgInfo.metadata.id})
        },
    },
    async created() {
        // get current pg only if in edit mode
        if (this.mode === "edit") {
            await this.getUser()
        }
    }
}
</script>
