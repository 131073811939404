<template>
    <v-card class="pa-4 card-ui">
        <v-card-title>Property Group Geocoding</v-card-title>
        <v-card-subtitle>You can set the geocoding for the selected property group</v-card-subtitle>

        <v-form
            ref="form"
            v-model="validForm"
        >
            <v-row class="pa-4">
                <v-col class="col-6">
                    <h4 class="pa-2">Link</h4>
                    <v-row>
                        <v-col class="col-12">
                            <v-autocomplete
                                dense
                                outlined
                                :rules="[rules.requiredNormal]"
                                :items="poiItemOptions"
                                v-model="selectedPoi"
                                label="POI"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-12">
                            <v-autocomplete
                                dense
                                outlined
                                :rules="[rules.requiredNormal]"
                                :items="categoryItemOptions"
                                v-model="selectedCategory"
                                label="Category"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-12">
                            <v-autocomplete
                                dense
                                outlined
                                :rules="[rules.requiredForStars]"
                                :items="starsItemOptions"
                                v-model="selectedStars"
                                label="Stars"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-12">
                            <v-combobox
                                dense
                                outlined
                                multiple
                                chips
                                deletable-chips
                                persistent-hint
                                :rules="[rules.requiredNormal, rules.noSpaces, rules.noSpecialCharacters]"
                                :hint="isNewMarket"
                                :items="marketsListParsed"
                                v-model="selectedMarkets"
                                label="Market"
                            ></v-combobox>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="col-6">
                    <h4 class="pa-2">Geocoding</h4>
                    <v-row>
                        <v-col class="col-12">
                            <v-autocomplete
                                dense
                                outlined
                                :rules="[rules.requiredNormal]"
                                :items="countryItemOptions"
                                v-model="selectedCountry"
                                @change="[fetchStateOptions($event), selectedState = null, selectedCounty = null, selectedCity = null]"
                                label="Country"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-12">
                            <v-autocomplete
                                dense
                                outlined
                                :disabled="!selectedCountry"
                                :items="stateItemOptions"
                                :rules="[rules.requiredNormal]"
                                v-model="selectedState"
                                @change="[fetchCountyOptions(selectedCountry, $event), selectedCounty = null, selectedCity = null]"
                                label="State"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-12">
                            <v-autocomplete
                                dense
                                outlined
                                :disabled="!selectedState"
                                :items="countyItemOptions"
                                :rules="[rules.requiredNormal]"
                                v-model="selectedCounty"
                                @change="[fetchCityOptions(selectedCountry, selectedState, $event), selectedCity = null]"
                                label="County"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-12">
                            <v-autocomplete
                                dense
                                outlined
                                :disabled="!selectedCounty"
                                :rules="[rules.requiredNormal]"
                                :items="cityItemOptions"
                                v-model="selectedCity"
                                label="City"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-card-actions class="pa-4">
            <v-btn 
                color="primary" 
                @click="savePg"
            >Save</v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    props: ["value"],
    data() {
        return {
            poiItems: [],
            categoryItems: [],
            starsItems: [],
            stateItems: [],
            countyItems: [],
            cityItems: [],

            // selected link and geocoding options
            selectedPoi: null,
            selectedCategory: null,
            selectedStars: null,
            selectedMarkets: null,
            selectedCountry: null,
            selectedState: null,
            selectedCounty: null,
            selectedCity: null,

            valueCopy: null,

            loadingUser: false,
            validForm: true,

            rules: {
                requiredForStars: val => val !== null || val !== undefined || "Value Required",
                requiredNormal: val => !!val || "Value Required",
                noSpecialCharacters: val => {
                    if (val instanceof Array && val?.length > 0) {
                        return !val.some(word => (/[^a-zA-Z0-9\s_]/.test(word))) || "No special characters allowed!"
                    }
                    return !(/[^a-zA-Z0-9\s_]/.test(val)) || "No special characters allowed!"
                },
                noSpaces: val => {
                    if (val instanceof Array && val?.length > 0) {
                        return !val.some(word => word.includes(" ")) || "No Spaces"
                    }
                    return !String(val).includes(" ") || "No Spaces"
                },
            }
        }
    },
    computed: {
        //dropdown options calculated based on the property group provided
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
            marketsList: "graph/markets",
        }),
        poiItemOptions() {
            return this.poiItems.map(val => val?.metadata?.id)
        },
        categoryItemOptions() {
            return this.categoryItems.map(val => val?.metadata?.id)
        },
        starsItemOptions() {
            return this.starsItems.map(val => val?.metadata?.id)
        },
        countryItemOptions() {
            return this.$store.state.graph.country.map(val => val?.metadata?.id)
        },
        stateItemOptions() {
            return this.stateItems.map(val => val?.spec?.state)
        },
        countyItemOptions() {
            return this.countyItems.map(val => val?.spec?.county)
        },
        cityItemOptions() {
            return this.cityItems.map(val => val?.spec?.city)
        },
        marketsListParsed() {
            return this.marketsList.map(val => val.metadata.id)
        },
        isNewMarket() {
            const alreadyCreatedMarkets = this.marketsList?.map(val => val.metadata.id)

            return this.selectedMarkets?.some(selected => !alreadyCreatedMarkets.includes(selected)) ? "There is a new market! BE AWARE OF THAT!" : ""
        }
    },
    watch: {
        value: {
            handler: async function(newVal, oldVal) {
                this.valueCopy = structuredClone(newVal)
                await this.generalReset()
                if (!!this.valueCopy && !this.loadingUser) {
                    await this.loadUser()
                }
            },
            deep: true,
        }
    },
    methods: {
        async fetchLinkOptions() {
            const api = this.$store.state.api.api

            try {
                const starsReq = api.get('graph/vpg', '/stars', {}, {}, {})
                const poiReq = api.get('graph/vpg', '/poi', {}, {}, {})
                const catReq = api.get('graph/vpg', '/category', {}, {}, {})

                const [starsRes, poiRes, catRes] = await Promise.all([starsReq, poiReq, catReq])
                this.starsItems = starsRes.items
                this.poiItems = poiRes.items
                this.categoryItems = catRes.items
            } catch (error) {
                this.$store.dispatch("alertSystem/newError", {
                    message: `Error fetching stars, categories and point of interest: ${error}`
                })
            }
        },
        async fetchStateOptions(country) { // depend on the country selected
            const api = this.$store.state.api.api

            try {
                const res = await api.get("geocoding/v1", `/${country}`, {}, {}, {})
                this.countyItems = []
                this.cityItems = []
                this.stateItems = !!res.items?.length ? res.items : [{metadata: {id: `${country}_`}, spec: {country: `${country}`, state: `*`, county: "*", city: "*"} }]

            } catch (error) {
                this.$store.dispatch("alertSystem/newError", {
                    message: `Error fetching states list: ${error}`
                })
            }
        },
        async fetchCountyOptions(country, state) { // depened on the country and state selected
            const api = this.$store.state.api.api

            try {
                const res = await api.get("geocoding/v1", `/${country}/${state}`, {}, {}, {})
                this.cityItems = []
                this.countyItems = !!res.items?.length ? res.items : [{metadata: {county: "*"}}]
            } catch (error) {
                this.$store.dispatch("alertSystem/newError", {
                    message: `Error fetching counties list: ${error}`
                })
            }
        },
        async fetchCityOptions(country, state, county) { // depend on the country, state and county selected
            const api = this.$store.state.api.api

            try {
                const res = await api.get("geocoding/v1", `/${country}/${state}/${county}`, {}, {}, {})
                this.cityItems = res.items
            } catch (error) {
                this.$store.dispatch("alertSystem/newError", {
                    message: `Error fetching cities list: ${error}`
                })
            }
        },

        async savePg () {
            try {
                this.$refs.form.validate()
                if (!this.validForm) {
                    throw "Form Invalid"
                }

                let cityID = null
                this.cityItems.some((c) => {
                    if (c.metadata.city == this.selectedCity) {
                        cityID = c.metadata.id
                        return true
                    }
                })

                let copyToSave = structuredClone(this.valueCopy)

                if (!copyToSave) {
                    copyToSave = {}
                    copyToSave.kind = "Propertygroup"
                    copyToSave.link = {}
                    copyToSave.metadata = {
                        id : this.$store.state.core.currentPG.metadata.id
                    }
                    copyToSave.spec = {
                        label : this.$store.state.core.currentPG.metadata.id + '_' +  this.$store.state.core.currentPG.metadata.name.split(' ').join('_')
                    }
                    copyToSave.link.Root = []
                    copyToSave.link.EventSource = []
                }
                if (copyToSave.link !== undefined && copyToSave.link !== null) {
                    delete copyToSave.link.HolidaySource
                    delete copyToSave.link.HolidayTarget
                    delete copyToSave.link.HolidayReferenceMarket
                }

                let poiAlreadyPresent = false
                let categoryAlreadyPresent = false
                let starsAlreadyPresent = false
                
                Object.keys(copyToSave.link).forEach((linkKind) => {
                    const linkForKind = copyToSave.link[linkKind]
                    for (var i = 0; i < linkForKind.length; i += 1) {
                        const link = linkForKind[i]
                        if (link.kind == 'Poi') {
                        poiAlreadyPresent=true
                        copyToSave.link[linkKind][i].id = this.selectedPoi
                        }
                        if (link.kind == 'Category') {
                        categoryAlreadyPresent=true
                        copyToSave.link[linkKind][i].id = this.selectedCategory
                        }
                        if (link.kind == 'Stars') {
                        starsAlreadyPresent=true
                        copyToSave.link[linkKind][i].id = this.selectedStars
                        }
                    }
                })

                if (!copyToSave?.link?.Root) {
                    copyToSave.link.Root = []
                }

                if ( !poiAlreadyPresent ) {
                    copyToSave.link.Root.push({
                        id: this.selectedPoi,
                        kind: "Poi"
                    })
                }
                if ( !categoryAlreadyPresent ) {
                    copyToSave.link.Root.push({
                        id: this.selectedCategory,
                        kind: "Category"
                    })
                }
                if ( !starsAlreadyPresent ) {
                    copyToSave.link.Root.push({
                        id: this.selectedStars,
                        kind: "Stars"
                    })
                }
                
                // Geocoding
                await this.updateGeonode('country', this.selectedCountry)
                await this.updateGeonode('state', [this.selectedCountry, this.selectedState].join("_").replaceAll("*", ""))
                await this.updateGeonode('county', [this.selectedCountry, this.selectedState, this.selectedCounty].join("_").replaceAll("*", ""))
                await this.updateGeonode('city', [this.selectedCountry, this.selectedState, this.selectedCounty, this.selectedCity].join("_").replaceAll("*", ""))
                
                if (copyToSave.link.Root == undefined) {
                    copyToSave.link.Root = []
                }

                // searching for Root link and updating/inserting it
                let foundRoot = false
                copyToSave.link.Root.forEach((l) => {
                    if (l.kind == 'Location:City') {
                        l.id = [this.selectedCountry, this.selectedState, this.selectedCounty, this.selectedCity].join("_").replaceAll("*", ""),
                        foundRoot = true
                    }
                })
                if (foundRoot == false) {
                    copyToSave.link.Root.push({
                        kind: 'Location:City',
                        id: [this.selectedCountry, this.selectedState, this.selectedCounty, this.selectedCity].join("_").replaceAll("*", ""),
                        value: 0
                    })
                }

                let res = await this.$store.dispatch('graph/saveResource', {
                    body: copyToSave
                })

                if (!!res.error) {
                    throw res.error
                }

                /*
                if the pg was not already on neo4j
                we must write the Propertytype and the Properties
                */

                //write Propertytype

                let propertyType = await this.$store.dispatch('graph/getOne', {
                    kind: `Propertytype`,
                    id: this.$store.state.core.currentPG.metadata.id + "_room"
                })
                
                if ( propertyType?.items?.length === 0 ) {
                    // if the resource doesnt exists, we create it and save it into the DB
                    propertyType = {}
                    propertyType.kind="Propertytype"
                    propertyType.metadata = {
                        id : this.$store.state.core.currentPG.metadata.id + "_room"
                    }
                    propertyType.spec = {
                        label : "room"
                    }
                    propertyType.link = {
                        Root : [{
                        kind: "Propertygroup",
                        id: this.$store.state.core.currentPG.metadata.id
                        }]
                    }
                    res = await this.$store.dispatch('graph/saveResource', {
                        body: propertyType
                    })
                    if (res.error !== null) {
                        throw res.error
                    }
                }

                // check if market is present if not adds it
                await Promise.all(this.selectedMarkets.map(async (market) => {

                    let marketRes = await this.$store.dispatch('graph/getOne', {
                        kind: `Market`,
                        id: market
                    })

                    if (!marketRes?.items?.length) {
                        const newMarket = {
                                kind: "Location:Market",
                                metadata: {
                                    id: market
                                }
                            }
                        res = await this.$store.dispatch('graph/saveResource', {
                            body: newMarket
                        })

                        if (res.error !== null) {
                            throw res.error
                        }

                        this.$store.commit("graph/addLocation", {newLoc: newMarket})
                    }

                }))

                // check if properties exist if not adds them
                const neo4jProp = await this.getPgProperties()
                const neo4jPropIds = neo4jProp.map(prop => prop.metadata.id) 
                const currentProperties = this.$store.state.core.currentProperties

                const updateRequestsToMake = []
                for (let prop of currentProperties) {

                    // check if property already exists on neo4j or not
                    const exists = neo4jPropIds.includes(prop.metadata.id)

                    if (exists) { // prop exist
                        const neo4jProperty = structuredClone(neo4jProp.find(val => val.metadata.id === prop.metadata.id))

                        neo4jProperty.link.Root = neo4jProperty.link.Root.filter(val => val.kind !== "Location:Market")
                        this.selectedMarkets.forEach(mkt => {
                            neo4jProperty.link.Root.push({
                                kind: "Location:Market",
                                id: mkt,
                                value: null,
                            })
                        })

                        updateRequestsToMake.push(this.$store.dispatch('graph/saveResource', {
                            body: neo4jProperty
                        }))
                       
                    } else { // prop doesn't exist
                       
                        updateRequestsToMake.push(this.$store.dispatch('graph/saveResource', {
                            body: {            
                                kind: 'Property',
                                metadata: {
                                    id: prop.metadata.id
                                },
                                spec: {
                                    label: prop.metadata.name
                                },
                                link: {
                                    Root: [{
                                        kind: "Propertytype",
                                        // LEAVE AS PROPERTYGROUP to avoid duplicates
                                        id: this.$store.state.core.currentPG.metadata.id+"_room",
                                        value: null
                                    },
                                    ...this.selectedMarkets.map(mkt => {
                                        return {
                                            kind: "Location:Market",
                                            id: mkt,
                                            value: null,
                                        }
                                    })
                                    ]
                                }
                            }
                        }))  
                    }

                }

                const responses = await Promise.all(updateRequestsToMake)
                responses.forEach(res => {
                    if (res.error !== null) {
                        throw res.error
                    }  
                })

                this.valueCopy = copyToSave
                this.$emit("input", this.valueCopy)

                this.$store.dispatch('alertSystem/newSuccess', {
                    title: `Property Group Graph Data Saved`,
                    message: `Data correctly saved for property group ${this.$store.state.core.currentPG.metadata.id}`,
                    timeout: 5000
                });
            } catch (error){
                this.$store.dispatch('alertSystem/newError', {
                    title: "Error while saving",
                    message: error
                });
            }
        },
        async getPgMarket () {
            const api = this.$store.state.api.api

            try {
                const pgId = this.$store.state.core.currentPG.metadata.id
                const res = await api.get("graph/vpg", `/markets/propertygroup/${pgId}`, {}, {}, {})

                return res.items
            } catch (error) {
                return null
            }
        },

        async getPgProperties() {
            const api = this.$store.state.api.api

            try {
                const pgId = this.$store.state.core.currentPG.metadata.id
                const res = await api.get("graph/vpg", `/properties/propertygroup/${pgId}`, {}, {}, {})

                return res.items
            } catch (error) {
                return null
            }
        },
        async updateGeonode(kind, id) {
            let apiKind2Neo4Jkind = {
                'country':'Location:Country',
                'state':'Location:State',
                'county':'Location:County',
                'city':'Location:City',
            }

            if ( apiKind2Neo4Jkind[kind] == undefined ) {
                console.log(`${kind} not recognised`)
                return false
            }      

            let geocodingResource = await this.$store.dispatch('graph/getOne', {
                kind: `${kind}`,
                id: id
            })
            
            if ( geocodingResource?.items?.length === 0 ) {
                // if the resource doesnt exists, we create it and save it into the DB
                geocodingResource = {            
                    kind: apiKind2Neo4Jkind[kind],
                    metadata: {
                        id: id
                    },
                }

                if ( kind =='state'  ) {
                    geocodingResource.spec = {}
                    geocodingResource.spec.label = this.selectedState
                    geocodingResource.link = {
                        Root: [{
                        kind:'Location:Country',
                        id: this.selectedCountry
                        }]
                    }
                } else if ( kind == 'county' ) {
                    geocodingResource.spec = {}
                    geocodingResource.spec.label = this.selectedCounty
                    geocodingResource.link = {
                        Root: [ {
                        kind:'Location:State',
                        id: `${this.selectedCountry}_${this.selectedState}`
                        }]
                    }
                } else if ( kind == 'city' ) {
                    geocodingResource.spec = {}
                    geocodingResource.spec.label = this.selectedCity
                    geocodingResource.link = {
                        Root: [ {
                        kind:'Location:County',
                        id: `${this.selectedCountry}_${this.selectedState}_${this.selectedCounty}`
                        }]
                    }
                }
                
                let res = await this.$store.dispatch('graph/saveResource', {
                    body: geocodingResource
                })

                if (kind == "city") {
                    this.$store.commit("graph/addLocation", {newLoc: geocodingResource})
                }

                if (res.error !== null) {
                    throw res.error
                }        
            } else {
                // if it exists, we don't have to insert it
                // we save it into to country variable that is used when constructing the State object
                // TODO: what should happen when there is not a link to the country? same thing for county and city

                geocodingResource = geocodingResource.items[0]
            }
            return geocodingResource
        },

        // load user
        async loadUser() {
            this.loadingUser = true
            const rootLinks = this.valueCopy?.link?.Root

            const hashMap = {}
            rootLinks?.forEach(val => {
                hashMap[val.kind] = val    
            })

            const location = hashMap["Location:City"]?.id?.split("_")
            if (!!location) {
                await Promise.all([
                    await this.fetchStateOptions(location[0]),
                    await this.fetchCountyOptions(location[0], location[1] || "*"),
                    await this.fetchCityOptions(location[0], location[1] || "*", location[2] || "*"),
                ])
                
                this.selectedCountry = location[0]
                this.selectedState = location[1] || "*"
                this.selectedCounty = location[2] || "*"
                this.selectedCity = location[3]
            }
            
            const markets = await this.getPgMarket()

            this.selectedMarkets = markets?.map(mkt => mkt.metadata.id) || null
            this.selectedPoi = hashMap["Poi"]?.id
            this.selectedCategory = hashMap["Category"]?.id
            this.selectedStars = hashMap["Stars"]?.id
            this.loadingUser = false
        },
        async generalReset() {
            this.selectedPoi = null
            this.selectedCategory = null
            this.selectedStars = null
            this.selectedMarkets = null
            this.selectedCountry = null
            this.selectedState = null
            this.selectedCounty = null
            this.selectedCity = null
        },
    },
    async created() {
        this.valueCopy = structuredClone(this.value)
        await this.fetchLinkOptions()
        await this.generalReset()
        if (!!this.valueCopy && !this.loadingUser) {
            await this.loadUser()
        }
    }
}
</script>