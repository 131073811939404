<template>
    <v-card 
        class="card-ui"
    >
        <v-card-text >
            <v-row>
                <v-col class="col-9">
                    <v-row>
                        <v-col class="col-6">
                            <v-autocomplete
                                outlined
                                dense
                                label="Modifier Type"
                                :items="modifierTypesList"
                                :disabled="!selectedModIsActive"
                                v-model="selectedModType"
                            >
                                <template v-slot:item="data">
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-list-item-content 
                                                v-text="data.item.text"
                                                v-bind="attrs"
                                                v-on="on"    
                                            ></v-list-item-content>
                                        </template>
                                        <span>{{ data.item.description || "" }}</span>
                                    </v-tooltip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col class="col-6">
                            <v-menu
                                v-model="dateMenu1"
                                v-if="selectedModType !== 'extend'"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        label="To Date Range"
                                        :value="`${selectedModDateRange[0]} ÷ ${selectedModDateRange[1]}`"
                                        readonly
                                        :error="!selectedModDateRange[0]"
                                        :error-messages="!selectedModDateRange[0] ? 'Required' : ''"
                                        v-bind="attrs"
                                        :disabled="!selectedModIsActive ? selectedModValueDateRange[0] === undefined : false"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    reactive
                                    :first-day-of-week="1"
                                    :value="selectedModDateRange"
                                    v-model="dateRangeTemp1"
                                    @change="selectedModDateRange = dateRangeTemp1"
                                    :allowed-dates="allowedDatesValues"
                                    range
                                >
                                    <div class="date-buttons">
                                        <div class="date-buttons-col">
                                            <v-btn
                                                block
                                                @click="resetStartDate()"
                                            >Reset Start</v-btn>
                                            <v-btn
                                                block
                                                @click="resetEndDate()"
                                            >Reset End</v-btn>
                                            <v-btn
                                                block
                                                color="red"
                                                @click="selectWholeDateRange"
                                            >Whole Date Range</v-btn>
                                        </div>
                                    </div>
                                </v-date-picker>
                            </v-menu>

                            <v-menu
                                v-model="dateMenu3"
                                v-else
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        label="To Date"
                                        :value="selectedDateTo"
                                        readonly
                                        v-bind="attrs"
                                        :error="!selectedDateTo"
                                        :error-messages="!selectedDateTo ? 'Required' : ''"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    reactive
                                    :first-day-of-week="1"
                                    :value="selectedDateTo"
                                    @change="selectedDateTo = $event"
                                    :allowed-dates="allowedDatesValues"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-6" v-if="selectedModType !== 'extend'">
                            <v-autocomplete
                                outlined
                                dense
                                label="Properties Affected"
                                multiple
                                :disabled="!selectedModIsActive"
                                :items="currentPropertiesFiltered"
                                v-model="selectedModProperties"
                                chips
                                small-chips
                            >
                                <!-- <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index < 2" small :disabled="!selectedModIsActive">
                                        <span>{{ item.value }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 2"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ selectedModProperties.length - 2 }} others)
                                    </span>
                                </template> -->
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @mousedown.prevent
                                        @click="selectAllProperties"
                                    >
                                        <v-list-item-action>
                                            <v-icon dense :color="selectedModProperties.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icon }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                            Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col class="col-6" v-if="!['extend', 'overwrite'].includes(selectedModType)">
                            <v-autocomplete
                                v-if="selectedModType === 'copy'"
                                outlined
                                dense
                                label="Property to copy"
                                :disabled="!selectedModIsActive"
                                :items="currentPropertiesFilteredForCopy"
                                v-model="selectedModValueProperty"
                            ></v-autocomplete>
                            <v-menu
                                v-model="dateMenu2"
                                v-else-if="selectedModType === 'duplicate'"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        label="From Date Range"
                                        :value="`${selectedModValueDateRange[0]} ÷ ${selectedModValueDateRange[1]}`"
                                        readonly
                                        v-bind="attrs"
                                        :disabled="!selectedModIsActive"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    :value="selectedModValueDateRange"
                                    :first-day-of-week="1"
                                    v-model="dateRangeTemp2"
                                    @change="[selectedModValueDateRange = dateRangeTemp2, selectedModDateRange = [], dateRangeTemp1 = []]"
                                    range
                                >
                                    <v-btn
                                        color="red"
                                        @click="selectWholeDateRange"
                                    >Whole Date Range</v-btn>
                                </v-date-picker>
                            </v-menu>
                            <v-text-field
                                v-else
                                outlined
                                dense
                                label="Value"
                                :disabled="!selectedModIsActive"
                                :suffix="modifierSuffix"
                                :rules="[rules.isNumber]"
                                v-model="selectedModValue"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="col-3">
                    <v-row>
                        <v-col class="col-4">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="ma-2"
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="moveModifier({startIndex: index, endIndex: index - 1})"
                                        :disabled="index === 0"
                                    >
                                        <v-icon>fas fa-chevron-up</v-icon>
                                    </v-btn>
                                </template>
                                <span>Move Up</span>
                            </v-tooltip>
                        </v-col>
                        <v-col class="col-4">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="ma-2"
                                        text
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="deleteModifier({index: index})"
                                    >
                                        <v-icon>fas fa-trash-alt</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </v-col>
                        <v-col class="col-4 d-flex align-center justify-center">
                            {{ index }}
                        </v-col>
                        <v-col class="col-4">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="ma-2"
                                        text
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="moveModifier({startIndex: index, endIndex: index + 1})"
                                        :disabled="appliedModifiers.length-1 === index"
                                    >
                                        <v-icon>fas fa-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <span>Move Down</span>
                            </v-tooltip>
                        </v-col>
                        <v-col class="col-4">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="ma-2"
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        @click="copyModifier({index: index, user: username})"
                                    >
                                        <v-icon>far fa-copy</v-icon>
                                    </v-btn>
                                </template>
                                <span>Copy</span>
                            </v-tooltip>
                        </v-col>
                        <v-col class="col-4">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-switch
                                            class="mt-2"
                                            v-model="selectedModIsActive"
                                        ></v-switch>
                                    </div>
                                </template>
                                <span>{{ selectedModIsActive ? 'Disable' : 'Enable' }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import dateUtils from "@/utils/dateUtils"

export default {
    props: ["modifier", "index"],
    data() {
        return {
            dateRangeTemp1: [],
            dateMenu1: false,

            dateRangeTemp2: [],
            dateMenu2: false,

            dateMenu3: false,

            modifierTypesList: [
                {text: "Percentual", value: "percentual", description: "Applies a percentual multiplier to the selected date range and properties"}, 
                {text: "Absolute", value: "absolute", description: "Adds or Subtracts a value to the selected date range and properties"}, 
                {text: "Direct", value: "direct", description: "Applies the chosen value to the selected date range and properties"}, 
                {text: "Copy Property", value: "copy", description: "Copies the value of a property into other properties for the selected date range"},
                {text: "Overwrite Period", value: "overwrite", description: "Takes the period of the selected date range for the selected properties and overwrites it with the values of the year before"},
                {text: "Extend", value: "extend", description: "Extends the values taking the ones from the previous year until the selected date"},
            ],
            
            rules: {
                isNumber: value => !isNaN(Number(value)) || "Value must be a number",
            }
        }
    },
    computed: {
        ...mapState({
            appliedModifiers: state => state.basePrice.appliedModifiers,
            username: state => state.auth.username,
            currentProperties: state => state.core.currentProperties,
            allowedProperties: state => state.strategy.currentGeneralSettings.propertyIds,
            currentPS: (state) => state.core.currentStrategyPS,
        }),
        ...mapGetters({
            allowedBasePricePropertiesDropdown: "basePrice/allowedBasePricePropertiesDropdown",
            currentCurrency: "core/currentCurrency",
        }),
        currentPropertiesFiltered () {
            return this.allowedBasePricePropertiesDropdown
            .filter(prop => prop.value !== this.selectedModValueProperty)
        },
        currentPropertiesFilteredForCopy () {
            return this.allowedBasePricePropertiesDropdown
        },
        wholeDateRange() {
            const dates = this.$store.state.basePrice.basePrice.dateValues

            return [new Date().toISOString().split("T")[0], dates[dates.length-1]]
        },
        selectedModType: {
            get () {
                return this.modifier.kind
            },
            set (value) {
                this.updateModifier({
                    index: this.index,
                    key: "kind",
                    value: value,
                })

                // delete properties not needed when changing modifiers
                if (value === "copy") {
                    this.updateModifier({
                        index: this.index,
                        key: "propertyIds",
                        value: this.modifier.propertyIds !== undefined ? this.modifier.propertyIds : [],
                    })
                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "value"
                    })
                } else if (value === "extend") {
                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "value"
                    })
                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "fromPropertyId"
                    })
                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "startDate"
                    })
                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "endDate"
                    })
                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "propertyIds"
                    })
                } else if (value === "overwrite") {
                    this.dateRangeTemp1 = [this.modifier.startDate || "", this.modifier.endDate || ""]

                    this.updateModifier({
                        index: this.index,
                        key: "propertyIds",
                        value: this.modifier.propertyIds !== undefined ? this.modifier.propertyIds : [],
                    })
                    this.updateModifier({
                        index: this.index,
                        key: "startDate",
                        value: this.modifier.startDate || "",
                    })
                    this.updateModifier({
                        index: this.index,
                        key: "endDate",
                        value: this.modifier.endDate || "",
                    })
                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "value"
                    })
                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "fromPropertyId"
                    })
                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "toDate",
                    })
                } else {
                    this.dateRangeTemp1 = [this.modifier.startDate || "", this.modifier.endDate || ""]

                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "fromPropertyId"
                    })
                    this.deletePropertyFromModifier({
                        index: this.index,
                        key: "toDate"
                    })
                    this.updateModifier({
                        index: this.index,
                        key: "propertyIds",
                        value: this.modifier.propertyIds !== undefined ? this.modifier.propertyIds : [],
                    })
                    this.updateModifier({
                        index: this.index,
                        key: "value",
                        value: this.modifier.value || 1,
                    })
                    this.updateModifier({
                        index: this.index,
                        key: "startDate",
                        value: this.modifier.startDate || "",
                    })
                    this.updateModifier({
                        index: this.index,
                        key: "endDate",
                        value: this.modifier.endDate || "",
                    })
                }
            }
        },
        selectedModDateRange: {
            get() {
                return [this.modifier.startDate, this.modifier.endDate]
            },
            set(value){
                this.dateMenu1 = false
                this.updateModifier({
                    index: this.index,
                    key: "startDate",
                    value: value[0] < value[1] ? value[0] : value[1],
                })
                this.updateModifier({
                    index: this.index,
                    key: "endDate",
                    value: value[0] < value[1] ? value[1] : value[0],
                })
            }
        },
        selectedDateTo: {
            get() {
                return this.modifier.toDate
            },
            set(value) {
                this.dateMenu3 = false
                this.updateModifier({
                    index: this.index,
                    key: "toDate",
                    value: value,
                })
            }
        },
        selectedModProperties: {
            get() {
                return this.modifier.propertyIds
            },
            set(value) {
                this.updateModifier({
                    index: this.index,
                    key: "propertyIds",
                    value: value,
                })
            },
        },
        selectedModValue: {
            get() {
                return this.modifier.value
            },
            set(value) {
                this.updateModifier({
                    index: this.index,
                    key: "value",
                    value: value,
                })
            }
        },
        selectedModValueProperty: {
            get() {
                return this.modifier.fromPropertyId
            },
            set(value) {
                this.updateModifier({
                    index: this.index,
                    key: "fromPropertyId",
                    value: value,
                })
            },
        },
        selectedModValueDateRange: {
            get() {
                return [this.modifier.fromStartDate, this.modifier.fromEndDate]
            },
            set(value){
                this.dateMenu2 = false
                this.updateModifier({
                    index: this.index,
                    key: "fromStartDate",
                    value: value[0] < value[1] ? value[0] : value[1],
                })
                this.updateModifier({
                    index: this.index,
                    key: "fromEndDate",
                    value: value[0] < value[1] ? value[1] : value[0],
                })
            }
        },
        selectedModIsActive: {
            get() {
                return this.modifier.isActive
            },
            set(value) {
                this.updateModifier({
                    index: this.index,
                    key: "isActive",
                    value: value,
                })
            }
        },
        modifierSuffix() {
            switch (this.selectedModType) {
                case 'percentual': 
                    return `= ${this.selectedModValue < 1 ? "" : "+"}${Number((this.selectedModValue-1)*100).toFixed(0)}%`
                case 'absolute':
                    return `= ${this.selectedModValue < 0 ? "" : "+"}${Number(this.selectedModValue)} ${this.currentCurrency?.spec?.code}`
                case 'direct':
                    return `= ${Number(this.selectedModValue)} ${this.currentCurrency?.spec?.code}`
            }
        },
        icon () {
            if (this.selectedModProperties.length === this.currentPropertiesFiltered.length) return 'fa-light fa-times-square'
            if (this.selectedModProperties.length !== this.currentPropertiesFiltered.length && this.selectedModProperties.length > 0) return 'fa-regular fa-minus-square'
            return 'fa-regular fa-square-full'
        },
    },
    watch: {
        modifier(newVal, oldVal) {
            this.dateRangeTemp1 = [newVal.startDate || "", newVal.endDate || ""]
        }
    },
    methods: {
        ...mapMutations({
            deletePropertyFromModifier: "basePrice/deletePropertyFromModifier",
            updateModifier: "basePrice/updateModifier",
            deleteModifier: "basePrice/deleteModifier",
            moveModifier: "basePrice/moveModifier",
            copyModifier: "basePrice/copyModifier",
        }),
        selectWholeDateRange(){
            this.dateRangeTemp1 = this.wholeDateRange
            this.selectedModDateRange = this.wholeDateRange
        },
        allowedDatesValues(date) {
            if (this.selectedModType === 'duplicate') {
                let diff = dateUtils.daysDifference(this.selectedModValueDateRange[0], this.selectedModValueDateRange[1], 1)
                const notAllowed = dateUtils.toIsoDateOnly(dateUtils.addDaysToDate(this.selectedModValueDateRange[0], -diff))
    
                return  date < notAllowed || date > this.selectedModValueDateRange[1]
            } else {
                return date >= dateUtils.toIsoDateOnly(new Date())
            }
        },
        selectAllProperties() {
            this.$nextTick(() => {
                if (this.selectedModProperties.length === this.currentPropertiesFiltered.length) {
                this.selectedModProperties = []
                } else {
                this.selectedModProperties = this.currentPropertiesFiltered.map(prop => prop.value)
                }
            })
        },
        resetStartDate() {
            this.selectedModDateRange[0] > this.selectedModDateRange[1] ? this.selectedModDateRange.splice(1, 1) : this.selectedModDateRange.splice(0, 1)
            this.dateRangeTemp1[0] > this.dateRangeTemp1[1] ? this.dateRangeTemp1.splice(1, 1) : this.dateRangeTemp1.splice(0, 1)
        },
        resetEndDate() {
            this.selectedModDateRange[0] < this.selectedModDateRange[1] ? this.selectedModDateRange.splice(1, 1) : this.selectedModDateRange.splice(0, 1)
            this.dateRangeTemp1[0] < this.dateRangeTemp1[1] ? this.dateRangeTemp1.splice(1, 1) : this.dateRangeTemp1.splice(0, 1)
        }
    },
    created() {
        this.dateRangeTemp1 = this.selectedModDateRange
        this.dateRangeTemp2 = this.selectedModValueDateRange
    }
}
</script>

<style scoped>
.date-buttons, .date-buttons-col  {
    gap: 1rem;
    width: 100%;
}

.date-buttons {
    display: flex;
}

.date-buttons-col{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>