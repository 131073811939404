<template>
  <perfect-scrollbar>
  <div class="home">
    <v-card class="px-8 py-4" style="height:100%;" v-if="!errorStatus">
      <v-card-title>Property Tag Mappings</v-card-title>
      <v-row justify="start" align="center">
        <v-col class="col-4">
          <h4>Room</h4>
        </v-col>
         <v-col class="col-6">
          <h4>Tag</h4>
        </v-col>
      </v-row>
      <v-row v-for="(propMapping, index) in propertyRoomMappings" justify="start" align="center" :key="index">
        <v-col class="col-4">
          <v-label>{{ propMapping.prettyName }}</v-label>
        </v-col>
         <v-col class="col-6">
            <div>
              <v-combobox class="pt-2"
              v-model="propMapping.tag"
              :items="allUniqueTags"
              label="Tag" 
              hint="Select or type new"
              outlined
              dense
              @change="changeYamlEditor()"
              :readonly="!editable"
            ></v-combobox>
          </div>
        </v-col>
      </v-row> 
    </v-card>

    <!-- Error status -->
    <v-card class="pa-8" style="height: 100%;" v-else> 
      <v-card-title class="red--text">ERROR</v-card-title>
      <v-card-text class="red--text">
        <p v-for="message of errorMessages">{{ message }}</p>
      </v-card-text>
    </v-card>
  </div>
</perfect-scrollbar>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name:"PredictionProperties",
  props: ['editable'],
  data () {
    return {
      propertyRoomMappings: [],

      errorMessages: [],
    }
  },

  computed: {
    ...mapState({
      propertyTags: state => state.core.propertyTagsList,
    }),
    ...mapGetters({
      allowedProperties: "pipelineV1/allowedProperties",
      codeToRenderObj: "editor/codeToRenderObj",
    }),
    allowedPropertiesById(){
      const hashMap = {}
      this.allowedProperties.forEach(prop => {
        hashMap[prop.metadata.id] = prop
      })

      return hashMap
    },
    allowedPropertiesIds() {
      return Object.keys(this.allowedPropertiesById)
    },
    propertyRoomMappingsIds() {
      return this.propertyRoomMappings.map(propMap => propMap.id)
    },
    errorStatus() {
      return !!this.errorMessages.length
    },
    editorTagsList() {
      if (this.codeToRenderObj.global?.room_tag_map) {
        return Object.keys(this.codeToRenderObj.global?.room_tag_map) || []
      } else {
        return []
      }
    },
    allUniqueTags() {
      const propertyDefaultTags = structuredClone(this.propertyTags)
      const editorKeys = structuredClone(this.editorTagsList)

      return Array.from(new Set(propertyDefaultTags.concat(editorKeys)))      
    },
    finalPropertyTagMappings() {

      if (Array.from(new Set(this.propertyRoomMappings.map(propMap => propMap.tag))).length === 1) { // caso all
        return {
          [this.propertyRoomMappings[0].tag]: "all",
        }
      } else {
        const finalMap = {}

        // initializes the array
        this.propertyRoomMappings.forEach(propMapping => finalMap[propMapping.tag] = [])

        // populates the array
        this.propertyRoomMappings.forEach(propMapping => {
          finalMap[propMapping.tag].push(Number(propMapping.id))
        })

        return finalMap
      }
    }
  },

  methods: {
    ...mapMutations({
      writeCodeToRender: "editor/writeCodeToRender",
    }),
    initializeComponent() {
      if (this.codeToRenderObj.global?.room_tag_map) { // case where the property mappigns are present inside yaml
        for (const [tag, propArray] of Object.entries(this.codeToRenderObj.global?.room_tag_map)) {
          if (propArray === "all") { // in the case of tag === all
            this.allowedProperties.forEach(prop => {
              this.propertyRoomMappings.push({
                id: prop.metadata.id,
                name: prop.metadata.name,
                prettyName: `${prop.metadata.id} - ${prop.metadata.name}`,
                tag: tag,
              })
            })
          } else if (typeof propArray === "number") {
            const prop = this.allowedPropertiesById[propArray]

            this.propertyRoomMappings.push({
              id: prop.metadata.id,
              name: prop.metadata.name,
              prettyName: `${prop.metadata.id} - ${prop.metadata.name}`,
              tag: tag,
            })
          } else { // case where there are different tags for different properties
            propArray.forEach(propId => {
              const prop = this.allowedPropertiesById[propId]

              this.propertyRoomMappings.push({
                id: prop.metadata.id,
                name: prop.metadata.name,
                prettyName: `${prop.metadata.id} - ${prop.metadata.name}`,
                tag: tag,
              })
            })
          }
        }
        // case where not all property ids are present inside yaml so populate the missing ones with default room tag
        this.allowedProperties.forEach(prop => {
          if (!this.propertyRoomMappingsIds.includes(prop.metadata.id)) {
            this.propertyRoomMappings.push({
              id: prop.metadata.id,
              name: prop.metadata.name,
              prettyName: `${prop.metadata.id} - ${prop.metadata.name}`,
              tag: this.propertyTags[0],
            })
          }
        })


      } else { // case where property mappings are not present inside yaml
        this.allowedProperties.forEach(prop => {
          this.propertyRoomMappings.push({
            id: prop.metadata.id,
            name: prop.metadata.name,
            prettyName: `${prop.metadata.id} - ${prop.metadata.name}`,
            tag: this.propertyTags[0],
          })
        })
      }
    },
    
    changeYamlEditor() {
      const editorCode = structuredClone(this.codeToRenderObj)

      editorCode.global.room_tag_map = structuredClone(this.finalPropertyTagMappings)

      this.writeCodeToRender(editorCode)
    },

    checkYamlValidity() {

      if (this.codeToRenderObj.global?.room_tag_map) {
        for (const [tag, val] of Object.entries(this.codeToRenderObj.global?.room_tag_map)) {
          if (val === null || val === undefined) {
            this.errorMessages.push(`Tag ${tag} has value of null or undefined, remove it or add properties to it.`)
          }
        }
      }

      // get all the property ids from yaml
      let propIds = []
      if (this.codeToRenderObj.global?.room_tag_map) {
        for (let propArr of Object.values(this.codeToRenderObj.global?.room_tag_map)) {
          if (propArr === "all") {
            this.allowedProperties.forEach(prop => propIds.push(prop.metadata.id))
            break
          } else if (typeof propArr === "number") {
            propIds.push(propArr)
          } else {
            propArr.forEach(prop => propIds.push(prop))
          }
        }
      }

      // filter them and create error messages
      propIds.forEach(prop => {
        if (!this.allowedPropertiesIds.includes(String(prop))) {
          this.errorMessages.push(`The property id ${prop} isn't allowed, remove it from editor or add it inside general settings.`)
        } 
      })
    }
  },

  created() {
    this.checkYamlValidity()
    if (!this.errorStatus) {
      this.initializeComponent()
      this.changeYamlEditor()
    } 
  }
}
</script>