"use strict";

module.exports.API_ENDPOINT = () => {
    let currentLocation = window.location.host;
    if (currentLocation == "localhost:8080") {
        if (process.env.VUE_APP_API_ENDPOINT) {
            return process.env.VUE_APP_API_ENDPOINT;
        } else {
            return "http://localhost:3000";
        }
    } else if (currentLocation == "internal-dashboard.smartpricing.it") {
        return "https://api-prod-v2.smartpricing.it";
    } else if (currentLocation == "internal-dashboard-stage.smartpricing.it") {
        return "https://api-stage-v2.smartpricing.it";
    } else if (currentLocation == "internal-dashboard-dev.smartpricing.it") {
        return "https://api-dev-v2.smartpricing.it";
    }
};

module.exports.MEDUSA_ENDPOINT = () => {
    let currentLocation = window.location.host;
    if (currentLocation == "localhost:8080") {
        if (process.env.VUE_APP_MEDUSA_ENDPOINT) {
            return process.env.VUE_APP_MEDUSA_ENDPOINT;
        } else {
            return "http://localhost:9001";
        }
    } else if (currentLocation == "internal-dashboard.smartpricing.it") {
        return "https://admin.smartpaying.it";
    } else if (currentLocation == "internal-dashboard-stage.smartpricing.it") {
        return "https://admin-stage.smartpaying.it";
    } else if (
        currentLocation == "internal-dashboard-stage-v2.smartpricing.it"
    ) {
        return "https://admin-stage-v2.smartpaying.it";
    }
};

module.exports.ROLES = [
    "admin.all",
    "revenuemanager.all",
    "onboarder.all",
    "techsupport.all",
    "retention.all",
    "marketing.all",
];

module.exports.ROLES_MAP = {
    "admin.all": [
        "davide.bulbarelli@smartpricing.it",
        "alice.setti@smartpricing.it",
        "amedeo@smartpricing.it",
        "paolo.eccher@smartpricing.it",
        "andrea@smartpricing.it",
        "emilio@smartpricing.it",
        "eugenio@smartpricing.it",
        "fabrizio.rozzo@smartpricing.it",
        "gabriele@smartpricing.it",
        "martino@smartpricing.it",
        "federico@smartpricing.it",
        "andrea.garganico@smartpricing.it",
        "katia@smartpricing.it",
        "mario@smartpricing.it",
        "giacomo@smartpricing.it",
        "alberto@smartpricing.it",
        "fabrizio@smartpricing.it",
        "alberto.fabro@smartpricing.it",
        "federico.allegrini@smartpricing.it",
        "luciano@smartpricing.it",
        "giovanni.lorenzini@smartpricing.it",
        "alessio@smartpricing.it",
        "raffaele@smartpricing.it",
        "andrea.longo@smartpricing.it",
        "manuel@smartpricing.it",
        "andrea.minato@smartpricing.it",
        "lorenzo@smartpricing.it",
        "francesca.dellabianca@smartpricing.it",
        "federico.pasolli@smartpricing.it",
        "anton@smartpricing.it",
        "emanuele.micheletti@smartpricing.it",
        "mattia.uttini@smartpricing.it",
    ],
    "revenuemanager.all": [
        "simone@smartpricing.it",
        "ignazio@smartpricing.it",
        "sabrina@smartpricing.it",
        "francesca.guarneri@smartpricing.it",
        "simone@smartpricing.it",
        "samantha@smartpricing.it",
        "mattia@smartpricing.it",
        "claudia.marotta@smartpricing.it",
        "federica.barbaro@smartpricing.it",
        "britt@smartpricing.it",
        "marina@smartpricing.it",
        "leonardo@smartpricing.it",
        "lukas@smartpricing.it",
    ],
    "onboarder.all": [
        "damiano@smartpricing.it",
        "paola@smartpricing.it",
        "anastasiya@smartpricing.it",
        "linda@smartpricing.it",
        "giuliana@smartpricing.it",
        "laetitia@smartpricing.it",
        "mariarita@smartpricing.it",
        "federica.tognocchi@smartpricing.it",
        "claudiad@smartpricing.it",
        "ana@smartpricing.it",
        "vivica@smartpricing.it",
        "neera@smartpricing.it",
    ],
    "sales.all": [
        "tommaso@smartpricing.it",
        "oksana@smartpricing.it",
        "julian@smartpricing.it",
        "simon@smartpricing.it",
        "jolanda@smartpricing.it",
        "maximilian@smartpricing.it",
        "evelyn@smartpricing.it",
        "giada.sommariva@smartpricing.it",
        "mariagrazia@smartpricing.it",
        "antonio@smartpricing.it",
        "chiara@smartpricing.it",
        "grazia@smartpricing.it",
        "giovannaraciti@smartpricing.it",
        "sofia@smartpricing.it",
        "fabio@smartpricing.it",
        "manila@smartpricing.it",
        "emanuele@smartpricing.it",
        "davidelitto@smartpricing.it",
        "massimo@smartpricing.it",
        "andrea.brandi@smartpricing.it",
        "melanie@smartpricing.it",
        "felix@smartpricing.it",
        "sabrina.strassberger@smartpricing.it",
        "nicole.lagioia@smartpricing.it",
        "simone.koch@smartpricing.it",
        "beatrice.bogdan@smartpricing.it",
        "catia@smartpricing.it",

        "antonio@smartpricing.it",
        "chiara@smartpricing.it",
        "davidelitto@smartpricing.it",
        "giovannaraciti@smartpricing.it",
        "manila@smartpricing.it",
        "fabio@smartpricing.it",
        "massimo@smartpricing.it",
        "mariagrazia@smartpricing.it",
        "sofia@smartpricing.it",
        "grazia@smartpricing.it",

        "carla@smartpricing.it",
        "davide.finizio@smartpricing.it",
        "martina.maratta@smartpricing.it",

        "marica@smartpricing.it",
        "domenico@smartpricing.it",
        "paolaplantamura@smartpricing.it",
        "federica@smartpricing.it",

        "sabrina.strassberger@smartpricing.it",
        "nicole.lagioia@smartpricing.it",
        "simone.koch@smartpricing.it",
        "beatrice.bogdan@smartpricing.it",
        "catia@smartpricing.it",

        "naghmeh@smartpricing.it",
        "frank@smartpricing.it",
        "mariarosaria@smartpricing.it",
        "emanuele.delgamba@smartpricing.it",
        "enrico@smartpricing.it",
        "aljoscha@smartpricing.it",
        "elena@smartpricing.it",

        "rene@smartpricing.it",
        "lewis@smartpricing.it",
        "aaron@smartpricing.it",
        "ekaterina@smartpricing.it",
        "ben@smartpricing.it",
        "eleonora@smartpricing.it",
        "laura@smartpricing.it",

        "ekaterina@smartpricing.it",
        "ben@smartpricing.it",

        "lorenzoraimondi@smartpricing.it",
        "christoph@smartpricing.it",
        "luisanna@smartpricing.it",

        "christina@smartpricing.it",
        "ricardo@smartpricing.it",
        "tomas@smartpricing.it",
        "dominik@smartpricing.it",

        "francesco@smartpricing.it",
        "francesca.grossi@smartpricing.it",
        "antonella@smartpricing.it",
        "giorgia@smartpricing.it",
        "laura.andreussi@smartpricing.it",
        "claudiaciotta@smartpricing.it",
        "francescabattaiotto@smartpricing.it",

        "christina@smartpricing.it",
        "ricardo@smartpricing.it",
    ],
    "salessmartpaying.all": [
        "mariarosaria@smartpricing.it",
        "antonio.fioroni@smartpricing.it",
        "andrea.brandi@smartpricing.it",
        "stella@smartpricing.it",
        "alice@smartpricing.it",
        "eleonora@smartpricing.it",
        "eleonora.ballatori@smartpricing.it",
        "enrico.dorigato@smartpricing.it",
        "rodolfo@smartpricing.it",
        "alessandra@smartpricing.it",
        "antonio@smartpricing.it",
        "arianna@smartpricing.it",
        "emanuele.delgamba@smartpricing.it",
        "paolo.bacci@smartpricing.it",
    ],
    "techsupport.all": [
        "giulia@smartpricing.it",
        "riccardo@smartpricing.it",
        "fabio.inzaghi@smartpricing.it",
        "giovanni@smartpricing.it",
        "marco.saccarola@smartpricing.it",
        "giulia.serena@smartpricing.it",
        "monica.minervini@smartpricing.it",
        "paolo.bacci@smartpricing.it",
        "tiziana@smartpricing.it",
        "adalgisa@smartpricing.it",
        "andrea.estelli@smartpricing.it",
        "gloria@smartpricing.it",
        "sandro@smartpricing.it",
        "antonio@smartpricing.it",
    ],
    "marketing.all": ["alice@smartpricing.it", "eleonora@smartpricing.it"],
    "backoffice.all": [
        "admin@smartpricing.it",
        "francesca.frau@smartpricing.it",
        "lorenzo.viliani@smartpricing.it",
        "nicole@smartpricing.it",
    ],
    "managers.all": [
        "tommaso@smartpricing.it",
        "alessia@smartpricing.it",
        "vincenzo@smartpricing.it",
        "francesca@smartpricing.it",
        "kevin@smartpricing.it",
        "simon@smartpricing.it",
        "frank@smartpricing.it",
        "rosaria@smartpricing.it",
        "luca@smartpricing.it",
        "fiorenzo@smartpricing.it",
        "gianlorenzo@smartpricing.it",
        "rosaria@smartpricing.it",
        "christoph@smartpricing.it",
    ],
    "integrations.all": [
        "giovanni@smartpricing.it",
        "marco.saccarola@smartpricing.it",
        "andrea.estelli@smartpricing.it",
    ],
};

const TECHSUPPORT = ["admin.all", "techsupport.all"];
const TECHSUPPORT_OBS_RM = [
    "admin.all",
    "techsupport.all",
    "onboarder.all",
    "revenuemanager.all",
];
const TECHSUPPORT_OBS_RM_SALES = [
    "admin.all",
    "techsupport.all",
    "onboarder.all",
    "revenuemanager.all",
    "sales.all",
];
const TECHSUPPORT_OBS_RM_SALES_MANAGERS = [
    "admin.all",
    "techsupport.all",
    "onboarder.all",
    "revenuemanager.all",
    "sales.all",
    "managers.all",
];
const TECHSUPPORT_OBS_RM_MARKETING_SALES_MANAGERS = [
    "admin.all",
    "techsupport.all",
    "onboarder.all",
    "revenuemanager.all",
    "marketing.all",
    "sales.all",
    "managers.all",
    "salessmartpaying.all",
];
const TECHSUPPORT_MARKETING = ["admin.all", "techsupport.all", "marketing.all"];
const TECHSUPPORT_OBS_BACKOFFICE_SALESSMARTPAYING = [
    "admin.all",
    "techsupport.all",
    "onboarder.all",
    "backoffice.all",
    "salessmartpaying.all",
];
const MANAGERS_INTEGRATIONS = ["admin.all", "managers.all", "integrations.all"];
const SALES = ["sales.all"];

module.exports.ROLES_APP = {
    core: {
        propertyGroup: TECHSUPPORT_OBS_RM,
        addPropertyGroup: TECHSUPPORT_OBS_RM,
        property: TECHSUPPORT_OBS_RM,
        addProperty: TECHSUPPORT_OBS_RM,
        manageUsers: TECHSUPPORT_OBS_RM,
        addUser: TECHSUPPORT_OBS_RM,
        manageRates: TECHSUPPORT_OBS_RM,
        manageSubscriptions: TECHSUPPORT_OBS_RM,
        customersMap: TECHSUPPORT_OBS_RM_SALES_MANAGERS,
        tokenGeneration: TECHSUPPORT_OBS_RM_SALES_MANAGERS,
        oauthLink: TECHSUPPORT_OBS_RM,
        losManagement: TECHSUPPORT_OBS_RM,
        propertySet: TECHSUPPORT_OBS_RM,
    },
    graph: {
        propertyGroup: TECHSUPPORT_OBS_RM,
        holiday: TECHSUPPORT_OBS_RM,
        holidayOverview: TECHSUPPORT_OBS_RM,
        events: TECHSUPPORT_OBS_RM,
        customRootEvents: TECHSUPPORT_OBS_RM,
        eventsOverview: TECHSUPPORT_OBS_RM,
    },
    integrations: {
        integrationsSpec: TECHSUPPORT_OBS_RM,
        managePartners: TECHSUPPORT_OBS_RM,
        versori: TECHSUPPORT_OBS_RM,
    },
    gpcs: {
        runJob: TECHSUPPORT_OBS_RM,
        resetSentPrice: TECHSUPPORT_OBS_RM,
        deleteReservations: TECHSUPPORT_OBS_RM,
        moveReservations: TECHSUPPORT_OBS_RM,
        //"resetAckSysdat": TECHSUPPORT_OBS_RM,
        rawReservations: TECHSUPPORT_OBS_RM,
        diffReservations: TECHSUPPORT_OBS_RM,
    },
    logs: {
        systemLogs: TECHSUPPORT_OBS_RM,
        dashboard: TECHSUPPORT_OBS_RM,
        computedPricesLogs: TECHSUPPORT_OBS_RM,
        predictLogs: TECHSUPPORT_OBS_RM,
    },
    alarms: {
        notActivePg: TECHSUPPORT_OBS_RM,
        alarmReservation: TECHSUPPORT_OBS_RM,
        alarmRetention: TECHSUPPORT_OBS_RM,
        alarmOccupancy: TECHSUPPORT_OBS_RM,
        launcher: TECHSUPPORT_OBS_RM,
        triggered: TECHSUPPORT_OBS_RM,
        jobs: TECHSUPPORT_OBS_RM,
    },
    strategy: {
        manageStrategy: TECHSUPPORT_OBS_RM,
        typeform: TECHSUPPORT_OBS_RM,
        copyBaseprice: TECHSUPPORT_OBS_RM,
    },
    analytics: {
        analyticsDs: TECHSUPPORT_OBS_RM_MARKETING_SALES_MANAGERS,
        analyticsReservations: TECHSUPPORT_OBS_RM_MARKETING_SALES_MANAGERS,
        analyticsPerformance: TECHSUPPORT_OBS_RM_MARKETING_SALES_MANAGERS,
        manageMarkets: TECHSUPPORT_OBS_RM_MARKETING_SALES_MANAGERS,
        marketIntelligence: TECHSUPPORT_OBS_RM_MARKETING_SALES_MANAGERS,
        earlySales: TECHSUPPORT_OBS_RM_MARKETING_SALES_MANAGERS,
        pmsCmOverview: TECHSUPPORT_OBS_RM_MARKETING_SALES_MANAGERS,
    },
    integrationsStatus: {
        integrationsStatus: ["guest.all"],
        integrationsStatusOverview: ["guest.all"],
    },
    smartpaying: {
        onboarding: TECHSUPPORT_OBS_BACKOFFICE_SALESSMARTPAYING,
        messaging: TECHSUPPORT_OBS_BACKOFFICE_SALESSMARTPAYING,
        stripe: TECHSUPPORT_OBS_BACKOFFICE_SALESSMARTPAYING,
        upselling: TECHSUPPORT_OBS_BACKOFFICE_SALESSMARTPAYING,
        reporting: TECHSUPPORT_OBS_BACKOFFICE_SALESSMARTPAYING,
        customSenderAddress: TECHSUPPORT_OBS_BACKOFFICE_SALESSMARTPAYING,
        support: TECHSUPPORT_OBS_BACKOFFICE_SALESSMARTPAYING,
        autonboarding: TECHSUPPORT_OBS_BACKOFFICE_SALESSMARTPAYING,
    },
    scraper: {
        hotel: TECHSUPPORT,
        area: TECHSUPPORT,
        search: TECHSUPPORT,
    },
};
