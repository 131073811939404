<template>
	<v-container fluid class="pa-4">
		<v-card v-if="globalSelectedPgId" class="card-ui pa-4">
			<v-card-title>Reset Sent Price</v-card-title>
			<v-card-subtitle v-if="!enabled" class="red--text">The feature is not supported by the selected pg</v-card-subtitle>

			<v-row class="pa-4" v-if="enabled">                  
				<v-col
					cols="12"
					sm="6"
					md="6"
				>
					<v-menu
						v-model="menu"
						:close-on-content-click="false"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
						<v-text-field
							class="mt-3"
							v-model="datesText"
							label="Reset Sent Prices Date Range"
							prepend-icon="fa-solid fa-calendar"
							dense
							outlined
							hint="Format: YYYY-MM-DD - YYYY-MM-DD"
							v-bind="attrs"
							v-on="on"
							ref="dateRangeTextField"
						></v-text-field>
						</template>
						<v-date-picker
							v-model="dates"
							dark
							range
							color="cyan"
							@change="menu = false"
							show-adjacent-months
						>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="primary"
								@click="setBulkDates"
							>
								Massive
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="primary"
								@click="resetDates"
							>
								Reset
							</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>
			</v-row>

			<v-card-actions class="pa-4" v-if="enabled">
				<v-btn 
					color="primary"
					@click="launchJob"
				>
					SUBMIT
				</v-btn>
			</v-card-actions>
		</v-card>
		<SelectPgAlert v-else></SelectPgAlert>
	</v-container>
</template>

<script>

// @ is an alias to /src
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import SelectPgAlert from '@/components/utils/SelectPgAlert'

export default {
  name: 'ResetSentPrice',
  components: {SelectPgAlert},
  data: function () {
	return {  
		menu: false,
		dates: [],
		enabled: false,
	}
  },
  
  computed: {
	...mapState({
		partnersListByFeature: state => state.integrationsSpec.partnersListByFeature
	}),
	...mapGetters({
		globalSelectedPgId: "core/globalSelectedPgId",
	}),
	datesText() {
		const first = this.dates[0] < this.dates[1] ? this.dates[0] : this.dates[1]
		const last = this.dates[1] < this.dates[0] ? this.dates[0] : this.dates[1]
		return this.dates.length === 2 ? `From ${first} to ${last}` : ""
	}
  },
  watch: {
	globalSelectedPgId(newVal, oldVal) {
		this.checkSupport()
	}
  },
  methods: {
    ...mapActions({
        getFeaturesList: "integrationsSpec/getFeaturesList",
        getPartnersByFeature: "integrationsSpec/getPartnersByFeature",
    }),
	setBulkDates(){
	  let today = new Date()
      let nextTwoYearsDate = new Date()
      nextTwoYearsDate.setFullYear(nextTwoYearsDate.getFullYear() + 2)
      this.dates = [today.toISOString().split("T")[0], nextTwoYearsDate.toISOString().split("T")[0]]
    },
	resetDates() {
		this.dates = []
	},

	async launchJob() {
		const args = {
			job : {
				property_group_id : this.globalSelectedPgId,
				from_date : this.dates[0] < this.dates[1] ? this.dates[0] : this.dates[1],
				to_date : this.dates[1] < this.dates[0] ? this.dates[0] : this.dates[1],
			}
      	}

		let res = await this.$store.dispatch("gpcs/launchResetSentPrice", args )

		if ( res.status == 200 ) {
			this.$store.dispatch("alertSystem/newSuccess", {
				title: "Update executed",
				message: "The last_send_with_success has been reset",
				timeout: 5000,
			})		
		} else if ( res.status == 500 ) {
			this.$store.dispatch("alertSystem/newError", {
				title: "Error",
				message: `Error ${res.error}`,
				timeout: -1,
			})
		}		
	},
	checkSupport() {
		let selectedPgCM = this.$store.state.core.currentPG.spec.channel_manager_name
		
		let enabled = false
		for ( let partner of this.partnersListByFeature ) {
			if ( partner.text === selectedPgCM ) {
				if ( partner.value === 'pull' ) {
					enabled = true
				}
				break
			}		
		}

		this.enabled = enabled
	}
  },

  async created() {
	await this.getPartnersByFeature({featureId: 'pricesCommunicationMethod'})
	this.checkSupport()
  }
}
</script>