<template>
    <v-container fluid class="pa-4">
        <v-card v-if="!!globalSelectedPgId" class="card-ui pa-4">
            <v-card-title>Reservations</v-card-title>

            <DataFilters :items="reservations" :schema="filtersSchema" v-model="filteredResults" :filtersMenuDefault="true"
                class="card-ui">
                <template v-slot:header>
                    <v-btn @click="downloadCsv" class="mr-2">
                        Download CSV
                    </v-btn>
                    <v-btn @click="downloadXlsx" class="mx-2">
                        Download XLSX
                    </v-btn>
                </template>
                <template v-slot:filters-top>
                    <v-row class="pa-2">
                        <v-col class="col-2">
                            <v-switch label="Use Booking Date" v-model="useBookingDate" hide-details :true-value="true"
                                :false-value="false" class="mt-0" dense></v-switch>
                        </v-col>
                        <v-col class="col-2">
                            <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="bookingStart" label="Booking Start" type="text" @change="fetch()"
                                        dense outlined hide-details append-icon="fa-solid fa-calendar" v-bind="attrs"
                                        v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="bookingStart" @input="menu1 = false"
                                    @picker-date.sync="bookingStart" @change="fetch()" align="center" color="blue">
                                    <div class="date-buttons" @click="fetch()">
                                        <div class="date-buttons-col">
                                            <v-btn color="error" elevation="2"
                                                @click="bookingStart = changeDate(bookingStart, -1)">- 1 Day</v-btn>
                                            <v-btn color="error" elevation="2"
                                                @click="bookingStart = changeDate(bookingStart, -30)">- 30 Days</v-btn>
                                            <v-btn color="error" elevation="2"
                                                @click="bookingStart = changeDate(bookingStart, -30 * 6)">- 6 Months</v-btn>
                                        </div>
                                        <div class="date-buttons-col">
                                            <v-btn color="primary" elevation="2"
                                                @click="bookingStart = changeDate(bookingStart, 1)">+ 1 Day</v-btn>
                                            <v-btn color="primary" elevation="2"
                                                @click="bookingStart = changeDate(bookingStart, 30)">+ 30 Days</v-btn>
                                            <v-btn color="primary" elevation="2"
                                                @click="bookingStart = changeDate(bookingStart, 30 * 6)">+ 6 Months</v-btn>
                                        </div>
                                    </div>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-2">
                            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="bookingEnd" label="Booking End" type="text" @change="fetch()"
                                        hide-details dense outlined append-icon="fa-solid fa-calendar" v-bind="attrs"
                                        v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="bookingEnd" @input="menu2 = false" @change="fetch()" color="blue">
                                    <div class="date-buttons" @click="fetch()">
                                        <div class="date-buttons-col">
                                            <v-btn color="error" elevation="2"
                                                @click="bookingEnd = changeDate(bookingEnd, -1)">- 1 Day</v-btn>
                                            <v-btn color="error" elevation="2"
                                                @click="bookingEnd = changeDate(bookingEnd, -30)">- 30 Days</v-btn>
                                            <v-btn color="error" elevation="2"
                                                @click="bookingEnd = changeDate(bookingEnd, -30 * 6)">- 6 Months</v-btn>
                                        </div>
                                        <div class="date-buttons-col">
                                            <v-btn color="primary" elevation="2"
                                                @click="bookingEnd = changeDate(bookingEnd, 1)">+ 1 Day</v-btn>
                                            <v-btn color="primary" elevation="2"
                                                @click="bookingEnd = changeDate(bookingEnd, 30)">+ 30 Days</v-btn>
                                            <v-btn color="primary" elevation="2"
                                                @click="bookingEnd = changeDate(bookingEnd, 30 * 6)">+ 6 Months</v-btn>
                                        </div>
                                    </div>
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-spacer></v-spacer>

                        <v-col class="col-2">
                            <v-switch label="Use Stay Date" v-model="useStayDate" class="mt-0" hide-details
                                dense></v-switch>
                        </v-col>
                        <v-col class="col-2">
                            <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="stayStart" label="Stay Start" type="text" @change="fetch()" dense
                                        hide-details outlined append-icon="fa-solid fa-calendar" v-bind="attrs" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="stayStart" @input="menu3 = false" @change="fetch()" color="blue">
                                    <div class="date-buttons" @click="fetch()">
                                        <div class="date-buttons-col">
                                            <v-btn color="error" elevation="2"
                                                @click="stayStart = changeDate(stayStart, -1)">- 1 Day</v-btn>
                                            <v-btn color="error" elevation="2"
                                                @click="stayStart = changeDate(stayStart, -30)">- 30 Days</v-btn>
                                            <v-btn color="error" elevation="2"
                                                @click="stayStart = changeDate(stayStart, -30 * 6)">- 6 Months</v-btn>
                                        </div>
                                        <div class="date-buttons-col">
                                            <v-btn color="primary" elevation="2"
                                                @click="stayStart = changeDate(stayStart, 1)">+ 1 Day</v-btn>
                                            <v-btn color="primary" elevation="2"
                                                @click="stayStart = changeDate(stayStart, 30)">+ 30 Days</v-btn>
                                            <v-btn color="primary" elevation="2"
                                                @click="stayStart = changeDate(stayStart, 30 * 6)">+ 6 Months</v-btn>
                                        </div>
                                    </div>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-2">
                            <v-menu v-model="menu4" :close-on-content-click="false" :nudge-left="40"
                                transition="scale-transition" offset-y left min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="stayEnd" label="Stay End" append-icon="fa-solid fa-calendar"
                                        @change="fetch()" type="text" hide-details outlined dense v-bind="attrs" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="stayEnd" @input="menu4 = false" @change="fetch()" color="blue">
                                    <div class="date-buttons" @click="fetch()">
                                        <div class="date-buttons-col">
                                            <v-btn color="error" elevation="2" @click="stayEnd = changeDate(stayEnd, -1)">-
                                                1
                                                Day</v-btn>
                                            <v-btn color="error" elevation="2" @click="stayEnd = changeDate(stayEnd, -30)">-
                                                30 Days</v-btn>
                                            <v-btn color="error" elevation="2"
                                                @click="stayEnd = changeDate(stayEnd, -30 * 6)">- 6 Months</v-btn>
                                        </div>
                                        <div class="date-buttons-col">
                                            <v-btn color="primary" elevation="2" @click="stayEnd = changeDate(stayEnd, 1)">+
                                                1 Day</v-btn>
                                            <v-btn color="primary" elevation="2"
                                                @click="stayEnd = changeDate(stayEnd, 30)">+
                                                30 Days</v-btn>
                                            <v-btn color="primary" elevation="2"
                                                @click="stayEnd = changeDate(stayEnd, 30 * 6)">+ 6 Months</v-btn>
                                        </div>
                                    </div>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </template>
            </DataFilters>

            <ColumnsFilter :tableColumns="tableColumns" tableColumnsFilterDefaultEnabled="true"
                v-model="currentlyEnabledTableColumns" class="card-ui">
            </ColumnsFilter>

            <v-data-table :headers="currentlyEnabledTableColumns" :items="filteredResults" class="card-ui ma-4"
                item-key="id" :items-per-page="25" :footer-props="{
                    itemsPerPageOptions: [25, 100, 500, -1],
                }">
                <template v-slot:footer.prepend>
                    <v-chip class="ma-2">
                        Total Reservation count: {{ reservationsCount }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState } from 'vuex'
import { jsonToWorksheet } from '@/utils/csvUtils'
import SelectPgAlert from '@/components/utils/SelectPgAlert'
import DataFilters from '@/components/utils/DataFilters.vue'
import ColumnsFilter from '@/components/utils/ColumnsFilter.vue'

export default {
    name: 'Reservations',
    components: { SelectPgAlert, DataFilters, ColumnsFilter },
    data() {
        return {
            filteredResults: [],
            filtersSchema: [
                { text: "Name", value: "name" },
                { text: "Duration", value: "los" },
                { text: "Status", value: "status" },
                { text: "Origin Channel", value: "origin_channel" },
                { text: "Origin Channel Type", value: "origin_channel_type" },
                { text: "Rate Description", value: "rate_description" },
                { text: "Board Type", value: "r_board_type" },
                { text: "External ID", value: "external_id" },
            ],

            tableColumns: [
                { text: 'ID', value: 'id', width: "5%", defaultEnabled: false },
                { text: 'External ID', value: 'external_id', width: "10%", defaultEnabled: true },
                { text: "Room", value: 'name', width: "5%", defaultEnabled: true },
                { text: 'Check-In', value: 'checkin_at', width: "7%", defaultEnabled: true },
                { text: 'Check-Out', value: 'checkout_at', width: "7%", defaultEnabled: true },
                { text: 'Duration', value: 'los', width: "1%", defaultEnabled: true },
                { text: 'Amount', value: 'amount_reservation', width: "5%", defaultEnabled: true },
                { text: 'ADR', value: 'adr', width: "2%", defaultEnabled: true },
                { text: 'Guests', value: 'guest_count', width: "1%", defaultEnabled: true },
                { text: 'Status', value: 'status', width: "5%", defaultEnabled: true },
                { text: 'Channel', value: 'origin_channel', width: "5%", defaultEnabled: true },
                { text: 'Type', value: 'origin_channel_type', width: "5%", defaultEnabled: true },
                { text: 'Pricelist', value: 'rate_description', width: "10%", defaultEnabled: true },
                { text: 'Board Type', value: 'r_board_type', width: "1%", defaultEnabled: true },
                { text: 'Created At', value: 'created_at', width: "10%", defaultEnabled: true },
                { text: 'External Booked At', value: 'external_booked_at', width: "5%", defaultEnabled: true },
                { text: 'Canceled At', value: 'canceled_at', width: "5%", defaultEnabled: true },
                { text: 'External Canceled At', value: 'external_canceled_at', width: "5%", defaultEnabled: true }
            ],
            
            currentlyEnabledTableColumns: [],

            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,

            useBookingDate: true,
            useStayDate: true,
            bookingStart: "",
            bookingEnd: "",
            stayStart: "",
            stayEnd: "",
        }
    },
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG,
            reservationsList: state => state.reservations.reservationsList,
            reservationsCount: state => state.reservations.reservationsCount,
        }),
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
        }),
        reservations() {
            return this.reservationsList.map(r => {
                try {
                    let spec = r.spec
                    spec.id = r.metadata.id
                    spec.checkin_at = spec.checkin_at.split('T')[0]
                    spec.checkout_at = spec.checkout_at.split('T')[0]
                    spec.external_booked_at = `${spec.external_booked_at.split('T')[0]} ${spec.external_booked_at.split('T')[1].split('.')[0]}`
                    spec.adr = parseFloat(spec.adr.toFixed(3))
                    spec.amount_reservation = parseFloat(spec.amount_reservation.toFixed(3))
                    return spec
                } catch (e) {
                    return r.spec
                }
            })
        },
    },
    watch: {
        currentPG(newVal, oldVal) {
            this.fetch()
            this.fetchReservationCount()
        },
        useBookingDate(newVal, oldVal) {
            if (newVal == true) {
                const today = new Date()
                this.bookingStart = this.previousDateISO(today, -30)
                this.bookingEnd = this.previousDateISO(today, 0)
            } else {
                this.bookingStart = null
                this.bookingEnd = null
            }
            this.fetch()
        },
        useStayDate(newVal, oldVal) {
            if (newVal == true) {
                const today = new Date()
                this.stayStart = this.previousDateISO(today, -30)
                this.stayEnd = this.previousDateISO(today, 0)
            } else {
                this.stayStart = null
                this.stayEnd = null
            }
            this.fetch()
        },
    },
    methods: {
        async downloadCsv() {
            await jsonToWorksheet(this.filteredResults, "reservations.csv", "csv", this.currentlyEnabledTableColumns)
        },
        async downloadXlsx() {
            await jsonToWorksheet(this.filteredResults, "reservations.xlsx", "xlsx", this.currentlyEnabledTableColumns)
        },
        previousDateISO(date, daysAgo) { // function to get the date n days ago from today
            const timeElapsed = 1000 * 60 * 60 * 24 * daysAgo
            const newDate = new Date(date.getTime() + timeElapsed)
            return newDate.toISOString().split("T")[0]
        },
        changeDate(date, variation) {
            const currentDate = new Date(date)
            return this.previousDateISO(currentDate, variation)
        },
        fetch() {
            const payload = {
                pgId: this.currentPG.metadata.id,
                bookingStart: this.bookingStart == null ? '2000-01-01' : this.bookingStart,
                bookingEnd: this.bookingEnd == null ? '2099-01-01' : this.bookingEnd,
                stayStart: this.stayStart == null ? '2000-01-01' : this.stayStart,
                stayEnd: this.stayEnd == null ? '2099-01-01' : this.stayEnd,
            }
            if (!Object.values(payload).every(arg => arg !== undefined && arg !== null)) {
                return
            }

            this.$store.dispatch('reservations/fetchReservationsList', payload)

            return payload
        },
        fetchReservationCount() {
            const payload = {
                pgId: this.currentPG.metadata.id
            }
            this.$store.dispatch('reservations/fetchReservationsCount', payload)
        }
    },
    created() {
        // set's the date pickers to default dates
        const today = new Date()

        this.bookingStart = this.previousDateISO(today, -30)
        this.bookingEnd = this.previousDateISO(today, 0)

        this.stayStart = this.bookingStart
        this.stayEnd = this.bookingEnd

        this.fetch()
    },
    mounted() {
        this.fetchReservationCount()
    }
}
</script>

<style scoped>
.date-buttons,
.date-buttons-col {
    gap: 1rem;
}

.date-buttons {
    display: flex;
}

.date-buttons-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
