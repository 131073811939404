/**
 * Store for dashboard
 */ 
async function apiRequest(context, path, args){
  try {
    const api = context.rootState.api.api
    const res = await api.get('log/v1', path, {}, {}, {})
    return res
  } catch (e) {
    console.error(e)
  }
}

import Vue from "vue"

export default {
  namespaced: true,

  state: {
    warnings: [],
    notSentProperties: [],
    gradient: ['purple', '#FF1744', 'gray'],
    allPropertyGroupStateHistory: [],
    currentStateByPropertyID: {},
  },
  getters: {
    warnings(state){
      return state.warnings
    },
    notSentProperties(state){
      return state.notSentProperties
    },
    allPropertyGroupStateHistory(state){
      return state.allPropertyGroupStateHistory
    }
  },
  mutations: {
    warnings(state, data) {
      state.warnings = data
    },
    notSentProperties(state, data) {
      state.notSentProperties = data
    },
    allPropertyGroupStateHistory(state, data) {
      state.allPropertyGroupStateHistory = data
      data.forEach(pg => {
        Vue.set(state.currentStateByPropertyID, pg.propertyGroupId, pg.lifecycleStateName + "." + pg.lifecycleStateLabel)
      })
    },
  },
  actions: {
    async warnings(context, args) {
      const path = '/pg/logs/warning'
      const response = await apiRequest(context, path)
      if(response?.response?.status === 500){
        return
      }
      context.commit('warnings', response)
    },
    async notSentProperties(context, args) {
      const path = '/notsent/' + args.date
      const response = await apiRequest(context, path)
      if(response?.response?.status === 500){
        return
      }
      context.commit('notSentProperties', response)
    },
    async allPropertyGroupStateHistory (context, args) {
      function getNumberOfDays(start, end){
        const date1 = new Date(start);
        const oneDay = 1000 * 60 * 60 * 24;
        const diffInTime = end.getTime() - date1.getTime();
        const diffInDays = Math.round(diffInTime / oneDay);

        return diffInDays;
      }

      const today = new Date()
      const api = context.rootState.api.api
      const jobRes = await api.get('lifecycle/v1', '/propertygroupstatelist', {}, {}, {})
      //const jobRes = await api.get('lifecycle/v2', '/propertygroupstate/latest/smartpricing', {}, {}, {})
      // in the v2 there isn't the propertyGroupName, so for now we keep the v1
      const objToSave = jobRes.items?.map(x => {
        x.days = getNumberOfDays(x.date, today)
        x.pgString = `${x.propertyGroupId} ${x.propertyGroupName}`
        x.lcString = `${x.lifecycleStateName}/${x.lifecycleStateLabel}`
        return x
      })
      context.commit('allPropertyGroupStateHistory', objToSave)
    },  
  }
}
