<template>
    <v-card
        class="card-ui pa-4"
    >
        <v-card-title
            color="primary"
            dark
        >Token Generation</v-card-title>
        <v-card-text>
            <v-row>
                <v-col class="col col-6">
                    <v-autocomplete
                        :items="currentUsersDropdown"
                        item-text="text"
                        item-value="text"
                        label="Select the user"
                        v-model="selectedUser"
                        outlined
                    ></v-autocomplete>
                </v-col>
                <v-col class="col col-6">
                    <v-text-field
                        outlined
                        v-model="generatedToken"
                        persistent-hint
                        readonly
                        class="pointer"
                        :hint="copied ? `copied to clipboard` : ``"
                        append-icon="far fa-copy"
                        @click="openevent($event)"
                        @click:append="copyevent($event)"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-dialog
                transition="dialog-bottom-transition"
                width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!selectedUser"
                    >Generate Token For Production</v-btn>
                </template>
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                            color="success"
                            dark
                        >Token for production</v-toolbar>
                        <v-card-text>
                            <div class="text-h2 pa-12">You are about to generate the token for production, understood?</div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                color="success"
                                @click="[createToken(`admin`), dialog.value = false]"
                            >Generate Token For Production</v-btn>
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>

            <v-spacer></v-spacer>

            <v-dialog
                transition="dialog-bottom-transition"
                width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="warning"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!selectedUser"
                    >Generate Token For Staging</v-btn>
                </template>
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                            color="warning"
                            dark
                        >Token for staging</v-toolbar>
                        <v-card-text>
                            <div class="text-h2 pa-12">You are about to generate the token for staging, understood?</div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                @click="[createToken(`staging`), dialog.value = false]"
                                :disabled="!selectedUser"
                                color="warning"
                            >
                                Generate Token For Staging
                            </v-btn>
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>

            <v-spacer></v-spacer>

            <v-dialog
                transition="dialog-bottom-transition"
                width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!selectedUser"
                    >Generate Token For Development</v-btn>
                </template>
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                            color="info"
                            dark
                        >Token for Development</v-toolbar>
                        <v-card-text>
                            <div class="text-h2 pa-12">You are about to generate the token for Development, understood?</div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                color="info"
                                @click="[createToken(`dev`), dialog.value = false]"
                            >Generate Token For Development</v-btn>
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>

        </v-card-actions>

    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            selectedUser: "",
            copied: false,
            generatedToken: "",
        }
    },
    computed: {
        ...mapGetters({
            currentUsersDropdown: "core/currentUsersDropdown",
            globalSelectedPgId: "core/globalSelectedPgId",
        })
    },
    watch: {
        async globalSelectedPgId (newVal, oldVal) {
            this.generatedToken = ""
            this.selectedUser = ""
        }
    },
    methods: {
        async openevent(event) {
            if (this.generatedToken) {
                window.open(this.generatedToken, "_blank");
            }
        },
        async copyevent(event){
            await navigator.clipboard.writeText(this.generatedToken)
            this.copied = true
            setTimeout(() => this.copied = false, 5000)
        },
        async createToken(environment) {
            this.generatedToken = await this.$store.dispatch("core/generateToken", {
                targetUsername: this.selectedUser.split(" - ")[1],
				issuedBy: this.$store.state.auth.username,
                environment: environment,
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.far-copy{
    cursor: pointer !important;
}
</style>
