// lo store per i charts della pipeline v2 dato che sennò non si capisce nulla
import { ChartUtils } from "../../../utils/chartUtils"
import dateUtils from "../../../utils/dateUtils"

export default {
    namespaced: true,

    state: {
        chartPipelineTypes: ["master", "test"],

        chartsOptions: {
            seasonalityHeatmap: {
                master: null,
                test: null,
            },
            /* basePriceChart: {
                master: null,
                test: null,
            }, */
            eventsImpactChart: {
                master: null,
                test: null,
            },
            dowImpactChart: {
                master: null,
                test: null,
            },
            demandImpactChart: {
                master: null,
                test: null,
            },
            demandHeatmapLowSeason: {
                master: null,
                test: null,
            },
            demandHeatmapMidSesaon: {
                master: null,
                test: null,
            },
            demandHeatmapHighSeason: {
                master: null,
                test: null,
            },
            interalMarketCorrectionImpactChart: {
                master: null,
                test: null,
            },
            compsetCorrectionImpactChart: {
                master: null,
                test: null,
            },
            compsetCorrectionChart: {
                master: null,
                test: null,
            },
            finalPriceChart: {
                master: null,
                test: null,
            },
            statsChart: {
                master: null,
                test: null,
            },
            basePriceMap: {
                master: null,
                test: null
            },
            originalBasePriceMap: {
                master: null,
                test: null
            }
        },
    },

    getters: {

        seasonalityHeatmapFiltered(state) {
            return function(pipelineType) {
                return state.chartsOptions.seasonalityHeatmap[pipelineType]
            }
        },

        eventsImpactChartFiltered(state) {
            return function(pipelineType, propertiesSelected) {
                const chart = state.chartsOptions.eventsImpactChart[pipelineType]

                if (!chart) return null

                const newChartData = _.cloneDeep(chart)
                newChartData.series = chart.series.filter(val => {
                    return propertiesSelected.includes(Number(val.name.split(" - ")[0] ))
                })
                newChartData.legend.data = chart.legend.data.filter(val => {
                    return propertiesSelected.includes(Number(val.split(" - ")[0]))
                })
                return newChartData
            }
        },

        dowImpactChartFiltered(state) {
            return function(pipelineType, propertiesSelected) {
                const chart = state.chartsOptions.dowImpactChart[pipelineType]

                if (!chart) return null

                const newChartData = _.cloneDeep(chart)
                newChartData.series = chart.series.filter(val => {
                    return propertiesSelected.includes(Number(val.name.split(" - ")[0] ))
                })
                newChartData.legend.data = chart.legend.data.filter(val => {
                    return propertiesSelected.includes(Number(val.split(" - ")[0]))
                })
                return newChartData
            }
        },

        demandImpactChartFiltered(state) {
            return function(pipelineType, propertiesSelected) {
                const chart = state.chartsOptions.demandImpactChart[pipelineType]

                if (!chart) return null

                const newChartData = _.cloneDeep(chart)
                newChartData.series = chart.series.filter(val => {
                    return propertiesSelected.includes(Number(val.name.split(" - ")[0] ))
                })
                newChartData.legend.data = chart.legend.data.filter(val => {
                    return propertiesSelected.includes(Number(val.split(" - ")[0]))
                })
                return newChartData
            }
        },

        demandHeatmapLowSeasonFiltered(state) {
            return function(pipelineType) {
                return state.chartsOptions.demandHeatmapLowSeason[pipelineType]
            }
        },

        demandHeatmapMidSesaonFiltered(state) {
            return function(pipelineType) {
                return state.chartsOptions.demandHeatmapMidSesaon[pipelineType]
            }
        },

        demandHeatmapHighSeasonFiltered(state) {
            return function(pipelineType) {
                return state.chartsOptions.demandHeatmapHighSeason[pipelineType]
            }
        },

        interalMarketCorrectionImpactChartFiltered(state) {
            return function(pipelineType, propertiesSelected) {
                const chart = state.chartsOptions.interalMarketCorrectionImpactChart[pipelineType]

                if (!chart) return null

                const newChartData = _.cloneDeep(chart)
                newChartData.series = chart.series.filter(val => {
                    return propertiesSelected.includes(Number(val.name.split(" - ")[0] ))
                })
                newChartData.legend.data = chart.legend.data.filter(val => {
                    return propertiesSelected.includes(Number(val.split(" - ")[0]))
                })
                return newChartData
            }
        },

        compsetCorrectionImpactChartFiltered(state) {
            return function(pipelineType, propertiesSelected) {
                const chart = state.chartsOptions.compsetCorrectionImpactChart[pipelineType]

                if (!chart) return null

                const newChartData = _.cloneDeep(chart)
                newChartData.series = chart.series.filter(val => {
                    return propertiesSelected.includes(Number(val.name.split(" - ")[0] ))
                })
                newChartData.legend.data = chart.legend.data.filter(val => {
                    return propertiesSelected.includes(Number(val.split(" - ")[0]))
                })
                return newChartData
            }
        },

        finalPriceChartFiltered(state) {
            return function(pipelineType, propertiesSelected, showMaster = false) {
                const chart = state.chartsOptions.finalPriceChart[pipelineType]

                if (!chart) return null

                const newChartData = _.cloneDeep(chart)
                newChartData.series = chart.series.filter(val => {
                    const propId = val.name.split(" - ")[0]
                    const traceName = val.name.split(" - ")[1]

                    return propertiesSelected.includes(Number(propId)) && (showMaster && traceName.includes("master") || !traceName.includes("master"))
                })

                newChartData.legend.data = chart.legend.data.filter(val => {
                    const propId = val.split(" - ")[0]
                    const traceName = val.split(" - ")[1]

                    return propertiesSelected.includes(Number(propId)) && (showMaster && traceName.includes("master") || !traceName.includes("master"))
                })
                return newChartData
            }
        },

        statsChartFiltered(state) {
            return function(pipelineType, propertiesSelected, fieldsToShow) {
                const chart = state.chartsOptions.statsChart[pipelineType]

                if (!chart) return null

                const newChartData = _.cloneDeep(chart)
                newChartData.series = chart.series.filter(val => {
                    return propertiesSelected.includes(Number(val.name.split(" - ")[0] )) && fieldsToShow.includes(val.name.split(" - ")[1])
                })
                newChartData.legend.data = chart.legend.data.filter(val => {
                    return propertiesSelected.includes(Number(val.split(" - ")[0])) && fieldsToShow.includes(val.split(" - ")[1])
                })
                return newChartData
            }
        },

        basePriceMap(state) {
            return function(pipelineType) {
                const chart = state.chartsOptions.basePriceMap[pipelineType]
                if ( !chart ) return {}

                return chart
            }
        },

        originalBasePriceMap(state) {
            return function(pipelineType) {
                const chart = state.chartsOptions.originalBasePriceMap[pipelineType]
                if ( !chart ) return {}

                return chart
            }
        }
    },

    mutations: {

        resetChartOptions(state, payload) {
            if (!payload) {
                return
            }
            state.chartsOptions[payload.chart][payload.type] = null
            state.chartsOptions[payload.chart][payload.type] = payload.newVal
        },

    },

    actions: {

        async loadSeasonalityHeatmap(context, payload) {

            const data = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.seasonality

            const mapValues = []

            Object.entries(data).forEach(([week, season], index) => {
                const weekInfo = dateUtils.getWeekDates(week, new Date().getFullYear())

                const from = weekInfo.start
                const end = weekInfo.end

                const periodLength = dateUtils.daysDifference(from, end);

                for (let difference = 0; difference <= periodLength; difference++) {
                    const day = dateUtils.addDaysToDate(from.toISOString().split("T")[0], difference);

                    mapValues.push({
                        date: day,
                        season: season,
                    });
                }
            })

            context.commit("resetChartOptions", {
                chart: "seasonalityHeatmap",
                type: payload.pipelineType,
                newVal: ChartUtils.createCalendarHeatmap(mapValues, "date", "season", "Seasonality", [
                    {value: 0, label: 'Low Season'},
                    {value: 1, label: 'Mid Season'},
                    {value: 2, label: 'High Season'},
                ])
            })
        },

        /* async loadBasePriceChart() {

        }, */

        async loadEventsImpactChart(context, payload) {
            const xCol = "d_stay"
            const yCols = ["before", "after"]
            const yCols2 = ["percentual_diff"]

            const impactData = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.events?.impact

            context.commit("resetChartOptions", {
                chart: "eventsImpactChart",
                type: payload.pipelineType,
                newVal: ChartUtils.createImpactChart(impactData, xCol, yCols, yCols2),
            })
        },

        async loadDowImpactChart(context, payload) {
            const xCol = "d_stay"
            const yCols = ["before", "after"]
            const yCols2 = ["percentual_diff"]

            const impactData = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.dow?.impact

            context.commit("resetChartOptions", {
                chart: "dowImpactChart",
                type: payload.pipelineType,
                newVal: ChartUtils.createImpactChart(impactData, xCol, yCols, yCols2),
            })
        },

        async loadDemandImpactChart(context, payload) {
            const xCol = "d_stay"
            const yCols = ["before", "after"]
            const yCols2 = ["percentual_diff"]

            const impactData = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.demand?.impact

            context.commit("resetChartOptions", {
                chart: "demandImpactChart",
                type: payload.pipelineType,
                newVal: ChartUtils.createImpactChart(impactData, xCol, yCols, yCols2),
            })
        },

        async loadDemandHeatmapForEverySeason(context, payload) {
            const seasonsIndexToChartOption = {
                0: "demandHeatmapLowSeason",
                1: "demandHeatmapMidSesaon",
                2: "demandHeatmapHighSeason",
            }

            const seasonIndexToLabel = {
                0: "Low Season",
                1: "Mid Season",
                2: "High Season",
            }

            const demandMapsForEverySeason = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.demand?.map

            demandMapsForEverySeason?.forEach(season => {
                context.commit("resetChartOptions", {
                    chart: seasonsIndexToChartOption[season.season],
                    type: payload.pipelineType,
                    newVal: ChartUtils.createHeatmapChart(season.map, "book_days", "threshold", "coefficient", "Book Days", "Threshold", seasonIndexToLabel[season.season])
                })
            })
        },

        async loadInteralMarketCorrectionImpactChart(context, payload) {
            const xCol = "d_stay"
            const yCols = ["before", "after"]
            const yCols2 = ["percentual_diff"]

            const impactData = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.market_correction?.impact

            context.commit("resetChartOptions", {
                chart: "interalMarketCorrectionImpactChart",
                type: payload.pipelineType,
                newVal: ChartUtils.createImpactChart(impactData, xCol, yCols, yCols2),
            })
        },

        async loadCompsetCorrectionImpactChart(context, payload) {
            const yCols = ["before", "after"]
            const yCols2 = ["percentual_diff"]
            const xCol = "d_stay"

            const impactData = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.compset_correction.impact

            context.commit("resetChartOptions", {
                chart: "compsetCorrectionImpactChart",
                type: payload.pipelineType,
                newVal: ChartUtils.createImpactChart(impactData, xCol, yCols, yCols2),
            })
        },

        async loadCompsetCorrectionChart(context, payload) {
            const xCol = "d_stay"
            const yCols = ["price", "price_corrected_w", "price_corrected_1", "middle_market_for_prop", "upper_uncertainty_market_for_prop", "lower_uncertainty_market_for_prop"]
            const yCols2 = ["grouped_occupancy_perc_nopr"]


            const impactData = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.compset_correction.plot

            context.commit("resetChartOptions", {
                chart: "compsetCorrectionChart",
                type: payload.pipelineType,
                newVal: ChartUtils.createImpactChart(impactData, xCol, yCols, yCols2),
            })

        },

        async loadFinalPriceChart(context, payload) {
            const xCol = "d_stay"
            const yCols = {
                master: ["base_price", "day_map_endprice", "events_endprice", "heatmap_endprice", "market_correction_endprice", "price_weighted_derived"],
                test: ["base_price", "day_map_endprice", "events_endprice", "heatmap_endprice", "market_correction_endprice", "price_weighted_derived", "master_base_price", "master_day_map_endprice", "master_events_endprice", "master_heatmap_endprice", "master_compset_correction_endprice", "master_price_weighted_derived"]
            }

            const impactData = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.justifiers.plot

            context.commit("resetChartOptions", {
                chart: "finalPriceChart",
                type: payload.pipelineType,
                newVal: ChartUtils.createNormalLineChart(impactData, xCol, yCols[payload.pipelineType], "Price", "Date"),
            })
        },

        async loadStatsChart(context, payload) {
            const xCol = "d_stay"
            const yCols = [
                "adr_nopr",
                "grouped_adr_nopr",
                "adr_pr",
                "grouped_adr_pr",
                "grouped_adr_pr_market",
                "adr_pr_ly1",
                "grouped_adr_pr_ly1",
                "grouped_adr_pr_market_ly1",
                "adr_pr_ly2",
                "grouped_adr_pr_ly2",
                "grouped_adr_pr_market_ly2",
                "adr_pr_ly3",
                "grouped_adr_pr_ly3",
                "grouped_adr_pr_market_ly3",
                "adr_pr_ly4",
                "grouped_adr_pr_ly4",
                "grouped_adr_pr_market_ly4",
                "base_price",
                "price_weighted_derived"
            ]
            const yCols2 = [
                "occupancy_perc_nopr",
                "grouped_occupancy_perc_nopr",
                "grouped_occupancy_perc_nopr_market",
                "grouped_occupancy_perc_nopr_ly1",
                "grouped_occupancy_perc_nopr_market_ly1",
                "grouped_occupancy_perc_nopr_ly2",
                "grouped_occupancy_perc_nopr_market_ly2",
                "grouped_occupancy_perc_nopr_ly3",
                "grouped_occupancy_perc_nopr_market_ly3",
                "grouped_occupancy_perc_nopr_ly4",
                "grouped_occupancy_perc_nopr_market_ly4",
                "grouped_rolling_21_occupancy_perc_nopr",
                "grouped_rolling_21_occupancy_perc_nopr_market",
                "grouped_rolling_21_occupancy_perc_nopr_ly1",
                "grouped_rolling_21_occupancy_perc_nopr_market_ly1",
                "grouped_rolling_21_occupancy_perc_nopr_ly2",
                "grouped_rolling_21_occupancy_perc_nopr_market_ly2",
                "grouped_rolling_21_occupancy_perc_nopr_ly3",
                "grouped_rolling_21_occupancy_perc_nopr_market_ly3",
                "grouped_rolling_21_occupancy_perc_nopr_ly4",
                "grouped_rolling_21_occupancy_perc_nopr_market_ly4"
            ]

            const impactData = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.stats.plot
            context.commit("resetChartOptions", {
                chart: "statsChart",
                type: payload.pipelineType,
                newVal: ChartUtils.createImpactChart(impactData, xCol, yCols, yCols2, "Date", "Price", "Price Variation"),
            })
        },


        async loadBasePriceMap(context, payload) {

            const basePriceMapData = context.rootState?.pipelineV2?.pipelineData?.[payload.pipelineType]?.baseprice?.spec?.source?.job?.spec?.parameters?.base_price_config?.trendline?.base_price_map
            context.commit("resetChartOptions", {
                chart: "basePriceMap",
                type: payload.pipelineType,
                newVal: basePriceMapData,
            })
            context.commit("resetChartOptions", {
                chart: "originalBasePriceMap",
                type: payload.pipelineType,
                newVal: basePriceMapData,
            })
        },


        async loadAllCharts(context, payload) {
            await Promise.all([
                context.dispatch("loadSeasonalityHeatmap", {pipelineType: payload.pipelineType}),
                //context.dispatch("loadBasePriceChart", {pipelineType: payload.pipelineType}),
                context.dispatch("loadEventsImpactChart", {pipelineType: payload.pipelineType}),
                context.dispatch("loadDowImpactChart", {pipelineType: payload.pipelineType}),
                context.dispatch("loadDemandImpactChart", {pipelineType: payload.pipelineType}),
                context.dispatch("loadDemandHeatmapForEverySeason", {pipelineType: payload.pipelineType}),
                context.dispatch("loadInteralMarketCorrectionImpactChart", {pipelineType: payload.pipelineType}),
                //context.dispatch("loadCompsetCorrectionImpactChart", {pipelineType: payload.pipelineType}),
                //context.dispatch("loadCompsetCorrectionChart", {pipelineType: payload.pipelineType}),
                context.dispatch("loadFinalPriceChart", {pipelineType: payload.pipelineType}),
                context.dispatch("loadStatsChart", {pipelineType: payload.pipelineType}),
                context.dispatch("loadBasePriceMap", {pipelineType: payload.pipelineType})
            ])
        },

    },

}
