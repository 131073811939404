<template>
    <v-row
        :class="{
            'using-cache': inCache,
            'card-ui': !inCache,
            'master-pipelinebind': inMaster,
        }"
    >
        <!-- <v-col class="col-12" v-if="inCache !== null && !!inCache"> -->
        <!--     <v-spacer></v-spacer> -->
        <!--     <v-chip class="ma-2" color="error" outlined> -->
        <!--         <v-icon left small> fa-regular fa-circle-dot fa-xs </v-icon> -->
        <!--         Changes Not Saved -->
        <!--     </v-chip> -->
        <!-- </v-col> -->

        <v-col class="col-12" v-if="!inMaster && pipelinesData?.test?.outdated">
            <v-alert type="warning">
                Test resource is outdated, overwrite it with the master
                pipeline!
            </v-alert>
        </v-col>

        <v-col class="col-12 my-4" v-if="!inError">
            <v-card class="pa-4">
                <v-card-title>Competitors</v-card-title>

                <v-row>
                    <v-col class="col-6">
                        <MapNew
                            style="height: 30dvh"
                            :data="competitorsMapData"
                            :mapOptions="{
                                startingView: [
                                    currentPG.spec.coordinates.x,
                                    currentPG.spec.coordinates.y,
                                ],
                                startingZoomLevel: 18,
                                clustering: false,
                            }"
                        ></MapNew>
                    </v-col>
                    <v-col class="col-6">
                        <v-data-table
                            class="card-ui"
                            dense
                            :headers="competitorsTableHeaders"
                            :items="competitorsTable"
                        >
                            <template v-slot:item.name="{ item }">
                                <v-btn
                                    :href="item.url"
                                    color="primary"
                                    text
                                    small
                                    target="_blank"
                                    >{{ item.name }}</v-btn
                                >
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col class="col-12 my-4" v-if="!inError">
            <v-card class="pa-4">
                <v-card-title>Start Day Of Week</v-card-title>

                <v-row>
                    <v-col class="col-4">
                        <v-text-field
                            readonly
                            label="Start Day Of Week"
                            value="Monday"
                            outlined
                            dense
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col class="col-12 my-4" v-if="!inError">
            <v-card class="pa-4">
                <v-card-title>Seasonality</v-card-title>

                <v-row>
                    <v-col class="col-12">
                        <NewChart
                            :option="seasonalityHeatmap"
                            :theme="darkMode ? 'dark' : 'walden'"
                            style="height: 300px"
                        ></NewChart>
                    </v-col>
                    <v-col class="col-12">
                        <v-data-table
                            class="card-ui"
                            dense
                            :headers="seasonalityTableHeaders"
                            :items="seasonalityTable"
                        ></v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col
            class="col-12 my-4"
            v-if="!inError || (inError && errorType === 'baseprice')"
        >
            <v-card class="pa-4">
                <v-card-title>Base Price</v-card-title>

                <v-row>
                    <v-col class="col-6" v-show="!inMaster">
                        <NewBasepriceModifiersEditor
                            :dataframes="
                                this.pipelinesData[this.pipelineType]?.baseprice
                                    ?.value
                            "
                            :defaultPrice="500"
                            :disabled="inMaster"
                            :propertiesDropdown="propertiesDropdown"
                            @input="
                                [
                                    (localBasePrice = $event),
                                    updatePipelineCurrentEdits({
                                        key: 'basePrice',
                                        value: $event?.modifiers?.length
                                            ? $event
                                            : null,
                                    }),
                                ]
                            "
                        >
                            <template v-slot:header-buttons>
                                <v-spacer></v-spacer>
                                <v-switch
                                    :true-value="true"
                                    :false-value="false"
                                    label="7 Days Span"
                                    v-model="min7daysSpan[pipelineType]"
                                    @change="
                                        updatePipelineCurrentEdits({
                                            key: 'basePrice7DaysSpan',
                                            value: $event,
                                        })
                                    "
                                ></v-switch>
                            </template>
                        </NewBasepriceModifiersEditor>
                        <p
                            v-show="!!invalidDateRange?.length"
                            class="red--text"
                        >
                            Invalid date range for properties:
                            {{ invalidDateRange }}
                        </p>
                    </v-col>
                    <v-col class="col-6">
                        <v-autocomplete
                            outlined
                            dense
                            :items="propertiesDropdown"
                            v-model="selectedGlobalProperties"
                            hide-details
                            label="Properties"
                            multiple
                        ></v-autocomplete>
                        <Plotly
                            :data="basePricePlotByProperty?.data"
                            :layout="basePricePlotByProperty.layout"
                        />
                        <v-col class="col-12">
                            <p>Base Price map</p>
                            <v-data-table
                                class="card-ui"
                                dense
                                :headers="basePriceMapHeader"
                                :items="basePriceArray"
                                :items-per-page="-1"
                                disable-pagination
                                hide-default-footer
                            >
                                <template
                                    v-slot:item.price="props"
                                    v-if="!inMaster"
                                >
                                    <v-edit-dialog
                                        :return-value.sync="props.item.price"
                                        @save="saveBasePriceMap()"
                                    >
                                        {{ props.item.price }}
                                        <template v-slot:input>
                                            <v-text-field
                                                @change="
                                                    props.item.price =
                                                        Number($event)
                                                "
                                                label="Edit"
                                                :value="props.item.price"
                                                single-line
                                                counter
                                            ></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                            </v-data-table>
                        </v-col>
                        <NewBasePriceDateRangeTable
                            :items="localBasePrice?.data"
                            :propertyMappings="allPropertyNamesById"
                            :minSpan7Days="min7daysSpan[pipelineType]"
                            v-model="invalidDateRange"
                        ></NewBasePriceDateRangeTable>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col class="col-12 my-4" v-if="!inError">
            <v-card class="pa-4">
                <v-card-title>Events</v-card-title>

                <v-row class="mt-2">
                    <v-col class="col-12" v-if="false">
                        <v-slider
                            :value="pipelinesData[pipelineType]?.events?.weight"
                            :max="1"
                            :min="0"
                            :disabled="inMaster"
                            :step="0.1"
                            @change="
                                [
                                    (localWeights[pipelineType].events =
                                        $event),
                                    updatePipelineCurrentEdits({
                                        key: 'eventsWeight',
                                        value: Number($event),
                                    }),
                                ]
                            "
                            :thumb-size="24"
                            label="Weight"
                            thumb-label="always"
                            ticks
                        >
                            <template v-slot:append>
                                <v-text-field
                                    :value="localWeights[pipelineType].events"
                                    hide-details
                                    outlined
                                    dense
                                    :disabled="inMaster"
                                    type="number"
                                    style="width: 100px"
                                    readonly
                                ></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>
                    <v-col class="col-12">
                        <v-autocomplete
                            outlined
                            dense
                            :items="propertiesDropdown"
                            v-model="selectedGlobalProperties"
                            hide-details
                            label="Properties"
                            multiple
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-12">
                        <NewChart
                            :option="eventsImpactChart"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col class="col-12 my-4" v-if="!inError">
            <v-card class="pa-4">
                <v-card-title>Day of Week</v-card-title>

                <v-row class="mt-2">
                    <v-col class="col-12" v-if="false">
                        <v-slider
                            :value="pipelinesData[pipelineType]?.dow?.weight"
                            :max="1"
                            :min="0"
                            hide-details
                            :step="0.1"
                            :disabled="inMaster"
                            :thumb-size="24"
                            label="Weight"
                            @change="
                                [
                                    (localWeights[pipelineType].dayOfWeek =
                                        $event),
                                    updatePipelineCurrentEdits({
                                        key: 'dowWeight',
                                        value: Number($event),
                                    }),
                                ]
                            "
                            thumb-label="always"
                            ticks
                        >
                            <template v-slot:append>
                                <v-text-field
                                    :value="
                                        localWeights[pipelineType].dayOfWeek
                                    "
                                    hide-details
                                    outlined
                                    dense
                                    :disabled="inMaster"
                                    type="number"
                                    style="width: 100px"
                                    readonly
                                ></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>
                    <v-col class="col-12">
                        <v-data-table
                            class="card-ui"
                            dense
                            :headers="dowTableHeaders"
                            :items="dowTableNormal"
                            :items-per-page="7"
                            disable-pagination
                            hide-default-footer
                        >
                            <template
                                v-slot:item.lowSeason="props"
                                v-if="!inMaster"
                            >
                                <v-edit-dialog
                                    :return-value.sync="props.item.lowSeason"
                                    @save="save(dowTableNormal)"
                                >
                                    {{ props.item.lowSeason }}
                                    <template v-slot:input>
                                        <v-text-field
                                            @change="
                                                props.item.lowSeason =
                                                    Number($event)
                                            "
                                            label="Edit"
                                            :value="props.item.lowSeason"
                                            single-line
                                            counter
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>
                            <template
                                v-slot:item.midSeason="props"
                                v-if="!inMaster"
                            >
                                <v-edit-dialog
                                    :return-value.sync="props.item.midSeason"
                                    @save="save(dowTableNormal)"
                                >
                                    {{ props.item.midSeason }}
                                    <template v-slot:input>
                                        <v-text-field
                                            @change="
                                                props.item.midSeason =
                                                    Number($event)
                                            "
                                            label="Edit"
                                            :value="props.item.midSeason"
                                            single-line
                                            counter
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>
                            <template
                                v-slot:item.highSeason="props"
                                v-if="!inMaster"
                            >
                                <v-edit-dialog
                                    :return-value.sync="props.item.highSeason"
                                    @save="save(dowTableNormal)"
                                >
                                    {{ props.item.highSeason }}
                                    <template v-slot:input>
                                        <v-text-field
                                            @change="
                                                props.item.highSeason =
                                                    Number($event)
                                            "
                                            label="Edit"
                                            :value="props.item.highSeason"
                                            single-line
                                            counter
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-col class="col-12">
                        <v-autocomplete
                            outlined
                            dense
                            :items="propertiesDropdown"
                            v-model="selectedGlobalProperties"
                            hide-details
                            label="Properties"
                            multiple
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-12">
                        <NewChart
                            style="height: 500px"
                            :option="dowImpactChart"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col class="col-12 my-4" v-if="!inError">
            <v-card class="pa-4">
                <v-card-title>Demand Maps</v-card-title>
                <v-row class="mt-2">
                    <v-col class="col-12">
                        <v-slider
                            :value="pipelinesData[pipelineType]?.demand?.weight"
                            :max="2"
                            :min="0"
                            :step="0.1"
                            @change="
                                [
                                    (localWeights[pipelineType].demandMaps =
                                        $event),
                                    updatePipelineCurrentEdits({
                                        key: 'demandMapsWeight',
                                        value: Number($event),
                                    }),
                                ]
                            "
                            :disabled="inMaster"
                            :thumb-size="24"
                            label="Weight"
                            thumb-label="always"
                            ticks
                        >
                            <template v-slot:append>
                                <v-text-field
                                    :value="
                                        localWeights[pipelineType].demandMaps
                                    "
                                    hide-details
                                    outlined
                                    dense
                                    :disabled="inMaster"
                                    type="number"
                                    style="width: 100px"
                                    readonly
                                ></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>
                    <v-col class="col-12 my-2" v-show="!inMaster">
                        <DemandMapModifier
                            @input="
                                updatePipelineCurrentEdits({
                                    key: 'demandMapsEdits',
                                    value: $event,
                                })
                            "
                            :bookDaysOptions="
                                demandHeatmapModifierOptions.bookDaysOptions
                            "
                            :seasonOptions="
                                demandHeatmapModifierOptions.seasonOptions
                            "
                        ></DemandMapModifier>
                    </v-col>
                    <v-col class="col-12" v-if="demandHeatmapLowSeason">
                        <NewChart
                            style="height: 500px"
                            :option="demandHeatmapLowSeason"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    </v-col>
                    <v-col class="col-12" v-if="demandHeatmapMidSesaon">
                        <NewChart
                            style="height: 500px"
                            :option="demandHeatmapMidSesaon"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    </v-col>
                    <v-col class="col-12" v-if="demandHeatmapHighSeason">
                        <NewChart
                            style="height: 500px"
                            :option="demandHeatmapHighSeason"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    <v-col class="col-12">
                        <v-autocomplete
                            outlined
                            dense
                            :items="propertiesDropdown"
                            v-model="selectedGlobalProperties"
                            hide-details
                            label="Properties"
                            multiple
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-12">
                        <NewChart
                            :option="demandImpactChart"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col class="col-12 my-4" v-if="!inError">
            <v-card class="pa-4">
                <v-card-title>Internal Market Correction</v-card-title>

                <v-row class="mt-2">
                    <v-col class="col-12">
                        <v-slider
                            :value="
                                pipelinesData[pipelineType]?.market_correction
                                    ?.weight
                            "
                            :max="1"
                            :min="0"
                            :disabled="inMaster"
                            :step="0.1"
                            @change="
                                [
                                    (localWeights[
                                        pipelineType
                                    ].marketCorrection = $event),
                                    updatePipelineCurrentEdits({
                                        key: 'externalMarketCorrectionWeight',
                                        value: Number($event),
                                    }),
                                ]
                            "
                            :thumb-size="24"
                            label="Weight"
                            thumb-label="always"
                            ticks
                        >
                            <template v-slot:append>
                                <v-text-field
                                    :value="
                                        localWeights[pipelineType]
                                            .marketCorrection
                                    "
                                    hide-details
                                    outlined
                                    dense
                                    :disabled="inMaster"
                                    type="number"
                                    style="width: 100px"
                                    readonly
                                ></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>
                    <v-col class="col-12">
                        <v-autocomplete
                            outlined
                            dense
                            :items="propertiesDropdown"
                            v-model="selectedGlobalProperties"
                            hide-details
                            label="Properties"
                            multiple
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-12">
                        <NewChart
                            :option="interalMarketCorrectionImpactChart"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col class="col-12 my-4" v-if="false">
            <v-card class="pa-4">
                <v-card-title>External Market Correction</v-card-title>
                <v-row>
                    <v-col class="col-12" v-show="!inMaster">
                        <v-slider :max="1" :step="0.1" :min="0"></v-slider>
                        <v-switch></v-switch>
                    </v-col>
                    <v-col class="col-12">
                        <NewChart
                            :option="compsetCorrectionImpactChart"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    </v-col>
                    <v-col class="col-12">
                        <v-autocomplete
                            label="Select Property"
                            v-model="selectedGlobalProperties"
                            dense
                            :items="propertiesDropdown"
                            outlined
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-12">
                        <NewChart
                            :option="compsetCorrectionChart"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>

        <v-col class="col-12 sticky-header-bottom" v-show="!inMaster">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="onGenerateButtonClick()"
                        >New</v-btn
                    >
                </template>
                <span
                    >Generates the pipelinebind with complete inference (won't
                    reset test until you save these changes)</span
                >
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!!invalidDateRange?.length"
                        class="mx-2"
                        @click="
                            edit({
                                psId: currentStrategyPS.metadata.id,
                                config: pipelineCurrentEditsFormatted,
                            })
                        "
                        >Recalculate & Preview</v-btn
                    >
                </template>
                <span
                    >Recalculates the pipelinebind with the edits provided and
                    previews them</span
                >
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="success"
                        class="mx-2"
                        v-bind="attrs"
                        :disabled="!!invalidDateRange?.length"
                        v-on="on"
                        v-show="inCache"
                        @click="saveTest({ psId: currentStrategyPS.metadata.id })"
                        >Save</v-btn
                    >
                </template>
                <span>Saves the changes made to the test pipelinebind</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="error"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        v-show="inCache"
                        @click="resetCache({ psId: currentStrategyPS.metadata.id })"
                        >Reset Changes</v-btn
                    >
                </template>
                <span>Resets the changes made to the test pipelinebind</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="warning"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        v-show="!inCache"
                        @click="saveMaster({ psId: currentStrategyPS.metadata.id })"
                        >Overwrite Master</v-btn
                    >
                </template>
                <span>Overwrites master with the test pipelinebind</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="default"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        v-show="!inCache"
                        @click="resetTest({ psId: currentStrategyPS.metadata.id })"
                        >Copy From Master</v-btn
                    >
                </template>
                <span>Copies the master pipelinebind to the test one</span>
            </v-tooltip>
        </v-col>

        <v-col class="col-12" v-if="!inError">
            <v-card class="pa-4">
                <v-card-title>Final Price</v-card-title>

                <v-row>
                    <v-col class="col-12">
                        <v-autocomplete
                            label="Properties"
                            v-model="selectedGlobalProperties"
                            dense
                            :items="propertiesDropdown"
                            outlined
                            multiple
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col
                        class="col-12"
                        v-if="!inMaster && !pipelineMasterInError"
                    >
                        <v-btn
                            :outlined="!showMaster"
                            color="primary"
                            class="mx-2"
                            @click="showMaster = !showMaster"
                            >Compare with Master</v-btn
                        >
                    </v-col>
                    <v-col class="col-12">
                        <NewChart
                            :option="finalPriceChart"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    </v-col>
                    <v-col class="col-12">
                        <v-btn
                            :outlined="!showAdr"
                            color="primary"
                            class="mx-2"
                            @click="showAdr = !showAdr"
                            >ADR</v-btn
                        >
                        <v-btn
                            :outlined="!showGroupedAdr"
                            color="primary"
                            class="mx-2"
                            @click="showGroupedAdr = !showGroupedAdr"
                            >Grouped ADR</v-btn
                        >
                        <v-btn
                            :outlined="!showOccupancy"
                            color="primary"
                            class="mx-2"
                            @click="showOccupancy = !showOccupancy"
                            >Occupancy</v-btn
                        >
                        <v-btn
                            :outlined="!showRollingOccupancy"
                            color="primary"
                            class="mx-2"
                            @click="showRollingOccupancy = !showRollingOccupancy"
                            >Rolling Occupancy</v-btn
                        >
                        <v-btn
                            :outlined="!showPriceWeightedDerived"
                            color="primary"
                            class="mx-2"
                            @click="
                                showPriceWeightedDerived =
                                    !showPriceWeightedDerived
                            "
                            >Show Price Weighted derived</v-btn
                        >
                        <v-btn
                            :outlined="!showBasePrice"
                            color="primary"
                            class="mx-2"
                            @click="showBasePrice = !showBasePrice"
                            >Show Base Prices</v-btn
                        >
                    </v-col>
                    <v-col class="col-12">
                        <NewChart
                            :option="statsChart"
                            :theme="darkMode ? 'dark' : 'walden'"
                        ></NewChart>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
// libraries
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import dateUtils from "@/utils/dateUtils";
import { ChartUtils } from "../../../utils/chartUtils";

// components
import NewBasePriceDateRangeTable from "./NewBasePriceDateRangeTable.vue";
import MapNew from "@/components/utils/Map.vue";
import DemandMapModifier from "./DemandMapModifier.vue";
import NewBasepriceModifiersEditor from "./NewBasepriceModifiersEditor.vue";
import { Plotly } from "vue-plotly";
import NewChart from "../../../components/utils/NewChart.vue";

export default {
    components: {
        MapNew,
        NewChart,
        Plotly,
        DemandMapModifier,
        NewBasepriceModifiersEditor,
        NewBasePriceDateRangeTable,
    },
    props: {
        pipelineType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            globalSettingsHeaders: [
                { text: "ID", value: "id" },
                { text: "Name", value: "name" },
            ],

            competitorsTableHeaders: [
                { text: "Name", value: "name" },
                { text: "Score", value: "score" },
                { text: "Review Score", value: "review_score" },
            ],

            seasonsHashMap: {
                0: "Low Season",
                1: "Mid Season",
                2: "High Season",
            },

            seasonalityTableHeaders: [
                { text: "Start Date", value: "start" },
                { text: "End Date", value: "end" },
                { text: "Season", value: "season" },
            ],

            localWeights: {
                master: {
                    dayOfWeek: null,
                    events: null,
                    demandMaps: null,
                    marketCorrection: null,
                },
                test: {
                    dayOfWeek: null,
                    events: null,
                    demandMaps: null,
                    marketCorrection: null,
                },
            },

            dowTableNormal: [],
            basePriceArrayOriginal: [],
            localBasePrice: null,

            selectedGlobalProperties: [],
            min7daysSpan: {
                master: null,
                test: null,
            },
            invalidDateRange: [],

            showAdr: false,
            showGroupedAdr: false,
            showOccupancy: false,
            showRollingOccupancy: false,
            showPriceWeightedDerived: false,
            showBasePrice: false,

            showMaster: false,
        };
    },
    watch: {
        pipelineType: function (newVal, oldVal) {
            this.dowTableNormal = structuredClone(this.dowTable);
            this.basePriceArrayOriginal = basePriceMapToArray(
                structuredClone(this.basePriceMap)
            );
            this.resetPipelineCurrentEdits();
            this.showMaster = false;
        },
    },
    computed: {
        ...mapState({
            currentPG: (state) => state.core.currentPG,
            currentStrategyPS: (state) => state.core.currentStrategyPS,
            darkMode: (state) => state.ui.darkMode,
            pipelinesData: (state) => state.pipelineV2.pipelineData,
        }),
        ...mapGetters({
            allPropertyNamesById: "core/allPropertyNamesById",
            pipelineCurrentEditsFormatted:
                "pipelineV2/pipelineCurrentEditsFormatted",
            pipelineMasterInError: "pipelineV2/pipelineMasterInError",
            pipelineTestInError: "pipelineV2/pipelineTestInError",
            errorTypeMaster: "pipelineV2/errorTypeMaster",
            errorTypeTest: "pipelineV2/errorTypeTest",
        }),
        inError() {
            if (this.pipelineType === "master") {
                return this.pipelineMasterInError;
            } else {
                return this.pipelineTestInError;
            }
        },
        errorType() {
            if (this.pipelineType === "master") {
                return this.errorTypeMaster;
            } else {
                return this.errorTypeTest;
            }
        },

        inCache() {
            // tells if we're using the cache or not
            if (this.pipelineType === "master") return null;

            return this.pipelinesData[this.pipelineType].tmp;
        },
        inMaster() {
            return this.pipelineType === "master";
        },
        propertiesDropdown() {
            return this.pipelinesData.global.predicted_property_ids.map(
                (propId) => {
                    return {
                        text: `${propId} - ${this.allPropertyNamesById[propId]}`,
                        value: propId,
                    };
                }
            );
        },
        dowTableHeaders() {
            const headers = [
                { text: "Day of the Week", value: "dayOfTheWeek" },
            ];

            const seasonLabel = {
                0: "lowSeason",
                1: "midSeason",
                2: "highSeason",
            };

            const dowMaps = this.pipelinesData[this.pipelineType].dow.map;

            dowMaps
                ?.map((dowM) => dowM.season)
                .forEach((season) => {
                    headers.push({
                        text: this.seasonsHashMap[season],
                        value: seasonLabel[season],
                    });
                });

            return headers;
        },
        demandHeatmapModifierOptions() {
            const demandMapsForEverySeason =
                this.pipelinesData[this.pipelineType].demand.map;

            const bookDaysOptions = demandMapsForEverySeason?.[0]?.map.map(
                (entry) => {
                    return {
                        text: `${entry.book_days[0]} to ${entry.book_days[1]}`,
                        value: `${entry.book_days[0]},${entry.book_days[1]}`,
                    };
                }
            );
            const seasonOptions = Object.values(
                demandMapsForEverySeason || {}
            )?.map((season) => {
                return {
                    text: this.seasonsHashMap[season.season],
                    value: Number(season.season),
                };
            });

            return {
                bookDaysOptions: bookDaysOptions,
                seasonOptions: seasonOptions,
            };
        },
        competitorsMapData() {
            const compMapData =
                this.pipelinesData[this.pipelineType]?.compset
                    ?.filter((comp) => comp.type === "active")
                    ?.map((competitor) => {
                        const color = (function (competitor) {
                            if (competitor.type !== "active")
                                return "#2b2b2b33";

                            const ranges = [
                                { color: "#ffc100", min: 0, max: 6 },
                                { color: "#ff9a00", min: 6.1, max: 8 },
                                { color: "#ff7400", min: 8.1, max: 9 },
                                { color: "#ff4d00", min: 9.1, max: 9.5 },
                                { color: "#ff0000", min: 9.6, max: 10 },
                            ];

                            for (const range of ranges) {
                                if (
                                    competitor.score >= range.min &&
                                    competitor.score <= range.max
                                ) {
                                    return range.color;
                                }
                            }
                        })(competitor);

                        return {
                            type: "marker",
                            popupHtml: `
                        <b>${competitor.name}</b><br>
                        <a href="${
                            competitor.url
                        }" target="_blank">Booking Page</a><br>
                        <b>Review Score: ${
                            competitor?.review_score || "Not Present"
                        }</b><br>
                        <b>Similarity Score: ${
                            competitor?.score || "Not Present"
                        }</b>
                    `,
                            color: color,
                            coordinates: competitor.coordinates,
                        };
                    }) || [];

            compMapData.push({
                type: "marker",
                popupHtml: `
                    <b>${this.currentPG.metadata.name}</b><br>
                `,
                color: "blue",
                coordinates: [
                    this.currentPG?.spec?.coordinates?.x,
                    this.currentPG?.spec?.coordinates?.y,
                ],
            });

            return compMapData;
        },
        competitorsTable() {
            return this.pipelinesData[this.pipelineType].compset;
        },
        dowTable() {
            const data = this.pipelinesData[this.pipelineType]?.dow?.map;

            const dowHashMap = {
                0: "Monday",
                1: "Tuesday",
                2: "Wednesday",
                3: "Thursday",
                4: "Friday",
                5: "Saturday",
                6: "Sunday",
            };

            const seasonLabel = {
                0: "lowSeason",
                1: "midSeason",
                2: "highSeason",
            };

            const result = [];

            for (let dayIndex in dowHashMap) {
                const dayOfTheWeek = dowHashMap[dayIndex];
                const dayData = {
                    dayOfTheWeek,
                };
                data?.forEach((entry) => {
                    dayData[seasonLabel[entry.season]] = entry.map[dayIndex];
                });
                result.push(dayData);
            }

            return result;
        },
        seasonalityTable() {
            const data = this.pipelinesData?.[this.pipelineType]?.seasonality;

            if (!data) return null;

            const mapValues = Object.entries(data).map(([week, season]) => {
                const weekRange = dateUtils.getWeekDates(Number(week));

                return {
                    season: season,
                    start: new Date(weekRange.start)
                        .toISOString()
                        .split("T")[0],
                    end: new Date(weekRange.end).toISOString().split("T")[0],
                };
            });

            const groupedData = [];

            let currentSeason = mapValues[0].season;
            let startDate = mapValues[0].start;
            let endDate = mapValues[0].end;

            for (let i = 1; i < mapValues.length; i++) {
                if (mapValues[i].season === currentSeason) {
                    endDate = mapValues[i].end;
                } else {
                    groupedData.push({
                        start: startDate,
                        end: endDate,
                        season: currentSeason,
                    });

                    currentSeason = mapValues[i].season;
                    startDate = mapValues[i].start;
                    endDate = mapValues[i].end;
                }
            }

            groupedData.push({
                start: startDate,
                end: endDate,
                season: currentSeason,
            });

            return (
                groupedData.map((val) => {
                    val.season = this.seasonsHashMap[val.season];
                    return val;
                }) || null
            );
        },
        basePricePlotByProperty() {
            const data = this.localBasePrice?.data;

            if (!data)
                return {
                    layout: {
                        yaxis: {
                            title: "Price",
                            showgrid: true,
                            gridcolor: "rgba(165, 161, 161, 0.27)",
                        },
                        paper_bgcolor: this.darkMode ? "#221f1c" : "#fff",
                        plot_bgcolor: this.darkMode ? "#373432" : "#fff",
                        font: {
                            color: this.darkMode ? "#ffffff" : "#333",
                        },
                        legend: {
                            orientation: "h",
                        },
                    },
                    data: null,
                };

            const traces = [];
            Object.entries(data).forEach(([propId, values]) => {
                if (this.selectedGlobalProperties?.includes(Number(propId))) {
                    traces.push({
                        mode: "lines",
                        name: `${propId} - ${this.allPropertyNamesById[propId]}`,
                        y: values.values.map((val) => Math.round(val)),
                        x: values.dates,
                    });
                }
            });

            return {
                layout: ChartUtils.createLineChartLayout(
                    "Price",
                    null,
                    null,
                    this.darkMode,
                    "h"
                ),
                data: traces,
            };
        },

        seasonalityHeatmap() {
            const chartFunc =
                this.$store.getters[
                    "pipelineV2Charts/seasonalityHeatmapFiltered"
                ];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(this.pipelineType, this.selectedGlobalProperties);
        },
        eventsImpactChart() {
            const chartFunc =
                this.$store.getters[
                    "pipelineV2Charts/eventsImpactChartFiltered"
                ];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(this.pipelineType, this.selectedGlobalProperties);
        },
        dowImpactChart() {
            const chartFunc =
                this.$store.getters["pipelineV2Charts/dowImpactChartFiltered"];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(this.pipelineType, this.selectedGlobalProperties);
        },
        demandImpactChart() {
            const chartFunc =
                this.$store.getters[
                    "pipelineV2Charts/demandImpactChartFiltered"
                ];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(this.pipelineType, this.selectedGlobalProperties);
        },
        demandHeatmapLowSeason() {
            const chartFunc =
                this.$store.getters[
                    "pipelineV2Charts/demandHeatmapLowSeasonFiltered"
                ];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(this.pipelineType, this.selectedGlobalProperties);
        },
        demandHeatmapMidSesaon() {
            const chartFunc =
                this.$store.getters[
                    "pipelineV2Charts/demandHeatmapMidSesaonFiltered"
                ];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(this.pipelineType, this.selectedGlobalProperties);
        },
        demandHeatmapHighSeason() {
            const chartFunc =
                this.$store.getters[
                    "pipelineV2Charts/demandHeatmapHighSeasonFiltered"
                ];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(this.pipelineType, this.selectedGlobalProperties);
        },
        interalMarketCorrectionImpactChart() {
            const chartFunc =
                this.$store.getters[
                    "pipelineV2Charts/interalMarketCorrectionImpactChartFiltered"
                ];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(this.pipelineType, this.selectedGlobalProperties);
        },
        compsetCorrectionImpactChart() {
            const chartFunc =
                this.$store.getters[
                    "pipelineV2Charts/compsetCorrectionImpactChartFiltered"
                ];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(this.pipelineType, this.selectedGlobalProperties);
        },
        compsetCorrectionChart() {
            const chartFunc =
                this.$store.getters[
                    "pipelineV2Charts/compsetCorrectionChartFiltered"
                ];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(this.pipelineType, this.selectedGlobalProperties);
        },
        finalPriceChart() {
            const chartFunc =
                this.$store.getters["pipelineV2Charts/finalPriceChartFiltered"];
            if (typeof chartFunc !== "function") return null;
            return chartFunc(
                this.pipelineType,
                this.selectedGlobalProperties,
                this.showMaster
            );
        },
        statsChart() {
            const chartFunc =
                this.$store.getters["pipelineV2Charts/statsChartFiltered"];
            if (typeof chartFunc !== "function") return null;

            const fieldsToShow = [];

            if (this.showAdr)
                fieldsToShow.push(
                    "adr_nopr",
                    "adr_pr",
                    "adr_pr_ly1",
                    "adr_pr_ly2",
                    "adr_pr_ly3",
                    "adr_pr_ly4"
                );

            if (this.showGroupedAdr)
                fieldsToShow.push(
                    "grouped_adr_nopr",
                    "grouped_adr_pr",
                    "grouped_adr_pr_ly1",
                    "grouped_adr_pr_ly2",
                    "grouped_adr_pr_ly3",
                    "grouped_adr_pr_ly4",
                    "grouped_adr_pr_market",
                    "grouped_adr_pr_market_ly1",
                    "grouped_adr_pr_market_ly2",
                    "grouped_adr_pr_market_ly3",
                    "grouped_adr_pr_market_ly4"
                );

            if (this.showOccupancy)
                fieldsToShow.push(
                    "grouped_occupancy_perc_nopr",
                    "grouped_occupancy_perc_nopr_ly1",
                    "grouped_occupancy_perc_nopr_ly2",
                    "grouped_occupancy_perc_nopr_ly3",
                    "grouped_occupancy_perc_nopr_ly4",
                    "grouped_occupancy_perc_nopr_market",
                    "grouped_occupancy_perc_nopr_market_ly1",
                    "grouped_occupancy_perc_nopr_market_ly2",
                    "grouped_occupancy_perc_nopr_market_ly3",
                    "grouped_occupancy_perc_nopr_market_ly4"
                );

            if (this.showRollingOccupancy)
                fieldsToShow.push(
                    "grouped_rolling_21_occupancy_perc_nopr",
                    "grouped_rolling_21_occupancy_perc_nopr_ly1",
                    "grouped_rolling_21_occupancy_perc_nopr_ly2",
                    "grouped_rolling_21_occupancy_perc_nopr_ly3",
                    "grouped_rolling_21_occupancy_perc_nopr_ly4",
                    "grouped_rolling_21_occupancy_perc_nopr_market",
                    "grouped_rolling_21_occupancy_perc_nopr_market_ly1",
                    "grouped_rolling_21_occupancy_perc_nopr_market_ly2",
                    "grouped_rolling_21_occupancy_perc_nopr_market_ly3",
                    "grouped_rolling_21_occupancy_perc_nopr_market_ly4"
                );

            if (this.showPriceWeightedDerived)
                fieldsToShow.push("price_weighted_derived");

            if (this.showBasePrice) fieldsToShow.push("base_price");

            return chartFunc(
                this.pipelineType,
                this.selectedGlobalProperties,
                fieldsToShow
            );
        },
        basePriceMapHeader() {
            const headers = [
                { text: "Property", value: "property" },
                { text: "Price", value: "price" },
            ];
            return headers;
        },
        basePriceMap() {
            const chartFunc =
                this.$store.getters["pipelineV2Charts/basePriceMap"];
            if (typeof chartFunc !== "function") return null;
            let basePriceMap = chartFunc(this.pipelineType, this.showMaster);
            return basePriceMap;
        },
        basePriceArray() {
            const chartFunc =
                this.$store.getters["pipelineV2Charts/basePriceMap"];
            if (typeof chartFunc !== "function") return null;
            let basePriceMap = chartFunc(this.pipelineType, this.showMaster);
            return basePriceMapToArray(basePriceMap);
        },
    },
    methods: {
        ...mapMutations({
            updatePipelineCurrentEdits: "pipelineV2/updatePipelineCurrentEdits",
            resetPipelineCurrentEdits: "pipelineV2/resetPipelineCurrentEdits",
        }),
        ...mapActions({
            generateNew: "pipelineV2/generateNew",
            edit: "pipelineV2/edit",
            preview: "pipelineV2/preview",
            resetCache: "pipelineV2/resetCache",
            resetTest: "pipelineV2/resetTest",
            saveTest: "pipelineV2/saveTest",
            saveMaster: "pipelineV2/saveMaster",
            createV2Strategy: "strategyNew/createV2Strategy",
        }),

        onGenerateButtonClick() {
            let originalBasePriceMap = {};
            for (let el of this.basePriceArrayOriginal) {
                originalBasePriceMap[el.property] = el.price;
            }
            let updatedBasePriceMap = {};
            for (let el of this.basePriceArray) {
                updatedBasePriceMap[el.property] = el.price;
            }

            let differences = {};
            for (let [key, value] of Object.entries(updatedBasePriceMap)) {
                if (originalBasePriceMap[key] == undefined) {
                    differences[key] = value;
                } else if (
                    originalBasePriceMap[key] != updatedBasePriceMap[key]
                ) {
                    differences[key] = value;
                }
            }
            this.generateNew({
                psId: this.currentStrategyPS.metadata.id,
                config: { base_price_map: differences },
            });
        },

        save(tableUpdated) {
            const data = structuredClone(tableUpdated);

            const dowHashMap = {
                Monday: 0,
                Tuesday: 1,
                Wednesday: 2,
                Thursday: 3,
                Friday: 4,
                Saturday: 5,
                Sunday: 6,
            };

            const seasonLabel = {
                lowSeason: 0,
                midSeason: 1,
                highSeason: 2,
            };

            const seasonToMap = {};
            Object.entries(data[0]).forEach(([key, value]) => {
                if (
                    Object.keys(seasonLabel).includes(key) &&
                    value !== undefined
                ) {
                    const seasonIndex = seasonLabel[key];
                    seasonToMap[seasonIndex] = {};
                }
            });

            data.forEach((val) => {
                const day = dowHashMap[val.dayOfTheWeek];
                delete val.dayOfTheWeek;

                Object.entries(val).forEach(([season, value]) => {
                    const s = seasonLabel[season];
                    seasonToMap[s][day] = value;
                });
            });

            this.updatePipelineCurrentEdits({
                key: "dowMaps",
                value: Object.entries(seasonToMap).map(([season, values]) => {
                    return {
                        season: Number(season),
                        map: values,
                    };
                }),
            });
        },
        saveBasePriceMap() {},
    },
    created() {
        this.dowTableNormal = structuredClone(this.dowTable);
        this.min7daysSpan = {
            test: this.pipelinesData["test"]?.baseprice?.spec?.min_span_7_days,
            master: this.pipelinesData["master"]?.baseprice?.spec
                ?.min_span_7_days,
        };

        this.localWeights.master.dayOfWeek =
            this.pipelinesData?.master?.dow?.weight;
        this.localWeights.master.events =
            this.pipelinesData?.master?.events?.weight;
        this.localWeights.master.demandMaps =
            this.pipelinesData?.master?.demand?.weight;
        this.localWeights.master.marketCorrection =
            this.pipelinesData?.master?.market_correction?.weight;

        this.localWeights.test.dayOfWeek =
            this.pipelinesData?.test?.dow?.weight;
        this.localWeights.test.events =
            this.pipelinesData?.test?.events?.weight;
        this.localWeights.test.demandMaps =
            this.pipelinesData?.test?.demand?.weight;
        this.localWeights.test.marketCorrection =
            this.pipelinesData?.test?.market_correction?.weight;
    },
};

function basePriceMapToArray(basePriceMap) {
    let arr = [];
    for (let key of Object.keys(basePriceMap)) {
        arr.push({
            property: key,
            price: basePriceMap[key],
        });
    }
    return arr;
}
</script>

<style>
.using-cache {
    background: #2f2626 !important;
}

.card-ui.master-pipelinebind {
    background: #1d2b36 !important;
}
.sticky-header-bottom {
    background: #1e1e1e !important;
    position: sticky;
    bottom: 0;
}
</style>
