<template>
  <v-container fluid>
    <v-row class="align-center">
      <v-autocomplete
        class="col-8 col-md-8 mx-auto"
        :items="fetchedUsers"
        v-model="selectedUser"
        label="Accounts"
      ></v-autocomplete>
    </v-row>
    <v-row>
      <v-text-field
        outlined
        v-model="upsellingAccessUrl"
        readonly
        class="pointer col-8 col-md-8 mx-auto"
        @click="openevent"
      ></v-text-field>
    </v-row>
    <v-divider class="my-2" />
    <v-row>
      <v-col class="col-12 col-md-12">
        <MedusaStoreCreator />
      </v-col>
    </v-row>
    <v-divider class="my-2" />
    <v-row>
      <v-col>
        <p class="text-h4">Enable upselling automations</p>
        <v-stepper v-model="step" class="col-12">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Services to enable
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              Upselling email test
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              Upselling automations setup
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <ConfigUpsellingServicesStep @next="step = 2" />
            </v-stepper-content>

            <v-stepper-content step="2">
              <TestUpsellingMailStep @next="step = 3" @back="step = 1" />
            </v-stepper-content>

            <v-stepper-content step="3">
              <ConfigUpsellingAutomationStep @next="reset" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";

import ConfigUpsellingServicesStep from "../components/ConfigUpsellingServicesStep.vue";
import TestUpsellingMailStep from "../components/TestUpsellingMailStep.vue";
import ConfigUpsellingAutomationStep from "../components/ConfigUpsellingAutomationStep.vue";
import { mapGetters } from "vuex";
import MedusaStoreCreator from "../components/MedusaStoreCreator.vue";
export default Vue.extend({
  components: {
    ConfigUpsellingServicesStep,
    TestUpsellingMailStep,
    ConfigUpsellingAutomationStep,
    MedusaStoreCreator,
  },
  name: "Upselling",
  data: function () {
    return {
      loadingData: false,
      valid: true,
      pgIdRules: [(v) => !!v || "Pg id is required"],
      dialog: false,
      alertTitle: null,
      alertLog: null,
      alertVisible: false,
      pgId: null,
      step: 1,
      fetchedUsers: [],
      selectedUser: null,
    };
  },
  methods: {
    reset() {
      this.$store.dispatch("smartpaying/reset");
      this.step = 1;
    },
    openevent() {
      if (this.generatedToken) {
        window.open(this.generatedToken, "_blank");
      }
    },
  },
  watch: {
    selectedUser: {
      async handler(newUser) {
        console.log(newUser);
        this.$store.dispatch("smartpaying/generateUpsellingToken", {
          userId: newUser,
        });
      },
    },
  },
  async mounted() {
    const users = await this.$store.dispatch("smartpaying/fetchUpsellingUsers");
    this.fetchedUsers = users;
  },
  computed: {
    upsellingAccessUrl() {
      if (this.generatedToken) {
        return this.generatedToken;
      } else {
        return "Choose a store and click 'Generate token' to get an access token";
      }
    },
    ...mapGetters({
      generatedToken: "smartpaying/generatedToken",
    }),
  },
});
</script>
<style lang="scss">
.v-card__text,
.v-card__title {
  word-break: normal;
  /* maybe !important  */
}
</style>
