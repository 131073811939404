<template>
    <v-card class="pa-4 card-ui">
        <v-card-title>Property Base Price Mappings</v-card-title>

        <v-card-subtitle><slot name="subtitle"></slot></v-card-subtitle>
        
        <v-row>
            <v-col class="col-12 col-md-6" v-for="[key, val] in Object.entries(value)" :key="key">
                <v-text-field
                    outlined
                    dense
                    :label="`${key} - ${propertiesMappings?.[key] || ''}`"
                    :value="val"
                    type="number"
                    :rules="[rules.isNumber, rules.isAboveZero]"
                    @input="[value[key] = Number($event), emits('input', value)]"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-card-actions>
            <slot name="actions"></slot>
        </v-card-actions>
    </v-card>
</template>

<script setup>
import { toRefs, onMounted, ref, defineProps, computed, defineEmits} from 'vue';

const rules = ref({
    isNumber: value => !isNaN(value) || "Value must be a number",
    isAboveZero: value => (value > 0 || value === null) || "Value must be above zero",
})

const props = defineProps({
    value: {type: Object, required: false, default: () => {}},
    propertiesMappings: {type: Object, required: false, default: () => {}}
})

const emits = defineEmits(["input"])

const {value, propertiesMappings} = toRefs(props)
</script>