<template>
    <v-card class="card-ui pa-4">
        <v-card-title>Add Property</v-card-title>

        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col class="col-12">
                    <v-text-field
                        outlined
                        dense
                        label="Name"
                        :rules="[rules.required, rules.noTrailingSpaces]"
                        v-model="selectedName"
                    ></v-text-field>
                </v-col>
                <v-col class="col-4">
                    <v-text-field
                        outlined
                        dense
                        label="Number of units"
                        :error-messages="invalidCombination"
                        v-model="selectedNumUnits"
                        type="number"
                    ></v-text-field>
                </v-col>
                <v-col class="col-4">
                    <v-text-field
                        outlined
                        dense
                        label="From offset"
                        :rules="[rules.required]"
                        type="number"
                        v-model="selectedFromOffest"
                    ></v-text-field>
                </v-col>
                <v-col class="col-4">
                    <v-text-field
                        outlined
                        dense
                        label="To offset"
                        type="number"
                        :rules="[rules.required]"
                        v-model="selectedToOffset"
                    ></v-text-field>
                </v-col>
                <v-col class="col-6">
                    <v-autocomplete
                        outlined
                        dense
                        :items="priceStrategySource"
                        label="Graph JSON"
                        v-model="selectedGraphJSON"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-6">
                    <v-autocomplete
                        outlined
                        dense
                        :items="storeComputeItems"
                        label="Force store computations"
                        v-model="selectedForceStoreComputations"
                    ></v-autocomplete>
                </v-col>
                <v-col v-if="selectedGraphJSON?.includes('IndexedPrice')" class="col-12">
                    <v-row>
                        <v-col class="col col-6">
                            <v-autocomplete
                                label="Variation Type"
                                outlined
                                dense
                                :items="[{text: 'Percentage', value: 'percentage'}, {text: 'Amount', value: 'amount'}]"
                                :rules="[rules.required]"
                                v-model="selectedPropModVarType"
                                @change="selectedPropModAmount = 0"
                            ></v-autocomplete>
                        </v-col>    
                        <v-col class="col col-6">
                            <v-text-field
                                label="Variation Amount"
                                :suffix="selectedPropModVarType === 'percentage' ? '%' : ''"
                                outlined
                                dense
                                :value="rawValueToNormal(selectedPropModAmount)"
                                @input="selectedPropModAmount = normalValueToRaw($event)"
                            ></v-text-field>
                        </v-col>
                        <v-col class="col col-6">
                            <v-autocomplete
                                label="Select a property group"
                                outlined
                                dense
                                :items="pgsDropdown"
                                v-model="selectedPropModPropGroup"
                                @change="fetchPropModifierProperties(selectedPropModPropGroup)"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col col-6">
                            <v-autocomplete
                                label="Source Property Id"
                                outlined
                                dense
                                :items="propModifierProperties"
                                :rules="[rules.required]"
                                v-model="selectedPropModSourceProp"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="col-12">
                    <v-row>                        
                        <v-col class="col-2">
                            <v-switch
                                outlined
                                dense
                                label="Visible"
                                v-model="selectedIsVisible"
                            ></v-switch>
                        </v-col>
                        <v-col class="col-2">
                            <v-switch
                                outlined
                                dense
                                label="Allotment"
                                v-model="selectedIsAllotment"
                            ></v-switch>
                        </v-col>
                        <v-col class="col-2">
                            <v-switch
                                outlined
                                dense
                                label="Is Set Prices Enabled"
                                v-model="selectedPrEnabled"
                            ></v-switch>
                        </v-col>
                        <v-col class="col-4">
                            <v-switch
                                outlined
                                dense
                                :error-messages="invalidCombination"
                                label="Import Reservations"
                                v-model="selectedImportReservations"
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="col-4">
                    <v-autocomplete
                        v-if="selectedPgCmMappingSupported"
                        outlined
                        :items="cmIdsDropdown"
                        item-text="text"
                        item-value="value"
                        clearable
                        dense
                        label="Channel Manager ID"
                        v-model="selectedCmId"
                    ></v-autocomplete>
                    <v-text-field
                        v-else
                        outlined
                        :rules="[rules.noTrailingSpaces]"
                        persistent-hint
                        dense
                        :hint="hintTextRoomsOnWritingSys"
                        clearable
                        label="Channel Manager ID"
                        v-model="selectedCmId"
                    ></v-text-field>
                </v-col>
                <v-col class="col-4">
                    <v-autocomplete
                        v-if="selectedPgCmRatesSupported"
                        outlined
                        :items="rateMasterDropdown"
                        dense
                        item-text="text"
                        item-value="value"
                        clearable
                        label="Channel Manager Rate ID"
                        v-model="selectedCmRateId"
                    ></v-autocomplete>
                    <v-text-field
                        v-else
                        outlined
                        :rules="[rules.noTrailingSpaces]"
                        persistent-hint
                        dense
                        :hint="hintTextRateMaster"
                        clearable
                        label="Channel Manager Rate ID"
                        v-model="selectedCmRateId"
                    ></v-text-field>        
                </v-col>
                <v-col class="col-4">
                    <v-autocomplete
                        label="PMS Mapping"
                        :items="pmsIdsDropdown"
                        item-text="text"
                        dense
                        item-value="value"
                        :error-messages="invalidCombination"
                        outlined
                        v-model="selectedPmsMapping"
                        v-if="selectedPgPmsMappingSupported"
                    ></v-autocomplete>
                    <v-text-field
                        label="PMS Mapping"
                        outlined
                        dense
                        :rules="[rules.noTrailingSpaces]"
                        persistent-hint
                        :hint="hintTextInsertPmsMapping"
                        :error-messages="invalidCombination"
                        v-model="selectedPmsMapping"
                        v-else
                    ></v-text-field>
                </v-col>
                <v-col class="col-6">
                    <v-autocomplete
                        dense
                        outlined
                        :items="possibleProperties"
                        :error-messages="invalidCombination"
                        v-model="selectedParentId"
                        :disabled="!selectedPgRateMasteer"
                        label="Parent ID"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-6">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-autocomplete
                                dense
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                :items="pgsDropdown"
                                label="PG ID With Parent ID"
                                v-model="selectedPgRateMasteer"
                                @change="fetchProperties"
                            ></v-autocomplete>
                        </template>
                        <span>If the parent ID of the new property is associated with another pgid, please select it</span>
                    </v-tooltip>
                </v-col>
                <v-col class="col-12">
                    <v-row>
                        <v-col class="col-6">
                            <v-textarea
                                outlined
                                dense
                                :rules="[rules.isJSON]"
                                label="Extra params"
                                v-model="selectedExtraParams"
                            ></v-textarea>
                        </v-col>
                        <v-col class="col-6">
                            <NewDynamicInputForm
                                v-model="selectedExtraParams"
                                :customSchema="!!cmPropertyExtraParamsSchema ? cmPropertyExtraParamsSchema : undefined"
                            ></NewDynamicInputForm>
                        </v-col>  
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-card-actions>
            <v-btn
                color="primary"
                @click="validate()"
            >Add New Property</v-btn>
        </v-card-actions>

        <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="confirmDialog"
            >
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                        color="primary"
                        dark
                        >Confirm adding new property</v-toolbar>
                        <v-card-text>
                            <div class="text-h4 pa-12">Are you sure you want to add the property {{ selectedName }} to pg {{ globalSelectedPropertyGroup }}</div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                color="primary"
                                @click="[addNewProperty(), dialog.value = false]"
                            >Add Property</v-btn>
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
    </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex';
import NewDynamicInputForm from '../../../components/newDynamicForm/NewDynamicInputForm.vue';

export default {
    components: { NewDynamicInputForm },
    data() {
        return {

            // default ui properties
            storeComputeItems: [
                {text: "Never", value: "never"}, 
                {text: "Always", value: "always"}, 
                {text: "User Action", value: "user_action"},
            ],

            // form fields
            selectedName: "",
            selectedNumUnits: "0",
            selectedGraphJSON: null,
            selectedIsVisible: false,
            selectedIsAllotment: false,
            selectedPrEnabled: false,
            selectedImportReservations: true,
            selectedFromOffest: "0",
            selectedToOffset: "500",
            selectedForceStoreComputations: null,
            selectedCmId: null,
            selectedCmRateId: null,
            selectedPmsMapping: null,
            selectedExtraParams: '{}',
            selectedPgRateMasteer: "",
            selectedParentId: null,

            selectedPropModVarType: null,
            selectedPropModAmount: null,
            selectedPropModSourceProp: null,

            possibleProperties: [],
            propModifierProperties: [],

            // validation
            validForm: true,
            confirmDialog: false,
            rules: {
                isJSON: value => this.isJSON(value) || "Must be valid JSON!",
                required: value => !!value || "Required!",
                noTrailingSpaces: value => {
                    if (value && (value.endsWith(" ") || value.startsWith(" "))) {
                        return "No trailing spaces allowed, remove them!"
                    }
                    return true
                },
            },
        }
    },
    watch: {
        currentPG(newVal, oldVal) {
            this.reset()
            this.selectedPgRateMasteer = String(newVal.metadata.id) 
            this.fetchProperties(newVal.metadata.id)
        }
    },
    computed: {
        ...mapState({
            priceStrategySource: state => state.mappingAndRates.priceStrategySource,
            currentPG: state => state.core.currentPG,
            currentCmId: state => state.core.currentPG.spec.channel_manager_name,
            currentPmsId: state => state.core.currentPG.spec.pms_name,
            api: state => state.api.api,
        }),
        ...mapGetters({
            cmIdsDropdown: "mappingAndRates/cmIdsDropdown",
            rateMasterDropdown: "mappingAndRates/rateMasterDropdown",
            selectedPgCmMappingSupported: "mappingAndRates/selectedPgCmMappingSupported",
            selectedPgCmRatesSupported: "mappingAndRates/selectedPgCmRatesSupported",
            selectedPgPmsMappingSupported: "mappingAndRates/selectedPgPmsMappingSupported",
            pmsIdsDropdown: "mappingAndRates/pmsIdsDropdown",
            cmPropertyExtraParamsSchema: "core/cmPropertyExtraParamsSchema",
            pgsDropdown: "core/pgsDropdown",
            globalSelectedPropertyGroup: "core/globalSelectedPropertyGroup",
        }),
        hintTextRoomsOnWritingSys() {
            return this.selectedPgCmMappingSupported ? "" : `${this.currentCmId} doesn't support the following feature, write down the room on your own!`
        },
        hintTextRateMaster(){
            return this.selectedPgCmRatesSupported ? "" : `${this.currentCmId} doesn't support the following feature, write down the rate on your own!`
        },
        hintTextInsertPmsMapping(){
            return this.selectedPgPmsMappingSupported ? "" : `${this.currentPmsId} doesn't support the following feature, write down the id on your own!`
        },
        finalProperty() {
            return {
                metadata: {
                    name: this.selectedName,
                },
                spec: {
                    property_group_id: this.currentPG.metadata.id,
                    num_units: Number(this.selectedNumUnits),
                    channel_manager_id: this.selectedCmId,
                    channel_manager_rate_id: this.selectedCmRateId,
                    set_price_from_offset: Number(this.selectedFromOffest),
                    set_price_to_offset: Number(this.selectedToOffset),
                    graph_json: this.selectedGraphJSON?.split(",") || null,
                    force_store_computations: this.selectedForceStoreComputations,
                    parent_id: Number(this.selectedParentId) || null,
                    
                    is_allotment: this.selectedIsAllotment,
                    is_visible: this.selectedIsVisible,
                    extra_params: JSON.parse(this.selectedExtraParams),

                    is_set_prices_enabled: this.selectedPrEnabled,
                    is_import_reservation_enabled: this.selectedImportReservations,
                }
            }
        },
        newPropertyModifier() {
            return {
                metadata: {},
                spec: {
                    property_id: null,
                    name: this.toKebabCase(`${this.selectedPropModVarType}-${this.currentPG.metadata.name}-${this.selectedName}`),
                    modifier_id: 40,
                    variation_params: {
                        variations: {
                            "*": {
                                variation_type: this.selectedPropModVarType,
                                variation_value: Number(this.selectedPropModAmount),
                                source_property_id: Number(this.selectedPropModSourceProp),
                            }
                        }
                    },
                    date: null
                }
            }
        },
        invalidCombination() {
            const errors = []
            if (!!this.selectedPmsMapping && this.selectedImportReservations && !Number(this.selectedNumUnits)) {
                errors.push("You cannot have a pms mapping and import reservation enabled with num units at 0!")
            }

            if (!!this.selectedParentId && this.selectedImportReservations) {
                errors.push("You cannot have parent id and import reservations both enabled!")
            }

            return errors
        }
    },
    methods: {
        ...mapActions({
            addProperty: "core/addProperty",
            newError: "alertSystem/newError"
        }),
        reset() {
            this.selectedName = ""
            this.selectedNumUnits = "0"
            this.selectedGraphJSON = null
            this.selectedIsVisible = false
            this.selectedIsAllotment = false
            this.selectedPrEnabled = false
            this.selectedImportReservations = true
            this.selectedFromOffest = "0"
            this.selectedToOffset = "500"
            this.selectedForceStoreComputations = null
            this.selectedCmId = null
            this.selectedCmRateId = null
            this.selectedPmsMapping = null
            this.selectedExtraParams = '{}'
            this.selectedPgRateMasteer = ""
            this.selectedParentId = null
        },
        isJSON(value) {
            try {
                const jsonobj = JSON.parse(value)
                return true
            } catch (error) {
                return false
            }
        },
        async validate() {
            await this.$refs.form.validate()

            if (this.validForm) {
                this.confirmDialog = true
            }
        },
        async fetchProperties(pgId) {
            try {
                const jobRes = await this.api.get( "core/v1", "/propertygroup/" + pgId + "/property",
                    {spec: [""],}, {}, {}
                );
                
                this.possibleProperties = []
                jobRes.items.forEach(prop => {
                    const newProp = {
                        text: `${prop.metadata.id} - ${prop.metadata.name}`,
                        value: prop.metadata.id,
                    }
                    this.possibleProperties.push(newProp)
                })

            } catch (error) {
                this.newError({
                    message: "Error fetching the properties for the selected pg."
                })
            }
        },
        rawValueToNormal(rawValue) {
            const type = this.selectedPropModVarType
            switch (type) {
                case "percentage":
                    return `${Math.round(rawValue * 100)}`
                case "amount":
                    return rawValue
            }
        },
        normalValueToRaw(normalValue) {
            const value = Number(normalValue)
            const type = this.selectedPropModVarType
            switch (type) {
                case "percentage":
                    return Number(Number(value / 100).toFixed(2))
                case "amount":
                    return Number(value)
            }
        },
        async fetchPropModifierProperties(pgId) {
            const api = this.$store.state.api.api

            try {
                const resp = await api.get("core/v1", `/propertygroup/${pgId}/property`, {}, {}, {})
                
                this.propModifierProperties = resp.items.map(prop => {
                    if (prop.metadata.id !== this.selectedPropertyId){
                        return {
                            text: `${prop.metadata.id} - ${prop.metadata.name}`,
                            value: prop.metadata.id,
                        }
                    }
                })

            } catch (error) {
                this.$store.dispatch("alertSystem/newError", {
                    message: `Error getting the properties for the selected pg: ${error}`
                })
            }
        },
        toKebabCase(str) {
            return str && str
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(x => x.toLowerCase())
            .join('-');
        },
        async addNewProperty() {
            await this.addProperty({
                pgId: this.currentPG.metadata.id, 
                config: this.finalProperty, 
                pmsMapping: this.selectedPmsMapping, 
                propertyModifier: this.newPropertyModifier,
            })
        }
    },
    created() {
        this.selectedPgRateMasteer = String(this.currentPG.metadata.id) 
        this.fetchProperties(this.currentPG.metadata.id)
        this.selectedPropModPropGroup = this.currentPG.metadata.id
        this.fetchPropModifierProperties(this.selectedPropModPropGroup)
    }
}
</script>