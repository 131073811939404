<template>
    <v-card class="card-ui pb-1" :class="{ 'grey darken-3': darkMode }">
        <v-card-title>{{ bundle }}</v-card-title>
        <v-card flat class="mx-4 card-ui" v-if="!!lifecycle">
            <v-alert border="left" :color="color" class="d-flex justify-center align-center"
                style="text-align: center; height: 120px;">
                <h2> {{ lifecycle?.metadata.name }}</h2>
            </v-alert>
        </v-card>
        <v-card flat class="mx-4 card-ui" v-else>
            <v-alert outlined color="red" class="d-flex justify-center align-center"
                style="text-align: center; height: 120px">
                <h2>Property Group State NOT SET</h2>
            </v-alert>
        </v-card>

        <v-autocomplete class="px-4" outlined label="Select New Lifecycle" clearable dense hide-details
            :items="lifecycleDropdownParsed" v-model="selectedNewLifecycleId"></v-autocomplete>

        <v-card-actions class="my-2 ml-2">
            <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="!selectedNewLifecycleId">
                        Update
                    </v-btn>
                </template>
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar color="primary" dark>
                            Confirm lifecycle update for {{ bundle }}
                        </v-toolbar>

                        <v-card-text>
                            <div class="text-h4 pt-6">
                                Are you sure you want to update bundle {{ bundle }} from {{ lifecycle?.metadata?.name ||
                                    "NOT SET" }} to {{
        `${selectedNewLifecycle?.metadata.name}/${selectedNewLifecycle?.metadata.label}?` }}
                            </div>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn color="primary" @click="[updateLifecycleState(), dialog.value = false]">Confirm</v-btn>
                            <v-btn text color="red" @click="dialog.value = false">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { formatDate } from "@/utils/dateUtils"

export default {
    props: ["lifecycle", "bundle"],
    data() {
        return {
            selectedNewLifecycleId: null,
        }
    },
    computed: {
        ...mapState({
            darkMode: state => state.ui.darkMode,
            username: state => state.auth.username,
            lifecycleHistoryHeadersByBundle: state => state.lifecycle.lifecycleHistoryHeadersByBundle,
            lifecycleProgressionByBundle: state => state.lifecycle.lifecycleProgressionByBundle,
        }),
        ...mapGetters({
            lifecycleStatesByBundle: "lifecycle/lifecycleStatesByBundle",
            globalSelectedPgId: "core/globalSelectedPgId",
            currentHistoryByBundle: "lifecycle/currentHistoryByBundle"
        }),
        color() {
            return (this.lifecycle?.metadata.lifecycleStateName == 'live' || this.lifecycle?.metadata.lifecycleStateName == 'trial') && this.lifecycle?.metadata.lifecycleStateLabel !== 'maintenance' ? 'green' : 'red'
        },
        lifecycleDropdown() {
            let allowedLifecyclesParsed = []

            if (!this.lifecycle) {
                if (['smartpricing', 'market-intelligence'].includes(this.bundle)) {
                    allowedLifecyclesParsed = this.lifecycleStatesByBundle[this.bundle].filter(lifecycle => lifecycle.metadata.name === "created" && lifecycle.metadata.label === "default")
                } else {
                    allowedLifecyclesParsed = this.lifecycleStatesByBundle[this.bundle]
                }
            } else {
                const allowedStates = this.lifecycle.spec.childs

                allowedLifecyclesParsed = this.lifecycleStatesByBundle[this.bundle].filter(val => {
                    return allowedStates.includes(val.metadata.name)
                }).filter(val => {
                    const lifecycleFullname = val.metadata.name + '/' + val.metadata.label
                    return this.lifecycle.metadata.name !== lifecycleFullname
                })
            }

            if (this.lifecycleProgressionByBundle[this.bundle]) {
                allowedLifecyclesParsed = allowedLifecyclesParsed.sort((ls1, ls2) => {
                    const ls1index = this.lifecycleProgressionByBundle[this.bundle].indexOf(ls1.metadata.name)
                    const ls2index = this.lifecycleProgressionByBundle[this.bundle].indexOf(ls2.metadata.name)

                    if (ls1index === -1 && ls2index === -1) return 0
                    if (ls1index === -1) return 1
                    if (ls2index === -1) return -1

                    return ls1index - ls2index
                })
            }

            return allowedLifecyclesParsed
        },
        lifecycleDropdownParsed() {
            return this.lifecycleDropdown.map(val => ({
                text: `${val.metadata.name}/${val.metadata.label}`,
                value: val.metadata.id
            }))
        },
        selectedNewLifecycle() {
            return this.lifecycleDropdown.find(val => val.metadata.id === this.selectedNewLifecycleId)
        }
    },
    methods: {
        formatDate,
        updateLifecycleState() {
            const newLifecycle = {
                "metadata": {
                    "lifecycleStateId": this.selectedNewLifecycle?.metadata.id,
                    "name": this.selectedNewLifecycle?.metadata.name,
                    "label": this.selectedNewLifecycle?.metadata.label,
                    "propertyGroupId": this.globalSelectedPgId,
                    "bundle": this.bundle,
                    "setBy": this.username,
                }
            }

            this.$store.dispatch("lifecycle/updateLifecycle", {
                newLifecycle: newLifecycle,
            })
        }
    }
}
</script>
