<template>
    <v-card
        class="card-ui"
    >
        <v-toolbar
            color="primary"
            dark
            class="px-8"
        >
            <v-toolbar-title>Mappings Configuration</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                @click="$emit('closeDialog')"
                plain
                color="black"
            >
                Close
            </v-btn>
        </v-toolbar>
        
        <v-card-text
            class="pa-4"
        >
           <!--  <v-autocomplete
                label="Select property"
                outlined
                clearable
                dense
                :items="currentProperties"
                :item-text="property => `${property.metadata.id} - ${property.metadata.name}`"
                :item-value="property => property"
                v-model="selectedProperty"
                @change=""
            ></v-autocomplete>
 -->
            <v-tabs
                v-model="tab"
                background-color="transparent"
                grow
                v-if="!loading"
            >
                <v-tab
                    v-for="item in items"
                    :key="item"
                >
                    {{ item }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" v-if="!loading">
                <v-tab-item key="Write" class="pa-4">
                    <MappingsWriteTab />
                    <PropertyRatesTable />
                </v-tab-item>
                <v-tab-item key="Read" class="pa-4">
                    <MappingsReadTab />
                </v-tab-item>
            </v-tabs-items>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import MappingsReadTab from "../components/MappingsReadTab.vue"
import MappingsWriteTab from "../components/MappingsWriteTab.vue"
import PropertyRatesTable from '../components/PropertyRatesTable.vue';

export default {
    props: ["property"],
    components: {MappingsReadTab, MappingsWriteTab, PropertyRatesTable},
    data() {
        return {
            items: ["Write", "Read"],
            tab: "",
        }
    },
    computed: {
        ...mapState({
            currentProperties: state => state.core.currentProperties,
            loading: state => state.mappingAndRates.loading,
        })
    },
    methods: {
        ...mapActions({
            checkMappingSupport: "mappingAndRates/checkMappingSupport",
            selectProperty: "mappingAndRates/selectProperty",
        })
    },
    async created() {
        await this.checkMappingSupport()
        await this.selectProperty({property: this.property})
    },
}
</script>