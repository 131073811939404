<template>
  <v-card class="pa-0" elevation="0" color="transparent" v-if="pipelinBindsReady == true && !!globalSelectedPgId">
    <v-tabs v-model="tab" background-color="deep-purple accent-4" color="white" center-active>
      <v-tab v-for="item in [ 'general settings', 'config', 'preprocess charts', 'predict charts']" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>    

    <GeneralSettings v-if="tab == 0" :key="tabsKey" ></GeneralSettings>

    <v-row v-if="tab == 1">
      <v-col class="col-12 col-md-3">
        <h3 class="overline cyan pa-0 pl-4 mt-2"> Preprocess </h3>
        <PipelineBind 
          v-for="pipelineBind in currentPipelineBinds" 
          :pipelineBind="pipelineBind" 
          v-if="pipelineBind.resource.metadata.labels.includes('preprocess')"
          @openPipelineBindEditor="showPipelineBindEditor = true"
        ></PipelineBind>
      </v-col>

      <v-col class="col-12 col-md-3">
        <h3 class="overline green accent-4 pa-0 pl-4 mt-2"> Baseprice </h3>
        <v-card tile class="mt-2 card-ui elevation-2">
          <v-toolbar dense elevation="0" class="card-ui" style="border-bottom: 1px solid #3a3a3a;">
            <v-card-title class="ml-0 pl-0 overline">
              {{`baseprice.standard.${$store.state.core.currentPG.metadata.id}`}}
            </v-card-title>
          </v-toolbar>

          <v-card-text>
            <v-row style="min-height: 80px;"> 
              <v-col class="col-11">
                <v-chip small class="mt-1 ml-2" color="green accent-4">
                  <v-icon x-small class="pr-2">fa-solid fa-tag</v-icon><b>baseprice</b>
                </v-chip>
                <v-chip small class="mt-1 ml-2" color="red">
                  <v-icon x-small class="pr-2">fa-solid fa-tag</v-icon><b>master</b>
                </v-chip>
                </v-col>
              <v-col class="col-1">

              </v-col>
            </v-row>
          </v-card-text>


          <v-card-actions :class="{'grey darken-3' : darkMode}">
            <!-- EDIT --> 

            <v-dialog
              transition="dialog-bottom-transition"
              fullscreen
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                v-bind="attrs" 
                v-on="on" 
                :disabled="blockBasePrice"
                color="pink" fab x-small
                class="elevation-0">
                  <v-icon small>fa-solid fa-edit</v-icon>
                </v-btn> 
              </template>
              <template v-slot:default="dialog">
                <BasePriceEditor @closeDialog="dialog.value = false" @close="dialog.value = false" />
              </template>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>   

      <v-col class="col-12 col-md-3">
        <h3 class="overline blue pa-0 pl-4 mt-2"> Predict </h3>
        <PipelineBind 
          v-for="pipelineBind in currentPipelineBinds" 
          :pipelineBind="pipelineBind" 
          @openPipelineBindEditor="showPipelineBindEditor = true"
          v-if="pipelineBind.resource.metadata.labels.includes('predict') && !pipelineBind.resource.metadata.labels.includes('expertuser')"
        ></PipelineBind>
      </v-col>  

      <v-col class="col-12 col-md-3">
        <h3 class="overline purple pa-0 pl-4 mt-2"> Expertuser </h3>
        <PipelineBind 
          v-for="pipelineBind in currentPipelineBinds" 
          :pipelineBind="pipelineBind" 
          v-if="pipelineBind.resource.metadata.labels.includes('expertuser') && !pipelineBind.resource.metadata.labels.includes('preview')"
        ></PipelineBind>
      </v-col>                
    </v-row>

    <StrategyChartsTabs v-if="pipelinBindsReady && tab == 2" :key="tabsKey" component="preprocess"></StrategyChartsTabs>

    <StrategyChartsTabs v-if="pipelinBindsReady && tab == 3" :key="tabsKey" component="predict"></StrategyChartsTabs>     

    <v-row v-if="tab == 1">
      <v-col class="col-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              color="primary"
              v-bind="attrs"
              v-on="on" 
              small
              @click="magicWandConfirm()" 
              fab
            >
              <v-icon>fa-solid fa-wand-magic-sparkles</v-icon>
            </v-btn>
          </template>
          <span>Create auto-strategy</span>
        </v-tooltip>
      </v-col>

      <v-col class="col-1" v-if="isPredictCronsDisabled">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              color="error"
              v-bind="attrs"
              v-on="on" 
              small
              @click="deleteConfirm()" 
              fab
            >
              <v-icon>fa-solid fa-radiation</v-icon>
            </v-btn>
          </template>
          <span>DELETE V1</span>
        </v-tooltip>
      </v-col>
      

      <!--v-col class="col-1" v-if="false">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              color="error"
              v-bind="attrs"
              :loading="loadingConversionToV2"
              v-on="on" 
              @click="convertStrategyConfirm()"
              small
              fab
            >
              <v-icon>fa-solid fa-radiation</v-icon>
            </v-btn>
          </template>
          <span>Convert pipeline to V2</span>
        </v-tooltip>
      </v-col-->
    </v-row>
      
    <ConfirmDialog ref="confirm" />

    <v-dialog
      v-model="showPipelineBindEditor"
      hide-overlay
      fullscreen
      scrollbar
      persistent
      transition="dialog-bottom-transition"
      style="height: 100%;
      overflow-y: hidden;"
    >     
      <v-card class="card-ui pa-0">  
        <v-toolbar dense elevation="0" :class="{'grey darken-3': darkMode}">
          <v-card-title class="overline ml-0 pl-0">
            <v-icon small class="mr-2">fa-solid fa-rocket</v-icon>
            <b class="grey--text darken-6">PipelineBind</b> Editor
          </v-card-title>   
          <v-spacer></v-spacer>
          <div>
            <v-btn color="grey" fab x-small class="mt-0 ml-6 elevation-0" @click="closeEditor()">
              <v-icon small>fa-solid fa-xmark</v-icon>
            </v-btn>
          </div>               
        </v-toolbar>

        <PipelineBindEditor
          v-if="showPipelineBindEditor"
          @close="closeEditor"
        />
      </v-card>
    </v-dialog> 
  </v-card>
</template>

<script>
// @ is an alias to /src
import PipelineBind from '../components/PipelineBind'
import PipelineBindEditor from '../components/PipelineBindEditor'
import StrategyChartsTabs from '../components/StategyChartsTabs'
import ConfirmDialog from '@/components/utils/ConfirmDialog'
import BasePriceEditor from "../components/BasePriceEditor.vue"
import { mapActions, mapGetters, mapState} from 'vuex'
import GeneralSettings from './GeneralSettings.vue'

export default {
  name: 'Builder',
  components: {
    PipelineBind, PipelineBindEditor, StrategyChartsTabs, ConfirmDialog, BasePriceEditor, GeneralSettings
  },
  data() {
    return {
      tab: 1,
      showPipelineBindEditor: false,
      tabsKey: 0,
      pipelinBindsReady: false,
      fetchingData: false,
      loadingConversionToV2: false,
    }
  },
  watch: {
    async globalSelectedPgId (newVal, oldVal) {
      if (this.$store.state.strategyNew.version === 1) {
        this.load()
      }
    }
  },
  computed: {
    ...mapState({
      darkMode: state => state.ui.darkMode,
      blockBasePrice: state => state.basePrice.blockBasePrice,
      currentPG: state => state.core.currentPG,
      currentStrategyPS: (state) => state.core.currentStrategyPS,
      strategy: state => state.pipelineV1.currentStrategy,
      currentPipelineBinds: state => state.pipelineV1.currentPipelineBinds,
    }),
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId",
      globalSelectedPropertyGroup: "core/globalSelectedPropertyGroup",
      currentLifecycleStateByBundle: "lifecycle/currentLifecycleStateByBundle",
    }),
    isPredictCronsDisabled() {
      let currentState = this.currentLifecycleStateByBundle?.['smartpricing']
      let isPredictCronDisabled = currentState?.spec?.fields?.predict?.price?.cron === false
      return isPredictCronDisabled
    }
  },
  methods: {
    ...mapActions({
      getBasePrice: "basePrice/getBasePrice",
      fetchGeneralSettings: "pipelineV1/fetchGeneralSettings",
      getBasePriceHistory: "basePrice/getBasePriceHistory",
      newWarning: "alertSystem/newWarning",
      magicWand: "pipelineV1/magicWand",
      strategyV1toV2: "strategyNew/strategyV1toV2",
      deleteV1: "strategyNew/deleteV1"
    }),
    async convertStrategyConfirm() {

      let options = { cardTextClass: "pa-4 error accent-3--text", color: "error", confirmText: "Convert"}
      let title = "Confirm conversion to pipeline V2";
      let message = `
      <strong style="text-align:center">WARNING</strong> <br>
      <p style="text-align:center">The conversion will delete every data about the current pipelinebinds. Are you sure you want to continue?</p>`;

      if (await this.$refs.confirm.open(title, message, options) ) {
        this.loadingConversionToV2 = true
        await this.strategyV1toV2({psId: this.currentStrategyPS.metadata.id})      
        this.loadingConversionToV2 = false
      }
    },
    async magicWandConfirm() {
      let options = { cardTextClass: "pa-4 primary accent-3--text", color: "primary", confirmText: "Confirm"}
      let title = "Confirm creation auto strategy";
      let message = `
      <strong style="text-align:center">WARNING</strong> <br>
      <p style="text-align:center">This will create the pipelinebinds automatically. Are you sure you want to continue?</p>`;

      if (await this.$refs.confirm.open(title, message, options) ) {
        await this.magicWand({psId: this.currentStrategyPS.metadata.id})     
      }
    },
    async deleteConfirm() {
      let options = { cardTextClass: "pa-4 error accent-3--text", color: "error", confirmText: "Confirm"}
      let title = "Do you confirm the deletion of V1 strategy?";
      let message = `
      <strong style="text-align:center">WARNING</strong> <br>
      <p style="text-align:center">This will DELETE every resource related to the v1 stretegy. Are you sure you want to continue?</p>`;

      if (await this.$refs.confirm.open(title, message, options) ) {
        await this.deleteV1({psId: this.currentStrategyPS.metadata.id})      
      }
    },
    async closeEditor(){
      let showPipelineBindEditor = false;
      var frontEndCode = this.$store.state.editor.codeToRender;
      //check if original YAML was modified and save the response in state.editor.codeHasChanged
      this.$store.dispatch('editor/checkOriginalCode', {frontEndCode: frontEndCode});

      if(this.$store.state.editor.codeHasChanged == true){
        showPipelineBindEditor = true;

        var options = { cardTextClass: "pa-4 orange accent-3--text", color: "orange darken-3", confirmText: "Close"}
        var title = "Confirm close";
        var message =  '<strong style="text-align:center">WARNING</strong><br><p  style="text-align:center"><strong>There are unsaved changes in the strategy. Do you want to close the editor without saving?<strong>';

        if (await this.$refs.confirm.open(title, message, options) ) {
          showPipelineBindEditor = false; //close editor if user confirms
          await this.$store.dispatch('editor/cleanCodeToRender', {}); //clean codeToRender
          await this.$store.dispatch('editor/unSetOriginalCode', {}); //reset original code
        }   
      }
      this.showPipelineBindEditor = showPipelineBindEditor; 
      this.$store.commit("pipelineV1/resetSelectedPipelineBind")
    },

    async fetch (psId) {
      await this.$store.dispatch('pipelineV1/fetchAll', {
        psId: psId,
      });
      if(this.currentPipelineBinds.length > 0){
      }      
    },
    async load() {
      this.pipelinBindsReady = false;
      if (!!this.globalSelectedPgId) {
        await this.fetchGeneralSettings({psId: this.currentStrategyPS.metadata.id })
        await this.fetch(this.currentStrategyPS.metadata.id)
        await this.getBasePrice({psId: this.currentStrategyPS.metadata.id })
        await this.getBasePriceHistory({psId: this.currentStrategyPS.metadata.id })
      }
      this.pipelinBindsReady = true;
    }
  },

  async mounted () {
    // fetch all the general info shared between pgs
    await Promise.all([
      this.$store.dispatch("pipelineV1/fetchConfigMaps", {}),
      this.$store.dispatch("pipelineV1/fetchPipelines", {}),
      this.$store.dispatch("pipelineV1/fetchMarkets", {}),
    ])
    
    await this.load()
  }
}
</script>

