<template>
    <v-container fluid class="pa-4">
        <v-card  class="card-ui pa-4">
            <v-card-text>
                <v-row>
                    <v-col class="col-12">
                        <h3>Instructions</h3>
                        <p>
                            This page allows you to scrape a hotel page. You can select the operations you want to perform and the Booking ID or Booking URL of the hotel.
                        </p>
                        <p>
                            Order of operations:
                            <ul>
                                <li>Scrape the hotel info and wait one minute so the operations are performed</li>
                                <li>Scrape preview and detailed prices as required</li>
                            </ul>
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="col-10">
                        <v-text-field v-model="idOrUrl" label="Booking ID or Booking URL" outlined hint="85378 or https://www.booking.com/hotel/it/garni-villa-maria.it.html"
                        :rules="[rules.bookingUrlOrIdValid, rules.noTrailingSpaces]"></v-text-field>
                    </v-col>
                    <v-col class="col-2">
                        <v-btn @click="idOrUrl = pgBookingId" v-if="!!globalSelectedPgId">Copy current PG Booking ID</v-btn>
                    </v-col>
                    <v-col class="col-12">
                        <h3> Job operations</h3>
                        <v-checkbox v-model="jobs.info.enabled" label="Info"></v-checkbox>
                        <v-checkbox v-model="jobs.preview.enabled" label="Preview prices"></v-checkbox>
                        <v-checkbox v-model="jobs.detailed.enabled" label="Detailed prices"></v-checkbox>
                        <v-checkbox v-model="jobs.review.enabled" label="Review"></v-checkbox>
                        <v-switch v-model="hp" inset :label="`${hp ? 'HP' : 'LP'}`"></v-switch>
                    </v-col>
                    <v-col class="col-12">
                        <v-btn color="success" block @click="launchJob">Launch job</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
// @ is an alias to /src
import SelectPgAlert from '@/components/utils/SelectPgAlert'
import { mapActions, mapGetters, mapState } from 'vuex'
import { isBookingUrlValid, fixBookingUrl } from '@/utils/booking.js'
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'Hotel',
    components: {
        SelectPgAlert,
    },
    data: function (argument) {
        return {
            idOrUrl: "",
            hp: true,
            jobs: {
                info: {
                    enabled: false,
                    type: "Info",
                },
                preview: {
                    enabled: false,
                    type: "PreviewPrice",
                },
                detailed: {
                    enabled: false,
                    type: "DetailedPrice",
                },
                review: {
                    enabled: false,
                    type: "Review",
                },
            },
            rules: {
                noTrailingSpaces: value => {
                    if (value && (value.endsWith(" ") || value.startsWith(" "))) {
                        return "No trailing spaces allowed, remove them!"
                    }
                    return true
                },
                bookingUrlOrIdValid: value => {
                    if (!value) {
                        return "Booking ID or Booking URL is required"
                    }

                    // booking id
                    if (!isNaN(value)) {
                        return true
                    }

                    // booking url
                    if (isBookingUrlValid(value)) {
                        return true
                    } else {
                        return "The provided Booking ID or Booking URL is not valid, please check it"
                    }
                },
            },
        }
    },
    watch: {
    },
    computed: {
        ...mapState({
            pgBookingId: state => state.core.currentPG.spec.booking_id,
        }),
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
        }),
    },
    methods: {
        ...mapActions({
        }),
        async launchJob() {
            let actionsToCall = []

            if (!this.idOrUrl) {
                this.$store.dispatch("alertSystem/newWarning", {
                    message: `ID or URL is required`
                })
                return
            }

            if (isNaN(this.idOrUrl) && !isBookingUrlValid(this.idOrUrl)) {
                this.$store.dispatch("alertSystem/newWarning", {
                    message: `The provided Booking ID or Booking URL is not valid, please check it`
                })
                return
            }

            if (!Object.values(this.jobs).some(job => job.enabled)) {
                this.$store.dispatch("alertSystem/newWarning", {
                    message: "Please select at least one job"
                })
                return
            }

            const sessionId = uuidv4()

            let target = null
            if (isBookingUrlValid(this.idOrUrl)) {
                const url = fixBookingUrl(this.idOrUrl)
                await this.$store.dispatch("scraper/search", {
                    query: {
                        words: url,
                        limit: 1,
                    },
                    cb: function (res) {
                        target = res.results[0].booking_id
                    }.bind(this)
                })
            } else {
                target = +this.idOrUrl
            }

            for (let job in this.jobs) {
                if (this.jobs[job].enabled == true) {
                    actionsToCall.push(this.$store.dispatch('scraper/submit', {
                        job: {
                            "apiVersion": "scraper/v2",
                            "kind": "Request",
                            "metadata": {
                                "tenant": "sp",
                                "namespace": "Booking",
                                "type": this.jobs[job].type,
                                "channel": this.hp ? "HP" : "LP",
                                "sessionId": sessionId,
                            },
                            "spec": {
                                "targets": [target]
                            }
                        },
                        cb: function (res) {
                            this.$store.dispatch("alertSystem/newInfo", {
                                message: `${JSON.stringify(res)}`
                            })
                        }.bind(this)
                    }))
                }
            }

            Promise.all(actionsToCall)
                .then((value) => { })
                .catch((error) => {
                    this.$store.dispatch("alertSystem/newError", {
                        message: `Error while performing the scraping operations: ${error}`
                    })
                })
        }
    },
    async created() {
    }
}
</script>
