<template>
  <div class="home">
    <v-container fluid>      
      <v-card class="card-ui">
        <v-card-text>
          <v-row>
            <v-col class="col-10">
              <v-text-field
                dark
                v-model="search"
                append-icon="mdi-magnify"
                :label="'Search in ' + holidays.length + ' rows'"
                solo-inverted
                dense
                class="mb-6 card-ui"
                single-line
                hide-details
              ></v-text-field>       
            </v-col>   
            <v-col class="col-2">
              <v-btn style="width: 100%;" color="cyan" @click="addHoliday"> ADD </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            :search="search"
            dense
            :headers="aggregatedDataHeaders"
            :items="holidays"
            :items-per-page="10"
            :footer-props="{
              itemsPerPageOptions: [10, 100, 1000, 5000],
              showFirstLastPage: true,
            }"
            sort-by="holiday_name"
            class="elevation-0 card-ui"
            loading-text="Loading... Please wait"
            style="cursor: pointer"
            @click:row="handleHolidayClick"
          >

            <template v-slot:item.has_translations="{ item }">
                <v-chip
                  :color="translationsColor(item.has_translations)"
                  dark
                >
                {{ item.has_translations }}
                </v-chip>
            </template>
            <template v-slot:item.has_translation_en="{ item }">
                <v-chip
                  :color="translationsColor(item.has_translation_en)"
                  dark
                >
                {{ item.has_translation_en }}
                </v-chip>
            </template>
            <template v-slot:item.has_translation_de="{ item }">
                <v-chip
                  :color="translationsColor(item.has_translation_de)"
                  dark
                >
                {{ item.has_translation_de }}
                </v-chip>
            </template>
            <template v-slot:item.has_translation_it="{ item }">
                <v-chip
                  :color="translationsColor(item.has_translation_it)"
                  dark
                >
                {{ item.has_translation_it }}
                </v-chip>
            </template>
            <template v-slot:item.has_translation_default="{ item }">
                <v-chip
                  :color="translationsColor(item.has_translation_default)"
                  dark
                >
                {{ item.has_translation_default }}
                </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>      
    </v-container>
    <v-dialog v-model="showHolidayConfigDialog" fullscreen style="overflow-x: hidden;">
      <v-card class="card-ui" v-if="showHolidayConfigApplySpinner == true" style="text-align: center;">
        <v-progress-circular
          style="margin-top: 40vh;"
          :size="70"
          :width="7"        
          indeterminate
          color="primary"
        ></v-progress-circular>
        <h3>
          Loading
        </h3>
      </v-card>            
      <div v-if="keyIndex == 1"> 
      <!-- Delete -->
      <v-dialog v-model="askDeleteHoliday" width="500">
        <div class="pa-0">
          <v-card-text class="pa-0">
            <v-alert type="warning">
              Are you sure you want to delete this Holiday?
            </v-alert>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-btn style="width: 50%;" color="blue" @click="askDeleteHoliday = false"> Cancel </v-btn>
            <v-btn style="width: 50%;" color="red" @click="deleteHoliday"> Delete </v-btn>
          </v-card-actions>
        </div>
      </v-dialog>      
      <!-- Loader -->

      <!-- Add date mini dialog -->
      <v-dialog v-model="showAddDialog" width="400">
        <v-card class="elevation-0 card-ui" style="text-align: center; background: rgba(0,0,0,0);">
          <v-card-text>
            <v-date-picker
              v-model="dateToAdd"
              dark
              color="blue"
            ></v-date-picker>
          </v-card-text>
          <v-card-actions style="text-align: center;">
            <v-btn style="width: 100%" @click="addDate">Add {{dateToAdd}}</v-btn>
          </v-card-actions>
        </v-card>        
      </v-dialog>

      <v-card class="card-ui" v-if="selectedHoliday !== null">        
          <v-alert
            v-if="containsDuplicatedHolidays"
            border="left"
            type="error"
            elevation="1"
            prominent
          >
            <v-row align="center">
              <v-col class="grow">
                Looks like some rows are duplicated. You won't be able to submit until you fix it.
              </v-col>
              <v-col class="shrink">
                <!-- <v-btn>Delete duplicates</v-btn> -->
              <v-dialog
                v-model="deleteDuplicatesDialog"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                  >
                    Delete duplicates
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    Duplicated rows
                  </v-card-title>

                  <v-card-text>
                    <v-data-table
                      v-model="selectedToDelete"
                      :headers="headersDuplicated"
                      :items="duplicatedHolidays"
                      item-key="holidaylinkid"
                      show-select
                      group-by="readableDate"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="deleteDuplicatesDialog = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="deleteDuplicates"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              </v-col>
            </v-row>
          </v-alert>
        <v-toolbar min-height='120px' dense elevation="0" :class="{'grey darken-4': darkMode}">
          <v-card-title class="overline ml-0 pl-0">
            <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
            <b class="grey--text darken-6">Holiday Config</b>
        </v-card-title>

        <v-col class="col-2 mt-10">
          <v-text-field class="mt-6" :disabled="!isNewHoliday" solo v-model="selectedHoliday.holiday_name" label="Name" outlined dense></v-text-field>           
        </v-col>
          
        <v-col v-for="language of supportedLanguages" :key="language" class="col-1 mt-10">
          {{language}}
          <v-text-field
            v-model="selectedHoliday.translations[language]"
            @change="selectedHoliday.translations[language] = $event !== '' ? $event : null">
          </v-text-field> 
        </v-col>

        <v-spacer></v-spacer>
          <v-text-field
            v-model="searchDates"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        <v-spacer></v-spacer>

          <v-btn color="grey" fab x-small class="elevation-0 mr-2" @click="showHolidayConfigDialog = false">
            <v-icon small>fa-solid fa-close</v-icon>
          </v-btn>  
          <v-btn color="red" fab x-small class="elevation-0 mr-2" @click="askDeleteHoliday = true">
            <v-icon small>fa-solid fa-trash</v-icon>
          </v-btn>
          <v-btn color="cyan" fab x-small class="elevation-0 mr-2" @click="saveHoliday" :disabled="containsDuplicatedHolidays">
            <v-icon small>fa-solid fa-save</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pa-0 card-ui">
          <v-row>
            <v-col class="col-2" :class="{'grey darken-4': darkMode}" style="min-height: 95vh;" v-if="yearToShow !== null">
              <v-btn class="mb-6 mt-3" color="blue" style="width: 100%" text @click="showAddDialog = true"> ADD DATE </v-btn>          
              <v-tabs v-model="dateToShow" :background-color="darkMode ? 'grey darken-4 pa-0' : ''" color="yellow" vertical>
                <v-tab v-for="item in holidayMap[yearToShow].dates" :key="item.holidaydate">
                  {{ item.holidaydate }}
                </v-tab>
              </v-tabs>
              <v-btn class="mb-6 mt-6" color="red" style="width: 100%" text @click="deleteDate()"> REMOVE SELECTED DATE </v-btn>                  
            </v-col>
            <v-col class="col-10">

              <v-row class='mr-2 mt-2'>
                <v-col>
                  <v-tabs v-model="yearToShow" background-color="card-ui" color="yellow">
                    <v-tab  v-for="item in holidayMap" :key="item.year">
                      {{ item.year }}
                    </v-tab>
                  </v-tabs>
                </v-col>
                
                <v-dialog
                  v-model="dialog"
                  v-if="showNewLinkButton"
                  max-width="700px"
                >

                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="ma-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      New Link
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-text>
                      <v-container>
                        <h2>Valid combinations</h2>
                        <ul>
                          <li>Pg_id and Weight</li>
                          <li>Country, Poi and Weight</li>
                          <li>Country, State, Poi and Weight</li>
                        </ul>
                        <br>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-select class=" pb-0"
                              outlined dense label="Country" 
                              :items="$store.state.graph.country"
                              :item-text="value => value.metadata.id"
                              :item-value="value => value.metadata.id"
                              v-model="editedItem.country"
                              :clearable="true"
                            ></v-select>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-select class="col2 pb-0"
                              outlined dense label="State"
                              :disabled="editedItem.country == null"
                              :items="$store.state.graph.statesFor[editedItem.country]"
                              v-model="editedItem.state"
                              :clearable="true"
                            ></v-select>
                            
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                             <v-select class="col2 pb-0"
                              outlined dense label="POI"
                              :items="$store.state.graph.poi.map((p) => { return p.metadata.id })"            
                              v-model="editedItem.attr1"
                              :clearable="true"
                            ></v-select>

                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.pgid"
                              label="Custom to pgid"
                              @change="editedItem.pgid = $event !== '' ? $event : null"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.value"
                              label="Weight"
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-alert
                        v-if="isEditedElementAlreadyPresent"
                        dense
                        border="left"
                        colored-border
                        type="error"
                        elevation="1"
                      >
                        Seems like this holiday already exists
                      </v-alert>
                    </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!isEditedElementValid"
                      @click="addHolidayDateLink(yearToShow, dateToShow)"
                    >
                      Add
                    </v-btn>
                  </v-card-actions>
                </v-card>
                </v-dialog>
              </v-row>

              <div v-if="yearToShow !== null">
              <div v-for="(hd, indexDate) in holidayMap[yearToShow].dates" class="mt-3">                
                <div v-if="indexDate == dateToShow" class="mt-6">
                <v-data-table
                  :headers="headers"
                  :items="hd.holidayLink"
                  :search="searchDates"
                >

                <template v-slot:item.country="{ item }">
                  <v-col>
                    <v-select class="col-6 pb-0"
                      outlined dense label="Location" 
                      :items="$store.state.graph.country"
                      :item-text="value => value.metadata.id"
                      :item-value="value => value.metadata.id"
                      v-model="item.country"
                      :clearable="true"
                    ></v-select>
                  </v-col>
                </template>
                
                <template v-slot:item.state="{ item }">
                  <v-col>
                    <v-select class="col2 pb-0"
                      outlined dense label="State"
                      :disabled="item.country == null"
                      :items="$store.state.graph.statesFor[item.country]"
                      v-model="item.state"
                      :clearable="true"
                    ></v-select>
                  </v-col>
                </template>

                <template v-slot:item.attr1="{ item }">
                  <v-col>
                    <v-select class="col2 pb-0"
                      outlined dense label="POI"
                      :items="$store.state.graph.poi.map((p) => { return p.metadata.id })"            
                      v-model="item.attr1"
                      :clearable="true"
                    ></v-select>
                  </v-col>
                </template>

                <template v-slot:item.pgid="{ item }">
                  <v-col>
                    <v-text-field class="col2 pb-0"
                      outlined dense label="Custom to PG"
                      v-model="item.pgid"
                      @change="hdl.pgid = $event !== '' ? $event : null"
                    ></v-text-field>
                  </v-col>
                </template>
                
                <template v-slot:item.value="{ item }">
                  <v-col>
                    <v-text-field class="col2 pb-0"
                      outlined dense label="Value"
                      type="number"
                      v-model="item.value"
                    ></v-text-field>
                  </v-col>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn
                    class="mx-2 mt-1" fab x-small dark color="red"
                    @click="deleteHolidayDateLink(item)"
                    >
                    <v-icon dark>
                      fa-solid fa-trash
                    </v-icon>
                  </v-btn>

                </template>
                
                </v-data-table>

                </div>
              </div>
              </div>
            </v-col>
            </v-row>      
        </v-card-text>         
      </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// @ is an alias to /src

export default {
  name: 'Holiday',
  components: {},
  data: function (argument) {
    return {
      keyIndex: 0,
      holidayMap: [], //{}, [ { year: 2022, dates: []}, ]

      supportedLanguages: ["en","it","de","default"],

      yearsTab: [],
      datesTab: [],
      showAddDialog: false,
      dateToAdd: (new Date()).toISOString().split('T')[0],

      datesToShowMap: {},
      dateToShow: null,

      deleteDuplicatesDialog: false,
      selectedToDelete: [],

      yearsToShowMap:  {},
      yearToShow: null,
      
      holiday: {
        holidayName: null,
        holidayId: null,
        holidayDates: []
      },
      showHolidayConfigApplySpinner: false,
      askDeleteHoliday: false,
      search: '',
      searchDates: '',
      dateMenus: {

      },
      dateMenu: false,
      isNewHoliday: true,
      showHolidayConfigDialog: false,
      selectedHoliday: null,
      selectedHolidayDates: [],
      holidaysForPg: [],
      holidays: [],
      deletedDates: [],
      aggregatedDataHeaders: [ 
        {
          text: 'Label',
          align: 'start',
          sortable: true,
          value: 'holiday_name',
        },
        {
          text: 'Has At Leat One Translation',
          align: 'start',
          sortable: true,
          value: 'has_translations' 
        },
        {
          text: 'Has English Translation',
          align: 'start',
          sortable: true,
          value: 'has_translation_en' 
        },
        {
          text: 'Has German Translation',
          align: 'start',
          sortable: true,
          value: 'has_translation_de' 
        },
        {
          text: 'Has Italian Translation',
          align: 'start',
          sortable: true,
          value: 'has_translation_it' 
        },
        {
          text: 'Has Default Translation',
          align: 'start',
          sortable: true,
          value: 'has_translation_default' 
        }
      ],
      dialog: false,
      headers: [
        {
          text:'country',
          value:'country'  
        },
        {
          text:'state',
          value:'state'  
        },
        {
          text:'poi',
          value:'attr1'  
        },
        {
          text:'pg_id',
          value:'pgid'  
        },
        {
          text:'weight',
          value:'value'  
        },
        {
          text:'actions',
          value:'actions'  
        }
      ],
      headersDuplicated: [
        {
          text:'country',
          value:'country'  
        },
        {
          text:'state',
          value:'state'  
        },
        {
          text:'poi',
          value:'attr1'  
        },
        {
          text:'pg_id',
          value:'pgid'  
        },
        {
          text:'weight',
          value:'value'  
        },
        {
          text:'date',
          value:'readableDate'  
        }
      ],

      editedItem: {
        country: null,
        state: null,
        attr1: null,
        pgid: null,
        value: null,
      },

      defaultItem: {
        country: null,
        state: null,
        attr1: null,
        pgid: null,
        value: null,
      }

    }
  },
  watch: {
    showHolidayConfigDialog (newVal, oldVal) {
      if (newVal == false) {
        this.keyIndex = 0
      } else {
        
      }
    },
  },
  computed: {
    ...mapState({
      darkMode: state => state.ui.darkMode,
    }),
    duplicatedHolidays(){
      let arr = []
      for(const year in this.holidayMap){
        for(const day in this.holidayMap[year].dates){
          arr.push(...this.getDuplicatedHolidayRows(year, day, this.holidayMap[year].dates[day].holidaydate, this.holidayMap[year].dates[day].holidayLink))
        }
      }
      return arr
    },
    containsDuplicatedHolidays(){
      for(const year of this.holidayMap){
        for(const day of year.dates){
          if(this.hasDuplicatedHolidayRows(day.holidayLink)){
            return true
          }
        }
      }
      return false
    },
    isEditedElementAlreadyPresent(){
      const entries = Object.entries(this.editedItem)
      return this.holidayMap[this.yearToShow]
        ?.dates[this.dateToShow]
        ?.holidayLink
        .some(l => entries.every(([k,v]) => {
          if(k === "value") return true
          return v == l[k]
        }))
    },
    isEditedElementValid() {
      /*
        the date is considered valid if it has the weight and one between:
        -pg_id
        -country && state
        -country && poi
      */
      let isValid =
        (
          ( this.editedItem.pgid != null ) ||
          ( this.editedItem.country != null && this.editedItem.attr1 != null )
        ) && (
          this.editedItem.value != null
        )
      return isValid && !this.isEditedElementAlreadyPresent
    },
    showNewLinkButton() {
      if ( this.yearToShow == undefined ) {
        return false
      }
      return this.holidayMap[this.yearToShow].dates.length > 0
    },
    countries() {
      let res = new Set()
      for ( let index in this.holidayMap[this.yearToShow]?.dates ) {
        if ( index == this.dateToShow ) {
            let holidayLinks = this.holidayMap[this.yearToShow].dates[index].holidayLink
            for ( let hl of holidayLinks ) {
              res.add(hl.country)
            }
        } else {
            continue
        }
      }
      return [...res]
    },
    pois() {
      let res = new Set()
      for ( let index in this.holidayMap[this.yearToShow]?.dates ) {
        if ( index == this.dateToShow ) {
            let holidayLinks = this.holidayMap[this.yearToShow].dates[index].holidayLink
            for ( let hl of holidayLinks ) {
              res.add(hl.attr1)
            }
        } else {
            continue
        }
      }
      return [...res]
    },
    pgs() {
      let res = new Set()
      res.add('null')
      for ( let index in this.holidayMap[this.yearToShow]?.dates ) {
        if ( index == this.dateToShow ) {
            let holidayLinks = this.holidayMap[this.yearToShow].dates[index].holidayLink
            for ( let hl of holidayLinks ) {
              res.add(hl.pgid)
            }
        } else {
            continue
        }
      }
      return [...res]
    }
  },
  methods: {
    async deleteHoliday () {
      if (this.selectedHoliday.holiday_id == null) {
        alert("You cannot delete this holiday because is not saved yet")
        return
      }
      await this.$store.dispatch('graph/deleteHoliday', {holiday_id: this.selectedHoliday.holiday_id}) 
      this.showHolidayConfigDialog = false
      this.askDeleteHoliday = false
      this.getMany()  
    },
    translationsColor(value) {
      if ( value == true ) {
        return "success"
      } else {
        return "red darken-1"
      }
    },
    async saveHoliday () {
      try {
        let errors = []
        this.keyIndex = 0
        this.showHolidayConfigApplySpinner = true
        let rows = []
        if (this.selectedHoliday.holiday_name == null || this.selectedHoliday.holiday_name == '') {
          errors.push("Holiday name cannot be null")
        }
        const holidayToInsert = {holidayName: this.selectedHoliday.holiday_name, holidayTranslations: this.selectedHoliday.translations, holidayDates: []}
        for (const yearIndex in this.holidayMap) {
          if (this.holidayMap[yearIndex].dates.length == 0) {
            //errors.push("Holiday must contain at least one date")
            //break
            continue
          }
          for (const dateIndex in this.holidayMap[yearIndex].dates) {
            this.holidayMap[yearIndex].dates[dateIndex].holidayLink.forEach((link) => {
              link.location = null
              if (link.country == null && link.pgid == null) {
                errors.push('Country or pgid must be setted ' + this.holidayMap[yearIndex].dates[dateIndex].holidaydate + ' ' + link.location)
              }
              if (link.attr1 == null && link.pgid == null) {
                errors.push('Poi or pgid must be setted ' + this.holidayMap[yearIndex].dates[dateIndex].holidaydate + ' ' + link.location)
              }    
              if (link.value == null || link.value == '') {
                errors.push('Value must be a number ' + this.holidayMap[yearIndex].dates[dateIndex].holidaydate + ' ' + link.location)
              }                            
              if (link.country !== null && link.country !== undefined) {
                link.location = link.country
              }
              if (link.state !== null && link.state !== undefined) {
                link.location +=  '_' + link.state
              }
            })
            const holidayDate = {
              holidayDate: this.holidayMap[yearIndex].dates[dateIndex].holidaydate,
              holidayLink: this.holidayMap[yearIndex].dates[dateIndex].holidayLink
            }  
            holidayToInsert.holidayDates.push(holidayDate)            
          }          
        }
        if (errors.length > 0) {
          //alert("Holiday not valid: \n" + errors.join('\n'))
          this.showHolidayConfigApplySpinner = false
          this.showHolidayConfigDialog = false

          this.$store.dispatch("alertSystem/newError", {
            title: "Error while saving the Holiday",
            message: errors.join('\n'),
          })

          return

        } else if ( holidayToInsert.holidayDates.length == 0 ) {
          this.showHolidayConfigApplySpinner = false
          this.showHolidayConfigDialog = false

          this.$store.dispatch("alertSystem/newError", {
            title: "Error while saving the Holiday",
            message: ["There are no dates to save"].join('\n'),
          })
          return

        } else {
          rows.push(holidayToInsert)
          let response = await this.$store.dispatch('graph/saveHoliday', rows)
        
          if (response.error) {
            this.$store.dispatch("alertSystem/newError", {
              title: "Error while saving the Holiday",
              message: response.error,
            })
          } else {
            this.$store.dispatch("alertSystem/newSuccess", {
              title: "Holiday saved",
            })
          }

          this.showHolidayConfigApplySpinner = false
          this.showHolidayConfigDialog = false
          await this.getMany()
        }
      } catch (error) {
        alert(error.message)
        this.showHolidayConfigApplySpinner = false
        this.showHolidayConfigDialog = false
      }
    },
    sortHolidayMap() {
      for ( let i = 0 ; i < this.holidayMap.length ; i++) {
        let sorted = this.holidayMap[i].dates.sort( (a,b) => {
            if ( a.holidaydate < b.holidaydate ){
              return -1;
            }
            if ( a.holidaydate > b.holidaydate ){
              return 1;
            }
            return 0;  
        }) 
        this.holidayMap[i].dates = sorted
      }
    },
    addHoliday () {
      this.selectedHoliday = {holiday_name: null, holiday_id: null, translations:{}}
      for ( let language of this.supportedLanguages) {
        this.selectedHoliday.translations[language] = null
      }
      this.holidayMap = [{year: (new Date()).toISOString().split('T')[0].split('-')[0], dates: [] }]
      this.yearToShow = null
      this.showHolidayConfigDialog = true
      this.keyIndex = 1
      this.selectedHolidayDates = []
      this.isNewHoliday = true
    },
    addHolidayDateLink (yearToShow, indexDate) {
      this.holidayMap[yearToShow].dates[indexDate].holidayLink.unshift(
        this.editedItem
      )
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = false
    },
    deleteDuplicates(){
      const isEqual = (holidayToCheck) => {
        return (element) => {
          if(holidayToCheck.holidaylinkid){
            return holidayToCheck.holidaylinkid == element.holidaylinkid
          }
          return Object.keys(element).every(e => element[e] == holidayToCheck[e])
        }
      }

      for(const d of this.selectedToDelete){
        const yearToShow = d.year
        const dateToShow = d.date
        delete d.year
        delete d.date
        delete d.readableDate

        const index = this.holidayMap[yearToShow].dates[dateToShow].holidayLink.findIndex(isEqual(d))
        this.holidayMap[yearToShow].dates[dateToShow].holidayLink.splice(index, 1)
      }
      this.selectedToDelete = []
      // this.deleteDuplicatesDialog = false
    },
    deleteHolidayDateLink (item) {
      let index = this.holidayMap[this.yearToShow].dates[this.dateToShow].holidayLink.indexOf(item)
      this.holidayMap[this.yearToShow].dates[this.dateToShow].holidayLink.splice(index, 1)
    },    
    deleteDate () {
      this.holidayMap[this.yearToShow].dates.splice(this.dateToShow,1)
    },
    addDate () {
      let found = false
      let foundIndex = 0
      const year = this.dateToAdd.split('-')[0]
      for (let h of this.holidayMap) {
        if (h.year == year) {
          found = true
          foundIndex = this.holidayMap.indexOf(h)
        }
      }
      if (found == false) {
        this.holidayMap.push({year: year, dates: [ {holidaydate: this.dateToAdd, holidayLink: []} ] })
      } else {
        if (this.holidayMap[foundIndex].dates.filter((d) => { return d.holidaydate == this.dateToAdd}).length == 0) {
          this.holidayMap[foundIndex].dates.push({
            holidaydate: this.dateToAdd,
            holidayLink: []
          })
        }
      }
    },
    async fetchHolidayDates (holidayId) {
      this.yearToShow = null
      this.showHolidayConfigApplySpinner = true
      const holidayMap = []
      const holidaysdates = await this.$store.dispatch('graph/describeHoliday', {
        holiday_id: holidayId
      })      
      for (const hd of holidaysdates.holidayDates) {
        hd.holidaydate = hd.holidaydate.split('T')[0]
        for (const hl of hd.holidayLink) {
          const splittedLoc = hl.location?.split('_')
          hl.country = splittedLoc?.[0]
          hl.state = splittedLoc?.[1]
          hl.county = splittedLoc?.[2]
          hl.city = splittedLoc?.[3]
        }
        const year = hd.holidaydate.split('T')[0].split('-')[0]
        const date = hd.holidaydate.split('T')[0]
        
        let found = false
        let foundIndex = 0
        for (var y of holidayMap) {
          if (y.year == year) {
            found = true
            foundIndex = holidayMap.indexOf(y)
          }
        }
        if (found == false) {
          holidayMap.push({year: year, dates: []})
          holidayMap[holidayMap.length - 1].dates.push(hd)    
        } else {
          holidayMap[foundIndex].dates.push(hd)      
        } 
      }
      this.holidayMap = holidayMap
      this.showHolidayConfigApplySpinner = false
      this.keyIndex = 1
      this.sortHolidayMap()

    },
    async handleHolidayClick (row) {
      this.isNewHoliday = false
      this.selectedHoliday = row
      this.showHolidayConfigDialog = true
      if ( this.selectedHoliday.translations == undefined ) {
        this.selectedHoliday.translations = {}
        for ( let language of this.supportedLanguages ) {
          if ( this.selectedHoliday.translations[language] == undefined ) {
            this.selectedHoliday.translations[language] = null
          }          
        }
      }
      await this.fetchHolidayDates(row.holiday_id)
    },
    async getMany () {
      let holidays = await this.$store.dispatch('graph/getHolidays')
      holidays = holidays.map( (a) => { 
        a.has_translations = a.translations != null && (a.translations.en != null || a.translations.de != null || a.translations.it != null || a.translations.default != null ),
        a.has_translation_en = a.translations?.en != null
        a.has_translation_de = a.translations?.de != null
        a.has_translation_it = a.translations?.it != null
        a.has_translation_default = a.translations?.default != null

        return a 
      })
      this.holidays = holidays
    },
    close () {
      this.dialog = false
      this.searchDates = null
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    getRowHash(r){
      return `${r.country || null}_${r.state || null}_${r.attr1 || null}_${r.pgid || null}`
    },
    getDuplicatedHolidayRows(year, date, readableDate, arr){
      const hashMap = {}
      for(const o of arr){
        const hash = this.getRowHash(o)
        if(!hashMap[hash]){
          hashMap[hash] = []
        }
        const obj = structuredClone(o)
        obj.year = year
        obj.date = date
        obj.readableDate = readableDate
        hashMap[hash].push(obj)
      }
      return Object.values(hashMap).filter(a => a.length > 1).flat()
    },
    hasDuplicatedHolidayRows(arr){
      const hashMap = {}
      for(const o of arr){
        const hash = this.getRowHash(o)
        if(hashMap[hash]){
          return true
        }
        hashMap[hash] = true
      }
      return false
    }
  },
  
  mounted () {
    this.getMany()
    this.$store.dispatch('graph/prefetch', {})
  },
  beforeMount () {}
}
</script>
