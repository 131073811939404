export default {
    namespaced: true,

    state: {
        currentPgHotels: null,
    },
    actions: {
        async fetchCurrentPgHotels(context, payload) {
            const api = context.rootState.api.api
            try {
                const path = `/${payload.pmsName}/hotels/${payload.pgId}`
                const response = await api.get("integrations/v2", path, {}, {}, {})
                const responseError = response?.response?.data?.error

                if (!responseError) {
                    context.commit("resetStateArray", { state: "currentPgHotels", values: response })
                }
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching hotels: ${error}`,
                    timeout: -1,
                }, { root: true })
            }
        },
        async deleteReservationsSubscription(context, payload) {
            const api = context.rootState.api.api
            try {
                const path = `/${payload.pmsName}/deletesubscription/${payload.pgId}/${payload.hotelId}`
                const response = await api.get("integrations/v2", path, {}, {}, {})
                const responseError = response.response.data.error

                if (!responseError) {
                    context.dispatch("fetchCurrentPgHotels", { pmsName: payload.pmsName, pgId: payload.pgId })
                } else {
                    context.dispatch("alertSystem/newError", {
                        message: `Error deleting reservation subscription: ${responseError}`,
                        timeout: -1,
                    }, { root: true })
                }
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error deleting reservation subscription: ${error}`,
                    timeout: -1,
                }, { root: true })
            }
        },
        async deleteAllReservationsSubscription(context, payload) {
            const api = context.rootState.api.api
            try {
                const path = `/${payload.pmsName}/deletesubscription/${payload.pgId}`
                const response = await api.get("integrations/v2", path, {}, {}, {})
                const responseError = response.response.data.error

                if (!responseError) {
                    context.dispatch("fetchCurrentPgHotels", { pmsName: payload.pmsName, pgId: payload.pgId })
                } else {
                    context.dispatch("alertSystem/newError", {
                        message: `Error deleting all reservation subscriptions: ${responseError}`,
                        timeout: -1,
                    }, { root: true })
                }
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error deleting all reservation subscriptions: ${error}`,
                    timeout: -1,
                }, { root: true })
            }
        },
    },
    mutations: {
        resetStateArray(state, payload) {
            state[payload.state] = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state[payload.state].push(val)
                })
            }
        },
    },
}
