import { v4 as uuidv4 } from 'uuid';

/**
 * Store for auth status
 */

export default {
    namespaced: true,

    state: {
        // selection
        currentPG: null,
        currentPS: null,
        currentPSProperties: [],
        currentPSPropertyGroups: [],
        currentPgCountry: null,
        currentPgLanguage: null,
        currentLifecycleStatusHistory: [],
        currentProperties: [],
        currentUsers: [],
        currentParent: null,
        currentChildren: null,
        currentPropertiesIncludingChildren: [],
        currentHubspotDeal: null,

        // property types list - stays the same always
        propertyTagsList: [
            "room",
            "family-room",
            "apartment",
            "suite",
            "junior suite",
            "villa",
            "house",
            "penthouse",
        ],

        // lifecycle states list
        lifecycleStatesList: [],
        propertyGroupsStatesList: [],

        // currencies list
        currenciesList: [],

        // property groups list
        propertyGroupsList: [],
        // property sets list
        propertySetsList: [],
        

        // partners lists
        partnerList: [],
        pmsList: [],
        cmList: [],

        //
        selectedLosProperties: [],

        currentPropertySets: [],
        currentStrategyPS: null,
        currentPhysicalPS: null,
        currentPMSPS: null,
        currentCHMPS: null,
        currentPMSPSPropertygroups: null,
        currentCHMPSPropertygroups: null,
    },

    getters: {
        // selected property group

        globalSelectedPgId(state) { // selected pg id
            return state.currentPG?.metadata?.id || null
        },

        globalSelectedPsId(state) { // selected ps id
            return state.currentPS?.metadata?.id || null
        },
        
        globalSelectedPropertyGroup(state) { // selected pg id + name
            return !!state.currentPG ? `${state.currentPG?.metadata?.id} - ${state.currentPG?.metadata?.name}` : null
        },

        allPropertiesById(state, getters) { // properties including children of selected pg by prop id
            const hashMap = {}
            state.currentProperties?.forEach(prop => {
                hashMap[prop.metadata.id] = prop
            })

            state.currentPropertiesIncludingChildren?.forEach(prop => {
                hashMap[prop.metadata.id] = prop
            })

            return hashMap
        },

        allPropertyNamesById(state) { // property names including children of selected pg by prop id
            const hashMap = {}
            state.currentProperties.forEach(prop => {
                hashMap[prop.metadata.id] = prop.metadata.name
            })
            state.currentPropertiesIncludingChildren?.forEach(prop => {
                hashMap[prop.metadata.id] = prop.metadata.name
            })
            return hashMap
        },

        currentPropertiesDropdown(state) { // current pg properties dropdown
            return state.currentProperties?.map(prop => {
                return {
                    text: `${prop.metadata.id} - ${prop.metadata.name}`,
                    value: prop.metadata.id,
                }
            }) || []
        },

        currentPropertiesIncludingChildrenDropdown(state) { // current pg properties including children dropdown
            return state.currentPropertiesIncludingChildren?.map(prop => {
                return {
                    text: `${prop.metadata.id} - ${prop.metadata.name}`,
                    value: prop.metadata.id,
                }
            }) || []
        },

        currentCurrency(state, getters) { // selected pg currency
            return getters.currencyById[state.currentPG?.spec?.currency_id] || null
        },

        currentPms(state, getters) { // selected pg pms
            return getters.dataByPmsId[state.currentPG?.spec?.pms_name]
        },

        currentCm(state, getters) { // selected pg cm
            return getters.dataByCmId[state.currentPG?.spec?.channel_manager_name]
        },

        pmsPropertyGroupExtraParamsSchema(state, getters) { // selected pg pms property group extra params schema
            return getters.dataByPmsId[state.currentPG?.spec?.pms_name]?.spec?.property_group_extra_params_schema
        },

        cmPropertyGroupExtraParamsSchema(state, getters) { // selected pg cm property group extra params schema
            return getters.dataByCmId[state.currentPG?.spec?.channel_manager_name]?.spec?.property_group_extra_params_schema
        },

        cmPropertyExtraParamsSchema(state, getters) {// selected pg cm property extra params schema
            return getters.dataByCmId[state.currentPG?.spec?.channel_manager_name]?.spec?.property_extra_params_schema
        },

        currentUsersDropdown(state) { // current users dropdown
            return state.currentUsers?.map(val => {
                return {
                    text: `${val.spec.user_id} - ${val.spec.username}`,
                    value: String(val.spec.user_id),
                }
            }) || null
        },

        // ---
        // property group

        pgNameById(state, getters) { // property gropu name by pg id
            const hashMap = {}
            state.propertyGroupsList.forEach(val => {
                hashMap[val.metadata.id] = val.metadata.name
            })
            return hashMap
        },

        pgPmsAndCmById(state, getters) {
            const hashMap = {}
            state.propertyGroupsList.forEach(val => {
                hashMap[val.metadata.id] = {
                    'pms': val.spec.pms_name,
                    'pms_id': val.spec.pms_id,
                    'cm': val.spec.channel_manager_name,
                    'cm_id': val.spec.channel_manager_id,

                }
            })
            return hashMap
        },

        propertyGroupsDataById(state, getters) { // property group data by pg id
            const hashMap = {}
            state.propertyGroupsList.forEach(val => {
                hashMap[val.metadata.id] = val
            })
            return hashMap
        },

        pgsDropdown(state, getters) { // property groups dropdown
            return state.propertyGroupsList.map(val => {
                return {
                    text: `${val.metadata.id} ${val.metadata.name}`,
                    value: String(val.metadata.id),
                }
            })
        },

        pgsListWithLifecycleStatus(state, getters) { // property groups info with lifecycle
            return state.propertyGroupsList.sort((a, b) => (a.metadata.id - b.metadata.id)).map(val => {
                let value = getters.lifecycleStatusByPgId[val.metadata.id]
                if (value?.lifecycleStateName && value?.lifecycleStateLabel) {
                    value = value.lifecycleStateName + "/" + value.lifecycleStateLabel
                }
                const fullStatus = value || ""
                //const fullStatus = getters.lifecycleStatusByPgId[val.metadata.id]?.metadata?.name || ""
                const temp = fullStatus.split("/")
                const statusType = temp[0] || ""
                const statusLabel = temp[1] || ""

                return {
                    id: String(val.metadata.id),
                    name: val.metadata.name,
                    status: fullStatus,
                    statusType: statusType,
                    statusLabel: statusLabel,
                    value: String(val.metadata.id),
                    searchValue: `${String(val.metadata.id)} ${val.metadata.name} - ${fullStatus}`,
                    textColor: (statusType == 'live' || statusType == 'trial') && statusLabel !== 'maintenance' ? 'green--text' : 'red--text'
                }
            })
        },

        psList(state, getters) {
            return state.propertySetsList.map(val => {
                return {
                    id: val.metadata.id,
                    kind: val.metadata.kind,
                    searchValue: `${val.metadata.id} - ${val.metadata.kind}`,
                }
            })
        },

        // ---
        // partners related

        dataByPmsId(state, getters) { // data of pms by id
            const hashMap = {}
            state.pmsList.forEach(val => {
                hashMap[val.metadata.id] = val
            })

            return hashMap
        },

        dataByCmId(state, getters) { // data of cm by id
            const hashMap = {}
            state.cmList.forEach(val => {
                hashMap[val.metadata.id] = val
            })

            return hashMap
        },

        pmsNames(state) {
            return state.pmsList.map(val => val.metadata.id)
        },

        cmNames(state) {
            return state.cmList.map(val => val.metadata.id)
        },

        // ---
        // currencies
        currenciesDropdown(state) { // currencies dropdown
            return state.currenciesList.map(val => {
                return {
                    text: val.spec.currency_name,
                    value: val.metadata.id,
                }
            })
        },

        currencyById(state) { // currency by currency id
            const hashMap = {}
            state.currenciesList.forEach(val => {
                hashMap[val.metadata.id] = val
            })
            return hashMap
        },

        // lifecycle status
        lifecycleStatusByPgId(state) {
            const hashMap = {}
            state.propertyGroupsStatesList.forEach(val => {
                //hashMap[val.metadata.propertyGroupId] = val
                hashMap[val.propertyGroupId] = val
            })
            return hashMap
        }
    },


    mutations: {
        // property groups
        resetPropertyGroupsList(state, payload) { // resets and updates the pgs list
            state.propertyGroupsList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.propertyGroupsList.push(val)
                })
            }
        },

        addPg(state, payload) { // adds a pg to the list of pgs
            state.propertyGroupsList.push(payload.value)
        },

        // property sets
        resetPropertySetsList(state, payload) { // resets and updates the pgs list
            state.propertySetsList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.propertySetsList.push(val)
                })
            }
        },


        // ---
        // selection mutations

        resetSelectedPgData(state, payload) { // resets and updates the selected pg data

            // update selected pg
            state.currentPG = null
            state.currentPG = payload?.currentPG || null

            // update selected property sets
            state.currentPropertySets = []
            if (!!payload?.currentPropertySets) {
                payload.currentPropertySets.forEach(val => {
                    state.currentPropertySets.push(val)
                })
            }
            state.currentStrategyPS = state.currentPropertySets.find(ps => ps.metadata.kind === 'Strategy')
            state.currentPhysicalPS = state.currentPropertySets.find(ps => ps.metadata.kind === 'Physical')
            state.currentPMSPS = state.currentPropertySets.find(ps => ps.metadata.kind === 'PMS')
            state.currentCHMPS = state.currentPropertySets.find(ps => ps.metadata.kind === 'CHM')
            
            state.currentPMSPSPropertygroups = payload.currentPMSPSPropertygroups
            state.currentCHMPSPropertygroups = payload.currentCHMPSPropertygroups
            

            // update selected pg lifecycle status
            state.currentLifecycleStatus = null
            state.currentLifecycleStatus = payload?.currentLifecycleStatus || null

            // update selected pg lifecycle status history
            state.currentLifecycleStatusHistory = []
            if (!!payload?.currentLifecycleStatusHistory) {
                payload.currentLifecycleStatusHistory.forEach(val => {
                    state.currentLifecycleStatusHistory.push(val)
                })
            }

            // update selected pg properties
            state.currentProperties = []
            if (!!payload?.currentProperties) {
                payload.currentProperties.forEach(val => {
                    state.currentProperties.push(val)
                }) || []
            }

            // update selected pg parent (if it has one)
            state.currentParent = null
            state.currentParent = payload?.currentParent || null

            // update selected pg children (if any)
            state.currentChildren = null
            state.currentChildren = payload?.currentChildren || null

            // update selected pg users (if any)
            state.currentUsers = []
            if (!!payload?.currentUsers) {
                payload.currentUsers.forEach(val => {
                    state.currentUsers.push(val)
                }) || null
            }

            state.currentHubspotDeal = null
            state.currentHubspotDeal = payload?.currentHubspotDeal || null

            // update selected pg properties with children
            state.currentPropertiesIncludingChildren = []
            if (!!payload?.currentPropertiesIncludingChildren) {
                payload.currentPropertiesIncludingChildren.forEach(val => {
                    state.currentPropertiesIncludingChildren.push(val)
                }) || null
            }
            state.currentProperties.forEach(prop => {
                state.currentPropertiesIncludingChildren.push(prop)
            })

            state.currentPgCountry = payload?.currentPgCountry
            state.currentPgLanguage = payload?.currentPgLanguage
        },

        resetSelectedPsData(state,payload) {
            // update selected pg
            state.currentPS = null
            state.currentPS = payload?.currentPS || null

            state.currentPSProperties = []
            state.currentPSProperties = payload?.currentPSProperties || []

            state.currentPSPropertyGroups = []
            state.currentPSPropertyGroups = payload?.currentPSPropertyGroups || []

        },

        resetSelectedUsers(state, payload) {
            state.currentUsers = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.currentUsers.push(val)
                })
            }
        },

        removeSelectedUser(state, payload) {
            const index = state.currentUsers.findIndex(val => val.metadata.id === payload.id)

            if (index !== -1) {
                state.currentUsers.splice(index, 1)
            }
        },

        updateSelectedPg(state, payload) { // updates the selected pg data
            const index = state.propertyGroupsList.findIndex(val => val.metadata.id === payload.id)

            // update the pg inside the list
            if (index !== -1) {
                state.propertyGroupsList.splice(index, 1, payload.value)
            }

            // update the selected pg since if you can update it, you have it selected
            state.currentPG = payload.value
        },

        updateSelectedPgLifecycleStatus(state, payload) { // updates the selected pg lifecycle status
            state.currentLifecycleStatus = payload.value
        },

        addLifecycleStatusToHistory(state, payload) { // adds the lifecycle status to the history of the selected pg
            //unshift to add the new status as the first elemnt in the list
            state.currentLifecycleStatusHistory.unshift(payload.value)
        },

        updateSelectedPgProperty(state, payload) { // updates a property of the selected pg
            const index1 = state.currentProperties.findIndex(prop => prop.metadata.id === payload.id)
            if (index1 !== -1) {
                state.currentProperties.splice(index1, 1, payload.value)
            }

            const index2 = state.currentPropertiesIncludingChildren.findIndex(prop => prop.metadata.id === payload.id)
            if (index2 !== -1) {
                state.currentPropertiesIncludingChildren.splice(index2, 1, payload.value)
            }
        },

        addSelectedPgProperty(state, payload) { // adds property to the selected pg
            state.currentProperties.push(payload.value)
            state.currentPropertiesIncludingChildren.push(payload.value)
        },

        deleteSelectedPgProperty(state, payload) { // deletes a property of the selected pg
            const index1 = state.currentProperties.findIndex(prop => prop.metadata.id === payload.id)
            if (index1 !== -1) {
                state.currentProperties.splice(index1, 1)
            }

            const index2 = state.currentPropertiesIncludingChildren.findIndex(prop => prop.metadata.id === payload.id)
            if (index2 !== -1) {
                state.currentPropertiesIncludingChildren.splice(index2, 1)
            }
        },

        resetSelectedPgChildren(state, payload) { // resets selected pg children
            state.currentChildren = []
            payload.values.forEach(val => {
                state.currentChildren.push(val)
            })
        },

        // ---
        // generic mutations

        resetCurrenciesList(state, payload) {
            state.currenciesList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.currenciesList.push(val)
                })
            }
        },

        resetLifecycleStatesList(state, payload) {
            state.lifecycleStatesList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.lifecycleStatesList.push(val)
                })
            }
        },

        resetPropertyGroupsStatesList(state, payload) {
            state.propertyGroupsStatesList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.propertyGroupsStatesList.push(val)
                })
            }
        },

        updateLifecycleStatusByPgId(state, payload) {
            const index = state.propertyGroupsStatesList.findIndex(val => val.propertyGroupId === payload.pgId)

            const updatedPGState = {
                bundle: payload.value.metadata.bundle,
                childs: payload.value.spec.childs,
                date: payload.value.metadata.insertDate,
                fields: payload.value.spec.fields,
                id: payload.value.metadata.lifecycleStateId,
                lifecycleStateId: payload.value.metadata.lifecycleStateId,
                lifecycleStateLabel: payload.value.metadata.lifecycleStateLabel,
                lifecycleStateName: payload.value.metadata.lifecycleStateName,
                propertyGroupId: payload.value.metadata.propertyGroupId,
                setBy: payload.value.metadata.setBy,
            }

            if (index !== -1) {
                state.propertyGroupsStatesList.splice(index, 1, updatedPGState)
            } else {
                state.propertyGroupsStatesList.push(updatedPGState)
            }
        },

        // ---
        // partners mutations

        resetPartnerList(state, payload) {
            state.partnerList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.partnerList.push(val)
                })
            }
        },

        resetPmsList(state, payload) { // resets the pms list and updates it with a new one if provuded
            state.pmsList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.pmsList.push(val)
                })
            }
        },

        addPms(state, payload) { // adds a new pms - for the future
            state.pmsList.push(payload.value)
        },

        updatePms(state, payload) { // updates an existing pms provided id and the new value - for the future
            const index = state.pmsList.findIndex(val => val.metadata.id === payload.id)

            if (index !== -1) {
                state.pmsList.splice(index, 1, payload.value)
            }
        },

        resetCmList(state, payload) { // resets the cm list and updates it with a new one if provided
            state.cmList = []
            if (!!payload?.values) {
                payload.values.forEach(val => {
                    state.cmList.push(val)
                })
            }
        },

        addCm(state, payload) { // adds a new cm - for the future
            state.cmList.push(payload.value)
        },

        updateCm(state, payload) { // updates an existing cm provided id and the new value - for the future
            const index = state.cmList.findIndex(val => val.metadata.id === payload.id)

            if (index !== -1) {
                state.cmList.splice(index, 1, payload.value)
            }
        },

        updateSelectedLosProperties(state, payload) {
            state.selectedLosProperties = payload.value
        }

    },
    actions: {
        // selection update/add/delete actions

        async addPG(context, payload) { // adds a pg to the pgs list
            const api = context.rootState.api.api;

            try {
                const res = await api.put("core/v1", "/propertygroup", {}, payload.config, {})

                if (res.status !== 200) throw res.error

                context.commit("addPg", { value: res.items[0] })

                context.dispatch("alertSystem/newSuccess", {
                    message: `Property group ${res.items[0].metadata.id} - ${res.items[0].metadata.name} added`,
                    timeout: -1,
                }, { root: true })
                return res.items[0]
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Property group add failed: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },
        async updatePS(context, payload) {
            const api = context.rootState.api.api
            try {
                const res = await api.put("propertyset/v1", "/propertyset", {}, payload.config, {})
                if (res.status !== 200) throw res.error
                
                context.dispatch("alertSystem/newSuccess", {
                    message: `Property set ${res.items[0].metadata.id} of kind ${res.items[0].metadata.kind} updated`,
                    timeout: 10000,
                }, { root: true })

                return res.items[0]

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Property set ${res.items[0].metadata.id} of kind ${res.items[0].metadata.kind} update failed: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },
        async updatePG(context, payload) { // updates a pg (if the pg is the selected one then updates it too)
            const api = context.rootState.api.api

            try {
                const res = await api.put("core/v1", "/propertygroup", {}, payload.config, {})

                if (res.status !== 200) throw res.error

                // check for updating current pg only if the ids are the same as the current globally selected pg
                if (res.items[0].metadata.id === context.state.currentPG.metadata.id) {
                    context.commit("updateSelectedPg", { id: res.items[0].metadata.id, value: res.items[0] })
                }

                context.dispatch("alertSystem/newSuccess", {
                    message: `Property group ${res.items[0].metadata.id} updated`,
                    timeout: 10000,
                }, { root: true })
                return res.items[0]
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Property group update failed: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },
        async addProperty(context, payload) { // adds a property (if the property is part of the selected pg then it's added too)
            const api = context.rootState.api.api

            try {
                const res = await api.put("core/v1", `/propertygroup/${payload.pgId}/property`, {}, payload.config, {})

                // adds the newly added property to the properties list if the pgids are the same
                if (payload.pgId === context.state.currentPG.metadata.id) {
                    context.commit("addSelectedPgProperty", {
                        value: res.items[0]
                    })
                }

                context.dispatch("alertSystem/newSuccess", {
                    message: `Property ${res.items[0].metadata.id} - ${res.items[0].metadata.name} added successfully`,
                }, { root: true })

                if (payload.pmsMapping !== null) {
                    await context.dispatch("mappingAndRates/insertPmsPropertyMapping", {
                        pmsId: payload.pmsMapping,
                        propertyId: res.items[0].metadata.id,
                    }, { root: true })

                    context.dispatch("alertSystem/newSuccess", {
                        message: `Pms mapping added to property ${res.items[0].metadata.name}`,
                        timeout: 5000
                    }, { root: true })
                }

                // avoid insert of property_modifier to property_source_id=0 if graph_json is null
                if (payload.propertyModifier !== null && payload?.config?.spec?.graph_json != null) {
                    const propMod = structuredClone(payload.propertyModifier)
                    propMod.spec.property_id = res.items[0].metadata.id

                    await api.put("core/v1", `/propertymodifier/property/${res.items[0].metadata.id}/modifier/40/date/-`, {}, propMod, {})
                }
                return res.items[0]
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error adding the property: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async updateProperty(context, payload) { // updates a property (if the property is part of the selected pg then it's updated too)
            const api = context.rootState.api.api

            try {
                const res = await api.put("core/v1", `/propertygroup/${payload.pgId}/property`, {}, payload.config, {})

                if (!!res.error) throw res.error

                // updates also the current pg properties list if the current pg is the same as the one provided by payload
                if (payload.pgId === context.state.currentPG.metadata.id) {
                    context.commit("updateSelectedPgProperty", {
                        id: res.items[0].metadata.id,
                        value: res.items[0]
                    })
                }

                context.dispatch("alertSystem/newSuccess", {
                    message: `Property ${res.items[0].metadata.id} - ${res.items[0].metadata.name} updated successfully`,
                }, { root: true })
                return res.items[0]
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating the property: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async deleteProperty(context, payload) { // deletes a property (if the property is part of the selected pg then it's removed too)
            const api = context.rootState.api.api

            try {
                const res = await api.delete("core/v1", `/propertygroup/${payload.pgId || '-'}/property/${payload.propertyId}`, {}, {}, {})

                // removes the property from the properties list if the pgids are the same
                if (res.items[0].spec.property_group_id === context.state.currentPG.metadata.id) {
                    context.commit("deleteSelectedPgProperty", { id: res.items[0].metadata.id })
                }

                context.dispatch("alertSystem/newSuccess", {
                    message: `Property ${res.items[0].metadata.id} - ${res.items[0].metadata.name} deleted successfully`,
                }, { root: true })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error deleting property: ${error}`,
                    timeout: -1
                }, { root: true })
            }
        },

        async updatePgChildren(context, payload) { // updates the selected pg children
            const api = context.rootState.api.api

            try {
                let addedChildren = []
                let removedChildren = []

                // find the added children
                for (const newId of payload.newChildren) {
                    if (!context.state.currentChildren.includes(newId)) {
                        addedChildren.push(newId);
                    }
                }

                // find the removed children
                for (const oldId of context.state.currentChildren) {
                    const found = payload.newChildren.some(newId => newId === oldId);
                    if (!found) {
                        removedChildren.push(oldId);
                    }
                }

                // delete the removed children
                let removedChildrenData = await Promise.all(removedChildren.map(async (childId) => {
                    const res = await api.get("propertyset/v1", `/propertyset/propertygroup/${childId}/kind/Physical`, {}, {}, {})
                    return {
                        pgId: childId,
                        psId: res.items[0].metadata.id,
                    }
                }))
                // remove duplicates from removedChildrenData
                removedChildrenData = removedChildrenData.filter(pg => pg !== context.getters.globalSelectedPgId)

                const uniqueRemovedChildrenData = removedChildrenData.filter((v, i, a) => a.findIndex(t => (t.pgId === v.pgId)) === i)

                for (let oldChild of uniqueRemovedChildrenData) {
                    const newPS = {
                        apiVersion: "propertyset/v1",
                        kind: "PropertySet",
                        metadata: {
                            id: uuidv4(),
                            name: `${oldChild.pgId}-Strategy`,
                            kind: "Strategy",
                            physicalPropertysetId: oldChild.psId,
                        },
                        spec: {
                            config: {
                                Strategy: {
                                    parent_physical_propertyset_id: oldChild.psId
                                }
                            }
                        }
                    }
                    const res = await api.put("propertyset/v1", "/propertyset", {}, newPS, {})
                    if (!!res.error) throw res.error
                }

                addedChildren = addedChildren.filter(pg => pg !== context.getters.globalSelectedPgId)

                // add the newly added children
                const addedChildrenData = await Promise.all(addedChildren.map(async (childId) => {
                    const res = await api.get("propertyset/v1", `/propertyset/propertygroup/${childId}/kind/Strategy`, {}, {}, {})
                    return {
                        ps: res.items[0],
                    }
                }))
                const parentPSid = context.rootState.core.currentPhysicalPS.metadata.id
                for (let newChild of addedChildrenData) {
                    newChild.ps.spec.config.Strategy.parent_physical_propertyset_id = parentPSid
                    const res = await api.put("propertyset/v1", "/propertyset", {}, newChild.ps, {})
                    if (!!res.error) throw res.error
                }

                context.commit("resetSelectedPgChildren", { values: payload.newChildren })

                context.dispatch("alertSystem/newSuccess", {
                    message: `Children updated successfully`,
                }, { root: true })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating the children: ${error}`,
                    timeout: -1,
                }, { root: true })
            }
        },

        async updatePgState(context, payload) { // updates the pgs state (if the pg provided is the selected one then updates its status)
            const api = context.rootState.api.api;

            try {
                await api.put("lifecycle/v2", "/propertygroupstate", {}, payload.newState, {});
                const res = await context.dispatch("fetchLifecycleStatus", { pgId: payload.newState.metadata.propertyGroupId })

                // update the current state only if the global pg is the same (useful in case we add a new pg with an initial state)
                if (payload.newState.metadata.propertyGroupId === context.state.currentPG.metadata.id) {
                    context.commit("updateSelectedPgLifecycleStatus", { value: res })
                    context.commit("addLifecycleStatusToHistory", { value: res })
                }

                context.commit("updateLifecycleStatusByPgId", { pgId: payload.newState.metadata.propertyGroupId, value: res })

                /* // automations in case the new state is lost or deleted
                if ((payload.newState.metadata.name === "lost" && payload.newState.metadata.label === "default") || (payload.newState.metadata.name === "deleted" && payload.newState.metadata.label === "default")) {

                    // operations on properties
                    const pgProps = await context.dispatch("fetchProperties", {pgId: payload.newState.metadata.propertyGroupId})

                    await Promise.all(pgProps.map(async (prop) => {

                        const newProp = structuredClone(prop)
                        newProp.spec.is_import_reservation_enabled = false
                        newProp.spec.set_price_from_offset = 0
                        newProp.spec.set_price_to_offset = 0
                        newProp.spec.graph_json = null
                        newProp.spec.cm_id = null

                        await context.dispatch("updateProperty", {
                            pgId: payload.newState.metadata.propertyGroupId,
                            config: newProp,
                        })
                    }))

                    // disconnect users
                    await api.delete("user/v1", `/user_property_group/${payload.newState.metadata.propertyGroupId}`, {}, {}, {})
                    context.commit("resetSelectedUsers")

                    // updates the pg info
                    const pg = await context.dispatch("fetchPropertyGroup", {pgId: payload.newState.metadata.propertyGroupId})
                    pg.spec.pms_id = null
                    pg.spec.encoded_pms_access_info = null
                    pg.spec.channel_manager_id = null
                    pg.spec.encoded_channel_manager_access_info = null

                    await context.dispatch("updatePG", {
                        config: pg
                    })
                } */

                context.dispatch("alertSystem/newSuccess", {
                    message: `Property Group lifecycle status updated successfully`,
                }, { root: true })
                return res
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating the pg state: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async getPgsLinkedToPS(context, payload) {
            const api = context.rootState.api.api
            try {   
                const res = await api.get("propertyset/v1", `/propertygroup/propertyset/${payload.psId}`, {}, {}, {})

                if (!!res.error) throw res.error

                return res.items.map(val => `${val.metadata.property_group_id}`)

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating the property: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        // ---
        // selection fetch actions

        async selectGlobalPg(context, payload) { // makes the api request when a pg is selected from the navbar
            const globalSelection = {
                currentPG: null,
                currentPropertySets: null,
                currentPMSPSPropertygroups: null,
                currentCHMPSPropertygroups: null,
                currentLifecycleStatus: null,
                currentLifecycleStatusHistory: null,
                currentProperties: null,
                currentParent: null,
                currentChildren: null,
                currentPropertiesIncludingChildren: null,
                currentUsers: null,
                currentPgCountry: null,
                currentPgLanguage: null,
                currentHubspotDeal: null,
            }

            try {
                // fetch the selected pg
                const resPg = await context.dispatch("fetchPropertyGroup", { pgId: payload.pgId })
                globalSelection.currentPG = structuredClone(resPg) || null

                // fetch the propetysets
                const resPropertySets = await context.dispatch("fetchPropertySets", { pgId: payload.pgId })
                globalSelection.currentPropertySets = structuredClone(resPropertySets) || null

                // fetch the pgs linked to the propertysets 
                const resPgsLinkedToPMSPropertySet = (await context.dispatch("getPgsLinkedToPS", { psId: globalSelection.currentPropertySets?.filter( ps => ps.metadata.kind == 'PMS')?.map(ps => ps.metadata.id) })).join(",")
                globalSelection.currentPMSPSPropertygroups = structuredClone(resPgsLinkedToPMSPropertySet) || null

                const resPgsLinkedToCHMPropertySet = (await context.dispatch("getPgsLinkedToPS", { psId: globalSelection.currentPropertySets?.filter( ps => ps.metadata.kind == 'CHM')?.map(ps => ps.metadata.id) })).join(",")
                globalSelection.currentCHMPSPropertygroups = structuredClone(resPgsLinkedToCHMPropertySet) || null

                // fetch the selected pg lifecycle status
                const resLifecycle = await context.dispatch("fetchLifecycleStatus", { pgId: payload.pgId })
                globalSelection.currentLifecycleStatus = structuredClone(resLifecycle) || null

                const resLifecycleHistory = await context.dispatch("fetchLifecycleStatusHistory", { pgId: payload.pgId })
                globalSelection.currentLifecycleStatusHistory = structuredClone(resLifecycleHistory) || null

                // fetch the selected pg properties
                const resProperties = await context.dispatch("fetchProperties", { pgId: payload.pgId })
                globalSelection.currentProperties = structuredClone(resProperties) || null

                // fetch the selected pg parent
                const resParent = await context.dispatch("fetchParent", { ps: globalSelection.currentPropertySets })
                globalSelection.currentParent = structuredClone(resParent) || null

                // fetch the selected pg children
                const resChildren = await context.dispatch("fetchChilds", { ps: globalSelection.currentPropertySets, pgId: payload.pgId })
                globalSelection.currentChildren = structuredClone(resChildren) || null

                // fetch the selected pg users
                const resUsers = await context.dispatch("fetchUsers", { pgId: payload.pgId })
                globalSelection.currentUsers = structuredClone(resUsers) || null

                // fetch the selected pg country and language
                const resPgGraph = await context.dispatch('fetchGraphInfo', {
                    kind: 'Propertygroup',
                    id: resPg?.metadata?.id,
                })
                let pgCountry = 'unknown'
                let pgLanguage = 'unknown'
                let rootlink = resPgGraph?.items?.[0]?.link?.Root || []
                for (let link of rootlink) {
                    if (link.kind == 'Location:City') {
                        const splittedLoc = link?.id?.split('_')
                        const country = splittedLoc[0]
                        pgCountry = country
                        if (['germany', 'austria'].includes(country)) {
                            pgLanguage = 'de'
                        } else if (['italy', 'san.marino'].includes(country)) {
                            pgLanguage = 'it'
                        } else {
                            pgLanguage = 'en'
                        }
                    }
                }
                globalSelection.currentPgCountry = pgCountry
                globalSelection.currentPgLanguage = pgLanguage

                // fetch hubspot deal
                const resHubspotDeal = await context.dispatch("fetchHubspotDeal", { pgId: payload.pgId })
                globalSelection.currentHubspotDeal = structuredClone(resUsers) || null

                // fetch the selected pg children properties
                const resChildrenProps = []
                if (!!resChildren) {
                    await Promise.all(resChildren?.map(async (pgId) => {
                        const res = await context.dispatch("fetchProperties", { pgId: pgId })

                        if (!!res) {
                            res.forEach(prop => {
                                resChildrenProps.push(prop)
                            })
                        }
                    }))
                }
                globalSelection.currentPropertiesIncludingChildren = resChildrenProps?.length ? structuredClone(resChildrenProps) : null

                await context.dispatch("subscriptions/fetchCurrentPgHotels", { pgId: resPg.metadata.id, pmsName: resPg.spec.pms_name }, { root: true })

                console.log(resParent[0])

                context.commit("resetSelectedPgData", {
                    currentPG: resPg,
                    currentPropertySets: resPropertySets,
                    currentPMSPSPropertygroups: resPgsLinkedToPMSPropertySet,
                    currentCHMPSPropertygroups: resPgsLinkedToCHMPropertySet,
                    currentLifecycleStatus: resLifecycle,
                    currentLifecycleStatusHistory: resLifecycleHistory,
                    currentProperties: resProperties,
                    currentParent: !!resParent?.length ? resParent : null,
                    currentChildren: resChildren,
                    currentPropertiesIncludingChildren: !!resChildrenProps?.length ? resChildrenProps : null,
                    currentUsers: !!resUsers.length ? resUsers : null,
                    currentPgCountry: pgCountry,
                    currentPgLanguage: pgLanguage,
                    currentHubspotDeal: !!resHubspotDeal?.length ? resHubspotDeal[0] : null,
                })
                return globalSelection
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error selecting the global property group: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async selectGlobalPs(context, payload) {
            const globalSelection = {
                currentPS: null
            }
            try {
                // fetch the selected pg
                const resPs = await context.dispatch("fetchPropertySet", { psId: payload.psId })
                globalSelection.currentPS = structuredClone(resPs) || null

                const resPsProperties = await context.dispatch("fetchPropertiesOfPropertySet", { psId: payload.psId })
                globalSelection.currentPSProperties = structuredClone(resPsProperties) || null

                const resPsPropertyGroups = await context.dispatch("fetchPropertyGroupsOfPropertySet", { psId: payload.psId })
                globalSelection.currentPSPropertyGroups = structuredClone(resPsPropertyGroups) || null

                context.commit("resetSelectedPsData", {
                    currentPS: resPs,
                    currentPSProperties: resPsProperties,
                    currentPSPropertyGroups: resPsPropertyGroups
                })
                return globalSelection

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error selecting the global property set: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }

        },

        async fetchPropertyGroup(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("core/v1", `/propertygroup/${payload.pgId}`, { spec: [""] }, {}, {})

                return res.items[0]
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the property group: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },


        async fetchPropertySet(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("propertyset/v1", `/propertyset/${payload.psId}`, { }, {}, {})

                return res.items[0]

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the property set: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchPropertiesOfPropertySet(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("propertyset/v1", `/propertyset/${payload.psId}/property`, { }, {}, {})
                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the properties of the property set: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchPropertyGroupsOfPropertySet(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("propertyset/v1", `/propertygroup/propertyset/${payload.psId}`, { }, {}, {})
                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the property groups of the property set: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchLifecycleStatus(context, payload) {
            const api = context.rootState.api.api

            try {
                let res = await api.get("lifecycle/v2", `/propertygroupstate/${payload.pgId}/latest`, { spec: [""] }, {}, {})
                res.items = res.items.filter((el) => el.metadata.bundle === "smartpricing")
                return res.items[0]
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the lifecycle status: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchLifecycleStatusHistory(context, payload) {
            const api = context.rootState.api.api

            try {
                let res = await api.get("lifecycle/v2", `/propertygroupstate/${payload.pgId}`, { spec: [""] }, {}, {})
                res.items = res.items.filter((el) => el.metadata.bundle === "smartpricing")
                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the lifecycle status history: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchProperties(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("core/v1", `/propertygroup/${payload.pgId}/property`, { spec: [""] }, {}, {});

                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the properties list: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchUsers(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("user/v1", `/users/property_group/${payload.pgId}`, {}, {}, {})

                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching users: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchHubspotDeal(context, payload) {
            const api = context.rootState.api.api

            try {
                // new endpoint returns data with a different format
                // const res = await api.get("hubspot/v1", `/deals/filter?pgId=${payload.pgId}`, {}, {}, {})
                const res = await api.get("hubspot/v1", `/objects/deals/filter?pgId=${payload.pgId}`, {}, {}, {})

                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching hubspot deal status: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchPropertySets(context, payload) {
            const api = context.rootState.api.api

            try {
                console.log("FETCH PS")
                const res = await api.get("propertyset/v1", `/propertyset/propertygroup/${payload.pgId}`, {}, {}, {})

                return res.items
            }
            catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching propertysets: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchParent(context, payload) {
            const api = context.rootState.api.api

            try {
                const physicalPSid = payload.ps.find(ps => ps.metadata.kind === 'Physical').metadata.id
                const strategyPS = payload.ps.find(ps => ps.metadata.kind === 'Strategy')
                const parentPSid = strategyPS.spec.config.Strategy.parent_physical_propertyset_id
                if (parentPSid === physicalPSid) {
                    return []
                }

                // get the pgId of the parent
                const res = await api.get("propertyset/v1", `/propertygroup/propertyset/${parentPSid}`, {}, {}, {})
                return [res.items[0].metadata.property_group_id]
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error while fetching the parent: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchChilds(context, payload) {
            const api = context.rootState.api.api

            try {
                const physicalPSid = payload.ps.find(ps => ps.metadata.kind === 'Physical').metadata.id
                const strategyPS = payload.ps.find(ps => ps.metadata.kind === 'Strategy')
                const parentPSid = strategyPS.spec.config.Strategy.parent_physical_propertyset_id
                // if I am not the parent, show no children
                if (parentPSid !== physicalPSid) {
                    return []
                }

                // get the pgs related to the strategy ps
                const pgsLinkedToStrategyPsRes = await api.get("propertyset/v1", `/propertygroup/propertyset/${strategyPS.metadata.id}`, {}, {}, {})
                const children = pgsLinkedToStrategyPsRes.items.map(pg => pg.metadata.property_group_id)
                                                            .filter(pg => pg !== payload.pgId )

                return children

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error while fetching the children: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchGraphInfo(context, payload) {
            const api = context.rootState.api.api
            try {
                const res = await api.get('graph/vpg', '/' + payload.kind + '/' + payload.id, {}, {}, {})
                return res

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the Pg Graph data: ${error}`,
                    timeout: -1,
                }, { root: true })
                return false
            }
        },

        // ---
        // automapping

        async executeAutomapping(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("integrations/v2", `/full-mapping/${payload.pgId}`, {}, {}, {})
                if (!res.success) throw `Automapping for ${payload.pgId} failed`

                context.dispatch("alertSystem/newInfo", {
                    message: `Automapping for ${payload.pgId} ran successfully`,
                    timeout: -1
                }, { root: true })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error executing the automapping: ${error}`,
                    timeout: -1
                }, { root: true })
            }
        },

        // ---
        // token generation actions

        async generateToken(context, payload) {
            const api = context.rootState.api.api;

            try {
                const res = await api.get("core/v1", `/generatetoken?environment=${payload.environment}&issuedby=${payload.issuedBy}&targetuser=${payload.targetUsername}`, {}, {}, {})

                if (res.status === "error") throw res.error

                return res.result
            } catch (error) {
                context.commit("alertSystem/newError", {
                    messages: `Token generation failed ${res.error}`,
                }, { root: true });
                return null
            }
        },

        // ---
        // generic fetch actions

        async fetchPropertyGroupsList(context) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("core/v1", "/propertygroup", { filterColumns: "id,name,channel_manager_name,pms_name,pms_id,channel_manager_id" }, {}, {})

                context.commit("resetPropertyGroupsList", { values: res.items })

                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the property groups list: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchPropertySetsList(context) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("propertyset/v1", "/propertyset?fields=id,kind", {}, {}, {})
                context.commit("resetPropertySetsList", { values: res.items })

                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the property groups list: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchLifecycleStatesList(context) {
            const api = context.rootState.api.api;

            try {
                const res = await api.get("lifecycle/v2", "/state", {}, {}, {});
                res.items = res.items.filter((el) => el.metadata.bundle === "smartpricing")
                context.commit("resetLifecycleStatesList", {
                    values: res.items
                })

                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the lifecycle states: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchPropertyGroupsLifecycleStatesList(context) {
            const api = context.rootState.api.api

            try {
                //const res = await api.get("lifecycle/v1", "/propertygroupstate/latest", {}, {}, {})
                const res = await api.get("lifecycle/v2", "/propertygroupstate/latest/smartpricing", {}, {}, {})

                context.commit("resetPropertyGroupsStatesList", {
                    values: res.items
                })
                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching the lifecycle states list for property groups: ${error}`,
                    timeout: -1,
                }, { root: true })
                return null
            }
        },

        async fetchCurrenciesList(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("core/v1", "/currency", {}, {}, {});

                context.commit("resetCurrenciesList", {
                    values: res.items
                })
                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: "Error fetching the currencies list",
                }, { root: true })
                return []
            }
        },

        async fetchPartnerList(context) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("partner/v1", "/partner", {}, {}, {});

                context.commit("resetPartnerList", {
                    values: res.items,
                })

                return res;
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: "Error fetching the partner list",
                }, { root: true })
                return []
            }
        },

        async fetchPmsList(context) { // gets the pms list with relative schema
            const api = context.rootState.api.api;

            try {
                let res = await api.get("partner/v1", "/pms", {}, {}, {});

                context.commit("resetPmsList", {
                    values: res.items,
                })

                return res;
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: "Error fetching the pms list",
                }, { root: true })
                return []
            }
        },

        async fetchCmList(context) { // gets the cm list with relative schema
            const api = context.rootState.api.api;

            try {
                let res = await api.get("partner/v1", "/cm", {}, {}, {});

                context.commit("resetCmList", {
                    values: res.items,
                })

                return res;
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: "Error fetching the channel managers list",
                }, { root: true })
                return []
            }
        },

        // ---
        // partners actions

        async addPms(context, payload) { // add the pms to the list
            const api = context.rootState.api.api;

            try {
                if (context.getters.pmsNames.includes(payload.body.metadata.id)) throw "The pms name is already present in the database"

                let res = await api.put("partner/v1", "/pms", {}, payload.body, {});

                context.commit("addPms", {
                    value: res.items[0],
                })

                return res.items[0];
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error adding the property management system: ${error}`,
                }, { root: true })
                return null
            }
        },

        async updatePms(context, payload) { // add the pms to the list
            const api = context.rootState.api.api;

            try {
                let res = await api.put("partner/v1", "/pms", {}, payload.body, {});

                context.commit("updatePms", {
                    id: res.items[0].metadata.id,
                    value: res.items[0],
                })

                context.dispatch("alertSystem/newSuccess", {
                    message: `PMS ${res.items[0].metadata.id} updated successfully`,
                }, { root: true })
                return res.items[0];
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating the property management system: ${error}`,
                }, { root: true })
                return null
            }
        },

        async addCm(context, payload) { // add the cm to the list
            const api = context.rootState.api.api;

            try {
                if (context.getters.cmNames.includes(payload.body.metadata.id)) throw "The cm name is already present in the database"

                let res = await api.put("partner/v1", "/cm", {}, payload.body, {});

                context.commit("addCm", {
                    value: res.items[0],
                })

                return res.items[0];
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error adding channel manager: ${error}`,
                }, { root: true })
                return null
            }
        },

        async updateCm(context, payload) { // add the cm to the list
            const api = context.rootState.api.api;

            try {
                let res = await api.put("partner/v1", "/cm", {}, payload.body, {});

                context.commit("updateCm", {
                    id: res.items[0].metadata.id,
                    value: res.items[0],
                })

                context.dispatch("alertSystem/newSuccess", {
                    message: `Channel Manager ${res.items[0].metadata.id} updated successfully`,
                }, { root: true })
                return res.items[0];
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating channel manager: ${error}`,
                }, { root: true })
                return null
            }
        },

        async insertSmartpricingPartner(context, payload) { // add a partner that integrates with us
            const api = context.rootState.api.api;

            try {
                let res = await api.get("partner/v1", `/smartpricing/${payload.name}`, {}, {}, {});

                if (!res.success) throw res.error
                return true;
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating channel manager: ${error}`,
                }, { root: true })
                return false
            }
        },

        async fetchLosSettings(context, payload) {
            const api = context.rootState.api.api;
            try {
                let res = await api.get("core/v1/los", `/settings/${payload.pgId}`, {}, {}, {});
                context.commit("updateSelectedLosProperties", {
                    value: res.propertyIds,
                })

                if (res?.response?.data?.error) {
                    throw res?.response?.data?.error
                }

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching los: ${error}`,
                }, { root: true })
                return false
            }
        },

        async saveLosSettings(context, payload) {
            const api = context.rootState.api.api;
            try {
                let obj = { "propertyIds": payload.propertyIds }
                let res = await api.put("core/v1/los", `/settings/${payload.pgId}`, {}, obj, {});

                if (res?.response?.data?.error) {
                    throw res?.response?.data?.error
                }

                context.dispatch("alertSystem/newSuccess", {
                    message: `LoS settings for pg ${payload.pgId} updated successfully`,
                }, { root: true })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error saving los: ${error}`,
                }, { root: true })
                return false
            }
        }
    }
}
