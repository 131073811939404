<template>
    <v-container fluid class="pa-4">
        <v-card class="pa-4 card-ui" v-if="currentPMSPS">
            <v-card-title>Raw Reservations</v-card-title>

            <v-card-subtitle v-if="supported">Allows you to check the raw version of a reservation <strong>(ONLY FOR PUSHED
                    OR PULLED + ACK RESERVATIONS)</strong>, also the raw version is only available for reservations with an
                updated at date starting from 21/07/2023 onwards.</v-card-subtitle>
            <v-card-subtitle v-else class="red--text">This PG is not supported</v-card-subtitle>

            <v-row class="pa-4" v-if="supported">
                <v-col class="col-4">
                    <v-autocomplete label="Select a reservation (PMS - External ID - Last Update):" outlined dense
                        :items="reservationsList" @change="fetchReservation($event)"
                        v-model="selectedReservation"></v-autocomplete>
                </v-col>
                <v-col class="col-8">
                    <v-btn color="success" class="mr-2" :disabled="!selectedReservation"
                        @click="reconsumeReservation(selectedReservation)">Reconsume</v-btn>
                    <v-dialog transition="dialog-bottom-transition" width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" v-bind="attrs" v-on="on">Reconsume all</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar color="error" dark>Reconsume all</v-toolbar>
                                <v-card-text>
                                    <div class="text-h2 pa-12">You are about to reconsume all reservations, understood?
                                    </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                    <v-btn color="error"
                                        @click="[reconsumeAllReservations(), dialog.value = false]">Reconsume all</v-btn>
                                    <v-btn text @click="dialog.value = false">Close</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </v-col>
                <v-col class="col-6">
                    <v-textarea label="Raw Reservation" outlined readonly no-resize v-model="rawReservation"
                        rows="30"></v-textarea>
                </v-col>
                <v-col class="col-6">
                    <v-textarea label="Formatted Reservation" outlined readonly no-resize v-model="formattedReservation"
                        rows="30"></v-textarea>
                </v-col>
            </v-row>
        </v-card>
        <v-alert v-else type="warning">
            Select a PG
        </v-alert>
    </v-container>
</template>

<script>
import { formatDate } from "@/utils/dateUtils"
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            reservations: [],

            supportedPartners: [],

            selectedReservation: null,
            rawReservation: "",
            formattedReservation: "",
        }
    },
    computed: {
        ...mapState({
            api: state => state.api.api,
            currentPMSPS: state => state.core.currentPMSPS
        }),
        supported() {
            const currentPms = this.currentPMSPS.spec.config.PMS.pms_name
            return this.supportedPartners.includes(currentPms)
        },
        reservationsList() {
            return this.reservations.map(val => {
                return {
                    text: `${val.pmsName} - ${val.externalId} - ${formatDate(val.updatedAt)}`,
                    value: val.externalId,
                }
            })
        }
    },
    watch: {
        async currentPMSPS(newVal, oldVal) {
            if (!!this.currentPMSPS && this.supported) {
                await this.getAllRawReservations()
            }
        }
    },
    methods: {
        ...mapActions({
            newError: "alertSystem/newError",
            newSuccess: "alertSystem/newSuccess",
        }),
        async getAllRawReservations() {
            try {
                const res = await this.api.get("integrations/v2", `/rawreservation/${this.currentPMSPS.metadata.id}`, {}, {}, {})

                this.reservations = []
                this.reservations = res

            } catch (error) {
                this.newError({
                    message: `Error getting the reservations: ${error}`
                })
            }
        },
        async checkSupport() {
            try {
                const resCommMethod = await this.api.get("integrations/v2", `/specs/feature/reservationsCommunicationMethod`, {}, {}, {})
                const resAck = await this.api.get("integrations/v2", `/specs/feature/reservationsAck`, {}, {}, {})

                const supportedPartners = Object.keys(resAck)

                Object.entries(resCommMethod).forEach(([par, value]) => {
                    if (["push", "hybrid"].includes(value)) {
                        supportedPartners.push(par)
                    }
                })

                this.supportedPartners = Array.from(new Set(supportedPartners))
            } catch (error) {
                this.newError({
                    message: `Error getting the reservations communication method: ${error}`
                })
            }
        },
        async fetchReservation(externalId) {
            try {
                const resRes = await this.api.get("integrations/v2", `/rawreservation/${this.currentPMSPS.metadata.id}/${externalId}`, {}, {}, {})

                this.rawReservation = ""
                if (typeof resRes.data === "string") {
                    this.rawReservation = resRes.data
                } else {
                    this.rawReservation = JSON.stringify(resRes.data, "", "\t")
                }

                const resParsed = await this.api.post("integrations/v2", `/rawreservation/${this.currentPMSPS.metadata.id}/parse`, {}, {
                    reservations: [resRes.data]
                }, {})

                this.formattedReservation = ""
                if (typeof resParsed === "string") {
                    this.formattedReservation = resParsed
                } else {
                    this.formattedReservation = JSON.stringify(resParsed, "", "\t")
                }
            } catch (error) {
                this.newError({
                    message: `Error fetching the selected reservation: ${error}`
                })
            }
        },
        async reconsumeAllReservations() {
            try {
                let res = await this.api.put("integrations/v2", `/rawreservation/reconsume/${this.currentPMSPS.metadata.id}`, {}, {}, {})
                if (res.totalReconsumed != undefined && res.totalReconsumed != null) {
                    this.newSuccess({
                        message: `${res.totalReconsumed} reservations reconsumed with success`,
                        timeout: 5000
                    })
                } else {
                    throw JSON.stringify(res)
                }

            } catch (error) {
                this.newError({
                    message: `Error while reconsuming the reservations: ${error}`,
                    timeout: -1
                })
            }
        },
        async reconsumeReservation(externalId) {
            try {
                await this.api.put("integrations/v2", `/rawreservation/reconsume/${this.currentPMSPS.metadata.id}/reservation/${externalId}`, {}, {
                    reservations: [this.rawReservation]
                }, {})

                this.newSuccess({
                    message: "Reservation reconsumed successfully!",
                })
            } catch (error) {
                this.newError({
                    message: `Error reconsuming the selected reservation: ${error}`
                })
            }
        }
    },
    async created() {
        await this.checkSupport()
        if (!!this.currentPMSPS && this.supported) {
            await this.getAllRawReservations()
        }
    },
    destroyed() {
        this.rawReservation = ""
        this.formattedReservation = ""
    }
}
</script>
