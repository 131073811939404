<template>
    <v-container fluid class="pa-4">
        <v-card v-if="!!globalSelectedPgId" class="card-ui pa-4">
            <v-toolbar
                dense
                elevation="0"
                class="card-ui"
                style="border-bottom: 1px solid #3a3a3a"
            >
                <v-card-title class="overline ml-0 pl-0">
                    <v-icon small class="mr-2">fa-solid fa-rocket</v-icon>
                    <b class="grey--text darken-6">GPCS Job runner</b>
                </v-card-title>
            </v-toolbar>

            <v-card-text>
                <v-row>
                    <v-col>
                        <h3>Job mode</h3>
                        <p>
                            Async jobs will be put in queue, useful for long
                            running jobs
                        </p>
                        <div class="d-flex align-center">
                            <v-switch
                                v-model="jobsV3.metadata.async"
                                class="d-inline-block"
                                inset
                            ></v-switch>
                            <p class="mt-3" style="font-size: 16px">
                                {{
                                    jobsV3.metadata.async
                                        ? "Async Mode Enabled (send managed by the Scheduler)"
                                        : "Sync mode Enabled"
                                }}
                            </p>
                        </div>
                        <h3 class="mt-6">Job operations</h3>
                        <p>Only <b>sync</b> jobs will be run in order</p>
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="jobsV3.metadata.download"
                                color="green"
                                :error="!!canDownloadReservations.length"
                                :error-messages="canDownloadReservations"
                            ></v-checkbox>
                            <p class="mt-3" style="font-size: 16px">
                                Download reservations
                            </p>
                        </div>
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="jobsPredict.metadata.predict"
                                color="yellow"
                                :disabled="hasFather"
                                :error="!!canPredictPrice.length"
                                :error-messages="canPredictPrice"
                            ></v-checkbox>
                            <p class="mt-3" style="font-size: 16px">
                                Predict prices
                            </p>
                        </div>
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="jobsV3.metadata.compute"
                                color="blue"
                                :error="!!canComputePrice.length"
                                :error-messages="canComputePrice"
                            ></v-checkbox>
                            <p class="mt-3" style="font-size: 16px">
                                Compute prices
                            </p>
                        </div>
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="jobsV3.metadata.send"
                                color="red"
                                :error="!!canSendPrice.length"
                                :error-messages="canSendPrice"
                            ></v-checkbox>
                            <p class="mt-3" style="font-size: 16px">
                                Send prices
                            </p>
                        </div>
                    </v-col>
                    <v-col>
                        <h3>Operation date ranges</h3>
                        <p>
                            Date ranges are used to override default job range
                            behaviour
                        </p>
                        <v-row>
                            <v-col>
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            class="mt-3"
                                            v-model="datesText"
                                            label="Sync dates in range"
                                            prepend-icon="fa-solid fa-calendar"
                                            dense
                                            outlined
                                            :rules="[rules.dateRange]"
                                            hint="Format: YYYY-MM-DD - YYYY-MM-DD"
                                            v-bind="attrs"
                                            v-on="on"
                                            ref="dateRangeTextField"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dates"
                                        dark
                                        range
                                        color="cyan"
                                        show-adjacent-months
                                        :min="jobsV3.spec.syncFromDate"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="setBulkDates"
                                        >
                                            Massive
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="reset"
                                        >
                                            Reset
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menu = false"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <h3 class="mt-6">Operation options</h3>
                        <p>
                            Options are used to override default job range
                            behaviour
                        </p>
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="
                                    jobsV3.spec.options
                                        .forceUpdateCustomerParams
                                "
                                color="cyan"
                                hide-details
                            ></v-checkbox>
                            <p class="mt-9" style="font-size: 16px">
                                Force update customer params
                            </p>
                        </div>
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="jobsV3.spec.options.forceCompute"
                                color="cyan"
                                hide-details
                            ></v-checkbox>
                            <p class="mt-9" style="font-size: 16px">
                                Force compute
                            </p>
                        </div>
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="jobsV3.spec.options.forceImport"
                                color="cyan"
                                hide-details
                            ></v-checkbox>
                            <p class="mt-9" style="font-size: 16px">
                                Force import reservations
                            </p>
                        </div>
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="jobsV3.spec.options.forceAll"
                                color="cyan"
                                hide-details
                            ></v-checkbox>
                            <p class="mt-9" style="font-size: 16px">
                                Force All (Send All Prices)
                            </p>
                        </div>
                    </v-col>

                    <v-col class="col-12">
                        <v-alert
                            v-if="errorMessage"
                            outlined
                            type="error"
                            colore="red"
                            style="text-align: center"
                            class="ma-0"
                        >
                            <b>{{ errorMessage }}</b>
                        </v-alert>
                    </v-col>

                    <v-col class="col-12">
                        <v-btn
                            color="success"
                            block
                            @click="launchJob"
                            :loading="jobIsRunning"
                            :disabled="!isValid"
                            >Launch job</v-btn
                        >
                    </v-col>
                    <v-col class="col-12">
                        <v-textarea
                            auto-grow
                            rows="1"
                            v-if="resultPage !== null"
                            v-model="resultPage"
                            readonly
                            outlined
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
// @ is an alias to /src
import * as dateValidator from "@/utils/datesValidator.js";
import SelectPgAlert from "@/components/utils/SelectPgAlert";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    name: "RunJob",
    components: { SelectPgAlert },
    data: function (argument) {
        return {
            oldIsValid: false,
            jobIsRunning: false,
            resultPage: "",
            errorMessage: "",
            menu: false,
            jobsV3: {
                kind: "GpcsV3Job",
                metadata: {
                    propertyGroup: null,
                    download: false,
                    compute: false,
                    send: false,
                    async: true,
                },
                spec: {
                    syncFromDate: null,
                    syncToDate: null,
                    options: {
                        from: null,
                        to: null,
                        forceUpdateCustomerParams: true,
                        forceCompute: true,
                        forceImport: false,
                        forceAll: false,
                    },
                    toSendPrices: [],
                    resetStrategy: false,
                    origin: "intool",
                },
            },
            jobsPredict: {
                kind: "PredictJob",
                metadata: {
                    predict: false,
                },
            },

            rules: {
                dateRange: dateValidator.validateDateRange,
            },
        };
    },
    watch: {
        globalSelectedPgId(newVal, oldVal) {
            this.jobsV3.metadata.propertyGroup = newVal;
        },
        "jobsV3.spec.syncFromDate"(newVal, oldVal) {
            this.jobsV3.spec.options.from = newVal;
        },
        "jobsV3.spec.syncToDate"(newVal, oldVal) {
            this.jobsV3.spec.options.to = newVal;
        },
        "jobsV3.metadata.async"(newVal, oldVal) {
            this.validateJob();
        },
        "jobsV3.metadata.propertyGroup"(newVal, oldVal) {
            this.validateJob();
        },
        "jobsV3.metadata.send"(newVal, oldVal) {
            this.validateJob();
        },
        "jobsV3.metadata.compute"(newVal, oldVal) {
            this.validateJob();
        },
        //only jobsV2 should contain the download flag
        "jobsV3.metadata.download"(newVal, oldVal) {
            this.validateJob();
        },
        "jobsPredict.metadata.predict"(newVal, oldVal) {
            this.validateJob();
        },
    },
    computed: {
        ...mapState({
            currentStrategyPS: (state) => state.core.currentStrategyPS,
        }),
        ...mapGetters({
            canDownloadReservations: "gpcs/canDownloadReservations",
            canPredictPrice: "gpcs/canPredictPrice",
            canComputePrice: "gpcs/canComputePrice",
            canSendPrice: "gpcs/canSendPrice",
            globalSelectedPgId: "core/globalSelectedPgId",
        }),
        hasFather() {
            return !!this.$store.state.core.currentParent;
        },
        dates: {
            get: function () {
                return [
                    this.jobsV3.spec.syncFromDate,
                    this.jobsV3.spec.syncToDate,
                ].filter((d) => d);
            },
            set: function (val) {
                const check = this.rules.dateRange(val);
                if (check === true || val.length === 0) {
                    this.jobsV3.spec.syncFromDate = val[0] || null;
                    this.jobsV3.spec.syncToDate = val[1] || null;
                }
            },
        },
        currentPgChildren() {
            return this.$store.state.core.currentChildren;
        },
        datesText: {
            get: function () {
                return (
                    (this.jobsV3.spec.syncFromDate || "") +
                    " - " +
                    (this.jobsV3.spec.syncToDate || "")
                );
            },
            set: function (val) {
                const arr = val.split(" - ");
                this.dates = arr.filter((d) => d !== "");
            },
        },
        isValid() {
            return this.oldIsValid && this.$refs.dateRangeTextField.valid;
        },
    },
    methods: {
        ...mapActions({
            checkSendSupport: "gpcs/checkSendSupport",
            checkReadSupport: "gpcs/checkReadSupport",
            runMasterPredictResources: "gpcs/runMasterPredictResources",
        }),
        validateJob() {
            this.oldIsValid = false;
            this.errorMessage = "";
            if (
                this.jobsV3.metadata.propertyGroup == null ||
                this.jobsV3.metadata.propertyGroup == undefined ||
                typeof this.jobsV3.metadata.propertyGroup != "string"
            ) {
                this.errorMessage =
                    "propertyGroup is not valid: " +
                    this.jobsV3.metadata.propertyGroup;
                return;
            }
            if (
                this.jobsV3.metadata.send == true &&
                this.jobsV3.metadata.compute == false
            ) {
                this.errorMessage =
                    "cannot send without pre compute the prices";
                return;
            }
            if (
                this.jobsV3.metadata.send == false &&
                this.jobsV3.metadata.compute == false &&
                this.jobsV3.metadata.download == false &&
                this.jobsPredict.metadata.predict === false
            ) {
                this.errorMessage = "you must select at least one operation";
                return;
            }
            if (
                this.jobsV3.metadata.async == false &&
                this.jobsV3.metadata.send == true
            ) {
                this.errorMessage = "you cannot send in sync mode";
                return;
            }
            if (
                this.jobsV3.metadata.async == true &&
                this.jobsV3.metadata.download == true
            ) {
                this.errorMessage = "you cannot download in async mode";
                return;
            }
            this.oldIsValid = true;
            return null;
        },
        async launchJob() {
            const val = this.validateJob();
            this.resultPage = "";
            this.jobIsRunning = true;
            try {
                const val = this.validateJob();
                this.resultPage = "";
                if (val !== null) {
                    alert(val);
                    return;
                }
                this.jobIsRunning = true;

                let canRunCompute = true;
                if (this.jobsPredict.metadata.predict == true) {
                    const results = await this.runMasterPredictResources({
                        psId: this.currentStrategyPS.metadata.id,
                    });
                    results.forEach((val) => {
                        this.resultPage += `${String(
                            val.type
                        ).toUpperCase()} - ${val.message}\n`;
                    });

                    canRunCompute = !results.find((val) => {
                        return val.type === "error";
                    });
                }

                let pgs = [this.globalSelectedPgId];
                pgs = pgs.concat(this.currentPgChildren);

                //pgs contains the pg_id of the current pg and of its children

                for (let pg of pgs) {
                    if (
                        this.jobsV3.metadata.compute == true &&
                        canRunCompute == false
                    ) {
                        throw new Error(
                            "Cannot run compute due to predict error"
                        );
                    }

                    this.jobsV3.metadata.propertyGroup = pg;
                    let actionsToCall = [];

                    actionsToCall.push(
                        this.$store.dispatch("gpcs/submit", {
                            job: structuredClone(this.jobsV3),
                            version: "v3",
                            cb: function (res) {
                                if (this.jobsV3.metadata.async == true) {
                                    this.$store.dispatch(
                                        "alertSystem/newInfo",
                                        {
                                            message: `GPCSV3 Job for pg$ ${pg}: ${JSON.stringify(
                                                res
                                            )}`,
                                        }
                                    );
                                } else {
                                    this.resultPage +=
                                        `Result GPCSV3 for pg ${pg}: \n` +
                                        JSON.stringify(res) +
                                        "\n\n";
                                }
                            }.bind(this),
                        })
                    );

                    Promise.all(actionsToCall)
                        .then((value) => {})
                        .catch((error) => {
                            this.$store.dispatch("alertSystem/newError", {
                                message: `Error while performing the GPCS operations: ${error}`,
                            });
                        });
                }
                this.jobIsRunning = false;
            } catch (error) {
                this.$store.dispatch("alertSystem/newError", {
                    message: `Error while performing the GPCS operations: ${error}`,
                });
                this.jobIsRunning = false;
            }
        },
        reset() {
            this.dates = [];
            this.resultPage = "";
            this.$refs.dateRangeTextField.lazyValue = " - ";
        },
        setBulkDates() {
            let nextTwoYearsDate = new Date();
            nextTwoYearsDate.setFullYear(nextTwoYearsDate.getFullYear() + 2);
            this.dates = [
                "2019-01-01",
                nextTwoYearsDate.toISOString().split("T")[0],
            ];
        },
    },
    async created() {
        await this.checkReadSupport();
        await this.checkSendSupport();
        if (this.globalSelectedPgId !== null) {
            this.jobsV3.metadata.propertyGroup = this.globalSelectedPgId;
        }
    },
};
</script>
