<template>
    <v-row class="my-2">
        <v-col class="col col-3">
            <v-switch
                v-model="currentDateRange.withYear"
                label="with Year"
                @change="updateDateRange()"
            ></v-switch>
        </v-col>
        <v-col class="col col-7">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedDate"
                        label="Date Range"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        readonly
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dates"
                    range
                    @input="updateDateRange()"
                    :allowed-dates="allowedDates"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                        Close
                    </v-btn>
                </v-date-picker>
            </v-menu>
        </v-col>
        <v-col class="col col-2">
            <v-btn
                color="error"
                class="mt-4"
                @click="deleteDateRange()"
            >
                <v-icon >fa-solid fa-trash-can</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: ["roomRateId", "dateRangeId"],
        data() {
            return {
                menu: false,
                dates: [],
            }
        },
        watch: {
            'currentDateRange.withYear'(newVal, oldVal) {
                this.dates = []
                this.updateDateRange()
            }
        },
        computed: {
            currentDateRange: {
                get() {
                    const dateRanges = this.$store.getters["newRoomsRate/dateRangesById"](this.roomRateId)

                    const index = dateRanges.findIndex(range => {
                        return range.id === this.dateRangeId
                    })

                    return dateRanges[index]
                }
            },
            formattedDate() {
                return this.formatDate(this.currentDateRange)
            },
            chosenDateRange() {
                return {
                    start: this.dates[0] > this.dates[1] ? this.dates[1] : this.dates[0],
                    end: this.dates[0] > this.dates[1] ? this.dates[0] : this.dates[1],
                    withYear: this.currentDateRange.withYear,
                    id: this.dateRangeId,
                }
            },
            allowedDateRangesNoYear() {
                const allDates = this.$store.getters["newRoomsRate/dateRangesById"](this.roomRateId)

                const alreadyPresentDates = allDates.filter(value => { // returns only date ranges with year
                    return !value.withYear
                })

                return alreadyPresentDates
            },
            allowedDateRangesYear() {
                const allDates = this.$store.getters["newRoomsRate/dateRangesById"](this.roomRateId)

                const alreadyPresentDates = allDates.filter(value => { // returns only date ranges without year
                    return value.withYear
                })

                return alreadyPresentDates
            }
        },
        methods: {
            updateDateRange() {
                this.$store.commit("newRoomsRate/updateDateRange", {
                    id: this.roomRateId,
                    dateRange: this.chosenDateRange,
                })
                this.$store.dispatch("newRoomsRate/writeToYaml")
            },
            deleteDateRange() {
                this.$store.commit("newRoomsRate/deleteDateRange", {
                    id: this.roomRateId,
                    dateRange: this.chosenDateRange,
                })
                this.$store.dispatch("newRoomsRate/writeToYaml")
            },
            formatDate(dateObj) {
                const cond1 = dateObj.start !== null
                const cond3 = dateObj.start !== undefined
                const cond4 = !!dateObj.start

                if (!!dateObj.start && !!dateObj.end){
                    if (dateObj.withYear) { // date with year
                        return `${dateObj.start}/${dateObj.end}`
                    } else { // date without year
                        const start = dateObj.start.split("-")
                        const end = dateObj.end.split("-")
    
                        start.splice(0, 1)
                        end.splice(0, 1)
    
                        return `${start.join("-")}/${end.join("-")}`
                    }
                }
            },
            allowedDates(dateToCheck) {

                let datesToCompare = []

                if (this.chosenDateRange.withYear) {
                    datesToCompare = [...this.allowedDateRangesYear]
                } else {
                    datesToCompare = [...this.allowedDateRangesNoYear]
                }


                let toAllow = true 
                datesToCompare.forEach(date => {
                    
                    if (!!date.start && !!date.end) {
                        if (this.chosenDateRange.withYear) {
                            if (dateToCheck >= date.start && dateToCheck <= date.end) {
                                toAllow = false
                            }
                        } else {
    
                            const dateToCheckNew = dateToCheck.split("-")
                            dateToCheckNew.splice(0,1)
    
                            const dateStart = date.start.split("-")
                            dateStart.splice(0,1)
    
                            const dateEnd = date.end.split("-")
                            dateEnd.splice(0,1)
    
                            if (dateToCheckNew >= dateStart && dateToCheckNew <= dateEnd) {
                                toAllow = false
                            }
                        }
                    }

                })

                return toAllow
            }
        },
        created(){ // sets the cuttent date in the picker
            this.dates = [this.currentDateRange.start, this.currentDateRange.end]
        }
    }
</script>