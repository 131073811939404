<template>
    <v-card class="mt-2 card-ui">
        <v-toolbar dense elevation="0" class="grey darken-3">
            <v-card-title class="overline ml-0 pl-0">
                <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                <b class="grey--text darken-6">Health Status</b>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="runCheck()">Run Check</v-btn>
        </v-toolbar>
        <v-data-table :headers="headers" :items="currentHealthStats" sort-by="title" class="elevation-1 card-ui">
            <template v-slot:item.shortDescription="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ item.shortDescription }}</span>
                    </template>
                    <span>
                        {{ item.description }}
                    </span>
                </v-tooltip>
            </template>

            <template v-slot:item.severity="{ item }">
                <v-chip :color="item.severity === 'disabled' ? 'secondary' : severityColor(item.severity)" dark>
                    {{ item.severity }} - {{ item.severityDesc }}
                </v-chip>
            </template>

            <template v-slot:item.status="{ item }">
                <v-icon :color="computeStatusColor(item.status)">{{ computeStatusIcon(item.status) }}</v-icon>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" :disabled="!item.recordsLength"
                            @click="openDialog(item)">
                            <v-icon>fa-solid fa-eye</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        Show Records
                    </span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" :disabled="!item.recordsLength"
                            @click="downloadRecords(item.records, item.fileName)">
                            <v-icon>fa-solid fa-download</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        Download Records
                    </span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog transition="dialog-bottom-transition" max-width="1600" v-model="showDialog">
            <template v-slot:default="dialog">
                <v-card>
                    <v-toolbar color="primary" dark>{{ dialogData.title }}</v-toolbar>

                    <v-data-table :headers="dialogData.headers" :items="dialogData.records" class="elevation-1 card-ui ma-4"
                        items-per-page="5"></v-data-table>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

    </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import { jsonToWorksheet } from '@/utils/csvUtils'
import { toISOLocal } from '../../../utils/dates'

export default {
    data() {
        return {
            currentHealthStats: [],
            showDialog: false,
            dialogData: {},
            execDate: "",
        }
    },
    computed: {
        ...mapState({
            partnersListByFeature: state => state.integrationsSpec.partnersListByFeature,
            currentPG: state => state.core.currentPG,
            api: state => state.api.api,
        }),
        ...mapGetters({
            selectedPgCmRatesSupported: "mappingAndRates/selectedPgCmRatesSupported",
        }),
        healthChecks() {
            return [
                {
                    title: "Length of Stay Consistency",
                    shortDescription: "Checks the consistency of the check-in, check-out and stay-date of reservations.",
                    description: `The length of consistency checks whether the check-in, check-out and stay-date, regarding the reservations, are consistent.`,
                    url: `reservation/v2/health-check/los-consistency/${this.currentPG.metadata.id}`,
                    parsingFunc: this.defaultParser,
                    fileName: `${this.currentPG.metadata.id} - ${this.currentPG.metadata.name} - Length of Stay Consistency - ${this.execDate}`,
                    precondition: () => true,
                    disabledMessage: "",
                },
                {
                    title: "Total Amount Consistency",
                    shortDescription: "Checks if the field total_amount of a reservation is equal to the sum of the single night's prices.",
                    description: "Checks if the price sum of the reservations is equal to the sum of the single night prices.",
                    url: `reservation/v2/health-check/total-amount-consistency/${this.currentPG.metadata.id}`,
                    parsingFunc: this.defaultParser,
                    fileName: `${this.currentPG.metadata.id} - ${this.currentPG.metadata.name} - Total Amount Consistency - ${this.execDate}`,
                    precondition: () => true,
                    disabledMessage: "",
                },
                {
                    title: "Reservations in Invisible Properties",
                    shortDescription: "Checks if there are any reservations on not visible properties.",
                    description: "Checks if there are any reservations on not visible properties.",
                    url: `core/v1/propertygroup/${this.currentPG.metadata.id}/healthcheck/reservationsofinvisibleproperties`,
                    parsingFunc: this.defaultParser,
                    fileName: `${this.currentPG.metadata.id} - ${this.currentPG.metadata.name} - Reservations in Invisible Properties - ${this.execDate}`,
                    precondition: () => true,
                    disabledMessage: "",
                },
                {
                    title: "Last Downloaded Reservation",
                    shortDescription: "Checks the date of the last reservation inside the database.",
                    description: "Checks the date of the last reservation inside the database.",
                    url: `core/v1/propertygroup/${this.currentPG.metadata.id}/healthcheck/lastreservation`,
                    parsingFunc: this.lastReservationParser,
                    fileName: `${this.currentPG.metadata.id} - ${this.currentPG.metadata.name} - Last Downloaded Reservation - ${this.execDate}`,
                    precondition: () => true,
                    disabledMessage: "",
                },
                {
                    title: "Active Rooms Without Read ID",
                    shortDescription: "Checks if the are properties without pms id which have import reservations enabled.",
                    description: "Checks if the are properties without pms id which have import reservations enabled.",
                    url: `core/v1/propertygroup/${this.currentPG.metadata.id}/healthcheck/importwithoutpmsid`,
                    parsingFunc: this.defaultParser,
                    fileName: `${this.currentPG.metadata.id} - ${this.currentPG.metadata.name} - Active Rooms Without Read ID - ${this.execDate}`,
                    precondition: () => true,
                    disabledMessage: "",
                },
                {
                    title: "Write Configuration",
                    shortDescription: "Checks that all parameters needed to write the prices are present.",
                    description: "Checks that all parameters needed to write the prices are present.",
                    url: `core/v1/propertygroup/${this.currentPG.metadata.id}/healthcheck/writeconfiguration`,
                    parsingFunc: this.writeConfigParser,
                    fileName: `${this.currentPG.metadata.id} - ${this.currentPG.metadata.name} - Write Configuration - ${this.execDate}`,
                    precondition: () => true,
                    disabledMessage: "",
                },
                {
                    title: "Reservations and Num Units Consistency",
                    shortDescription: "Checks if there are properties with no units but with reservations.",
                    description: "Checks if there are properties with no units but with reservations.",
                    url: `core/v1/propertygroup/${this.currentPG.metadata.id}/healthcheck/reservationsnumunitsconsistency`,
                    parsingFunc: this.defaultParser,
                    fileName: `${this.currentPG.metadata.id} - ${this.currentPG.metadata.name} - Reservations and Num Units Consistency - ${this.execDate}`,
                    precondition: () => true,
                    disabledMessage: "",
                },
                {
                    title: "Last Sent Price",
                    shortDescription: "Checks the last sent price inside the database among computed prices and property rates prices.",
                    description: "Checks the last sent price inside the database among computed prices and property rates prices.",
                    url: `core/v1/propertygroup/${this.currentPG.metadata.id}/healthcheck/lastsentprices`,
                    parsingFunc: this.lastSentParser,
                    fileName: `${this.currentPG.metadata.id} - ${this.currentPG.metadata.name} - Last Sent Price - ${this.execDate}`,
                    precondition: () => true,
                    disabledMessage: "",
                },
                {
                    title: "Slopelike configuration",
                    shortDescription: "Check that the configuration of the rooms and rates is slopelike",
                    description: "Check that the configuration of the rooms and rates is slopelike",
                    url: `core/v1/propertygroup/${this.currentPG.metadata.id}/healthcheck/slopelikeconfiguration`,
                    parsingFunc: this.defaultParser,
                    fileName: `${this.currentPG.metadata.id} - ${this.currentPG.metadata.name} - Slopelike configuration - ${this.execDate}`,
                    precondition: async () => await this.pgIsSlopelike(),
                    disabledMessage: "Current PG does not support slopelike configuration.",
                }
            ]
        },
        headers() {
            return [
                { text: "Check Name", value: "title" },
                { text: "Description", value: "shortDescription" },
                { text: "Status", value: "status" },
                { text: "Serverity", value: "severity" },
                { text: "Actions", value: "actions" },
            ]
        }
    },
    watch: {
        currentPG(newVal, oldVal) {
            this.currentHealthStats = []
        }
    },
    methods: {
        ...mapActions({
            getPartnersByFeature: "integrationsSpec/getPartnersByFeature",
        }),
        async pgIsSlopelike() {
            await this.getPartnersByFeature({ featureId: 'sendAllOccupancies' })

            const pgPartner = this.partnersListByFeature.find(partner => partner.text === this.currentPG.spec.channel_manager_name)

            // Check if partner supports slopelike configuration, else do not run slopelike healthcheck
            if (!pgPartner || (JSON.parse(JSON.stringify(pgPartner)).value === false)) return false;

            return true;
        },
        async runCheck() {
            this.currentHealthStats = []
            this.execDate = toISOLocal(new Date()).split(".")[0].replaceAll("T", ' ')

            Promise.all(this.healthChecks.map(async (check) => {
                if (await check.precondition() === true) {
                    const res = await this.api.get(check.url, "", {}, {}, {})

                    const parsedRes = check.parsingFunc(res, check)
                    this.currentHealthStats.push(parsedRes)
                } else {
                    this.currentHealthStats.push({
                        title: check.title,
                        shortDescription: check.shortDescription,
                        description: check.description,
                        status: "disabled",
                        severity: "disabled",
                        severityDesc: check.disabledMessage,
                        records: [],
                        recordsLength: 0,
                        fileName: check.fileName,
                        executedAt: null,
                    })
                }
            }))
        },
        defaultParser(res, check) {
            let severity = ""
            if (res.recordsLength === 0) {
                severity = "none"
            } else if (res.recordsLength >= 1 && res.recordsLength <= 5) {
                severity = "low"
            } else if (res.recordsLength >= 6 && res.recordsLength <= 10) {
                severity = "medium"
            } else if (res.recordsLength >= 11 && res.recordsLength <= 20) {
                severity = "high"
            } else {
                severity = "very high"
            }

            return {
                title: check.title,
                shortDescription: check.shortDescription,
                description: check.description,
                status: res.success ? "success" : "error",
                severity: severity,
                severityDesc: `${res.recordsLength} records`,
                records: res.records,
                recordsLength: res.recordsLength,
                fileName: check.fileName,
                executedAt: res.executedAt,
            }
        },
        lastReservationParser(res, check) {

            const date1 = new Date(res.records[0].updated_at);
            const date2 = new Date();
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

            let severity = ""
            if (diffDays === 0) {
                severity = "none"
            } else if (diffDays >= 1 && diffDays <= 2) {
                severity = "low"
            } else if (diffDays >= 3 && diffDays <= 8) {
                severity = "medium"
            } else if (diffDays >= 9 && diffDays <= 15) {
                severity = "high"
            } else {
                severity = "very high"
            }

            return {
                title: check.title,
                shortDescription: check.shortDescription,
                description: check.description,
                status: severity === "none" ? "success" : "error",
                severity: severity,
                severityDesc: `Last reservation ${diffDays} days ago`,
                records: res.records,
                recordsLength: 1,
                fileName: check.fileName,
                executedAt: res.executedAt,
            }
        },
        lastSentParser(res, check) {
            const date1 = new Date(res.records[0].last_send_with_success);
            const date2 = new Date();
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

            let severity = ""
            if (diffDays === 0) {
                severity = "none"
            } else if (diffDays >= 1 && diffDays <= 4) {
                severity = "high"
            } else {
                severity = "very high"
            }

            return {
                title: check.title,
                shortDescription: check.shortDescription,
                description: check.description,
                status: severity === "none" ? "success" : "error",
                severity: severity,
                severityDesc: `Last sent was ${diffDays} days ago`,
                records: res.records,
                recordsLength: 1,
                fileName: check.fileName,
                executedAt: res.executedAt,
            }
        },

        writeConfigParser(res, check) {

            if (!this.selectedPgCmRatesSupported) {
                res.records.forEach(record => {
                    record.channel_manager_rate_id_is_null = false
                })
            }

            res.records = res.records.filter(rec => {
                return rec.channel_manager_rate_id_is_null && rec.channel_manager_id_is_null && rec.extraparams_schema_not_compliant;
            });
            res.recordsLength = res.records.length
            res.success = !res.recordsLength

            let severity = ""
            if (res.recordsLength === 0) {
                severity = "none"
            } else if (res.recordsLength >= 1 && res.recordsLength <= 5) {
                severity = "low"
            } else if (res.recordsLength >= 6 && res.recordsLength <= 10) {
                severity = "medium"
            } else if (res.recordsLength >= 11 && res.recordsLength <= 20) {
                severity = "high"
            } else {
                severity = "very high"
            }

            return {
                title: check.title,
                shortDescription: check.shortDescription,
                description: check.description,
                status: res.success ? "success" : "error",
                severity: severity,
                severityDesc: `${res.recordsLength} records`,
                records: res.records,
                recordsLength: res.recordsLength,
                fileName: check.fileName,
                executedAt: res.executedAt,
            }
        },
        severityColor(severity) {
            switch (severity) {
                case "none":
                    return "success"
                case "low":
                    return "amber darken-1"
                case "medium":
                    return "orange accent-4"
                case "high":
                    return "red darken-1"
                case "very high":
                    return "red accent-4"
            }
        },
        async downloadRecords(records, fileName) {
            await jsonToWorksheet(records, `${fileName}.csv`, "csv")
        },
        openDialog(item) {
            this.showDialog = false
            this.dialogData = {}
            this.dialogData = {
                title: item.title,
                headers: Object.keys(item.records[0]).map(key => {
                    return {
                        text: key,
                        value: key,
                    }
                }),
                records: item.records,
            }
            this.showDialog = true
        },
        computeStatusColor(status) {
            if (status === "success" || status === "error") return status
            if (status === "disabled") return "secondary"
        },
        computeStatusIcon(status) {
            if (status === "success") return "fa-solid fa-check"
            if (status === "error") return "fa-solid fa-xmark"
            if (status === "disabled") return "fa-solid fa-minus"
        },
    }
}
</script>
