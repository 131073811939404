<template>
  <perfect-scrollbar>

  <v-card class="ma-3">

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        fab
        small
        color="indigo"
        outlined
        :disabled="!canAddReconciliation"
        @click="addReconciliation()"
      >
        <v-icon>fa-solid fa-plus fa-2x</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>

    <DynamicForm
      v-for="(rec, index) in reconciliationArrayLocal" :key="index"
      v-bind:value="reconciliationArrayLocal[index]"
      @input="updateLocalReconciliation($event, index)"
      :schema="getReconciliationDict"
      @delete="deleteLocalReconciliation(index)"
      @selected-type="selectedTypeHandler(index, $event)"
      :basetype="reconciliationType[index]"
      :showDelete="true"
      :editable="editable"
      ref="dynamicForm"
    />

  </v-card>
  </perfect-scrollbar>

</template>



<script>
//https://medium.com/cbazil-dev/reusable-components-using-vue-vuetify-fbb46601a83b
const yaml = require('js-yaml')
import DynamicForm from '@/components/form/DynamicForm'
import { mapGetters, mapState } from 'vuex'

export default {
  props: ['reconciliationsData', 'editable'],
  components: {DynamicForm},
  data() {
    return {
      menu: [],
      propertiesId: [],
      reconciliationArray: null,
      reconciliationLocalArray: [],
      reconciliationType: [],
      pgProperties: [],
      pgBoardTypes: [],
      pgOriginChannelTypes: [],
      pgRateDescriptions: [],
      deltaSchema:                 {
        type: "one_of",
        text: "Delta Type",
        dict: {
          deltaPerGuest: {
            text: "Delta Per Guest",
            value: [
              {
                type: "number",
                label: "Delta Per Guest",
                key: "delta_per_guest"
              }
            ]
          },
          otherDelta: {
            text: "other delta",
            value: [
              {
                type: "all",
                value: [
                  {
                    type: "number",
                    label: "delta",
                    key: "delta"
                  },
                  {
                    type: "number",
                    label: "coefficient",
                    key: "coefficient"
                  }
                ]
              }
            ]
          }
        }
      },
      toggleItemsSelectAll: (self, cb) => {
        if(self.allItemsSelected){
          self.selectedItems = []
        } else {
          const valuesToAdd = self.schema.value.map(i => i.value)
          self.selectedItems = valuesToAdd
        }
        cb()
      },
      customSetSelectedItems: (self) => {
        if(self.allItemsSelected){
          self.computedValue[self.schema.key] = "all"
        } else if(self.selectedItems.length === 0){
          self.$delete(self.computedValue, self.schema.key)
        } else {
          self.computedValue[self.schema.key] = self.selectedItems
        }
      }
    }
  },

  methods: {
    editReconciliationDispatch(value){
      this.$store.dispatch('strategy/editReconciliation', value);
    },
    addReconciliation(){
      this.reconciliationArrayLocal.push({})
    },
    deleteLocalReconciliation(index){
      let newReconciliation = [...this.reconciliationArrayLocal]
      newReconciliation.splice(index, 1)
      this.reconciliationType.splice(index,1)
      this.reconciliationArrayLocal = newReconciliation
      this.$forceUpdate()
    },
    updateLocalReconciliation(obj, index){
      let newReconciliation = [...this.reconciliationArrayLocal]
      newReconciliation[index] = obj
      this.reconciliationArrayLocal = newReconciliation
    },
    selectedTypeHandler(index, obj){
      this.reconciliationType.splice(index,1, obj)
    },
    async fetchPgBoardTypes() {
      let fetchResult = await this.$store.dispatch('reservations/fetchPgBoardTypesList', { pgId: this.currentPG.metadata.id})
      fetchResult = fetchResult.map( (a) => { return a.metadata.name} )
      this.pgBoardTypes = fetchResult
    },
    async fetchPgRateDescriptions() {
      let fetchResult = await this.$store.dispatch('reservations/fetchPgRateDescriptionsList', { pgId: this.currentPG.metadata.id})
      fetchResult = fetchResult.map( (a) => { return a.metadata.name} )
      this.pgRateDescriptions = fetchResult
    },
    async fetchPgOriginChannelTypes() {
      let fetchResult = await this.$store.dispatch('reservations/fetchPgOriginChannelTypesList', { pgId: this.currentPG.metadata.id})
      fetchResult = fetchResult.map( (a) => { return a.metadata.name} )
      this.pgOriginChannelTypes = fetchResult
    },
    cleanReconciliation(reconciliation){
      reconciliation.forEach(rec => {
        rec.propertyIds = rec.propertyIds?.map(id => Number(id))
        delete rec.type
      })
      reconciliation = JSON.parse(JSON.stringify(reconciliation))
      reconciliation = reconciliation.filter(rec => Object.keys(rec).length > 0)

      if(reconciliation.length === 0){
        reconciliation = undefined
      }
      return reconciliation
    }
  },//end methods
  computed: {
    ...mapState({
      currentPG: state => state.core.currentPG,
    }),
    ...mapGetters({
      allowedProperties: "pipelineV1/allowedProperties",
    }),
    reconciliation: {
      get(){
        this.reconciliationArray = yaml.load(this.$store.state.editor.codeToRender)?.['reconciliations'] || []
        return this.reconciliationArray
      },
      set(rec){
        rec = rec? cleanReconciliation(JSON.parse(JSON.stringify(rec))) : null
        var parameters = yaml.load(this.$store.state.editor.codeToRender);

        if ( parameters['reconciliations'] == undefined && rec?.length > 0){
          parameters['reconciliations'] = []
        }

        if(!rec){
          delete parameters['reconciliations']
        } else {
          parameters['reconciliations'] = rec
        }
        this.state.editor.codeToRender = yaml.dump(parameters, {ref: false});
      }
    },
    reconciliationArrayLocal: {
      get(){
        this.reconciliationLocalArray.splice(0, this.reconciliation.length, ...this.reconciliation)
        return this.reconciliationLocalArray
      },
      set(newValue){
        this.reconciliationLocalArray = newValue
        this.reconciliation = newValue
      }
    },
    disableAutoReconciliations(){
      return this.reconciliationArrayLocal.some(o => o.reconciliation_type === 'auto')
    },
    getGlobalPropertyIds(){
      return yaml.load(this.$store.state.editor.codeToRender)?.['global']?.['property_ids'] || []
    },
    canAddReconciliation(){
      return this.reconciliationLocalArray.filter(r => Object.keys(r).length === 0).length < 1 && this.editable
    },
    getPgProperties(){
      return this.allowedProperties.map(prop => {
        return {
          text: `${prop.metadata.id} - ${prop.metadata.name}`,
          value: Number(prop.metadata.id)
        }
      })
    },
    getPgBoardTypes() {
      return this.pgBoardTypes
    },
    getPgRateDescriptions() {
      return this.pgRateDescriptions
    },
    getPgOriginChannelTypes() {
      return this.pgOriginChannelTypes
    },
    getReconciliationDict(){
      return [
        {
          type: "typed_one_of",
          text: "Type",
          key: "reconciliation_type",
          dict: {
            board: {
              text: "Board",
              value: [
                {
                  type: "select",
                  label: "Board Type",
                  key: "board_type",
                  value: this.getPgBoardTypes
                },
                this.deltaSchema,
                {
                  type: "multiselect",
                  label: "Properties",
                  key: "property_id",
                  value: this.getPgProperties,
                  toggleItemsSelectAll: this.toggleItemsSelectAll,
                  customSetSelectedItems: this.customSetSelectedItems,
                  explicitAllSelected: true
                }
              ],
            },
            channel: {
              text: "Channel",
              value: [
                {
                  type: "select",
                  label: "Origin Channel Type",
                  key: "origin_channel_type",
                  value: this.getPgOriginChannelTypes
                },
                this.deltaSchema,
                {
                  type: "multiselect",
                  label: "Properties",
                  key: "property_id",
                  value: this.getPgProperties,
                  toggleItemsSelectAll: this.toggleItemsSelectAll,
                  customSetSelectedItems: this.customSetSelectedItems,
                  explicitAllSelected: true
                }
              ],
            },
            rate: {
              text: "Rate",
              value: [
                {
                  type: "select",
                  label: "Rate Description",
                  key: "rate_description",
                  value: this.getPgRateDescriptions
                },
                this.deltaSchema,
                {
                  type: "multiselect",
                  label: "Properties",
                  key: "property_id",
                  value: this.getPgProperties,
                  toggleItemsSelectAll: this.toggleItemsSelectAll,
                  customSetSelectedItems: this.customSetSelectedItems,
                  explicitAllSelected: true
                }
              ],
            },
            guest: {
              text: "Guest",
              value: [
                {
                  type: "number",
                  label: "Guest Count",
                  key: "guest_count",
                },
                {
                  type: "number",
                  label: "delta",
                  key: "delta"
                },
                {
                  type: "number",
                  label: "coefficient",
                  key: "coefficient"
                },
                {
                  type: "multiselect",
                  label: "Properties",
                  key: "property_id",
                  value: this.getPgProperties,
                  toggleItemsSelectAll: this.toggleItemsSelectAll,
                  customSetSelectedItems: this.customSetSelectedItems,
                  explicitAllSelected: true
                }
              ],
            },
            auto: {
              text: "Auto",
              disabled: this.disableAutoReconciliations,
              value: [
                {
                  type: "boolean",
                  label: "Per Pax",
                  key: "perpax",
                  fieldsToClean: ["rate_map"]
                },
                {
                  type: "map",
                  label: "Rate Map",
                  key: "rate_map",
                  enableIf: "perpax"
                },
                {
                  type: "select",
                  label: "Origin Channel Type",
                  key: "origin_channel_type",
                  value: this.getPgOriginChannelTypes
                },
              ],
            },
          }
        }
      ]
    }

  },
  created(){
    this.fetchPgBoardTypes()
    this.fetchPgRateDescriptions()
    this.fetchPgOriginChannelTypes()
  },
}
</script>

<style scoped>
</style>
