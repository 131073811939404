<template>
  <v-card>
    <v-card-text>
      <v-tabs
        v-model="tab"
      >
        <v-tab
          v-for="item in items"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item"
        >
          <v-card flat>
            <v-card-text>
              <EventForm
                :type="item"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

  </v-card>

</template>

<script>
import EventForm from '../components/EventForm'

export default {
  name: 'Events',
  components: {EventForm},
  data: function (argument) {
    return {
      tab: null,
      items: [
        'add', 'edit'
      ]
    }
  }
}
</script>
