import _ from "lodash"
import Vue from "vue"
import dateUtils from "@/utils/dateUtils"

export default {
    namespaced: true,

    state: {
        blockBasePrice: false,

        // both sections
        basePrice: {
            dateValues: [],
            basePriceValues: [],
            propertyValues: [],
        },

        appliedModifiers: [],
        valuesByPropertyWithModifiers: null,

        prodBasePrice: {
            dateValues: [],
            basePriceValues: [],
            propertyValues: [],
        },

        basePriceInfo: {
            metadataInfo: {},
            editsHistory: [],
        },

        minSpan7Days: true,

        // generation section
        marketList: [],

        selectedGenerationSource: null,

        // by trendline
        selectedTrendlineValues: {
            dowStartDay: 0,
            period: "s_week",
            smoothing: null,

            basePriceMap: {

            },
            pgPeaksRemovalDegree: 3,

            internalMarketPeaksRemovalDegree: 3,
            internalMarketId: null,

            externalMarketPeaksRemovalDegree: 3,

            globalPgWeight: 0,
            globalInternalMarketWeight: 0,
            globalExternalMarketWeight: 0,

            trendlineLocalMixList: []
        },

        // generation source
        generationSourceToSend: {},

        // dataframes
        dataFrames: {},

        // base prices history
        basePriceVersionHistory: [],
        basePriceVersionHistorySpec: {},

        // preview with base price data
        previewDataByProperty: {},
    },

    getters: {

        basePriceGenerated(state, getters) {
            return !!state.prodBasePrice.dateValues.length
        },

        allowedBasePriceProperties(state, getters, rootState, rootGetters) {
            return rootGetters["pipelineV1/allowedProperties"].filter(prop => prop.spec?.graph_json?.join(",") === "NotebookPredictedPrice,Tweak,ThresholdPrevent,FrozenPrice")
        },

        allowedBasePricePropertiesDropdown(state, getters) {
            return getters.allowedBasePriceProperties.map(prop => {
                return {
                    text: `${prop.metadata.id} - ${prop.metadata.name}`,
                    value: prop.metadata.id,
                }
            })
        },

        allowedBasePricePropertiesIds(state, getters) {
            return getters.allowedBasePriceProperties.map(prop => prop.metadata.id)
        },

        prodValuesByProperty(state, getters) { // generates an object with the prop id as keys and inside two arrays with dates and base prices
            const properties = {}
            if (state.prodBasePrice !== undefined) {
                // calculate the propety: {dates, values} obj
                const allowedBasePricePropertiesIds = getters.allowedBasePricePropertiesIds

                const prodBasePriceValues = state.prodBasePrice.basePriceValues
                const prodBasePriceDateValues = state.prodBasePrice.dateValues

                state.prodBasePrice.propertyValues.forEach((propId, index) => {
                    if (allowedBasePricePropertiesIds.includes(String(propId))) {
                        if (!properties[propId]) {
                            properties[propId] = {
                                dates: [],
                                values: [],
                            }
                        }
                        const value = prodBasePriceValues[index]
                        const date = prodBasePriceDateValues[index]

                        properties[propId].values.push(value)
                        properties[propId].dates.push(date)
                    }
                })
            }
            return properties
        },

        dateRangeValuesByProperty(state, getters) {
            if (!state?.valuesByPropertyWithModifiers) return null

            const dateRangeValuesByProperty = {}
            for (const [propId, propValues] of Object.entries(state?.valuesByPropertyWithModifiers)) {

                if (propValues.dates.length && propValues.values.length) {

                    const dates = propValues.dates.map(date => new Date(date))
                    const prices = propValues.values

                    const groupedPrices = [];
                    let startDate = dates[0];
                    let currentPrice = prices[0];

                    for (let i = 1; i < dates.length; i++) {
                        if (prices[i] !== currentPrice) {
                            if (dates[i - 1].getTime() === startDate.getTime()) {
                                groupedPrices.push({
                                    startDate: startDate.toISOString().split('T')[0],
                                    endDate: startDate.toISOString().split('T')[0],
                                    price: currentPrice,
                                    days: dateUtils.daysDifference(startDate, startDate, 1)
                                });
                            } else {
                                groupedPrices.push({
                                    startDate: startDate.toISOString().split('T')[0],
                                    endDate: dates[i - 1].toISOString().split('T')[0],
                                    price: currentPrice,
                                    days: dateUtils.daysDifference(startDate, dates[i - 1], 1)
                                });
                            }
                            startDate = dates[i];
                            currentPrice = prices[i];
                        }
                    }

                    // Add the last group
                    if (dates[dates.length - 1].getTime() === startDate.getTime()) {
                        groupedPrices.push({
                            startDate: startDate.toISOString().split('T')[0],
                            endDate: startDate.toISOString().split('T')[0],
                            price: currentPrice,
                            days: dateUtils.daysDifference(startDate, startDate, 1)
                        });
                    } else {
                        groupedPrices.push({
                            startDate: startDate.toISOString().split('T')[0],
                            endDate: dates[dates.length - 1].toISOString().split('T')[0],
                            price: currentPrice,
                            days: dateUtils.daysDifference(startDate, dates[dates.length - 1], 1)
                        });
                    }


                    dateRangeValuesByProperty[propId] = groupedPrices.map(value => {
                        const newValue = value
                        if (newValue.price !== null) {
                            newValue.price = Math.round(newValue.price)
                        }
                        return newValue
                    })
                }
            }

            return dateRangeValuesByProperty
        },

        propertiesWithInvalidDateRanges(state, getters) {
            if (!getters?.dateRangeValuesByProperty) return null

            const propertiesList = []
            if (state.minSpan7Days) {
                const today = new Date().toISOString().split("T")

                Object.entries(getters.dateRangeValuesByProperty).forEach(([propId, values]) => {
                    if (values.map(val => val).some(val => val.days < 7 && val.startDate > today)) {
                        propertiesList.push(propId)
                    }
                })
            }

            return propertiesList
        },

        valuesModifiedToSend(state, getters) {
            const result = {
                date: [],
                propertyId: [],
                price: []
            };

            for (const propertyId in state.valuesByPropertyWithModifiers) {
                const property = state.valuesByPropertyWithModifiers[propertyId];

                for (let i = 0; i < property.dates.length; i++) {
                    result.date.push(property.dates[i]);
                    result.propertyId.push(Number(propertyId));
                    result.price.push(Math.round(property.values[i]))
                }
            }

            return result;
        },


        finalObjForGeneration(state, getters) {
            // to define how to calculate the other fields

            const bodyToSend = {
                global: {},
                base_price_config: {}
            }

            bodyToSend.base_price_config["trendline"] = {
                dow_start_day: state.selectedTrendlineValues.dowStartDay,
                tl_period: state.selectedTrendlineValues.period,
                smoothing: state.selectedTrendlineValues.smoothing,
                pg: {
                    peaks_removal_degree: state.selectedTrendlineValues.pgPeaksRemovalDegree,
                },
                internal_mkt: {
                    peaks_removal_degree: state.selectedTrendlineValues.internalMarketPeaksRemovalDegree,
                },
                external_mkt: {
                    peaks_removal_degree: state.selectedTrendlineValues.externalMarketPeaksRemovalDegree,
                },
                mix: {
                    global: {
                        pg: state.selectedTrendlineValues.globalPgWeight,
                        internal_mkt: state.selectedTrendlineValues.globalInternalMarketWeight,
                        external_mkt: state.selectedTrendlineValues.globalExternalMarketWeight,
                    },
                    local: state.selectedTrendlineValues.trendlineLocalMixList.map(value => {
                        return {
                            startDate: value.startDate,
                            endDate: value.endDate,
                            pg: value.pgWeight,
                            internal_mkt: value.internalMarketWeight,
                            external_mkt: value.externalMarketWeight,
                        }
                    }),
                }
            }
            bodyToSend.global.market_subscription = state.selectedTrendlineValues.internalMarketId

            if (state.selectedTrendlineValues.basePriceMap) bodyToSend.base_price_config.trendline.base_price_map = {}
            for (let [key, value] of Object.entries(state.selectedTrendlineValues.basePriceMap)) {
                if (/* value !== undefined && value !== null */ !!value) {
                    bodyToSend.base_price_config.trendline.base_price_map[key] = value
                }
            }
            return bodyToSend
        },

        finalObjToSubmit(state, getters) {


            const appliedModifiers = structuredClone(state.appliedModifiers).map(value => {
                const modifier = value
                if ("value" in modifier) {
                    modifier.value = Number(modifier.value)
                }
                return modifier
            })

            return {
                apiVersion: "pricelist/v2",
                kind: "Baseprice",
                metadata: state.basePriceInfo.metadataInfo,
                spec: {
                    source: state.generationSourceToSend,
                    edits: state.basePriceInfo.editsHistory.concat(appliedModifiers),
                    min_span_7_days: state.minSpan7Days,
                },
                value: getters.valuesModifiedToSend
            }

        }

    },

    mutations: {

        resetBlockBasePrice(state, payload) {
            state.blockBasePrice = false
            if (!!payload?.value) {
                state.blockBasePrice = payload.value
            }
        },

        // base price values
        updateBasePriceValues(state, payload) {
            Vue.set(state.basePrice, "dateValues", payload.dates)
            Vue.set(state.basePrice, "basePriceValues", payload.basePrice)
            Vue.set(state.basePrice, "propertyValues", payload.property)
        },

        resetAppliedModifiers(state, payload) {
            state.appliedModifiers = null
            if (!!payload?.values) {
                state.appliedModifiers = []
                payload.values.forEach(val => {
                    state.appliedModifiers.push(val)
                })
            }
        },

        updateValuesByPropertyWithModifiers(state, payload) {
            state.valuesByPropertyWithModifiers = payload.value
        },

        updateProdBasePriceValues(state, payload) {
            Vue.set(state.prodBasePrice, "dateValues", payload.dates)
            Vue.set(state.prodBasePrice, "basePriceValues", payload.basePrice)
            Vue.set(state.prodBasePrice, "propertyValues", payload.property)
        },

        resetBasePriceValues(state) {
            Vue.set(state.basePrice, "dateValues", [])
            Vue.set(state.basePrice, "basePriceValues", [])
            Vue.set(state.basePrice, "propertyValues", [])
        },

        resetProdBasePriceValues(state) {
            Vue.set(state.prodBasePrice, "dateValues", [])
            Vue.set(state.prodBasePrice, "basePriceValues", [])
            Vue.set(state.prodBasePrice, "propertyValues", [])
        },

        // min span
        updateMinSpanFlag(state, payload) {
            state.minSpan7Days = payload.value
        },

        // generation section items and lists

        updateMarketList(state, payload) {
            payload.items.forEach(market => {
                state.marketList.push({
                    text: market.metadata?.id?.replaceAll("_", " "),
                    value: market.metadata.id
                })
            })
        },

        resetMarketList(state) {
            state.marketList = []
        },

        updateCustomerBarList(state, payload) {
            payload.customerBarList.forEach(customerBar => {
                state.customerBarList.push(customerBar)
            })
        },

        resetCustomerBarList(state) {
            state.customerBarList = []
        },

        // generations section

        updateSelectedGenerationSource(state, payload) {
            state.selectedGenerationSource = payload.selected
        },

        resetSelectedGenerationValues(state) {
            // trendline
            state.selectedTrendlineValues = {
                dowStartDay: 0,
                period: "s_week",
                smoothing: null,
                basePriceMap: {
                },

                pgPeaksRemovalDegree: 3,
                internalMarketPeaksRemovalDegree: 3,
                externalMarketPeaksRemovalDegree: 3,

                internalMarketId: null,
                globalPgWeight: 0,
                globalInternalMarketWeight: 0,
                globalExternalMarketWeight: 0,
                trendlineLocalMixList: []
            }
        },

        updateSelectedTrendlineValues(state, payload) {
            Vue.set(state.selectedTrendlineValues, payload.key, payload.value)
        },

        updateSelectedTrendlineBasePriceMapValues(state, payload) {
            Vue.set(state.selectedTrendlineValues.basePriceMap, payload.key, payload.value)
        },

        // trendline mix local
        addTrendlineMix(state, payload) {
            state.selectedTrendlineValues.trendlineLocalMixList.push({
                startDate: payload.startDate || "",
                endDate: payload.endDate || "",
                pgWeight: payload.pgWeight || 0,
                internalMarketWeight: payload.internalMarketWeight || 0,
                externalMarketWeight: payload.externalMarketWeight || 0,
            })
        },

        updateTrendlineMix(state, payload) {
            Vue.set(state.selectedTrendlineValues.trendlineLocalMixList[payload.index], payload.key, payload.value)
        },

        deleteTrendlineMix(state, payload) {
            state.selectedTrendlineValues.trendlineLocalMixList.splice(payload.index, 1)
        },

        updateBasePriceInfoMetadata(state, payload) {
            Vue.set(state.basePriceInfo.metadataInfo, payload.key, payload.value)
        },

        updateBasePriceInfoSource(state, payload) {
            Vue.set(state.basePriceInfo.sourceInfo, payload.key, payload.value)
        },

        updateBasePriceEditsHistory(state, payload) {
            payload.values.forEach(value => {
                state.basePriceInfo.editsHistory.push(value)
            })
        },

        resetBasePriceInfo(state) {
            Vue.set(state.basePriceInfo, "metadataInfo", {})
            Vue.set(state.basePriceInfo, "sourceInfo", {})
            Vue.set(state.basePriceInfo, "editsHistory", [])
        },

        resetGenerationSourceToSend(state) {
            state.generationSourceToSend = {}
        },

        updateGenerationSourceToSend(state, payload) {
            state.generationSourceToSend = payload.value
        },

        resetDataFrames(state) {
            state.dataFrames = {}
        },

        updateDataFrames(state, payload) {
            state.dataFrames = payload.dfs
        },

        // base price version history
        resetBasePriceVersHistory(state, payload) {
            state.basePriceVersionHistory = []

            if (payload.basePriceVersionHistory !== undefined) {
                payload.basePriceVersionHistory.forEach(vers => {
                    state.basePriceVersionHistory.push(vers)
                })
            }
        },

        updateBasePriceVersHistorySpec(state, payload) {
            state.basePriceVersionHistorySpec[payload.versionId] = payload.spec
        },

        // preview predict with base price
        resetPreviewDataByProperty(state, payload) {
            state.previewDataByProperty = {}
            if (!!payload?.value) {
                state.previewDataByProperty = payload.value
            }
        }
    },

    actions: {

        async loadBasePrice(context, payload) {
            try {
                context.commit("updateBasePriceValues", {
                    dates: payload.basePrice.value.date || [],
                    basePrice: payload.basePrice.value.price || [],
                    property: payload.basePrice.value.propertyId || [],
                })

                if (!!payload.basePrice?.value?.date) {
                    // load also the properties with modifiers
                    const combinedArray = payload.basePrice?.value?.date?.map((date, index) => ({
                        date,
                        price: payload?.basePrice?.value?.price[index],
                        room: payload?.basePrice?.value?.propertyId[index],
                    }));

                    // sort values
                    combinedArray.sort((a, b) => new Date(a.date) - new Date(b.date));

                    // create array for values by property
                    const valuesByProp = {}
                    for (let prop of Array.from(new Set(payload?.basePrice?.value?.propertyId))) {
                        valuesByProp[prop] = {
                            values: [],
                            dates: [],
                        }
                    }

                    for (let i = 0; i < combinedArray.length; i++) {
                        const { date, price, room } = combinedArray[i];
                        valuesByProp[room].values.push(price)
                        valuesByProp[room].dates.push(date)
                    }

                    context.commit("updateValuesByPropertyWithModifiers", { value: valuesByProp })
                }

                if (payload?.changeProdValues !== undefined && !!payload?.changeProdValues) {
                    context.commit("updateProdBasePriceValues", {
                        dates: payload.basePrice.value?.date || [],
                        basePrice: payload.basePrice.value?.price || [],
                        property: payload.basePrice.value?.propertyId || [],
                    })
                }

                context.commit("updateBasePriceInfoMetadata", { key: "key", value: payload.basePrice.metadata.key })


                if (payload.basePrice.spec?.edits) context.commit("updateBasePriceEditsHistory", { values: payload.basePrice.spec?.edits })

                context.commit("updateMinSpanFlag", { value: payload.basePrice.spec?.min_span_7_days })

                if (typeof payload.basePrice.spec?.source !== "string") {
                    context.commit("updateGenerationSourceToSend", {
                        value: structuredClone(payload.basePrice.spec.source)
                    })

                    // there are still base-prices generated using pricelist, but the UI is dead
                    if ("trendline" in ( payload.basePrice.spec?.source?.job?.spec?.parameters?.base_price_config ?? {} ) ) {
                        context.commit("updateSelectedGenerationSource", { selected: "trendline" })

                        const trendLine = payload.basePrice.spec?.source?.job?.spec?.parameters?.base_price_config?.trendline

                        context.commit("updateSelectedTrendlineValues", { key: "dowStartDay", value: trendLine.dow_start_day })
                        context.commit("updateSelectedTrendlineValues", { key: "period", value: trendLine.tl_period })
                        context.commit("updateSelectedTrendlineValues", { key: "basePriceMap", value: trendLine.base_price_map })
                        context.commit("updateSelectedTrendlineValues", { key: "smoothing", value: trendLine.smoothing })

                        context.commit("updateSelectedTrendlineValues", { key: "internalMarketId", value: payload.basePrice.spec.source.job.spec.parameters.global.market_subscription })

                        context.commit("updateSelectedTrendlineValues", { key: "pgPeaksRemovalDegree", value: trendLine?.pg?.peaks_removal_degree || 3 })
                        context.commit("updateSelectedTrendlineValues", { key: "internalMarketPeaksRemovalDegree", value: trendLine?.internal_mkt?.peaks_removal_degree || 3 })

                        if (!!context.rootState.core.currentPG.spec.booking_id) {
                            context.commit("updateSelectedTrendlineValues", { key: "externalMarketPeaksRemovalDegree", value: trendLine?.external_mkt?.peaks_removal_degree || 3 })
                        } else {
                            context.commit("updateSelectedTrendlineValues", { key: "externalMarketPeaksRemovalDegree", value: 0 })
                        }

                        context.commit("updateSelectedTrendlineValues", { key: "globalPgWeight", value: trendLine.mix.global?.pg || 0 })
                        context.commit("updateSelectedTrendlineValues", { key: "globalInternalMarketWeight", value: trendLine.mix.global?.internal_mkt || 0 })

                        if (!!context.rootState.core.currentPG.spec.booking_id) {
                            context.commit("updateSelectedTrendlineValues", { key: "globalExternalMarketWeight", value: trendLine.mix.global?.external_mkt || 0 })
                        } else {
                            context.commit("updateSelectedTrendlineValues", { key: "globalExternalMarketWeight", value: 0 })
                        }


                        trendLine.mix.local.forEach(localMix => {
                            context.commit("addTrendlineMix", {
                                startDate: localMix.startDate,
                                endDate: localMix.endDate,
                                pgWeight: localMix.pg,
                                internalMarketWeight: localMix.internal_mkt,
                                externalMarketWeight: !!context.rootState.core.currentPG.spec.booking_id ? localMix.external_mkt : 0,
                            })
                        })
                    }
                }

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error loading the base price: ${error}`,
                    timeout: -1
                }, { root: true })
            }
        },

        async getBasePrice(context, payload) {
            const api = context.rootState.api.api

            try {
                const resp = await api.get("pricelist/v2", `/Baseprice/propertyset.${payload.psId}-master`, {}, {}, {})

                context.commit("resetSelectedGenerationValues")
                context.commit("resetDataFrames")
                context.commit("resetPreviewDataByProperty")
                //context.commit("resetModifiersList")
                context.commit("resetBasePriceValues")
                context.commit("resetProdBasePriceValues")
                context.commit("resetBasePriceInfo")
                context.commit("resetGenerationSourceToSend")
                context.commit("resetBlockBasePrice")

                if (resp.items.length > 1) {
                    context.commit("resetBlockBasePrice", { value: true })
                    throw "Error found more than one base prices"
                }

                if (resp.items.length) {
                    await context.dispatch("loadBasePrice", { basePrice: resp.items[0], changeProdValues: true })
                } else {
                    context.commit("updateBasePriceInfoMetadata", { key: "key", value: `propertyset.${payload.psId}-master` })

                    context.dispatch("alertSystem/newWarning", {
                        message: `The base price is currently missing, generate it for the first time`,
                        timeout: 5000
                    }, { root: true })
                }

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the base price: ${error}`,
                    timeout: -1
                }, { root: true })
            }
        },

        async magicWand(context, payload) {
            const api = context.rootState.api.api

            try {
                const resJob = await api.get("predictjob/v2", `/master/pipelinebindtemplate/inference.baseprice.v1?pss_id=${payload.psId}`, {}, {}, {})

                const jobSpec = resJob.items[0].resource
                const resp = await api.put('predict/v1', '/job', { priority: context.rootState.pipelineV1.globalRequestPriority }, [{ job: jobSpec, dryRun: false, force: true }], {});

                if (!!resp.results[0]?.result?.error) throw resp.results[0].result.error.error

                const parameters = resp.results[0].result.parameters

                if (parameters.base_price_config.trendline !== undefined) {
                    context.commit("updateSelectedGenerationSource", { selected: "trendline" })

                    const trendLine = parameters.base_price_config.trendline
                        
                    context.commit("updateSelectedTrendlineValues", { key: "dowStartDay", value: trendLine.dow_start_day })
                    context.commit("updateSelectedTrendlineValues", { key: "period", value: trendLine.tl_period })
                    context.commit("updateSelectedTrendlineValues", { key: "basePriceMap", value: trendLine.base_price_map })

                    context.commit("updateSelectedTrendlineValues", { key: "pgPeaksRemovalDegree", value: trendLine.pg.peaks_removal_degree })
                    context.commit("updateSelectedTrendlineValues", { key: "internalMarketPeaksRemovalDegree", value: trendLine.internal_mkt.peaks_removal_degree })
                    
                    if (!!context.rootState.core.currentPG.spec.booking_id) {
                        context.commit("updateSelectedTrendlineValues", { key: "externalMarketPeaksRemovalDegree", value: trendLine.external_mkt.peaks_removal_degree })
                    } else {
                        context.commit("updateSelectedTrendlineValues", { key: "externalMarketPeaksRemovalDegree", value: 0 })
                    }

                    context.commit("updateSelectedTrendlineValues", { key: "internalMarketId", value: parameters.global.market_subscription })
                    
                    context.commit("updateSelectedTrendlineValues", { key: "globalPgWeight", value: trendLine.mix.global.pg })
                    context.commit("updateSelectedTrendlineValues", { key: "globalInternalMarketWeight", value: trendLine.mix.global.internal_mkt })

                    if (!!context.rootState.core.currentPG.spec.booking_id) {
                        context.commit("updateSelectedTrendlineValues", { key: "globalExternalMarketWeight", value: trendLine.mix.global.external_mkt })
                    } else {
                        context.commit("updateSelectedTrendlineValues", { key: "globalExternalMarketWeight", value: 0 })
                    }

                    
                    trendLine.mix.local.forEach(localMix => {
                        context.commit("addTrendlineMix", {
                            startDate: localMix.startDate,
                            endDate: localMix.endDate,
                            pgWeight: localMix.pg,
                            internalMarketWeight: localMix.internal_mkt,
                            externalMarketWeight: localMix.external_mkt,
                        })
                    })
                }
                
                context.dispatch("alertSystem/newSuccess", {
                    message: `Baseprice Autogeneration completed successfully`,
                    timeout: 5000
                }, { root: true })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error autogeneration of baseprice: ${error}`,
                    timeout: 5000
                }, { root: true })
            }

        },

        async getBasePriceHistory(context, payload) { // loads the base prices history
            const api = context.rootState.api.api
            const basePriceKey = context.state.basePriceInfo.metadataInfo.key

            try {
                const resp = await api.get("pricelist/v2", `/Baseprice/${basePriceKey}/history/list`, {}, {}, {})

                await context.commit("resetBasePriceVersHistory", { basePriceVersionHistory: resp.items })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the base price history list: ${error}`,
                    timeout: 5000
                }, { root: true })
            }
        },

        async getBasePriceHistorySpec(context, payload) {
            const api = context.rootState.api.api
            const basePriceKey = context.state.basePriceInfo.metadataInfo.key

            if (!context.state.basePriceVersionHistorySpec[payload.versionId]) {
                try {
                    const resp = await api.get("pricelist/v2", `/Baseprice/${basePriceKey}/${payload.versionId}`, {}, {}, {})

                    await context.commit("updateBasePriceVersHistorySpec", { versionId: payload.versionId, spec: resp.items[0] })
                } catch (error) {
                    context.dispatch("alertSystem/newError", {
                        message: `Error getting the base price history spec: ${error}`,
                        timeout: 5000
                    }, { root: true })
                }
            }
        },

        async updateBasePrice(context, payload) {
            const api = context.rootState.api.api

            try {
                const resp = await api.put("pricelist/v2", ``, {}, context.getters.finalObjToSubmit, {})

                if (resp instanceof Error) throw "internalservererror"

                //context.commit("resetModifiersList")
                context.commit("resetBasePriceValues")
                await context.dispatch("getBasePrice", { psId: context.rootState.core.currentStrategyPS.metadata.id })

                context.commit("resetDataFrames")

                context.dispatch("alertSystem/newSuccess", {
                    message: `Successfully updated the base price`,
                    timeout: 3000,
                }, { root: true })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating the base price: ${error}`
                }, { root: true })
            }
        },

        async getCustomerBar(context, payload) {
            const api = context.rootState.api.api

            try {
                const resp = await api.get("customerbar/v1", `/customerbars/${context.rootState.core.currentPG.metadata.id}?spec[]=`, {}, {}, {})

                context.commit("resetCustomerBarList"),
                    context.commit("updateCustomerBarList", { customerBarList: resp.items })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error retriving the customer bar list: ${error}`
                }, { root: true })
            }
        },

        async generateBasePrice(context, payload) {
            const api = context.rootState.api.api

            try {
                const resJob = await api.put("predictjob/v2", `/master/pipelinebindtemplate/generation.baseprice.v1?pss_id=${payload.psId}`, {}, context.getters.finalObjForGeneration, {});

                context.dispatch("alertSystem/newInfo", {
                    message: `Job started please wait a couple of seconds`,
                    timeout: 4000,
                }, { root: true })
                const jobSpec = resJob.items[0].resource
                const resJobRun = await api.put('predict/v1', '/job', { priority: context.rootState.pipelineV1.globalRequestPriority }, [{ job: jobSpec, dryRun: false, force: true }], {});

                if (!resJobRun.results[0].success) throw `Job run failed with error ${JSON.stringify(resJobRun.results[0].error)}`

                const values = resJobRun.results[0].result.base_price

                context.commit("resetDataFrames")
                context.commit("updateDataFrames", {
                    dfs: resJobRun.results[0].result.df
                })

                context.commit("resetBasePriceValues")
                context.commit("updateBasePriceValues", {
                    dates: values.d_stay,
                    basePrice: values.base_price,
                    property: values.property_id,
                })

                // load also the properties with modifiers
                const combinedArray = values?.d_stay?.map((date, index) => ({
                    date: date,
                    value: values.base_price[index],
                    property_id: values.property_id[index],
                }));

                // sort values
                combinedArray.sort((a, b) => new Date(a.date) - new Date(b.date));

                // create array for values by property
                const valuesByProp = {}
                for (let prop of Array.from(new Set(combinedArray.map(val => val.property_id)))) {
                    valuesByProp[prop] = {
                        values: [],
                        dates: [],
                    }
                }

                for (let i = 0; i < combinedArray.length; i++) {
                    const { date, value, property_id } = combinedArray[i];
                    valuesByProp[property_id].values.push(value)
                    valuesByProp[property_id].dates.push(date)
                }

                context.commit("updateValuesByPropertyWithModifiers", { value: valuesByProp })

                const infoCopy = structuredClone(context.state.basePriceInfo.metadataInfo)
                context.commit("resetBasePriceInfo")
                context.commit("updateBasePriceInfoMetadata", { key: "key", value: infoCopy.key })

                const generationSource = structuredClone({ job: jobSpec })
                generationSource.user = context.rootState.auth.username
                context.commit("resetGenerationSourceToSend")
                context.commit("updateGenerationSourceToSend", {
                    value: generationSource,
                })

                context.dispatch("alertSystem/newSuccess", {
                    message: "Base Price generated successfully",
                    timeout: 4000,
                }, { root: true })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error generating the base price: ${error}`,
                    timeout: 7000,
                }, { root: true })
            }
        },

        async previewFinalPrice(context, payload) {
            const api = context.rootState.api.api

            try {
                const newFinal = context.getters.finalObjToSubmit
                newFinal.metadata.version = null
                const resJob = await api.put("predictjob/v2", `/master/pipelinebind/expert.preview.standard.${payload.psId}`, {}, {
                    runtimeData: [newFinal],
                }, {});

                context.dispatch("alertSystem/newInfo", {
                    message: `Job started please wait a couple of seconds`,
                    timeout: 4000,
                }, { root: true })

                const jobSpec = resJob.items[0].resource
                const resJobRun = await api.put('predict/v1', '/job', { priority: context.rootState.pipelineV1.globalRequestPriority }, [{ job: jobSpec, dryRun: false, force: true }], {});

                if (!resJobRun.results[0].success) throw `Job run failed with error ${resJobRun.results[0].error.error}`

                // plot the graph on the main one
                context.commit("resetPreviewDataByProperty", {
                    value: resJobRun.results[0].result.prices_by_property
                })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error previewing the final price: ${error}`,
                    timeout: 7000,
                }, { root: true })
            }
        },

        async getMarkets(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get("graph/vpg", "/market", {}, {}, {})

                context.commit("resetMarketList")
                context.commit("updateMarketList", { items: res.items })
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the markets: ${error}`
                }, { root: true })
            }
        }

    }
}
