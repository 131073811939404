
export default {
    namespaced: true,

    state: {

        // selected property
        selectedProperty: {},
        selectedPropertyModifier: {},
        selectedPropertyPmsMappings: [],
        selectedPropertyRates: [],

        // mappigns list from integrations api
        mappingsFromPmsList: [],
        mappingsFromCmList: [],

        // partners with Mapping support
        supportsMappingHashMap: {},
        partnersWithNoRateSysHashMap: {},

        // price strategy source
        priceStrategySource: [
            {text: "Algorithm", value: "NotebookPredictedPrice,Tweak,ThresholdPrevent,FrozenPrice"},
            {text: "Derived Price", value: "IndexedPrice"},
            {text: "Derived Price with Modifiers", value: "IndexedPrice,Tweak,ThresholdPrevent,FrozenPrice"},
            {text: "No Strategy", value: null}
        ],

        // board type standard values:
        boardTypeList: ["RO", "HB", "FB", "BB", "AI"],

        loading: false,
    },

    getters: {

        // rates table
        propertyRatesTable(state, getters) {
            return state.selectedPropertyRates.map(rate => {

                const cmRate = getters.rateMasterDropdown.find(rateMaster => rateMaster.value === rate.spec.rate_external_id)
                
            
                return {
                    name: `${rate.spec.rate_id} - ${rate.spec.rate_name}`,
                    write: rate.spec.is_set_prices_enabled,
                    variationType: rate?.spec?.prop_rate_mod_variation_params?.variations["*"]?.variation_type || null,
                    variationValue: rate?.spec?.prop_rate_mod_variation_params?.variations["*"]?.variation_value || null,
                    extraParams: JSON.stringify(rate.spec.extra_params),
                    cmRate: cmRate !== null || cmRate !== undefined ? typeof cmRate === "object" ? cmRate.text: cmRate : "", 
                    
                }
            })
        },

        // selected property
        selectedPropertyPrettyName(state) {
            return `${state.selectedProperty?.metadata?.id || ""} - ${state.selectedProperty?.metadata?.name || ""}`
        },

        selectedPropertyId(state) {
            return state.selectedProperty.metadata.id
        },

        // support for various features
        selectedPgPmsMappingSupported(state, getters, rootState) {
            const pmsId = rootState.core.currentPG.spec.pms_name
            const support = state.supportsMappingHashMap[pmsId]
            
            if (support === undefined) return false
            if (support.rooms === undefined) return false
            
            return support.rooms
        },

        selectedPgCmMappingSupported(state, getters, rootState) {
            const cmId = rootState.core.currentPG.spec.channel_manager_name
            const support = state.supportsMappingHashMap[cmId]

            if (support === undefined) return false
            if (support.rooms === undefined) return false
            
            return support.rooms
        },

        selectedPgCmRatesSupported(state, getters, rootState) {
            const cmId = rootState.core.currentPG.spec.channel_manager_name
            const support = state.supportsMappingHashMap[cmId]
            
            // check if automapping.rates is present 
            let ratesAutomapping = false
            if (support === undefined) return false
            if (support.rates === undefined) return  false
            ratesAutomapping = support.rates
            
            const ratesSupport = state.partnersWithNoRateSysHashMap[cmId]

            return !ratesSupport && ratesAutomapping
        },
        
        alreadyPresentPmsMappings(state) { // returns the ids of already present pms mappings
            if (!state.selectedPropertyPmsMappings.length) {
                return []
            } else {
                return state.selectedPropertyPmsMappings.map(mapping => {
                    return String(mapping.pmsId)
                })
            }
        },

        pmsIdToPropertyName(state) {
            const hashMap = {}
            state?.mappingsFromPmsList?.forEach(mapping => {
                const pmsIds = new Set()

                // in the future the externalId will be returned from api and has to be added
                //pmsIds.add(mapping.externalId)

                mapping?.pmsPropertyMapping?.forEach(pmsId => pmsIds.add(pmsId))

                const pmsIdsArr = Array.from(pmsIds)
                pmsIdsArr.forEach(pmsIdUnique => {
                    hashMap[pmsIdUnique] = mapping.name 
                })
            })
            return hashMap
        },

        pmsIdsDropdown(state, getters) { // calculate dropdown to insert pms mapping
            const dropdown = []

            for ( const [key, value] of Object.entries(getters.pmsIdToPropertyName)) {
                if (!getters.alreadyPresentPmsMappings.includes(String(key))) { 
                    dropdown.push({
                        text: `${key} - ${value}`,
                        value: String(key)
                    })
                }
            }
            return dropdown
        },

        cmIdToPropertyName(state, getters) { // hasm map of cm id : cm mapping name
            const hashMap = {}

            state.mappingsFromCmList.forEach(mapping => {
                hashMap[mapping.externalId] = mapping.name
            })
            return hashMap
        },

        rateIdToName(state, getters) {
            const hashMap = {}
            getters.rateMasterDropdown.forEach(val => {
                hashMap[val.value] = val.text
            })
            return hashMap
        },

        cmIdsDropdown(state, getters) { // calculate dropdown to insert cm mapping
            const dropdown = []
            state.mappingsFromCmList.forEach(mapping => {
                dropdown.push({
                    text: `${mapping.externalId} - ${getters.cmIdToPropertyName[mapping.externalId]}`,
                    value: String(mapping.externalId),
                    inDatabase: true,
                }) 
            })
            return dropdown
        },

        rateMasterDropdown(state, getters) { // calculate the dropdown to insert the rate master
            const alreadyPresentIds = []
            const dropdown = []
            
            state.mappingsFromCmList.forEach(mapping => {
                mapping.rates.forEach(rate => {
                    if (!alreadyPresentIds.includes(rate.externalId)) {
                        dropdown.push({
                            text: `${rate.externalId} - ${rate.name}`,
                            value: String(rate.externalId),
                            inDatabase: true,
                        })
                        alreadyPresentIds.push(rate.externalId)
                    }
                })
            })
            return dropdown
        },

        hasPropertyModifier(state, getters) { // checks whether the property has a property modifier or not
            return state.selectedProperty.spec.graph_json?.[0] === `IndexedPrice`
        }

    },

    mutations: {

        // selected property
        updateSelectedProperty(state, payload) {
            state.selectedProperty = payload.property
        },

        resetSelectedProperty(state) {
            state.selectedProperty = {}
        },


        // mappings from pms and cm

        updateMappingsFromPms(state, payload) {
            if (!!payload?.mappings) {
                payload.mappings.forEach(mapping => {
                    state.mappingsFromPmsList.push(mapping)
                })
            }
        },

        updateMappingsFromCm(state, payload) {
            if (!!payload?.mappings) {
                payload.mappings.forEach(mapping => {
                    state.mappingsFromCmList.push(mapping)
                })
            }
        },

        resetMappingsFromPms(state) {
            state.mappingsFromPmsList = []
        },

        resetMappingsFromCm(state) {
            state.mappingsFromCmList = []
        },

        // Mapping hash map
        updateMappingHashMap(state, payload) {
            for (const [key, value] of Object.entries(payload.list)) {
                state.supportsMappingHashMap[key] = value
            }
        },

        resetMappingHashMap(state) {
            state.supportsMappingHashMap = {}
        },

        // rate sys hash map

        updateRateSysHashMap(state, payload) {
            for (const [key, value] of Object.entries(payload.list)) {
                state.partnersWithNoRateSysHashMap[key] = value
            }
        },

        resetRateSysHashMap(state) {
            state.partnersWithNoRateSysHashMap = {}
        },

        // pms property mappings

        updatePropertyPmsMappings(state, payload){
            payload.pmsMappings.forEach(mapping => {
                state.selectedPropertyPmsMappings.push(mapping)
            })
        },
        
        deletePropertyPmsMappingById(state, payload) {
            const index = state.selectedPropertyPmsMappings.findIndex(value => value.pmsId === payload.pmsId)

            if (index !== -1) {
                state.selectedPropertyPmsMappings.splice(index, 1)
            }
        },

        addPropertyPmsMapping(state, payload) {
            state.selectedPropertyPmsMappings.push(payload.mapping)
        },

        resetPropertyPmsMappings(state) {
            state.selectedPropertyPmsMappings = []
        },

        // property modifier
        updatePropertyModifier(state, payload) {
            state.selectedPropertyModifier = payload.propertyModifier
        },

        resetPropertyModifier(state) {
            state.selectedPropertyModifier = {}
        },

        // property rates
        updatePropertyRates(state, payload) {
            payload.rates.forEach(rate => {
                state.selectedPropertyRates.push(rate)
            })
        },

        resetPropertyRates(state) {
            state.selectedPropertyRates = []
        },

        isLoading(state){
            state.loading = true
        },

        isNotLoading(state){
            state.loading = false
        },

    },

    actions: {

        // global rates actions

        async selectProperty(context, payload) {
            // resets and populates the selected property state
            context.commit("isLoading")
            context.commit("resetSelectedProperty")
            context.commit("updateSelectedProperty", {
                property: payload.property,
            })

            // loads the property modifiers if they're present
            if (context.getters.hasPropertyModifier) {
                await context.dispatch("getPropertyModifier", {propertyId: payload.property.metadata.id})
            }
            
            // loads the mappings from the cm and pms
            context.commit("resetMappingsFromPms")
            context.commit("resetMappingsFromCm")
            if (context.getters.selectedPgPmsMappingSupported){
                await context.dispatch("getMappingsFromPms", {pgId: payload.property.spec.property_group_id})
            }
            if (context.rootState.core.currentPG.spec.pms_name !== context.rootState.core.currentPG.spec.channel_manager_name) {
                if (context.getters.selectedPgCmMappingSupported || context.getters.selectedPgCmRatesSupported) {
                    await context.dispatch("getMappingsFromCm", {pgId: payload.property.spec.property_group_id})
                }
            }

            // loads the pms property mappings
            await context.dispatch("getPmsPropertyMapping", {propertyId: payload.property.metadata.id})


            // loads the property rates
            await context.dispatch("getPropertyRates", {propertyId: payload.property.metadata.id})

            context.commit("isNotLoading")
        },

        // check for Mapping support
        async checkMappingSupport(context, payload) {
            const api = context.rootState.api.api

            try {
                const responseMapping = await api.get("integrations/v2", "/specs/feature/automapping", {}, {}, {})

                context.commit("resetMappingHashMap")
                context.commit("updateMappingHashMap", {list: responseMapping})

                const responseRatesSys = await api.get("integrations/v2", "/specs/feature/noRateSystem", {}, {}, {})

                context.commit("resetRateSysHashMap")
                context.commit("updateRateSysHashMap", {list: responseRatesSys})

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error cheking mapping support: ${error}`, 
                }, {root: true})
            }
        },

        // mappings from pms and cm
        async getMappingsFromPms(context, payload) {
            const api = context.rootState.api.api
            
            try {
                const response = await api.get("integrations/v2", `/automapping/pms/${payload.pgId}`, {}, {}, {})

                if (!response?.success) throw response?.response?.data?.error

                context.commit("resetMappingsFromPms")
                context.commit("updateMappingsFromPms", {mappings: response.properties})

                if (context.rootState.core.currentPG.spec.pms_name === context.rootState.core.currentPG.spec.channel_manager_name) {
                    context.commit("resetMappingsFromCm")
                    context.commit("updateMappingsFromCm", {mappings: response.properties})
                }

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the mapping from the pms: ${error}`, 
                }, {root: true})
            }

        },

        async getMappingsFromCm(context, payload) {
            const api = context.rootState.api.api

            try {
               const response = await api.get("integrations/v2", `/automapping/chm/${payload.pgId}`, {}, {}, {})

                if (!response?.success) throw response?.response?.data?.error
                
                context.commit("resetMappingsFromCm")
                context.commit("updateMappingsFromCm", {mappings: response.properties})

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the mapping from the cm: ${error}`, 
                }, {root: true})
            }

        },


        // read table actions

        async getPmsPropertyMapping(context, payload) {
            const api = context.rootState.api.api

            try {
                const response = await api.get("core/v1", `/pmspropertymapping/property/${payload.propertyId}`, {}, {}, {})

                const result = response.items.map(mapping => {

                    return {
                        pmsId: String(mapping.spec.pms_id),
                        propertyId: mapping.spec.property_id,
                        name: context.getters.pmsIdToPropertyName[mapping.spec.pms_id] || mapping.spec.pms_id, 
                        inPms: !!context.getters.pmsIdToPropertyName[mapping.spec.pms_id],
                    }
                })

                // resets and populates the pms mappings list state
                context.commit("resetPropertyPmsMappings")
                context.commit("updatePropertyPmsMappings", {
                    pmsMappings: result
                })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the pms mappings list: ${error}`, 
                }, {root: true})
            }
        },

        async deletePmsPropertyMapping(context, payload) {
            const api = context.rootState.api.api

            try {

                const response = await api.delete("core/v1", `/pmspropertymapping/pmsid/${payload.pmsId}/property/${payload.propertyId}`, {}, {}, {})

                if (response.status !== 200 ) throw ``

                context.commit("deletePropertyPmsMappingById", {
                    pmsId: payload.pmsId
                })


            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error deleting the pms mapping: ${error}`, 
                }, {root: true})
            }

        },

        async insertPmsPropertyMapping(context, payload) {
            const api = context.rootState.api.api

            try {
                const data = { spec: { pms_id: payload.pmsId, property_id: payload.propertyId }}

                const response = await api.put("core/v1", "/pmspropertymapping", {}, data, {})

                if (response.status !== 200) throw response.error

                context.commit("addPropertyPmsMapping", {
                    mapping: {
                        propertyId: payload.propertyId,
                        pmsId: payload.pmsId,
                        name: context.getters.pmsIdToPropertyName[payload.pmsId], 
                    }
                })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error adding the pms mapping: ${error}`, 
                }, {root: true})
            }
        },

        async getPropertyModifier(context, payload) {
            const api = context.rootState.api.api

            try {
                const response = await api.get("core/v1", `/propertymodifier/property/${payload.propertyId}/modifier/40/date/-`, {}, {}, {})

                context.commit("resetPropertyModifier")
                context.commit("updatePropertyModifier", {
                    propertyModifier: response.items[0]
                })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the property modifier: ${error}`, 
                }, {root: true})
            }
        },

        async updatePropertyModifier(context, payload) {
            const api = context.rootState.api.api

            try {
                const response = await api.put("core/v1", `/propertymodifier/property/${payload.propertyId}/modifier/40/date/-`, {}, payload.data, {})

                context.commit("resetPropertyModifier")
                context.commit("updatePropertyModifier", {
                    propertyModifier: response.items[0]
                })

                context.dispatch("alertSystem/newSuccess", {
                    message: `Property modifier updated successfully`, 
                }, {root: true})
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error updating the property modifier: ${error}`, 
                }, {root: true})
            }
        },


        async deletePropertyModifier(context, payload) {
            const api = context.rootState.api.api

            try {
                const response = await api.delete("core/v1", `/propertymodifier/property/${payload.propertyId}/modifier/40/date/-`, {}, payload.data, {})

                context.commit("resetPropertyModifier")

                context.dispatch("alertSystem/newSuccess", {
                    message: `Property modifier successfully deleted`, 
                }, {root: true})

                return true

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error deleting the property modifier: ${error}`, 
                }, {root: true})
                return false
            }
        },

        async getPropertyRates (context, payload) {
            const api = context.rootState.api.api

            try {
                const response = await api.get("rate/v1", `/propertyrate/property/${payload.propertyId}`, {}, {}, {})
                
                context.commit("resetPropertyRates")
                context.commit("updatePropertyRates", {
                    rates: response.items
                })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the rates for the selected property: ${error}`, 
                }, {root: true})
            }
        }
    }
}