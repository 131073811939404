<template>
  <v-container fluid :class="globalSelectedPgId ? 'pa-0' : 'pa-4'" style="height: 100vh;">
    <iframe width="100%" height="100%" :src="reportUrl" frameborder="0" style="border:0" allowfullscreen v-if="globalSelectedPgId !== null"></iframe>          
    <SelectPgAlert v-else></SelectPgAlert>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectPgAlert from '@/components/utils/SelectPgAlert'
// @ is an alias to /src

export default {
  name: 'Performance',
  components: {SelectPgAlert},
  data: function (argument) {
    return {}
  },
  computed: {
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId",
    }),
    reportUrl () {
      if (this.globalSelectedPgId == null) {
        return
      }
      const propertyGroupId = this.globalSelectedPgId
      const baseUrl = 'https://lookerstudio.google.com/embed/reporting/d37bce31-e06c-404d-9212-56717947f72b/page/p_ysxabgisxc'
      const params = {
        "ds20.property_group_id" : propertyGroupId,
        "ds23.property_group_id" : propertyGroupId,
        "ds27.property_group_id" : propertyGroupId,
        "ds29.property_group_id" : propertyGroupId,
        "ds30.property_group_id" : propertyGroupId,
        "ds33.property_group_id" : propertyGroupId,
        "ds35.property_group_id" : propertyGroupId
      };
      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString)
      return baseUrl + '?params=' + encodedParams
    }
  }
}
</script>
