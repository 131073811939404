<template>
    <v-card class="pa-4" :elevation="0">
        <v-row>
            <v-col class="col-12">
                <v-card-actions>
                    <v-btn
                        color="primary"
                        :disabled="disabled"
                        @click="addModifier()"
                        >Add Modifier</v-btn
                    >
                    <slot name="header-buttons"></slot>
                </v-card-actions>
            </v-col>

            <v-col
                class="col-12 card-ui my-2"
                v-for="(modifier, index) of modifiersListWithMenuInfo"
                :key="modifier.id"
            >
                <v-row>
                    <v-col class="col-9">
                        <v-row>
                            <!-- Modifier Kind Selector -->
                            <v-col class="col-6">
                                <v-autocomplete
                                    outlined
                                    dense
                                    label="Modifier Type"
                                    :items="modifierKindList"
                                    :disabled="!modifier.active || disabled"
                                    :value="modifier.kind"
                                    @change="
                                        [
                                            changeModifierKind(index, $event),
                                            emitNewCalculation(),
                                        ]
                                    "
                                >
                                    <template v-slot:item="data">
                                        <v-tooltip right>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-list-item-content
                                                    v-text="data.item.text"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-list-item-content>
                                            </template>
                                            <span>{{
                                                data.item.description || ""
                                            }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <!-- Date Range or Date Selector -->
                            <v-col class="col-6">
                                <v-menu
                                    v-if="modifier.kind !== 'extend'"
                                    v-model="modifier.showRangeMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            dense
                                            label="To Date Range"
                                            :value="
                                                prettifyDateRange([
                                                    modifier.startDate,
                                                    modifier.endDate,
                                                ])
                                            "
                                            readonly
                                            :error="
                                                modifier.active &&
                                                !prettifyDateRange([
                                                    modifier.startDate,
                                                    modifier.endDate,
                                                ])
                                            "
                                            :error-messages="
                                                modifier.active &&
                                                !prettifyDateRange([
                                                    modifier.startDate,
                                                    modifier.endDate,
                                                ])
                                                    ? 'Required'
                                                    : ''
                                            "
                                            v-bind="attrs"
                                            :disabled="
                                                !modifier.active || disabled
                                            "
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="
                                            datePickerDateRanges[modifier.id]
                                        "
                                        :first-day-of-week="1"
                                        @change="
                                            [
                                                updateModifier(
                                                    index,
                                                    'startDate',
                                                    sortDateRange($event).start
                                                ),
                                                updateModifier(
                                                    index,
                                                    'endDate',
                                                    sortDateRange($event).end
                                                ),
                                                emitNewCalculation(),
                                                closeToDateRangeMenu(
                                                    modifier.id
                                                ),
                                            ]
                                        "
                                        range
                                    >
                                        <div class="date-buttons">
                                            <div class="date-buttons-col">
                                                <v-btn
                                                    block
                                                    @click="
                                                        resetStartDate(index)
                                                    "
                                                    >Reset Start</v-btn
                                                >
                                                <v-btn
                                                    block
                                                    @click="resetEndDate(index)"
                                                    >Reset End</v-btn
                                                >
                                                <v-btn
                                                    block
                                                    color="red"
                                                    @click="
                                                        [
                                                            selectWholeDateRange(
                                                                index
                                                            ),
                                                            emitNewCalculation(),
                                                        ]
                                                    "
                                                    >Whole Date Range</v-btn
                                                >
                                            </div>
                                        </div>
                                    </v-date-picker>
                                </v-menu>

                                <v-menu
                                    v-else
                                    v-model="modifier.showMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            dense
                                            label="To Date"
                                            :value="modifier.toDate"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :error="!modifier.toDate"
                                            :error-messages="
                                                !modifier.toDate
                                                    ? 'Required'
                                                    : ''
                                            "
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        :value="modifier.toDate"
                                        :first-day-of-week="1"
                                        @change="
                                            [
                                                updateModifier(
                                                    index,
                                                    'toDate',
                                                    $event
                                                ),
                                                emitNewCalculation(),
                                                closeToDateMenu(modifier.id),
                                            ]
                                        "
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Properties Affected Selector -->
                            <v-col
                                class="col-6"
                                v-if="modifier.kind !== 'extend'"
                            >
                                <v-autocomplete
                                    outlined
                                    dense
                                    label="Properties Affected"
                                    multiple
                                    :disabled="!modifier.active || disabled"
                                    :items="currentPropertiesFiltered(index)"
                                    :value="modifier.propertyIds"
                                    @change="
                                        [
                                            updateModifier(
                                                index,
                                                'propertyIds',
                                                $event
                                            ),
                                            emitNewCalculation(),
                                        ]
                                    "
                                    chips
                                    small-chips
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @mousedown.prevent
                                            @click="
                                                [
                                                    selectAllProperties(index),
                                                    emitNewCalculation(),
                                                ]
                                            "
                                        >
                                            <v-list-item-action>
                                                <v-icon
                                                    dense
                                                    :color="
                                                        modifier.propertyIds
                                                            .length > 0
                                                            ? 'indigo darken-4'
                                                            : ''
                                                    "
                                                >
                                                    {{ icon(index) }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <!-- Property selector -->
                            <v-col
                                class="col-6"
                                v-if="
                                    !['extend', 'overwrite'].includes(
                                        modifier.kind
                                    )
                                "
                            >
                                <v-autocomplete
                                    v-if="modifier.kind === 'copy'"
                                    outlined
                                    dense
                                    label="Property to copy"
                                    :disabled="!modifier.active || disabled"
                                    :items="propertiesDropdown"
                                    :value="modifier.fromPropertyId"
                                    @change="
                                        [
                                            updateModifier(
                                                index,
                                                'fromPropertyId',
                                                $event
                                            ),
                                            emitNewCalculation(),
                                        ]
                                    "
                                ></v-autocomplete>
                                <v-text-field
                                    v-else
                                    outlined
                                    dense
                                    label="Value"
                                    :disabled="!modifier.active || disabled"
                                    :suffix="
                                        modifierSuffix(
                                            modifier.kind,
                                            modifier.value
                                        )
                                    "
                                    :rules="[rules.isNumber]"
                                    :value="modifier.value"
                                    @input="
                                        [
                                            updateModifier(
                                                index,
                                                'value',
                                                $event
                                            ),
                                            emitNewCalculation(),
                                        ]
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- Modifier Transition Buttons -->
                    <v-col class="col-3">
                        <v-row>
                            <v-col class="col-4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="ma-2"
                                            text
                                            v-bind="attrs"
                                            v-on="on"
                                            icon
                                            @click="
                                                [
                                                    moveModifier(
                                                        index,
                                                        index - 1
                                                    ),
                                                    emitNewCalculation(),
                                                ]
                                            "
                                            :disabled="index === 0"
                                        >
                                            <v-icon>fas fa-chevron-up</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Move Up</span>
                                </v-tooltip>
                            </v-col>
                            <v-col class="col-4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="ma-2"
                                            text
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="
                                                [
                                                    deleteModifier(index),
                                                    emitNewCalculation(),
                                                ]
                                            "
                                        >
                                            <v-icon>fas fa-trash-alt</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </v-col>
                            <v-col
                                class="col-4 d-flex align-center justify-center"
                            >
                                {{ index }}
                            </v-col>
                            <v-col class="col-4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="ma-2"
                                            text
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="
                                                [
                                                    moveModifier(
                                                        index,
                                                        index + 1
                                                    ),
                                                    emitNewCalculation(),
                                                ]
                                            "
                                            :disabled="
                                                modifiersList.length - 1 ===
                                                index
                                            "
                                        >
                                            <v-icon>fas fa-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Move Down</span>
                                </v-tooltip>
                            </v-col>
                            <v-col class="col-4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="ma-2"
                                            text
                                            v-bind="attrs"
                                            v-on="on"
                                            icon
                                            @click="
                                                [
                                                    copyModifier(index),
                                                    emitNewCalculation(),
                                                ]
                                            "
                                        >
                                            <v-icon>far fa-copy</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Copy</span>
                                </v-tooltip>
                            </v-col>
                            <v-col class="col-4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                            <v-switch
                                                class="mt-2"
                                                @change="emitNewCalculation()"
                                                v-model="modifier.active"
                                            ></v-switch>
                                        </div>
                                    </template>
                                    <span>{{
                                        modifier.active || disabled
                                            ? "Disable"
                                            : "Enable"
                                    }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import Vue from "vue";
import dateUtils from "@/utils/dateUtils";
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        dataframes: {
            type: Object,
            required: true,
        },
        dateCol: {
            type: String,
            default: "date",
        },
        priceCol: {
            type: String,
            default: "price",
        },
        propCol: {
            type: String,
            default: "propertyId",
        },
        propertiesDropdown: {
            type: Array,
            required: true,
        },
        defaultPrice: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modifiersList: [],
            valuesByProp: null,
            valuesByPropWithModifiers: null,

            defaultValuesByKey: {
                user: null,
                createdAt: null,
                kind: "percentual",
                propertyIds: [],
                toDate: null,
                value: 1,
                startDate: null,
                endDate: null,
                fromPropertyId: null,
                active: true,
            },

            datePickerDateRanges: {},

            modifierKindList: [
                {
                    text: "Percentual",
                    value: "percentual",
                    description:
                        "Applies a percentual multiplier to the selected date range and properties",
                    fields: [
                        "id",
                        "user",
                        "createdAt",
                        "kind",
                        "propertyIds",
                        "startDate",
                        "endDate",
                        "value",
                        "active",
                    ],
                },
                {
                    text: "Absolute",
                    value: "absolute",
                    description:
                        "Adds or Subtracts a value to the selected date range and properties",
                    fields: [
                        "id",
                        "user",
                        "createdAt",
                        "kind",
                        "propertyIds",
                        "startDate",
                        "endDate",
                        "value",
                        "active",
                    ],
                },
                {
                    text: "Direct",
                    value: "direct",
                    description:
                        "Applies the chosen value to the selected date range and properties",
                    fields: [
                        "id",
                        "user",
                        "createdAt",
                        "kind",
                        "propertyIds",
                        "startDate",
                        "endDate",
                        "value",
                        "active",
                    ],
                },
                {
                    text: "Copy Property",
                    value: "copy",
                    description:
                        "Copies the value of a property into other properties for the selected date range",
                    fields: [
                        "id",
                        "user",
                        "createdAt",
                        "kind",
                        "propertyIds",
                        "startDate",
                        "endDate",
                        "fromPropertyId",
                        "active",
                    ],
                },
                {
                    text: "Overwrite Period",
                    value: "overwrite",
                    description:
                        "Takes the period of the selected date range for the selected properties and overwrites it with the values of the year before",
                    fields: [
                        "id",
                        "user",
                        "createdAt",
                        "kind",
                        "propertyIds",
                        "startDate",
                        "endDate",
                        "value",
                        "active",
                    ],
                },
                {
                    text: "Extend",
                    value: "extend",
                    description:
                        "Extends the values taking the ones from the previous year until the selected date",
                    fields: [
                        "id",
                        "user",
                        "createdAt",
                        "kind",
                        "toDate",
                        "active",
                    ],
                },
            ],

            rules: {
                isNumber: (value) =>
                    !isNaN(Number(value)) || "Value must be a number",
            },

            toDateMenu: false,
            toDateRangeMenu: false,
        };
    },
    watch: {
        dataframes: {
            deep: true,
            handler: function () {
                this.recalculateDataframesValues();
            },
        },
        dateCol: {
            deep: true,
            handler: function () {
                this.recalculateDataframesValues();
            },
        },
        priceCol: {
            deep: true,
            handler: function () {
                this.recalculateDataframesValues();
            },
        },
        propCol: {
            deep: true,
            handler: function () {
                this.recalculateDataframesValues();
            },
        },
    },
    computed: {
        ...mapState({
            username: (state) => state.auth.username,
        }),
        ...mapGetters({
            currentCurrency: "core/currentCurrency",
        }),
        currentPropertiesFiltered() {
            return (index) => {
                return this.propertiesDropdown.filter(
                    (prop) =>
                        prop.value !== this.modifiersList[index].fromPropertyId
                );
            };
        },
        modifiersListWithMenuInfo() {
            return this.modifiersList.map((modifier) => {
                return {
                    ...modifier,
                    showMenu: false,
                    showRangeMenu: false,
                };
            });
        },
    },
    methods: {
        /* Modifier CRUD Methods */
        addModifier() {
            const newModifier = {
                id: `${this.modifiersList.length + 1}${String(
                    Math.random()
                ).replace(".", "")}${String(performance.now()).replace(
                    ".",
                    ""
                )}`,
                user: this.username,
                createdAt: new Date(),
                kind: "percentual",
                propertyIds: [],
                startDate: null,
                endDate: null,
                value: 1,
                active: true,
            };
            this.modifiersList.push(newModifier);
            Vue.set(this.datePickerDateRanges, newModifier.id, []);
        },
        changeModifierKind(index, newKind) {
            const oldModifier = this.modifiersList[index];
            const newModifier = {};
            const newKindFields = this.modifierKindList.find(
                (val) => val.value === newKind
            ).fields;

            newKindFields.forEach((key) => {
                const defaultVal = this.defaultValuesByKey[key];
                newModifier[key] =
                    oldModifier[key] === undefined
                        ? defaultVal
                        : oldModifier[key];
            });
            newModifier.kind = newKind;
            this.modifiersList.splice(index, 1, newModifier);
        },
        deleteModifier(index) {
            Vue.delete(this.datePickerDateRanges, this.modifiersList[index].id);
            this.modifiersList.splice(index, 1);
        },
        updateModifier(index, key, value) {
            Vue.set(this.modifiersList[index], key, value);
        },
        moveModifier(startIndex, endIndex) {
            // moves the modifier from the start index position to end index position
            const temp = this.modifiersList[startIndex];
            this.modifiersList.splice(startIndex, 1);
            this.modifiersList.splice(endIndex, 0, temp);

            const tempDateRange =
                this.datePickerDateRanges[this.modifiersList[startIndex].id];
            Vue.set(
                this.datePickerDateRanges,
                this.modifiersList[startIndex].id,
                this.datePickerDateRanges[this.modifiersList[endIndex].id]
            );
            Vue.set(
                this.datePickerDateRanges,
                this.modifiersList[endIndex].id,
                tempDateRange
            );
        },
        copyModifier(index) {
            // copies a modifier at the end of the modifiers array
            const modifier = structuredClone(this.modifiersList[index]);
            modifier["createdAt"] = new Date();
            modifier["user"] = this.username;
            modifier["id"] = `${this.modifiersList.length + 1}${String(
                Math.random()
            ).replace(".", "")}${String(performance.now()).replace(".", "")}`;
            this.modifiersList.push(modifier);
            Vue.set(this.datePickerDateRanges, modifier.id, []);
        },
        resetModifiers() {
            this.modifiersList = [];
            this.emitNewCalculation();
        },

        // Base Price Calculation Methods
        recalculateDataframesValues() {
            const values = structuredClone(this.dataframes);
            const dates = values?.[this.dateCol];
            const prices = values?.[this.priceCol];
            const properties = values?.[this.propCol];

            if (!dates?.length || !prices?.length || !properties?.length)
                return null;

            if (!dates?.length && !prices?.length && !properties?.length) {
                this.valuesByProp = null;
                return;
            }

            const combinedArray = dates.map((date, index) => ({
                date,
                price: prices[index],
                room: properties[index],
            }));

            // sort values
            combinedArray.sort((a, b) => new Date(a.date) - new Date(b.date));

            // create array for values by property
            const valuesByProp = {};
            for (let prop of Array.from(new Set(properties))) {
                valuesByProp[prop] = {
                    values: [],
                    dates: [],
                };
            }

            for (let i = 0; i < combinedArray.length; i++) {
                const { date, price, room } = combinedArray[i];
                valuesByProp[room].values.push(Math.round(price));
                valuesByProp[room].dates.push(date);
            }

            this.valuesByProp = null;
            this.valuesByProp = valuesByProp;
            this.emitNewCalculation();
        },

        recalculateBaseprice() {
            const valByProp = structuredClone(this.valuesByProp);
            const defaultPrice = this.defaultPrice;

            if (!valByProp) {
                this.valuesByPropWithModifiers = null;
                return;
            }

            for (let modifier of this.modifiersList) {
                if (!modifier.active || this.disabled) continue;

                // populate with default values
                if (!["extend"].includes(modifier.kind)) {
                    for (let propId of modifier.propertyIds) {
                        if (valByProp[propId] === undefined) {
                            valByProp[propId] = {
                                dates: [],
                                values: [],
                            };
                            const objToCopy = Object.values(valByProp).find(
                                (val) => !!val?.dates?.length
                            );

                            objToCopy?.dates?.forEach((date) => {
                                valByProp[propId].dates.push(date);
                                valByProp[propId].values.push(
                                    Math.round(defaultPrice)
                                );
                            });
                        }
                    }
                }

                // autoamtic extension otherwise it will break the modifiers
                if (!["extend", "copy"].includes(modifier.kind)) {
                    for (let propId of modifier.propertyIds) {
                        const dates = valByProp[propId].dates;
                        const values = valByProp[propId].values;

                        if (modifier.endDate > dates[dates.length - 1]) {
                            const diff = dateUtils.daysDifference(
                                dates[dates.length - 1],
                                modifier.endDate,
                                1
                            );
                            for (let i = 1; i < diff; i++) {
                                let newVal =
                                    values[values.length - 364] || defaultPrice;
                                let newDate = dateUtils.toIsoDateOnly(
                                    dateUtils.addDaysToDate(
                                        dates[dates.length - 1],
                                        1
                                    )
                                );

                                values.push(Math.round(newVal));
                                dates.push(newDate);
                            }
                        }
                    }
                }

                // modifier appliator
                switch (modifier.kind) {
                    case "percentual": {
                        for (let propId of modifier.propertyIds) {
                            const dates = valByProp[propId].dates;
                            const values = valByProp[propId].values;

                            const startIndex = dates.indexOf(
                                modifier.startDate
                            );
                            const endIndex = dates.indexOf(modifier.endDate);

                            for (let i = startIndex; i <= endIndex; i++) {
                                values[i] = Math.round(
                                    values[i] * Number(modifier.value)
                                );
                            }
                        }
                        break;
                    }

                    case "absolute": {
                        for (let propId of modifier.propertyIds) {
                            const dates = valByProp[propId].dates;
                            const values = valByProp[propId].values;

                            const startIndex = dates.indexOf(
                                modifier.startDate
                            );
                            const endIndex = dates.indexOf(modifier.endDate);

                            for (let i = startIndex; i <= endIndex; i++) {
                                values[i] = Math.round(
                                    values[i] + Number(modifier.value)
                                );
                            }
                        }

                        break;
                    }

                    case "direct": {
                        for (let propId of modifier.propertyIds) {
                            const dates = valByProp[propId].dates;
                            const values = valByProp[propId].values;

                            const startIndex = dates.indexOf(
                                modifier.startDate
                            );
                            const endIndex = dates.indexOf(modifier.endDate);

                            for (let i = startIndex; i <= endIndex; i++) {
                                values[i] = Math.round(Number(modifier.value));
                            }
                        }

                        break;
                    }

                    case "copy": {
                        // copies the value of a property for the selected date range into other properties

                        if (!!modifier.fromPropertyId) {
                            const datesFromProp =
                                valByProp[modifier.fromPropertyId].dates;
                            const valuesFromProp =
                                valByProp[modifier.fromPropertyId].values;

                            if (
                                modifier.endDate >
                                datesFromProp[datesFromProp.length - 1]
                            ) {
                                const diff = dateUtils.daysDifference(
                                    datesFromProp[datesFromProp.length - 1],
                                    modifier.endDate,
                                    1
                                );
                                for (let i = 1; i < diff; i++) {
                                    let newVal =
                                        valuesFromProp[
                                            valuesFromProp.length - 364
                                        ] || defaultPrice;
                                    let newDate = dateUtils.toIsoDateOnly(
                                        dateUtils.addDaysToDate(
                                            datesFromProp[
                                                datesFromProp.length - 1
                                            ],
                                            1
                                        )
                                    );

                                    valuesFromProp.push(Math.round(newVal));
                                    datesFromProp.push(newDate);
                                }
                            }

                            for (let propId of modifier.propertyIds) {
                                const dates = valByProp[propId].dates;
                                const values = valByProp[propId].values;

                                const datesOtherProp =
                                    valByProp[modifier.fromPropertyId].dates;
                                const valuesOtherProp =
                                    valByProp[modifier.fromPropertyId].values;

                                const startIndex = dates.indexOf(
                                    modifier.startDate
                                );
                                const endIndex = dates.indexOf(
                                    modifier.endDate
                                );

                                let startIndexOther = datesOtherProp.indexOf(
                                    modifier.startDate
                                );

                                for (let i = startIndex; i <= endIndex; i++) {
                                    values[i] = Math.round(
                                        valuesOtherProp[startIndexOther]
                                    );
                                    startIndexOther++;
                                }
                            }
                        }

                        break;
                    }

                    case "overwrite": {
                        for (let propId of modifier.propertyIds) {
                            const dates = valByProp[propId].dates;
                            const values = valByProp[propId].values;

                            // indexes of date range to overwrite
                            const startIndex = _.sortedIndexOf(
                                dates,
                                modifier.startDate
                            );
                            const endIndex = _.sortedIndexOf(
                                dates,
                                modifier.endDate
                            );

                            for (let i = startIndex; i <= endIndex; i++) {
                                let newVal = values[i - 364] || defaultPrice;

                                values[i] = Math.round(newVal);
                            }
                        }

                        break;
                    }

                    case "extend": {
                        // extends all the properties
                        for (let propVals of Object.values(valByProp)) {
                            const dates = propVals.dates;
                            const values = propVals.values;

                            if (modifier.toDate > dates[dates.length - 1]) {
                                // extends only if the date is later than the last one
                                const diff = dateUtils.daysDifference(
                                    dates[dates.length - 1],
                                    modifier.toDate,
                                    1
                                );
                                for (let i = 1; i < diff; i++) {
                                    let newVal =
                                        values[values.length - 364] ||
                                        defaultPrice;
                                    let newDate = dateUtils.toIsoDateOnly(
                                        dateUtils.addDaysToDate(
                                            dates[dates.length - 1],
                                            1
                                        )
                                    );

                                    values.push(Math.round(newVal));
                                    dates.push(newDate);
                                }
                            }
                        }
                        break;
                    }
                }
            }

            this.valuesByPropWithModifiers = valByProp;
        },
        valByPropToDataFrame(valuesByProp) {
            const result = {
                [this.dateCol]: [],
                [this.propCol]: [],
                [this.priceCol]: [],
            };

            Object.entries(valuesByProp).forEach(([propId, values]) => {
                for (let i = 0; i < values.dates.length; i++) {
                    result?.[this.dateCol].push(values.dates[i]);
                    result?.[this.propCol].push(Number(propId));
                    result?.[this.priceCol].push(Math.round(values.values[i]));
                }
            });

            return result;
        },
        emitNewCalculation() {
            this.recalculateBaseprice();
            this.$emit("input", {
                data: this.valuesByPropWithModifiers,
                dataFrames: this.valByPropToDataFrame(
                    this.valuesByPropWithModifiers
                ),
                modifiers: this.modifiersList.map((modifier) => {
                    const newMod = structuredClone(modifier);
                    delete newMod.active;
                    delete newMod.id;
                    return newMod;
                }),
            });
            this.$emit("update:modelValue", {
                data: this.valuesByPropWithModifiers,
                dataFrames: this.valByPropToDataFrame(
                    this.valuesByPropWithModifiers
                ),
                modifiers: this.modifiersList.map((modifier) => {
                    const newMod = structuredClone(modifier);
                    delete newMod.active;
                    delete newMod.id;
                    return newMod;
                }),
            });
        },

        /* Generic Methods */
        prettifyDateRange(dateRange) {
            const { start, end } = this.sortDateRange(dateRange);
            return !!start && !!end ? `${start} ÷ ${end}` : null;
        },
        sortDateRange(dateRange) {
            const [start, end] =
                dateRange[0] < dateRange[1]
                    ? [dateRange[0], dateRange[1]]
                    : [dateRange[1], dateRange[0]];
            return { start: start, end: end };
        },
        modifierSuffix(kind, value) {
            switch (kind) {
                case "percentual":
                    return `= ${value < 1 ? "" : "+"}${Number(
                        (value - 1) * 100
                    ).toFixed(0)}%`;
                case "absolute":
                    return `= ${value < 0 ? "" : "+"}${Number(value)} ${
                        this.currentCurrency?.spec?.code
                    }`;
                case "direct":
                    return `= ${Number(value)} ${
                        this.currentCurrency?.spec?.code
                    }`;
            }
        },

        icon(index) {
            const modifier = this.modifiersList[index];
            if (
                modifier.propertyIds.length ===
                this.currentPropertiesFiltered(index).length
            )
                return "fa-light fa-times-square";
            if (
                modifier.propertyIds.length !==
                    this.currentPropertiesFiltered(index).length &&
                modifier.propertyIds.length > 0
            )
                return "fa-regular fa-minus-square";
            return "fa-regular fa-square-full";
        },

        resetStartDate(index) {
            const modifier = this.modifiersList[index];

            this.closeToDateMenu(modifier.id);
            this.closeToDateRangeMenu(modifier.id);
            this.datePickerDateRanges[modifier.id] = [modifier.endDate];
        },

        resetEndDate(index) {
            const modifier = this.modifiersList[index];

            this.closeToDateMenu(modifier.id);
            this.closeToDateRangeMenu(modifier.id);
            this.datePickerDateRanges[modifier.id] = [modifier.startDate];
        },

        selectWholeDateRange(index) {
            const modifier = this.modifiersList[index];
            const dates = structuredClone(
                this.valByPropToDataFrame(this.valuesByPropWithModifiers)[
                    this.dateCol
                ]
            );

            dates.sort((a, b) => new Date(a) - new Date(b));

            Vue.set(modifier, "startDate", dates[0]);
            Vue.set(modifier, "endDate", dates[dates.length - 1]);

            this.closeToDateMenu(modifier.id);
            this.closeToDateRangeMenu(modifier.id);
            this.datePickerDateRanges[modifier.id] = [
                dates[0],
                dates[dates.length - 1],
            ];
        },

        selectAllProperties(index) {
            const modifier = this.modifiersList[index];
            if (
                modifier.propertyIds.length ===
                this.currentPropertiesFiltered(index).length
            ) {
                modifier.propertyIds = [];
            } else {
                modifier.propertyIds = this.currentPropertiesFiltered(
                    index
                ).map((prop) => prop.value);
            }
        },

        closeToDateMenu(modifierId) {
            this.modifiersList.find(
                (mod) => mod.id === modifierId
            ).showMenu = false;
        },
        closeToDateRangeMenu(modifierId) {
            this.modifiersList.find(
                (mod) => mod.id === modifierId
            ).showRangeMenu = false;
        },

        // validation methods
    },
    created() {
        this.recalculateDataframesValues();
        this.emitNewCalculation();
    },
};
</script>

<style scoped>
.date-buttons,
.date-buttons-col {
    gap: 1rem;
    width: 100%;
}

.date-buttons {
    display: flex;
}

.date-buttons-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>

<!-- 
Modifier types:
extend
percentual
absolute
direct
copy
overwrite


Modifiers schema:
{
    user: "martino@smartpricing.it"
    createdAt: 2023-05-08T12:36:56.638Z
    kind: "percentual",
    propertyIds: [89, 90],
    startDate: "2024-01-20",
    endDate: "2024-03-20",
    value: 1.3,
    active: false,
}



-->
