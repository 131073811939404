<template>
  <v-container fluid class="pa-0 ma-0">
     <v-card class="card-ui">
      <component v-bind:is="component == 'preprocess' ? 'PreprocessCharts' : 'PredictionCharts'" :showAddBasePriceMap="showAddBasePriceMap" ></component>
    </v-card>
  </v-container>
</template>

<script>
import PredictionCharts from './PipelineV1PredictCharts.vue'
import PreprocessCharts from './PipelineV1PreprocessCharts.vue'

export default {
  props: ['component', 'showAddBasePriceMap'],

  components: {
    PredictionCharts,
    PreprocessCharts,
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: 'Preprocess charts', content: 'PreprocessCharts' },
        { tab: 'Prediction charts', content: 'PredictionCharts' },
      ],
      showItems: {'Prediction charts': false, 'Preprocess charts': false},
    };
  },

  watch: {
    '$store.state.strategy.predictChartsAreLoaded'(newVal, oldVal){
      this.showItems['Prediction charts'] = this.$store.state.strategy.predictChartsAreLoaded;
    },

    '$store.state.strategy.preprocessChartsAreLoaded'(newVal, oldVal){
      this.showItems['Preprocess charts'] = this.$store.state.strategy.preprocessChartsAreLoaded;
    },
    
  },

  methods:{


  }, //end methods

  mounted(){
  }


};
</script>

