<template>
    <v-container fluid class="pa-4">
        <v-card class="card-ui">
            <v-toolbar dense elevation="0" :class="{ 'grey darken-3': darkMode }">
                <v-card-title class="overline ml-0 pl-0">
                    <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                    <b class="grey--text darken-6">Run checks manually</b>
                </v-card-title>
            </v-toolbar>
            <div class="px-3 pt-3 pb-5">
                <v-card-text>
                    <v-autocomplete label="Select check(s) to run" :items="availableChecks" :item-text="check => {
                        return `${check.kind.toUpperCase().replaceAll('_', ' ')} - ${check.description}`
                    }" v-model="selectedChecksToRun" outlined dense multiple hide-details return-object />
                </v-card-text>
                <v-card-actions class="pl-4">
                    <v-btn color="primary" @click="runSelectedChecks">Run selected checks</v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-container>
</template>

<script>

import { mapState } from 'vuex';

export default {
    data() {
        return {
            selectedChecksToRun: []
        }
    },
    computed: {
        ...mapState({
            darkMode: state => state.ui.darkMode,
            availableChecks: state => state.alarms.availableChecks
        }),
    },
    methods: {
        async runSelectedChecks() {
            await this.$store.dispatch("alarms/runChecks", this.selectedChecksToRun)
        }
    },
    async created() {
        await this.$store.dispatch("alarms/getAvailableChecks")
    }
}
</script>
