<template>
  <div class="card-ui">
    <PreprocessCharts v-if="globalSelectedPgId"/>
    <v-alert v-else type="warning">
      Select a PG
    </v-alert>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import PreprocessCharts from '../components/PipelineV1PreprocessCharts.vue'

export default {
  components: { PreprocessCharts },
  computed: {
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId",
    })
  }
}

</script>
