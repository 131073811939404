<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="d-flex justify-space-between text-h3 my-4 mx-auto">Create Smartpaying Property Group
          <v-btn class="ma-2" color="red" href="https://helium-global.slack.com/archives/D02JFH6LCRM" target="_blank">
            <v-icon class="mr-2">fa-solid fa-fire-extinguisher</v-icon>
            Emergency support
          </v-btn>
        </div>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Accommodation logo upload
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              Config generation
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              Check and create
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <ImageCroppingStep :canSkip="true" @next="onCroppedImage" />
            </v-stepper-content>

            <v-stepper-content step="2">
              <ConfigGenerationStep @next="step = 3" @back="step = 1" />
            </v-stepper-content>

            <v-stepper-content step="3">
              <PropertyGroupCreationStep @back="step = 2" @finish="onOnboardingEnd" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src


import Vue from 'vue'

import ImageCroppingStep from '../components/ImageCroppingStep.vue'
import ConfigGenerationStep from '../components/ConfigGenerationStep.vue'
import PropertyGroupCreationStep from '../components/PropertyGroupCreationStep.vue'

export default Vue.extend({
  components: {
    ImageCroppingStep,
    ConfigGenerationStep,
    PropertyGroupCreationStep
  },
  name: 'Onboarding',
  data: function () {
    return {
      step: 1
    }
  },
  methods: {
    onOnboardingEnd() {
      this.step = 1
      this.$store.dispatch('smartpaying/reset')
    },
    async onCroppedImage(file, psIds) {
      if (file == null || psIds == null) {
        this.step = 2
      }
      else {
        for (const pgId of psIds) {
          const renamedFile = new File([file], `${pgId}.jpg`);
          const formData = new FormData()
          formData.append('file', renamedFile)
          const resp = await this.$store.dispatch('smartpaying/uploadPgLogo', { formData: formData })

          if (resp.response?.status >= 400) {
            const options = { cardTextClass: "pa-4 orange accent-1--text", color: 'orange' }
            const title = "Error uploading image"
            const message = resp.response.data
            await this.$refs.dialog.open(title, message, options)
          }
        }
        this.step = 2
      }
    }
  }
})
</script>
<style lang="scss">
.v-card__text,
.v-card__title {
  word-break: normal;
  /* maybe !important  */
}

.upload {
  margin-top: 20px;
  margin-bottom: 20px;
  user-select: none;

  &__cropper {
    border: solid 1px #eee;
    min-height: 300px;
    max-height: 500px;
    width: 100%;
  }

  &__cropper-wrapper {
    position: relative;
  }

  &__file-type {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #0d0d0d;
    border-radius: 5px;
    padding: 0px 10px;
    padding-bottom: 2px;
    font-size: 12px;
    color: white;
  }

}
</style>
