<template>
    <v-card>
        <v-card-text>
            <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                dark
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFrom"
                    label="Show holidays after"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    @change="fetchHolidays()"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="dateFrom"
                header-color="primary"
                @change="fetchHolidays()"
                ></v-date-picker>
            </v-menu>

            <v-data-table
                :headers="headers"
                :items="holidaysToShow"
                sort-by="holiday_date"
                item-key="uniqueKey"
                :items-per-page="100"
                :footer-props="{
                        itemsPerPageOptions: [100, 500,  -1],
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus'
                }"
            >
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapState} from "vuex"

export default {
    name: "HolidaysByPG",
    data() {
        return {
            TodayDate: new Date(),
            dateFrom: null,

            holidays: [],

            headers: [
                {
                    text: 'Location',
                    value: 'location',
                    width: "1%",
                },
                {
                    text: 'Holiday Name',
                    value: 'holiday_name',
                    width: "1%",
                },
                {
                    text: 'Holiday Date',
                    value: 'holiday_date',
                    width: "10%",
                },
                {
                    text: "Attribute",
                    value: "attr1",
                    width: "1%"
                },
                {
                    text: "Property Group ID",
                    value: "pg_id",
                    width: "1%"
                },
                {
                    text: 'Price Final Coefficient',
                    value: 'value',
                    width: "1%",
                },
            ],
        }
    },
    computed: {
        ...mapState({
            currentPhysicalPSid: state => state.core.currentPropertySets.find(ps => ps.metadata.kind === 'Physical').metadata.id
        }),
        dateTo(){
            return this.formatDate(this.addYears(new Date(this.dateFrom), 5))
        },
        holidaysToShow(){

            // add unique keys to the holidays
            this.holidays.forEach(holiday => {
                holiday.uniqueKey = holiday.location + holiday.holiday_date + holiday.holiday_name.replace(" ", "") + holiday.value
            })

            return this.holidays
        }
    },
    methods: {
        formatDate(date) { // formats the date in YYYY-MM-DD
            return date.toISOString().split("T")[0]
        },
        addYears(date, yearsToAdd) { // add n years to the date
            date.setFullYear(date.getFullYear() + yearsToAdd);
            return date;
        },
        async fetchHolidays(){ // fetch the holidays given 
            let response = await this.$store.dispatch('graph/getHolidaysForPs', {psId: this.currentPhysicalPSid, from: this.dateFrom, to: this.dateTo})
            
            this.holidays = response
        }
    },
    created(){
        // set the default dates
        this.dateFrom = this.formatDate(this.TodayDate)

        // fetches the holidays
        this.fetchHolidays()
    }
}
</script>
