
export default {
    namespaced: true,
    state: {
        versoriConnectionUrl: null
    },
    actions: {
        async getVersoriConnectionUrl(context, payload) {
            const api = context.rootState.api.api

            try {
                
                const propertyGroupId = payload.propertyGroupId
                const userId = payload.userId
                const integrationSub = payload.integrationSub

                const response = await api.get("integrations/v2", `/versori/connectionurl?propertyGroupId=${propertyGroupId}&userId=${userId}&integrationSub=${integrationSub}`, {}, {}, {})

                if ( response?.response?.status === 500 ) {
                    throw response?.response?.data
                }

                return response?.url

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting the versori connection url: ${error?.error}`
                }, {root: true})
                return null
            }

        },
    }
}