var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-card',{staticClass:"card-ui pa-4"},[_c('v-card-title',[_vm._v("Custom Root Events")]),_c('DataFilters',{staticClass:"card-ui",attrs:{"items":_vm.customRootEventsWithPgName,"schema":_vm.filtersSchema},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.selectedRows?.length},on:{"click":_vm.tagAsDiscarded}},[_vm._v("Mark As Not Of General Interest")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.selectedRows?.length},on:{"click":_vm.untagAsDiscarded}},[_vm._v("Mark As Of General Interest")])]},proxy:true}]),model:{value:(_vm.filteredEvents),callback:function ($$v) {_vm.filteredEvents=$$v},expression:"filteredEvents"}}),_c('v-data-table',{attrs:{"headers":_vm.customRootEventsHeaders,"items":_vm.filteredEvents,"items-per-page":100,"footer-props":{
      itemsPerPageOptions: [100, 500,  -1],
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus'
      },"show-select":"","sort-by":"label"},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.dates",fn:function({ item }){return _vm._l((item.dates),function(date){return _c('v-chip',{attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(date.id)+" - "+_vm._s(date.value)+" ")])})}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":!_vm.isSelected(item),"text":"","icon":"","color":"blue lighten-2","dark":""},on:{"click":function($event){return _vm.openDialog(item.id)}}},[_c('v-icon',[_vm._v("fa-solid fa-cog")])],1)]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-dialog',{model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Convert to smartpricing event ")]),_c('v-card-text',[(_vm.showDialog)?_c('EventForm',{attrs:{"type":"add","default-event":_vm.itemToEdit,"geocoding-pgid-list":_vm.selectedPgList},on:{"save-success":_vm.saveSuccess}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }