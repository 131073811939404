<template>
    <v-container fluid class="pa-4">
        <v-row>
            <v-col class="col col-6">
                <PartnersToFeatureTable />
            </v-col>
            <v-col class="col col-6">
                <FeatureToPartnersTable />
            </v-col>
            <v-col class="col col-12">
                <v-card
                    class="card-ui"
                >
                    <v-card-text>
                        <span class="font-weight-bold">No Default Status Mapping</span> => old system, or status mapping not availble in this integration
                        <v-spacer></v-spacer>
                        <span class="font-weight-bold">Missing Status in default status mapping</span> => all statuses that are not in the default status mapping nor in the property group's custom mapping are considered "confirmed"                    
                        <v-spacer></v-spacer>
                        <span class="font-weight-bold">ASYNC prices confirmation</span> => last_send_with_success set in any case (even on error responses) [todo: dynamic lsws update]
                        <v-spacer></v-spacer>
                        <span class="font-weight-bold">ASYNC automapping</span> => a sort of pushed automapping, meaning that it's only available for the automatic insert in database (not for internal dashboad apps)
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PartnersToFeatureTable from '../components/PartnersToFeatureTable.vue';
import FeatureToPartnersTable from '../components/FeatureToPartnersTable.vue';

export default {
    components: {PartnersToFeatureTable, FeatureToPartnersTable}
}
</script>