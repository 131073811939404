<template>
    <v-card class="card-ui pb-1" :class="{ 'grey darken-3': darkMode }">
        <v-card-title>{{ bundle }}</v-card-title>
        <v-card flat class="mx-4 card-ui" v-if="!!currentLifecycleStateByBundle[bundle]">
            <v-alert border="left" :color="lifecycleAlertColor" class="d-flex justify-center align-center"
                style="text-align: center; height: 120px;">
                <h2>{{ currentLifecycleStateByBundle[bundle].metadata.name }}</h2>
            </v-alert>
        </v-card>
        <v-card flat class="mx-4 card-ui" v-else>
            <v-alert outlined color="red" class="d-flex justify-center align-center"
                style="text-align: center; height: 120px">
                <h2>Property Group State NOT SET</h2>
            </v-alert>
        </v-card>
    </v-card>
</template>

<script>

import { mapState, mapGetters } from 'vuex';

export default {
    props: ["bundle"],
    computed: {
        ...mapState({
            darkMode: state => state.ui.darkMode,
        }),
        ...mapGetters({
            currentLifecycleStateByBundle: "lifecycle/currentLifecycleStateByBundle",
        }),
        lifecycleAlertColor() {
            return (this.currentLifecycleStateByBundle[this.bundle].metadata.lifecycleStateName == 'live' || this.currentLifecycleStateByBundle[this.bundle].metadata.lifecycleStateName == 'trial') && this.currentLifecycleStateByBundle[this.bundle].metadata.lifecycleStateLabel !== 'maintenance' ? 'green' : 'red'
        },
    }
}

</script>
