import Vue from 'vue'
import cookie from 'vue-cookies'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

window.onerror = function (message, source, lineno, colno, error) {
  console.log('Hello Exception: ', error)
}

Vue.config.errorHandler = err => {
  if (err.name !== undefined && err.name == "YAMLException") {
    console.log(" A YAMLException occured");
    console.log(err);
    store.state.pipelineV1.yamlIsValid = false;
  }
  else {
    console.log(' Error handler Exception: ', err)
  }
}

Vue.prototype.$cookie = cookie
Vue.config.productionTip = false


router.beforeEach(async (to, from, next) => {
  if (store.state.auth.isAuth == false && to.name !== 'Login') {
    // Verify token
    let cookie = Vue.prototype.$cookie
    const cookieDate = new Date(parseInt(cookie.get('sp-internal-dashboard-auth-date')))
    if (cookie.get('sp-internal-dashboard-auth') == 'true'
      && cookieDate > (new Date()).getTime() - 10 * 60 * 60 * 1000) {
      const token = cookie.get('sp-internal-dashboard-token')
      store.commit('auth/setAuthProperties', {
        isAuth: true,
        username: cookie.get('sp-internal-dashboard-username'),
        name: null,
        accessToken: token
      })
      store.commit('auth/setAuthProperties', {
        isAuth: true,
        username: cookie.get('sp-internal-dashboard-username'),
        name: null,
        accessToken: token
      })
      await store.dispatch('api/makeAPIClient', {
        accessToken: token
      })
    } else {
      router.push({ path: '/login', replace: true })
    }
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
