<template>
    <v-card class="card-ui pa-4 my-2">
        <v-card-title>Insert Random Prices</v-card-title>

        <v-row class="pa-4">
            <v-col class="col-6">
                <v-menu
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="prettifyDateRange(dateRange)"
                            label="Date Range"
                            dense
                            outlined
                            hide-details
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        range
                        v-model="dateRange"
                        @change="datePickerMenu = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>

        <v-card-actions class="pa-4 my-2">
            <v-btn
                color="primary"
                :disable="dateRange?.length !== 2"
                @click="generateRandomPrices()"
            >Generate Random Prices</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapState} from "vuex"

export default {
    data() {
        return {
            datePickerMenu: false,
            dateRange: null,
        }
    },
    computed: {
        ...mapState({
            api: state => state.api.api,
            currentPG: state => state.core.currentPG,
        }),
    },
    methods: {
        async generateRandomPrices() {
            try {
                const [start, end] = this.dateRange[0] < this.dateRange[1] ? [this.dateRange[0], this.dateRange[1]] : [this.dateRange[1], this.dateRange[0]]

                const res = await this.api.post("core/v1", `/prices/random`, {}, {
                    fromDate: start,
                    toDate: end,
                    pgId: this.currentPG.metadata.id,
                }, {})

                if (!!res.error) throw res.error

                this.$store.dispatch("alertSystem/newSuccess", {
                    message: `Random prices generated successfully!`,
                })
            } catch (error) {
                this.$store.dispatch("alertSystem/newError", {
                    message: `Error generating random prices: ${error}`,
                })
            }
        },
        prettifyDateRange(dateRange) {
            if (!dateRange) return null

            const [start, end] = dateRange[0] < dateRange[1] ? [dateRange[0], dateRange[1]] : [dateRange[1], dateRange[0]]
            return dateRange?.length === 2 ? `${start} : ${end}` : null
        }
    }
}
</script>