<template>
  <v-container fluid>
    <v-card
      class="pa-ma-2"
      flat
    >

      <v-card-text>
        <v-form
          :readonly="!editable"
          ref="form"
        >
          <DynamicComponentCatalog
            v-for="(obj, index) in schema"
            v-bind:value="value"
            :schema="schema[index]"
            :basetype="basetype"
            @input="inputHandler"
            @selected-type="$emit('selected-type', $event)"
            @clean-model="inputHandler"
          />
        </v-form>
      </v-card-text>

      <v-card-actions v-if="showDelete">
        <v-btn
          block
          depressed
          plain
          @click="$emit('delete')"
          :disabled="!editable"
        >
          <v-icon >fa-solid fa-trash-can</v-icon>
        </v-btn>
      </v-card-actions>

      <v-divider class="mx-4"></v-divider>       

    </v-card>
  </v-container>

</template>

<script>
import DynamicComponentCatalog from '@/components/form/DynamicComponentCatalog'
export default {
  name: "DynamicForm",
  props: ['value', 'schema', 'basetype', 'showDelete', 'editable'],
  components: {DynamicComponentCatalog},
  data: function () {
    return {
    }
  },
  computed: {
  },
  watch: {},
  methods: {
    inputHandler(obj){
      this.$emit('input', obj)
    },
    getRef(){
      return this.$refs.form
    }
  },
}
</script>
