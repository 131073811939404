<template>
    <v-card class="pa-4 card-ui">
        <v-card-title>Event Source</v-card-title>
        <v-card-subtitle>You can set the event source for the selected pg</v-card-subtitle>

        <v-row class="pa-4">
            <v-col class="col-6">
                <v-autocomplete
                    outlined
                    dense
                    multiple
                    :items="locationsDropdown"
                    hide-details
                    chips
                    deletable-chips
                    small-chips
                    v-model="selectedLocations"
                    label="Select Location"
                ></v-autocomplete>
            </v-col>
        </v-row>

        <v-card-actions class="pa-4">
            <v-btn
                @click="savePg()"
                color="primary"
            >Link</v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>
import { mapState } from "vuex"

export default {
    props: ["value"],
    data() {
        return {
            valueCopy: null,

            selectedLocations: null,
        }
    },
    watch: {
        value: {
            deep: true,
            handler: function(newVal, oldVal) {
                this.selectedLocations = null
                this.valueCopy = structuredClone(this.value) || null
                if (!!this.valueCopy) {
                    this.selectedLocations = this.valueCopy.link.EventSource.map(val => val.id)
                }
            }
        }
    },
    computed: {
        ...mapState({
            locations: state => state.graph.locations
        }),
        locationsDropdown() {
            return this.locations.filter(loc => ["Location:City", "Location:Market"].includes(loc.kind)).map(loc => {
                return {
                    text: `${String(loc.metadata.id).replaceAll(".", " ").replaceAll("_", " - ")} - ${loc.kind.split(':')[1]}`,
                    value: loc.metadata.id,
                } 
            })
        },
        locationsById() {
            const hashMap = {}
            this.locations.forEach(loc => {
                hashMap[loc.metadata.id] = loc
            })
            return hashMap
        },
    },
    methods: {
        async savePg() {
            try {
                let copyToSave = structuredClone(this.valueCopy)
    
                if (!copyToSave) {
                    const pgId = this.$store.state.core.currentPG.metadata.id
                    copyToSave = {}
                    copyToSave.kind = "Propertygroup"
                    copyToSave.link = {}
                    copyToSave.metadata = {
                        id : pgId
                    }
                    copyToSave.spec = {
                        label : this.$store.state.core.currentPG.metadata.name
                    }
                    copyToSave.link.Root = []
                    copyToSave.link.EventSource = []
                }
                if (copyToSave.link !== undefined && copyToSave.link !== null) {
                    delete copyToSave.link.HolidaySource
                    delete copyToSave.link.HolidayTarget
                    delete copyToSave.link.HolidayReferenceMarket
                }
    
                this.selectedLocations.forEach(loc => {
                    const found = copyToSave.link.EventSource.findIndex(val => val.id === loc) !== -1
                    if (!found) {
                        const newLoc = this.locationsById[loc]
                        copyToSave.link.EventSource.push({
                            id: newLoc.metadata.id,
                            kind: newLoc.kind,
                            value: Date.now(),
                        })
                    }
                })
    
                // delete not selected values
                copyToSave.link.EventSource = copyToSave.link.EventSource.filter(val => {
                    return this.selectedLocations.includes(val.id)
                })
    
    
                let res = await this.$store.dispatch('graph/saveResource', {
                    body: copyToSave
                })
    
                if (!!res.error) {
                    throw res.error
                }
    
                this.valueCopy = copyToSave
                this.$emit("input", this.valueCopy)
                this.$store.dispatch("alertSystem/newSuccess", {
                    message: "Event source updated successfully",
                })
            } catch (error) {
                this.$store.dispatch("alertSystem/newError", {
                    message: `Event source failed to update: ${error}`,
                })
            }
        }
    },
    created() {
        this.selectedLocations = null
        this.valueCopy = structuredClone(this.value) || null
        if (!!this.valueCopy) {
            this.selectedLocations = this.valueCopy.link.EventSource.map(val => val.id)
        }
    }
}
</script>