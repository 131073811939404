import Vue from "vue"
import { ChartUtils } from "../../../utils/chartUtils"

const colors = [
  'rgba(127, 63, 191, 0.65)', 
  'rgba(53, 176, 22, 0.65)', 
  'rgba(237, 118, 27, 0.93)', 
  'rgba(27, 41, 237, 0.97)', 
  'rgba(237, 27, 41, 0.97)', 
  'rgba(27, 146, 237, 0.64)',
  'rgba(88, 138, 176, 0.83)',
  'rgba(92, 238, 226, 0.4)',
  'rgba(238, 92, 224, 0.59)',
  'rgba(238, 92, 224, 0.59)',
  'rgba(49, 234, 46, 0.91)',
]



// doesn't have a name
function newGet2DContourChartTraces(newDfs, xCol, yCol, yearsMap, yearCol) {
  const tracesMap = {}
  const strategies = Object.keys(newDfs)
  strategies.forEach(strategy => {
    tracesMap[strategy] = []
    const dataFrame = newDfs[strategy]
    const years = yearsMap[strategy]

    // i don't fucking know what this is
    tracesMap[strategy].push({
      x: dataFrame[xCol],
      y: dataFrame[yCol],
      z: dataFrame[yearCol],
      showscale: false,
      type: 'histogram2dcontour',
      reversescale: true,
      colorscale: 'jet',
      contours:{
        coloring: 'lines'
      },
      connectgaps: true,
      fillcolor: 'rgb(35, 141, 221)',
      mode: "markers",
      autocontour: true, 
    })


    const valuesByYear = ChartUtils.valuesByKey(dataFrame, yearCol)

    years.forEach((year, i) => {
      let yearColor = colors[i];
  
      const yearValues = valuesByYear[year]
  
      tracesMap[strategy].push({
        x: yearValues[xCol],
        marker: {color: yearColor},
        yaxis: 'y2',
        type: 'histogram',
        showlegend: false,
        hovertemplate: 'Year: '+ year + '<br>Price: %{y}<br>Book days range: %{x}<br><extra></extra>',
      })
  
      tracesMap[strategy].push({
        y: yearValues[yCol],
        name: year.toString(), //+ ' ' + yLabel,
        marker: {color: yearColor},
        xaxis: 'x2',
        type: 'histogram',
        hovertemplate: 'Year: '+ year + '<br>Price range: %{y}<br>Book days: %{x}<br><extra></extra>',
      })
    })
  })

  return tracesMap
}

// yearly price | price by channel type | rate description | yearly board type price
function newGetBaseBplot(newDfs, xCol, yCol, yearsMap, yearCol, orientation) {
  const tracesMap = {}
  const strategies = Object.keys(newDfs)
  strategies.forEach(strategy => {
    tracesMap[strategy] = [] 
    const dataFrame = newDfs[strategy]
    const years = yearsMap[strategy]

    const valuesByYear = ChartUtils.valuesByKey(dataFrame, yearCol)

    years.forEach((year, i) => {
      let yearColor = colors[i];
  
      const yearValues = valuesByYear[year]

      if(!!xCol){
        tracesMap[strategy].push({
          x: yearValues[xCol],
          y: yearValues[yCol],
          name: String(year),
          marker: {color: yearColor},
          type: 'box',
          orientation: orientation
        })

      }else{

        tracesMap[strategy].push({
          y: yearValues[yCol],
          name: String(year),
          marker: {color: yearColor},
          type: 'box',
          orientation: orientation
        })

      }
    })
  })
  
  return tracesMap
}

// monthly adr by year
function newGetMonthTotalsBarChartData(newDfs, xCol, yCol, yearsMap, yearCol) {
  const monthsMap = {1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
  const tracesMap = {}
  const strategies = Object.keys(newDfs)
  strategies.forEach(strategy => {
    tracesMap[strategy] = [] 
    const dataFrame = newDfs[strategy]
    const years = yearsMap[strategy]

    const valuesByYear = ChartUtils.valuesByKey(dataFrame, yearCol)

    years.forEach((year, i) => {
      const yearValues = valuesByYear[year]

      tracesMap[strategy].push({
        x: yearValues[xCol].map(val => monthsMap[val]),
        y: yearValues[yCol],
        name: String(year),
        marker: {color: colors[i]},
        type: 'bar',
        hovertemplate: `Year: ${year} <br>Month: %{x}<br>ADR: %{y:.2f}<extra></extra>`,
      })
      
    })
  })

  return tracesMap
}

// properties price by year
function newGetPropertiesPriceBPlotChartData(newDfs, xCol, yCol, yearsMap, yearCol, idCol, nameCol){
  const tracesMap = {}
  const strategies = Object.keys(newDfs)
  strategies.forEach(strategy => {
    tracesMap[strategy] = [] 
    const dataFrame = newDfs[strategy]
    const years = yearsMap[strategy]

    const valuesByYear = ChartUtils.valuesByKey(dataFrame, yearCol)

    years.forEach((year, i) => {
      const yearValues = valuesByYear[year]

      tracesMap[strategy].push({
        x: yearValues["pid_name"],
        y: yearValues[yCol],
        name: String(year),
        marker: {color: colors[i]},
        type: 'box'
      })
      
    })
  })

  return tracesMap
}

// adr by origin channel
function newGetChannelTypePieChartData(newDfs, labelsCol, valuesCol, traceName ){
  const tracesMap = {}
  const strategies = Object.keys(newDfs)
  strategies.forEach(strategy => {
    tracesMap[strategy] = [] 
    const dataFrame = newDfs[strategy]

    let labels = Array.from(new Set(dataFrame[labelsCol]))

    tracesMap[strategy].push({
      values: dataFrame[valuesCol],
      labels: dataFrame[labelsCol],
      name: traceName,
      marker: {color: colors.slice(0, labels.length)},
      type: 'pie',
      insidetextorientation: "radial",
      hole: .4,
    })
      
  })

  return tracesMap
}

// adr price range
function newGetAdrRangePieChartData(newDfs, adrCol, traceName){
  const tracesMap = {}
  const valueRangesLabels = ['<50', '50-100', '100-150', '150-200','200-250','>250'];
  const strategies = Object.keys(newDfs)
  strategies.forEach(strategy => {
    tracesMap[strategy] = [] 

    const dataFrame = newDfs[strategy]

    let rangesLabels = dataFrame[adrCol]?.map((x)=>{
      let rangeClass = Math.trunc(x/50);
      if(rangeClass > 5){
        rangeClass = 5;
      }
      let label = valueRangesLabels[rangeClass];    
      return label;
    })

    tracesMap[strategy].push({
      values: dataFrame[adrCol],
      labels: rangesLabels,
      name: traceName,
      marker: {color: colors.slice(0, valueRangesLabels.length)},
      type: 'pie',
      insidetextorientation: "radial",
      hole: .4,
    })
      
  })

  return tracesMap;
}

// rate description pie chart
function newGetRateDescriptionPieChartData(newDfs, valuesCol, labelsCol ){
  const tracesMap = {}
  const strategies = Object.keys(newDfs)
  strategies.forEach(strategy => {
    tracesMap[strategy] = [] 
    const dataFrame = newDfs[strategy]

    let labels = Array.from(new Set(dataFrame[labelsCol]))

    tracesMap[strategy].push({
      values: dataFrame[valuesCol],
      labels: dataFrame[labelsCol],
      marker: {color: colors.slice(0, labels.length)},
      type: 'pie',
      insidetextorientation: "radial",
      hole: .4,
    })
      
  })

  return tracesMap
}

// board type pie chart
function newGetBoardTypePieChartData(newDfs, valuesCol){
  const tracesMap = {}
  const strategies = Object.keys(newDfs)
  strategies.forEach(strategy => {
    tracesMap[strategy] = [] 
    const dataFrame = newDfs[strategy]

    const values = dataFrame[valuesCol]

    let count = {}  // 1-> 123  2->32  3->23

    for ( let i = 0; i < values?.length; i++ ) {
      let val = values[i]
      count[val] = count[val] == undefined ? 1 : count[val] + 1
    }

    tracesMap[strategy].push({
      values: Object.values(count),
      labels: Object.keys(count),
      marker: {color: colors.slice(0, Array.from(new Set(values)).length)},
      type: 'pie',
      insidetextorientation: "radial",
      hole: .4,
    })
      
  })

  return tracesMap
}

// guest count price by year | stay length price by year
function newGetGuestCountBPlotsData(newDfs, yearsMap, yearCol, guestCountCol, priceCol, pIdCol, pNameCol, countLabel){
  const tracesMap = {}
  const strategies = Object.keys(newDfs)
  // to check again
  strategies.forEach(strategy => {
    const yearTracesMap = {}
    const dataFrame = newDfs[strategy]
    const years = yearsMap[strategy]

    const valuesByYear = ChartUtils.valuesByKey(dataFrame, yearCol)

    years.forEach((year, i) => {
      yearTracesMap[year] = []
      const yearValues = valuesByYear[year]

      let gcounts = Array.from(new Set(yearValues[guestCountCol])).sort();

      for (let guestCountsCurrentYear of gcounts) {
        const valuesByGuestCountForYear = ChartUtils.valuesByKey(yearValues, guestCountCol)
        const dfGCount = valuesByGuestCountForYear[parseInt(guestCountsCurrentYear)]
        
        yearTracesMap[year].push({
          x: dfGCount[priceCol],
          y: dfGCount['pid_name'],
          type: 'box',
          orientation: 'h',
          name: `${year}: ${guestCountsCurrentYear} ${countLabel}`,
          hovertemplate: `${year} <br>Price: %{x}<br>Property: %{y}<extra></extra>`,
        })
      
      }
    })

    tracesMap[strategy] = [yearTracesMap, years]
  })
  
  return tracesMap
}

// guest count by property
function newGetGuestCountByPropertyPieChart(newDfs, guestCountCol){
  const tracesMap = {}
  const strategies = Object.keys(newDfs)
  strategies.forEach(strategy => {
    const dataFrame = newDfs[strategy]

    const valuesByProp = ChartUtils.valuesByKey(dataFrame, "property_id")

    const pids_pies = {}
    const pids_labels = []
    
    Object.keys(valuesByProp).forEach(prop => {
      let tracecolors = [];

      pids_labels.push(prop)

      const valuesCurrentProp = valuesByProp[prop]

      const values = valuesCurrentProp[guestCountCol]

      let count = {}  // 1-> 123  2->32  3->23
                
      for ( let i = 0; i < values.length; i++ ) {
        let val = values[i]
        count[val] = count[val] == undefined ? 1 : count[val] + 1
      }

      let gCounLabels = Array.from(new Set(valuesCurrentProp[guestCountCol])).sort();

      for(var l=0; l < gCounLabels.length; l++){
        tracecolors.push(colors[l]);
      }

      pids_pies[prop] = [{
        values: Object.values(count),
        labels: Object.keys(count),
        marker: {color: tracecolors},
        type: 'pie',
        insidetextorientation: "radial",
        hole: .4,
      }]

    })

    tracesMap[strategy] = {'properties_charts': pids_pies, 'properties_labels': pids_labels}
  })

  return tracesMap
}

// yearly nights count | yearly price sum
function newGetNightsCountBarStackChart(newDfs, yearCol, priceCol, pIdCol, pNameCol, countLabel, aggregator ){
  const tracesMap = {}
  const strategies = Object.keys(newDfs)
  strategies.forEach(strategy => {
    tracesMap[strategy] = [] 
    
    const dataFrame = newDfs[strategy]
    const valuesByProp = ChartUtils.valuesByKey(dataFrame, "pid_name")

    Object.keys(valuesByProp).forEach((prop, index) => {
      const x = []
      const y = []
      const currentPropValues = valuesByProp[prop]

      const currentPropValuesByYear = ChartUtils.valuesByKey(currentPropValues, yearCol)

      Object.entries(currentPropValuesByYear).forEach(([year, propVals]) => {
        x.push(year)

        y.push(aggregator === "count" ? propVals[priceCol].length : propVals[priceCol].reduce((a, b) => {
          return a+b
        }))
      })

      tracesMap[strategy].push({
        x: x,
        y: y,
        name: prop,
        color: colors[index],
        type: 'bar',
        hovertemplate: 'Year: %{x}<br>Property: ' + prop +'<br>' + aggregator +': %{y:.0f}<extra></extra>',
      })
    })
  })

  return tracesMap
}

export default {
  namespaced: true,

  state: {
    //recurrent columns
    yearCol: 's_year',
    priceCol: 'price',
    adrCol: 'adr',
    propertyIdCol: 'property_id',
    propertyNameCol: 'name',


    //2d contour chart
    contourChartData2D: null,
    series2DXcol: 'book_days',

    //bulk channel type boxplot price-nights
    bulkChannelTypeBPlotChartData: null,
    xColBulkChannel: 'origin_channel', 


    //price-night boxplot by channel type
    channelTypeBPlotChartData: null,
    xColChannelTypeChannel: 'origin_channel', 


    //months total bar chart
    monthTotalsBarChartData: null,
    xColMonthTotal: 's_month', 


    //properties price boxplots
    propertiesPriceBPlotChartData: null,
    xColpropPrice: 'property_id_named', 


    //channel type price pie
    channelTypePieChartData: null,
    channelTypePieChartName: 'Origin channel',
    labelsChannelTypePieCol: 'origin_channel',


    //price range pie chart
    adrRangePieChartData: null,
    adrRangePieChartName: 'Adr price range',


    //rate description boxplot
    rateDescriptionBPlotData: null,
    yColRateDescription: 'rate_description', 


    //rate description pie
    rateDescriptionPieChartData: null,
    labelsrateDescriptionPieCol: 'rate_description',


    //board type pie chart data
    boardTypePieChartData: null,
    labelsBoardTypePieCol: "board_type",


    //price-property-guest count by year
    guestCountBPlotsYears: [],
    guestCountBPlotsCountCol: 'guest_count',


    //bplot board type
    boardTypeBPlotData: null,
    boardTypeBPlotBoardCol: 'board_type',



    //price-property-length stay by year
    stayLengthBPlotsTracesMap: {},
    stayLengthBPlotsYears: [],
    stayLengthBPlotsCountCol: 'length',


    //nights count bar chart stacked
    nightsCountBarStackData: null,
    nightsCountBarStackLabel: 'Count',


    //nights count bar chart stacked
    nightsPriceSumBarStackData: null,
    nightsSumBarStackLabel: 'Sum',

    //property-geuest per night
    propertyNameGuestCol: 'property_id_named',
    guestCountCol: 'guest_count',
    propertyNameGuestCountPieChartData: null,

    // traces lists
    tracesLists: {
      contour: null, 
      yearlyPrice: null,
      priceByChannelType: null,
      monthlyAdr: null,
      propertiesPrice: null,
      adrOriginChannel: null,
      adrPriceRange: null,
      rateDescription: null,
      rateDescriptionPieChart: null,
      boardTypePieChart: null,
      guestCountPrice: null,
      yearlyBoardType: null,
      stayLengthPrice: null,
      yearlyNightCount: null,
      yearlyPriceSum: null,
      guestCountByProperty: null,
    }
  },

  getters: {

    allPreprocessPipelineBindsLoaded(state, getters, rootState, rootGetters) {
      const alreadyCalculated = Object.keys(rootState.pipelineV1.pipelineBindsCalculationResults)

      const notCalculated = rootGetters["pipelineV1/preprocessStrategies"].filter(pb => {
        return !alreadyCalculated.includes(pb)
      })

      return !notCalculated.length
    },

    yearsMap(state, getters, rootState, rootGetters) {
      const preprocessResults = rootGetters["pipelineV1/pipelineBindsPreprocessCalculationResults"]

      const hashMap = {}
      Object.entries(preprocessResults).forEach(([stratName, strat]) => {
        hashMap[stratName] = Array.from(new Set(strat.result.stats.s_year)).sort((a, b) => a-b)
      })

      return hashMap
    },

    priceBookYearsMap(state, getters, rootState, rootGetters){
      const preprocessResults = rootGetters["pipelineV1/pipelineBindsPreprocessCalculationResults"]

      const hashMap = {}
      Object.entries(preprocessResults).forEach(([stratName, strat]) => {
        hashMap[stratName] = Array.from(new Set(strat.result.month_totals.s_year)).sort((a, b) => a-b)
      })

      return hashMap
    },

    preprocessDataFramesByStrategy(state, getters, rootState, rootGetters) {
      return Object.fromEntries(Object.entries(rootGetters["pipelineV1/pipelineBindsPreprocessCalculationResults"]).map(([pbName, pbData]) => {
        return [pbName, pbData.result.stats]
      }))
    },

    priceBookDataFramesByStrategy(state, getters, rootState, rootGetters) {
      return Object.fromEntries(Object.entries(rootGetters["pipelineV1/pipelineBindsPreprocessCalculationResults"]).map(([pbName, pbData]) => {
        return [pbName, pbData.result.month_totals]
      }))
    }

  },
    
  mutations: {

    resetAllChartTraces(state) {
      const charts = Object.keys(state.tracesLists)

      charts.forEach(ch => {
        state.tracesLists[ch] = null
      })
    },
    
    resetChartTraces(state, payload) {
      if (!payload?.key) return 

      Vue.set(state.tracesLists, payload.key, null)

      if (!!payload?.value) {
        Vue.set(state.tracesLists, payload.key, payload.value)
      }
    },


  
  },
  
  actions: {

    async fetchPreprocessData (context) { // fetches only not loaded pipelinebinds
      try {

        const parsedPipelineBinds = context.rootGetters["pipelineV1/preprocessStrategies"].filter(pbName => {
          return !context.rootGetters["pipelineV1/alreadyLoadedPipelineBinds"].includes(pbName)
        }).map(pbName => {

          const pb = context.rootGetters["pipelineV1/pipelineBindByLabel"][pbName]

          return {
            name: pb.resource.metadata.name,
            workspace: pb.resource.metadata.workspace,
            forceCache: false,
          }
        })

        await Promise.all(parsedPipelineBinds.map(async (parsedPb) => {

          await context.dispatch("pipelineV1/runPipelinebind", {
            workspace: parsedPb.workspace,
            name: parsedPb.name,
            forceCache: parsedPb.forceCache,
          }, {root: true})
          
        }))

      } catch (error) {
        context.dispatch("alertSystem/newError", {
          message: `Failed to fetch the preprocess data! Error: ${error}`,
        })
      }
    },

    // loaders for the charts
    async loadContourChart(context) {
      
      const traces = newGet2DContourChartTraces(
        context.getters.preprocessDataFramesByStrategy,
        context.state.series2DXcol,
        context.state.priceCol,
        context.getters.yearsMap,
        context.state.yearCol
      )

      context.commit("resetChartTraces", {
        key: "contour",
        value: traces,
      })
    },

    async loadYearlyPriceChart(context) {
      
      const traces = newGetBaseBplot(
        context.getters.preprocessDataFramesByStrategy, 
        null, 
        context.state.priceCol,
        context.getters.yearsMap,
        context.state.yearCol,
        'v'
      )

      context.commit("resetChartTraces", {
        key: "yearlyPrice",
        value: traces,
      })
    },

    async loadPriceByChannelTypeChart(context) {

      const traces = newGetBaseBplot(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.xColChannelTypeChannel, 
        context.state.priceCol,
        context.getters.yearsMap,
        context.state.yearCol,
        'v'
      )

      context.commit("resetChartTraces", {
        key: "priceByChannelType",
        value: traces,
      })
    },

    async loadMonthlyAdrChart(context) {

      const traces = newGetMonthTotalsBarChartData(
        context.getters.priceBookDataFramesByStrategy, 
        context.state.xColMonthTotal, 
        context.state.adrCol, 
        context.getters.priceBookYearsMap,
        context.state.yearCol,
      )

      context.commit("resetChartTraces", {
        key: "monthlyAdr",
        value: traces,
      })
    },

    async loadPropertiesPriceChart(context) {

      const traces = newGetPropertiesPriceBPlotChartData(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.xColpropPrice, 
        context.state.priceCol, 
        context.getters.yearsMap, 
        context.state.yearCol, 
        context.state.propertyIdCol, 
        context.state.propertyNameCol
      )

      context.commit("resetChartTraces", {
        key: "propertiesPrice",
        value: traces,
      })
    },

    async loadAdrOriginChannelChart(context) {

      const traces = newGetChannelTypePieChartData(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.labelsChannelTypePieCol, 
        context.state.adrCol, 
        context.state.channelTypePieChartName
      )

      context.commit("resetChartTraces", {
        key: "adrOriginChannel",
        value: traces,
      })
    },

    async loadAdrPriceRangeChart(context) {

      const traces = newGetAdrRangePieChartData(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.adrCol, 
        context.state.adrRangePieChartName
      )

      context.commit("resetChartTraces", {
        key: "adrPriceRange",
        value: traces,
      })
    },

    async loadRateDescriptionChart(context) {

      const traces = newGetBaseBplot(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.priceCol, 
        context.state.yColRateDescription, 
        context.getters.yearsMap, 
        context.state.yearCol, 
        'h'
      )

      context.commit("resetChartTraces", {
        key: "rateDescription",
        value: traces,
      })
    },

    async loadRateDescriptionPieChart(context) {

      const traces = newGetRateDescriptionPieChartData(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.priceCol, 
        context.state.labelsrateDescriptionPieCol
      )

      context.commit("resetChartTraces", {
        key: "rateDescriptionPieChart",
        value: traces,
      })
    },

    async loadBoardTypePieChart(context) {

      const traces = newGetBoardTypePieChartData(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.labelsBoardTypePieCol
      )

      context.commit("resetChartTraces", {
        key: "boardTypePieChart",
        value: traces,
      })
    },

    async loadGuestCountPriceChart(context) {

      const traces = newGetGuestCountBPlotsData(
        context.getters.preprocessDataFramesByStrategy, 
        context.getters.yearsMap, 
        context.state.yearCol, 
        context.state.guestCountBPlotsCountCol, 
        context.state.priceCol, 
        context.state.propertyIdCol, 
        context.state.propertyNameCol, 
        'guests'
      )

      context.commit("resetChartTraces", {
        key: "guestCountPrice",
        value: traces,
      })
    },

    async loadYearlyBoardTypeChart(context) {

      const traces = newGetBaseBplot(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.boardTypeBPlotBoardCol, 
        context.state.priceCol, 
        context.getters.yearsMap, 
        context.state.yearCol, 
        'v'
      )

      context.commit("resetChartTraces", {
        key: "yearlyBoardType",
        value: traces,
      })
    },

    async loadStayLengthPriceChart(context) {

      const traces = newGetGuestCountBPlotsData(
        context.getters.preprocessDataFramesByStrategy, 
        context.getters.yearsMap, 
        context.state.yearCol, 
        context.state.stayLengthBPlotsCountCol, 
        context.state.priceCol, 
        context.state.propertyIdCol, 
        context.state.propertyNameCol, 
        'days'
      )

      context.commit("resetChartTraces", {
        key: "stayLengthPrice",
        value: traces,
      })
    },

    async loadYearlyNightCountChart(context) {

      const traces = newGetNightsCountBarStackChart(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.yearCol, 
        context.state.priceCol, 
        context.state.propertyIdCol, 
        context.state.propertyNameCol, 
        context.state.nightsCountBarStackLabel, 
        'count'
      )

      context.commit("resetChartTraces", {
        key: "yearlyNightCount",
        value: traces,
      })
    },

    async loadYearlyPriceSumChart(context) {

      const traces = newGetNightsCountBarStackChart(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.yearCol, 
        context.state.priceCol, 
        context.state.propertyIdCol, 
        context.state.propertyNameCol, 
        context.state.nightsSumBarStackLabel, 
        'sum'
      )

      context.commit("resetChartTraces", {
        key: "yearlyPriceSum",
        value: traces,
      })
    },

    async loadGuestCountByPropertyChart(context) {

      const traces = newGetGuestCountByPropertyPieChart(
        context.getters.preprocessDataFramesByStrategy, 
        context.state.guestCountCol, 
        context.state.propertyIdCol, 
        context.state.propertyNameCol
      )

      context.commit("resetChartTraces", {
        key: "guestCountByProperty",
        value: traces,
      })
    }

  }
}