
export default {
    namespaced: true,
  
    state: {
      
        alerts: []
    
    },
  
    getters: {
        alertsPresent(state) {
            return !!state.alerts.length
        },
      
    },
  
    mutations: {
        addAlert(state, payload) {
            state.alerts.push({
                id: payload.id,
                type: payload.type,
                message: payload.message,
                icon: payload.icon,
                title: payload.title,
            })
        },

        deleteAlert(state, payload) {
            const index = state.alerts.findIndex(alert => alert.id === payload.id)

            if (index !== -1) {
                state.alerts.splice(index, 1)
            } 
        },

        resetAlerts(state) {
            state.alerts = []
        }
        
    },
  
    actions: {

        async addAlert(context, payload) {
            const randomId = `${new Date().getTime()}${Math.floor(Math.random() * 100)}${payload.message.length}`

            context.commit("addAlert", {
                id: randomId,
                type: payload.type,
                title: payload.title,
                message: payload.message,
                icon: payload.icon,
                color: payload.color,
            })

            if (payload.timeout > 0) {
                setTimeout(() => {
                    context.commit("deleteAlert", {id: randomId})
                }, payload.timeout)
            }
        },
        
        async newSuccess(context, payload) {
            await context.dispatch("addAlert", {
                type: "success",
                title: payload.title || "Success",
                message: payload.message || "",
                icon: payload.icon || undefined,
                timeout: payload.timeout || 2000,
                color: payload.color || undefined,
            })
        },

        async newInfo(context, payload) {
            await context.dispatch("addAlert", {
                type: "info",
                title: payload.title || "Info",
                message: payload.message,
                icon: payload.icon || undefined,
                timeout: payload.timeout || 3000,
                color: payload.color || undefined,
            })
        },

        async newWarning(context, payload) {
            await context.dispatch("addAlert", {
                type: "warning",
                title: payload.title || "Warning",
                message: payload.message,
                icon: payload.icon || undefined,
                timeout: payload.timeout || 3000,
                color: payload.color || undefined,
            })
        },

        async newError(context, payload) {
            await context.dispatch("addAlert", {
                type: "error",
                title: payload.title || "Error",
                message: payload.message,
                icon: payload.icon || undefined,
                timeout: payload.timeout || 3000,
                color: payload.color || undefined,
            })
        }
      
    },
}
  