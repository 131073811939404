<template>
    <v-card elevation="0">
        <v-card-actions>
            <v-btn 
                @click="addNewModifier()"
                color="primary"
            >Add Demand Map Modifier</v-btn>
        </v-card-actions>

        <v-row class="mt-2">
            <v-col class="col-12 my-2 card-ui pa-2" v-for="modifier, index of valueCopy">
                <v-row>
                    <v-col class="col-5">
                        <v-autocomplete
                            :items="bookDaysOptions || []"
                            dense
                            multiple
                            :value="modifier?.book_days?.map(el=>el.join(','))"
                            @change="[valueCopy[index].book_days = $event.map((el)=>el.split(',').map(val => Number(val))), $emit('input', valueCopy)]"
                            :disabled="disabled"
                            :readonly="readonly"
                            hide-details
                            outlined
                            label="Book Days"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-5">
                        <v-autocomplete
                            :items="seasonOptions || []"
                            dense
                            multiple
                            :value="modifier?.seasons"
                            @change="[valueCopy[index].seasons = $event, $emit('input', valueCopy)]"
                            :disabled="disabled"
                            :readonly="readonly"
                            outlined
                            hide-details
                            label="Season"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-2">
                        <v-btn
                            color="error"
                            block
                            @click="[valueCopy.splice(index, 1), $emit('input', valueCopy)]"
                        >
                            Delete
                        </v-btn>
                    </v-col>
                    <v-col class="col-12">
                        <v-slider
                            :max="0.2"
                            :min="-0.2"
                            dense
                            :value="modifier?.delta"
                            @change="[valueCopy[index].delta = $event, $emit('input', valueCopy)]"
                            :disabled="disabled"
                            :readonly="readonly"
                            hide-details
                            :step="0.02"
                            :thumb-size="16"
                            label="Delta"
                            thumb-label="always"
                            ticks
                        ></v-slider>
                    </v-col>
                    <v-col class="col-12">
                        <v-slider
                            :value="modifier?.coefficient"
                            :max="1.5"
                            :min="0.5"
                            dense
                            @change="[valueCopy[index].coefficient = $event, $emit('input', valueCopy)]"
                            :disabled="disabled"
                            :readonly="readonly"
                            :step="0.05"
                            hide-details
                            :thumb-size="16"
                            label="Coefficient"
                            thumb-label="always"
                            ticks
                        ></v-slider>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
        },
        bookDaysOptions: {
            type: Array,
        },
        seasonOptions: {
            type: Array,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            valueCopy: null,
        }
    },
    watch: {
        value: {
            deep: true,
            handler: function(newVal, oldVal) {
                this.valueCopy = structuredClone(newVal || [])
            }
        }
    },
    methods: {
        addNewModifier() {
            this.valueCopy.push({
                seasons: null,
                book_days: null,
                delta: 0,
                coefficient: 1.0
            })
        }
    },
    created() {
        this.valueCopy = structuredClone(this.value || [])
    }
}
</script>