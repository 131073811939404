<template>
   <perfect-scrollbar>
  <div class="home">
    <v-container fluid class="pt-0" v-if="pipelinBindsReady == true && !!globalSelectedPgId">   
      <MapsSelector
        :editable="editable"
      />
      <!-- <CompsetCorrection
        :editable="editable"
      ></CompsetCorrection> -->
      <MapsTypeEditor 
        :fields="pickupMaps" 
        :editable="editable"
      />
      <MapsTypeEditor 
        :fields="typePickupMaps" 
        :editable="editable"
      />
      <MapsTypeEditor 
        :fields="dayOfTheWeekMaps" 
        :editable="editable"
      />     
       <MapsTypeEditor 
        :fields="farOutPremiumMap" 
        :editable="editable"
      /> 
       <MapsTypeEditor 
        :fields="customDropMaps" 
        :editable="editable"
      /> 
       <MapsTypeEditor 
        :fields="unitPickupMaps" 
        :editable="editable"
      /> 
      <CustomerThresholdMapsEditor 
        :fields="customerThresholdMaps"
        :editable="editable"
      />

    </v-container>
  </div>
  </perfect-scrollbar>
</template>

<script>

const js_yaml = require('js-yaml')

import MapsTypeEditor from './StandardMapDateRange';
import MapsSelector from './UseMapsSelector';
import CustomerThresholdMapsEditor from './CustomerThresholdMaps';
import CompsetCorrection from './CompsetCorrection.vue'
import * as yamlUtils from '@/utils/standardMapsYamlUtils.js' 
import { mapGetters } from 'vuex';



export default {
  name: 'StandardMapsYamlEditor',   
  props: ['yaml', 'editable'], //yaml is actually a customConfigMap.parameters object
  components: {
    MapsTypeEditor,
    CustomerThresholdMapsEditor,
    MapsSelector,
    CompsetCorrection
  },
  data: function (argument) {
    return {

      pipelinBindsReady: true,
      mapsHash: {},
      pgData: null,

    }
  },
  watch: {

    '$store.$store.state.editor.codeToRender'(newVal, oldVal){
      this.$forceUpdate();
    },    
       
  },
  computed: {
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId"
    }),
    typePickupMaps(){
      var params = this.yaml;
      if(params.pickup === undefined){
        params.pickup = {};
      }
      if(params.pickup.type_pickup_maps === undefined){
        params.pickup.type_pickup_maps = [];
      }

      //convert yaml format-maps into visual editor format
      this.$store.state.mapsYamlEditor.type_pickup_maps.maps = params.pickup.type_pickup_maps;
      this.$store.state.mapsYamlEditor.type_pickup_maps = yamlUtils.yamlMapsToVisualMaps(this.$store.state.mapsYamlEditor.type_pickup_maps);
      return this.$store.state.mapsYamlEditor.type_pickup_maps;
    },

    pickupMaps(){
      var params = this.yaml;
      if(params.pickup === undefined){
        params.pickup = {};
      }
      if(params.pickup.pickup_maps === undefined){
        params.pickup.pickup_maps = [];
      }

      //convert yaml format-maps into visual editor format
      this.$store.state.mapsYamlEditor.pickup_maps.maps = params.pickup.pickup_maps;
      this.$store.state.mapsYamlEditor.pickup_maps = yamlUtils.yamlMapsToVisualMaps(this.$store.state.mapsYamlEditor.pickup_maps);

      return this.$store.state.mapsYamlEditor.pickup_maps;
    },

    dayOfTheWeekMaps(){
      var params = this.yaml;
      if(params.dow === undefined){
        params.dow = {};
      }    
      if(params.dow.pickup_day_of_week_maps === undefined){
        params.dow.pickup_day_of_week_maps = [];
      }
      this.$store.state.mapsYamlEditor.pickup_day_of_week_maps.maps = params.dow.pickup_day_of_week_maps;
      this.$store.state.mapsYamlEditor.pickup_day_of_week_maps = yamlUtils.yamlMapsToVisualMaps(this.$store.state.mapsYamlEditor.pickup_day_of_week_maps);

      return this.$store.state.mapsYamlEditor.pickup_day_of_week_maps;
    },

    farOutPremiumMap(){
      var params = this.yaml;
      if(params.drop === undefined){
        params.drop = {};
      } 
      if(params.drop.far_out_premium_map === undefined){
        params.drop.far_out_premium_map = [];
      } 
      this.$store.state.mapsYamlEditor.far_out_premium_maps.maps = [params.drop.far_out_premium_map];
      this.$store.state.mapsYamlEditor.far_out_premium_maps = yamlUtils.yamlMapsToVisualMaps(this.$store.state.mapsYamlEditor.far_out_premium_maps);
      return this.$store.state.mapsYamlEditor.far_out_premium_maps;
    },

    customDropMaps(){
      var params = this.yaml;
      if(params.drop === undefined){
        params.drop = {};
      }
      if(params.drop.custom_drop_maps === undefined){
        params.drop.custom_drop_maps = [];
      }
      this.$store.state.mapsYamlEditor.custom_drop_maps.maps = params.drop.custom_drop_maps;
      this.$store.state.mapsYamlEditor.custom_drop_maps = yamlUtils.yamlMapsToVisualMaps(this.$store.state.mapsYamlEditor.custom_drop_maps);

      return this.$store.state.mapsYamlEditor.custom_drop_maps;
    },
    unitPickupMaps(){
      var params = this.yaml;
      if(params.pickup === undefined){
        params.pickup = {};
      }
      if(params.pickup.units_pickup_maps === undefined){
        params.pickup.units_pickup_maps = [];
      }

      this.$store.state.mapsYamlEditor.units_pickup_maps.maps = params.pickup.units_pickup_maps;
      this.$store.state.mapsYamlEditor.units_pickup_maps = yamlUtils.yamlMapsToVisualMaps(this.$store.state.mapsYamlEditor.units_pickup_maps);

      return this.$store.state.mapsYamlEditor.units_pickup_maps;
    },

    customerThresholdMaps(){
      var params = this.yaml;
      if(params.cap === undefined){
        params.cap = {};
      }
      if(params.cap.customer_bar_thresholds === undefined){
        params.cap.customer_bar_thresholds = [];
      }

        if(params.cap.customer_bar_thresholds.length  >0){
          let bars = params.cap.customer_bar_thresholds;
          
          for(var i=0; i<bars.length; i++){
            let bar = bars[i];

            //make a default one for display in case date range is undefined or empty
            var tmpDateRange = {
                                  'start_date': {'day': null, 'month': null, 'year': null}, 
                                  'end_date': {'day': null, 'month': null, 'year': null}
                                };

            if(bar.date_range !== undefined) { 
              let drange = bar.date_range; //assumes that if it gets here, format has been validated

              if(Array.isArray(drange) == true){
                if(drange.length > 0){
                  //converts date range from array to object
                  drange = drange[0]; //data range for this map is an array of with one date range
                  if(drange.length > 0){
                    let start_date = drange[0].split('-');
                    let end_date = drange[1].split('-');

                    //0 and 1 for month and day indexes respectively are not a mistake, date is in the format YYYY/MM/DD (with YYYY optional)
                    tmpDateRange = {
                                        'start_date': {'day': start_date[1], 'month': start_date[0], 'year': null}, 
                                        'end_date': {'day': end_date[1], 'month': end_date[0], 'year': null}
                                      };


                    if(start_date.length == 3 && end_date.length == 3){
                      tmpDateRange.start_date.year = start_date[0];
                      tmpDateRange.end_date.year = end_date[0];

                      tmpDateRange.start_date.day = start_date[2]
                      tmpDateRange.start_date.month = start_date[1]

                      tmpDateRange.end_date.day = end_date[2]
                      tmpDateRange.end_date.month = end_date[1]

                    }
                  }
                }
              }
              else{
                //occurs when changing tab without editing the yaml (--> date range is already an object)
                tmpDateRange = {
                                    'start_date': {'day': drange.start_date.day, 'month': drange.start_date.month, 'year': drange.start_date.year}, 
                                    'end_date': {'day': drange.end_date.day, 'month': drange.end_date.month, 'year': drange.end_date.year}
                                  };
              }
              
            }

            bar.date_range = tmpDateRange;
        }
      }

      this.$store.state.customerThresholdMapsEditor.customerThresholdMaps = params.cap.customer_bar_thresholds ;
      return this.$store.state.customerThresholdMapsEditor.customerThresholdMaps;
    }





  },
  methods: {

  },

  beforeMount () {
    //make default slots in params if missing
    var parameters = this.yaml;
    parameters = yamlUtils.instantiateMaps(parameters);

    var mapsArray = [parameters.pickup.pickup_maps, 
                      parameters.drop.far_out_premium_maps, 
                      parameters.dow.pickup_day_of_week_maps,
                      parameters.cap.customer_bar_thresholds];

    var hashsArray = [parameters.global.room_tag_map];

    var args = {'mapsArray': mapsArray, 'hashsArray': hashsArray, 'propertyTypesArray':  this.$store.state.core.propertyTagsList};
    this.$store.state.core.propertyTagsList = yamlUtils.includePropertyTypes(args);
 
  },
  mounted () {

  },
  unmounted(){
    //cleanup
    this.$store.state.mapsYamlEditor.pickup_maps = [];
    this.$store.state.mapsYamlEditor.pickup_day_of_week_maps = [];
    this.$store.state.mapsYamlEditor.far_out_premium_maps = [];
    this.$store.state.mapsYamlEditor.custom_drop_maps = [];
    this.$store.state.mapsYamlEditor.units_pickup_maps = [];


  }
}
</script>
<style>
.ps {
      height: 900px;
    }

</style>
