
export default {
    namespaced: true,

    state: {

        usersList: [],
        versionsList: [],

        selectedUserPaymentStatus: {},

        selectedUser: {},

        newGeneratedLink: "",


        fetchedUsers: [],

        rolesOptions: [
            {text: "Admin", value: "ADMIN",},
            {text: "Demo", value: "DEMO",},
        ]

    },

    mutations: {

        updateUserList(state, payload) {             
            state.usersList = payload.users
        },

        addUser(state, payload) {
            state.usersList.push(payload.value)
        },

        updateVersionsList(state, payload) {
            state.versionsList = payload.versions
        },

        updateSelectedUser(state, payload) {
            state.selectedUser = payload.user
        },

        updateSelectedUserPaymentStatus(state, payload) {
            state.selectedUserPaymentStatus = payload.data
        },

        resetSelectedUser(state, payload) {
            state.selectedUser = {}
        },

        setLink(state, payload){
            state.newGeneratedLink = payload.link
        },

        setFetchedUsers(state, payload) {
            state.fetchedUsers = payload.users
        },

        resetFetchedUsers(state) {
            state.fetchedUsers = []
        }
    },

    actions: {

        async getAllUsers(context){ // gets the users list inside the state
            const api = context.rootState.api.api

            const jobRes = await api.get('user/v1', '/users', {}, {}, {})
            
            if (jobRes.status === "found") {
                context.commit("updateUserList", {
                    users: jobRes.items.map(value => {
                        return {
                            id: value.metadata.id,
                            canGenerateToken: value.spec.can_generate_token,
                            username: value.spec.username,
                            versionID: value.spec.version_id,
                            activationToken: value.spec.activation_token,
                            role: value.spec.role,
                        }
                    })
                })
            }

        },
        
        async getUserByID(context, args) {
            const api = context.rootState.api.api

            const jobRes = await api.get("user/v1", `/users/${args.userID}`, {}, {}, {})
            
            if (jobRes.status === "found") {
                context.commit("updateSelectedUser", {
                    user: jobRes.items.map(value => {
                        return {
                            id: value.metadata.id,
                            canGenerateToken: value.spec.can_generate_token,
                            username: value.spec.username,
                            versionID: value.spec.version_id,
                            activationToken: value.spec.activation_token,
                            role: value.spec.role,
                        }
                    })[0]
                })
            }
        },


        async getPGsByUserID(context, args) {
            const api = context.rootState.api.api

            const jobRes = await api.get("user/v1", `/users/${args.userID}/property_group`, {}, {}, {})

            const PGsList = jobRes.items
            const PGsListIds = jobRes.items.map(value => value.spec.property_group_id)

            const obj = JSON.parse(JSON.stringify(context.state.selectedUser))

            context.commit("updateSelectedUser", {
                user: {
                    ...obj,
                    relatedPGsIds: PGsListIds,
                }
            })

        },

        async getPaymentStatusByUserID(context, args) {
            const api = context.rootState.api.api

            const jobRes = await api.get("billing/v1", `/user/${args.userID}/payment-status`, {}, {}, {})

            let data
            if ( jobRes.success == true ) {
                data = jobRes.data
            } else {
                data = null
            }

            for ( let bundle of Object.keys(data) ) {
                if ( data[bundle].status == 'paying') {
                    data[bundle]["color"] = 'green'
                    data[bundle]["label"] = 'Paying'
                } else if ( data[bundle].status == 'not_paying' ) {
                    data[bundle]["color"] = 'red'
                    data[bundle]["label"] = 'Not Paying'
                } else if ( data[bundle].status == 'not_active' ) {
                    data[bundle]["color"] = 'grey'
                    data[bundle]["label"] = 'Not Active'
                } else {
                    data[bundle]["color"] = 'grey'
                    data[bundle]["label"] = ''
                }
            }
            context.commit("updateSelectedUserPaymentStatus", {
                data
            })

        },


        async getPGbyID(context, args) {
            const api = context.rootState.api.api

            const jobRes = await api.get("user/v1", `/users/property_group/${args.pgID}`, {}, {}, {})

            
        },

        async sendQuestionnaireNotification(context, args) {
            const api = context.rootState.api.api

            let payload = {
                userID: args.userID,
                language: args.language,
                userEmail: args.userEmail,
                activationToken: args.activationToken,
                propertyGroupIds: args.propertyGroupIds
            }
            const jobRes = await api.put("user/v1", `/notification/questionnaire`, {}, payload, {})

            if ( jobRes.status == 200 ) {
                context.dispatch("alertSystem/newSuccess", {
                    message: jobRes.message,
                }, {root: true})
            } else {
                context.dispatch("alertSystem/newError", {
                    message: `Error while sending the email: ${jobRes.message}`,
                }, {root: true})
            }
        },

        async sendGoingLiveNotification(context, args) {
            const api = context.rootState.api.api

            let payload = {
                language: args.language,
                usersEmailAddresses: args.usersEmailAddresses,
                endDate: args.endDate,
                customerName: args.customerName,
                sender: args.sender,
                freeTrialDays: args.freeTrialDays,
                propertyGroupId: args.propertyGroupId
            }

            const jobRes = await api.put("user/v1", `/notification/goinglive`, {}, payload, {})

            if ( jobRes.status == 200 ) {
                context.dispatch("alertSystem/newSuccess", {
                    message: jobRes.message,
                }, {root: true})
            } else {
                context.dispatch("alertSystem/newError", {
                    message: `Error while sending the email: ${jobRes.message}`,
                }, {root: true})
            }
        },

        async assignPGsToUser(context, args) {
            const api = context.rootState.api.api

            const jobRes = await api.put("user/v1", "/users/property_group", {}, args.config, {})

        },


        async getVersions(context){
            const api = context.rootState.api.api

            const jobRes = await api.get("user/v1", "/version", {}, {}, {})

            if (jobRes.status === "found") {
                context.commit("updateVersionsList", {
                    versions: jobRes.items.map(value => {
                        return {
                            id: value.metadata.id,
                            description: value.spec.description,
                            label: `${value.metadata.id} - ${value.spec.description}`
                        }
                    })
                })
            }
            
        },

        async removeLinkedPGs(context, args) {
            const api = context.rootState.api.api

            try {
                const jobRes = await api.delete("user/v1", `/users/${args.userID}/property_group`, {}, {}, {})

                context.dispatch("alertSystem/newSuccess", {
                    message: "User disabled successfully",
                }, {root: true})
            } catch (error) {
                context.dispatch("alertSystem/newSuccess", {
                    message: `User disable failed: ${error}`,
                    timeout: -1,
                }, {root: true})
            }
        },

        async saveUser(context) {
            
            const api = context.rootState.api.api

            const user = context.state.selectedUser
            
            const jobRes = await api.put("user/v1", "/users", {}, {
                metadata: {
                    id: user.id
                },
                spec: {
                    username: user.username,
                    version_id: user.versionID,
                    role: user.role,
                }
            }, {})

            await context.dispatch("removeLinkedPGs", {userID: user.id})

            const reqObj = {
                items: user.relatedPGsIds.map(value => {
                    return {
                        metadata: {
                            id: user.id
                        },
                        spec: {
                            property_group_id: value,
                            user_id: Number(jobRes.items[0].metadata.id),
                        }
                    }
                })
            }


            if (reqObj.items.length) {
                context.dispatch("assignPGsToUser", {config: reqObj})
            }

            if(jobRes.status === 200){
                context.dispatch("alertSystem/newSuccess", {
                    message: `User edited successfully`, 
                }, {root: true})
            } else {
                context.dispatch("alertSystem/newError", {
                    message: `Something went wrong: ${error}`, 
                }, {root: true})
            }
        },

        async fetchUserByPGID(context, args) {

            const api = context.rootState.api.api

            const jobRes = await api.get("user/v1", `/users/property_group/${args.pgid}`, {}, {}, {})


            const users = jobRes.items.map(user => {
                return {
                    text: user.spec.username,
                    value: String(user.spec.user_id),
                }
            })

            context.commit("setFetchedUsers", {users: users})
        },

        async generateNewToken(context, args) {
            const api = context.rootState.api.api

            const jobRes = await api.post("user/v1", "/generatetoken", {} , args.user, {})

            if (!args.user.spec.sendEmail) {
                context.commit("setLink", {
                    link: `https://admin.smartpricing.it/auth/activate?activation_token=${jobRes.message.token}`
                })
            }

        },
        
        async insertUser(context, args) {
            const api = context.rootState.api.api

            try {
                const jobRes = await api.put("user/v1", "/users", {}, args.user, {})
                
                if (jobRes.status !== 200) throw jobRes.error
                

                const reqObj = {
                    items: args.linkedPGs.map(value => {
                        return {
                            metadata: {},
                            spec: {
                                property_group_id: value,
                                user_id: Number(jobRes.items[0].metadata.id),
                            }
                        }
                    })
                }
    
                if (jobRes.items.length) {
                    context.dispatch("assignPGsToUser", {config: reqObj})
                }
    
                if (args.generateToken){
                    context.dispatch("generateNewToken", {user: {
                        metadata: {
                            id: jobRes.items[0].metadata.id
                        },
                        spec: {
                            sendEmail: true
                        }
                    }})
                }

                context.commit("addUser", {value: {
                    id: jobRes.items[0].metadata.id,
                    canGenerateToken: jobRes.items[0].spec.can_generate_token,
                    username: jobRes.items[0].spec.username,
                    versionID: jobRes.items[0].spec.version_id,
                    activationToken: jobRes.items[0].spec.activation_token,
                    role: jobRes.items[0].spec.role
                }})

                context.dispatch("alertSystem/newSuccess", {
                    message: `User ${args.user.spec.username} added successfully`, 
                }, {root: true})
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `User insert failed with error: ${error}`, 
                }, {root: true})
            }    
        }

       
    }   
}