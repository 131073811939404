<template>
    <v-card class="ml-3 mt-3 mb-6">
        <h3 class="mt-4 pt-3 ml-2">External Market Correction</h3>

        <v-row class="mt-1 ml-2">
            <v-col class="col-12">
                <v-slider
                    :label="String(weight)"
                    :max="1"
                    :min="0"
                    :step="0.1"
                    :disabled="!editable || !enabled"
                    discrete
                    v-model="weight"
                    hide-details
                    @input="changeYamlEditor"
                ></v-slider>
            </v-col>
        </v-row>

    </v-card>
</template>

<script>
import { mapMutations, mapGetters, mapState} from "vuex"

export default {
    props: ["editable"],
    data() {
        return {
            weight: 0,
        }
    },
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG,
        }),
        ...mapGetters({
            codeToRenderObj: "editor/codeToRenderObj",
        }),
        enabled() {
            return !!this.codeToRenderObj?.compset_correction?.enable && !!this.currentPG.spec.booking_id
        }
    },
    methods: {
        ...mapMutations({
            writeCodeToRender: "editor/writeCodeToRender",
        }),
        initializeComponent() {
            if (!!this.codeToRenderObj?.compset_correction) {
                this.weight = this.codeToRenderObj?.compset_correction?.weight
            }
        },
        changeYamlEditor() {
            const editorCode = structuredClone(this.codeToRenderObj)

            editorCode.compset_correction = {
                enable: this.enabled,
                weight: this.weight,
            }

            this.writeCodeToRender(editorCode)
        },
    },
    created() {
        this.initializeComponent()
    }
}
</script>