<template>
  <v-card class="card-ui pa-4">
      <v-card-title>Edit Rate</v-card-title>

      <v-row class="pa-4">
          <v-col class="col-6">
              <v-row>
                  <v-col class="col-12">
                      <v-autocomplete
                          outlined
                          dense
                          label="Select Rate"
                          :items="currentRates"
                          :disabled="!ratesAndPropRatesFetched"
                          v-model="selectedRate"
                          @change="loadExistingRate()"
                      ></v-autocomplete>
                  </v-col>
                  <v-col class="col-12">
                      <v-autocomplete
                          v-if="selectedPgCmMappingSupported"
                          outlined
                          dense
                          :items="automappingRatesList"
                          item-text="text"
                          item-value="value"
                          label="External ID"
                          v-model="selectedExternalID"
                          :return-object="false"
                          :disabled="!selectedRate"
                      >
                          <template v-slot:selection="{ item }">
                              {{ item?.text }}
                              <v-chip
                                  color="error"
                                  v-if="!item.isInChannelManager"
                                  small
                                  >Not In CM</v-chip
                              >
                              <v-chip
                                  v-else-if="!item.isInDatabase"
                                  color="error"
                                  small
                                  >Not in DB</v-chip
                              >
                          </template>
                          <template v-slot:item="{ item }">
                              {{ item?.text }}
                              <v-chip
                                  class="ml-2"
                                  color="error"
                                  v-if="!item.isInChannelManager"
                                  small
                                  >Not In CM</v-chip
                              >
                              <v-chip
                                  class="ml-2"
                                  v-else-if="!item.isInDatabase"
                                  color="error"
                                  small
                                  >Not in DB</v-chip
                              >
                          </template>
                      </v-autocomplete>
                      <v-text-field
                          outlined
                          v-else
                          dense
                          label="External ID"
                          persistent-hint
                          hint="The selected pg CM doesn't support automapping so you must write the External ID manually"
                          v-model="selectedExternalID"
                          :disabled="!selectedRate"
                      ></v-text-field>
                  </v-col>
                  <v-col class="col-12">
                      <v-text-field
                          outlined
                          dense
                          label="Rate Name"
                          :disabled="!selectedRate"
                          v-model="selectedName"
                      ></v-text-field>
                  </v-col>
              </v-row>
          </v-col>
          <v-col class="col-6">
              <v-row>
                  <v-col class="col-12">
                      <v-autocomplete
                          outlined
                          dense
                          multiple
                          chips
                          deletable-chips
                          :disabled="!selectedRate"
                          :items="currentPropertiesDropdown"
                          label="Select Property"
                          v-model="selectedProperties"
                          @change="changeSelectedProperties()"
                      ></v-autocomplete>
                  </v-col>
              </v-row>
          </v-col>
      </v-row>
      <v-row class="pa-4">
          <v-col
              class="col-12"
              v-for="(
                  propertyRateWithModifier, index
              ) of selectedProperyRatesWithModifiers"
              :key="propertyRateWithModifier.propertyRate.metadata.id"
          >
              <PropertyRateForm
                  v-model="selectedProperyRatesWithModifiers[index]"
                  :index="index"
                  @copyVarParams="copyVarParams(index)"
              ></PropertyRateForm>
          </v-col>
      </v-row>

      <v-card-actions class="pa-4">
          <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="confirmDialog"
          >
              <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="primary"
                      >Update Rate</v-btn
                  >
              </template>
              <template v-slot:default="dialog">
                  <v-card :loading="loading">
                      <v-toolbar color="primary" dark
                          >Confirm Rate Update</v-toolbar
                      >
                      <v-card-text>
                          <div class="text-h4 pa-12">
                              Are you sure you want to submit these changes?
                          </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                          <v-btn @click="submitUpdatedRate()" color="primary"
                              >Update Rate</v-btn
                          >
                          <v-btn text @click="dialog.value = false"
                              >Close</v-btn
                          >
                      </v-card-actions>
                  </v-card>
              </template>
          </v-dialog>
      </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import PropertyRateForm from "./PropertyRateForm.vue";

export default {
  components: { PropertyRateForm },
  data() {
      return {
          // main data from api
          selectedRate: "",
          selectedProperyRatesWithModifiers: [],
          propertyRatesToDelete: [],

          // local data
          selectedName: "",
          selectedExternalID: "",
          selectedProperties: [],

          // generic
          loading: false,
          confirmDialog: false,
      };
  },
  computed: {
      ...mapState({
          currentPG: (state) => state.core.currentPG,
      }),
      ...mapGetters({
          ratesAndPropRatesFetched: "rates/ratesAndPropRatesFetched",
          ratesByPropertyGroupID: "rates/ratesByPropertyGroupID",
          propertyRatesByRateID: "rates/propertyRatesByRateID",
          propertyRateModifierByPropRateID:
              "rates/propertyRateModifierByPropRateID",
          currentPropertiesDropdown: "core/currentPropertiesDropdown",
          selectedPgCmMappingSupported: "rates/selectedPgCmMappingSupported",
          automappingRatesList: "rates/automappingRatesList",
      }),
      currentRates() {
          return this.ratesByPropertyGroupID[this.currentPG.metadata.id] !==
              undefined
              ? this.ratesByPropertyGroupID[this.currentPG.metadata.id].map(
                    (rate) => {
                        return {
                            text: `${rate.metadata.id} - ${rate.spec.property_group_id} - ${rate.spec.name}`,
                            value: rate,
                        };
                    }
                )
              : [];
      },
      finalObjToSubmit() {
          const rate = structuredClone(this.selectedRate);

          rate.spec.name = this.selectedName || rate.spec.name;
          rate.spec.external_id =
              this.selectedExternalID || rate.spec.external_id;

          return {
              rate: rate,
              propertyRates: this.selectedProperyRatesWithModifiers,
              propertyRatesToDelete: this.propertyRatesToDelete.length
                  ? this.propertyRatesToDelete
                  : null,
          };
      },
  },
  watch: {
      currentPG(newVal, oldVal) {
          this.reset();
      },
  },
  methods: {
      ...mapActions({
          updateRate: "rates/updateRate",
      }),
      reset() {
          this.selectedProperyRatesWithModifiers = [];
          this.propertyRatesToDelete = [];
          this.selectedName = "";
          this.selectedExternalID = "";
          this.selectedProperties = [];
      },
      loadExistingRate() {
          this.reset();

          if (
              this.propertyRatesByRateID[this.selectedRate.metadata.id] !==
              undefined
          ) {
              this.selectedProperyRatesWithModifiers =
                  this.propertyRatesByRateID[
                      this.selectedRate.metadata.id
                  ].map((rate) => {
                      const propRate = rate || {
                          metadata: {},
                          spec: {
                              property_id: difference,
                              rate_id: this.selectedRate.metadata.id,
                              is_set_prices_enabled: true,
                              property_external_id: null,
                              extra_params: {},
                              graph_json: ["IndexedPrice"],
                          },
                      };

                      const propRateMod = this
                          .propertyRateModifierByPropRateID[
                          rate.metadata.id
                      ] || {
                          metadata: {},
                          spec: {
                              property_rate_id:
                                  propRate?.metadata?.id || null,
                              modifier_id: 40,
                              variation_params: {
                                  variations: {
                                      "*": {
                                          variation_type: "amount",
                                          variation_value: 0,
                                          source_property_id: null,
                                      },
                                  },
                              },
                          },
                      };

                      return {
                          propertyRate: propRate,
                          propertyRateModifier: propRateMod,
                      };
                  });
              this.selectedProperties =
                  this.selectedProperyRatesWithModifiers.map(
                      (val) => val.propertyRate.spec.property_id
                  );
          }

          this.selectedRate = this.ratesByPropertyGroupID[
              this.currentPG.metadata.id
          ].find((val) => val.metadata.id === this.selectedRate.metadata.id);
          this.selectedName = this.selectedRate.spec.name;
          this.selectedExternalID = this.selectedRate.spec.external_id;
      },
      changeSelectedProperties() {
          const propertyRatesPresent =
              this.selectedProperyRatesWithModifiers.map(
                  (val) => val.propertyRate.spec.property_id
              );
          let difference = propertyRatesPresent
              .filter((x) => !this.selectedProperties.includes(x))
              .concat(
                  this.selectedProperties.filter(
                      (x) => !propertyRatesPresent.includes(x)
                  )
              )[0];

          if (propertyRatesPresent.includes(difference)) {
              // property rate already present so remove it

              const index = this.selectedProperyRatesWithModifiers.findIndex(
                  (val) => val.propertyRate.spec.property_id === difference
              );

              if (index !== -1) {
                  let deletedArr =
                      this.selectedProperyRatesWithModifiers.splice(index, 1);
                  this.propertyRatesToDelete.push(deletedArr[0]);
              }
          } else {
              // property rate not present so add it
              this.selectedProperyRatesWithModifiers.push({
                  propertyRate: {
                      metadata: {},
                      spec: {
                          property_id: difference,
                          rate_id: this.selectedRate.metadata.id,
                          is_set_prices_enabled: true,
                          property_external_id: null,
                          extra_params: {},
                          graph_json: ["IndexedPrice"],
                      },
                  },
                  propertyRateModifier: {
                      metadata: {},
                      spec: {
                          property_rate_id: null,
                          modifier_id: 40,
                          variation_params: {
                              variations: {
                                  "*": {
                                      variation_type: "amount",
                                      variation_value: 0,
                                      source_property_id: null,
                                  },
                              },
                          },
                      },
                  },
              });
          }
      },
      copyVarParams(index) {
          const extraParamsToCopy = structuredClone(
              this.selectedProperyRatesWithModifiers[index].propertyRate.spec
                  .extra_params
          );
          const paramsToCopy = structuredClone(
              this.selectedProperyRatesWithModifiers[index]
                  .propertyRateModifier.spec.variation_params.variations["*"]
          );

          for (let propRateWithMod of this
              .selectedProperyRatesWithModifiers) {
              // copy extra params
              propRateWithMod.propertyRate.spec.extra_params =
                  extraParamsToCopy;

              propRateWithMod.propertyRateModifier.spec.variation_params.variations[
                  "*"
              ].variation_type = paramsToCopy.variation_type;
              propRateWithMod.propertyRateModifier.spec.variation_params.variations[
                  "*"
              ].variation_value = paramsToCopy.variation_value;
          }
      },
      async submitUpdatedRate() {
          this.loading = true;
          await this.updateRate(this.finalObjToSubmit);
          await this.loadExistingRate();
          this.loading = false;
          this.confirmDialog = false;
      },
  },
  destroyed() {
      this.reset();
  },
};
</script>
