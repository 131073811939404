import * as echarts from "echarts"
import dateUtils from "./dateUtils"

export class ChartUtils {

    // function to group dataframes based on a single data frame values
    static valuesByKey = (dataFrames, discriminatingKey) => {
        if (!!dataFrames?.property_id_named) {
            dataFrames["pid_name"] = dataFrames?.property_id_named.map(val => {
                return String(val).replaceAll("_", " - ")
            })
        }
    
        const valuesByKey = {};
        const allKeys = Object.keys(dataFrames);
    
        dataFrames[discriminatingKey]?.forEach((discriminatingVal, index) => {
            if (!valuesByKey[discriminatingVal]) {
                valuesByKey[discriminatingVal] = {};
            }
        
            allKeys.forEach(key => {
                if (!valuesByKey[discriminatingVal][key]) {
                    valuesByKey[discriminatingVal][key] = [];
                }
            
                valuesByKey[discriminatingVal][key].push(dataFrames[key][index]);
            });
        });
    
        return valuesByKey;
    }

    static createNormalLineChart = (dataframesArr, xCol, yCols, xTitle, yTitle) => {
        if (!dataframesArr) return null

        const seriesToShow = []
        const legendToShow = []
        dataframesArr.forEach(propertyDataframe => {
            yCols.forEach(col => {
                const colName = `${propertyDataframe.property_id} - ${col}`

                seriesToShow.push({
                    data: propertyDataframe[col],
                    type: "line",
                    showSymbol: false,
                    name: colName,
                })

                legendToShow.push(colName)
            })
        })
    
        return {
            legend: {
                data: legendToShow,
                type: 'scroll',
                orient: 'vertical',
                top: 30,
                right: 0,
                bottom: 20,
                selector: [
                    {type: "all", title: "All"},
                    {type: "inverse", title: "Reverse"}
                ]
            },
            grid: {
                right: 350,
                left: 100,
                bottom: 100,
                top: 100,
            },
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%'];
                }
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    restore: {},
                    saveAsImage: {}
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                },
                {
                    start: 0,
                    end: 100
                }
            ],
            xAxis: {
                data: dataframesArr?.[0]?.[xCol],
                name: xTitle || "",
            },
            yAxis: {
                name: yTitle || '',
                type: 'value',
                alignTicks: true,
            },
            series: seriesToShow
        }
    }

    /* Function to create the impact charts v2 */
    static createImpactChart = (dataframesArr, xCol, y1Cols, y2Cols, xTitle, y1Title, y2Title) => {
        if (!dataframesArr) return null

        const seriesToShow = []
        const legendToShow = []
        dataframesArr.forEach(propertyDataframe => {
            y1Cols.forEach(col => {
                const colName = `${propertyDataframe.property_id} - ${col}`

                seriesToShow.push({
                    data: propertyDataframe[col],
                    type: "line",
                    yAxisIndex: 0,
                    showSymbol: false,
                    name: colName,
                })

                legendToShow.push(colName)
            })

            y2Cols.forEach(col => {
                const colName = `${propertyDataframe.property_id} - ${col}`

                seriesToShow.push({
                    data: propertyDataframe[col],
                    type: "line",
                    yAxisIndex: 1,
                    showSymbol: false,
                    name: colName,
                    lineStyle: {
                        normal: {
                            type: 'dashed'
                        }
                    }
                })

                legendToShow.push(colName)
            })
        })
    
        return {
            legend: {
                data: legendToShow,
                type: 'scroll',
                orient: 'vertical',
                right: 0,
                top: 30,
                bottom: 20,
                selector: [
                    {type: "all", title: "All"},
                    {type: "inverse", title: "Reverse"}
                ]
            },
            grid: {
                right: 350,
                left: 100,
                bottom: 100,
                top: 100,
            },
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%'];
                }
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    restore: {},
                    saveAsImage: {}
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                },
                {
                    start: 0,
                    end: 100
                }
            ],
            xAxis: {
                data: dataframesArr?.[0]?.[xCol],
                name: xTitle || "",
            },
            yAxis: [
                {
                    name: y1Title || '',
                    type: 'value',
                    alignTicks: true,
                },
                {
                    name: y2Title || '',
                    type: 'value',
                    alignTicks: true,
                }
            ],
            series: seriesToShow,
        }
    }

    /* Function to create the heatmap v2 */
    static createHeatmapChart = (dataframesObj, xCol, yCol, zCol, xTitle, yTitle, chartTitle) => {
        if (!dataframesObj) return null

        const xValues = [];
        const yValues = [];
        const zValues = [];

        dataframesObj.forEach(valuesObj => {
            const xVal = valuesObj[xCol]
            const yVal = valuesObj[yCol]
            const zVal = valuesObj[zCol]

            xValues.push(Array.isArray(xVal) ? xVal.join(" to ") : xVal)
            yValues.push(Array.isArray(yVal) ? yVal.join(" to ") : yVal)
            zValues.push(Array.isArray(zVal) ? zVal.join(" to ") : zVal)
        })
        
        const data = xValues.map((x, index) => {
            return [
                x,
                yValues[index],
                zValues[index],
            ]
        })
        
        return {
            title: {
                top: 10,
                left: 'center',
                text: chartTitle || ""
            },
            tooltip: {
                position: 'top'
            },
            grid: {
                height: '50%',
                top: '10%'
            },
            xAxis: {
                name: xTitle || '',
                type: 'category',
                data: Array.from(new Set(xValues)),
                splitArea: {
                    show: true
                }
            },
            yAxis: {
                name: yTitle || '',
                type: 'category',
                data: Array.from(new Set(yValues)),
                splitArea: {
                    show: true
                }
            },
            visualMap: {
                min: -0.5,
                max: 0.5,
                calculable: true,
                orient: 'horizontal',
                left: 'center',
                bottom: '15%'
            },
            series: [
                {
                    type: 'heatmap',
                    data: data,
                    label: {
                        show: true,
                        formatter: function(p) {
                            return `${Number(p.value[2]*100).toFixed(0)} %`
                        }
                    },
                }
            ]
        }
    }

    /* Function to create the calendar heatmap v2 */
    static createCalendarHeatmap = (dataframesObj, dateCol, valueCol, chartTitle, legendPieces) => {
        if (!dataframesObj) return null

        const data = dataframesObj.map((values, index) => {
            const time = echarts.time.parse(values[dateCol])

            return [
                echarts.time.format(time, '{yyyy}-{MM}-{dd}', false),
                values[valueCol]
            ]
        })

        const allDates = dataframesObj.map(values => values[dateCol])

        return {
            title: {
                top: 10,
                left: 'center',
                text: chartTitle || ""
            },
            tooltip: {
                position: 'top',
                formatter: function (p) {
                    const format = echarts.time.format(p.data[0], '{yyyy}-{MM}-{dd}', false);
                    return format + ': ' + p.data[1];
                }
            },
            visualMap: {
                min: 0,
                max: 2,
                calculable: true,
                type: "piecewise",
                orient: 'horizontal',
                left: 'center',
                bottom: '15%',
                pieces: legendPieces,
            },
            calendar: {
                top: 120,
                left: 30,
                right: 30,
                cellSize: ['auto', 13],
                range: [
                    dateUtils.smallestDate(allDates).toISOString().split("T")[0],
                    dateUtils.largestDate(allDates).toISOString().split("T")[0],
                ]
            },
            series: {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                data: data
            }
        };
    }

    /* To remove as soon as possible */
    static createLineChartLayout = ( // TO SIMPLIFY
        y1Title = "", 
        y2Title = null, 
        xTitle = "", 
        darkMode = true, 
        legendOrientation = "h", 
        width = null, 
        height = null
    ) => {
        const baseLayoutObj = {
            xaxis: {
                title: xTitle || "",
            },
            yaxis: {
                title: y1Title  || "",
                showgrid: true,
                gridcolor:'rgba(165, 161, 161, 0.27)',
            },
            paper_bgcolor: darkMode ? '#221f1c' : '#fff',
            plot_bgcolor: darkMode ? '#373432': '#fff',
            font:{
                color: darkMode ? '#Ffffff' : '#333',
            },
            legend: {
                orientation: legendOrientation || "h",
            }
        }

        if (width) baseLayoutObj.width = width
        if (height) baseLayoutObj.height = height

        if (y2Title) {
            baseLayoutObj["yaxis2"] = {
                title: y2Title  || "",
                overlaying: 'y',
                side: 'right',
                showgrid: true,
                gridcolor:'rgba(165, 161, 161, 0.27)',
            }
        }

        return baseLayoutObj
    }

}