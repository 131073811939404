<template >
  <v-row>
    <v-col>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field label="Property Group ID" type="text" v-model="psIds" required :rules="pgIdRules"></v-text-field>
        <v-file-input accept="image/jpg, image/jpeg" label="Logo upload, required format JPG" v-model="chosenFile"
          :rules="chosenFileRule" @change="onFileInput" required></v-file-input>
        <div class="upload__cropper-wrapper">
          <Cropper ref="cropper" class="upload__cropper" check-orientation :src="image.src" image-restriction="none"
            :stencil-props="{
                aspectRatio: 16 / 9
              }" />
          <div class="upload__file-type" v-if="image.type">
            {{ image.type }}
          </div>
        </div>
      </v-form>
      <div class="d-flex justify-end mt-2">
        <v-btn color="primary" @click="nextStep">
          Continue
        </v-btn>
        <v-btn color="secondary" class="ml-2" @click="reset">
          Reset
        </v-btn>
      </div>
      <ConfirmDialog ref="dialog" />
    </v-col>
  </v-row>
</template>

<script>
import ConfirmDialog from '@/components/utils/ConfirmDialog'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
export default {
  name: 'ImageCroppingStep',
  components: { Cropper, ConfirmDialog },
  props: {
    canSkip: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      valid: true,
      chosenFile: null,
      chosenFileRule: [
        v => !!v || 'Logo is required',
      ],
      pgIdRules: [
        v => !!v || 'Pg id is required'
      ],
      image: {
        src: null,
        type: null
      },
      psIds: null,
    };
  },
  methods: {
    async nextStep() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        this.psIds = this.psIds.split(',')
        const file = await this.cropImage()
        this.$emit('next', file, this.psIds)
      }
      else if (this.canSkip) {
        const options = { cardTextClass: "pa-4 orange accent-1--text", color: 'orange' };
        const title = "Proceed without image upload?";
        const message = "You didn't upload any image for the specified property group, if you are only updating a config this is fine, otherwise please upload the accommodation logo"
        await this.$refs.dialog.open(title, message, options)
        this.$emit('next', null, null)
      }
    },
    getCanvasBlob(canvas) {
      return new Promise(function (resolve, reject) {
        var newCanvas = canvas.cloneNode(true);
        var ctx = newCanvas.getContext('2d');
        ctx.fillStyle = "#FFF";
        ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
        ctx.drawImage(canvas, 0, 0);
        newCanvas.toBlob(function (blob) {
          resolve(blob)
        }, 'image/jpeg')
      })
    },
    async cropImage() {
      const { canvas } = this.$refs.cropper.getResult();
      const blob = await this.getCanvasBlob(canvas)
      const file = new File([blob], `${this.pgId}.jpg`)
      return file
    },
    reset() {
      this.image = {
        src: null,
        type: null
      }
    },
    onFileInput(file) {
      if (file) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(file);

        this.image = {
          src: blob,
          type: file.type
        }
      }
    }
  }




}
</script>
