<template>
    <v-container fluid class="pa-4">
        <v-card class="card-ui" v-if="!!globalSelectedPgId">
            <v-toolbar dense elevation="0" :class="{ 'grey darken-3': darkMode }">
                <v-card-title class="overline ml-0 pl-0">
                    <v-icon small class="mr-2">fa-solid fa-circle-info</v-icon>
                    <b class="grey--text darken-6">Property group subscriptions</b>
                </v-card-title>
                <v-spacer />
            </v-toolbar>
            <v-card-text>
                <div class="pb-4">
                    <v-btn @click="deleteReservationsSubscription()" class="mr-4" color="primary" elevation="1"
                        :disabled="hotelsSelected.length <= 0">
                        Delete reservations subscription
                    </v-btn>
                    <v-btn @click="deleteAllReservationsSubscription()" color="error" elevation="1">
                        Delete all reservations subscription
                    </v-btn>
                </div>
                <v-data-table v-model="hotelsSelected" :items="currentPgHotels" :headers="hotelsTableHeaders"
                    show-select></v-data-table>
            </v-card-text>
        </v-card>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import SelectPgAlert from '@/components/utils/SelectPgAlert'

export default {
    components: { SelectPgAlert },
    data() {
        return {
            hotelsSelected: [],
            hotelsTableHeaders: [
                { text: "Hotel ID", value: "hotel_id" },
                { text: "Hotel name", value: "name" },
                { text: "Timezone", value: "timezone" },
            ]
        }
    },
    computed: {
        ...mapState({
            darkMode: state => state.ui.darkMode,
            currentPgHotels: state => state.subscriptions.currentPgHotels
        }),
        ...mapGetters({
            globalSelectedPgId: "core/globalSelectedPgId",
            currentPms: "core/currentPms",
        }),
    },
    methods: {
        async deleteReservationsSubscription() {
            this.hotelsSelected.map(async (hotel) => {
                await this.$store.dispatch("subscriptions/deleteReservationsSubscription", { pmsName: this.currentPms.metadata.id, pgId: this.globalSelectedPgId, hotelId: hotel.hotel_id })
            })
        },
        async deleteAllReservationsSubscription() {
            await this.$store.dispatch("subscriptions/deleteAllReservationsSubscription", { pmsName: this.currentPms.metadata.id, pgId: this.globalSelectedPgId })
        },
    }
}
</script>
