<template>
    <v-card class="pa-8 py-0">
        <v-card-title>{{ `${propertyRate.spec.property_id} - ${allPropertyNamesById[propertyRate.spec.property_id]}` }}</v-card-title>
        <v-row>
            <v-col class="col-6">
                <v-row>
                    <v-col class="col-12">
                        <v-autocomplete
                            outlined
                            dense
                            v-if="!!automappingPropertyRateExternalIdList?.length"
                            :value="propertyRate.spec.property_external_id"
                            label="Property Rate External ID"
                            :items="automappingPropertyRateExternalIdList"
                            @change="[valueCopy.propertyRate.spec.property_external_id = $event, $emit('input', valueCopy)]"
                        ></v-autocomplete>
                        <v-text-field
                            outlined
                            dense
                            v-else
                            :value="propertyRate.spec.property_external_id"
                            label="Property Rate External ID"
                            @input="[valueCopy.propertyRate.spec.property_external_id = $event, $emit('input', valueCopy)]"
                        ></v-text-field>
                    </v-col>
                    <v-col class="col-6">
                        <v-textarea
                            outlined
                            dense
                            :rules="[rules.isJSON]"
                            label="Extra Params"
                            :value="JSON.stringify(propertyRate.spec.extra_params)"
                            @input="[valueCopy.propertyRate.spec.extra_params = JSON.parse($event), $emit('input', valueCopy)]"
                        ></v-textarea>
                    </v-col>
                    <v-col class="col-6">
                        <NewDynamicInputForm
                            :value="JSON.stringify(propertyRate.spec.extra_params)"
                            @input="[valueCopy.propertyRate.spec.extra_params = JSON.parse($event), $emit('input', valueCopy)]"
                            :customSchema="!!cmPropertyExtraParamsSchema ? cmPropertyExtraParamsSchema : undefined"
                        ></NewDynamicInputForm>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="col-6">
                <v-row>
                    <v-col class="col-6">
                        <v-autocomplete
                            outlined
                            dense
                            label="Variation type"
                            :items="variationTypes"
                            :value="propertyRateModifier.spec.variation_params.variations['*'].variation_type"
                            @change="[valueCopy.propertyRateModifier.spec.variation_params.variations['*'].variation_type = $event, 
                            valueCopy.propertyRateModifier.spec.variation_params.variations['*'].variation_value = 0,
                            $emit('input', valueCopy)]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-6">
                        <v-autocomplete
                            outlined
                            dense
                            label="Source Property ID"
                            :items="currentPropertiesDropdown"
                            :value="String(propertyRateModifier.spec.variation_params.variations['*'].source_property_id)"
                            @change="[valueCopy.propertyRateModifier.spec.variation_params.variations['*'].source_property_id = $event, $emit('input', valueCopy)]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col class="col-12">
                        <v-text-field
                            outlined
                            dense
                            label="Variation value"
                            type="number"
                            :suffix="valueSuffix"
                            :value="rawValueToNormal(propertyRateModifier.spec.variation_params.variations['*'].variation_value)"
                            @input="[valueCopy.propertyRateModifier.spec.variation_params.variations['*'].variation_value = normalValueToRaw($event), $emit('input', valueCopy)]"
                        ></v-text-field>
                    </v-col>
                    <v-switch
                        label="Is set Prices Enabled"
                        dense
                        :value="!!propertyRate.spec.is_set_prices_enabled"
                        v-model="valueCopy.propertyRate.spec.is_set_prices_enabled"
                        @change="[valueCopy.propertyRate.spec.is_set_prices_enabled = $event ,$emit('input', valueCopy)]"
                        outlined
                        :false-value="false"
                        :true-value="true"
                        class="mt-n4 pl-3"
                    ></v-switch>
                    <v-col class="col-7" v-show="index === 0">
                        <v-btn
                            block
                            dense
                            color="success"
                            @click="$emit('copyVarParams')"
                        >Copy Variations to all rows</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import NewDynamicInputForm from '../../../components/newDynamicForm/NewDynamicInputForm.vue';
import Decimal from 'decimal.js';


export default {
    name: "property-rate-form",
    props: ["value", "index", /* "propertyRate", "propertyRateModifier" */],
    components: {NewDynamicInputForm},
    data() {
        return {
            valueCopy: null,
            
            extraParamsTemp: "",
            varValueTemp: "",

            variationTypes: [
                {text: "Amount", value: "amount"},
                {text: "Percentage", value: "percentage"},
                {text: "Overwrite", value: "overwrite"},
            ],

            rules: {
                isJSON: val => this.isValidJSON(val) || "Value must be valid JSON!"
            },
        }
    },
    computed: {
        ...mapState({
            currentCmId: state => state.core.currentPG.spec.channel_manager_name,
        }),
        ...mapGetters({
            currentPropertiesDropdown: "core/currentPropertiesDropdown",
            currentCurrency: "core/currentCurrency",
            allPropertyNamesById: "core/allPropertyNamesById",
            cmPropertyExtraParamsSchema: "core/cmPropertyExtraParamsSchema",
            automappingPropertyRateExternalIdList: "rates/automappingPropertyRateExternalIdList"
        }),
        propertyRate() {
            return this.value.propertyRate
        },
        propertyRateModifier() {
            return this.value.propertyRateModifier
        },
        valueSuffix() {
            const type = this.propertyRateModifier.spec.variation_params.variations['*'].variation_type
            const value = this.propertyRateModifier.spec.variation_params.variations['*'].variation_value
            const currencyVal = this.currentCurrency?.spec?.code || "(currency missing)"
            switch (type) {
                case "percentage":
                    return `= ${value < 1 ? "" : "+"}${Number((value)*100).toFixed(2)}%`
                case "amount":
                    return `= ${value < 0 ? "" : "+"}${Number(value)} ${currencyVal}`
                case "overwrite":
                    return `= ${Number(value)} ${currencyVal}`
            }
        },
    },
    watch: {
        value: {
            handler: function (newVal, oldVal) {
                this.valueCopy = structuredClone(this.value)
            },
            deep: true,
        }
    },
    methods: {
        rawValueToNormal(rawValue) {
            const type = this.propertyRateModifier.spec.variation_params.variations['*'].variation_type
            switch (type) {
                case "percentage":
                    let aux = new Decimal(rawValue)
                    let toReturn = aux.times(100).toString()
                    return `${toReturn}`
                case "amount":
                case "overwrite":
                    return rawValue
            }
        },
        normalValueToRaw(normalValue) {
            let value = Number(normalValue)
            const type = this.propertyRateModifier.spec.variation_params.variations['*'].variation_type
            switch (type) {
                case "percentage":
                    let aux = new Decimal(value)
                    let toReturn = aux.dividedBy(100).toString()
                    return parseFloat(toReturn)
                case "amount":
                case "overwrite":
                    return Number(value)
            }
        },
        isValidJSON(json) {
            try {
                JSON.parse(json)
                return true
            } catch (e) {
                return false
            }
        }
    },
    created() {
        this.valueCopy = structuredClone(this.value)

        this.valueCopy.propertyRateModifier.spec.variation_params.variations['*'].source_property_id = this.propertyRateModifier.spec.variation_params.variations['*']?.source_property_id || this.propertyRate.spec.property_id
        this.$emit('input', this.valueCopy)
    }
}
</script>
