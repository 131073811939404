<template>
    <v-card>
        <v-toolbar color="primary" dark>
            Base Price Editor
            <v-btn text class="ml-auto" @click="$emit('closeDialog')">
                Close
            </v-btn>
        </v-toolbar>

        <v-card-text>
            <v-row>
                <v-col class="col-12 col-md-6 py-8">
                    <v-card class="card-ui" :loading="loading">
                        <v-card-title>Configuration of Base Price</v-card-title>

                        <v-card-text>
                            <v-tabs
                                v-model="tab"
                                background-color="transparent"
                                grow
                            >
                                <v-tab v-for="item in items" :key="item">
                                    {{ item }}
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item key="Generate">
                                    <v-card>
                                        <v-card-actions class="px-4">
                                            <v-select
                                                outlined
                                                dense
                                                label="Select the generation source"
                                                :items="generationSourceList"
                                                item-text="text"
                                                item-value="value"
                                                v-model="
                                                    selectedGenerationSource
                                                "
                                                class="mt-8"
                                            ></v-select>
                                            <v-btn
                                                @click="autogeneration()"
                                                icon
                                                class="mx-2"
                                                :loading="loadingAutogeneration"
                                            >
                                                <v-icon
                                                    >fa-solid
                                                    fa-wand-magic-sparkles</v-icon
                                                >
                                            </v-btn>
                                        </v-card-actions>

                                        <!-- Trendline generation form -->
                                        <BasePriceTrendlineGenerator
                                            v-if="
                                                selectedGenerationSource ===
                                                    'trendline' &&
                                                selectedGenerationSource
                                            "
                                        />
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item key="Edit">
                                    <v-row class="pa-4">
                                        <v-col class="col-12">
                                            <v-form
                                                ref="submitForm"
                                                v-model="validForSubmit"
                                            >
                                                <NewBasepriceModifiersEditor
                                                    :dataframes="
                                                        basePriceValues
                                                    "
                                                    :defaultPrice="800"
                                                    :propertiesDropdown="
                                                        propertiesList
                                                    "
                                                    @input="
                                                        [
                                                            updateValuesByPropertyWithModifiers(
                                                                {
                                                                    value: $event.data,
                                                                }
                                                            ),
                                                            resetAppliedModifiers(
                                                                {
                                                                    values: $event.modifiers,
                                                                }
                                                            ),
                                                        ]
                                                    "
                                                    ref="basePriceModifierEditor"
                                                    dateCol="dateValues"
                                                    priceCol="basePriceValues"
                                                    propCol="propertyValues"
                                                ></NewBasepriceModifiersEditor>
                                            </v-form>
                                        </v-col>
                                        <v-col class="col-12">
                                            <v-row
                                                align="center"
                                                justify="center"
                                            >
                                                <v-col class="col-6">
                                                    <v-btn
                                                        color="primary"
                                                        block
                                                        :disabled="
                                                            !!propertiesWithInvalidDateRanges?.length
                                                        "
                                                        @click="
                                                            checkSubmitValidation()
                                                        "
                                                        >Submit Base
                                                        Price</v-btn
                                                    >
                                                    <v-dialog
                                                        transition="dialog-bottom-transition"
                                                        max-width="600"
                                                        v-model="submitDialog"
                                                    >
                                                        <template
                                                            v-slot:default="dialog"
                                                        >
                                                            <v-card>
                                                                <v-toolbar
                                                                    color="primary"
                                                                    dark
                                                                    >Confirm
                                                                    base price
                                                                    submission</v-toolbar
                                                                >
                                                                <v-card-text
                                                                    class="pa-4"
                                                                >
                                                                    <div
                                                                        class="text-h6"
                                                                    >
                                                                        Confirm
                                                                        that you
                                                                        want to
                                                                        submit
                                                                        the base
                                                                        price
                                                                        with the
                                                                        current
                                                                        modifiers.
                                                                    </div>
                                                                    <div
                                                                        class="text-h6 red--text"
                                                                    >
                                                                        BE AWARE
                                                                        that
                                                                        only
                                                                        active
                                                                        modifiers
                                                                        are
                                                                        submitted
                                                                        for the
                                                                        final
                                                                        base
                                                                        price!
                                                                    </div>
                                                                </v-card-text>
                                                                <v-card-actions
                                                                    class="justify-end"
                                                                >
                                                                    <v-btn
                                                                        @click="
                                                                            [
                                                                                (dialog.value = false),
                                                                                updateBasePrice(
                                                                                    {
                                                                                        psId: currentStrategyPS
                                                                                            .metadata
                                                                                            .id,
                                                                                    }
                                                                                ),
                                                                                $refs.basePriceModifierEditor.resetModifiers(),
                                                                            ]
                                                                        "
                                                                        color="primary"
                                                                        >Submit
                                                                        Base
                                                                        Price</v-btn
                                                                    >
                                                                    <v-btn
                                                                        text
                                                                        @click="
                                                                            dialog.value = false
                                                                        "
                                                                        >Close</v-btn
                                                                    >
                                                                </v-card-actions>
                                                            </v-card>
                                                        </template>
                                                    </v-dialog>
                                                </v-col>
                                                <v-switch
                                                    dense
                                                    label="7 Days Span"
                                                    v-model="minSpan7Days"
                                                ></v-switch>
                                                <v-col class="col-12">
                                                    <span
                                                        v-if="
                                                            propertiesWithInvalidDateRanges?.length
                                                        "
                                                        class="red--text pa-2"
                                                    >
                                                        Some properties have
                                                        date ranges with less
                                                        than 7 days, see base
                                                        price by date range
                                                        table!
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col class="col-12 col-md-6 py-8">
                    <v-card class="card-ui" :loading="loading">
                        <v-card-title>Base Price Preview</v-card-title>
                        <v-card-text v-if="basePriceChartWithSavedLegend">
                            <v-row>
                                <v-col class="col-12">
                                    <v-autocomplete
                                        v-model="selectedPropertiesToShow"
                                        dense
                                        hide-details
                                        label="Select property"
                                        :items="propertiesList"
                                        multiple
                                        outlined
                                    >
                                        <template v-slot:prepend-item>
                                            <v-list-item
                                                ripple
                                                @mousedown.prevent
                                                @click="selectAllProperties"
                                            >
                                                <v-list-item-action>
                                                    <v-icon
                                                        dense
                                                        :color="
                                                            selectedPropertiesToShow?.length >
                                                            0
                                                                ? 'indigo darken-4'
                                                                : ''
                                                        "
                                                    >
                                                        {{ icon }}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        Select All
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider class="mt-2"></v-divider>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col class="col-12">
                                    <v-btn
                                        @click="
                                            previewFinalPrice({
                                                psId: currentStrategyPS.metadata.id,
                                            })
                                        "
                                        >Preview Final Price</v-btn
                                    >
                                </v-col>
                                <v-col class="col-12">
                                    <v-btn
                                        class="mr-2"
                                        color="primary"
                                        :outlined="!showOld"
                                        @click="showOld = !showOld"
                                        >Show Current</v-btn
                                    >
                                    <v-btn
                                        class="mr-2"
                                        color="primary"
                                        :outlined="!showNew"
                                        @click="showNew = !showNew"
                                        >Show New</v-btn
                                    >
                                </v-col>
                                <v-col class="col-12">
                                    <Plotly
                                        :data="basePriceChartWithSavedLegend"
                                        :layout="layoutBasePrice"
                                        @restyle="logEvent($event)"
                                    />
                                </v-col>
                                <v-col class="col-12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col class="col-12">
                                    <NewBasePriceDateRangeTable
                                        :items="valuesByPropertyWithModifiers"
                                        :propertyMappings="
                                            propertiesIncldingChildren
                                        "
                                        :minSpan7Days="minSpan7Days"
                                    ></NewBasePriceDateRangeTable>
                                </v-col>
                                <v-col class="col-12">
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="
                                    Object.keys(predictionChartDataByProperty)
                                        ?.length
                                "
                            >
                                <v-col class="col-12">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        label="Select property"
                                        :items="propertiesList"
                                        v-model="selectedPropertyToShow"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12">
                                    <v-btn
                                        :outlined="!showDefaults"
                                        color="primary"
                                        class="mx-2"
                                        @click="showDefaults = !showDefaults"
                                        >Defaults</v-btn
                                    >
                                    <v-btn
                                        :outlined="!showAdr"
                                        color="primary"
                                        class="mx-2"
                                        @click="showAdr = !showAdr"
                                        >ADR</v-btn
                                    >
                                    <v-btn
                                        :outlined="!showOccupancy"
                                        color="primary"
                                        class="mx-2"
                                        @click="showOccupancy = !showOccupancy"
                                        >Occupancy</v-btn
                                    >
                                    <v-btn
                                        :outlined="!showRollingOccupancy"
                                        color="primary"
                                        class="mx-2"
                                        @click="showRollingOccupancy = !showRollingOccupancy"
                                        >Rolling Occupancy</v-btn
                                    >
                                    <v-btn
                                        :outlined="!showCustomerBars"
                                        color="primary"
                                        class="mx-2"
                                        @click="
                                            showCustomerBars = !showCustomerBars
                                        "
                                        >Customer Bar</v-btn
                                    >
                                </v-col>
                                <v-col class="col-12">
                                    <Plotly
                                        :data="predictionChart"
                                        :layout="layoutPredict"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <BasePriceEditHistory></BasePriceEditHistory>
                    <BasePriceVersionHistory></BasePriceVersionHistory>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Plotly } from "vue-plotly";
import NewBasepriceModifiersEditor from "./NewBasepriceModifiersEditor.vue";
import BasePriceTrendlineGenerator from "./BasePriceTrendlineGenerator.vue";
import NewBasePriceDateRangeTable from "./NewBasePriceDateRangeTable.vue";
import BasePriceEditHistory from "./BasePriceEditHistory.vue";
import BasePriceVersionHistory from "./BasePriceVersionHistory.vue";
import Vue from "vue";

export default {
    components: {
        Plotly,
        NewBasepriceModifiersEditor,
        BasePriceTrendlineGenerator,
        NewBasePriceDateRangeTable,
        BasePriceEditHistory,
        BasePriceVersionHistory,
    },
    data() {
        return {
            items: ["Generate", "Edit"],
            tab: "",

            generationSourceList: [
                { text: "Trendline", value: "trendline" },
            ],

            loadingAutogeneration: false,

            // form
            validForSubmit: true,
            submitDialog: false,

            legendHashMap: {},
            selectedPropertyToShow: null,
            selectedPropertiesToShow: [],

            showNew: true,
            showOld: true,

            showDefaults: true,
            showAdr: false,
            showOccupancy: false,
            showCustomerBars: false,
        };
    },
    computed: {
        ...mapState({
            currentStrategyPS: (state) => state.core.currentStrategyPS,
            //appliedModifiers: state => state.basePrice.appliedModifiers,
            username: (state) => state.auth.username,
            loading: (state) => state.api.runningAPIRequest,
            dataFrames: (state) => state.basePrice.dataFrames,
            basePriceValues: (state) => state.basePrice.basePrice,
            valuesByPropertyWithModifiers: (state) =>
                state.basePrice.valuesByPropertyWithModifiers,
            darkMode: (state) => state.ui.darkMode,
            previewDataByProperty: (state) =>
                state.basePrice.previewDataByProperty,
        }),
        ...mapGetters({
            prodValuesByProperty: "basePrice/prodValuesByProperty",
            predictionChart: "basePrice/predictionChart",
            propertiesIncldingChildren: "core/allPropertyNamesById",
            propertiesWithInvalidDateRanges:
                "basePrice/propertiesWithInvalidDateRanges",
            propertiesList: "basePrice/allowedBasePricePropertiesDropdown",
        }),
        layoutBasePrice() {
            return {
                title: "Base Price",
                plot_bgcolor: this.darkMode ? "#333" : "#fff",
                paper_bgcolor: this.darkMode ? "#333" : `#fff`,
                font: { color: this.darkMode ? "#fff" : "#333" },
                showlegend: true,
                legend: { orientation: "h" },
                margin: { autoexpand: true },
                width: "100%",
                xaxis: {
                    showgrid: true,
                    gridcolor: this.darkMode
                        ? "rgba(255, 255, 255, 0.3)"
                        : `rgba(0, 0, 0, 0.3)`,
                },
                yaxis: {
                    showgrid: true,
                    gridcolor: this.darkMode
                        ? "rgba(255, 255, 255, 0.3)"
                        : `rgba(0, 0, 0, 0.3)`,
                },
            };
        },
        layoutPredict() {
            return {
                title: "Predict by Property",
                plot_bgcolor: this.darkMode ? "#333" : "#fff",
                paper_bgcolor: this.darkMode ? "#333" : `#fff`,
                font: { color: this.darkMode ? "#fff" : "#333" },
                showlegend: true,
                legend: { orientation: "h" },
                margin: { autoexpand: true },
                width: "100%",
                yaxis: { title: "Everything Else" },
                yaxis2: {
                    title: "Occupancy Stats",
                    overlaying: "y",
                    side: "right",
                },
            };
        },
        predictionChartDataByProperty() {
            // prediction chart data
            const dfs = structuredClone(this.dataFrames);

            const fieldsToAdd = [];

            if (this.showDefaults)
                fieldsToAdd.push(
                    "adr_nopr",
                    "adr_pr",
                    "customer_bar",
                    "grouped_adr_nopr",
                    "grouped_adr_pr",
                    "grouped_adr_pr_market",
                    "grouped_rolling_21_occupancy_perc",
                    "grouped_occupancy_perc_nopr",
                    "grouped_occupancy_perc_nopr_market",
                    "occupancy_perc_nopr"
                );

            if (this.showAdr)
                fieldsToAdd.push(
                    "adr_pr_ly1",
                    "adr_pr_ly2",
                    "adr_pr_ly3",
                    "adr_pr_ly4",
                    "adr_pr_ly5",
                    "grouped_adr_pr_ly1",
                    "grouped_adr_pr_ly2",
                    "grouped_adr_pr_ly3",
                    "grouped_adr_pr_ly4",
                    "grouped_adr_pr_ly5",
                    "grouped_adr_pr_market_ly1",
                    "grouped_adr_pr_market_ly2",
                    "grouped_adr_pr_market_ly3",
                    "grouped_adr_pr_market_ly4",
                    "grouped_adr_pr_market_ly5"
                );

            if (this.showOccupancy)
                fieldsToAdd.push(
                    "grouped_occupancy_perc_nopr_ly1",
                    "grouped_occupancy_perc_nopr_ly2",
                    "grouped_occupancy_perc_nopr_ly3",
                    "grouped_occupancy_perc_nopr_ly4",
                    "grouped_occupancy_perc_nopr_ly5",
                    "grouped_occupancy_perc_nopr_market_ly1",
                    "grouped_occupancy_perc_nopr_market_ly2",
                    "grouped_occupancy_perc_nopr_market_ly3",
                    "grouped_occupancy_perc_nopr_market_ly4",
                    "grouped_occupancy_perc_nopr_market_ly5"
                );

            if (this.showRollingOccupancy)
                fieldsToShow.push(
                    "grouped_rolling_21_occupancy_perc_nopr",
                    "grouped_rolling_21_occupancy_perc_nopr_ly1",
                    "grouped_rolling_21_occupancy_perc_nopr_ly2",
                    "grouped_rolling_21_occupancy_perc_nopr_ly3",
                    "grouped_rolling_21_occupancy_perc_nopr_ly4",
                    "grouped_rolling_21_occupancy_perc_nopr_market",
                    "grouped_rolling_21_occupancy_perc_nopr_market_ly1",
                    "grouped_rolling_21_occupancy_perc_nopr_market_ly2",
                    "grouped_rolling_21_occupancy_perc_nopr_market_ly3",
                    "grouped_rolling_21_occupancy_perc_nopr_market_ly4"
                );

            if (this.showCustomerBars)
                fieldsToAdd.push(
                    "customer_bar_smooth_season",
                    "customer_bar_smooth_week",
                    "customer_bar_smooth_week_peaks"
                );

            const predictionChartFinal = {};

            Array.from(new Set(dfs.property_id)).forEach((propertyId) => {
                predictionChartFinal[propertyId] = {};

                fieldsToAdd.forEach((field) => {
                    predictionChartFinal[propertyId][field] = {
                        x: [],
                        y: [],
                    };
                });
            });

            for (const field of Object.keys(dfs)) {
                if (fieldsToAdd.includes(field)) {
                    dfs.property_id.forEach((propertyId, index) => {
                        predictionChartFinal[propertyId][field].x.push(
                            dfs.d_stay[index]
                        );
                        predictionChartFinal[propertyId][field].y.push(
                            dfs[field][index]
                        );
                    });
                }
            }

            return predictionChartFinal;
        },
        predictionChart() {
            const selectedPropChart =
                this.predictionChartDataByProperty[this.selectedPropertyToShow];

            const lines = [];
            if (selectedPropChart !== undefined) {
                for (const [field, value] of Object.entries(
                    selectedPropChart
                )) {
                    const line = {
                        x: value.x,
                        y: value.y,
                        mode: "lines",
                        name: field,
                    };

                    if (field.includes("occupancy")) {
                        line.yaxis = "y2";
                    }

                    lines.push(line);
                }

                // prod lines
                lines.push({
                    x: this.prodValuesByProperty[this.selectedPropertyToShow]
                        ?.dates,
                    y: this.prodValuesByProperty[this.selectedPropertyToShow]
                        ?.values,
                    mode: "lines",
                    name: `${this.selectedPropertyToShow} - ${
                        this.propertiesIncldingChildren[
                            this.selectedPropertyToShow
                        ]
                    } - current`,
                });

                // with modifiers lines
                lines.push({
                    x: this.valuesByPropertyWithModifiers[
                        this.selectedPropertyToShow
                    ]?.dates,
                    y: this.valuesByPropertyWithModifiers[
                        this.selectedPropertyToShow
                    ]?.values,
                    mode: "lines",
                    name: `${this.selectedPropertyToShow} - ${
                        this.propertiesIncldingChildren[
                            this.selectedPropertyToShow
                        ]
                    } - new with modifiers`,
                });
            }

            return lines || [];
        },
        basePriceChart() {
            const lines = [];

            let colorVal = 0;
            this.propertiesList
                .map((prop) => prop.value)
                .forEach((propId) => {
                    colorVal = colorVal + 20;
                    if (colorVal > 360) colorVal = 0;

                    if (this.showOld) {
                        // prod lines
                        lines.push({
                            x: this.prodValuesByProperty[propId]?.dates,
                            y: this.prodValuesByProperty[propId]?.values.map(
                                (val) => Math.round(val)
                            ),
                            mode: "lines",
                            name: `${propId} - ${this.propertiesIncldingChildren[propId]} - current`,
                            line: {
                                dash: "dot",
                                color: `hsla(${colorVal}, 100%, 60%, 1)`,
                            },
                        });
                    }

                    if (this.showNew && !!this.valuesByPropertyWithModifiers) {
                        // with modifiers lines
                        lines.push({
                            x: this.valuesByPropertyWithModifiers[propId]
                                ?.dates,
                            y: this.valuesByPropertyWithModifiers[
                                propId
                            ]?.values.map((val) => Math.round(val)),
                            mode: "lines",
                            name: `${propId} - ${this.propertiesIncldingChildren[propId]} - new with modifiers`,
                            line: {
                                color: `hsla(${colorVal}, 100%, 40%, 1)`,
                            },
                        });

                        if (!!Object.keys(this.previewDataByProperty)?.length) {
                            lines.push({
                                x: this.previewDataByProperty[propId].map(
                                    (val) => val.date
                                ),
                                y: this.previewDataByProperty[propId].map(
                                    (val) => val.price_weighted_derived
                                ),
                                mode: "lines",
                                name: `${propId} - ${this.propertiesIncldingChildren[propId]} - price weighted derived`,
                                line: {
                                    color: `hsla(${colorVal}, 100%, 20%, 1)`,
                                },
                            });
                        }
                    }
                });

            return lines || [];
        },
        basePriceChartWithSavedLegend() {
            return this.basePriceChart
                .filter((val) =>
                    this.selectedPropertiesToShow.includes(
                        val.name.split(" - ")[0]
                    )
                )
                .map((plot) => {
                    const newPlot = plot;
                    newPlot.visible = this.legendHashMap[plot.name];
                    return newPlot;
                });
        },
        selectedGenerationSource: {
            get() {
                return this.$store.state.basePrice.selectedGenerationSource;
            },
            set(value) {
                this.updateSelectedGenerationSource({ selected: value });
            },
        },
        minSpan7Days: {
            get() {
                return this.$store.state.basePrice.minSpan7Days;
            },
            set(value) {
                this.updateMinSpanFlag({ value: value });
            },
        },
        icon() {
            if (
                this.selectedPropertiesToShow.length ===
                this.propertiesList.length
            )
                return "fa-light fa-times-square";
            if (
                this.selectedPropertiesToShow.length !==
                    this.propertiesList.length &&
                this.selectedPropertiesToShow.length > 0
            )
                return "fa-regular fa-minus-square";
            return "fa-regular fa-square-full";
        },
    },
    methods: {
        ...mapMutations({
            updateSelectedGenerationSource:
                "basePrice/updateSelectedGenerationSource",
            updateSelectedPropertyToShow:
                "basePrice/updateSelectedPropertyToShow",
            updateMinSpanFlag: "basePrice/updateMinSpanFlag",
            updateValuesByPropertyWithModifiers:
                "basePrice/updateValuesByPropertyWithModifiers",
            resetAppliedModifiers: "basePrice/resetAppliedModifiers",
        }),
        ...mapActions({
            updateBasePrice: "basePrice/updateBasePrice",
            previewFinalPrice: "basePrice/previewFinalPrice",
            magicWand: "basePrice/magicWand",
        }),
        async autogeneration() {
            this.loadingAutogeneration = true;
            await this.magicWand({ psId: this.currentStrategyPS.metadata.id });
            this.loadingAutogeneration = false;
        },
        logEvent(event) {
            // listen to graph legend change and saves the legend details, so that if info changes the legend stays the same
            event[1].forEach((plotIndex) => {
                let index = 0;
                for (const [propertyToSave, val] of Object.entries(event[0])) {
                    if (propertyToSave === "visible") {
                        Vue.set(
                            this.legendHashMap,
                            this.basePriceChart.data[plotIndex].name,
                            val[index]
                        );
                    }
                    index = index++;
                }
            });
        },
        selectAllProperties() {
            this.$nextTick(() => {
                if (
                    this.selectedPropertiesToShow.length ===
                    this.propertiesList.length
                ) {
                    this.selectedPropertiesToShow = [];
                } else {
                    this.selectedPropertiesToShow = this.propertiesList.map(
                        (prop) => prop.value
                    );
                }
            });
        },
        checkSubmitValidation() {
            this.$refs.submitForm.validate();

            if (this.validForSubmit) {
                this.submitDialog = true;
            }
        },
    },
};
</script>
