<template >
    <v-row>
        <v-col>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field label="Google Sheet ID" v-model="googleSheetId" :rules="googleSheetIdRules"
                    required></v-text-field>
            </v-form>
            <div class="d-flex justify-end">
                <v-btn color="primary" @click="nextStep">
                    Continue
                </v-btn>
                <v-btn color="secondary" class="mx-4" @click="$emit('back')">
                    Back
                </v-btn>
                <v-btn color="accent" @click="reset">
                    Reset
                </v-btn>
            </div>
            <ConfirmDialog ref="dialog" />
        </v-col>
    </v-row>
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import ConfirmDialog from '@/components/utils/ConfirmDialog'

export default Vue.extend({
    name: 'ConfigGenerationStep',
    components: { ConfirmDialog },
    data: function () {
        return {
            loadingData: false,
            valid: true,
            googleSheetId: '',
            googleSheetIdRules: [
                v => !!v || 'Google sheet is required'
            ]
        }
    },
    methods: {
        async nextStep() {
            const isValid = this.$refs.form.validate()
            if (isValid) {
                this.loadingData = true
                const pg = await this.$store.dispatch('smartpaying/createpropertySetConfig', { googleSheetId: this.googleSheetId })
                this.loadingData = false
                if (pg.response?.status == 400) {
                    const options = { cardTextClass: "pa-4 orange accent-1--text", color: 'orange' };
                    const title = "Something went wrong";
                    const message = pg.response.data
                    await this.$refs.dialog.open(title, message, options)
                }
                else {
                    this.googleSheetId = ''
                    this.$emit('next')
                }
            }
            else {
                const options = { cardTextClass: "pa-4 orange accent-1--text", color: 'orange' };
                const title = "Missing input";
                const message = "Please insert the google sheet id"
                await this.$refs.dialog.open(title, message, options)
            }

        },
        reset() {
            this.googleSheetId = ''
        }
    }
})
</script>