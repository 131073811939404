var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" Base Price Editor "),_c('v-btn',{staticClass:"ml-auto",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_vm._v(" Close ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-12 col-md-6 py-8"},[_c('v-card',{staticClass:"card-ui",attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v("Configuration of Base Price")]),_c('v-card-text',[_c('v-tabs',{attrs:{"background-color":"transparent","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"Generate"},[_c('v-card',[_c('v-card-actions',{staticClass:"px-4"},[_c('v-select',{staticClass:"mt-8",attrs:{"outlined":"","dense":"","label":"Select the generation source","items":_vm.generationSourceList,"item-text":"text","item-value":"value"},model:{value:(
                                                _vm.selectedGenerationSource
                                            ),callback:function ($$v) {
                                                _vm.selectedGenerationSource
                                            =$$v},expression:"\n                                                selectedGenerationSource\n                                            "}}),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","loading":_vm.loadingAutogeneration},on:{"click":function($event){return _vm.autogeneration()}}},[_c('v-icon',[_vm._v("fa-solid fa-wand-magic-sparkles")])],1)],1),(
                                            _vm.selectedGenerationSource ===
                                                'trendline' &&
                                            _vm.selectedGenerationSource
                                        )?_c('BasePriceTrendlineGenerator'):_vm._e()],1)],1),_c('v-tab-item',{key:"Edit"},[_c('v-row',{staticClass:"pa-4"},[_c('v-col',{staticClass:"col-12"},[_c('v-form',{ref:"submitForm",model:{value:(_vm.validForSubmit),callback:function ($$v) {_vm.validForSubmit=$$v},expression:"validForSubmit"}},[_c('NewBasepriceModifiersEditor',{ref:"basePriceModifierEditor",attrs:{"dataframes":_vm.basePriceValues,"defaultPrice":800,"propertiesDropdown":_vm.propertiesList,"dateCol":"dateValues","priceCol":"basePriceValues","propCol":"propertyValues"},on:{"input":function($event){[
                                                        _vm.updateValuesByPropertyWithModifiers(
                                                            {
                                                                value: $event.data,
                                                            }
                                                        ),
                                                        _vm.resetAppliedModifiers(
                                                            {
                                                                values: $event.modifiers,
                                                            }
                                                        ),
                                                    ]}}})],1)],1),_c('v-col',{staticClass:"col-12"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"col-6"},[_c('v-btn',{attrs:{"color":"primary","block":"","disabled":!!_vm.propertiesWithInvalidDateRanges?.length},on:{"click":function($event){return _vm.checkSubmitValidation()}}},[_vm._v("Submit Base Price")]),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Confirm base price submission")]),_c('v-card-text',{staticClass:"pa-4"},[_c('div',{staticClass:"text-h6"},[_vm._v(" Confirm that you want to submit the base price with the current modifiers. ")]),_c('div',{staticClass:"text-h6 red--text"},[_vm._v(" BE AWARE that only active modifiers are submitted for the final base price! ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){[
                                                                            (dialog.value = false),
                                                                            _vm.updateBasePrice(
                                                                                {
                                                                                    psId: _vm.currentStrategyPS
                                                                                        .metadata
                                                                                        .id,
                                                                                }
                                                                            ),
                                                                            _vm.$refs.basePriceModifierEditor.resetModifiers(),
                                                                        ]}}},[_vm._v("Submit Base Price")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close")])],1)],1)]}}]),model:{value:(_vm.submitDialog),callback:function ($$v) {_vm.submitDialog=$$v},expression:"submitDialog"}})],1),_c('v-switch',{attrs:{"dense":"","label":"7 Days Span"},model:{value:(_vm.minSpan7Days),callback:function ($$v) {_vm.minSpan7Days=$$v},expression:"minSpan7Days"}}),_c('v-col',{staticClass:"col-12"},[(
                                                        _vm.propertiesWithInvalidDateRanges?.length
                                                    )?_c('span',{staticClass:"red--text pa-2"},[_vm._v(" Some properties have date ranges with less than 7 days, see base price by date range table! ")]):_vm._e()])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"col-12 col-md-6 py-8"},[_c('v-card',{staticClass:"card-ui",attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v("Base Price Preview")]),(_vm.basePriceChartWithSavedLegend)?_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('v-autocomplete',{attrs:{"dense":"","hide-details":"","label":"Select property","items":_vm.propertiesList,"multiple":"","outlined":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.selectAllProperties}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"dense":"","color":_vm.selectedPropertiesToShow?.length >
                                                        0
                                                            ? 'indigo darken-4'
                                                            : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}],null,false,2440312241),model:{value:(_vm.selectedPropertiesToShow),callback:function ($$v) {_vm.selectedPropertiesToShow=$$v},expression:"selectedPropertiesToShow"}})],1),_c('v-col',{staticClass:"col-12"},[_c('v-btn',{on:{"click":function($event){return _vm.previewFinalPrice({
                                            psId: _vm.currentStrategyPS.metadata.id,
                                        })}}},[_vm._v("Preview Final Price")])],1),_c('v-col',{staticClass:"col-12"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","outlined":!_vm.showOld},on:{"click":function($event){_vm.showOld = !_vm.showOld}}},[_vm._v("Show Current")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","outlined":!_vm.showNew},on:{"click":function($event){_vm.showNew = !_vm.showNew}}},[_vm._v("Show New")])],1),_c('v-col',{staticClass:"col-12"},[_c('Plotly',{attrs:{"data":_vm.basePriceChartWithSavedLegend,"layout":_vm.layoutBasePrice},on:{"restyle":function($event){return _vm.logEvent($event)}}})],1),_c('v-col',{staticClass:"col-12"},[_c('v-divider')],1),_c('v-col',{staticClass:"col-12"},[_c('NewBasePriceDateRangeTable',{attrs:{"items":_vm.valuesByPropertyWithModifiers,"propertyMappings":_vm.propertiesIncldingChildren,"minSpan7Days":_vm.minSpan7Days}})],1),_c('v-col',{staticClass:"col-12"},[_c('v-divider')],1)],1),(
                                Object.keys(_vm.predictionChartDataByProperty)
                                    ?.length
                            )?_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Select property","items":_vm.propertiesList},model:{value:(_vm.selectedPropertyToShow),callback:function ($$v) {_vm.selectedPropertyToShow=$$v},expression:"selectedPropertyToShow"}})],1),_c('v-col',{staticClass:"col-12"},[_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":!_vm.showDefaults,"color":"primary"},on:{"click":function($event){_vm.showDefaults = !_vm.showDefaults}}},[_vm._v("Defaults")]),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":!_vm.showAdr,"color":"primary"},on:{"click":function($event){_vm.showAdr = !_vm.showAdr}}},[_vm._v("ADR")]),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":!_vm.showOccupancy,"color":"primary"},on:{"click":function($event){_vm.showOccupancy = !_vm.showOccupancy}}},[_vm._v("Occupancy")]),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":!_vm.showRollingOccupancy,"color":"primary"},on:{"click":function($event){_vm.showRollingOccupancy = !_vm.showRollingOccupancy}}},[_vm._v("Rolling Occupancy")]),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":!_vm.showCustomerBars,"color":"primary"},on:{"click":function($event){_vm.showCustomerBars = !_vm.showCustomerBars}}},[_vm._v("Customer Bar")])],1),_c('v-col',{staticClass:"col-12"},[_c('Plotly',{attrs:{"data":_vm.predictionChart,"layout":_vm.layoutPredict}})],1)],1):_vm._e()],1):_vm._e()],1),_c('BasePriceEditHistory'),_c('BasePriceVersionHistory')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }