<template>
    <v-card class="card-ui pa-4 my-4">
        <v-card-title>Edits History</v-card-title>
        <v-card-subtitle>Edits history of the base price modifications, be aware that this includes also edits from the current generation, past edits are available in previous versions</v-card-subtitle>

        <!-- <v-virtual-scroll
            :items="items"
            height="600"
            :bench="2"
            item-height="150"
        >
            <template v-slot:default="{ item }">
                <v-list-item :key="JSON.stringify(item)">
                    <v-list-item-content>
                        <v-list-item-title class="pa-2 text-wrap">
                            {{ item.message }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="pa-2">
                            {{ item.user }} - {{ item.date }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
            </template>
        </v-virtual-scroll> -->

        <v-data-table
            :headers="headers"
            :items="items"
            fixed-header
            height="600"
        >
            <template v-slot:item.properties="{ item }">
                <v-chip small v-for="prop in item.properties">
                    {{ prop }} - {{ allPropertyNamesById[prop] }}
                </v-chip>
            </template>
            <template v-slot:item.user="{ item }">
                {{ item.user.split("@")[0] }} <br>
                @{{ item.user.split("@")[1] }}
            </template>
            <template v-slot:item.dateRange="{ item }">
                <span v-if="item.kind !== 'extend'">
                    {{ item.dateRange.split("to")[0] }} <br>
                    ÷ <br>
                    {{ item.dateRange.split("to")[1] }}
                </span>
            </template>
        </v-data-table>

    </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    data() {
        return {


            headers: [
                { text: "Kind", value: "kind", width: "1%" },
                //{ text: "Action", value: "message" },
                { text: "Date Range", value: "dateRange", width: "5%" },
                { text: "Value", value: "value", width: "3%" },
                { text: "Properties Affected", value: "properties", width: "1%" },
                { text: "User", value: "user", width: "1%" },
                { text: "Date", value: "date", width: "2%" },
            ]
        }
    },
    computed: {
        ...mapState({
            editsHistory: state => state.basePrice.basePriceInfo.editsHistory,
        }),
        ...mapGetters({
            allPropertyNamesById: "core/allPropertyNamesById",
        }),
        items() {
            return this.editsHistory.map(modifier => this.createHistoryItem(modifier))
        }
    },
    methods: {
        createHistoryItem(modifier) {
            switch (modifier.kind) {
                case "percentual": {
                    return {
                        kind: "percentual",
                        message: `Added a percentual modifier from ${modifier.startDate} 
                        to ${modifier.endDate} of ${modifier.value} for properties: 
                        ${modifier.propertyIds.join(", ")}`,
                        dateRange: `${modifier.startDate}to${modifier.endDate}`,
                        value: modifier.value,
                        properties: modifier.propertyIds,
                        user: modifier.user,
                        date: new Date(modifier.createdAt).toLocaleString("it-it"),
                    } 
                }

                case "absolute": {
                    return {
                        kind: "absolute",
                        message: `Added an absolute modifier from ${modifier.startDate} 
                        to ${modifier.endDate} of ${modifier.value} for properties: 
                        ${modifier.propertyIds.join(", ")}`,
                        dateRange: `${modifier.startDate}to${modifier.endDate}`,
                        value: modifier.value,
                        properties: modifier.propertyIds,
                        user: modifier.user,
                        date: new Date(modifier.createdAt).toLocaleString("it-it"),
                    } 
                }

                case "direct": {
                    return {
                        kind: "direct",
                        message: `Added a direct modifier from ${modifier.startDate} 
                        to ${modifier.endDate} of ${modifier.value} for properties: 
                        ${modifier.propertyIds.join(", ")}`,
                        dateRange: `${modifier.startDate}to${modifier.endDate}`,
                        value: modifier.value,
                        properties: modifier.propertyIds,
                        user: modifier.user,
                        date: new Date(modifier.createdAt).toLocaleString("it-it"),
                    } 
                }

                case "copy": {
                    return {
                        kind: "copy",
                        message: `Copied values from ${modifier.startDate} 
                        to ${modifier.endDate} from property ${modifier.fromPropertyId} to properties: 
                        ${modifier.propertyIds.join(", ")}`,
                        dateRange: `${modifier.startDate}to${modifier.endDate}`,
                        value: `From property ${modifier.fromPropertyId}`,
                        properties: modifier.propertyIds,
                        user: modifier.user,
                        date: new Date(modifier.createdAt).toLocaleString("it-it"),
                    } 
                }

                case "overwrite": {
                    return {
                        kind: "overwrite",
                        /* message: `Overwrite values from ${modifier.startDate} 
                        to ${modifier.endDate} from property ${modifier.fromPropertyId} to properties: 
                        ${modifier.propertyIds.join(", ")}`, */
                        dateRange: `${modifier.startDate}to${modifier.endDate}`,
                        value: ``,
                        properties: modifier.propertyIds,
                        user: modifier.user,
                        date: new Date(modifier.createdAt).toLocaleString("it-it"),
                    } 
                }

                case "extend": {
                    return {
                        kind: "extend",
                        message: `Extended the base price to ${modifier.toDate}`,
                        value: `To ${modifier.toDate}`,
                        dateRange: "",
                        user: modifier.user,
                        date: new Date(modifier.createdAt).toLocaleString("it-it"),
                    } 
                }

                case 'copy-from-ps': {
                    return {
                        kind: "copy-from-ps",
                        message: `Copied values from PS ${modifier.fromPsId}`,
                        dateRange: "",
                        value: modifier.value.join("\n"),
                        properties: modifier.propertyIds,
                        user: modifier.user,
                        date: new Date(modifier.createdAt).toLocaleString("it-it"),
                    }
                }
            }

        }
    }
}
</script>