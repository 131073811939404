<template>
    <v-container class="pa-4" fluid>
        <DeleteReservations v-if="currentPG"></DeleteReservations>
        <SelectPgAlert v-else></SelectPgAlert>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DeleteReservations from '../components/DeleteReservations.vue'
import SelectPgAlert from '@/components/utils/SelectPgAlert'

export default {
    components: { DeleteReservations, SelectPgAlert },
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG,
        }),
    },
    methods: {
        ...mapActions({
            checkReadSupport: "gpcs/checkReadSupport",
        }),
    },
    async created() {
        await this.checkReadSupport()
    }
}
</script>

<style lang="scss" scoped></style>
