const yaml = require('js-yaml')
import * as yamlUtils from '@/utils/standardMapsYamlUtils.js' 

export default {
  namespaced: true,

  state: {

    customerThresholdMaps: [],
    //convertMaps: convertMaps,


  },//end of state 


  mutations: {


    editCustomerThresholdMap(state, args){
      var op = args.operation;
      var parameters = yaml.load(this.state.editor.codeToRender);

      if(parameters.cap === undefined){
        parameters['cap'] = {'customer_bar_thresholds':[]};
      }

      if(op == "update" || op == "create"){
        var maps = args.maps;
        maps = yamlUtils.customerThresholdMapsToYamlMaps(maps);
        parameters.cap.customer_bar_thresholds = maps;
      }

      if(op == "delete"){
        var index = args.index;
        parameters.cap.customer_bar_thresholds = parameters.cap.customer_bar_thresholds.slice(0,index).concat(parameters.cap.customer_bar_thresholds.slice(index+1));
      
        if(parameters.cap.customer_bar_thresholds.length == 0){
          delete parameters.cap.customer_bar_thresholds;

          let mapParentProps = Object.keys(parameters.cap);

          if(Array.isArray(mapParentProps) == false || mapParentProps.length == 0){
            delete parameters.cap //remove map parent if it doesn/t have any other property
          }
        }

      }

      this.state.editor.codeToRender = yaml.dump(parameters, {ref: false});
  },


  }, // end mutations
  
  actions: {
    editCustomerThresholdMap(context, args){
      context.commit('editCustomerThresholdMap', args);
    }

  }, //end of actions

}
