<template>
    <v-card elevation="0" class="pa-4">
        <v-card-actions>
            <v-btn color="primary" :outlined="!tableColumnFilterEnabled"
                @click="tableColumnFilterEnabled = !tableColumnFilterEnabled">
                Table columns
            </v-btn>
            <v-spacer></v-spacer>
            <div class="justify-end">
                <v-sheet class="my-2 card-ui">
                    <v-btn class="mr-2" @click="selectAllTableColumns()">
                        Select all
                    </v-btn>
                    <v-btn class="mx-2" @click="clearAllTableColumns()">
                        Clear all
                    </v-btn>
                </v-sheet>
            </div>
        </v-card-actions>

        <v-sheet v-show="tableColumnFilterEnabled" width="100%" class="my-2 card-ui">
            <v-autocomplete label="Table columns" outlined dense multiple hide-details return-object :items="tableColumns"
                v-model="tableColumnsCurrentlyEnabled"
                @change="updateCurrentlyEnabledTableColumns($event)"></v-autocomplete>
        </v-sheet>

        <v-sheet v-show="tableColumnsCurrentlyEnabledExtended.length" width="100%" class="my-2 card-ui">
            Enabled table columns:
            <v-chip-group active-class="primary--text" column>
                <v-chip v-for="column in tableColumnsCurrentlyEnabledExtended" :key="column.value" close small
                    :color="column.color" @click:close="removeCurrentlyEnabledTableColumn(column.value)">
                    {{ column.text }}
                </v-chip>
            </v-chip-group>
        </v-sheet>
    </v-card>
</template> 

<script>
export default {
    props: [
        "tableColumns",
        "tableColumnsFilterDefaultEnabled"
    ],
    data() {
        return {
            tableColumnFilterEnabled: false,
            tableColumnsCurrentlyEnabled: [],

            columnColorsArray: [
                "red darken-2", "red darken-4",
                "pink darken-2", "pink darken-4",
                "purple darken-2", "purple darken-4",
                "deep-purple darken-2", "deep-purple darken-4",
                "indigo darken-2", "indigo darken-4",
                "blue darken-2", "blue darken-4",
                "light-blue darken-2", "light-blue darken-4",
                "cyan darken-2", "cyan darken-4",
                "teal darken-2", "teal darken-4",
                "green darken-2", "green darken-4",
                "light-green darken-2", "light-green darken-4",
                "lime darken-2", "lime darken-4",
                "yellow darken-2", "yellow darken-4",
                "amber darken-2", "amber darken-4",
                "orange darken-2", "orange darken-4",
                "deep-orange darken-2", "deep-orange darken-4",
                "brown darken-2", "brown darken-4",
                "blue-grey darken-2", "blue-grey darken-4",
            ],

        }
    },
    watch: {
        tableColumnsCurrentlyEnabled(newValue) {
            this.$emit("input", newValue)
        }
    },
    computed: {
        columnColors() {
            const columnColorsMap = {}
            this.tableColumns.forEach(column => {
                columnColorsMap[column.value] = this.columnColorsArray[Math.floor(Math.random() * this.columnColorsArray.length)]
            })
            return columnColorsMap
        },
        tableColumnsCurrentlyEnabledExtended() {
            if (this.tableColumnsCurrentlyEnabled.length) {
                const tableColumnsExtended = []

                this.tableColumnsCurrentlyEnabled.forEach((column, _) => {
                    tableColumnsExtended.push({
                        ...column,
                        color: this.columnColors[column.value]
                    })
                })

                return tableColumnsExtended
            }
            return []
        },
    },
    methods: {
        updateCurrentlyEnabledTableColumns(newColumns) {
            this.tableColumnsCurrentlyEnabled = structuredClone(newColumns)
        },
        removeCurrentlyEnabledTableColumn(columnToRemoveValue) {
            const index = this.tableColumnsCurrentlyEnabled.findIndex(column => column.value === columnToRemoveValue)

            if (index !== -1) {
                this.tableColumnsCurrentlyEnabled.splice(index, 1)
            }
        },
        selectAllTableColumns() {
            this.updateCurrentlyEnabledTableColumns(this.tableColumns)
        },
        clearAllTableColumns() {
            this.updateCurrentlyEnabledTableColumns([])
        }
    },
    created() {
        this.tableColumnFilterEnabled = !!this.tableColumnsFilterDefaultEnabled
        this.updateCurrentlyEnabledTableColumns(this.tableColumns.filter(column => column.defaultEnabled === true || column.defaultEnabled === undefined))
    }
}
</script>
