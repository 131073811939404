// store for the pipeline v1 (aka Old but not so Old Pipeline)

import Vue from "vue"

export default {
    namespaced: true,

    state: {
        // don't even fucking know honestly
        yamlIsValid: false,

        // global request priority
        globalRequestPriority: "0.8",

        // selected pg data
        currentGeneralSettings: {},
        currentStrategy: null,
        currentPipelineBinds: null,

        // selected pipelinebind
        selectedPipelineBind: null,

        // pipelinebinds histories
        pipelineBindsHistories: {},

        // pipelinebinds calculation results
        pipelineBindsCalculationResults: {},

        // standard values
        configMaps: null,
        pipelines: null,
        markets: null,


        feedbackFunction: function(resJobRun){ // error checking not done by me...
            const messages = []
          
            if(resJobRun.results[0].error.error !== undefined && resJobRun.results[0].error.error.length >0){
                messages.push('Error: ' + resJobRun.results[0].error.error + '. ');
            }
            if(resJobRun.results[0].error.trace !== undefined && resJobRun.results[0].error.trace.length > 0){
                messages.push('Trace: ' + resJobRun.results[0].error.trace);
            }
          
            //this ain't duplicate code or an error
          
            if(resJobRun.results[0].error !== undefined && resJobRun.results[0].error.length >0){
          
                if (typeof resJobRun.results[0].error  === 'string'){
                    messages.push('Error: ' + resJobRun.results[0].error)
                }
            
                if(resJobRun.results[0].error.error !== undefined && typeof resJobRun.results[0].error.error == 'string'){
                    messages.push('Error: ' + resJobRun.results[0].error.error + '. ');
                }
            }
            if(resJobRun.results[0].traceback !== undefined && resJobRun.results[0].traceback.length > 0){
                messages.push('Traceback: ' + resJobRun.results[0].traceback);
            }  
          
            if(resJobRun.results[0].error !== undefined){
                if(resJobRun.results[0].error.input_errors !== undefined){
                    for(var i=0; i < resJobRun.results[0].error.input_errors.length; i++){
                        let error = 'Input error. Message: ' + resJobRun.results[0].error.input_errors[i].message + '. Path: ' + resJobRun.results[0].error.input_errors[i].path;
                        messages.push(error);
                    }
                }
                if(resJobRun.results[0].error.parameter_errors !== undefined){
                    for(var i=0; i < resJobRun.results[0].error.parameter_errors.length; i++){  
                        let error = 'Parameters error. Message: ' + resJobRun.results[0].error.parameter_errors[i].message + '. Path: ' + resJobRun.results[0].error.parameter_errors[i].path;
                        messages.push(error);
                    }
                }
            }
          
            return messages;
        }
    },

    getters: {

        // strategies

        allStrategiesList(state) {
            return state.currentStrategy?.spec?.pipelineBinds
        },

        preprocessStrategies(state, getters) {
            return getters.allStrategiesList.filter(val => val.includes("preprocess") && !val.includes("predict"))
        },

        predictStrategies(state, getters) {
            return getters.allStrategiesList.filter(val => val.includes("predict") && !val.includes("expert"))
        },

        preprocessStrategiesDropdown(state, getters) {
            return getters.preprocessStrategies.map(val => {
                return {
                    text: val?.replaceAll(".", " "),
                    value: val,
                }
            }) 
        },

        predictStrategiesDropdown(state, getters) {
            return getters.predictStrategies.map(val => {
                return {
                    text: val?.replaceAll(".", " "),
                    value: val,
                }
            })
        },

        pipelineBindByLabel(state, getters) {
            const hashMap = {}
            state.currentPipelineBinds.forEach(val => {
                hashMap[val?.name] = val
            })

            return hashMap
        },

        pipelineBindsPreprocessCalculationResults(state, getters) {
            const objEntriesFiltered = Object.entries(state.pipelineBindsCalculationResults).filter(([pbName, pbData]) => {
                return getters.preprocessStrategies.includes(pbName)
            })
            
            return Object.fromEntries(objEntriesFiltered)
        },

        pipelineBindsPredictCalculationResults(state, getters) {
            const objEntriesFiltered = Object.entries(state.pipelineBindsCalculationResults).filter(([pbName, pbData]) => {
                return getters.predictStrategies.includes(pbName)
            })
            
            return Object.fromEntries(objEntriesFiltered)
        },

        // other

        allowedProperties(state, getters, rootState, rootGetters) {
            return rootState.core.currentPropertiesIncludingChildren.filter(prop => state.currentGeneralSettings.propertyIds?.includes(Number(prop.metadata.id)))
        },

        allowedPropertiesDropdown(state, getters) {
            return getters.allowedProperties.map(prop => {
                return {
                    text: `${prop.metadata.id} - ${prop.metadata.name}`,
                    value: prop.metadata.id
                }
            })
        },

        pipelineBindsReady(state) {
            return !!state.currentPipelineBinds?.length
        },

        marketsDropdown(state) {
            return state?.markets?.map(mkt => {
                return {
                    text: mkt?.metadata?.id?.replaceAll("_", " "),
                    value: mkt?.metadata?.id
                }
            }) || null
        },

        predictConfigMap(state, getters){
            return state.configMaps?.find(e => e.name.split(".")[0] === 'predict')?.resource?.spec?.parameters
        },

        alreadyLoadedPipelineBinds(state) {
            return Object.keys(state.pipelineBindsCalculationResults) || null
        }
       
    },

    mutations: {

        resetAllPipelineV1(state) {
            state.currentPipelineBinds = null
            state.currentStrategy = null
            state.currentGeneralSettings = {}
            state.selectedPipelineBind = null
            state.pipelineBindsCalculationResults = {}
            state.configMaps = null
            state.pipelines = null
            state.markets = null
            state.pipelineBindsHistories = {}
        },

        // resets the current strategy with null or new strat value
        resetCurrentStrategy(state, payload) {
            state.currentStrategy = null
            if (!!payload) {
                state.currentStrategy = payload.value
            }
        },

        // resets all the pipeline binds with null or new pipeline values
        resetCurrentPipelineBinds(state, payload) {
            state.currentPipelineBinds = null
            if (!!payload) {
                state.currentPipelineBinds = []
                payload.values.forEach(val => {
                    state.currentPipelineBinds.push(val)
                })
            }
        },

        removeCurrentPipelineBind(state, payload) {
            const index = state.currentPipelineBinds.findIndex(val => val.resource.metadata.name === payload?.key)

            console.log(payload)
            if (index !== -1) {
                state.currentPipelineBinds.splice(index, 1)
            }
        },

        updateCurrentPipelineBind(state, payload) {
            const index = state.currentPipelineBinds.findIndex(val => val.resource.metadata.name === payload?.key)

            if (index !== -1 && !!payload?.value) {
                state.currentPipelineBinds.splice(index, 1, payload.value)
            }
        }, 

        addCurrentPipelineBind(state, payload) {
            if (!!payload?.value) {
                state.currentPipelineBinds.push(payload.value)
            }
        },

        // pipelinebind calculation results
        resetPipelineBindsCalculationResults(state) {
            state.pipelineBindsCalculationResults = {}
        },

        removePipelineBindsCalculationResults(state, payload) {
            if (payload?.key) {
                delete state.pipelineBindsCalculationResults[payload.key]
            }
        },

        addPipelineBindsCalculationResults(state, payload) {
            Vue.set(state.pipelineBindsCalculationResults, payload.key, payload.value)
        },        


        // pipelinebind histories
        resetPipelineBindsHistories(state, payload) {
            state.pipelineBindsHistories = {}
        },

        addPipelineBindsHistories(state, payload) {
            Vue.set(state.pipelineBindsHistories, payload.key, payload.value)
        },   


        // resets config maps with null or new config map values
        resetConfigMaps(state, payload) {
            state.configMaps = null
            if (!!payload) {
                state.configMaps = []
                payload.values.forEach(val => {
                    state.configMaps.push(val)
                })
            }
        },

        // resets pipelines with null or new values
        resetPipelines(state, payload) {
            state.pipelines = null
            if (!!payload) {
                state.pipelines = []
                payload.values.forEach(val => {
                    state.pipelines.push(val)
                })
            }
        },

        // resets the markets with null or new values
        resetMarkets(state, payload) {
            state.markets = null
            if (!!payload) {
                state.markets = []
                payload.values.forEach(val => {
                    state.markets.push(val)
                })
            }
        },

        // selected pipelinebind
        resetSelectedPipelineBind (state, payload) {
            state.selectedPipelineBind = null
            if (!!payload) {
                state.selectedPipelineBind = payload.value
            }
        },

        // ---
        // general settings

        updateGeneralSettings(state, payload) {
            Vue.set(state.currentGeneralSettings, payload.key, payload.value)
        },
    
        resetGeneralSettings(state) {
            Vue.set(state.currentGeneralSettings, "reqBody", null)
            Vue.set(state.currentGeneralSettings, "propertyIds", [])
            Vue.set(state.currentGeneralSettings, "propertySetStrategyId", null)
            Vue.set(state.currentGeneralSettings, "startYear", null)
            Vue.set(state.currentGeneralSettings, "defaultPrice", null)
        }
    },

    actions: {
        // standard fetches

        // fetch config maps (standard code same for all strategies v1)
        async fetchConfigMaps(context) {
            const api = context.rootState.api.api

            try {
                // fetch the config maps for preprocess & predict
                const [preprocessConfigMapsRes, predictConfigMapsRes] = await Promise.all([api.get('predictschema/v2', '/master/ConfigMap/preprocess.standard', {}, {}, {}), api.get('predictschema/v2', '/master/ConfigMap/predict.standard', {}, {}, {})])
                
                context.commit("resetConfigMaps", {
                    values: [preprocessConfigMapsRes.items?.[0], predictConfigMapsRes.items?.[0]] 
                })

            } catch(error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to fetch the config maps V1! Errors: ${error}`,
                    timeout: -1
                }, {root: true})
            }
        },

        // fetch the pipelines (standard code same for all strategies v1)
        async fetchPipelines(context) {
            const api = context.rootState.api.api

            try {
                const pipelineRes = await api.get('predictschema/v2', '/master/Pipeline', {}, {}, {})
                
                context.commit("resetPipelines", {
                    values: pipelineRes.items 
                })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to fetch the pipelines V1! Errors: ${error}`,
                    timeout: -1
                }, {root: true})
            }
        },

        // fetch the markets (standard code same for all strategies v1)
        async fetchMarkets(context) {
            const api = context.rootState.api.api

            try {
                const res = await api.get(`graph/vpg`, `/market`, {}, {}, {})

                if (res.httpResponseStatus !== 200) throw res.error

                context.commit("resetMarkets", {
                    values: res.items
                })
                
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to fetch the markets V1! Errors: ${error}`,
                }, {root: true})
            }
        },


        // magic wand (aka automatic strategy creation)
        async magicWand(context, payload) {
            const api = context.rootState.api.api

            try {
                // warning if the selected pg doesn't have a booking url
                if (!context.rootState.core.currentPG?.spec?.booking_id) {
                    context.dispatch("alertSystem/newWarning", {
                        message: "Autostrategy won't take into account the external market since no Booking URL was provided!",
                        timeout: -1,
                    }, {root: true})
                }

                // execute the magic wand (automatic generation)
                const res = await api.put(`predictapi/v1`, `/pipeline-v1/master/new/${payload.psId}`, {}, {}, {})

                if (res instanceof Error) throw res?.response?.data?.error?.error || res?.response?.data

                // refetch the updated strategy
                await context.dispatch("fetchAll", {psId: payload.psId})

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to generate the strategy automatically! Errors: ${error}`,
                    timeout: -1
                }, {root: true})
            }
        },

        async updateCurrentStrategy(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.put('predictschema/v2', `/`, {}, payload.updatedStrategy, {})

                context.commit("resetCurrentStrategy", {
                    value: payload.updatedStrategy
                })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to update the strategy V1! Errors: ${error}`,
                    timeout: -1
                }, {root: true})
            }
        },

        // fetch all the pipelinebinds
        async fetchAll(context, payload) {
            const api = context.rootState.api.api

            try {
                // fetch the strategy
                const resStrat = await api.get(`predictschema/v2`, `/master/Strategy/${payload.psId}`, {}, {}, {})
                if (!!resStrat.items?.length) {
                    context.commit("resetCurrentStrategy", {
                        value: resStrat.items[0].resource
                    })
                }

                // fetch the strategy pipeline binds
                const pipelineBindsList = []
                await Promise.all(context.state.currentStrategy.spec.pipelineBinds.map(async (pipelineBind) => {
                    const pipelineBindRes = await api.get(`predictschema/v2`, `/master/PipelineBind/${encodeURIComponent(pipelineBind)}`, {}, {}, {})

                    if (pipelineBindRes.status === "found") {
                        const labels = pipelineBindRes.items[0].resource.metadata.labels
                        // show the master label above the others
                        if (labels.includes("smartpricing") || labels.includes("master")) {
                            pipelineBindsList.unshift(pipelineBindRes.items[0])
                        } else {
                            pipelineBindsList.push(pipelineBindRes.items[0])
                        }
                    }
                }))

                context.commit("resetCurrentPipelineBinds", {
                    values: pipelineBindsList
                })
                
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Failed to fetch the strategy V1! Errors: ${error}`,
                    timeout: -1
                }, {root: true})
            }
        },

        
        // runs a single pipelinebind
        async runPipelinebind(context, payload) {
            const api = context.rootState.api.api

            try {
                // type examples: prediction || preprocess
                // name example: predict.standard.17
                // workspace example: master
                const jobCreateRes = await api.get(`predictjob/v2`, `/${payload.workspace}/pipelinebind/${payload.name}`, {}, {}, {})
                
                // check if job is found
                if (jobCreateRes.status !== "found" && !jobCreateRes?.items?.length) throw "No Pipeline Fetched"
                
                const jobSpec = jobCreateRes.items[0].resource
                
                // job run
                const jobRunRes = await api.put(`predict/v1`, `/job`, {priority: context.state.globalRequestPriority}, [
                    {job: jobSpec, force: !!payload.forceCache}
                ], {})


                if (!jobRunRes.results[0].success) throw context.state.feedbackFunction(jobRunRes)

                // returns the result
                context.commit("addPipelineBindsCalculationResults", {
                    key: jobRunRes.results[0].job.metadata.name,
                    value: jobRunRes.results[0]
                })
                
                context.dispatch("alertSystem/newSuccess", {
                    message: `PipelineBind ${jobRunRes.results[0].job.metadata.name} run successfully!`
                }, {root: true})
                return {
                    key: jobRunRes.results[0].job.metadata.name,
                    value: jobRunRes.results[0]
                }
            } catch (error) {
                if (error instanceof Array) {
                    error.forEach(err => {
                        context.dispatch("alertSystem/newError", {
                            message: `PipelineBind run failed! Error: ${err}`,
                            timeout: -1,
                        }, {root: true})
                    })
                } else {
                    context.dispatch("alertSystem/newError", {
                        message: `PipelineBind run failed! Error: ${error}`,
                        timeout: -1,
                    }, {root: true})
                }
                return null
            }
        },

        async overwritePipelineBind(context, payload) {
            const api = context.rootState.api.api

            try {
                const resJob = await api.put(`predictschema/v2`, `/master/overwrite/${payload.targetPb}/${payload.sourcePb}`, {
                    user: context.rootState.auth.username
                }, {}, {})

                if (resJob?.response?.data?.kind == "Error") throw JSON.stringify(resJob.response.data.items)
                // doesn't return the updated pipeline bind you have to fetch again

                const newPb = await context.dispatch("fetchPipelineBind", {
                    workspace: "master",
                    kind: "PipelineBind",
                    name: payload.targetPb
                })

                context.commit("updateCurrentPipelineBind", {
                    key: newPb.resource.metadata.name,
                    value: newPb
                })

                context.dispatch("alertSystem/newSuccess", {
                    message: `PipelineBind ${payload.targetPb} overwritten with ${payload.sourcePb} successfully!`
                }, {root: true})
                return resJob
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `PipelineBind overwriting failed! Error: ${error}`
                }, {root: true})
                return null
            }
        },

        async clonePipelineBind(context, payload) { // remember to add to the state the cloned pipeline
            const api = context.rootState.api.api

            try {
                const resJob = await api.put(`predictschema/v2`, `/master/clone/${payload.psId}/${payload.sourcePb}/${payload.targetPb}`, {}, {}, {})

                if (resJob?.response?.data?.kind == "Error") throw JSON.stringify(resJob.response.data.items)

                const newPb = await context.dispatch("fetchPipelineBind", {
                    workspace: "master",
                    kind: "PipelineBind",
                    name: payload.targetPb
                })

                // refresh the strategy
                const resStrat = await api.get(`predictschema/v2`, `/master/Strategy/${payload.psId}`, {}, {}, {})
                if (!!resStrat.items?.length) {
                    context.commit("resetCurrentStrategy", {
                        value: resStrat.items[0].resource
                    })
                }

                context.commit("addCurrentPipelineBind", {value: newPb})
                

                context.dispatch("alertSystem/newSuccess", {
                    message: `PipelineBind ${payload.sourcePb} cloned successfully!`
                }, {root: true})
                return resJob
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `PipelineBind cloning failed! Error: ${error}`
                }, {root: true})
                return error
            }
        },

        async validateCustomConfig(context, payload){
            const api = context.rootState.api.api

            try {
                let feedbackMessages = []
                const resJob = await api.put('predictjob/v2', '/' + payload.pipelineBind.workspace + '/' + 'pipelinebind', {}, payload.pipelineBind.resource, {});
          
                if (resJob.status == 'found' && resJob.items !== undefined && resJob.items.length == 1) {
                    const jobSpec = resJob.items[0].resource
                    const resJobRun = await api.put('predict/v1', '/job', {priority: context.state.globalRequestPriority}, [{job: jobSpec, dryRun: true}], {}); 
            
                    if(resJobRun.results[0].success!=undefined && resJobRun.results[0].success == false){
                        feedbackMessages = context.state.feedbackFunction(resJobRun);
                    }
                }

                return feedbackMessages;
            } catch (error) {
                return error
            }

        },

        async fetchPipelineBind(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get(`predictschema/v2`, `/${payload.workspace}/${payload.kind}/${encodeURIComponent(payload.name)}`, {}, {}, {})

                if (res.status !== "found") throw `PipelineBind not found`

                return res.items[0]
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `PipelineBind fetch failed! Error: ${error}`
                }, {root: true})
                return null
            }
        },

        async deletePipelineBind(context, payload) {
            const api = context.rootState.api.api

            try {
                // delete the pipelinebind
                const res = await api.delete('predictschema/v2', `/${payload.workspace}/${payload.kind}/${payload.name}`, {}, {}, {})

                // if successfull deletes the pipelinebind from the store
                context.commit("resetSelectedPipelineBind")
                context.commit("removePipelineBindsCalculationResults", {key: payload.name})
                context.commit("removeCurrentPipelineBind", {key: payload.name})

                // delete the pipelinebind from the strategy's pipelinebinds list
                const currentStrategyCopy = structuredClone(context.state.currentStrategy)
                currentStrategyCopy.spec.pipelineBinds = currentStrategyCopy.spec.pipelineBinds.filter(pbName => pbName !== payload.name)
                await context.dispatch("updateCurrentStrategy", {
                    updatedStrategy: currentStrategyCopy
                })

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `PipelineBind deletion failed! Error: ${error}`
                }, {root: true})
            }
        },

        async savePipelineBind(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.put('predictschema/v2', `/`, {}, payload.newPipelineBind.resource, {})

                const resNewPipeline = await context.dispatch("fetchPipelineBind", {
                    workspace: payload.newPipelineBind.resource.metadata.workspace,
                    kind: payload.newPipelineBind.kind,
                    name: payload.newPipelineBind.resource.metadata.name,
                })

                context.commit("updateCurrentPipelineBind", {
                    key: resNewPipeline.resource.metadata.name,
                    value: resNewPipeline,
                })

                if (context.state.selectedPipelineBind.resource.metadata.id === resNewPipeline.resource.metadata.name) {
                    context.commit("resetSelectedPipelineBind", {
                        value: resNewPipeline,
                    })
                }

                return res
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `PipelineBind save failed! Error: ${error}`,
                    timeout: -1
                }, {root: true})
            }
        },

        async fetchHistory(context, payload) {
            const api = context.rootState.api.api

            try {
                const res = await api.get('predictschema/v2', `/${payload.workspace}/${payload.kind}/${encodeURIComponent(payload.name)}/history`, {}, {}, {})

                context.commit("addPipelineBindsHistories", {key: payload.name, value: res.items})
                return res.items
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error fetching history! Error: ${error}`
                }, {root: true})
                return null
            }
        },

        // ---
        // general settings
        // maybe in the future to do a better implementation but for the sake of time we have this one

        async fetchGeneralSettings(context, payload) {
            const api = context.rootState.api.api

            try {
                const response = await api.get("predictschema/v2", `/master/ConfigMap/config.standard.${payload.psId}`, {}, {}, {})

                const resource = response.items[0].resource

                context.commit("resetGeneralSettings")
                context.commit("updateGeneralSettings", {key: "reqBody", value: response.items[0]})
                context.commit("updateGeneralSettings", {key: "propertyIds", value: resource.spec.parameters.global.property_ids})
                context.commit("updateGeneralSettings", {key: "propertySetStrategyId", value: resource.spec.parameters.global.property_set_strategy_id})
                context.commit("updateGeneralSettings", {key: "startYear", value: resource.spec.parameters.global.start_year})
                context.commit("updateGeneralSettings", {key: "defaultPrice", value: resource.spec.parameters.global.default_price})

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting general settings ${error}`,
                    timeout: -1,
                }, {root: true})
            }
        },

        async saveGeneralSettings(context, payload) {
            const api = context.rootState.api.api
      
            try {
                if (String(payload.psId) !== String(context.state.currentGeneralSettings.propertySetStrategyId)) throw "Pg mismatch! Reload page!"
                const body = structuredClone(context.state.currentGeneralSettings.reqBody)
                
                body.resource.spec.parameters.global.property_set_strategy_id = context.state.currentGeneralSettings.propertySetStrategyId
                body.resource.spec.parameters.global.property_ids = context.state.currentGeneralSettings.propertyIds
                body.resource.spec.parameters.global.start_year = context.state.currentGeneralSettings.startYear
                body.resource.spec.parameters.global.default_price = context.state.currentGeneralSettings.defaultPrice
                
                await api.put("predictschema/v2", `/`, {}, body.resource, {})
        
                context.dispatch("alertSystem/newSuccess", {
                    message: `General settings saved successfully`,
                }, {root: true})
            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error saving general settings ${error}`,
                    timeout: -1,
                }, {root: true})
            }
        },

    }
}



