<template>
    <v-card-text>
        <v-card-title class="text-h5 white--text">Trendline Generation</v-card-title>
        <v-form
            ref="form"
            v-model="validForm"
        >
        <v-row>
                <v-col class="col-12">
                    <v-divider></v-divider>
                    <v-card-title>General Settings</v-card-title>
                </v-col>
                <v-col class="col-6">
                    <v-autocomplete
                        outlined
                        dense
                        label="Select dow start day"
                        :items="daysOfTheWeekList"
                        item-text="text"
                        item-value="value"
                        v-model="selectedDowStartDay"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-6">
                    <v-autocomplete
                        outlined
                        dense
                        label="Select the period"
                        :items="periodList"
                        item-text="text"
                        item-value="value"
                        :rules="[rules.required]"
                        v-model="selectedPeriod"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-12">
                    <v-autocomplete
                        outlined
                        dense
                        label="Select smoothing"
                        :items="smoothingsList"
                        item-text="text"
                        item-value="value"
                        v-model="selectedSmoothing"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-12">
                    <v-divider></v-divider>
                    <v-card-title>Property Base Price Mappings</v-card-title>
                </v-col>
                <v-col class="col-12 col-md-6" v-for="id in propertiesList" :key="id">
                    <v-text-field
                        outlined
                        dense
                        :label="`${id} - ${allPropertyNamesById[id]}`"
                        :value="selectedBasePriceMap(id)"
                        :rules="[rules.isNumber, rules.isAboveZero]"
                        @input="updateBasePriceMap(id, $event)"
                    ></v-text-field>
                </v-col>
                <v-col class="col-12">
                    <v-divider></v-divider>
                    <v-card-title>Internal Market</v-card-title>
                </v-col>
                <v-col class="col-6">
                    <v-autocomplete
                        outlined
                        :items="marketList"
                        item-text="text"
                        item-value="value"
                        dense
                        :error="!!marketRequired"
                        :error-messages="marketRequired"
                        label="Market"
                        v-model="selectedIntMarketMarket"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-12">
                    <v-divider></v-divider>
                    <v-card-title>
                        Peaks Removal Degree 
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-2"
                                >
                                    fa-info-circle
                                </v-icon>
                            </template>
                            <span>
                                The peaks removal works by identifying the local maximum (left and right points are both smaller) and by replacing that value with the linear interpolation of the left and right points. 
                                <br>The process is iterated for peaks_removal_degree times by using as input the output of the previous iteration. 
                                <br>The result is the smoothing of the regions with peaks (events, festivity, ...).
                                <br>Each point here represents either a week or a month depending on tl_period. A single point is the mean of the prices of the entire week/month.
                            </span>
                        </v-tooltip>
                    </v-card-title>
                </v-col>
                <v-col class="col-12">
                    <v-slider
                        v-model="selectedPgPeaksRemovalDegree"
                        step="1"
                        max="6"
                        min="0"
                        thumb-label
                        ticks
                    >
                        <template v-slot:prepend>
                            <div style="width: 200px;">
                                PG
                            </div>
                        </template>
                        <template v-slot:append>
                            {{ selectedPgPeaksRemovalDegree }}
                        </template>
                    </v-slider>
                </v-col>
                <v-col class="col-12">
                    <v-slider
                        v-model="selectedInternalMarketPeaksRemovalDegree"
                        step="1"
                        max="6"
                        min="0"
                        thumb-label
                        ticks
                    >
                        <template v-slot:prepend>
                            <div style="width: 200px;">
                                Internal Market
                            </div>
                        </template>
                        <template v-slot:append>
                            {{ selectedInternalMarketPeaksRemovalDegree }}
                        </template>
                    </v-slider>
                </v-col>
                <v-col class="col-12">
                    <v-slider
                        v-model="selectedExternalMarketPeaksRemovalDegree"
                        step="1"
                        max="6"
                        min="0"
                        thumb-label
                        :disabled="!enableExternalMarket"
                        ticks
                    >
                        <template v-slot:prepend>
                            <div style="width: 200px;">
                                External Market
                            </div>
                        </template>
                        <template v-slot:append>
                            {{ selectedExternalMarketPeaksRemovalDegree }}
                        </template>
                    </v-slider>
                </v-col>

                <v-col class="col-12">
                    <v-divider></v-divider>
                    <v-card-title>Global Weights</v-card-title>
                </v-col>
                <v-col class="col-12">
                    <v-slider
                        v-model="selectedGlobalPgWeight"
                        step="0.1"
                        max="1"
                        min="0"
                        :error="!!sumGlobalWeightsMustBeOne"
                        :error-messages="sumGlobalWeightsMustBeOne"
                        thumb-label
                        ticks
                    >
                        <template v-slot:prepend>
                            <div style="width: 200px;">
                                Global PG weight
                            </div>
                        </template>
                        <template v-slot:append>
                            {{ selectedGlobalPgWeight }}
                        </template>
                    </v-slider>
                </v-col>
                <v-col class="col-12">
                    <v-slider
                        v-model="selectedGlobalInternalMarketWeight"
                        step="0.1"
                        max="1"
                        min="0"
                        thumb-label
                        :error="!!sumGlobalWeightsMustBeOne"
                        :error-messages="sumGlobalWeightsMustBeOne"
                        ticks
                    >
                        <template v-slot:prepend>
                            <div style="width: 200px;">
                                Global InternalMarket weight
                            </div>
                        </template>
                        <template v-slot:append>
                            {{ selectedGlobalInternalMarketWeight }}
                        </template>
                    </v-slider>
                </v-col>
                <v-col class="col-12">
                    <v-slider
                        v-model="selectedGlobalExternalMarketWeight"
                        step="0.1"
                        max="1"
                        :disabled="!enableExternalMarket"
                        min="0"
                        :error="!!sumGlobalWeightsMustBeOne"
                        :error-messages="sumGlobalWeightsMustBeOne"
                        thumb-label
                        ticks
                    >
                        <template v-slot:prepend>
                            <div style="width: 200px;">
                                Global ExternalMarket weight
                            </div>
                        </template>
                        <template v-slot:append>
                            {{ selectedGlobalExternalMarketWeight }}
                        </template>
                    </v-slider>
                </v-col>
                <v-col class="col-12">
                    <v-card elevation="0">
                        <v-card-title>Local Mix</v-card-title>
                        <v-card-actions>
                            <v-btn
                                @click="addTrendlineMix"
                            >Add Trendline Mix</v-btn>
                        </v-card-actions>
                        <v-card-text>
                            <v-row>
                                <v-col class="col-12" v-for="(localMix, index) in localTrendlineMixList" :key="index">
                                    <BasePriceTrendlineMix :trendlineMix="localMix" :index="index" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col class="col-12">
                    <v-btn
                    @click="submitGeneration"
                    color="primary"
                    class="mr-4"
                    >Generate Base Price</v-btn>
                    <v-btn
                    @click="getBasePrice({psId: currentStrategyPS.metadata.id})"
                    color="gray"
                    >Reset to Current Base Price</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-card-text>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BasePriceTrendlineMix from './BasePriceTrendlineMix.vue'

export default {
    components: {BasePriceTrendlineMix},
    data(){
        return {
            daysOfTheWeekList: [
                {text: "Monday", value: 0},
                {text: "Tuesday", value: 1},
                {text: "Wednesday", value: 2},
                {text: "Thursday", value: 3},
                {text: "Friday", value: 4},
                {text: "Saturday", value: 5},
                {text: "Sunday", value: 6}
            ],

            periodList: [
                {text: "Week", value: "s_week"},
                {text: "Month", value: "s_month"},
            ],

            rules: {
                isNumber: value => !isNaN(value) || "Value must be a number",
                isOdd: value => value%2 !== 0 || "Value must be an odd number",
                isAboveZero: value => (value > 0 || value === null) || "Value must be above zero",
                required: value => !!value || "Value required!",
            },

            smoothingsList: [
                {text: "Week", value: "tl_smooth_week"},
                {text: "Week Peak", value: "tl_smooth_week_peaks"},
                {text: "Season", value: "tl_smooth_season"},
                {text: "Null", value: null},
            ],

            validForm: true,
        }
    },
    computed: {
        ...mapState({
            enableExternalMarket: state => state.core.currentPG.spec.booking_id,
            currentStrategyPS: state => state.core.currentStrategyPS,
            marketList: state => state.basePrice.marketList,
            localTrendlineMixList: state => state.basePrice.selectedTrendlineValues.trendlineLocalMixList,
        }),
        ...mapGetters({
            propertiesList: "basePrice/allowedBasePricePropertiesIds",
            allPropertyNamesById: "core/allPropertyNamesById", 
        }),
        selectedBasePriceMap() {
            return (id) => {
                return this.$store.state.basePrice.selectedTrendlineValues.basePriceMap[id] || null
            }
        },
        selectedDowStartDay: {
            get() {
                return this.$store.state.basePrice.selectedTrendlineValues.dowStartDay
            },
            set(value) {
                this.updateSelectedTrendlineValues({key: "dowStartDay", value: value})
            }
        },
        selectedSmoothing: {
            get() {
                return this.$store.state.basePrice.selectedTrendlineValues.smoothing
            },
            set(value) {
                this.updateSelectedTrendlineValues({key: "smoothing", value: value})
            }
        },
        selectedPeriod: {
            get() {
                return this.$store.state.basePrice.selectedTrendlineValues.period
            },
            set(value) {
                this.updateSelectedTrendlineValues({key: "period", value: value})
            }
        },
        selectedPgPeaksRemovalDegree: {
            get() {
                return this.$store.state.basePrice.selectedTrendlineValues.pgPeaksRemovalDegree
            },
            set(value) {
                this.updateSelectedTrendlineValues({key: "pgPeaksRemovalDegree", value: value})
            }
        },
        selectedInternalMarketPeaksRemovalDegree: {
            get() {
                return this.$store.state.basePrice.selectedTrendlineValues.internalMarketPeaksRemovalDegree
            },
            set(value) {
                this.updateSelectedTrendlineValues({key: "internalMarketPeaksRemovalDegree", value: value})
            }
        },
        selectedExternalMarketPeaksRemovalDegree: {
            get() {
                return this.$store.state.basePrice.selectedTrendlineValues.externalMarketPeaksRemovalDegree
            },
            set(value) {
                this.updateSelectedTrendlineValues({key: "externalMarketPeaksRemovalDegree", value: value})
            }
        },
        selectedIntMarketMarket: {
            get() {
                return this.$store.state.basePrice.selectedTrendlineValues.internalMarketId
            },
            set(value) {
                this.updateSelectedTrendlineValues({key: "internalMarketId", value: value})
            }
        },
        selectedGlobalPgWeight: {
            get(){
                return this.$store.state.basePrice.selectedTrendlineValues.globalPgWeight
            },
            set(value) {
                this.updateSelectedTrendlineValues({key: "globalPgWeight", value: value})
            }
        },
        selectedGlobalInternalMarketWeight: {
            get(){
                return this.$store.state.basePrice.selectedTrendlineValues.globalInternalMarketWeight
            },
            set(value) {
                this.updateSelectedTrendlineValues({key: "globalInternalMarketWeight", value: value})
            }
        },
        selectedGlobalExternalMarketWeight: {
            get(){
                return this.$store.state.basePrice.selectedTrendlineValues.globalExternalMarketWeight
            },
            set(value) {
                this.updateSelectedTrendlineValues({key: "globalExternalMarketWeight", value: value})
            }
        },
        sumGlobalWeightsMustBeOne() {
            return this.selectedGlobalWeightSum === 1 ? "" : `The sum of the global weights must be 1 , current sum is ${this.selectedGlobalWeightSum}`
        },
        marketRequired() {
            return this.selectedIntMarketMarket ? "" : "Market Required" 
        },
        selectedGlobalWeightSum() {
            const sum = this.selectedGlobalPgWeight + 
            this.selectedGlobalInternalMarketWeight + 
            this.selectedGlobalExternalMarketWeight

            return Math.round(sum * 100) / 100
        },
    },
    methods: {
        ...mapMutations({
            updateSelectedTrendlineValues: "basePrice/updateSelectedTrendlineValues",
            updateSelectedTrendlineBasePriceMapValues: "basePrice/updateSelectedTrendlineBasePriceMapValues",
            addTrendlineMix: "basePrice/addTrendlineMix"
        }),
        ...mapActions({
            getMarkets: "basePrice/getMarkets",
            generateBasePrice: "basePrice/generateBasePrice",
            getBasePrice: "basePrice/getBasePrice",
        }),
        submitGeneration(){
            this.$refs.form.validate()
      
            if (this.validForm) {
                this.generateBasePrice({psId: this.currentStrategyPS.metadata.id})
            }
        },
        updateBasePriceMap(id, value) {
            this.updateSelectedTrendlineBasePriceMapValues({key: id, value: Number(value)})
        }
    },
    created() {
        this.getMarkets()
    }
}
</script>