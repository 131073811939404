/**
 * Store for auth status
 */ 
const SpApiClient = require('@dev.smartpricing/sp-api-client')
const Config = require('../const')

export default {
  namespaced: true,

  state: {
    api: null,
    isAuth: false,
    username: null,
    name: null,
    accessToken: null
  },

  mutations: {
    setAuthProperties(state, properties) {
      Object.keys(properties).forEach((prop) => {
        state[prop] = properties[prop]
      })
    }
  },

  getters: {
    userRoles(state) {
      const RolesAndIndividualsCurrentMap = Object.entries(Config.ROLES_MAP).filter(([role, individuals]) => {
        return individuals.includes(state.username)
      })

      const userRoles = Object.keys(Object.fromEntries(RolesAndIndividualsCurrentMap))

      return userRoles?.length !== 0 ? userRoles : ["guest.all"]
    }
  },

  actions: {
    async installClient(){
      var apiUrl = 'https://accounts.google.com/gsi/client'
      return new Promise((resolve) => {
        var script = document.createElement('script')
        script.src = apiUrl
        script.onreadystatechange = script.onload = function () {
          if (!script.readyState || /loaded|complete/.test(script.readyState)) {
            setTimeout(function () {
              resolve()
            }, 500)
          }
        }
        document.getElementsByTagName('head')[0].appendChild(script)
      })
    },

    async Auth() {
      if (!(this instanceof Auth))
        return new Auth()
      this.GoogleAuth = null /* window.gapi.auth2.getAuthInstance() */
      this.isAuthorized = false
      this.isInit = false
      this.prompt = null
      this.isLoaded = function () {
        /* eslint-disable */
        console.warn('isLoaded() will be deprecated. You can use "this.$gAuth.isInit"')
        return !!this.GoogleAuth
      };
  
      this.load = (config, prompt) => {
        installClient()
          .then(() => {
            return initClient(config)
          })
        }
    },

    

    async loginWithGoogle (context, args) {
      try { 
        
        const token = args.gAuth.credential

        context.state.api = require('@dev.smartpricing/sp-api-client')({
          apiEndpoint: Config.API_ENDPOINT(),
          accessToken: token,
          eventEmitter: null            
        })

        //await SpApiClient.restClient.discovery(context.state.api)
        const authRes = await context.state.api.get('auth/v1', '/google', {}, {}, {})

        if (authRes.status == "found"
          && authRes.items.length == 1 
          && authRes.items[0].authenticated == true) {
          context.state.username = authRes.items[0].username
          context.state.name = authRes.items[0].name
          context.state.isAuth = true
          context.state.accessToken = authRes.items[0].token
          if (args.cb !== null && typeof args.cb == 'function') {
            args.cb({
              auth: true,
              accessToken: authRes.items[0].token,
              username: authRes.items[0].username
            })
          }
        }
      } catch (error) {
        console.log('error', error)
        //self.authenticationError = true
      }
    }
  }

}
