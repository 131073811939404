<template>
    <div id="map"></div>
</template>

<script>
import L from "leaflet"
import "leaflet.markercluster"

export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        mapOptions: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            map: null,

            polygons: [],

            typeToParsingFunction: {
                marker: function (objInfo) {
                    const icon = L.divIcon({
                        html: `<svg width="27" height="35" viewBox="0 0 1024 1024" class="icon" xmlns="http://www.w3.org/2000/svg"><path d="M512 85.333c-164.95 0-298.667 133.739-298.667 298.667C213.333 548.95 512 938.667 512 938.667S810.667 548.949 810.667 384c0-164.928-133.718-298.667-298.667-298.667zm0 448a149.333 149.333 0 1 1 0-298.666 149.333 149.333 0 0 1 0 298.666z" fill="${objInfo?.color || '#0e60e6'}"/></svg>`,
                        iconAnchor: [13.5, 30],
                        popupAnchor: [0, -25],
                        shadowAnchor: [13.5, 35],
                    })

                    let marker = L.marker(objInfo?.coordinates, {
                        icon: icon,
                    })

                    marker.bindPopup(objInfo?.popupHtml)
                    
                    return marker
                },
                cricle: function(objInfo) {
                    // coming soon
                },
                polygon: function(objInfo) {
                    // coming soon
                },
            }
        }
    },
    watch: {
        data: {
            deep: true,
            handler: function(newVal, oldVal) {
                this.recalculateMap()
            }
        }
    },
    methods: {
        deleteOldMap() { // deletes the old map
            this.polygons.forEach(poly => {
                this.map.removeLayer(poly)
            })
        },
        createNewMap() { // recalculates the new map markers
            const newMarkers = []

            this.data.forEach(poly => {
                const parsingFunc = this.typeToParsingFunction[poly.type]
                const marker = parsingFunc(poly)

                newMarkers.push(marker)
            })

            return newMarkers
        },

        recalculateMap() {
            this.deleteOldMap()

            if (!!this.mapOptions?.clustering) {
                let markers = L.markerClusterGroup()
    
                this.createNewMap().forEach(mark => {
                    markers.addLayer(mark)
                    this.polygons.push(mark)
                })
    
                this.map.addLayer(markers)
            } else {
                this.createNewMap().forEach(mark => {
                    this.map.addLayer(mark)
                    this.polygons.push(mark)
                })
            }

        },
    },
    mounted() {
        const startingView = this.mapOptions?.startingView || [44.6471, 10.9252]
        const startingZoomLevel = this.mapOptions?.startingZoomLevel || 5

        this.map = L.map("map").setView(startingView, startingZoomLevel);

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(this.map);

        this.recalculateMap()
    }
}
</script>

<style>
@import "https://leaflet.github.io/Leaflet.markercluster/dist/MarkerCluster.css";
@import "https://leaflet.github.io/Leaflet.markercluster/dist/MarkerCluster.Default.css";

#map {
    height: 100%;
    width: 100%;
    z-index: 0;
}

.leaflet-div-icon {
    background: none;
    border: none
}
</style>


/* 
    {
        type: "marker",
        popupHtml: "<b>Hello world!</b><br>I am a popup.",
        color: "red",
        coordinates: [51.509, -0.08], // lat and long arr
    }
    
    {
        type: "circle",
        popupHtml: "<b>Hello world!</b><br>I am a popup.",
        color: "red",
        fillColor: "@f03",
        fillOpacity: 0.5,
        radius: 500,
        coordinates: [], // lat and long arr
    }
*/