<template>
  <v-container fluid class="pa-4">

    <div v-if="this.$store.state.core.currentPG">
      <v-card class="mx-auto mb-4 ml-2">
        <v-card-title>Respondent's email and answers codes</v-card-title>
        <v-row class="mb-2 ml-2">
          <v-col class="col-5">
            <v-text-field
              hint="Paste TypeForm row"
              v-model="typeFormAnswers"
              dense
            ></v-text-field>
          </v-col>
          <v-col class="col-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn class="elevation-0" color="primary" :disabled="!typeFormAnswers" @click="savePGConfig">SUBMIT</v-btn>  
              </template>
              <span>Submit form answers in the form of email and letters</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
  
      <div>
        <v-card class="mx-auto mb-4 ml-2 mr-2">
          <v-card-title class="mb-3">Summary</v-card-title>
          <v-card-text>
            <v-row
              class="mx-0"
            > 
              <v-row>
                <v-col class="col-2">
                  <div class="text-subtitle-1 flex mb-4">
                    <v-col class="col-12">
                        <v-row>
                          <span><b>Total score:&nbsp;</b>{{ getTypeFormView.score }}&nbsp;&nbsp;</span>    
                        <v-rating
                          :value="getTypeFormView.score"
                          color="amber"
                          dense
                          half-increments
                          readonly
                          size="14"
                        ></v-rating>
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
                <v-col class="col-2">
                  <div class="text-subtitle-1 flex mb-4">
                    <v-col class="col-12">
                      <v-row>
                        <span><b>Customer bar loaded:&nbsp;</b> {{getTypeFormView.bar_loaded}}</span>    
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
                <v-col class="col-2">
                  <div class="text-subtitle-1 flex mb-4">
                    <v-col class="col-12">
                      <v-row>
                        <span><b>Trendline:&nbsp;</b> {{getTypeFormView.trendline}}</span>    
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
                <v-col class="col-5">
                  <div class="text-subtitle-1 flex mb-4">
                    <v-col class="col-12">
                      <v-row>
                        <span><b>Respondent's email:&nbsp; </b> <a v-bind:href="'Email:' + getTypeFormView.email">{{ getTypeFormView.email }}</a></span>    
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
  
        <v-card class="mx-auto mb-4 ml-2 mr-2">
          <v-card-title class="mb-3">Scores by category</v-card-title>
          <v-card-text>
              <v-row
                class="mx-0"
              > 
              <v-row></v-row>
                <v-row v-for="(label, index) in getTypeFormView.categories_label">
                  <div class="my-4 text-subtitle-1 flex mb-1 ma-3">
                    <v-col class="col-12">
                      {{label}}                
                    <v-rating
                      :value="getTypeFormView.categories[label]"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="14"
                    ></v-rating>
                      <div class="grey--text ms-4">
                        {{ getTypeFormView.categories[label] }}
                      </div>
                    </v-col>
                  </div>
                </v-row>
            </v-row>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
        </v-card>
  
        <v-card class="mx-auto ml-2 mr-2">
          <v-card-title>Questions & Answers</v-card-title>
        </v-card>
        <v-card v-for="(question, index) in getTypeFormView.questions" class="mx-auto ml-2 mr-2">
          <v-card-text>
            <v-row
              class="mx-0"
            >
              
              <v-col class="12">
                <div class="my-4 text-subtitle-1"><b>Category: </b>{{ question.category }}</div>
              </v-col>
              <v-col class="12">
                <div class="my-4 text-subtitle-1"><p><b>Q:</b> {{ question.question }}</p></div>
              </v-col>
              <v-col class="12">
                <div class="my-4 text-subtitle-1"><p><b>A:</b> {{ question.answer.text }}</p></div>
              </v-col>
            </v-row>
            <v-row
              class="mx-0 mb-2"
            >           
              <div class="ms-4"><b>Score:&nbsp;</b> </div>
              <v-rating
                :value="question.answer.score"
                color="amber"
                dense
                half-increments
                readonly
                size="14"
              ></v-rating>
              <div class="grey--text ms-4">
                {{ question.answer.score }}
              </div>
              <div>
                &emsp;&emsp;<b>Strategy account:</b> {{ question.answer.strategyAccount }}
              </div>
            </v-row>
          </v-card-text>
          <v-divider class="mx-4 mb-4"></v-divider>
        </v-card>
      </div>    
    </div>

    <SelectPgAlert v-else></SelectPgAlert>
  </v-container>
</template>

<script>
import SelectPgAlert from '@/components/utils/SelectPgAlert'
import typeformTemplate from "@/assets/typeform_questions.json";

export default {
  name: 'Typeform',
  components: {SelectPgAlert},
  data() {
    return {
      currentPGData: null,
      typeFormView: null,
      header: [],
      reload: 0,

    }
  },
  methods: {
    async savePGConfig () {
      var tform = this.$store.state.typeForm.typeformAnswers; 
      tform = Object.values(tform);
      var tformTrim = {};
      //removes all trailing chars
      let numberOfQuestions = typeformTemplate.questions.length
      for ( var i = 0 ; i < numberOfQuestions; i++ ){ //TODO keep consistent with form length
        if(tform[i]!==undefined){
          tformTrim[i] = tform[i]; 
        }

      }
      
      const currentPG = structuredClone(this.$store.state.core.currentPG)
      currentPG.spec.typeform = { typeform: tformTrim, insertedAt: new Date() }
      this.$store.dispatch('core/updatePG', {config: currentPG});

      this.loadTypeFormView(this.$store.state.core.currentPG);
    },

    loadTypeFormView(propertyGroup){

      if(propertyGroup === undefined || propertyGroup === null){
        propertyGroup = this.$store.state.core.currentPG;
      }

      this.header = [];
      var typeform = propertyGroup.spec.typeform;
      if(typeform != null){
        this.typeFormView = this.$store.dispatch('typeForm/computeViewScores', {
                                                                'typeform': typeform,
                                                              });
        let questions = this.$store.state.typeForm.typeFormView.questions;
        for (var question of questions){
          this.header.push(question.question);
        }
      }else{
        this.$store.dispatch('typeForm/cleanupTypeForm', {});
      }

    },

  }, //end methods
  watch: {
    '$store.state.core.currentPG' (newVal, oldVal) {
      this.loadTypeFormView(null);

      //this.reload+=1;
    },

  },

  computed: {
    getTypeFormView(){
      return this.$store.state.typeForm.typeFormView;
    },

    typeFormAnswers:

    {
      get(){
        var answers = "";
        for (var a of this.$store.state.typeForm.typeformAnswers){
          answers+=a + '\t';
        }

        return answers.trimEnd();
      },
      set(answers){
        this.$store.state.typeForm.typeformAnswers = [];
        answers = answers.trimEnd();
        this.$store.state.typeForm.typeformAnswers = answers.split("\t");        
      }
    },

  },

  mounted(){
    this.loadTypeFormView(this.$store.state.core.currentPG);
  },
  unmounted(){
    //this.cleanupTypeForm();
  }

}
</script>
