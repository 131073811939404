function dateRangeOverlaps(a_start, a_end, b_start, b_end) {
    /*
        Checks if two dates overlap. Function arguments are all Date objects.
    */
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end   && b_end   <= a_end) return true; // b ends in a
    if (b_start <  a_start && a_end   <  b_end) return true; // a in b
    return false;
}

function makeDateObject(date){
    /*
        Creates a Date object from a date object (date = {month: ., day: , year: .}), if year not present
        sets it to 1000 ad.
        Returns the Date object and its string representation.
    */
    var date_string = date.month.toString() + "/" +  date.day.toString()
    if(date.year){
      date_string = `${date_string}/${date.year.toString()}`
    } else {
      date_string = `${date_string}/1000`
    }
    var date_obj = new Date(date_string);
    return {'date': date_obj, 'date_string': date_string};
}

export function datesAreCompleted(start, end) {
    var completed = false;
    if(start.day != null && end.day != null && start.month != null && end.month != null){
        completed  = true;
    }

    let sYear = null;
    let eYear = null;

    if(start.year == null && end.year != null){
        completed = false;
        eYear = end.year.length;
    }
    if(start.year != null && end.year == null){
        completed = false;
        sYear = start.year.length;
    }

    if(completed == false && sYear == 0 && eYear == 0){
        completed = true;
    }

    return completed;
}

export function datesAreEmpty(start, end){
    var empty = false;

    if(start.day == null && end.day == null && start.month == null && end.month == null){
        empty  = true;
    }
    return empty;
}

export function validateDateRange(value){
  try {
    let dateArr = value
    if(typeof dateArr === 'string'){
      dateArr = dateArr.split(" - ")
    }
    dateArr
      .filter(v => v !== "")
      .forEach(d => {
        if(isNaN(Date.parse(d))){
          throw new Error(`[${d}] is not a Date`)
        }
      })
    return true
  } catch (error) {
    return `Invalid date range format: ${error}`
  }
}

export function validDateFormat(date){
  /*
  Checks if a date is in valid format
  */
  var valid = true;
  var message = '';

  var date_obj_string = makeDateObject(date);
  var date_obj = date_obj_string.date;
  var date_string = date_obj_string.date_string;

  if(date_obj  == 'Invalid Date'){
    valid = false;
    date_obj = null;
    message = date_string + " is not a valid format. "
  }

  return {'valid': valid, 'message': message, 'date_string': date_string, 'date': date_obj};
}

export function validateMapDateRanges(maps){
    // maps are an array of "view maps" i.e. start/end dates are objects
    var valid = {'result': false, 'message': ''};
    let date_ranges = [];
    var errors = [];
    let date_objects = {};
    if(maps.length > 0){
        for(var i=0; i<maps.length; i++){
            let map = maps[i];
            let start = map.date_range.start_date;
            let end = map.date_range.end_date;

            let start_valid = validDateFormat(start);
            let end_valid = validDateFormat(end);
            var msg = ' (row ' + i+1 +')';
            if(end_valid.valid == false){
                msg = end_valid.message + msg
            }

            if(start_valid.valid == false){
                msg = start_valid.message + msg;
            }

            if(msg.length < 10){

                if(start_valid.date >= end_valid.date){
                  msg = "Start date must be earlier than end date " + msg;
                }
            }

            if(msg.length > 10){
                errors.push(msg);
            }
            else{
              date_objects[i+1] = {'start': start_valid, 'end': end_valid, "type": map.type}; //start and end dates are valid and mutually consistent

            }
            
        }

      //test between maps overlapping
      let overlaps = {}; //keeps tracks of the overlaps, otherwise we'd get double messages,
      for (const [row, date_range] of Object.entries(date_objects)) {

        for (const [row_in, date_range_in] of Object.entries(date_objects)) {

          if(overlaps.hasOwnProperty(row) == false && overlaps.hasOwnProperty(row_in) == false){

            if(row != row_in){
              let overlap = false
              if (date_range.type === date_range_in.type) {
                overlap = dateRangeOverlaps(date_range.start.date, date_range.end.date, date_range_in.start.date, date_range_in.end.date);
              }
              if(overlap == true){

                overlaps[row] = row_in;
                overlaps[row_in] = row;
                let message = "Date range on row " + row.toString() + " and date range on row " + row_in.toString() + " overlap. ";
                errors.push(message);

              }
            }
          }
        }
      }

    }

    if(errors.length > 0){
        valid.message = errors.toString().replace('[', '').replace(']', ' ');
    }
    if(errors.length == 0){
        valid.result = true;
    }

    return valid;
}
