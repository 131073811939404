export default {
    namespaced: true,

    state: {
        galiaitoken: "",
    },

    mutations: {
        setGaliAiToken(state, payload) {
            state.galiaitoken = payload.token
        },
    },

    actions: {
        async fetchGali(context, payload) {
            const api = context.rootState.api.api

            try {

                const res = await api.get("token/v1", `/galiai`, {}, {}, {})
                context.commit("setGaliAiToken", {token: res.text})

            } catch (error) {
                context.dispatch("alertSystem/newError", {
                    message: `Error getting galiai token: ${error}`
                }, {root: true})
            }
        }

    }
}


