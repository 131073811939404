<template>
    <v-card class="card-ui pa-4">
        <v-card-title>Delete Reservations</v-card-title>
        <v-card-text>
            <v-alert v-if="shouldShowDataLostWarning" type="warning">
                If you delete reservations for this property group, you will lose its corresponding data and you'll need to
                download it again from the "Raw reservtions" app.
            </v-alert>
            <v-row>
                <v-col class="col-4">
                    <v-autocomplete multiple label="Properties" :items="availableProperties" :item-text="i => i.label"
                        :item-value="i => i.id" v-model="selectedProperties">
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                                <span>{{ item.label }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                                (+{{ selectedProperties.length - 1 }} others)
                            </span>
                        </template>

                        <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="selectAllProperties">
                                <v-list-item-action>
                                    <v-icon dense :color="selectedProperties.length > 0 ? 'indigo darken-4' : ''">
                                        {{ icon }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Select All
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <!--v-col class="col-1"-->
            <v-row class="px-4">
                <v-col class="col-3">
                    <v-switch label="Date Range" class="ma-0" dense v-model="withDateRange"></v-switch>
                </v-col>
                <v-col class="col-7">
                    <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateRange" label="Delete reservations with checkin date in this range"
                                prepend-icon="mdi-calendar" outlined :disabled="!withDateRange" readonly dense
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateRange" range @change="dateMenu = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <div v-if="reservationCommunicationList !== undefined">
                <v-card-text class="pa-8 red--text" v-if="reservationCommunicationList[pgInfo.spec.pms_name] === 'push'">
                    The PMS of this PropertyGroup doesn't support reservations reimport on demand
                </v-card-text>
            </div>

        </v-card-text>
        <v-card-text class="pa-8 red--text" v-if="stateAllowSend == true">
            The Lifecycle state of this PropertyGroup allows the send of the prices.
        </v-card-text>

        <v-card-actions>
            <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" v-bind="attrs" v-on="on"
                        :disabled="withDateRange ? !(dateRange.length >= 2) : false">Delete Reservations</v-btn>
                </template>
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar color="red" dark>Delete the reservations</v-toolbar>

                        <v-card-text class="pa-8">
                            If you proceed, the published price might change considerably; please consider disabling the
                            price sending before proceeding.
                        </v-card-text>

                        <v-card-text v-if="shouldShowDataLostWarning" class="pb-8 px-8">Also remember that for this property
                            group, data for the deleted reservations will be lost and you will be required to download it
                            again from the "Raw reservations" app.
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn color="red"
                                @click="[deleteReservations({ pgId: pgInfo.metadata.id, startDate: finalObj.startDate, endDate: finalObj.endDate, properties: selectedProperties }), dialog.value = false]">Delete
                                Reservations</v-btn>
                            <v-btn text color="red" @click="dialog.value = false">
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>

        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            selectedProperties: [],
            withDateRange: false,
            dateMenu: false,
            dateRange: [],
        }
    },
    computed: {
        ...mapState({
            reservationCommunicationList: state => state.gpcs.reservationsCommunicationList,
            reservationsAckPartners: state => state.gpcs.reservationsAckPartners,
            pgInfo: state => state.core.currentPG,
        }),
        ...mapGetters({
            reservationsPushPartners: "gpcs/getReservationPullPartners",
        }),
        finalObj() {
            if (!this.withDateRange) {
                return {
                    startDate: "-",
                    endDate: "-",
                }
            }
            return {
                startDate: this.dateRange[0] < this.dateRange[1] ? this.dateRange[0] : this.dateRange[1],
                endDate: this.dateRange[0] > this.dateRange[1] ? this.dateRange[0] : this.dateRange[1],
            }
        },
        availableProperties() {
            return this.$store.state.core.currentProperties.map(a => { return { 'id': a.metadata.id, 'label': `${a.metadata.id} - ${a.metadata.name}` } })
        },
        icon() {
            if (this.selectedProperties.length === this.availableProperties.length) return 'fa-light fa-times-square'
            if (this.selectedProperties.length !== this.availableProperties.length && this.selectedProperties.length > 0) return 'fa-regular fa-minus-square'
            return 'fa-regular fa-square-full'
        },
        stateAllowSend() {
            return this.$store.state.core?.pgCurrentState?.spec?.fields?.send?.price?.cron == true
        },
        shouldShowDataLostWarning() {
            return this.reservationsAckPartners.includes(this.pgInfo.spec.pms_name) && this.reservationsPushPartners.includes(this.pgInfo.spec.pms_name)
        }
    },
    methods: {
        ...mapActions({
            deleteReservations: "gpcs/deleteReservations",
        }),
        selectAllProperties() {
            this.$nextTick(() => {
                if (this.selectedProperties.length === this.availableProperties.length) {
                    this.selectedProperties = []
                } else {
                    this.selectedProperties = this.availableProperties.map(prop => prop.id)
                }
            })
        },
    },
    async created() {
        await this.$store.dispatch("gpcs/fetchReservationAckPartners")
    },
}
</script>
