/**
 * Store for auth status
 */ 

const EventEmitter = require('events')
const eventEmitter = new EventEmitter()
const Config = require('../const')

export default {
  namespaced: true,

  state: {
    api: null,
    apiEndpoint: Config.API_ENDPOINT(),
    runningAPIRequest: true,
  },

  mutations: {},

  actions: {
    async makeAPIClient (context, args) {
      eventEmitter.on('start-api-request', () => {
        context.state.runningAPIRequest = true
      })      
      eventEmitter.on('end-api-request', (data) => {
        context.state.runningAPIRequest = false
        if (data.response !== undefined && data.response !== null && data.response.status == 500) {
          context.rootState.ui.restErrors.push({
            status: data.response.status,
            statusText: data.response.statusText,
            responseURL: data.request.responseURL
          })
        }
      })            
      context.state.api = require('@dev.smartpricing/sp-api-client')({
        apiEndpoint: Config.API_ENDPOINT(),
        accessToken: args.accessToken,
        eventEmitter: eventEmitter            
      })
      if (args.cb !== undefined && args.cb !== null) {
        args.cb()
      }
    }    
  }

}
