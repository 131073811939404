<template>
    <v-card class="card-ui pa-4">
        <v-card-title>Reset Sysdat Ack</v-card-title>
        <v-card-subtitle v-if="currentPG.spec.pms_name === 'sysdat'">This app allows you to redownload the reservations for a PG with Sysdat as PMS</v-card-subtitle>
        <v-card-subtitle class="red--text" v-else>The selected pg doesn't support this feature</v-card-subtitle>

        <v-card-actions v-if="currentPG.spec.pms_name === 'sysdat'">
            <v-btn
                color="primary"
                @click="resetAck()"
            >Reset Sysdat Ack</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            currentPG: state => state.core.currentPG,
            api: state => state.api.api,
        })
    },
    methods: {
        ...mapActions({
            newError: "alertSystem/newError",
            newSuccess: "alertSystem/newSuccess",
        }),
        async resetAck() {
            try {
                const res = await this.api.get("integrations/v2", `/sysdat/resetack/${this.currentPG.metadata.id}`, {}, {}, {})

                this.newSuccess({
                    message: "Ack resetted successfully"
                })
            } catch (error) {
                this.newError({
                    message: "Error resetting the ack for selected pg"
                })
            }
        }
    }
}
</script>