<template>
<v-row>
    <v-col class="col col-12">
        <v-card-title>Expert User Params</v-card-title>
        <v-simple-table dark class="card-ui elevation-1">
            <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        Average Price
                    </th>
                    <th class="text-left">
                        Price Decrease
                    </th>
                    <th class="text-left">
                        Price Increase
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="withIntervals">
                        <td>{{ customerStratData.intervals[0].averagePrice }}</td>
                        <td>{{ customerStratData.intervals[0].priceDecrease }}</td>
                        <td>{{ customerStratData.intervals[0].priceIncrease }}</td>
                    </tr>
                    <tr v-else>
                        <td>{{ customerStratData.averagePrice }}</td>
                        <td>{{ customerStratData.priceDecrease }}</td>
                        <td>{{ customerStratData.priceIncrease }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-col>
    <v-divider v-show="withIntervals"></v-divider>
    <v-col v-if="withIntervals" class="col col-12">
        <v-card-title>Expert User Params with Intervals</v-card-title>
        <v-data-table
            :headers="headersWithIntervals"
            :items="customerStratIntervals"
            :items-per-page="5"
            class="elevation-1 card-ui"
        ></v-data-table>
    </v-col>
</v-row>
</template>

<script>
    export default {
        props: ["customerStratData"],
        data() {
            return {
                withIntervals: false,

                headersWithIntervals: [
                    { text: 'From', value: 'fromDate', width:"3%" },
                    { text: 'To', value: 'toDate', width:"3%"  },
                    { text: 'Average Price', value: 'averagePrice', width:"1%"  },
                    { text: 'Price Decrease', value: 'priceDecrease', width:"1%"  },
                    { text: 'Price Increase', value: 'priceIncrease', width:"1%"  },
                ],
            }
        },
        computed: {
            customerStratIntervals() {
                if (this.withIntervals) {
                    return this.customerStratData.intervals.filter(value => {
                        return "fromDate" in value
                    })
                }
                return null
            }
        },
        created() {
            // check if there's an interval with dates or not
            if ("intervals" in this.customerStratData){
                this.withIntervals = true
            }

        }
    }
</script>