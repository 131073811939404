<template>
  <v-container fluid class="pa-4">
    <v-card class="mt-0 card-ui" v-if="!!globalSelectedPgId">
      <v-card-text class="pt-12">
        <v-row class="">
          <v-col class="col-2">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDate"
                  label="Log date"
                  readonly
                  dense
                  solo-inverted
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedDate"
                @input="menu2 = false"
                
                color="blue"
              ></v-date-picker>
            </v-menu>    
          </v-col>                
          <v-col class="col-10 pr-6 pa-0 pl-6 pt-3">
            <v-slider
              v-model="hourToShow"
              step="1"
              max="23"
              min="0"
              color="cyan"
              ticks="always"
              label="Logs for day hour"
              thumb-label="always"
              tick-size="4"
            ></v-slider>
          </v-col>
          <v-col class="col-9 pt-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="'Search in ' + allLogItems.length + ' rows'"
              solo-inverted
              dense
              class="mb-6 card-ui"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col class="col-3 pt-0">
            <v-btn
              color="red accent-3"
              style="width: 100%"
              @click="fetchNow"
              >Update Now</v-btn
            >
          </v-col>
          <v-col class="col col-6">
            <v-autocomplete
              dense
              outlined
              small-chips
              chips
              clearable
              label="Show logs by kind"
              multiple
              :items="logKindOptions"
              item-text="kind"
              item-value="kind"
              v-model="kindFilter"
              @change="changeKindFilter"
              deletable-chips
            ></v-autocomplete>
          </v-col>
          <v-col class="col col-6">
            <v-autocomplete
              dense
              outlined
              clearable
              chips
              small-chips
              label="Show logs by level"
              multiple
              :items="logLevelOptions"
              item-text="level"
              item-value="level"
              v-model="levelFilter"
              @change="changeLevelFilter"
              deletable-chips
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :items="allLogItems"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 100, 1000, 5000],
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
          }"
          class="elevation-0 card-ui"
          :loading="loadingData"
          loading-text="Loading... Please wait"
          @click:row="handleClick"
          style="cursor: pointer"
        >
          <template v-slot:item.insdate="{ item }">
            {{ item.insdate.split(".")[0].split("T")[1] }}
          </template>
          <template v-slot:item.level="{ item }">
            <v-chip
              
              :color="logLevelColor(item.level)"
            >
              {{ item.level }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
      <LogsDialog ref="logsDialog"/>
    </v-card>
    <SelectPgAlert v-else></SelectPgAlert>
  </v-container>
</template>

<script>
import Vue from 'vue'
import LogsDialog from  '../components/LogDialog.vue'
import { mapGetters, mapState } from 'vuex'

export default Vue.extend({
  name: 'SystemLogs',
  props: ['pgid'],
  components: {
    LogsDialog
  },
  data: function () {
    return {
      pg: '-',
      hourToShow: (new Date()).getHours(),
      loadingData: false,
      search: '',
      selectedPropertyGroup: { id: '-', name: '-' },
      selectedDate: (new Date()).toISOString().split('T')[0],
      logs: [],
      showLogs: [],
      kindFilterLocal: [],
      levelFilterLocal: [],
      kindFilterDiffLocal: new Set(),
      levelFilterDiffLocal: new Set(),
      menu2: false,
      showLogsChart: {},
      showLogsChartChartJs: {},
      headers: [
        {
          text: 'Insert Date',
          align: 'start',
          sortable: true,
          value: 'insdate',
        },
        { text: 'Level', value: 'level', sortable: true },
        { text: 'PS Kind', value: 'psKind' },
        { text: 'Property Set', value: 'psId' },
        { text: 'Kind', value: 'kind'},
        { text: 'PMS/CHM', value: 'pmsChm' },
        { text: 'Message', value: 'message' },
      ]
    }
  },
  computed: {
    ...mapState({
      darkMode: state => state.ui.darkMode
    }),
    ...mapGetters({
      globalSelectedPgId: "core/globalSelectedPgId",
    }),
    allLogItems() {
      return this.showLogs.filter(value => {
        return this.kindFilter.includes(value.kind) && this.levelFilter.includes(value.level)
      })
    },
    logKindOptions() {
      return Array.from(new Set(this.showLogs.map(value => {
        return value.kind
      })))
    },
    logLevelOptions() {
      return Array.from(new Set(this.showLogs.map(value => {
        return value.level
      })))
    },
    kindFilter: {
      get(){
        this.kindFilterLocal = this.setsDiff(this.logKindOptions, this.kindFilterDiffLocal)
        return this.kindFilterLocal
      },
      set(val){
        this.kindFilterLocal = val
      }
    },
    levelFilter: {
      get(){
        this.levelFilterLocal = this.setsDiff(this.logLevelOptions, this.levelFilterDiffLocal)
        return this.levelFilterLocal
      },
      set(val){
        this.levelFilterLocal = val
      }
    },

  },
  watch: {
    selectedDate (newVal, oldVal) {
      this.loadingData = true
      this.fetch()
    },
    globalSelectedPgId (newVal, oldVal) {
      this.loadingData = true
      if (!!newVal) {
        this.fetch()
      }
    },    
    hourToShow(newval, oldval) {
      this.loadingData = true
      this.fetch()
    },
    '$store.state.log.logs'(newval, oldval) {
      this.loadingData = false
      this.logs = newval
      this.showLogs = this.filterLogs()
      this.levelFilter = this.logLevelOptions
      let showLogsMap = {}
      this.showLogs.forEach((p) => {
        p.insdate = this.toISOLocal(new Date(p.insdate))
        p.pgName = this.$store.getters["core/pgNameById"][String(p.property_group)]
        p.pmsChm = p.pms_name + '/' + p.channel_manager_name
        let hour = parseInt(p.insdate.split('T')[1].split(':')[0])
        let minute = p.insdate.split('T')[1].split(':')[1]
        if (minute.toString().length == 1) {
          minute = '0' + minute
        }
        let key = hour + ':' + minute

        if (showLogsMap[key] == undefined) {
          showLogsMap[key] = 0
        }
        showLogsMap[key] += p.level == 'info' ? 0 : 1

        if (this.isJsonString(p.message)) {
          const messageParsed = JSON.parse(p.message)

          if (messageParsed.error) {
            p.message = messageParsed.error
          } else if (messageParsed.parameter_errors) {
            if (messageParsed.parameter_errors[0].message){
              p.message = messageParsed.parameter_errors[0].message
            }
          } 
        }

      })
      this.showLogsChart = showLogsMap
    }
  },
  methods: {
    isJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    },
    reset() {
      this.pg = '-'
      this.selectedPropertyGroup = { id: '-', name: '-' }
      this.selectedDate = (new Date()).toISOString().split('T')[0]
      this.hourToShow = (new Date()).getHours()
      this.fetch()
    },
    async fetch(forceCache = false) {      
      let d = new Date(Number(this.selectedDate.split('-')[0]), Number(this.selectedDate.split('-')[1]) - 1, Number(this.selectedDate.split('-')[2]), this.hourToShow)
      const utcHour = d.getUTCHours()
      const utcDate = d.toISOString().split('T')[0]

      this.$store.dispatch('log/systemlogs', { 
        date: utcDate, 
        selectedPropertyGroup: !this.globalSelectedPgId ? '-' : this.globalSelectedPgId, 
        hour: utcHour, 
        forceCache: forceCache 
      })

    },
    fetchNow() {
      this.fetch(true)
    },
    filterLogs() {
      let self = this
      let showLogs = this.logs.filter(function (l) {
        if (self.selectedDate !== null) {

          let split = self.selectedDate.split('-')

          if (l.date == self.selectedDate) {
            return true
          }

          return true

        } else {
          return true
        }
      })
      return showLogs
    },
    handleClick(row) {
      let logsDialog = this.$refs.logsDialog
      logsDialog.show(row)
    },
    logLevelColor(level) {
      switch (level) { 
        case "trace":
          return "grey darken-1"
        case "debug":
          return "amber darken-1"
        case "info":
          return "green accent-4"
        case "warning":
          return "red accent-4"
        case "error":
          return "red accent-4"
        case "fatal":
          return "red accent-4"
        default:
          return "grey darken-1"
      }
    },
    toISOLocal(d) {
      var z = n => ('0' + n).slice(-2);
      var zz = n => ('00' + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off > 0 ? '-' : '+';
      off = Math.abs(off);

      return d.getFullYear() + '-'
        + z(d.getMonth() + 1) + '-' +
        z(d.getDate()) + 'T' +
        z(d.getHours()) + ':' +
        z(d.getMinutes()) + ':' +
        z(d.getSeconds()) + '.' +
        zz(d.getMilliseconds()) +
        sign + z(off / 60 | 0) + ':' + z(off % 60);
    },
    setsDiff(a,b){
      a = structuredClone(a)
      b = new Set(structuredClone(b))
      return [...a].filter(x => !b.has(x))
    },
    changeKindFilter(val){
      const kindFilter = new Set(val)
      const diff = this.setsDiff(this.logKindOptions, kindFilter)

      val.forEach(f => {
        this.kindFilterDiffLocal.delete(f)
      })
      diff.forEach(d => {
        this.kindFilterDiffLocal.add(d)
      })
    },
    changeLevelFilter(val){
      const kindFilter = new Set(val)
      const diff = this.setsDiff(this.logLevelOptions, kindFilter)

      val.forEach(f => {
        this.levelFilterDiffLocal.delete(f)
      })
      diff.forEach(d => {
        this.levelFilterDiffLocal.add(d)
      })
    },
  },
  async mounted() {
    this.loadingData = true
    if (!!this.globalSelectedPgId) {
      await this.fetch()
    }
    /*if (this.$route.params.pgid !== '-' && this.$route.params.pgid !== null && this.$route.params.pgid !== undefined) {
      this.pg = this.$route.params.pgid
      this.selectedPropertyGroup = this.$store.state.propertyGroups.find(pg => pg.id === parseInt(this.pg))
      this.fetch()
    } else {
      this.fetch()
    }*/
  }
})
</script>
