<template>
    <v-container class="pa-4" fluid>
        <v-row>
            <v-col class="col-12">
                <v-card class="card-ui pa-4">
                    <v-card-title>Add New User</v-card-title>

                    <v-card-text>
                        <v-form ref="form" v-model="validForm">
                            <v-row>
                                <v-col class="col col-6">
                                    <v-text-field
                                        label="Username"
                                        placeholder="name.surname@example.com"
                                        outlined
                                        dense
                                        :rules="[
                                            rules.noTrailingSpaces,
                                            rules.required,
                                        ]"
                                        v-model="username"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col col-6">
                                    <v-autocomplete
                                        label="Select linked Property Groups"
                                        outlined
                                        multiple
                                        :items="
                                            $store.getters['core/pgsDropdown']
                                        "
                                        item-text="text"
                                        item-value="value"
                                        small-chips
                                        dense
                                        deletable-chips
                                        chips
                                        v-model="linkedPropertyGroups"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-2">
                                    <v-switch
                                        class="mt-0"
                                        label="Send email with token"
                                        dense
                                        v-model="sendEmail"
                                    ></v-switch>
                                </v-col>
                                <v-col class="col-4">
                                    <v-autocomplete
                                        label="Select Role"
                                        outlined
                                        clearable
                                        dense
                                        :items="rolesOptions"
                                        v-model="role"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6">
                                    <v-autocomplete
                                        label="Select Version"
                                        outlined
                                        dense
                                        :items="versionsList"
                                        item-text="label"
                                        item-value="id"
                                        v-model="version"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn color="primary" @click="checkForm">
                            Insert User
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col class="col-12">
                <v-card class="card-ui pa-4">
                    <v-card-title>Generate Activation Link</v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col class="col col-6">
                                <v-autocomplete
                                    dense
                                    outlined
                                    :items="userList"
                                    item-text="username"
                                    item-value="id"
                                    v-model="userIDtoActivate"
                                    label="Select user to activate"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col col-6">
                                <a
                                    target="_blank"
                                    v-if="activationLink"
                                    :href="activationLink"
                                    >{{ activationLink }}</a
                                >
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="primary"
                            :disabled="!userIDtoActivate"
                            @click="generateActivationLink()"
                        >
                            Generate activation link
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-overlay :value="confirmOverlay">
            <v-card class="mx-auto pa-5 card-ui" max-width="500">
                <v-card-text>
                    <p class="text-h4 text--primary">Confirm Adding User</p>
                    <div class="text--primary">
                        Confirm that you're adding the following User:
                        {{ user.username }}
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="insertUser()">
                        Insert User
                    </v-btn>

                    <v-btn
                        color="red"
                        @click="confirmOverlay = !confirmOverlay"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            DEFAULT_USER_VERSION: 8,

            username: "",
            linkedPropertyGroups: [],
            sendEmail: true,
            version: null,
            confirmOverlay: false,
            validForm: true,
            userIDtoActivate: "",
            role: "",

            rules: {
                noTrailingSpaces: (value) => {
                    if (
                        value &&
                        (value.endsWith(" ") || value.startsWith(" "))
                    ) {
                        return "No trailing spaces allowed, remove them!";
                    }
                    return true;
                },
                required: (value) => !!value || "Required.",
            },
        };
    },
    computed: {
        rolesOptions() {
            return this.$store.state.users.rolesOptions;
        },
        userList() {
            return this.$store.state.users.usersList;
        },
        versionsList() {
            return this.$store.state.users.versionsList;
        },
        activationLink() {
            return this.$store.state.users.newGeneratedLink;
        },
        user() {
            return {
                metadata: {},
                spec: {
                    username: this.username,
                    version_id: this.version,
                    social_login: {},
                    role: this.role,
                },
            };
        },
    },
    methods: {
        insertUser() {
            this.$store.dispatch("users/insertUser", {
                user: this.user,
                linkedPGs: this.linkedPropertyGroups,
                generateToken: this.sendEmail,
            });
            this.confirmOverlay = !this.confirmOverlay;
            this.reset();
        },
        reset() {
            this.username = "";
            this.role = "";
            this.linkedPropertyGroups = [];
            this.sendEmail = true;
            this.version = this.DEFAULT_USER_VERSION;
        },
        checkForm() {
            this.$refs.form.validate();

            if (this.validForm) {
                this.confirmOverlay = !this.confirmOverlay;
            }
        },
        generateActivationLink() {
            this.$store.dispatch("users/generateNewToken", {
                user: {
                    metadata: {
                        id: this.userIDtoActivate,
                    },
                    spec: {
                        sendEmail: false,
                    },
                },
            });
        },
    },
    created() {
        this.reset();
        this.$store.commit("users/setLink", { link: "" });
        this.$store.dispatch("users/getAllUsers");
        this.$store.dispatch("users/getVersions");
    },
};
</script>
