<template>
    <v-card v-if="!loading" class="pa-4 card-ui">
        <v-card-title>Strategy Version Selection</v-card-title>

        <v-row class="mt-2 pa-2">
            <v-col class="col-5">
                <v-card class="pa-2 card-ui" :class="{'active-selection-strategy': !versionSelector}" outlined>
                    <v-card-title>Version 1</v-card-title>

                    <v-card-subtitle class="mt-2">Specifications</v-card-subtitle>
                    <ul class="ml-4">
                        <li>PG can work without a booking URL.</li>
                        <li>PG can work with multiple strategy market.</li>
                        <li>Strategies that require different behaviors for property types (room and apartment) are supported.</li>
                    </ul>
                </v-card>
            </v-col>
            <v-col class="col-2 d-flex align-center justify-center">
                <v-switch
                    :false-value="false"
                    :true-value="true"
                    v-model="versionSelector"
                ></v-switch>
            </v-col>
            <v-col class="col-5">
                <v-card class="pa-2 card-ui" :class="{'active-selection-strategy': versionSelector}" outlined>
                    <v-card-title>Version 2</v-card-title>

                    At present, the use of the automatic pipeline is subject to specific conditions.

                    <v-card-subtitle class="mt-2">Specifications</v-card-subtitle>
                    <ul class="ml-4">
                        <li>PG must have the booking URL or coordinates, category and stars.</li>
                        <li>PG must be inserted in only one strategy market.</li>
                        <li>Strategies that require different behaviors for property types (room and apartment) are not supported.</li>
                        <li>The history of the PG or the internal market is recommended to use the demand maps.</li>
                    </ul>

                </v-card>
            </v-col>
        </v-row>

        <v-card-actions class="py-4 my-4">
            <v-btn
                color="primary"
                @click="selectVersion()"
            >Select</v-btn>
        </v-card-actions>
    </v-card>
    <v-card 
        v-else 
        class="card-ui pa-4 d-flex justify-center align-center flex-column" 
        height="300"
        elevation="0"
    >
        <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
            color="primary"
        ></v-progress-circular>
        <p>Creating Strategy</p>
    </v-card>
</template>

<script>
import {mapState} from "vuex"

export default {
    data() {
        return {
            loading: false,

            versionSelector: false, // true = v2, false = v1
        }
    },
    computed: {
        ...mapState({
            currentStrategyPS: state => state.core.currentStrategyPS,
        })
    },
    methods: {
        async selectVersion() {
            this.loading = true 
            if (this.versionSelector) {
                await this.$store.dispatch("strategyNew/createV2Strategy", {psId: this.currentStrategyPS.metadata.id})
            } else {
                await this.$store.dispatch("strategyNew/createV1Strategy", {psId: this.currentStrategyPS.metadata.id})
            }
            this.loading = false
        }
    }
}
</script>


<style>
.active-selection-strategy {
    border: 2px solid #03A9F4 !important;
}
</style>