/**
 * Store for generic UI status
 */ 

export default {
  namespaced: true,

  state: {
    name: null,

    // !! This must match one 
    // !! of the apps in store/modules/app/apps keys
    activeApp: 'strategymanager',
    activeAppSubresource: null,

    restErrors: [],

    darkMode: true,

  },


  mutations: {

    newRestError (state, data) {
      restErrors.push(data)
    },
    setApp (state, appName) {
      state.activeApp = appName
    },
    setAppSubresource (state, subresourceName) {
      state.activeAppSubresource = subresourceName
    },

    changeDisplayMode(state) {
      state.darkMode = !state.darkMode
    },
  },

  actions: {

  },
  getters: {
    getActiveApp(state, getters, rootState, rootGetters){
      return rootState.app.apps?.[state.activeApp]
    },
    getActiveSubresource(state, getters, rootState, rootGetters){
      return getters.getActiveApp ? getters.getActiveApp.subresources.find(s => s.name === state.activeAppSubresource) : {}
    },
    hidePropertyGroupSelectionAppBar(state, getters, rootState, rootGetters){
      return getters.getActiveSubresource?.hidePropertyGroupSelectionAppBar
    },
    hidePropertySetSelectionAppBar(state, getters, rootState, rootGetters){
      return getters.getActiveSubresource?.hidePropertySetSelectionAppBar
    }
  }
}
