<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 col-md-12">
        <v-card>
          <v-card-title>
            Download payouts reconciliation report
          </v-card-title>
          <v-card-subtitle>
            Attention! This operation may take up to 5 minutes
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col class="col-2">
                <v-form ref="form">
                  <v-menu ref="menu" v-model="datePickerOpen" :close-on-content-click="false" :return-value.sync="date"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="date" label="Select month" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" type="month" no-title scrollable @change="$refs.menu.save(date)">
                    </v-date-picker>
                  </v-menu>
                </v-form>
              </v-col>
              <v-col class="col-4">
                <v-btn color="success" class="mr-4 mt-2" @click="fetchAccounts" :loading="loading">
                  Fetch customers report
                </v-btn>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions class="d-flex justify-end">

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 mb-16">
        <v-card>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="accounts" :search="search">
            <template v-slot:item.actions="{ item }">
              <v-btn color="info" @click="downloadReport(item)" :loading="loading">
                Download report
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDialog ref="dialog" />
  </v-container>
</template>

<script>
// @ is an alias to /src


import Vue, { ref } from 'vue'
import ConfirmDialog from '@/components/utils/ConfirmDialog'
import { lastDayOfMonth, addDays } from 'date-fns'

export default Vue.extend({
  name: 'Reporting',
  components: { ConfirmDialog },
  data: function () {
    return {
      loading: false,
      datePickerOpen: false,
      accounts: [],
      search: '',
      date: new Date().toISOString().substr(0, 7),
      headers: [
        { text: 'Name', value: 'name', },
        { text: 'Merchant', value: 'merchant' },
        { text: 'Payments fee net', value: 'fee_net' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    }
  },
  methods: {
    async downloadReport(item) {
      this.loading = true
      const firstDateOfMonth = new Date(`${this.date}-01`)
      const lastDateOfMonth = addDays(lastDayOfMonth(firstDateOfMonth), 1)
      const resp = await this.$store.dispatch('smartpaying/fetchReport', { from: firstDateOfMonth.getTime() / 1000, to: lastDateOfMonth.getTime() / 1000, merchantId: item.merchant })
      this.loading = false
      if (resp.response?.status >= 400) {
        const options = { cardTextClass: "pa-4 orange accent-1--text", color: 'orange' }
        const title = "Error downloading report"
        const message = resp.response.data
        await this.$refs.dialog.open(title, message, options)
      }
      else {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(resp);
        anchor.target = '_blank';
        anchor.download = `report_${item.name.split(' ').join('_')}.csv`;
        anchor.click();
      }

    },
    async fetchAccounts() {
      this.loading = true
      const firstDateOfMonth = new Date(`${this.date}-01`)
      const lastDateOfMonth = addDays(lastDayOfMonth(firstDateOfMonth), 1)
      this.accounts = await this.$store.dispatch('smartpaying/fetchReportAccounts', { withTransactionFrom: firstDateOfMonth.getTime() / 1000, withTransactionTo: lastDateOfMonth.getTime() / 1000 })
      this.loading = false
    },
    async mounted() {
      //this.accounts = await this.$store.dispatch('smartpaying/fetchAccounts')
    }
  }
})
</script>

</script>
