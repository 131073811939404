<template>
    <v-chart 
        :option="option" 
        autoresize 
        :theme="theme" 
        :loading="loading" 
        ref="chart"
        :style="{ width: '100%', 'aspect-ratio': 3}"
    />
</template>

<script setup>
import { toRefs, defineProps, defineEmits, onMounted, ref, computed} from 'vue';
import { use, registerTheme } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {LineChart, HeatmapChart } from 'echarts/charts';
import echartsThemes from "@/assets/templates/echartsThemes.json";
import VChart from 'vue-echarts';

const chart = ref()

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  VisualMapComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent,
  DataZoomInsideComponent
} from 'echarts/components';

use([
    CanvasRenderer,
    LineChart,
    VisualMapComponent, 
    HeatmapChart,
    TitleComponent,
    TooltipComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    LegendComponent,
    GridComponent,
    ToolboxComponent,
])


echartsThemes.forEach(themeConfig => {
    registerTheme(themeConfig.themeName, themeConfig.theme)
})

const props = defineProps({
    theme: {type: String, required: false, default: "dark"},
    loading: {type: Boolean, required: false, default: false},
    option: {type: Object, required: false, default: () => {}}
})

const {theme, loading, option} = toRefs(props)
</script>