<template>
    <v-row>
        <v-col>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field label="Property Group ID (if multiple psIds, put them with ',' e.g. 1,2,3)" type="text"
                    v-model="psIds" required :rules="pgIdRules"></v-text-field>
                <v-text-field label="Insert upselling page link (e.g. 1dest, campo24...)" v-model="link"
                    :rules="linkRule" type="text" required></v-text-field>
                <v-text-field label="Custom sender address" v-model="sender" :rules="emailRule" type="text"
                    required></v-text-field>
                <v-checkbox label="Internal services" v-model="internal"></v-checkbox>
                <v-checkbox label="Freedome services" v-model="freedome"></v-checkbox>
                <v-checkbox label="Tiqets services" v-model="tiqets"></v-checkbox>
            </v-form>
            <div class="d-flex justify-end mt-2">
                <v-btn :disabled="!valid" color="primary" class="mr-4" @click="nextStep" :loading="loading">
                    Submit
                </v-btn>
            </div>
            <ConfirmDialog ref="dialog" />
        </v-col>
    </v-row>
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import ConfirmDialog from '@/components/utils/ConfirmDialog'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'ConfigUpsellingServicesStep',
    components: { ConfirmDialog },
    data: function () {
        return {
            loading: false,
            valid: true,
            link: '',
            sender: '',
            linkRule: [
                v => !!v || 'Link is required'
            ],
            pgIdRules: [
                v => !!v || 'Pg id is required'
            ],
            emailRule: [
                value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return value === '' || pattern.test(value) || 'Invalid e-mail.'
                }
            ],
            psIds: null,
            internal: true,
            freedome: true,
            tiqets: true
        }
    },
    computed: {
        ...mapGetters({
            upsellingPgId: 'smartpaying/upsellingPgId',
        })
    },
    methods: {
        async nextStep() {
            const isValid = this.$refs.form.validate()
            if (isValid) {
                this.loading = true
                const splittedIds = this.psIds.split(',')
                await this.$store.dispatch('smartpaying/setUpsellingPsIds', { psIds: splittedIds })
                const resp = await this.$store.dispatch('smartpaying/setUpsellingConfig', { link: this.link, internal: this.internal, freedome: this.freedome, tiqets: this.tiqets, sender: this.sender, afterCreationMail: null, beforeCheckinMail: null, beforeCheckinWhats: null, afterCreationWhats: null })
                this.loading = false
                if (resp.response?.status >= 400) {
                    const options = { cardTextClass: "pa-4 orange accent-1--text", color: 'orange' }
                    const title = "Error sending test message"
                    const message = resp.response.data
                    await this.$refs.dialog.open(title, message, options)
                }
                else {
                    this.$emit('next')
                    this.psIds = null
                    this.link = ''
                    this.sender = ''
                    this.internal = true
                    this.freedome = true
                    this.tiqets = true
                }
            }
        }
    }
})
</script>